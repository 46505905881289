import React from 'react';
import { useSelector } from 'react-redux';
import I18n from 'app/config/i18n';
import { PATHS } from '~/utils/constants';
import { getDataPointIdByPath } from '~/store/selectors/data_points';
import { getLastMeasurementValue } from '~/store/selectors/measurements';
import BatteryLevel from './battery_level';

const BatteryContainer = ({ nodeId }) => {
  const {
    measurement
  } = useSelector(
    (state) => {
      const measurementId = getDataPointIdByPath(state, nodeId, PATHS.BATTERY_VOLTAGE);
      return {
        measurement: getLastMeasurementValue(state, measurementId)
      };
    }
  );

  return (
    <BatteryLevel
      value={measurement}
      title={(value) => I18n.t('thiamis.more_info.battery', { value })}
      noValueText={I18n.t('base.labels.n_a')}
    />
  );
};

export default BatteryContainer;
