import { Component } from 'react';
import { app as App } from 'app/backbone/app';

export default class BackboneHider extends Component {
  componentDidMount() {
    $(App.getChannel().request('get:default:region').el).hide();
    $(App.getChannel().request('get:react:region').el).show();
  }

  componentWillUnmount() {
    $(App.getChannel().request('get:react:region').el).hide();
    $(App.getChannel().request('get:default:region').el).show();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
