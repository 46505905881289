import loadGoogleMapsApi from 'load-google-maps-api';

const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_KEY;

async function getMap(options = {}) {
  const { withMarkerClusterer, withMarkerWithLabel, withInfoBox } = _.defaults(options, { withMarkerClusterer: false, withMarkerWithLabel: false, withInfoBox: false });
  let MarkerClusterer;
  let MarkerWithLabel;
  let map;
  try {
    map = await loadGoogleMapsApi({
      key: GOOGLE_MAPS_KEY,
      v: '3.47',
      libraries: ['places']
    });
  } catch (e) {
    console.error(e);
  }

  if (withInfoBox) {
    const InfoBox = await import('@4ddavydov/infobox');
    _.extend(map, {
      InfoBox: InfoBox.default
    });
  }

  if (withMarkerClusterer) {
    MarkerClusterer = await import('@google/markerclustererplus');
    _.extend(map, {
      MarkerClusterer: MarkerClusterer.default
    });
  }
  if (withMarkerWithLabel) {
    MarkerWithLabel = await import('@google/markerwithlabel');
    _.extend(map, {
      MarkerWithLabel: MarkerWithLabel.default
    });
  }
  return map;
}

export default getMap;
