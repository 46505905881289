import axios from 'axios';
import { toast } from 'react-toastify';
import { getAuthToken } from '~/services/auth/selectors';

const { API_URL } = process.env;

axios.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = token; // eslint-disable-line no-param-reassign
    }
    return config;
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error)) {
      toast.error(_.parseErrResp({ status: error.response?.status, responseText: JSON.stringify(error.response?.data) }), {
        position: toast.POSITION.TOP_CENTER
      });
      if ([500, 404, 403, 402, 401].includes(error.response?.status)) {
        toast.error('There was a problem processing your request, please try again later or contact ssupport');
      } else {
        let errorResponse;
        if (error.response && error.response.data) {
          if (error.response.data?.errors) {
            return Promise.reject(error);
          }
          errorResponse = error.response.data;
        } else if (error.request) {
          errorResponse = error.request.message || error.request.statusText;
        } else {
          errorResponse = error.message;
        }
        toast.error(errorResponse);
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const request = (method, url, params, options = {}) => {
  const body = method === 'get' ? 'params' : 'data';

  const config = {
    method,
    url,
    [body]: params || {}
  };

  if (url.indexOf('http') !== 0) {
    config.baseURL = API_URL;
  }

  return axios.request(Object.assign(config, options));
};

export default request;
