/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import WidgetBase from 'app/backbone/entities/widgets/widget_base';
import { NodeSeriesStreamSubscribable } from 'app/backbone/lib/concerns/entities/measurement_updatable';
import { api as apiDataPoints } from 'app/backbone/entities/data_points';

export default class CompareDevicesWidget extends NodeSeriesStreamSubscribable(WidgetBase) {
  get defaults() {
    return {
      _type: 'CompareDevicesWidget',
      size: 1,
      time_period: 'hours_3',
      selected_data_point_ids: [],
      yaxis_grouped: true,
      resizable: true
    };
  }

  get validation() {
    return {
      selected_data_point_ids: {
        required: true
      },
      node_ids: {
        required: true
      }
    };
  }

  get transientAttrs() {
    return ['resizable'];
  }

  getPeriodFrom() {
    return this.getNodeDataStream().periodFrom();
  }

  getXhrs() {
    return [this.getNodeDataStream().getDeferred()];
  }

  getCombinedCollection(data) {
    return _.reduce(data, (accu, dp) => {
      const result = accu;
      if (!dp.path) {
        return accu;
      }
      const [, device, path] = dp.path.split(':');
      const index = `${device}:${path}`;
      if (!result[index]) {
        result[index] = { ...dp, ids: [dp._id], _id: index };
      } else {
        result[index].ids.push(dp._id);
      }
      return result;
    }, {});
  }

  getAvailableDataPointsColl() {
    if (this.defaultFormDataPoints) { return this.defaultFormDataPoints; }
    this.formDataPoints = apiDataPoints.getChannel().request('fetch:form:datapoints', this.get('node_ids'));
    this.formDataPoints.on('sync', (collection, response) => {
      const combined = this.getCombinedCollection(response);
      collection.reset(Object.values(combined).filter((dp) => dp.ids.length > 1));
    });
    this.defaultFormDataPoints = apiDataPoints.getChannel().request('new:filtered:datapoints', this.formDataPoints).filterDefault();
    this.on('change:node_ids', (model, nodeIds) => this.formDataPoints.changeNodeId(nodeIds));
    return this.defaultFormDataPoints;
  }

  formParams() {
    return { collection: this.getAvailableDataPointsColl() };
  }
}