export default {
  admin_message: {
    expires_at: {
      required: true
    },
    level: {
      required: true
    },
    message: {
      required: true
    }
  },
  alert_subscription: {
    alert_id: {
      required: true
    },
    organization: {
      required: true
    }
  },
  message: {
    body: {
      required: true
    },
    subject: {
      required: true
    },
    user_id: {
      required: true
    }
  },
  organization: {
    billing_address_city: {
      required: true
    },
    billing_address_country: {
      required: true
    },
    billing_address_state: {
      required: true
    },
    billing_address_street: {
      required: true
    },
    billing_address_zip_code: {
      required: true
    },
    contact_email: {
      required: true
    },
    contact_first_name: {
      required: true
    },
    contact_industry: {
      required: true
    },
    contact_last_name: {
      required: true
    },
    contact_phone: {
      required: true
    },
    name: {
      required: true
    },
    shipping_address_city: {
      required: true
    },
    shipping_address_country: {
      required: true
    },
    shipping_address_state: {
      required: true
    },
    shipping_address_street: {
      required: true
    },
    shipping_address_zip_code: {
      required: true
    }
  },
  partnership_invitation: {
    inviting_organization: {
      required: true
    }
  },
  user_invitation: {
    email: {
      required: true
    },
    role: {
      required: true
    }
  }
};