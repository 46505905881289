import React, { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { api as orgAppApi } from 'app/backbone/apps/organizations/organizations_app';
import I18n from 'app/config/i18n';
import { getUserByIdAbilities, getUserByIdIsRentalManager } from '~/store/selectors/users';
import { getOrganizationIdsIsRental } from '~/store/selectors/organizations';
import { getCurrentUserId } from '~/store/selectors/global';
import { getFilter, setFilter } from '../reducer';

export default function FiltersFormTop() {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const dispatch = useDispatch();
  const {
    organizationsQuery,
    isOrganizationCreatable
  } = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    const currentUserAbilities = getUserByIdAbilities(state, currentUserId);
    const rentalOrganizationIds = getOrganizationIdsIsRental(state);
    return {
      organizationsQuery: getFilter(state),
      isOrganizationCreatable: currentUserAbilities?.organization.creatable,
      isCurrentUserRentalManager: getUserByIdIsRentalManager(state, currentUserId, rentalOrganizationIds)
    };
  });

  useEffect(() => {
    if (organizationsQuery === debouncedQuery) {
      return;
    }
    dispatch(setFilter(debouncedQuery));
  }, [query, debouncedQuery, dispatch, organizationsQuery]);

  const handleOrganizationsCreateNewClick = useCallback(() => {
    orgAppApi.getChannel().request('app:organizations:new');
  }, []);

  return (
    <div className="content-top">
      <div className="pull-left">
        <div className="organizations-filter-input-region filter-input-wrap">
          <input
            className="filter-input"
            type="text"
            placeholder="Filter..."
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
          />
        </div>
      </div>
      <div className="pull-right">
        { isOrganizationCreatable
          && (
          <button type="button" className="btn btn-primary btn-invite new-org" onClick={handleOrganizationsCreateNewClick}>
            <i className="fa fa-fw fa-building-plus" />
            <span>{I18n.t('organizations.create_new')}</span>
          </button>
          )}
      </div>
    </div>
  );
}
