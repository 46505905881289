/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const fetchCalibrationsRequest = createAction('@@calibrations/FETCH_REQUEST');
export const fetchCalibrationsSuccess = createAction('@@calibrations/FETCH_SUCCESS');
export const fetchCalibrationsFailure = createAction('@@calibrations/FETCH_FAILURE');

export const fetchRerefenceDevicesRequest = createAction('@@calibrations/FETCH_REFERENCE_DEVICES_REQUEST');
export const fetchRerefenceDevicesSuccess = createAction('@@calibrations/FETCH_REFERENCE_DEVICES_SUCCESS');
export const fetchRerefenceDevicesFailure = createAction('@@calibrations/FETCH_REFERENCE_DEVICES_FAILURE');

export const createCalibrationRequest = createAction('@@calibrations/CREATE_REQUEST');
export const createCalibrationSuccess = createAction('@@calibrations/CREATE_SUCCESS');
export const createCalibrationFailure = createAction('@@calibrations/CREATE_FAILURE');

export const filterListByOrganizationId = createAction('@@calibrations/FILTER_BY_OID');
export const filterListByQuery = createAction('@@calibrations/FILTER_BY_QUERY');
export const clearFilters = createAction('@@calibrations/CLEAR_FILTERS');
export const filterListByTag = createAction('@@calibrations/FILTER_BY_TAG');
export const expandList = createAction('@@calibrations/EXPAND_ROW');

const initialFilter = { query: null, tags: [], oid: null };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByOrganizationId](state, { payload }) {
    state.oid = payload;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  },
  [filterListByTag](state, { payload }) {
    state.tags = payload;
  }
});

const idsInitialState = [];
const ids = createReducer(idsInitialState, {
  [fetchCalibrationsSuccess](state, { payload: { result } }) {
    return result;
  }
});

const byIdData = {};
const byId = createReducer(byIdData, {
  [fetchCalibrationsSuccess](state, { payload }) {
    if (payload.entities) {
      return get(payload.entities, 'calibrations', byIdData);
    }
    return state;
  }
});

const isFetched = createReducer(false, {
  [fetchCalibrationsRequest]() {
    return false;
  },
  [fetchCalibrationsFailure]() {
    return false;
  },
  [fetchCalibrationsSuccess]() {
    return true;
  }
});

const referenceDevices = createReducer([], {
  [fetchRerefenceDevicesSuccess](state, { payload }) {
    if (payload) {
      return payload;
    }
    return state;
  }
});

const calibrations = combineReducers({
  filter,
  isFetched,
  referenceDevices,
  ids,
  byId
});

export const getCalibration = (state) => state.calibrations.byId;
export const getCalibrationById = (state, id) => state.calibrations.byId[id];
export const getIds = (state) => state.calibrations.ids;
export const getIsFetched = (state) => state.calibrations.isFetched;
export const getFilter = (state) => state.calibrations.filter;
export const getReferenceDevices = (state) => state.calibrations.referenceDevices;

export default calibrations;
