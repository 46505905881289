import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  VirtualTable,
  TableHeaderRow
} from '@devexpress/dx-react-grid-bootstrap3';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import includes from 'lodash/includes';
import { getOrganizationById } from '~/store/reducers/organizations';
import { getNodeById } from '~/store/reducers/nodes';

import ManageCalibrationsContext from '../../../manage_context';

const ModalTable = ({ showBilling = false }) => {
  const { values: { disabledNodes: disabled } } = useFormState();
  const { selectedCalibrations } = useContext(ManageCalibrationsContext);
  const { t } = useTranslation();

  const {
    devices
  } = useSelector((state) => {
    const nodes = selectedCalibrations.map((deviceId) => getNodeById(state, deviceId));
    const nodesWithOrganization = nodes.map((node = {}) => {
      const organization = getOrganizationById(state, node.organization_id);
      const billing = getOrganizationById(state, node.payment);
      return {
        ...node,
        displayName: node.name ? `${node.serial} - ${node.name}` : node.serial,
        organization,
        owner: organization && organization.name,
        billing,
        biller: billing && billing.name
      };
    });
    const sortedNodes = nodesWithOrganization
      .sort((node1, node2) => node1.displayName?.localeCompare(node2.displayName));

    return {
      devices: sortedNodes
    };
  });

  const maxHeight = useMemo(() => (showBilling ? 150 : 220), [showBilling]);

  const Row = (props) => {
    const { row } = props;
    return (
      <VirtualTable.Row
        {...props}
        className={classNames('body-row', { 'disabled-row': includes(disabled, row?._id) })}
      />
    );
  };

  const Root = (props) => <Grid.Root {...props} style={{ maxHeight: 220, height: 'unset' }} />;

  const columns = useMemo(() => {
    const columnList = [
      { name: 'displayName', title: t('attributes.node.name') },
      { name: 'owner', title: t('thiamis.owner') }
    ];
    if (showBilling) {
      columnList.push({
        name: 'biller', title: t('thiamis.billing')
      });
    }
    return columnList;
  }, [t, showBilling]);

  let tableHeight = (devices.length + 1) * 38;

  if (tableHeight > maxHeight) {
    tableHeight = maxHeight;
  }

  return (
    <Grid
      rows={devices}
      columns={columns}
      rootComponent={Root}
    >
      <VirtualTable height={tableHeight} rowComponent={Row} />
      <TableHeaderRow showSortingControls={false} />
    </Grid>
  );
};

export default ModalTable;
