/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { api as apiStream } from 'app/backbone/entities/data_stream';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';

export const BaseNodeStreamSubscribable = (superclass) => class extends superclass {
  getNodeDataStream() {
    if (this.dataStream) { return this.dataStream; }

    this.createNodeDataStream();
    if (!this.dataStream) { return; }

    this.on('change:node_id', () => this.dataStream.set('node_id', this.get('node_id')));
    this.on('destroy', () => this.dataStream.destroy());
    return this.dataStream;
  }

  createNodeDataStream() { return _.abstractMethod(); }
};

export const BaseWidgetNodeStreamSubscribable = (superclass) => class extends BaseNodeStreamSubscribable(superclass) {
  initialize(...args) {
    super.initialize(...args);
    this.on('change:selected_data_point_ids change:filtered_data_point_ids', (widget) => {
      if (widget.isFormModel) { return; }
      return this.getFilteredDataPointsColl().refilter();
    });

    this.on('change:node_id', (widget, newValue, options) => {
      if (options.onSave) { return; }
      return this.set({
        selected_data_point_ids: _.result(this, 'defaults').selected_data_point_ids,
        filtered_data_point_ids: _.result(this, 'defaults').filtered_data_point_ids
      });
    });

    return this.on('change:time_period', (widget) => {
      if (widget.isFormModel) { return; }
      return (this.dataStream != null ? this.dataStream.set('time_period', this.get('time_period')) : undefined);
    });
  }

  getDataPointsColl() {
    return this.getNodeDataStream().getDataPointsColl();
  }

  getFilteredDataPointsColl() {
    if (this.dataPointsColl) { return this.dataPointsColl; }
    this.dataPointsColl = new FilteredCollection(this.getDataPointsColl());
    this.dataPointsColl.filterBy((dp) => {
      if (!this.has('data_points_filter_type')) {
        let needle;
        return (needle = dp.id, Array.from(this.getSelectedDataPointIds()).includes(needle));
      }
      if (this.get('data_points_filter_type') === 'included') {
        let needle1;
        return _.contains(this.getSelectedDataPointIds(), 'all') || (needle1 = dp.id, Array.from(this.getSelectedDataPointIds()).includes(needle1));
      }
      let needle2;
      return (needle2 = dp.id, !Array.from(this.getFilteredDataPointIds()).includes(needle2));
    });
    return this.dataPointsColl;
  }

  getSelectedDataPointIds() {
    return this.get('selected_data_point_ids') || _.result(this, 'defaults').selected_data_point_ids;
  }

  getFilteredDataPointIds() {
    return this.get('filtered_data_point_ids') || _.result(this, 'defaults').filtered_data_point_ids;
  }
};

export const NodeSeriesStreamSubscribable = (superclass) => class extends BaseWidgetNodeStreamSubscribable(superclass) {
  createNodeDataStream() {
    if (this.isFormModel) { return; }
    this.dataStream = apiStream.getChannel().request('get:thiamis:data:series:stream',
      { node_id: this.get('node_id') || this.get('node_ids'), time_period: this.get('time_period') });
    return undefined;
  }
};

export const NodeStreamSubscribable = (superclass) => class extends BaseWidgetNodeStreamSubscribable(superclass) {
  createNodeDataStream() {
    if (this.isFormModel) { return; }
    this.dataStream = apiStream.getChannel().request('get:thiamis:data:stream',
      { node_id: this.get('node_id') || this.get('node_ids'), last_measurements: this.get('last_measurements') });
    return undefined;
  }
};

export const AlertStreamSubscribable = (superclass) => class extends BaseNodeStreamSubscribable(superclass) {
  createNodeDataStream() {
    if (this.isFormModel) { return; }
    this.dataStream = apiStream.getChannel().request('get:thiamis:data:stream',
      { node_id: this.get('node_id'), last_measurements: this.get('last_measurements') });
    return undefined;
  }
};

export const ThiamisStreamSubscribable = (superclass) => class extends BaseNodeStreamSubscribable(superclass) {
  createNodeDataStream() {
    if (this.isFormModel) { return; }
    this.dataStream = apiStream.getChannel().request('get:thiamis:data:stream', { node_id: this.id, node: this });
    return undefined;
  }
};
