import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects';
import requestApi from './requestApi';
import { normalizeResp } from '~/store/normalizr';
import * as Actions from '~/store/reducers/webhooks';
import { getWebhookByIdIsEnabled } from '~/store/selectors/webhooks';
import * as schema from '~/store/schema';

export function* webhooksFetchRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'get',
      'webhooks',
      {}
    );
    const response = normalizeResp(data, schema.webhookListSchema);
    yield put(Actions.fetchWebhookListSuccess(response));
  } catch (e) {
    yield put(Actions.fetchWebhookListFailure(e.toString()));
  }
}

function* watchFetchWebhooksRequest() {
  yield takeLatest(Actions.fetchWebhookListRequest.toString(), webhooksFetchRequestSaga);
}

export function* webhooksDeleteRequestSaga({ payload = {} }) {
  try {
    const { id, onSuccess } = payload;
    yield call(
      requestApi,
      'delete',
      `webhooks/${id}`,
      {}
    );
    if (onSuccess) {
      onSuccess();
    }
    yield put(Actions.deleteWebhookSuccess(payload));
  } catch (e) {
    yield put(Actions.deleteWebhookFailure(e.toString()));
  }
}

function* watchDeleteWebhookRequest() {
  yield takeEvery(Actions.deleteWebhookRequest.toString(), webhooksDeleteRequestSaga);
}

export function* webhooksCreateRequestSaga({
  payload: {
    organization, url, auth, onSuccess, _type, nodes
  }
}) {
  try {
    const body = {
      organization_id: organization,
      url,
      auth,
      conditions: {
        _type,
        node_id: nodes
      }
    };
    const response = yield call(
      requestApi,
      'post',
      'webhooks',
      body
    );
    yield put(Actions.createWebhookSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.createWebhookFailure(e.toString()));
  }
}

function* watchCreateWebhookRequest() {
  yield takeEvery(Actions.createWebhookRequest.toString(), webhooksCreateRequestSaga);
}

export function* webhooksToggleRequestSaga({
  payload: {
    id
  }
}) {
  const isEnabled = yield select(getWebhookByIdIsEnabled, id);
  const action = isEnabled ? 'disable' : 'enable';
  try {
    yield call(
      requestApi,
      'put',
      `webhooks/${id}/${action}`
    );
    yield put(Actions.toggleWebhookSuccess({ id, enabled: !isEnabled }));
  } catch (e) {
    yield put(Actions.toggleWebhookFailure({ id, e }));
  }
}

function* watchWebhooksToggleRequestSaga() {
  yield takeEvery(Actions.toggleWebhookRequest.toString(), webhooksToggleRequestSaga);
}

export default function* app() {
  yield fork(watchFetchWebhooksRequest);
  yield fork(watchDeleteWebhookRequest);
  yield fork(watchCreateWebhookRequest);
  yield fork(watchWebhooksToggleRequestSaga);
}
