import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const resetPasswordRequest = createAction('@@profile/RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('@@profile/RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('@@profile/RESET_PASSWORD_FAILURE');

export const saveProfileRequest = createAction('@@profile/SAVE_REQUEST');
export const saveProfileSuccess = createAction('@@profile/SAVE_SUCCESS');
export const saveProfileFailure = createAction('@@profile/SAVE_FAILURE');

const isSyncing = createReducer(false, {
  [resetPasswordRequest]() {
    return true;
  },
  [resetPasswordSuccess]() {
    return false;
  },
  [resetPasswordFailure]() {
    return false;
  },
  [saveProfileRequest]() {
    return true;
  },
  [saveProfileSuccess]() {
    return false;
  },
  [saveProfileFailure]() {
    return false;
  }
});

const profile = combineReducers({
  isSyncing
});

export const getProfileIsSyncing = (state) => state.profile.isSyncing;

export default profile;
