import { Region } from 'backbone.marionette';

export default Region.extend({
  el: '.dialog-region',

  hideDialog() {
    if (this.hasView()) {
      this.currentView.closeDialog();
    }
  }
});
