import { createSelector } from 'reselect';
import { PATHS } from '~/utils/constants';
import { getDataPointIdByPath } from '~/store/selectors/data_points';
import { getLastMeasurementTs } from '~/store/selectors/measurements';

export const getDeviceGsmSignalTs = (state, nodeId) => {
  const id = getDataPointIdByPath(state, nodeId, PATHS.GSM_SIGNAL);
  return getLastMeasurementTs(state, id);
};

export const getDeviceWifiSignalTs = (state, nodeId) => {
  const id = getDataPointIdByPath(state, nodeId, PATHS.WIFI_SIGNAL);
  return getLastMeasurementTs(state, id);
};

export const getDeviceIsGsmSignal = createSelector(
  [getDeviceGsmSignalTs, getDeviceWifiSignalTs],
  (gsmTs = 0, wifiTs = 0) => gsmTs > wifiTs
);
