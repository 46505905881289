/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const modalActionRequest = createAction('@@users/MODAL_ACTION_REQUEST');
export const modalActionFailure = createAction('@@users/MODAL_ACTION_FAILURE');

export const filterListByQuery = createAction('@@usersList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@usersList/CLEAR_FILTERS');
export const expandList = createAction('@@usersList/EXPAND_ROW');

export const sortInvitationsList = createAction('@@invitationsList/SORT_BY');

const initialFilter = { query: null, tags: [] };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  }
});

const usersList = combineReducers({
  filter
});

export const getFilter = (state) => state.usersList.filter;

export default usersList;
