/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import Packery from 'packery';
import Draggabilly from 'draggabilly';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import { isMobile } from 'app/utils/resolution_helpers';
import { app as App } from 'app/backbone/app';
import { WidgetLayout } from 'app/backbone/lib/widgets/widgets_base/widget_views';
import WidgetsConfig from 'app/backbone/lib/widgets/config';
import containerLayoutTemplate from './templates/container_layout.hbs';
import containerTemplate from './templates/container.hbs';
import containerEmptyTemplate from './templates/container_empty.hbs';
import containerToolbarTemplate from './templates/container_toolbar.hbs';

export const ContainerLayout = View.extend({
  template: containerLayoutTemplate,
  regions:
    { containerRegion: '.container-region' }
});

export const Toolbar = View.extend({
  template: containerToolbarTemplate,
  className: 'dropdown',

  collectionEvents: {
    add: 'onWidgetsChanged',
    remove: 'onWidgetsChanged'
  },

  ui:
    { twitterWidget: 'a.new_twitter_widget' },

  triggers: {
    'click a.new_text_widget': 'click:new:text_widget',
    'click a.new_node_widget': 'click:new:node_widget',
    'click a.new_chart_widget': 'click:new:chart_widget',
    'click a.new_map_widget': 'click:new:map_widget',
    'click a.new_blocks_widget': 'click:new:blocks_widget',
    'click @ui.twitterWidget': 'click:new:twitter_widget',
    'click a.new_airthinx_widget': 'click:new:heatmap_widget',
    'click a.new_sensor_widget': 'click:new:sensor_widget'
  },

  onWidgetsChanged() {
    return this.ui.twitterWidget.toggleClass('disabled', this.collection.hasTwitterWidget());
  },

  onRender() {
    return this.onWidgetsChanged();
  }
});

export const EmptyContainer = View.extend({
  template: containerEmptyTemplate,
  className: 'empty-widget-container'
});

export const Container = AppCollectionView.extend({
  template: containerTemplate,
  childViewContainer: '.widgets',
  childView: WidgetLayout,
  emptyView: EmptyContainer,

  childViewOptions() {
    return _(this.options).pick(WidgetsConfig.requiredOptionNames);
  }
});

export const LayoutingContainer = Container.extend({
  onDomRefresh() {
    if (isMobile()) { return; }
    if (this.packery) { return; }
    this.packery = new Packery(this.el, {
      itemSelector: '.widget'
    });
    this.listenTo(App.getChannel(), 'window:resized', () => _.delay(() => this.packery.layout(), 300));
    this.children.each(this.makeDraggable, this);
  },

  getViewIndex(el) {
    return _(this.packery.getItemElements()).indexOf(el);
  },

  makeDraggable(childView) {
    const draggie = new Draggabilly(childView.el, { handle: '.action-move' });
    const position = this.getViewIndex(childView.el);
    childView.triggerMethod('dragged', position);
    draggie.on('dragEnd', () => {
      App.getChannel().trigger('window:resized', { originalEvent: true });
      return _.delay(() => {
        const newPosition = this.getViewIndex(childView.el);
        return childView.triggerMethod('dragged', newPosition);
      }, 800);
    });
    this.packery.bindDraggabillyEvents(draggie);
  },

  onAddChild(view, addedWidgetView) {
    if (this.packery) {
      this.packery.appended(addedWidgetView.$el);
      _.delay(() => {
        this.makeDraggable(addedWidgetView);
      }, 0);
    }
  },

  onRemoveChild(view, removedWidgetView) {
    if (this.packery) {
      this.packery.remove(removedWidgetView.$el);
      return this.packery.layout();
    }
  },

  onChildviewAfterResize() {
    return (this.packery != null ? this.packery.layout() : undefined);
  }
});
