/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import ChartWidget from 'app/backbone/entities/widgets/chart_widget';
import CompareDevicesWidget from 'app/backbone/entities/widgets/compare_devices_widget';
import NodeWidget from 'app/backbone/entities/widgets/node_widget';
import BlocksWidget from 'app/backbone/entities/widgets/blocks_widget';
import TextWidget from 'app/backbone/entities/widgets/text_widget';
import TwitterWidget from 'app/backbone/entities/widgets/twitter_widget';
import HeatmapWidget from 'app/backbone/entities/widgets/heatmap_widget';
import MapWidget from 'app/backbone/entities/widgets/map_widget';
import { __guard__ } from 'app/utils/custom-fns';

export default class Widgets extends AppCollection.extend({
  model(attrs, options = {}) {
    const { collection } = options;
    const { thiamises } = __guard__(collection.parents, (x) => x[0]) || {};
    return Widgets.getModelByType(attrs._type, attrs, _.extend(options, { thiamises }));
  }
}) {
  static MAXIMUM_NUMBER_OF_WIDGETS = 20;

  static getModelByType = (type, ...args) => {
    switch (type) {
      case 'CompareDevicesWidget':
        return new CompareDevicesWidget(...args);
      case 'ChartWidget':
        return new ChartWidget(...args);
      case 'NodeWidget':
        return new NodeWidget(...args);
      case 'BlocksWidget':
        return new BlocksWidget(...args);
      case 'MapWidget':
        return new MapWidget(...args);
      case 'TextWidget':
        return new TextWidget(...args);
      case 'TwitterWidget':
        return new TwitterWidget(...args);
      case 'HeatmapWidget':
        return new HeatmapWidget(...args);
      default:
        throw new Error('Invalid chart widget type');
    }
  }

  comparator(w1, w2) {
    const position1 = w1.get('position');
    const position2 = w2.get('position');
    if (position1 === position2) {
      return w1.get('updated_at') > w2.get('updated_at') ? -1 : 1;
    }
    return position1 > position2 ? 1 : -1;
  }

  url() {
    return `${this.parents[0].url()}/widgets`;
  }

  add(model, options = {}) {
    const max_widgets = Widgets.MAXIMUM_NUMBER_OF_WIDGETS;
    if (this.length < max_widgets) {
      return super.add(model);
    }
    const msg = I18n.t('dashboard.max_number_of_widgets_reached',
      { widget_number: max_widgets });
    if (options.failure != null) {
      return options.failure(msg);
    } throw msg;
  }

  hasTwitterWidget() {
    return !!this.findWhere({ _type: 'TwitterWidget' });
  }
}

export const API = MnObject.extend({
  channelName: 'entities:widget',
  radioRequests: {
    'new:widget': 'newWidgetEntity'
  },

  newWidgetEntity(widgetAttrs, options) {
    return Widgets.getModelByType(widgetAttrs._type, widgetAttrs, options);
  }
});

export const api = new API();
