import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListTableHoc from 'app/backbone/lib/react/hocs/ListTableHoc';
import ListTablePagination from 'app/backbone/lib/react/components/ListTable/ListTablePagination';

class ListTable extends Component {
  render() {
    return (
      <div>
        <div className={classNames('list-table', { empty: this.props.collection.isEmpty() })} style={{ height: this.props.listHeight }}>
          { this.createTable() }
        </div>
        {
          this.props.pagination
            ? <ListTablePagination {...{ collection: this.props.collection, ...this.props.pagination }} />
            : undefined
        }
      </div>
    );
  }
}

ListTable.defaultProps = {
  listHeight: ''
};

ListTable.propTypes = {
  pagination: PropTypes.any.isRequired,
  collection: PropTypes.any.isRequired,
  listHeight: PropTypes.any
};

export default ListTableHoc(ListTable);
