/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default (superclass) => class extends superclass {
  hasAddress(addrName) {
    return this.on(`change:${this._countryAttrName(addrName)}`, () => this._resetState(addrName));
  }

  validateState(addrName) {
    return function (value, attr, computedAttrs) {
      if (computedAttrs[this._countryAttrName(addrName)] === 'US') {
        return Backbone.Validation.validators.required(value, attr, this._stateAttrName(addrName), this, computedAttrs);
      } return null;
    };
  }

  _resetState(addrName) {
    if (this.previous(this._stateAttrName(addrName)) != null) {
      return this.set(this._stateAttrName(addrName), '');
    }
  }

  _countryAttrName(addrName) { return `${addrName}_country`; }
  _stateAttrName(addrName) { return `${addrName}_state`; }
};
