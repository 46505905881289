/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import defaultsDeep from 'lodash/defaultsDeep';
import * as Highcharts from 'highcharts';

require('highcharts/modules/windbarb')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);

const WindChart = View.extend({
  className: 'fill',

  template: false,

  onAugmentMeasurements: _.debounce(function () {
    return this.resetSeries();
  },
  1000),

  id() { return `polar-chart-wrapper-${this.cid}`; },

  getHighchart() {
    return this.highchart != null ? this.highchart : (this.highchart = this.buildHighchart());
  },

  initialize({ thiamis }) {
    this.thiamis = thiamis;
    if (this.collection) {
      this.listenTo(this.collection, 'updates:set', ({ initiate }) => {
        if (initiate) {
          this.listenToMeasurements();
          return this.resetSeries();
        }
      });
      this.listenToMeasurements();
    }
    return this.populateSeries();
  },

  listenToMeasurements() {
    const speed = this.collection.getWindSpeedDataPoint(this.thiamis);
    if (speed) {
      this.listenTo(speed, 'augment:measurements', this.onAugmentMeasurements);
    }

    const direction = this.collection.find((dp) => dp.isWindDirection() && this.thiamis.hasDevice(dp.get('device_id')));
    if (direction) {
      return this.listenTo(direction, 'augment:measurements', this.onAugmentMeasurements);
    }
  },

  populateSeries() {
    return this.model.populateSeries(this.collection, this.thiamis);
  },

  resetSeries() {
    this.populateSeries();
    this.clearChart();
    return this.renderSeries();
  },

  clearChart() {
    const chart = this.getHighchart();
    return (() => {
      const result = [];
      while (chart.series.length > 0) {
        result.push(chart.series[0].remove(false));
      }
      return result;
    })();
  },

  setChartOptions(options) {
    return this.chartOptions = defaultsDeep(this.getChartDefaults(), options);
  },

  getChartDefaults() {
    return {
      chart: {
        polar: true,
        type: 'column',
        renderTo: this.id()
      },
      title: {
        text: null
      },
      pane: {
        size: '85%'
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        y: 100,
        layout: 'vertical'
      },
      xAxis: {
        tickmarkPlacement: 'on',
        labels: {
          formatter() {
            return `${_.cardinalDirections()[this.value]}°`;
          }
        }
      },
      yAxis: {
        min: 0,
        endOnTick: false,
        showLastLabel: true,
        title: {
          text: 'Frequency (%)'
        },
        labels: {
          formatter() {
            return `${this.value}%`;
          }
        }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        followPointer: true
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          shadow: false,
          groupPadding: 0,
          pointPlacement: 'on'
        }
      }
    };
  },

  buildHighchart() {
    return new Highcharts.Chart(this.chartOptions || this.getChartDefaults());
  },

  renderChart() {
    return this.highchart = this.buildHighchart();
  },

  onBeforeDetach() {
    return this.getHighchart().destroy();
  },

  isChartDestroyed() {
    return _.isUndefined(this.getHighchart().axes);
  },

  addSeries(serie) {
    return this.getHighchart().addSeries(serie, false);
  },

  renderSeries() {
    this.series = this.model.get('series').toJSON({ asChartData: true });
    _.each(this.series, this.addSeries, this);
    return this.getHighchart().redraw();
  },

  onDomRefresh() {
    this.renderChart();
    return this.renderSeries();
  }
});

export default WindChart;
