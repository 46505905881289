import styled from 'styled-components';

export const AddButton = styled.div.attrs(() => ({
  className: 'btn btn-default'
}))``;

export const RemoveIconContainer = styled.div`
  top: 50%;
  right: -32px;
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
`;

export const RoleBlock = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  background: #f5f5f5;
  margin-bottom: 15px;
`;

export const RolesInfoBlock = styled.div`
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
`;

export const Row = styled.div`
  position: relative;
  margin-bottom: 10px;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  ${({ deleted }) => (deleted ? 'div { text-decoration: line-through; }' : '')}
`;
