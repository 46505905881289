/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { View } from 'backbone.marionette';
import { Provider } from 'react-redux';
import reduxStore from 'app/react/store';
import { unmountComponentAtNode } from 'app/backbone/lib/react/utils';
import { app as App } from 'app/backbone/app';
import { api as thiamisApi } from 'app/backbone/entities/nodes/thiamis';
import { normalizeResp, normalizeNodesResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import HeatmapWidget from '~/components/heatmap_widget';
import HeatmapWidgetTitle from '~/components/heatmap_widget/title';
import { fetchNodesSuccess } from '~/store/reducers/nodes';
import faceTemplate from './templates/face.hbs';

const HeatmapWidgetContentView = View.extend({
  template: faceTemplate,

  ui: {
    heatmapContainer: '.heatmap-container'
  },

  onBeforeDetach() {
    return unmountComponentAtNode(this.ui.heatmapContainer[0]);
  },

  initialize() {
    let params = [];
    if (!_.contains(this.model.get('node_ids'), 'all')) {
      params = this.model.get('node_ids');
    }
    this.listenTo(this.model, 'change:node_ids', async () => {
      App.getChannel().request('when:fetched', thiamisApi.getChannel().request('fetch:thiamis', params, null, { parse: false }), (data) => {
        const response = normalizeResp(normalizeNodesResp(data), schema.nodesListSchema);
        reduxStore.dispatch(fetchNodesSuccess(response));
      });
    });
    App.getChannel().request('when:fetched', thiamisApi.getChannel().request('fetch:thiamis', params, null, { parse: false }), (data) => {
      const response = normalizeResp(normalizeNodesResp(data), schema.nodesListSchema);
      reduxStore.dispatch(fetchNodesSuccess(response));
      this.model.getUserDataPointsColl();
    });
  },

  getHeatmapComponent() {
    return (
      <Provider store={reduxStore}>
        <HeatmapWidget nodeIds={this.model.get('node_ids')} dataPointIds={this.model.get('selected_data_point_ids')} widgetId={this.model.id} />
      </Provider>
    );
  },

  getWidgetTitle() {
    return (
      <Provider store={reduxStore}>
        <HeatmapWidgetTitle widgetId={this.model.id} />
      </Provider>
    );
  },

  onRender() {
    ReactDOM.render(this.getHeatmapComponent(), this.ui.heatmapContainer[0]);
  }
});

export default HeatmapWidgetContentView;
