import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useFormState, Field as FinalField } from 'react-final-form';
import { getManagedOrganizationsValueLabelPairsWithExternalId } from '~/store/selectors/organizations';
import useValidation from '~/hooks/useValidation';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const { validateRequired } = useValidation();
  const organizationOptions = useSelector(getManagedOrganizationsValueLabelPairsWithExternalId);
  const { hasValidationErrors } = useFormState();

  return (
    <div className="form form-horizontal profile">
      <div className="form-region form-margins">
        <FinalField
          name="organization_id"
          validate={validateRequired}
        >
          {
            ({ input }) => (
              <div className={classNames('form-group')}>
                <label className="col-sm-4">
                  {t('partnership_invitations.inviting_org_label')}
                </label>
                <div className="col-sm-8">
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    value={input.value}
                    isSearchable
                    onChange={input.onChange}
                    options={organizationOptions}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
        <FinalField
          name="external_id"
          validate={validateRequired}
        >
          {
            ({ input }) => (
              <div className={classNames('form-group')}>
                <label className="col-sm-4">
                  {t('partnership_invitations.invited_org_label')}
                </label>
                <div className="col-sm-8">
                  <CreatableSelect
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    isSearchable
                    isClearable
                    placeholder="Select or enter..."
                    value={input.value}
                    onChange={input.onChange}
                    options={organizationOptions}
                  />
                  <p className="help-block">Choose organization or enter external id, ex. AC112233</p>
                </div>
              </div>
            )
          }
        </FinalField>
      </div>
      <div className="form-group form-actions clearfix">
        <button type="button" className="btn btn-default" onClick={handleCancel}>
          {t('base.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="save-form btn btn-primary"
          disabled={hasValidationErrors}
          onClick={handleSubmit}
        >
          {t('partnership_invitations.create_partnership')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
