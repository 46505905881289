import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const withSelfUpdatable = (WrappedComponent) => {
  class SelfUpdatableComponent extends Component {
    componentDidMount() {
      // eslint-disable-next-line react/no-unused-state
      return this.interval = setInterval(() => this.setState({ updated: Date.now() }),
        this.props.interval);
    }

    componentWillUnmount() {
      return clearInterval(this.interval);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  SelfUpdatableComponent.propTypes = {
    interval: PropTypes.number
  };

  SelfUpdatableComponent.defaultProps = {
    interval: 15000
  };

  return SelfUpdatableComponent;
};

export const withTooltip = (WrappedComponent) => {
  class TooltipComponent extends Component {
    componentDidMount() {
      $(this.tooltipEl)
        .on('click', () => $(this.tooltipEl).tooltip('hide'))
        .tooltip({
          html: true,
          title: this.props.title,
          container: 'body'
        });
    }

    componentDidUpdate() {
      $(this.tooltipEl)
        .attr('data-original-title', this.props.statusText)
        .tooltip('fixTitle');
    }

    render() {
      return <WrappedComponent tooltipRef={(tooltipEl) => this.tooltipEl = tooltipEl} {...this.props} />;
    }
  }

  TooltipComponent.defaultProps = {
    statusText: '',
    title: ''
  };

  TooltipComponent.propTypes = {
    title: PropTypes.string,
    statusText: PropTypes.string
  };

  return TooltipComponent;
};

export default withTooltip;
