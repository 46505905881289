/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const fetchPartnershipsRequest = createAction('@@partnerships/FETCH_REQUEST');
export const fetchPartnershipsSuccess = createAction('@@partnerships/FETCH_SUCCESS');
export const fetchPartnershipsFailure = createAction('@@partnerships/FETCH_FAILURE');

export const destroyPartnershipRequest = createAction('@@partnerships/DELETE_REQUEST');
export const destroyPartnershipSuccess = createAction('@@partnerships/DELETE_SUCCESS');
export const destroyPartnershipFailure = createAction('@@partnerships/DELETE_FAILURE');

export const createPartnershipRequest = createAction('@@partnerships/CREATE_REQUEST');
export const createPartnershipSuccess = createAction('@@partnerships/CREATE_SUCCESS');
export const createPartnershipFailure = createAction('@@partnerships/CREATE_FAILURE');

export const fetchPartnershipDetailsRequest = createAction('@@partnerships/FETCH_DETAILS_REQUEST');
export const fetchPartnershipDetailsSuccess = createAction('@@partnerships/FETCH_DETAILS_SUCCESS');
export const fetchPartnershipDetailsFailure = createAction('@@partnerships/FETCH_DETAILS_FAILURE');

const byIdInitial = {};
const byId = createReducer(byIdInitial, {
  [fetchPartnershipsSuccess](state, { payload }) {
    const { entities: { partnerships } } = payload;
    return partnerships || state;
  },
  [destroyPartnershipSuccess](state, { payload: partnershipId }) {
    delete state[partnershipId];
    return state;
  },
  [fetchPartnershipDetailsSuccess](state, { payload: { partnershipId, sharedDevices, leasedDevices } }) {
    if (state[partnershipId]) {
      state[partnershipId].isSyncing = false;
      state[partnershipId].isSynced = true;
      state[partnershipId].sharedDevices = sharedDevices;
      state[partnershipId].leasedDevices = leasedDevices;
    }
  },
  [fetchPartnershipDetailsRequest](state, { payload: { partnershipId } }) {
    if (state[partnershipId]) {
      state[partnershipId].isSynced = false;
      state[partnershipId].isSyncing = true;
    }
  },
  [fetchPartnershipDetailsFailure](state, { payload: { partnershipId } }) {
    if (state[partnershipId]) {
      state[partnershipId].isSyncing = false;
      state[partnershipId].isSynced = true;
    }
  }
});

const isFetching = createReducer(false, {
  [fetchPartnershipsRequest]() {
    return true;
  },
  [fetchPartnershipsFailure]() {
    return false;
  },
  [fetchPartnershipsSuccess]() {
    return false;
  },
  [createPartnershipRequest]() {
    return true;
  },
  [createPartnershipSuccess]() {
    return false;
  },
  [createPartnershipFailure]() {
    return false;
  },
  [destroyPartnershipRequest]() {
    return true;
  },
  [destroyPartnershipSuccess]() {
    return false;
  },
  [destroyPartnershipFailure]() {
    return false;
  }
});

const isFetched = createReducer(false, {
  [fetchPartnershipsRequest]() {
    return false;
  },
  [fetchPartnershipsFailure]() {
    return false;
  },
  [fetchPartnershipsSuccess]() {
    return true;
  }
});

const partnerships = combineReducers({
  byId,
  isFetched,
  isFetching
});

export const getPartnerships = (state) => state.partnerships.byId;
export const getPartnershipById = (state, id) => state.partnerships.byId[id];
export const getPartnershipsIsFetched = (state) => state.partnerships.isFetched;
export const getPartnershipIsFetching = (state) => state.partnerships.isFetching;
export const getPartnershipByIdIsSyncing = (state, id) => state.partnerships.byId[id]?.isSyncing;
export const getPartnershipByIdIsSynced = (state, id) => state.partnerships.byId[id]?.isSynced;
export const getPartnershipByIdSharedDevices = (state, id) => state.partnerships.byId[id]?.sharedDevices;
export const getPartnershipByIdLeasedDevices = (state, id) => state.partnerships.byId[id]?.leasedDevices;

export default partnerships;
