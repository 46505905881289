/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const filterListByQuery = createAction('@@webhooksList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@webhooksList/CLEAR_FILTERS');

const initialFilter = { query: null };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  }
});

const webhooksList = combineReducers({
  filter
});

export const getFilter = (state) => state.webhooksList.filter;

export default webhooksList;
