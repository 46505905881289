/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Highcharts from 'highcharts/es-modules/masters/highstock.src';
import { View } from 'backbone.marionette';
import moment from 'app/config/moment';
import I18n from 'app/config/i18n';
import { isMobile } from 'app/utils/resolution_helpers';
import defaultsDeep from 'lodash/defaultsDeep';

export default View.extend({

  id() { return `chart-wrapper-${this.cid}`; },

  template: false,

  isRenderingAxis: true,

  initialize({ chartOptions }) {
    window.moment = moment;
    this.chartOptions = defaultsDeep(chartOptions, this.getChartDefaults());
    if (isMobile()) {
      this.chartOptions.navigator = false;
      this.chartOptions.rangeSelector = false;
      return this.chartOptions.scrollbar = false;
    }
  },

  addSeriesToChart(dataPoint) {
    let options = {
      name: dataPoint.presentName(),
      id: dataPoint.getChartSeriesId(),
      data: this.getData(dataPoint),
      yAxis: (this.isRenderingAxis ? dataPoint.getChartAxisId() : undefined),
      pointStart: this.getPeriodFrom(),
      pointInterval: this.getPointInterval(),
      dataPointId: dataPoint.id,
      round: dataPoint.get('round'),
      color: this.getColor(),
      unit: dataPoint.get('unit')
    };
    if (this.model.get('ordinal') === false) {
      options = _.extend(options, { ordinal: false, gapSize: 5 });
    }
    return this.getHighchart().addSeries(options);
  },

  addAxis(dataPoint) {
    const axisId = dataPoint.getChartAxisId();
    const options = {
      id: axisId,
      title: {
        text: null
      },
      opposite: false,
      labels: {
        x: -15,
        useHtml: true,
        formatter() {
          return `<tspan style="color:${this.axis.series[0].color}">${this.value}</tspan>`;
        }
      }
    };
    return this.getHighchart().addAxis(options);
  },

  getChartDefaults() {
    let options = {
      title: {
        text: null
      },
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          position: {
            x: -10,
            y: -10
          }
        },
        marginTop: 40,
        defaultSeriesType: 'spline',
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
      },
      colors: ['#cc4f4f', '#f7a865', '#49B96D', '#5649b7', '#b5af48', '#48b5b1'],
      plotOptions: {
        series: {
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          }
        },
        spline: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        minRange: 1000
      },
      legend: {
        enabled: true
      },
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false,
        outlineWidth: 0
      },
      scrollbar: {
        enabled: false,
        barBackgroundColor: '#DCDCDD',
        barBorderRadius: 5,
        barBorderWidth: 0,
        buttonBackgroundColor: 'white',
        buttonBorderWidth: 0,
        buttonArrowColor: '#DCDCDD',
        buttonBorderRadius: 7,
        rifleColor: 'white',
        trackBackgroundColor: 'white',
        trackBorderWidth: 0,
        trackBorderColor: 'white',
        trackBorderRadius: 7
      },
      lang: {
        noData: I18n.t('dashboard.chart_no_data'),
        resetZoom: I18n.t('chart_widget.reset_selected_range'),
        groupAxis: I18n.t('chart_widget.group_axis'),
        loading: I18n.t('base.labels.loading')
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#444'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        useHTML: true,
        headerFormat: '<table style="border-spacing: 2px;border-collapse: separate;"><tr><td colSpan="2"><small>{point.key}</small></td></tr>',
        pointFormatter() {
          const precision = this.series.options.round || _.getAvgDecimalsPoint(_.last(this.series.yData, 5));
          let value = this.y;
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          if (decimalPlaces > 4) { value = _.round(value, precision); }
          return `<tr> \
<td style="color: ${this.series.color}">${this.series.name}: </td> \
<td><b>${value}</b></td> \
</tr>`;
        },
        footerFormat: '</table>',
        xDateFormat: '%a, %e of %B, %H:%M:%S'
      }
    };
    if (this.model.get('ordinal') === false) {
      options = { ...options, xAxis: { ...options.xAxis, ordinal: false, gapSize: 5 } };
    }
    return options;
  },

  getChartOptions() {
    return this.chartOptions;
  },

  getProcessedData(dataPoint) {
    const series = this.getSeries(dataPoint);
    if (!series) { return; }
    const extremes = this.getHighchart().xAxis[0].getExtremes();
    const fromIndex = _.sortedIndex(series.xData, extremes.min);
    const toIndex = _.sortedIndex(series.xData, extremes.max);
    return series.yData.slice(fromIndex, toIndex);
  },

  getSeries(dataPoint) {
    return this.getHighchart().get(dataPoint.getChartSeriesId());
  },

  getHighchart() {
    if (this.highchart) { return this.highchart; }
    return this.highchart = this.buildHighchart();
  },

  buildHighchart() {
    return Highcharts.stockChart(this.id(), this.getChartOptions());
  },

  renderChart() {
    return this.highchart = this.buildHighchart();
  },

  onBeforeDetach() {
    return this.getHighchart().destroy();
  },

  isChartDestroyed() {
    return _.isUndefined(this.getHighchart().axes);
  },

  renderSeries() {
    return this.collection.each(this.addSeries, this);
  },

  getPointInterval() {
    const timePeriod = this.model.get('time_period');
    if (timePeriod === 'live') {
      return 1000;
    }
    if (_.contains(['today', 'yesterday'], timePeriod)) {
      return 5000;
    }
    return 360000;
  },

  groupAxisByUnit(options) {
    // eslint-disable-next-line no-param-reassign
    options = _.defaults(options, { redraw: true });
    const visibleSeries = this.getVisibleSeries();
    const groupedSeries = this.getGroupedSeries(visibleSeries);
    const hasGroupedSeries = this.hasGroupedSeries(visibleSeries);
    const showGroupedAxis = this.model.get('yaxis_grouped') && hasGroupedSeries;
    if (!this.getHighchart().get('grouped')) {
      this.getHighchart().addAxis({
        id: 'grouped',
        visible: false,
        title: {
          text: null
        },
        opposite: false,
        labels: {
          x: -15
        }
      });
    }

    if (showGroupedAxis) {
      _.each(groupedSeries, (series) => {
        if (series.length > 1) {
          return _.each(series, (serie) => serie.update({ yAxis: 'grouped' }, false));
        }
      });
    } else {
      _.each(visibleSeries, (series) => series.update({ yAxis: `${series.options.dataPointId}-axis` }, false));
    }

    this.triggerMethod('show:group:btn', hasGroupedSeries);
    this.getHighchart().get('grouped').update({ visible: showGroupedAxis }, false);
    if (options.redraw) { return this.getHighchart().redraw(); }
  },

  toggleOrdinal(options) {
    const { ordinal } = options;
    if (ordinal) {
      this.resetSeries();
      return this.getHighchart().xAxis[0].update(options);
    }
    _.each(this.getVisibleSeries(), (series) => series.update(options, false));
    this.getHighchart().xAxis[0].update(options, false);
    this.getHighchart().redraw();
  },

  getYAxis(dataPoint) {
    return this.getHighchart().get(dataPoint.getChartAxisId());
  },

  getColor() {
    return _.difference(this.getChartOptions().colors, _.pluck(this.getVisibleSeries(), 'color'))[0];
  },

  getVisibleSeries() {
    return _.filter(this.getHighchart().series, (series) => series.name !== 'Navigator');
  },

  hasGroupedSeries(series) {
    return _.some(this.getGroupedSeries(series), (_series) => _series.length > 1);
  },

  getGroupedSeries(series) {
    return _.groupBy(series, (serie) => serie.options.unit);
  },

  getData(dataPoint) {
    return dataPoint.get('measurements');
  },

  getPeriodFrom() {
    return this.model.getPeriodFrom();
  },

  setExtremes({ min, max }, redraw = false) {
    return this.getHighchart().xAxis[0].setExtremes(min, max, redraw);
  }
});
