import React, {
  useCallback, useMemo, useContext, useEffect
} from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import { fetchPartnershipsRequest, getPartnershipsIsFetched } from 'app/react/store/reducers/partnerships';
import Modal from '~/components/modal';
import Spinner from '~/components/spinner';
import { getNodeById, getIsUpdating, getUpdateError } from '~/store/reducers/nodes';
import { modalActionRequest } from '../../../reducer';
import ActionsModalForm from './actions_modal_form';
import ManageDevicesContext from '../../../manage_context';

const ActionsModal = ({ type, toggleModal }) => {
  const { selectedDevices, setSelectedDevices, setIsActive } = useContext(ManageDevicesContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    deviceOrganizations,
    isFetched,
    isUpdating,
    updateError
  } = useSelector((state) => {
    const nodes = selectedDevices.map((deviceId) => getNodeById(state, deviceId));
    const nodeOrganizations = nodes.map((node = {}) => node.organization_id);
    return {
      deviceOrganizations: nodeOrganizations,
      isFetched: getPartnershipsIsFetched(state),
      isUpdating: getIsUpdating(state),
      updateError: getUpdateError(state)
    };
  });

  const shouldFetchPartnerships = useMemo(() => includes(['share', 'lease', 'remove_share', 'remove_lease'], type), [type]);

  useEffect(() => {
    if (shouldFetchPartnerships) {
      if (!deviceOrganizations || !deviceOrganizations.length) {
        return;
      }
      dispatch(fetchPartnershipsRequest({ data: { partnership_ids: uniq(deviceOrganizations) } }));
    }
    // !Important:
    // deviceOrganizations, which is dependant on selectedDevices, will always be a new
    // array while selectedDevices will not, so it is desired behavior to trigger this hook only
    // when selectedDevices list has been changed.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDevices, shouldFetchPartnerships]);

  const handleSuccess = useCallback(() => {
    toast.success(
      t('thiamis.manage_dialog.success', { action: t(`thiamis.manage_actions.${type}_past`) }),
      {
        toastId: `success_${type}`,
        position: toast.POSITION.TOP_CENTER
      }
    );
    setSelectedDevices([]);
    setIsActive(false);
    toggleModal();
  }, [t, type, setSelectedDevices, setIsActive, toggleModal]);

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);
  const handleAction = useCallback((values) => {
    const { selectedOrganization, disabledNodes } = values;
    const allowedNodes = difference(selectedDevices, disabledNodes);
    allowedNodes.forEach((nodeId) => {
      dispatch(modalActionRequest({
        type, nodeId, orgId: selectedOrganization?.value, onSuccess: handleSuccess
      }));
    });
  }, [dispatch, type, selectedDevices, handleSuccess]);

  return (
    <Modal
      isVisible={!!type}
      handleCancel={handleCancel}
      title="Manage Thiamis"
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleAction}
          initialValues={{ disabledNodes: [], selectedOrganization: null }}
          render={({ handleSubmit, dirty }) => (
            <div className="form">
              {
                (shouldFetchPartnerships && !isFetched)
                  ? <Spinner className="react-modal-spinner" />
                  : (
                    <ActionsModalForm type={type} />
                  )
              }
              <div className="form-group form-actions clearfix">
                <button type="button" className="btn btn-default" onClick={handleCancel}>
                  {t('base.buttons.cancel')}
                </button>
                <button
                  className="save-form btn btn-primary"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={!updateError && ((shouldFetchPartnerships && !dirty) || isUpdating)}
                >
                  {t(`thiamis.manage_actions.${type}`)}
                </button>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default ActionsModal;
