import styled from 'styled-components';
import {
  space,
  size,
  flexbox,
  border,
  grid,
  layout,
  gridGap,
  width,
  height,
  color,
  shadow,
  fontSize,
  textAlign,
  fontWeight,
  background
} from 'styled-system';
import { space as themedSpace } from '~/theme/space';

export const clearLast = `
  &:last-of-type{
    margin-right: 0;
    margin-bottom: 0
  }
`;

export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  ${space}
  ${size}
  ${border}
  ${flexbox}
  ${width}
  ${height}
  ${color}
  ${shadow}
  ${background}
`;

export const FlexWrap = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-wrap: wrap;
  ${space}
  ${size}
  ${border}
  ${flexbox}
  ${width}
  ${height}
  ${color}
  ${shadow}
  ${background}
`;

export const Grid = styled(Flex)`
  display: grid;
  box-sizing: border-box;
  ${grid}
  ${gridGap}
  ${space}
  ${size}
  ${border}
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Image = styled.img`
  ${space}
  ${width}
`;

export const Text = styled(Flex)`
  ${fontSize}
  ${fontWeight}
  ${textAlign}
`;

export const Title = styled(Text).attrs({
  fontSize: 'formTitle',
  color: 'black'
})`
  font-weight: bold;
`;

export const Button = styled(Flex).attrs({
  as: 'button',
  bg: 'white'
})`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.midGrey};
  border-radius: 4px;
  cursor: pointer;
`;

export const Well = styled(Column)`
  margin: 0 auto;
  width: 100%;
`;

export const Elevated = styled(Column).attrs({
  p: space(4),
  bg: 'white'
})`
  padding: 40px;
  box-shadow: 0px ${themedSpace(0.5)} ${themedSpace()} rgba(0, 0, 0, 0.1);
`;

export const TwoColumns = styled(Grid).attrs({
  width: '100%'
})`
  grid-gap: ${({ gap = themedSpace(2) }) => gap};
  grid-template-columns: repeat(2, 1fr);
`;

export const FormContainer = styled(Column).attrs({
  p: themedSpace(4),
  bg: 'midGrey'
})``;

export const Label = styled(Text).attrs({
  fontSize: 'fineprint',
  fontWeight: 'normal',
  color: 'hsl(0,0%,20%)'
})``;

export const Placeholder = styled(Text).attrs({
  fontSize: 'fineprint',
  fontWeight: 'normal',
  color: 'hsl(0,0%,50%)'
})``;

export const Subtext = styled(Text).attrs({
  fontSize: 'small',
  marginLeft: themedSpace(0.5),
  color: 'grey'
})``;

export const Icon = styled.i.attrs(({ icon, iconGroup = '' }) => ({
  className: `fa${iconGroup} fa-${icon}`
}))`
  ${fontSize}
  ${space}
  ${layout}
  ${color}
`;
