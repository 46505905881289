/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppRouter from 'app/backbone/lib/routers/app_router';
import { app as App } from 'app/backbone/app';
import { MnObject } from 'backbone.marionette';
import { api as orgApi } from 'app/backbone/entities/organization';
import { api as userApi } from 'app/backbone/entities/user';
import { api as countriesApi } from 'app/backbone/entities/abstract/countries';
import { api as timezonesApi } from 'app/backbone/entities/abstract/timezones';
import { api as projectApi } from 'app/backbone/entities/project';

class Router extends AppRouter {
  initialize() {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.appRoute(/^users\/new$/, 'newUser');
    return this.appRoute(/^users\/(.+)$/, 'showUser');
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'user');
  }
}

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:users': 'listUsers',
    'app:users:show': 'showUser',
    'app:users:new': 'newUser'
  },

  listUsers() {
    Router.prototype.before();
    App.navigate('users');
    App.getChannel().request('enable:org_filter', true);
  },

  showUser(userId) {
    App.navigate(`users/${userId}`);
  },

  newUser() {
    App.navigate('users/new');
  }
});

const controller = {
  showUser(id) {
    const orgs = orgApi.getChannel().request('fetch:organizations:list');
    const promise = import('app/backbone/apps/users/show/show_controller');
    promise.then((UserShowController) => {
      // eslint-disable-next-line new-cap
      const showController = new UserShowController.default({
        model: userApi.getChannel().request('fetch:user', id),
        phonePrefixes: countriesApi.getChannel().request('get:phone_prefixes'),
        timeZones: timezonesApi.getChannel().request('get:timezones'),
        locales: App.getChannel().request('get:locales'),
        countries: countriesApi.getChannel().request('get:countries'),
        organizations: orgs,
        projects: projectApi.getChannel().request('fetch:projects'),
        memberedOrgs: orgApi.getChannel().request('get:membered:organizations', orgs),
        usStates: countriesApi.getChannel().request('get:us_states'),
        currentUser: App.getChannel().request('get:current:user')
      });
      return showController.showUser(id);
    });
    return promise;
  },

  newUser() {
    const orgs = orgApi.getChannel().request('fetch:organizations:list');
    const promise = import('app/backbone/apps/users/show/show_controller');
    promise.then((UserShowController) => {
      // eslint-disable-next-line new-cap
      const showController = new UserShowController.default({
        model: userApi.getChannel().request('new:user'),
        phonePrefixes: countriesApi.getChannel().request('get:phone_prefixes'),
        timeZones: timezonesApi.getChannel().request('get:timezones'),
        locales: App.getChannel().request('get:locales'),
        organizations: orgs,
        memberships: orgApi.getChannel().request('get:membered:organizations', orgs)
      });
      return showController.showUser();
    });
    return promise;
  }
};

export const api = new API();

const UsersApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default UsersApp;
