import React from 'react';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { useDispatch } from 'react-redux';
import TimeAgo from 'react-timeago';
import I18n from 'app/config/i18n';
import moment from 'moment';
import Switch from '~/components/form/switch';
import {
  toggleWebhookRequest
} from '~/store/reducers/webhooks';

export const EnabledFormatter = ({ value, row }) => {
  const dispatch = useDispatch();
  const onChangeEnabled = () => {
    dispatch(toggleWebhookRequest({
      id: row._id
    }));
  };

  return (
    <Switch value={value} onChange={onChangeEnabled} />
  );
};

export const EnabledProvider = (props) => (
  <DataTypeProvider
    formatterComponent={EnabledFormatter}
    {...props}
  />
);

export const LastUpdatedFormatter = ({ value }) => (value && moment(value).isValid()
  ? <TimeAgo date={value} />
  : I18n.t('alerts.triggered_never'));

export const LastUpdatedProvider = (props) => (
  <DataTypeProvider
    formatterComponent={LastUpdatedFormatter}
    {...props}
  />
);

export const LastResultFormatter = ({ value }) => (value ? `http: ${value}` : '-');

export const LastResultProvider = (props) => (
  <DataTypeProvider
    formatterComponent={LastResultFormatter}
    {...props}
  />
);
