import React, { useMemo } from 'react';
import PhoneInput from 'react-phone-number-input';
import colors from '~/theme/colors';

const Input = React.memo(({
  type = 'input', hasError, ...restProps
}) => {
  const InputComponent = useMemo(() => {
    switch (type) {
      case 'phone':
        return PhoneInput;
      case 'textarea':
        return 'textarea';
      default:
        return 'input';
    }
  }, [type]);
  return (
    <InputComponent
      {...restProps}
      style={{
        color: colors.greyColor,
        borderColor: hasError ? colors.redColor : colors.inputBorder
      }}
    />
  );
});

export default Input;
