/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import NodeWidgetFetchThiamisesMixin from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamises';
import { ChartEmptyDataPointsFilter } from 'app/backbone/lib/components/shared_views/widget_node_select';
import WidgetDataPointsSelectMixin from 'app/backbone/lib/concerns/views/widget_data_points_select';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import confTemplate from './templates/conf.hbs';
import nodesSelectTemplate from './templates/nodes_select.hbs';
import dataPointSelectTemplate from './templates/data_point_select.hbs';

export const NodeSelect = View.extend({
  template: nodesSelectTemplate,

  initialize({ thiamises }) {
    this.thiamises = thiamises;
  },

  bindings: {
    '.config-node_ids': {
      observe: 'node_ids',
      selectOptions: {
        collection() {
          const collection = _(this.getFilteredMonitorableThiamises().map((node) => ({ label: node.presentName(), value: node.id }))).sortBy('label');
          collection.unshift({
            label: I18n.t('base.labels.all'),
            value: 'all'
          });
          return collection;
        }
      },
      select2Options: {
        templateResult(data, container) {
          if (data.id === 'all') {
            $(container).addClass('all_option');
          }
          return data.text;
        }
      }
    }
  },

  getFilteredMonitorableThiamises() {
    if (this.filteredMonitorableThiamises) { return this.filteredMonitorableThiamises; }
    this.filteredMonitorableThiamises = new FilteredCollection(this.thiamises);
    this.filteredMonitorableThiamises.filterBy('read', (thiamis) => thiamis.isMonitorable() && thiamis.isAirthinx());
    return this.filteredMonitorableThiamises;
  },

  onRender() { return this.stickit(); }
});

export const DataPointsSelect = View.extend({
  template: dataPointSelectTemplate,
  className: 'data_points_filter_wrap',

  ui: {
    dataPointsFilter: '.config-data_points_filter'
  },

  bindings: {
    '@ui.dataPointsFilter': {
      observe: 'selected_data_point_ids',
      onGet(ids = []) {
        const result = ids.map((id) => {
          const dp = this.collection.find((model) => _.contains(model.get('ids'), id));
          if (dp) {
            return dp.get('_id');
          }
        });
        const path = _.chain(result).compact().first().value();
        this.model.set('path', path);
        return path;
      },
      onSet(path) {
        this.model.set('path', path);
        const dp = this.collection.findWhere({ _id: path });
        if (dp) {
          return dp.get('ids');
        }
      },
      selectOptions: {
        collection() {
          const collection = this.collection.reduce((memo, dp) => {
            const { device, path } = dp.parsePath();
            const id = `${device}:${path}`;
            memo[id] = { label: dp.presentName(), value: id }; // eslint-disable-line no-param-reassign
            return memo;
          }, {});
          return _(Object.values(collection)).sortBy('label');
        },
        defaultOption: {
          label: 'Choose...',
          value: null
        }
      }
    }
  },

  onRender() {
    this.stickit();
  }
});

const HeatmapWidgetConfigView = View.extend({
  behaviors: [NodeWidgetFetchThiamisesMixin, WidgetDataPointsSelectMixin],
  template: confTemplate,
  ui: {
    loading: '.loading-overlay',
    titleInput: 'input[name="title"]'
  },
  regions: {
    dataPointsFilterRegion: '.datapointsselector-region',
    nodeSelectRegion: '.nodeselect-region'
  },

  dataPointsFilterClass: DataPointsSelect,
  emptyDataPointsFilterClass: ChartEmptyDataPointsFilter,

  bindings: {
    '@ui.titleInput': 'name'
  },

  showNodeSelect() {
    return this.getRegion('nodeSelectRegion').show(this.getNodeSelect());
  },

  getNodeSelect() {
    return this.nodeSelect != null ? this.nodeSelect : (this.nodeSelect = new NodeSelect(_(this).pick('model', 'thiamises')));
  },

  onRender() {
    App.getChannel().request('when:fetched', this.thiamises, () => {
      this.showNodeSelect();
    });
    this.stickit();
  }
});

export default HeatmapWidgetConfigView;
