/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const modalActionRequest = createAction('@@alerts/MODAL_ACTION_REQUEST');
export const modalActionFailure = createAction('@@alerts/MODAL_ACTION_FAILURE');

export const filterListByQuery = createAction('@@alertsList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@alertsList/CLEAR_FILTERS');
export const filterListByTag = createAction('@@alertsList/FILTER_BY_TAG');
export const expandList = createAction('@@alertsList/EXPAND_ROW');

const initialFilter = { query: null, tags: [] };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  },
  [filterListByTag](state, { payload }) {
    state.tags = payload;
  }
});

const alertsList = combineReducers({
  filter
});

export const getFilter = (state) => state.alertsList.filter;

export default alertsList;
