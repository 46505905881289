import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, trim } from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { emailIsValid, urlIsValid } from '../utils/helpers';

const useValidation = () => {
  const { t } = useTranslation();

  const validateRequired = useCallback((value) => {
    if (isEmpty(value)) {
      return t('validation.errors.required');
    }
    return null;
  }, [t]);

  const validatePhone = useCallback((value) => {
    if (isEmpty(value)) {
      return t('validation.errors.required');
    }
    if (!isValidPhoneNumber(value)) {
      return t('validation.errors.phone');
    }
    return null;
  }, [t]);

  const validateEmail = useCallback((value) => {
    if (isEmpty(value)) {
      return t('validation.errors.required');
    }
    if (!emailIsValid(trim(value))) {
      return t('validation.errors.email');
    }
    return null;
  }, [t]);

  const validateUrl = useCallback((value) => {
    if (isEmpty(value)) {
      return t('validation.errors.required');
    }
    if (!urlIsValid(trim(value))) {
      return t('validation.errors.url');
    }
    return null;
  }, [t]);

  return {
    validateRequired,
    validatePhone,
    validateEmail,
    validateUrl
  };
};

export default useValidation;
