export default function mapAddress(address, address_type) {
  const mapped = {};
  Object.keys(address).forEach((key) => {
    const value = address[key];
    switch (key) {
      case 'country':
        mapped[`${address_type}_country`] = value.value;
        break;
      case 'state':
        if (address.country.value === 'US') {
          mapped[`${address_type}_state`] = value.value;
        }
        break;
      case 'state_other':
        if (address.country.value !== 'US') {
          mapped[`${address_type}_state`] = value;
        }
        break;
      default:
        mapped[`${address_type}_${key}`] = value;
        break;
    }
  });
  return mapped;
}
