import React from 'react';
import { connect } from 'react-redux';
import { ALERT_STATUSES } from '~/store/selectors/alerts';
import { getAlertStatusFilterTags } from '../selectors';
import FiltersSidebarPanel from './filters_sidebar_panel';

const statesOrder = [ALERT_STATUSES.ACTIVE, ALERT_STATUSES.RESOLVED, ALERT_STATUSES.IDLE, ALERT_STATUSES.DEACTIVATED];

const FiltersSidebar = React.memo((props) => {
  const { states } = props;
  return (
    <div className="filters-region right-panel">
      <div className="fill-height">
        <div className="filter-groups-list-region">
          <div id="filters-wrap" className="panel-group collapse in" aria-expanded="true">
            <FiltersSidebarPanel filters={states} group="alerts" order={statesOrder} collapsedIn />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  states: getAlertStatusFilterTags(state)
});

export default connect(mapStateToProps)(FiltersSidebar);
