/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppRouter from 'app/backbone/lib/routers/app_router';
import reduxHistory from '~/store/history';
import Controller from './controller';

class Router extends AppRouter {
  initialize({ controller }) {
    this.controller = controller;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.appRoute(/^react-analytics$/, 'nothing');
    this.appRoute(/^certificates$/, 'nothing');
    this.appRoute(/^certificates\/create$/, 'nothing');
    this.appRoute(/^certificates\/(\w+)$/, 'nothing');
    this.appRoute(/^alerts\/(\w+)\/monitor$/, 'nothing');
    this.appRoute(/^api$/, 'nothing');
    this.appRoute(/^users$/, 'nothing');
    this.appRoute(/^devices$/, 'nothing');
    this.appRoute(/^alerts$/, 'nothing');
    this.appRoute(/^organizations$/, 'nothing');
    this.appRoute(/^organizations$\/new$/, 'nothing');
    this.appRoute(/^organizations$\/(\w+)$/, 'nothing');
    this.appRoute(/^partnerships$/, 'nothing');
    this.appRoute(/^files$/, 'nothing');
    this.appRoute(/^firmware$/, 'nothing');
    this.appRoute(/^calibration$/, 'nothing');
    this.appRoute(/^react-dashboard$/, 'nothing');
    this.appRoute(/^profile$/, 'nothing');
    this.appRoute(/^users$\/(\w+)$/, 'nothing');
  }

  onRoute(name, path, args) {
    AppRouter.prototype.onRoute(name, path, args);
    reduxHistory.push(`/${path}`);
  }
}

const controller = {
  nothing() {
    const empty = new Controller();
    empty.showPageView();
  }
};

const BackboneToReactApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default BackboneToReactApp;
