import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  RowDetailState,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import { api as countriesApi } from 'app/backbone/entities/abstract/countries';
import {
  fetchOrganizationsRequest, getOrganizationsIsFetched
} from '~/store/reducers/organizations';

import Spinner from '~/components/spinner';
import { getFilteredList } from '../selectors';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from '~/components/react_grid/expand_button';
import GridDetailContainer from './detail_row';
import useWindowSize from '~/hooks/useWindowSize';
import {
  CreatedAtProvider,
  AvatarProvider,
  NameProvider
} from './info_providers';

function compareDates(a, b) {
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'avatar', title: ' ', sortingEnabled: false },
  { name: 'name', title: 'Name' },
  {
    name: 'contact_full_name',
    sortingEnabled: false,
    title: ' '
  },
  {
    name: 'location',
    title: 'Location',
    getCellValue({ shipping_address_country: organizationCountry, shipping_address_city: shippingAddressCity }) {
      let countryName = countriesApi.getChannel().request('get:country', organizationCountry)?.name;
      if (organizationCountry === 'US') { countryName = organizationCountry; }
      return shippingAddressCity && countryName && `${shippingAddressCity}, ${countryName}`;
    }
  },
  { name: 'created_at', title: 'Registered' }
];

const getRowId = (row) => row._id; // eslint-disable-line no-underscore-dangle

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = (props) => {
  const {
    handleFetchOrganizationsRequest, rows, isFetched
  } = props;
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'name', direction: 'asc' }]);
  const size = useWindowSize();

  const Row = React.memo((row) => {
    const { row: { _id: organizationId } } = row;
    const isExpanded = includes(expandedRowIds, organizationId);

    const handleExpandRowClick = useCallback(() => {
      if (includes(expandedRowIds, organizationId)) {
        setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== organizationId));
      } else {
        setExpandedRowIds([...expandedRowIds, ...[organizationId]]);
      }
    }, [organizationId]);
    return (
      <VirtualTable.Row
        {...row}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded })}
      />
    );
  });

  useEffect(() => {
    handleFetchOrganizationsRequest();
  }, [handleFetchOrganizationsRequest]);

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  if (!isFetched) {
    return (
      <Spinner />
    );
  }

  return (
    <div className="list-table organizations-list-table react-organizations-list" style={{ height: `${size.height - 125}px` }}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        rootComponent={Root}
      >
        <SortingState
          sorting={sorting}
          onSortingChange={handleSortingChange}
        />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'created_at', compare: compareDates }
          ]}
        />
        <AvatarProvider for={['avatar']} />
        <CreatedAtProvider for={['created_at']} />
        <NameProvider for={['name']} />
        <VirtualTable
          rowComponent={Row}
          columnExtensions={[
            {
              columnName: 'avatar',
              width: 65
            }
          ]}
        />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <TableRowDetail
          contentComponent={GridDetailContainer}
          toggleCellComponent={ExpandButton}
        />
        <DetailRowColumnOrder />
        <TableHeaderRow
          align="center"
          showSortingControls
        />
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rows: getFilteredList(state),
  isFetched: getOrganizationsIsFetched(state)
});

export default connect(mapStateToProps, {
  handleFetchOrganizationsRequest: fetchOrganizationsRequest
})(GridContainer);
