import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { app as App } from 'app/backbone/app';
import PermissionForm from '~/components/permissions_form';
import { FormContainer } from './styles';
import { getUsersIsSyncing, deleteUserPermissionsRequest } from '~/store/reducers/users';
import { getManagedOrganizationsValueLabelPairsWithExternalId } from '~/store/selectors/organizations';
import { getUserByIdRolesOrganizationsValueLabelPairs } from '~/store/selectors/users';

const AccessForm = ({
  userId,
  syncing,
  organizationsRolesOptions,
  organizationsOptions,
  handleDeleteUserPermissionsRequest
}) => {
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    App.navigate('users');
  }, []);

  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.users'),
      { toastId: 'success_user_updated' }
    );
    handleCancel();
  }, [t, handleCancel]);

  const handleSubmit = useCallback((permissions) => {
    const deletedOrganizations = permissions.filter(({ deleted }) => deleted);
    if (deletedOrganizations.length) {
      handleDeleteUserPermissionsRequest({
        externalIds: deletedOrganizations.map(({ organization: { external_id } }) => external_id),
        userId,
        onSuccess: handleSuccess
      });
    }
  }, [handleDeleteUserPermissionsRequest, handleSuccess, userId]);

  return (
    <FormContainer>
      <PermissionForm
        syncing={syncing}
        organizationsOptions={organizationsOptions}
        organizationsRolesOptions={organizationsRolesOptions}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </FormContainer>
  );
};

const mapStateToProps = (state, { userId }) => ({
  syncing: getUsersIsSyncing(state),
  organizationsOptions: getManagedOrganizationsValueLabelPairsWithExternalId(state),
  organizationsRolesOptions: getUserByIdRolesOrganizationsValueLabelPairs(state, userId)
});

const mapDispatchToProps = {
  handleDeleteUserPermissionsRequest: deleteUserPermissionsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessForm);
