/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import moment from 'moment';

export default (superclass) => class extends superclass {
  hasError() {
    return false;
  }

  getExtraReportingInterval(interval) {
    if (interval < moment.duration(5, 'minutes').asMilliseconds()) {
      return interval * 2;
    } return interval + moment.duration(2, 'minutes').asMilliseconds();
  }

  isDeviceReporting(lastTs, interval) {
    return (moment().valueOf() - lastTs) < this.getExtraReportingInterval(interval);
  }

  isSaving(options, mutator = {}) {
    if (_.has(options || {}, 'isTransient')) {
      return options.isTransient;
    } return mutator.transient;
  }
};
