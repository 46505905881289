import React, { useMemo } from 'react';
import { Field, useField } from 'react-final-form';
// import { useTranslation } from 'react-i18next';
import countries from 'app/config/common_data/countries';
import usStates from 'app/config/common_data/us_states';
import capitalize from 'lodash/capitalize';
import useValidation from '~/hooks/useValidation';
import Input from '~/components/form/input';
import Select from '~/components/form/select';

const AddressForm = React.memo(({ address = 'shipping' }) => {
  // const { t } = useTranslation();
  const { validateRequired } = useValidation();
  const { input: { value: selectedState } } = useField(`${address}.country`);

  const mappedCountries = useMemo(() => countries.map((c) => ({
    label: c.name,
    value: c.code
  })), []);

  const mappedUSStates = useMemo(() => usStates.map((c) => ({
    label: c.name,
    value: c.code
  })), []);

  return (
    <div className="row">
      <h3 style={{ fontWeight: 'bold' }}>
        <i className="fa fa-fw fa-envelope" />
        {capitalize(address)}
        {' '}
        Address
      </h3>
      <div className="row form">
        <div className="col-md-6 col-sm-12">
          <Field
            name={`${address}.country`}
            validate={validateRequired}
          >
            {({ input, meta }) => (
              <Select
                {...input}
                label="Country"
                options={mappedCountries}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        <div className="col-md-6 col-sm-12">
          <Field
            name={`${address}.city`}
            validate={validateRequired}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                label="City"
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        <div className="col-md-6 col-sm-12">
          {
            selectedState?.value === 'US'
              ? (
                <Field
                  name={`${address}.state`}
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Select
                      {...input}
                      label="State"
                      options={mappedUSStates}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              )
              : (
                <Field
                  name={`${address}.state_other`}
                  validate={() => null}
                >
                  {({ input }) => (
                    <Input
                      {...input}
                      label="State"
                    />
                  )}
                </Field>
              )
          }
        </div>
        <div className="col-md-6 col-sm-12">
          <Field
            name={`${address}.zip_code`}
            validate={validateRequired}
          >
            {({ input, meta }) => (
              <Input
                label="Zip Code"
                {...input}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        <div className="col-md-12 col-sm-12">
          <Field
            name={`${address}.street`}
            validate={validateRequired}
          >
            {({ input, meta }) => (
              <Input
                label="Street Address"
                type="textarea"
                {...input}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
      </div>
    </div>
  );
});

export default AddressForm;
