/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const fetchOrganizationsRequest = createAction('@@organizations/FETCH_REQUEST');
export const fetchOrganizationsSuccess = createAction('@@organizations/FETCH_SUCCESS');
export const fetchOrganizationsFailure = createAction('@@organizations/FETCH_FAILURE');

export const fetchOrganizationsListRequest = createAction('@@organizations/FETCH_LIST_REQUEST');
export const fetchOrganizationsListSuccess = createAction('@@organizations/FETCH_LIST_SUCCESS');
export const fetchOrganizationsListFailure = createAction('@@organizations/FETCH_LIST_FAILURE');

export const fetchOrganizationDetailsRequest = createAction('@@organizations/FETCH_DETAILS_REQUEST');
export const fetchOrganizationDetailsSuccess = createAction('@@organizations/FETCH_DETAILS_SUCCESS');
export const fetchOrganizationDetailsFailure = createAction('@@organizations/FETCH_DETAILS_FAILURE');

export const destroyOrganizationRequest = createAction('@@organizations/DELETE_REQUEST');
export const destroyOrganizationSuccess = createAction('@@organizations/DELETE_SUCCESS');
export const destroyOrganizationFailure = createAction('@@organizations/DELETE_FAILURE');

export const createOrganizationRequest = createAction('@@organizations/CREATE_REQUEST');
export const createOrganizationSuccess = createAction('@@organizations/CREATE_SUCCESS');
export const createOrganizationFailure = createAction('@@organizations/CREATE_FAILURE');

export const updateOrganizationRequest = createAction('@@organizations/UPDATE_REQUEST');
export const updateOrganizationSuccess = createAction('@@organizations/UPDATE_SUCCESS');
export const updateOrganizationFailure = createAction('@@organizations/UPDATE_FAILURE');

const ids = createReducer([], {
  [createOrganizationSuccess](state, { payload: { _id } }) {
    return [...state, _id];
  },
  [fetchOrganizationsSuccess](state, { payload: { result } }) {
    return result;
  },
  [fetchOrganizationsListSuccess](state, { payload: { result } }) {
    return result;
  },
  [destroyOrganizationSuccess](state, { payload: organizationId }) {
    state.splice(state.findIndex((id) => id === organizationId), 1);
  }
});

const byId = createReducer({}, {
  [createOrganizationSuccess](state, { payload }) {
    return {
      ...state,
      [payload._id]: payload
    };
  },
  [fetchOrganizationsSuccess](state, { payload }) {
    const { entities: { organizations } } = payload;
    if (organizations) {
      return organizations;
    }
    return state;
  },
  [fetchOrganizationsListSuccess](state, { payload }) {
    const { entities: { organizations } } = payload;
    if (organizations) {
      return organizations;
    }
    return state;
  },
  [destroyOrganizationRequest](state, { payload: { organizationId } }) {
    state[organizationId].isSyncing = true;
  },
  [destroyOrganizationFailure](state, { payload: { organizationId } }) {
    state[organizationId].isSyncing = false;
  },
  [destroyOrganizationSuccess](state, { payload: { organizationId } }) {
    delete state[organizationId];
  },
  [fetchOrganizationDetailsSuccess](state, { payload: { organizationId, deviceCounts } }) {
    state[organizationId].isSyncing = false;
    state[organizationId].isSynced = true;
    state[organizationId].deviceCounts = deviceCounts;
  },
  [fetchOrganizationDetailsRequest](state, { payload: { organizationId } }) {
    state[organizationId].isSynced = false;
    state[organizationId].isSyncing = true;
  },
  [fetchOrganizationDetailsFailure](state, { payload: { organizationId } }) {
    state[organizationId].isSyncing = false;
    state[organizationId].isSynced = true;
  }
});

const isFetched = createReducer(false, {
  [fetchOrganizationsRequest]() {
    return false;
  },
  [fetchOrganizationsFailure]() {
    return false;
  },
  [fetchOrganizationsSuccess]() {
    return true;
  },
  [fetchOrganizationsListRequest]() {
    return false;
  },
  [fetchOrganizationsListFailure]() {
    return false;
  },
  [fetchOrganizationsListSuccess]() {
    return true;
  }
});

const organizations = combineReducers({
  ids,
  byId,
  isFetched
});

export const getOrganizationById = (state, id) => state.organizations.byId[id];
export const getOrganizations = (state) => state.organizations.byId;
export const getOrganizationsIds = (state) => state.organizations.ids;
export const getOrganizationsIsFetched = (state) => state.organizations.isFetched;
export const getOrganizationIsSyncing = (state, id) => state.organizations.byId[id]?.isSyncing;
export const getOrganizationIsSynced = (state, id) => state.organizations.byId[id]?.isSynced;
export const getOrganizationDeviceCounts = (state, id) => state.organizations.byId[id]?.deviceCounts;

export default organizations;
