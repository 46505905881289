import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import { api as thiamisApi } from 'app/backbone/apps/thiamises/thiamises_app';
import ConfirmLinkButton from 'app/backbone/lib/react/components/common/ConfirmLinkButton/ConfirmLinkButton';
import I18n from 'app/config/i18n';
import {
  getNodeById, isNodeMonitorable, isNodeModifiable, isNodeDeletable, isNodeCreatable, getThiamisDeviceModel
} from '~/store/selectors/nodes';
import {
  fetchDataPointsRequest, getNodeIsSyncing, getNodeIsSynced, destroyNodesRequest
} from '~/store/reducers/nodes';
import BatteryContainer from './battery_container';
import SignalLevelContainer from './signal_level_container';
import LastTimeUpdatedContainer from './last_time_updated_container';
import SensorsListContainer from './sensors_list_container';

const GridDetailContainer = ({ row }) => {
  const nodeId = row?._id;
  const {
    isSyncing, isSynced, isCreatable, deviceModel,
    isMonitorable, isModifiable, isDeletable
  } = useSelector(
    (state) => {
      const node = getNodeById(state, nodeId);
      const actions = node?.actions || [];
      return {
        isSyncing: getNodeIsSyncing(state, nodeId),
        isSynced: getNodeIsSynced(state, nodeId),
        isCreatable: isNodeCreatable(actions),
        deviceModel: getThiamisDeviceModel(state, nodeId),
        isMonitorable: isNodeMonitorable(actions),
        isModifiable: isNodeModifiable(actions),
        isDeletable: isNodeDeletable(actions)
      };
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isSynced && !isSyncing) {
      dispatch(fetchDataPointsRequest({ node_id: [nodeId] }));
    }
  }, [dispatch, isSynced, nodeId, isSyncing]);
  const handleMonitorThiamisClick = useCallback(
    () => thiamisApi.getChannel().request('app:devices:monitor', nodeId),
    [nodeId]
  );
  const handleEditThiamisClick = useCallback(
    () => thiamisApi.getChannel().request('app:devices:modify', nodeId),
    [nodeId]
  );
  const handleDeleteThiamis = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(destroyNodesRequest({ nodeId }));
    },
    [dispatch, nodeId]
  );

  return (
    <div className={classNames('more-info', 'tr', { loading: isSyncing })}>
      <div colSpan="8" className="td">
        <div className={classNames('info-row', 'clearfix', { 'is-inventory': isCreatable })}>
          <div className="info-row-center">
            <div className="sensor-list">
              <div className="sensor-box-container thiamis-box">
                <div className="sensor-box clearfix">
                  <div className={`thiamis-img ${_.slugify(deviceModel)}`} title={deviceModel} />
                  <ul className="status-block">
                    <li>
                      <BatteryContainer nodeId={nodeId} />
                    </li>
                    <li>
                      <SignalLevelContainer nodeId={nodeId} />
                    </li>
                    <li>
                      <LastTimeUpdatedContainer nodeId={nodeId} />
                    </li>
                  </ul>
                </div>
              </div>
              <SensorsListContainer nodeId={nodeId} />
            </div>
          </div>
          <div className="actions-container">
            { isMonitorable
              ? (
                <LinkButton
                  className="info"
                  icon="fa fa-fw fa-line-chart"
                  title={I18n.t('thiamis.open_monitor')}
                  onClick={handleMonitorThiamisClick}
                />
              ) : null }
            { isModifiable
              ? (
                <LinkButton
                  className="info"
                  icon="fa-md fa-fw fa-pencil"
                  title={I18n.t('thiamis.edit_configuration')}
                  onClick={handleEditThiamisClick}
                />
              ) : null }
            { isDeletable
              ? (
                <ConfirmLinkButton
                  className="info"
                  icon="fa-md fa-fw fa-trash"
                  title={I18n.t('thiamis.delete_thiamis')}
                  onConfirm={handleDeleteThiamis}
                  btnOkLabel={I18n.t('base.buttons.reset')}
                  placement="left"
                />
              ) : null }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridDetailContainer;
