/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import DeviceTitle from 'app/backbone/lib/react/components/common/DeviceTitle/DeviceTitle';
import DeviceInfoIcon from 'app/backbone/lib/react/components/common/DeviceInfoIcon/DeviceInfoIcon';
import I18n from 'app/config/i18n';
import { withSelfUpdatable } from 'app/backbone/lib/react/hocs';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';
import { __guardMethod__ } from 'app/utils/custom-fns';

class ThiamisDeviceTitle extends withSelfUpdatable(Component) {
  constructor(props) {
    super();
    this.model = props.getModel();
  }

  componentDidMount() {
    if (this.dataPoints != null) {
      this.dataPoints.on('remove add change', this.onDataPointsUpdate, this);
    }
    __guardMethod__(this.model.getDevice(), 'on', (x) => x.on('change:location', _.bind(this.updateStatus, this)));
    return this.dataPoints = this.model.getDataPoints();
  }

  componentWillUnmount() {
    if (this.dataPoints != null) {
      this.dataPoints.off('remove add change', this.onDataPointsUpdate, this);
    }
    return __guardMethod__(this.model.getDevice(), 'off', (x) => x.off('change:location', _.bind(this.updateStatus, this)));
  }

  updateStatus() {
    return this.forceUpdate();
  }

  onDataPointsUpdate(dataPoint) {
    if (dataPoint.isLocation() || dataPoint.isGpsError() || dataPoint.isThiamisOnline()) {
      return this.updateStatus();
    }
  }

  render() {
    return (
      <DeviceTitle
        displayName={this.model.get('display_name')}
        statusType={this.model.get('status_type')}
        statusText={this.model.get('status_text')}
      />
    );
  }

  getErrorIcon() {
    return <DeviceInfoIcon popover={{ title: I18n.t('base.labels.warning'), content: I18n.t('notifications.errors.thiamis_location') }} />;
  }
}

export default BackboneReactComponent(ThiamisDeviceTitle);
