import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import get from 'lodash/get';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import ConfirmLinkButton from 'app/backbone/lib/react/components/common/ConfirmLinkButton/ConfirmLinkButton';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import PhoneLink from '~/components/phone_link';
import { isNodeModifiable, isNodeDeletable, getNodeById } from '~/store/selectors/nodes';
import { deleteCertificateRequest } from '~/store/reducers/certificates';
import { getCertificateById } from '~/store/selectors/certificates';

const GridDetailContainer = ({ row }) => {
  const {
    phone, address
  } = row.user_metadata || {};
  const phoneNumber = useMemo(() => {
    if (phone && isValidPhoneNumber(phone)) {
      return formatPhoneNumberIntl(phone);
    }
    return phone;
  }, [phone]);
  const { t } = useTranslation();
  const nodeId = row?._id;
  const dispatch = useDispatch();
  const {
    isModifiable, isDeletable
  } = useSelector(
    (state) => {
      const node = getCertificateById(state, nodeId);
      const actions = node?.actions || [];
      return {
        isModifiable: isNodeModifiable(actions),
        isDeletable: isNodeDeletable(actions)
      };
    }
  );
  const nodes = useSelector((state) => {
    const nodeIds = get(row, 'templates[0].configuration.node_id', []);
    return nodeIds.map((id) => getNodeById(state, id)).filter((node) => node);
  });

  const handleDeleteSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.delete_certificate'),
      { toastId: 'success_certificate_delete' }
    );
  }, [t]);

  const handleEditClick = useCallback(() => {
    App.navigate(`certificates/${nodeId}`);
  }, [nodeId]);

  const handleDeleteClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(deleteCertificateRequest({
      nodeId,
      onSuccess: handleDeleteSuccess
    }));
  }, [dispatch, nodeId, handleDeleteSuccess]);

  return (
    <div className={classNames('more-info', 'tr')}>
      <div className="certificate-lists-column" />
      <div className="certificate-lists-column">
        <div className="row">
          {
            !!nodes.length && (
              <div className="col-sm-12">
                <div className="caption-box">{I18n.t('certificates.devices')}</div>
                <ul>
                  {
                    nodes.map((node) => (
                      <li key={node._id}>
                        {`${node.serial} - ${node.name}`}
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          }
        </div>
      </div>
      <div className="certificate-lists-column">
        <div className="row">
          <div className="col-sm-12">
            <ul>
              <li>
                { phone && <PhoneLink phoneNumber={phoneNumber} /> }
              </li>
              <li>
                { address }
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="actions certificate-lists-column-actions">
        { isModifiable && (
        <LinkButton
          className="info"
          icon="fa-md fa-fw fa-pencil"
          onClick={handleEditClick}
        />
        )}
        {
          isDeletable && (
            <ConfirmLinkButton
              className="info"
              icon="fa-md fa-fw fa-trash"
              onConfirm={handleDeleteClick}
              btnOkLabel={I18n.t('base.buttons.delete')}
              placement="left"
            />
          )
        }
      </div>
    </div>
  );
};

export default GridDetailContainer;
