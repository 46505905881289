import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import withBackboneLink from '~/hocs/with_backbone_link';
import icnThiamis from '~/assets/icons/thiamis.svg';

export const ClaimDeviceButton = withBackboneLink(({ primary, ...restProps }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className={classNames(
        'btn',
        `btn-${primary ? 'primary' : 'default'}`,
      )}
      {...restProps}
    >
      <i className="fa fa-fw fa-md fa-plus" />
      <span>{t('claim.claim_devices')}</span>
    </button>
  );
});

const NoDevicesScreen = React.memo(({ children }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={children.length}>
        <div className="no-devices-container">
          <img src={icnThiamis} alt="No devices" />
          {t('claim.no_claimed_devices')}
          <ClaimDeviceButton primary to="devices/claim" />
        </div>
      </td>
    </tr>
  );
});

export default NoDevicesScreen;
