import React from 'react';
import { connect } from 'react-redux';
import { getFirmwaresStatusFilterTags, getFirmwaresChannelFilterTags, getFirmwaresThiamisFilterTags } from '../selectors';
import FiltersSidebarPanel from './filters_sidebar_panel';

const FiltersSidebar = React.memo((props) => {
  const { channels, status, thiamis } = props;
  return (
    <div className="filters-region right-panel">
      <div className="fill-height">
        <div className="filter-groups-list-region">
          <div id="filters-wrap" className="panel-group collapse in" aria-expanded="true">
            <FiltersSidebarPanel filters={channels} group="channels" collapsedIn />
            <FiltersSidebarPanel filters={status} group="status" />
            <FiltersSidebarPanel filters={thiamis} group="thiamis" />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  status: getFirmwaresStatusFilterTags(state),
  channels: getFirmwaresChannelFilterTags(state),
  thiamis: getFirmwaresThiamisFilterTags(state)
});

export default connect(mapStateToProps)(FiltersSidebar);
