import { createSelector } from 'reselect';
import { getTemplates } from '~/store/reducers/templates';

export const getTemplatesForDropdown = createSelector(
  getTemplates,
  (templates) => templates.map((item) => ({
    label: item.name,
    organization: item.organization_id,
    value: item._id
  }))
);

export const getBlueCertificateTemplate = createSelector(
  getTemplatesForDropdown,
  (templates) => templates
    .find((item) => _.underscored(item.label) === 'airthinx_blue')
);

export const getAqmsTemplateId = createSelector(
  getTemplates,
  (templates) => {
    const template = templates.find(({ name }) => _.underscored(name) === 'aqms_default');

    return template._id || null;
  }
);
