/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import React from 'react';
import classNames from 'classnames';
import { withTooltip } from 'app/backbone/lib/react/hocs';

const DeviceStatus = withTooltip((props) => {
  const title = _.isArray(props.statusText) ? props.statusText.join('\n') : props.statusText;
  return (
    <b ref={props.tooltipRef} title={title} className={classNames('device-status', _.slugify(props.statusType || 'empty'))}>
      <i className={classNames('fa', 'fa-circle', props.iconClassName || 'fa-sm')} />
    </b>
  );
});

export default DeviceStatus;
