import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { flatJson } from '~/utils/helpers';
import createRootReducer from './reducers';
import rootSaga from './sagas';

const rootReducer = createRootReducer();

function configureAppStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const sentryMiddleware = createSentryMiddleware(Sentry, {
    stateTransformer(state) {
      return flatJson(state);
    },
    actionTransformer(action) {
      return flatJson(action);
    }
  });
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sentryMiddleware, sagaMiddleware, ...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })],
    preloadedState
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureAppStore();
