/* eslint-disable no-param-reassign */

import { Application } from 'backbone.marionette';
import { api as apiAbilities } from 'app/backbone/entities/abstract/abilities';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs'; // eslint-disable-line import/no-cycle
import { api as organizationApi } from 'app/backbone/entities/organization';
import reduxHistory from '~/store/history';

const App = Application.extend({
  channelName: 'global',
  region: '#root',

  getCurrentRoute() {
    const frag = Backbone.history.fragment;
    if (_.isEmpty(frag)) {
      return null;
    }
    return frag;
  },

  navigate(route, options = {}) {
    _.defaults(options, { prependOrgSlug: true, trigger: true, replace: false });
    const { prependOrgSlug, replace } = options;
    if (route.charAt(0) === '/') { route = route.slice(1); }
    if (prependOrgSlug) {
      const orgSlug = orgNavsApi.getChannel().request('get:choosable:orgs').getChosedSlug();
      route = `${orgSlug || 'all'}/${route}`;
    }
    this.getChannel().request('enable:org_filter', false);
    Backbone.history.navigate(route, options);
    if (route === this.getCurrentRoute()) {
      reduxHistory.push('/');
    }
    if (replace) {
      reduxHistory.replace(`/${route}`);
    } else {
      reduxHistory.push(`/${route}`);
    }
  },

  changeOrgSlugRoute(orgSlug) {
    const currentRoute = this.getCurrentRoute();
    if (currentRoute != null) {
      const routeWithoutOrg = _(currentRoute).strRight('/');
      return this.navigate(`${orgSlug}/${routeWithoutOrg}`, { prependOrgSlug: false, replace: true });
    }
  }
});

export default App;
export const app = new App();

app.getChannel().reply('get:abilities', () => apiAbilities.getChannel().request('get:instance', app.currentUser).toJSON());
app.getChannel().reply('get:default:region', () => app.getView().getRegion('mainRegion'));
app.getChannel().reply('get:react:region', () => app.getView().getRegion('reactAppRegion'));
app.getChannel().on('nav:choose', (ref) => app.navs.chooseByRef(ref));
app.getChannel().on('nav:unchoose', () => {
  const ref1 = app.navs.getFirstChosen();
  return ref1 != null ? ref1.unchoose() : undefined;
});
app.getChannel().reply('set:organizations', (organizations) => app.organizations = organizations);
app.getChannel().reply('get:organizations', () => app.organizations);
app.getChannel().reply('reset:organizations', ({ organizations, success }) => {
  if (organizations instanceof Backbone.Collection) {
    if (!app.organizations) {
      app.organizations = organizationApi.getChannel().request('new:organizations');
    }
    app.organizations.reset(organizations.models);
    if (success) success();
  } else {
    app.organizations.fetch({ success });
  }
});

$(window).resize((event) => {
  if (!_(event.originalEvent).isUndefined()) {
    return app.getChannel().trigger('window:resized', event);
  }
});
app.getChannel().reply('change:window:location', (url) => window.location = url);
app.getChannel().reply('get:user:timezone', () => {
  let timezone;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.error('Not supported getting browser timezone');
  }
  return timezone;
});
app.getChannel().reply('app:index', () => app.navigate('dashboard'));
app.getChannel().reply('get:locales', () => app.locales);
app.getChannel().reply('get:current:user', () => app.currentUser);
app.getChannel().reply('clusternet:url', () => app.clusternetUrl);
app.getChannel().reply('clusternet:ws:url', () => app.clusternetWSUrl);
app.getChannel().reply('get:user_notices:methods', () => app.userNotices.methods);
app.getChannel().reply('get:default_org_name', () => app.defaultOrgName);
app.getChannel().reply('get:validations', () => app.validations);
