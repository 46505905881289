import React, { useCallback, useMemo } from 'react';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import RolesField from './roles_field';
import {
  Title,
  SaveButton,
  CancelButton,
  ButtonContainer
} from '~/styles';
import { FormContainer } from './styles';

const PermissionForm = ({
  syncing,
  organizationsRolesOptions,
  organizationsOptions,
  onCancel,
  onSubmit
}) => {
  const { t } = useTranslation();

  const initialData = useMemo(() => ({
    permissions: organizationsRolesOptions
  }), [organizationsRolesOptions]);

  const handleFormSubmit = useCallback(({ permissions }) => {
    onSubmit(permissions);
  }, [onSubmit]);

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={initialData}
      mutators={{ ...arrayMutators }}
      render={({
        handleSubmit
      }) => (
        <>
          <FormContainer>
            <Title>{t('users.organization_roles')}</Title>
            <FieldArray
              name="permissions"
              options={organizationsOptions}
              component={RolesField}
            />
          </FormContainer>
          <ButtonContainer>
            {onCancel && (
              <CancelButton onClick={onCancel}>
                {t('base.buttons.cancel')}
              </CancelButton>
            )}
            <SaveButton
              disabled={syncing}
              onClick={handleSubmit}
            >
              {t('base.buttons.save_changes')}
            </SaveButton>
          </ButtonContainer>
        </>
      )}
    />
  );
};

export default PermissionForm;
