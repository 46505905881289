import { createSelector } from 'reselect';
import times from 'lodash/times';
import moment from 'moment';

import * as fromMeasurements from '~/store/selectors/measurements';

function asSeconds(ts) {
  return ts / 1000;
}

export const getAlertDurationHeatmapData = createSelector(
  fromMeasurements.getMeasurementsByIdData,
  (measurements) => measurements.reduce((acc, [ts, active], i, arr) => {
    const [nextTs] = arr[i + 1] || [moment().valueOf()];
    if (active) {
      const days = moment.duration(nextTs - ts).asDays();
      if (days > 1) {
        let tsByDay = ts;
        acc[asSeconds(tsByDay)] = Math.round(moment.duration(moment(tsByDay).endOf('day').valueOf() - tsByDay).asMinutes());
        times(Math.floor(days), () => {
          tsByDay = moment(tsByDay).add(1, 'day').startOf('day').valueOf();
          acc[asSeconds(tsByDay)] = 24 * 60;
        });
        acc[asSeconds(nextTs)] = Math.round(moment.duration(nextTs - moment(tsByDay).endOf('day').valueOf()).asMinutes());
      } else {
        acc[asSeconds(ts)] = moment.duration(nextTs - ts).asMinutes();
      }
    }
    return acc;
  }, {})
);

export const getDurationsTableData = createSelector(
  fromMeasurements.getMeasurementsByIdData,
  (state, dataPointId) => dataPointId,
  (state, dataPointId, selectedDate) => selectedDate,
  (measurements, dataPointId, selectedDate) => measurements.reduce((acc, [ts, active], i, arr) => {
    if (selectedDate) {
      const startOfDay = moment(selectedDate).startOf('day');
      const endOfDay = moment(selectedDate).endOf('day');
      if (!moment(ts).isBetween(startOfDay, endOfDay, null, '[]')) {
        return acc;
      }
    }
    const [nextTs] = arr[i + 1] || [moment().valueOf()];
    if (active) {
      acc.push({ triggered: ts, duration: nextTs - ts, dataPointId });
    }
    return acc;
  }, [])
);

export const getDurationsDetailedTableData = createSelector(
  fromMeasurements.getMeasurementsByIdData,
  (state, dataPointId) => dataPointId,
  (state, dataPointId, selectedDate) => selectedDate,
  (measurements, dataPointId, selectedDate) => measurements.reduce((acc, [ts, active], i, arr) => {
    if (selectedDate) {
      const startOfDay = moment(selectedDate).startOf('day');
      const endOfDay = moment(selectedDate).endOf('day');
      if (!moment(ts).isBetween(startOfDay, endOfDay)) {
        return acc;
      }
    }
    const [nextTs] = arr[i + 1] || [moment().valueOf()];
    if (active) {
      acc.push({ triggered: ts, duration: nextTs - ts, dataPointId });
    }
    return acc;
  }, [])
);
