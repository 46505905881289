import { createSelector } from 'reselect';
import some from 'lodash/some';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import * as fromAlerts from '~/store/reducers/alerts';
import * as fromAlertSelectors from '~/store/selectors/alerts';
import { getOrganizations } from '~/store/reducers/organizations';
import { getFilter } from './reducer';

export const getAlerts = createSelector(
  fromAlerts.getIds,
  fromAlerts.getAlerts,
  (ids, alerts) => ids.map((id) => alerts[id])
);

export const getAlertActions = createSelector(
  getAlerts,
  (alerts = {}) => _(alerts.map((alert) => alert.actions))
    .chain()
    .flatten()
    .uniq()
    .value()
);

export const getActionsForAlertId = createSelector(
  fromAlerts.getAlertById,
  (alert = {}) => alert.actions
);

export const getAlertsFilterTags = createSelector(
  getFilter,
  ({ tags }) => tags
);

function getCounts(tags) {
  return tags.reduce((result, tag) => {
    if (!result[tag]) {
      result[tag] = 0; // eslint-disable-line no-param-reassign
    }
    result[tag] += 1; // eslint-disable-line no-param-reassign
    return result;
  }, {});
}

export const createStatusFilterTags = (state, alertId) => {
  const ids = fromAlertSelectors.getAlertDataPointsIds(state, alertId);
  const status = fromAlertSelectors.getAlertByIdStatus(state, alertId);
  const measurement = fromAlertSelectors.getAlertLastMeasurementValue(state, ids);
  return [fromAlertSelectors.getAlertStatus(status, measurement)];
};

export const getAlertStatusFilterTags = createSelector(
  getAlerts,
  (state) => state,
  (list, state) => {
    const result = {};
    result.counts = getCounts(flatten(list.map(({ _id }) => createStatusFilterTags(state, _id))));
    result.all = list.length;
    return result;
  }
);

export const getFilteredList = createSelector(
  getAlerts,
  getOrganizations,
  getFilter,
  (state) => state,
  (alerts, organizations, { query, tags }, state) => {
    let results = alerts.map((alert) => {
      // eslint-disable-next-line no-underscore-dangle
      const alertId = alert._id;
      const dataPointIds = fromAlertSelectors.getAlertDataPointsIds(state, alertId);
      const status = fromAlertSelectors.getAlertByIdStatus(state, alertId);
      const measurement = fromAlertSelectors.getAlertLastMeasurementValue(state, dataPointIds);
      return {
        ...alert,
        organization_name: organizations[alert.organization_id]?.name,
        last_triggered: fromAlertSelectors.getAlertLastMeasurementTs(state, dataPointIds),
        status_type: fromAlertSelectors.getAlertStatus(status, measurement)
      };
    });
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((alert) => {
        const { serial, name, description } = alert;
        const searchString = [serial, name, description].join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    if (!isEmpty(tags)) {
      results = results.filter(({ _id }) => {
        const allTags = createStatusFilterTags(state, _id);
        return intersection(tags, allTags).length === tags.length;
      });
    }
    return results;
  }
);

export function getAlertValueLabelPair(node = {}) {
  const { _id: value, name } = node;
  return { value, label: name };
}

export const getFilteredListValueLabelPairs = createSelector(
  getFilteredList,
  (nodes) => nodes.map(getAlertValueLabelPair)
);
