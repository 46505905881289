/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import ConfirmationHoc from 'app/backbone/lib/react/hocs/ConfirmationHoc';

class ConfirmLinkButton extends Component {
  // mixins: [App.React.Mixins.Confirmation],

  render() {
    return React.createElement(LinkButton,
      // eslint-disable-next-line react/forbid-foreign-prop-types
      _(this.props).chain().pick(_.keys(LinkButton.propTypes)).extend({ ref: (elem) => (this.confirmButtonElem = elem) })
        .value());
  }
}

export default ConfirmationHoc(ConfirmLinkButton);
