/* eslint-disable no-loop-func */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import AssociatedModel from 'app/backbone/lib/entities/associated_model';
import { __guard__ } from 'app/utils/custom-fns';

class WindFrequency extends Backbone.Model {
  get idAttribute() {
    return 'name';
  }

  get defaults() {
    return { count: 0 };
  }

  get mutators() {
    return {
      frequency: {
        get() {
          return _.round(((this.get('count') / this.getTotalMeasurements()) * 100), 2);
        }
      }
    };
  }

  getTotalMeasurements() {
    return this.collection.parents[0].collection.parents[0].get('total');
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.asChartData) {
      return [json.name, json.frequency];
    }
    return json;
  }
}

class WindFrequenciesColl extends Backbone.Collection {
  get model() {
    return WindFrequency;
  }

  comparator(frequency) {
    return -frequency.get('direction');
  }
}

class WindSerie extends Backbone.AssociatedModel {
  get idAttribute() {
    return 'name';
  }

  get defaults() {
    return {
      count: 0,
      frequencies: []
    };
  }

  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'frequencies',
        relatedModel: WindFrequency,
        collectionType: WindFrequenciesColl
      }
    ];
  }

  get mutators() {
    return {
      count: {
        get() {
          return this.get('frequencies').reduce((memo, frequency) => frequency.get('count') + memo,
            0);
        }
      }
    };
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.asChartData) {
      return _.extend(_.pick(json, 'name', 'count', 'color'), { data: json.frequencies });
    }
    return json;
  }
}

class WindSeriesColl extends Backbone.Collection {
  get model() {
    return WindSerie;
  }

  addMeasurement(...args) {
    const [direction, speed] = Array.from(args[0]);
    const dp = (this.size() > 1) && speed
      ? this.find((windDp) => {
        const { min, max } = windDp.pick('min', 'max');
        return (speed >= min) && (speed < max);
      })
      : this.first();

    const name = _.azimut2cardinal(direction);
    let count = __guard__(__guard__(dp.get('frequencies'), (x1) => x1.get(name)), (x) => x.get('count')) || 0;
    count += 1;
    return dp.get('frequencies').set([{ direction, speed, name, count }], { merge: true, remove: false });
  }

  toJSON(options = {}) {
    let json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.asChartData) {
      json = _.filter(json, (serie) => serie.count);
      // eslint-disable-next-line no-param-reassign
      _.each(json, (serie) => serie.data = _.map(_.cardinalDirections(), (category) => {
        const existing = _.find(serie.data, (serieData) => serieData[0] === category);
        return [category, existing ? existing[1] : 0];
      }));
    }
    return json;
  }
}

export default class WindChart extends AssociatedModel {
  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'series',
        relatedModel: WindSerie,
        collectionType: WindSeriesColl
      }
    ];
  }

  get defaults() {
    return {
      total: 0,
      series: []
    };
  }

  get NUM_SPEED_SERIES() {
    return 6;
  }

  get colors() {
    return ['#C2EBFF', '#70CFFF', '#1FB4FF', '#0088CC', '#005F8F', '#003652'];
  }

  populateSeries(dataPoints, thiamis) {
    let color; let
      name;
    const speeds = __guard__(dataPoints.getWindSpeedDataPoint(thiamis), (x) => x.get('measurements'));
    const directions = dataPoints.find((dp) => dp.isWindDirection() && thiamis.hasDevice(dp.get('device_id'))).get('measurements');
    const series = [];
    if (speeds) {
      let maxSpeed = 0;
      _.each(speeds, (speed) => {
        if (speed[1] > maxSpeed) { maxSpeed = speed[1]; }
        const index = _.findIndex(directions, (direction) => speed[0] === direction[0]);
        if (index !== -1 && _.isArray(directions[index])) {
          return directions[index] = directions[index].concat(speed[1]);
        }
      });
      const range = Math.round(maxSpeed / this.NUM_SPEED_SERIES) || 1;
      // eslint-disable-next-line no-plusplus
      for (var i = 1, end = this.NUM_SPEED_SERIES, asc = end >= 1; asc ? i <= end : i >= end; asc ? i++ : i--) {
        color = this.colors[i - 1];
        var min = (i * range) - range;
        var max = i === this.NUM_SPEED_SERIES ? 10000 : i * range;
        name = (() => {
          if (min === 0) {
            return `< ${max} m/s`;
          } if (i === this.NUM_SPEED_SERIES) {
            return `> ${min} m/s`;
          } if (min && max) {
            return `${min}-${max} m/s`;
          }
        })();
        series.push({ min, max, name, color });
      }
    } else {
      series.push({ name: 'Wind Direction', color: this.colors[0] });
    }

    this.get('series').reset(series);
    this.set('total', directions.length);

    return _.each(directions, (...args) => {
      const [, direction, speed] = Array.from(args[0]);
      return this.get('series').addMeasurement([direction, speed]);
    });
  }
}
