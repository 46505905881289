import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Spinner from '~/components/spinner';
import { fetchReportsRequest } from '~/store/reducers/reports';
import { fetchStatsRequest } from '~/store/reducers/stats';
import { getReportByIdWidgets, getDashboardReport, getReportsIsFetched } from '~/store/selectors/reports';
import Stats from './stats';
import Toolbar from './toolbar';
import WidgetsContainer from './widgets_container';

const DashboardScreen = (props) => {
  const {
    slug, widgets, handleFetchReportsRequest, handleFetchStatsRequest, isFetched
  } = props;
  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    handleFetchStatsRequest({ data });
  }, [slug, handleFetchStatsRequest]);

  useEffect(() => {
    if (!isFetched) {
      handleFetchReportsRequest();
    }
  }, [handleFetchReportsRequest, isFetched]);

  return (
    <div className="dashboard-page synced">
      <div className="header-region">
        <div className="content-top clearfix">
          <div className="col-sm-9">
            <div className="report-stats-region">
              <Stats />
            </div>
          </div>
          <div className="col-sm-3 fact-container pull-right widgets-container">
            <div className="report-toolbar-region">
              <Toolbar />
            </div>
          </div>
        </div>
      </div>
      <div className="widgets-region">
        {
          isFetched ? (<WidgetsContainer widgets={widgets} />) : (<Spinner />)
        }
      </div>
    </div>
  );
};

DashboardScreen.propTypes = {
  slug: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const reportId = get(getDashboardReport(state), '_id');
  return {
    widgets: getReportByIdWidgets(state, reportId),
    isFetched: getReportsIsFetched(state)
  };
};

export default connect(mapStateToProps, {
  handleFetchReportsRequest: fetchReportsRequest,
  handleFetchStatsRequest: fetchStatsRequest
})(DashboardScreen);
