var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form class=\"form-horizontal form\">\n  <fieldset>\n    <div class=\"config-region\"></div>\n    <div class=\"form-actions clearfix\">\n      <div class=\"col-sm-4\"><button class=\"btn btn-danger delete-form\">"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.buttons.delete",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":71},"end":{"line":5,"column":101}}}))
    + "</button></div>\n      <div class=\"col-sm-8\"><button data-dismiss=\"modal\" type=\"button\" class=\"btn btn-default\">"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.buttons.cancel",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":95},"end":{"line":6,"column":125}}}))
    + "</button><button type=\"submit\" class=\"btn btn-primary save-form\">"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.buttons.save",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":190},"end":{"line":6,"column":218}}}))
    + "</button></div>\n    </div>\n  </fieldset>\n</form>";
},"useData":true});