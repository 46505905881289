import '../styles/app.scss';
import Boot from 'app/utils/boot';
import AppLayout from 'app/backbone/lib/views/app_layout/app_layout';
import { app as App } from 'app/backbone/app';
import './app_on_before_start';
import './app_on_start';

App.showView(new AppLayout());
const bootHelper = new Boot();
bootHelper.boot().done((data) => App.start(data));
