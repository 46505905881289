import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAlertById, getAlertByIdStatus } from '~/store/selectors/alerts';
import { getOrganizationById } from '~/store/selectors/organizations';

const InfoBlock = (props) => {
  const {
    name, description, notificationLevel, owner, status
  } = props;
  const { t } = useTranslation();
  return (
    <div className="form-box">
      <h3>{t('alerts.alert_info')}</h3>
      <ul className="alert-general-info">
        <li>
          <b>{t('attributes.alert.name')}</b>
          <span className="name-conf">{name}</span>
        </li>
        {
          status === 'deactivated' && (
          <li>
            <b>{t('attributes.alert.status')}</b>
            <span className="name-conf">{t(`alerts.states.${status}`)}</span>
          </li>
          )
        }
        <li>
          <b>{t('attributes.alert.organization_id')}</b>
          <span className="owner-conf">{owner}</span>
        </li>
        <li>
          <b>{t('attributes.alert.notification_level')}</b>
          <span className="notification_level-conf">{notificationLevel || 'warning'}</span>
        </li>
        <li>
          <b>{t('attributes.alert.description')}</b>
          <p className="message-conf">{description}</p>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state, { id }) => {
  const alert = getAlertById(state, id);
  const organization = getOrganizationById(state, get(alert, 'organization_id'));
  return {
    name: get(alert, 'name'),
    status: getAlertByIdStatus(state, id),
    description: get(alert, 'description'),
    notificationLevel: get(alert, 'notification_level'),
    owner: get(organization, 'name')
  };
};

export default connect(mapStateToProps)(InfoBlock);
