import React, { useContext, useCallback } from 'react';
import classNames from 'classnames';
import I18n from 'app/config/i18n';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import RolloutFirmwareContext from '../../rollout_firmware_modal/context';

const GridDetailContainer = ({ row }) => {
  const { version, pending_units, current_units } = row;
  const {
    setIsActive,
    setSelectedFirmware
  } = useContext(RolloutFirmwareContext);

  const handleEditFirmware = useCallback(() => {
    setSelectedFirmware({ value: version, label: version });
    setIsActive(true);
  }, [version, setIsActive, setSelectedFirmware]);

  return (
    <div className={classNames('visible-row', 'tr')}>
      <div className="td" />
      <div className="td" style={{ overflow: 'scroll', maxHeight: '80px' }}>{current_units.join(', ')}</div>
      <div className="td" style={{ overflow: 'scroll', maxHeight: '80px' }}>{pending_units.join(', ')}</div>
      <div className="td" />
      <div className="actions td">
        <LinkButton
          className="info"
          icon="fa-md fa-fw fa-pencil"
          title={I18n.t('alerts.edit_configuration')}
          onClick={handleEditFirmware}
        />
      </div>
    </div>
  );
};

export default GridDetailContainer;
