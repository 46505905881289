import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'app/utils/resolution_helpers';
import { filterListByQuery, getIds } from '../reducer';
import ManageCalibrationsActions from './manage_calibrations';

export default function FiltersFormTop() {
  const dispatch = useDispatch();
  const calibrations = useSelector(getIds);

  const onFilterInputChange = _.debounce((e) => dispatch(filterListByQuery(e.target.value)), 200);
  const handleInputChange = (e) => {
    e.persist();
    return onFilterInputChange(e);
  };

  const handleCollapseClick = () => {
    $('body').toggleClass('filters-collapsed');
    if (isMobile()) { $('body').removeClass('nav-minified'); }
  };

  return (
    <div className="content-top">
      <div className="filter-input-wrap pull-left"><input className="filter-input" type="text" placeholder="Filter..." onChange={handleInputChange} /></div>
      <div className="pull-left">
        { calibrations.length && <ManageCalibrationsActions /> }
      </div>
      <div className="pull-right">
        <button type="button" onClick={handleCollapseClick} className="btn btn-default filters-collapse">
          <i className="fa fa-fw fa-md fa-bars" />
        </button>
      </div>
    </div>
  );
}
