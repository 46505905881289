/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import I18n from 'app/config/i18n';
import ChartWidgetConfigView from 'app/backbone/lib/widgets/chart_widget/conf_view';
import TextWidgetContentView from 'app/backbone/lib/widgets/text_widget/face_view';
import NodeWidgetContentView from 'app/backbone/lib/widgets/node_widget/face_view';
import BlocksWidgetContentView from 'app/backbone/lib/widgets/blocks_widget/face_view';
import ChartWidgetContentView from 'app/backbone/lib/widgets/chart_widget/face_view';
import CompareDevicesWidgetContentView from 'app/backbone/lib/widgets/compare_devices_widget/face_view';
import CompareDevicesWidgetConfigView from 'app/backbone/lib/widgets/compare_devices_widget/conf_view';
import MapWidgetContentView from 'app/backbone/lib/widgets/map_widget/face_view';
import TwitterWidgetContentView from 'app/backbone/lib/widgets/twitter_widget/face_view';
import { NodeWidgetConfig } from 'app/backbone/lib/components/shared_views/widget_node_views';
import MapWidgetConfigView from 'app/backbone/lib/widgets/map_widget/conf_view';
import TextWidgetConfigView from 'app/backbone/lib/widgets/text_widget/conf_view';
import HeatmapWidgetContentView from 'app/backbone/lib/widgets/heatmap_widget/face_view';
import HeatmapWidgetConfigView from 'app/backbone/lib/widgets/heatmap_widget/conf_view';

export default {
  requiredOptionNames: ['thiamises', 'thiamis'],
  buttons: ['resize', 'move', 'config', 'remove'],
  widgets: {
    TextWidget: {
      confView() { return TextWidgetConfigView; },
      faceView() {
        return TextWidgetContentView;
      },
      confTitle: I18n.t('text_widget.config_dialog_title'),
      buttons: ['move', 'config', 'remove']
    },
    NodeWidget: {
      confView() { return NodeWidgetConfig; },
      faceView() {
        return NodeWidgetContentView;
      },
      confTitle: I18n.t('node_widget.config_dialog_title')
    },
    BlocksWidget: {
      confView() { return NodeWidgetConfig; },
      faceView() {
        return BlocksWidgetContentView;
      },
      confTitle: I18n.t('blocks_widget.config_dialog_title')
    },
    MapWidget: {
      confView() { return MapWidgetConfigView; },
      faceView() {
        return MapWidgetContentView;
      },
      confTitle: I18n.t('map_widget.config_dialog_title')
    },
    HeatmapWidget: {
      confView() { return HeatmapWidgetConfigView; },
      faceView() {
        return HeatmapWidgetContentView;
      },
      confTitle: I18n.t('heatmap_widget.config_dialog_title')
    },
    ChartWidget: {
      confView() { return ChartWidgetConfigView; },
      faceView() {
        return ChartWidgetContentView;
      },
      confTitle: I18n.t('chart_widget.config_dialog_title')
    },
    CompareDevicesWidget: {
      buttons: ['move', 'config', 'remove'],
      confView() { return CompareDevicesWidgetConfigView; },
      faceView() {
        return CompareDevicesWidgetContentView;
      },
      confTitle: I18n.t('chart_widget.config_dialog_title')
    },
    TwitterWidget: {
      confView() { return null; },
      faceView() {
        return TwitterWidgetContentView;
      },
      confTitle: I18n.t('chart_widget.config_dialog_title'),
      buttons: ['move', 'remove']
    }
  },

  // eslint-disable-next-line max-len
  mapStyles: [{ featureType: 'landscape', elementType: 'labels', stylers: [{ visibility: 'off' }] }, { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }, { featureType: 'road', elementType: 'geometry', stylers: [{ lightness: 57 }] }, { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ visibility: 'on' }, { lightness: 24 }] }, { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }, { featureType: 'transit', elementType: 'labels', stylers: [{ visibility: 'off' }] }, { featureType: 'water', elementType: 'labels', stylers: [{ visibility: 'off' }] }],
  // eslint-disable-next-line max-len
  emptyMapStyles: [{ featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e9e9e9' }, { lightness: 17 }] }, { featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 20 }] }, { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }, { lightness: 17 }] }, { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }] }, { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 18 }] }, { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 16 }] }, { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 21 }] }, { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#dedede' }, { lightness: 21 }] }, { elementType: 'labels', stylers: [{ visibility: 'off' }] }, { featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#f2f2f2' }, { lightness: 19 }] }, { featureType: 'administrative', elementType: 'geometry.fill', stylers: [{ color: '#fefefe' }, { lightness: 20 }] }, { featureType: 'administrative', elementType: 'geometry.stroke', stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }] }]
};
