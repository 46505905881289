import { app as App } from 'app/backbone/app';
import { MnObject } from 'backbone.marionette';
import AppRouter from 'app/backbone/lib/routers/app_router';

class Router extends AppRouter {
  initialize() {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'firmware');
  }
}

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:firmware': 'showFirmwaresList'
  },

  showFirmwaresList() {
    Router.prototype.before();
    App.navigate('firmware');
    App.getChannel().request('enable:org_filter', true);
  }
});

const controller = {};

export const api = new API();

const FirmwareApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default FirmwareApp;
