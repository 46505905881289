import ReactDOM from 'react-dom';

const shallowEqual = require('fbjs/lib/shallowEqual');

function handleModel(obj) {
  return _.omit(obj, 'model');
}

// eslint-disable-next-line import/prefer-default-export
export function shallowCompare(instance, nextProps, nextState) {
  if (instance.props.model && instance.props.model instanceof Backbone.Model) {
    return (
      !shallowEqual(handleModel(instance.props), handleModel(nextProps))
        || !shallowEqual(handleModel(instance.state), handleModel(nextState))
    );
  }
  return (
    !shallowEqual(instance.props, nextProps)
    || !shallowEqual(instance.state, nextState)
  );
}

export function unmountComponentAtNode(elem) {
  if (elem && !_.isString(elem)) {
    ReactDOM.unmountComponentAtNode(elem);
  }
}
