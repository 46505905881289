/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';

const PhoneLink = ({ phoneNumber, type }) => (
  <a className="tel" href={`tel://${phoneNumber}`}>
    <i className={`fa fa-fw fa-${type} fa-sm`} />
    <span>{phoneNumber}</span>
  </a>
);

PhoneLink.defaultProps = {
  type: 'phone'
};

PhoneLink.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default PhoneLink;
