/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import { NodeWidgetContent, NoDataPoints } from 'app/backbone/lib/components/shared_views/widget_node_views';
import WidgetNodeTitle from 'app/backbone/lib/components/shared_views/widget_node_title';
import NodeWidgetFetchThiamis from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamis';
import { NodeWidgetDataPoint, NodeDataPoint } from 'app/backbone/lib/concerns/views/node_data_point';
import dataPointTemplate from './templates/data_point.hbs';

export const BlocksDataPoint = View.extend({
  behaviors: [NodeDataPoint, NodeWidgetDataPoint],
  className: 'col-sm-6 node-parameter',
  template: dataPointTemplate,

  ui: {
    name: '.name',
    value: '.value',
    unit: '.unit'
  },

  bindings: {
    '@ui.value': {
      observe: 'value',
      updateView(value) {
        return _.isDefined(value);
      },
      onGet() {
        return this.model.getDisplayValue();
      }
    },
    '@ui.unit': {
      observe: 'unit',
      onGet(unit) {
        const value = this.model.get('value');
        return `${_.isDefined(unit && _.isDefined(value)) ? unit : ''}`;
      }
    }
  },

  onRender() {
    return this.stickit();
  }
});

export const BlocksDataPointContainer = AppCollectionView.extend({
  childView: BlocksDataPoint,
  emptyView: NoDataPoints,
  className: 'node-widget-content clearfix',

  childViewOptions() {
    return _(this.options).pick('thiamis');
  }
});

const BlocksWidgetContentView = NodeWidgetContent.extend({
  behaviors: [NodeWidgetFetchThiamis],
  getWidgetTitle() {
    return new WidgetNodeTitle({
      model: this.options.thiamis
    });
  },

  getDataPointsContainer() {
    return this.dataPointsContainer != null ? this.dataPointsContainer : (this.dataPointsContainer = new BlocksDataPointContainer({
      collection: this.model.getUserDataPointsColl(), thiamis: this.options.thiamis }));
  }
});

export default BlocksWidgetContentView;
