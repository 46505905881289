/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';
import { api as thiamisApi } from 'app/backbone/entities/nodes/thiamis';
import { api as timezonesApi } from 'app/backbone/entities/abstract/timezones';

class Router extends AppRouter {
  initialize({ controller }) {
    this.controller = controller;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.appRoute(/^devices\/(\w+)\/(\w+:\w+)$/, 'showThiamisSensor');
    // eslint-disable-next-line no-useless-escape
    this.appRoute(/^devices\/(\w+)\/monitor\?([\w\|\&\=\%]+)$/, 'showThiamisMonitorWithParams');
    this.appRoute(/^devices\/(\w+)\/monitor$/, 'showThiamisMonitor');
    this.appRoute(/^devices\/(\w+)\/opened$/, 'showThiamisOpened');
    this.appRoute(/^devices\/(\w+)\/create$/, 'showThiamisOpened');
    return this.appRoute(/^devices\/(\w+)$/, 'showThiamis');
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'device');
  }
}

const controller = {
  showThiamis(thiamisId, options = { filteredThiamises: null }) {
    const promise = import('./show/show_controller'); // eslint-disable-line import/no-cycle
    promise.then((ShowController) => {
      // eslint-disable-next-line new-cap
      const ctrl = new ShowController.default({
        thiamis: thiamisApi.getChannel().request('fetch:subscribable:thiamis', thiamisId),
        timeZones: timezonesApi.getChannel().request('get:timezones'),
        filteredThiamises: options.filteredThiamises
      });
      return ctrl.showPageView();
    });
    return promise;
  },

  showThiamisSensor(thiamisId, sensorId, options = {}) {
    const promise = import('./show/show_controller'); // eslint-disable-line import/no-cycle
    promise.then((ShowController) => {
      // eslint-disable-next-line new-cap
      const ctrl = new ShowController.default({
        sensorId,
        thiamis: thiamisApi.getChannel().request('fetch:subscribable:thiamis', thiamisId),
        timeZones: timezonesApi.getChannel().request('get:timezones'),
        filteredThiamises: options.filteredThiamises
      });
      return ctrl.showPageView();
    });
    return promise;
  },

  showThiamisOpened(thiamisId, options = {}) {
    const promise = import('./show/show_controller'); // eslint-disable-line import/no-cycle
    promise.then((ShowController) => {
      // eslint-disable-next-line new-cap
      const ctrl = new ShowController.default({
        thiamis: thiamisApi.getChannel().request('fetch:subscribable:thiamis', thiamisId),
        timeZones: timezonesApi.getChannel().request('get:timezones'),
        filteredThiamises: options.filteredThiamises,
        opened: true
      });
      return ctrl.showPageView();
    });
    return promise;
  },

  showThiamisMonitor(nodeId, options = { filteredThiamises: null }) {
    const promise = import('app/backbone/apps/thiamises/monitor/monitor_controller'); // eslint-disable-line import/no-cycle
    promise.then((MonitorController) => {
      // eslint-disable-next-line new-cap
      const ctrl = new MonitorController.default({
        currentUser: App.getChannel().request('get:current:user'),
        thiamis: thiamisApi.getChannel().request('fetch:subscribable:thiamis', nodeId),
        filteredThiamises: options.filteredThiamises
      });
      return ctrl.showPageView();
    });
    return promise;
  },

  showThiamisMonitorWithParams(node_id, argParams = {}, options = { filteredThiamises: null }) {
    const params = _.isString(argParams) ? _.urlParamsToObj(argParams) : argParams;
    const promise = import('app/backbone/apps/thiamises/monitor/monitor_controller'); // eslint-disable-line import/no-cycle
    promise.then((MonitorController) => {
      // eslint-disable-next-line new-cap
      const ctrl = new MonitorController.default(_.extend(params, {
        currentUser: App.getChannel().request('get:current:user'),
        thiamis: thiamisApi.getChannel().request('fetch:subscribable:thiamis', node_id),
        filteredThiamises: options.filteredThiamises
      }));
      ctrl.showPageView();
    });
    return promise;
  }
};

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:devices': 'appDevices',
    'app:devices:modify': 'appDevicesModify',
    'app:devices:create': 'appDevicesCreate',
    'app:devices:modify:sensor': 'appDevicesModifySensor',
    'app:devices:modify:opened': 'appDevicesModifyOpened',
    'app:devices:monitor': 'appDevicesMonitor',
    'app:devices:monitor:params': 'appDevicesMonitorParams'
  },

  appDevices() {
    Router.prototype.before();
    App.navigate('devices');
    App.getChannel().request('enable:org_filter', true);
  },

  appDevicesModify(thiamisId, options) {
    Router.prototype.before();
    App.navigate(`devices/${thiamisId}`, options);
  },

  appDevicesCreate(thiamisId, options) {
    Router.prototype.before();
    App.navigate(`devices/${thiamisId}/create`, options);
  },

  appDevicesModifySensor(thiamisId, sensorId, options) {
    Router.prototype.before();
    App.navigate(`devices/${thiamisId}/${sensorId}`, options);
  },

  appDevicesModifyOpened(thiamisId, options) {
    Router.prototype.before();
    App.navigate(`devices/${thiamisId}/opened`, options);
  },

  appDevicesMonitor(thiamisId, options) {
    Router.prototype.before();
    App.navigate(`devices/${thiamisId}/monitor`, options);
    App.getChannel().request('clear:thiamis:data:stream');
  },

  appDevicesMonitorParams(thiamisId, params = {}, options = {}) {
    Router.prototype.before();
    App.navigate(`devices/${thiamisId}/monitor?data_points=${params.data_points}`, options);
    App.getChannel().request('clear:thiamis:data:stream');
  }
});

export const api = new API();

const ThiamisesApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default ThiamisesApp;
