/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import MarkdownIt from 'markdown-it';

const TextWidgetContentView = View.extend({
  template: false,
  className: 'content-text',

  initialize() {
    this.md = new MarkdownIt();
  },

  bindings: {
    ':el': {
      observe: 'text',
      onGet(value) {
        return this.md.render(value);
      },
      escape: false,
      updateMethod: 'html'
    }
  },

  onAttach() {
    this.stickit();
  }
});

export default TextWidgetContentView;
