/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

export const setWebhooksQuery = createAction('@@webhooks/SEARCH_REQUEST');
export const fetchWebhookListRequest = createAction('@@webhooks/FETCH_REQUEST');
export const fetchWebhookListSuccess = createAction('@@webhooks/FETCH_SUCCESS');
export const fetchWebhookListFailure = createAction('@@webhooks/FETCH_FAILURE');

export const createWebhookRequest = createAction('@@webhooks/CREATE_REQUEST');
export const createWebhookSuccess = createAction('@@webhooks/CREATE_SUCCESS');
export const createWebhookFailure = createAction('@@webhooks/CREATE_FAILURE');

export const toggleWebhookRequest = createAction('@@webhooks/TOGGLE_REQUEST');
export const toggleWebhookSuccess = createAction('@@webhooks/TOGGLE_SUCCESS');
export const toggleWebhookFailure = createAction('@@webhooks/TOGGLE_FAILURE');

export const deleteWebhookRequest = createAction('@@webhooks/DELETE_REQUEST');
export const deleteWebhookSuccess = createAction('@@webhooks/DELETE_SUCCESS');
export const deleteWebhookFailure = createAction('@@webhooks/DELETE_FAILURE');

const ids = createReducer([], {
  [fetchWebhookListSuccess](state, { payload: { result } }) {
    return result || state;
  },
  [deleteWebhookSuccess](state, { payload: { id } }) {
    return state.filter((webhook) => webhook !== id);
  },
  [createWebhookSuccess](state, { payload }) {
    return [
      ...state,
      payload._id
    ];
  }
});

const byId = createReducer({}, {
  [fetchWebhookListSuccess](state, { payload }) {
    const { entities: { webhooks } } = payload;
    if (webhooks) {
      return {
        ...state,
        ...webhooks
      };
    }
    return state;
  },
  [deleteWebhookRequest](state, { payload: { id } }) {
    state[id].isSyncing = true;
  },
  [deleteWebhookSuccess](state, { payload: { id } }) {
    return omit(state, [id]);
  },
  [deleteWebhookFailure](state, { payload: { id } }) {
    state[id].isSyncing = false;
  },
  [toggleWebhookRequest](state, { payload: { id } }) {
    state[id].isSyncing = true;
  },
  [toggleWebhookFailure](state, { payload: { id } }) {
    state[id].isSyncing = false;
  },
  [toggleWebhookSuccess](state, { payload: { id, enabled } }) {
    state[id].isSyncing = false;
    state[id].enabled = enabled;
  },
  [createWebhookSuccess](state, { payload }) {
    return {
      ...state,
      [payload._id]: payload
    };
  }
});

const isFetched = createReducer(false, {
  [fetchWebhookListRequest]() {
    return false;
  },
  [fetchWebhookListFailure]() {
    return true;
  },
  [fetchWebhookListSuccess]() {
    return true;
  }
});

const query = createReducer('', {
  [setWebhooksQuery](state, { payload }) {
    return payload;
  }
});

const webhooks = combineReducers({
  ids,
  byId,
  isFetched,
  query
});

export const getWebhookById = (state, id) => state.webhooks.byId[id];
export const getWebhooks = (state) => state.webhooks.byId;
export const getWebhookIds = (state) => state.webhooks.ids;
export const getIsFetched = (state) => state.webhooks.isFetched;
export const getWebhookQuery = (state) => state.webhooks.query;

export default webhooks;
