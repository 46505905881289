import React from 'react';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsManagerInOrganization } from '~/store/selectors/users';
import { getCurrentUserId } from '~/store/selectors/global';
import RevokePartnership from './revoke_partnership';

export const CreatedAtFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    value && moment(value).isValid()
      ? <TimeAgo date={value} />
      : t('alerts.triggered_never')
  );
};

export const CreatedAtProvider = (props) => (
  <DataTypeProvider
    formatterComponent={CreatedAtFormatter}
    {...props}
  />
);

export const OrganizationNameWithExernalId = ({ name, external_id }) => (
  <span title="Copy to clipboard" role="button" onClick={() => { navigator.clipboard.writeText(external_id); }}>
    {name}
    <small style={{ display: 'block', opacity: 0.6 }}><b>{external_id}</b></small>
  </span>
);

export const Org1NameFormatter = ({ row }) => (
  <OrganizationNameWithExernalId name={row.org1_name} external_id={row.org1_external_id} />
);

export const Org2NameFormatter = ({ row }) => (
  <OrganizationNameWithExernalId name={row.org2_name} external_id={row.org2_external_id} />
);

export const Org1NameProvider = (props) => (
  <DataTypeProvider
    formatterComponent={Org1NameFormatter}
    {...props}
  />
);

export const Org2NameProvider = (props) => (
  <DataTypeProvider
    formatterComponent={Org2NameFormatter}
    {...props}
  />
);
export const ActionsFormatter = ({ row }) => {
  const { id, org1_id } = row;
  const currentUserId = useSelector(getCurrentUserId);
  const isManagerInOrganization = useSelector((state) => getIsManagerInOrganization(state, currentUserId, org1_id));
  if (isManagerInOrganization) {
    return (
      <div className="actions">
        <RevokePartnership id={id} />
      </div>
    );
  }
  return null;
};

export const ActionsProvider = (props) => (
  <DataTypeProvider
    formatterComponent={ActionsFormatter}
    {...props}
  />
);
