/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app'; // eslint-disable-line import/no-cycle
import AppModel from 'app/backbone/lib/entities/app_model'; // eslint-disable-line import/no-cycle
import AppCollection from 'app/backbone/lib/entities/app_collection';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import Routes from 'app/config/routes';
import ValueLabelPairs from 'app/backbone/lib/concerns/entities/value_label_pairs';
import FilteredListColl from 'app/backbone/entities/abstract/filtered_list_coll'; // eslint-disable-line import/no-cycle
import { OrganizationBase, OrganizationsBase } from './abstract/org_base'; // eslint-disable-line import/no-cycle

export class UserCalibrationProfile extends AppModel {
  getConfiguration() {
    return this.pick('photometric_calibration', 'size_calibration');
  }

  getId() {
    return `${this.get('photometric_calibration')}_${this.get('size_calibration')}`;
  }
}

export class UserCalibrationProfiles extends AppCollection {
  get model() { return UserCalibrationProfile; }
}

export class Organization extends OrganizationBase {
  get defaults() { return { app: 'environet' }; }
  get jsonRootKey() { return 'organization'; }

  urlRoot() { return Routes.organizations_path(); }

  isRental() { return this.get('is_rental'); }

  isNetronix() {
    return this.get('name') === App.getChannel().request('get:default_org_name');
  }

  isMembered() {
    const orgIds = App.getChannel().request('get:current:user').get('roles').pluck('organization_id');
    return App.getChannel().request('get:current:user').isSupport() || _.contains(orgIds, this.id);
  }

  getVCard() {
    // const countryName = App.getChannel().request('get:country', this.get('shipping_address_country')).name;
    return _.getVCard({ first_name: this.get('contact_first_name'),
      last_name: this.get('contact_last_name'),
      org: this.get('name'),
      phone_work: this.get('contact_phone'),
      email: this.get('contact_email') });
  }
}

export class FilteredOrganizations extends ValueLabelPairs(FilteredCollection) {
  getManagedBy(user) {
    const memberships = this.filter((org) => user.isManagerInOrg(org));
    // eslint-disable-next-line no-use-before-define
    return api.getChannel().request('new:organizations', memberships);
  }

  getManagedAndOwnedBy(user) {
    const memberships = this.filter((org) => user.isManagerInOrg(org) || user.isOrgOwner(org));
    // eslint-disable-next-line no-use-before-define
    return api.getChannel().request('new:organizations', memberships);
  }

  getOwnedBy(user) {
    const memberships = this.filter((org) => user.isOrgOwner(org));
    // eslint-disable-next-line no-use-before-define
    return api.getChannel().request('new:organizations', memberships);
  }

  getRentalManaged(user) {
    const orgs = this.filter((org) => org.isRental() && user.isManagerInOrg(org));
    // eslint-disable-next-line no-use-before-define
    return api.getChannel().request('new:organizations', orgs);
  }
}

export class Organizations extends OrganizationsBase {
  get model() { return Organization; }

  url() { return Routes.organizations_path(); }
}

export const API = MnObject.extend({
  channelName: 'entities:organization',

  radioRequests: {
    'fetch:organizations': 'fetchOrganizationEntities',
    'fetch:organizations:list': 'fetchOrganizationEntitiesList',
    'get:filtered:organizations': 'getFilteredOrganizations',
    'get:membered:organizations': 'getMemberedOrganizationEntities',
    'fetch:organization': 'fetchOrganizationEntity',
    'new:organization': 'newOrganizationEntity',
    'new:organizations': 'getOrganizationsColl'
  },

  getOrganizationsColl(orgs) {
    if (!orgs) { return null; }
    return new Organizations(orgs);
  },

  fetchOrganizationEntity(id) {
    const organization = new Organization({ _id: id });
    organization.fetch();
    return organization;
  },

  fetchOrganizationEntitiesList() {
    return this.fetchOrganizationEntities({
      only: 'id,slug,name,destroyable,managed,membered,modifiable,partnered,is_rental,project_id'
    });
  },

  fetchOrganizationEntities(params = {}) {
    const orgs = new Organizations();
    orgs.fetch({
      data: params,
      reset: true
    });
    return orgs;
  },

  getMemberedOrganizationEntities(orgs) {
    const filteredOrgs = new FilteredOrganizations(orgs);
    filteredOrgs.setSort((org) => org.get('name').toLowerCase());
    filteredOrgs.filterBy('membered', (org) => org.isMembered());
    return filteredOrgs;
  },

  getFilteredOrganizations(orgs, filterInputModel) {
    const filteredOrgs = new FilteredListColl(orgs, { filterInputModel });
    filteredOrgs.setSort((org) => org.get('name').toLowerCase());
    filteredOrgs.filterBy('membered', (org) => org.isMembered());
    return filteredOrgs;
  },

  newOrganizationEntity() {
    return new Organization();
  }
});

export const api = new API();
