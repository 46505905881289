import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PermissionForm from '~/components/permissions_form';
import { getCurrentUserId } from '~/store/selectors/global';
import { deleteUserPermissionsRequest } from '~/store/reducers/users';
import { getCurrentUserIsAdmin } from '~/store/selectors/current_user';
import { getUserByIdRolesOrganizationsValueLabelPairs } from '~/store/selectors/users';
import { getManagedOrganizationsValueLabelPairsWithExternalId } from '~/store/selectors/organizations';
import { fetchOrganizationsListRequest } from '~/store/reducers/organizations';
import { getProfileIsSyncing } from '~/store/selectors/profile';
import { RoleBlock, RolesInfoBlock } from '~/components/permissions_form/roles_field/styles';

const AccessForm = ({
  syncing,
  isAdmin,
  organizationsRolesOptions,
  currentUserId,
  organizationsOptions,
  handleDeleteUserPermissionsRequest,
  handleFetchOrganizationsListRequest
}) => {
  const { t } = useTranslation();
  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.roles_updated'),
      { toastId: 'success_roles_updated' }
    );
    handleFetchOrganizationsListRequest();
  }, [t, handleFetchOrganizationsListRequest]);

  const handleSubmit = useCallback((permissions) => {
    const deletedOrganizations = permissions.filter(({ deleted }) => deleted);
    if (deletedOrganizations.length) {
      handleDeleteUserPermissionsRequest({
        externalIds: deletedOrganizations.map(({ organization: { external_id } }) => external_id),
        userId: currentUserId,
        onSuccess: handleSuccess
      });
    }
  }, [handleDeleteUserPermissionsRequest, handleSuccess, currentUserId]);

  return (
    <>
      {!isAdmin ? (
        <>
          {organizationsRolesOptions.map(({ role: { value: role }, organization: { label: organizationName } }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <RoleBlock key={`${role}${organizationName}${index}`}>
              {`${t(`users.roles.${role}_at`)} ${organizationName}`}
            </RoleBlock>
          ))}
          <RolesInfoBlock>{t('user_profile.roles_info')}</RolesInfoBlock>
        </>
      ) : (
        <PermissionForm
          syncing={syncing}
          organizationsOptions={organizationsOptions}
          organizationsRolesOptions={organizationsRolesOptions}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: getCurrentUserIsAdmin(state),
  syncing: getProfileIsSyncing(state),
  currentUserId: getCurrentUserId(state),
  organizationsOptions: getManagedOrganizationsValueLabelPairsWithExternalId(state),
  organizationsRolesOptions: getUserByIdRolesOrganizationsValueLabelPairs(state, getCurrentUserId(state))
});

const mapDispatchToProps = {
  handleDeleteUserPermissionsRequest: deleteUserPermissionsRequest,
  handleFetchOrganizationsListRequest: fetchOrganizationsListRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessForm);
