import { createContext } from 'react';

const ManageDevicesContext = createContext({
  isActive: false,
  selectedDevices: [],
  setSelectedDevices: () => {},
  setIsActive: () => {}
});

export default ManageDevicesContext;
