import React from 'react';
import get from 'lodash/get';
import attributeName from 'app/config/hbs-helpers/attributeName';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { CreatedAtFormatter } from '../formatters';

const ParameterBlockStyled = styled.ul`
  li {
    padding: 5px 0;
    b {
      display: block;
    }
  }
`;

const ParameterBlockContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  > div {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 8px 16px;
  }
`;

const ParameterBlock = ({
  parameter
}) => (
  <div>
    <ParameterBlockStyled>
      <li>
        {get(parameter, 'name')}
      </li>
      <li>
        <b>{attributeName('calibration', 'timestamp')}</b>
        <CreatedAtFormatter value={get(parameter, 'timestamp')} />
      </li>
      <li>
        <b>{attributeName('calibration', 'reference_value')}</b>
        {`[${get(parameter, 'reference.serial')}] ${get(parameter, 'reference.sensor')}`}
      </li>
      <li>
        <b>{attributeName('calibration', 'status')}</b>
        {attributeName('calibration.statuses', parameter.status)}
      </li>
      <li>
        <b>{attributeName('calibration', 'metrics')}</b>
        {Object.entries(get(parameter, 'metrics', {})).map(([key, value]) => `${key}: ${_.round(value, 2)}`).join(', ')}
      </li>
    </ParameterBlockStyled>
  </div>
);

const GridDetailContainer = ({ row }) => {
  const {
    parameters
  } = row;
  return (
    <Table>
      <tbody>
        <tr>
          <td />
          <td colSpan={7}>
            <ParameterBlockContainer>
              {parameters.map((parameter) => <ParameterBlock parameter={parameter} key={`${parameter.name}_parameter`} />)}
            </ParameterBlockContainer>

          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default GridDetailContainer;
