/* eslint-disable no-nested-ternary */
import React from 'react';

const ExpandButton = (props) => {
  const { row: { status } } = props;

  return (
    <td colSpan={1} className="expand-button">
      {
        status === 'available' ? (
          <a className="info" role="button">
            <i className="fa fa-md fa-fw fa-info" />
          </a>
        ) : (<span className="info empty" />)
      }
    </td>
  );
};

export default ExpandButton;
