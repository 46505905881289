import { createSelector } from 'reselect';
import * as fromPartnerships from '~/store/reducers/partnerships';

export const getPartnerships = (state) => fromPartnerships.getPartnerships(state);
export const getPartnershipByIdIsSyncing = (state, partnerId) => fromPartnerships.getPartnershipByIdIsSyncing(state, partnerId);
export const getPartnershipByIdIsSynced = (state, partnerId) => fromPartnerships.getPartnershipByIdIsSynced(state, partnerId);
export const getPartnershipByIdSharedDevices = (state, partnerId) => fromPartnerships.getPartnershipByIdSharedDevices(state, partnerId) || [];
export const getPartnershipByIdLeasedDevices = (state, partnerId) => fromPartnerships.getPartnershipByIdLeasedDevices(state, partnerId) || [];

export const getPartnershipsValueLabelPairs = createSelector(
  getPartnerships,
  (partnerships) => Object.values(partnerships).map((partnership) => ({
    label: partnership.org2_name,
    value: partnership.org2_id
  }))
);

export const getPartnershipByIdOrganizationId = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org1_id
);

export const getPartnershipByIdOrganization2Id = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_id
);

export const getPartnershipByIdContactPhone = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_contact_phone
);

export const getPartnershipByIdContactEmail = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_contact_email
);

export const getPartnershipByIdShippingAddressStreet = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_shipping_address_street
);

export const getPartnershipByIdShippingAddressCity = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_shipping_address_city
);

export const getPartnershipByIdShippingAddressState = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_shipping_address_state
);

export const getPartnershipByIdShippingAddressZipCode = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_shipping_address_zip_code
);

export const getPartnershipByIdShippingAddressCountry = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.org2_shipping_address_country
);

export const getPartnershipByIdIsDestroyable = createSelector(
  fromPartnerships.getPartnershipById,
  (partnership) => partnership?.destroyable
);
