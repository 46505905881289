/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import AppModel from 'app/backbone/lib/entities/app_model';
import Routes from 'app/config/routes';

export default class AdminContext extends AppModel {
  get transientAttrs() {
    return ['option'];
  }

  get validation() {
    return {
      email: [{
        required(value, attr, computedState) {
          return computedState.option === 'email';
        }
      }, {
        pattern: 'email'
      }],
      external_id: [{
        required(value, attr, computedState) {
          return computedState.option === 'org';
        }
      },
      {
        pattern: 'externalId',
        msg() {
          return 'invalid External ID, ex. \'AC123456\'';
        }
      }]
    };
  }

  url() { return Routes.admin_access_context_path(); }

  store() {
    // eslint-disable-next-line no-use-before-define
    return api.getChannel().request('store:admin_context', this);
  }
}

export const API = MnObject.extend({
  channelName: 'entities:designed_access_context',

  radioRequests: {
    'store:admin_context': 'storeAdminContext',
    'get:admin_context': 'getAdminContext'
  },

  storeAdminContext(adminContext) {
    return this.adminContext = _.pick(adminContext.toJSON(), 'email', 'external_id');
  },

  getAdminContext() {
    return new AdminContext(this.adminContext);
  },

  clearAdminContextHeader() {
    this.adminContext = null;
    return this.adminContextHeader = null;
  },

  setAdminContextHeader(header) {
    return this.adminContextHeader = header;
  }
});

export const api = new API();
