import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const setGlobalOrganizationId = createAction('@@global/SET_ORGANIZATION_ID');
export const fetchUserProfileSuccess = createAction('@@userProfile/FETCH_SUCCESS');

const currentUserId = createReducer(null, {
  [fetchUserProfileSuccess](state, { payload: { result } }) {
    return result || state;
  }
});

const initialOrganization = { value: 'all', label: 'All' };
const organization = createReducer(initialOrganization, {
  [setGlobalOrganizationId](state, { payload }) {
    return payload;
  }
});

const global = combineReducers({
  organization,
  currentUserId
});

export const getGlobalOrganization = (state) => state.global.organization;
export const getCurrentUserId = (state) => state.global.currentUserId;

export default global;
