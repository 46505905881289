import React, { useCallback } from 'react';
import { app as App } from 'app/backbone/app';

const CreateOrganizationActions = React.memo(({ handleSubmit }) => {
  const handleCancel = useCallback(() => {
    App.navigate('organizations');
  }, []);

  return (
    <div className="form-actions">
      <button
        type="button"
        className="btn btn-default form-cancel"
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        type="submit"
        className="btn btn-primary save-form"
        onClick={handleSubmit}
      >
        Save
      </button>
    </div>
  );
});

export default CreateOrganizationActions;
