/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';
import { api as timezonesApi } from 'app/backbone/entities/abstract/timezones';
import { api as countriesApi } from 'app/backbone/entities/abstract/countries';
import { api as projectApi } from 'app/backbone/entities/project';

class Router extends AppRouter {
  initialize({ controller }) {
    this.controller = controller;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    return this.appRoute(/^profile$/, 'editProfile');
  }
}

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:profile': 'editProfile'
  },

  editProfile() {
    App.navigate('profile');
    App.getChannel().trigger('nav:choose', 'profile');
  }
});

export const api = new API();

const controller = {
  editProfile() {
    const promise = import('./edit/edit_controller');
    promise.then((EditController) => {
      // eslint-disable-next-line new-cap
      const editController = new EditController.default({
        model: App.getChannel().request('get:current:user'),
        phonePrefixes: App.getChannel().request('get:phone_prefixes'),
        locales: App.getChannel().request('get:locales'),
        timeZones: timezonesApi.getChannel().request('get:timezones'),
        countries: countriesApi.getChannel().request('get:countries'),
        projects: projectApi.getChannel().request('fetch:projects'),
        noticeMethods: App.getChannel().request('get:user_notices:methods'),
        usStates: countriesApi.getChannel().request('get:us_states'),
        organizations: App.getChannel().request('get:organizations')
      });
      return editController.editProfile();
    });
    return promise;
  }
};

const ProfileApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default ProfileApp;
