import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import includes from 'lodash/includes';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getNodeById } from '~/store/reducers/nodes';
import ManageDevicesContext from '../../../manage_context';
import ModalOrganizationDropdown from './modal_organization';
import ModalOrganizationPlain from './modal_organization_plain';
import ModalTable from './modal_table';

const ActionsModalForm = ({ type }) => {
  const { change } = useForm();
  const { selectedDevices } = useContext(ManageDevicesContext);
  const { t } = useTranslation();

  const getDisabled = useSelector((state) => (selected) => {
    const nodes = selectedDevices.map((deviceId) => getNodeById(state, deviceId));
    const disabled = [];
    if ((type === 'share' || type === 'remove_share') && selected) {
      nodes.forEach(({ shared_to, _id }) => {
        if (type === 'share' && includes(shared_to || [], selected)) {
          disabled.push(_id);
        }
        if (type === 'remove_share' && !includes(shared_to || [], selected)) {
          disabled.push(_id);
        }
      });
    }
    return disabled;
  });

  const isShareLeaseType = useMemo(() => includes(['share', 'lease', 'remove_share'], type), [type]);

  const helpText = useMemo(() => {
    switch (type) {
      case 'share':
        return t('thiamis.manage_dialog.share_help_text');
      case 'lease':
        return t('thiamis.manage_dialog.lease_help_text');
      case 'transfer':
        return t('thiamis.manage_dialog.transfer_help_text');
      case 'delete':
        return t('thiamis.manage_dialog.reset_help_text');
      default:
        return '';
    }
  }, [t, type]);

  return (
    <>
      <div className="form-region">
        {
          isShareLeaseType
            ? (
              <ModalOrganizationDropdown type={type} />
            )
            : <ModalOrganizationPlain type={type} />
        }
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-12">
            <div className="table-responsive manage-node-table table-region">
              <ModalTable type={type} />
            </div>
            <p className="help-block">
              {helpText}
            </p>
          </div>
        </div>
      </div>
      <OnChange
        name="selectedOrganization"
      >
        {(selectedOrganization) => {
          change('disabledNodes', getDisabled(selectedOrganization?.value));
          return null;
        }}
      </OnChange>
    </>
  );
};

export default ActionsModalForm;
