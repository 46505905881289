/* eslint-disable no-nested-ternary */
import React from 'react';

const ExpandButton = (props = {}) => {
  const { onClick } = props;
  return (
    <td colSpan={1} className="expand-button">
      <a className="info" role="button" onClick={onClick}>
        <i className="fa fa-md fa-fw fa-info" />
      </a>
    </td>
  );
};

export default ExpandButton;
