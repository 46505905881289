/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from 'react';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Panel } from 'react-bootstrap';
import humanizeDuration from 'humanize-duration';
import I18n from 'app/config/i18n';
import moment from 'app/config/moment';
import HeatMap from 'app/backbone/lib/components/shared_views/heatmap';
import { withTranslation } from 'react-i18next';
import { getDataPointByIdNodeId } from '~/store/selectors/data_points';
import { getAlertByIdName } from '~/store/selectors/alerts';
import { getAlertDurationHeatmapData } from '../../selectors';
import { exportTableDataRequest } from '../../reducer';
import ThiamisTitle from '../thiamis_title';
import TableContainer from './table_container';

class StatsItem extends Component {
  constructor(props) {
    super(props);
    this.heatmapEl = React.createRef();
    this.heatmap = null;
    this.state = {
      selectedDate: null,
      prevHidden: false,
      nextHidden: false
    };
  }

  componentDidMount() {
    const { heatmapData } = this.props;
    this.getHeatmap({
      itemSelector: this.heatmapEl.current,
      data: heatmapData,
      minDate: moment(first(Object.keys(heatmapData)) * 1000).toDate()
    }).init();
  }

  componentWillUnmount() {
    if (typeof this.getHeatmap().destroy === 'function') {
      this.getHeatmap().destroy();
    }
  }

  handleRemoveDateSelected = () => {
    this.getHeatmap().getCalHeatMap().highlight(moment().toDate());
    this.setState({ selectedDate: null });
  };

  handlePrevHeatmapClick = () => {
    this.getHeatmap().getCalHeatMap().previous();
    this.setState({ nextHidden: false });
  }

  handleNextHeatmapClick = () => {
    this.getHeatmap().getCalHeatMap().next();
    this.setState({ prevHidden: false });
  };

  handleHeatmapClick = (date, duration) => {
    if (!duration) { return; }
    this.getHeatmap().getCalHeatMap().highlight(date);
    this.setState({ selectedDate: date });
  };

  onMinDomainReached = () => {
    this.setState({ prevHidden: true });
  };

  onMaxDomainReached = () => {
    this.setState({ nextHidden: true });
  };

  handleDownloadHeatmapClick = () => {
    const { handleExportTableDataRequest, dataPointId } = this.props;
    handleExportTableDataRequest(dataPointId);
  };

  getHeatmap(options) {
    if (this.heatmap) {
      return this.heatmap;
    }
    const { heatmapData } = this.props;
    this.heatmap = new HeatMap({
      ...options,
      itemName: ['minute', 'minutes'],
      itemNamespace: 'duration-hm',
      legend: [1, 15, 60, 180],
      onClick: this.handleHeatmapClick,
      minDate: moment(first(Object.keys(heatmapData)) * 1000).toDate(),
      onMinDomainReached: this.onMinDomainReached,
      onMaxDomainReached: this.onMaxDomainReached,
      onTooltip: (dateObject, duration) => {
        const date = this.heatmap.dateFormat(dateObject, '%A, %B %-e');
        if (!duration) { return date; }
        if (duration === 60 * 24) {
          return I18n.t('alerts.events.duration_hm_day_tooltip', { date });
        }
        const humanizedDuration = humanizeDuration(duration * 60 * 1000, { round: true });
        return I18n.t('alerts.events.duration_hm_tooltip', { duration: humanizedDuration, date });
      }
    });
    return this.heatmap;
  }

  render() {
    const {
      dataPointId, t, alertId, heatmapData, alertName, defaultExpanded
    } = this.props;
    const {
      selectedDate, prevHidden, nextHidden
    } = this.state;
    return (
      <Panel
        id={`panel-condition-${dataPointId}`}
        defaultExpanded={defaultExpanded}
        className={classNames('form-box', { 'daily-view': selectedDate, empty: isEmpty(heatmapData) })}
      >
        <Panel.Title toggle>
          <div className="thiamis-status">
            <ThiamisTitle dataPointId={dataPointId} alertId={alertId} />
          </div>
        </Panel.Title>
        {
          !isEmpty(heatmapData)
          && (
            <button type="button" className="btn btn-default btn-sm download" onClick={this.handleDownloadHeatmapClick}>
              <i className="fa fa-fw fa-download" />
              {t('alerts.events.download_csv')}
            </button>
          )
        }
        <Panel.Collapse>
          <Panel.Body>
            <div className="heatmap-container">
              <div className={classNames('prev-btn', { hidden: prevHidden })}>
                <a title={t('alerts.events.prev_month')} onClick={this.handlePrevHeatmapClick} role="button">
                  <i className="fa fa-lg fa-long-arrow-left" />
                </a>
              </div>
              <div className="heatmap-wrap duration-hm-wrap">
                <div className="duration-hm" ref={this.heatmapEl} />
                <div className="daily-info">
                  <span>
                    {`${t('alerts.events.showing')}:`}
                  </span>
                  <label className="tag tag-sm reset-daily">
                    <a role="button" title={t('alerts.events.reset_date_selection')} onClick={this.handleRemoveDateSelected}>
                      <i className="fa fa-xs fa-remove" />
                    </a>
                    { selectedDate && (<span>{ this.getHeatmap().dateFormat(selectedDate) }</span>) }
                  </label>
                </div>
              </div>
              <div className="thiamis-time" />
              <div className={classNames('next-btn', { hidden: nextHidden })}>
                <a title={t('alerts.events.next_month')} onClick={this.handleNextHeatmapClick} role="button">
                  <i className="fa fa-lg fa-long-arrow-right" />
                </a>
              </div>
            </div>
            <div className="alert-list-table-container row">
              <TableContainer selectedDate={selectedDate} dataPointId={dataPointId} alertName={alertName} />
            </div>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

const mapStateToProps = (state, { dataPointId }) => {
  const alertId = getDataPointByIdNodeId(state, dataPointId);
  return {
    alertName: getAlertByIdName(state, alertId),
    heatmapData: getAlertDurationHeatmapData(state, dataPointId)
  };
};

export default withTranslation()(connect(mapStateToProps, {
  handleExportTableDataRequest: exportTableDataRequest
})(StatsItem));
