import React from 'react';
import ReactList from 'react-list';
import classNames from 'classnames';
import I18n from 'app/config/i18n';
import ListTableRow from 'app/backbone/lib/react/components/ListTable/ListTableRow';
import Checkbox from 'app/backbone/lib/react/components/common/Checkbox/Checkbox';

export const ListTableHoc = (WrappedComponent) => {
  class ListTable extends WrappedComponent {
    state = {
      isChosen: false
    };

    componentDidMount() {
      const { collection, pagination } = this.props;
      if (pagination) {
        _.defer(() => collection.setPerPage(pagination.perPage));
      }
      collection.on('add', this.updateOnAdd);
      collection.on('reset sort destroy', () => this.forceUpdate());
      collection.on('unchoose:all', () => {
        if (this.props.choosableAll) { return this.setState({ isChosen: false }); }
      });
      collection.on('change:chosen', () => this.setState({ isChosen: this.allIsChosen() }));
      this.updateOnAdd = _.throttle(() => {
        if (collection.isLive) {
          if (collection.isLive()) {
            return this.forceUpdate();
          } return null;
        }
        return this.forceUpdate();
      }, 1000, { leading: false });

      const sortable = typeof collection.getSort === 'function' ? collection.getSort() : undefined;
      if (sortable) {
        return this.setState({
          sortName: sortable.comparator,
          sortOrder: sortable.dirrection
        });
      }
    }

    componentWillUnmount() {
      const { collection } = this.props;
      collection.off('reset sort destroy');
      collection.off('add', this.updateOnAdd);
      return collection.off('change:chosen');
    }

    handleSortClick = (column) => {
      const name = this.getSortName(column);
      const order = this.isCurrentSort(name) ? this.getReversedSortOrder() : 'asc';
      this.setState({
        sortName: name,
        sortOrder: order
      });
      if (_.isFunction(column.sortable)) {
        return this.props.collection.setSort(column.sortable, order);
      } return this.props.collection.setSort(name, order);
    };

    createTable() {
      if (this.props.collection.size()) {
        if (this.props.pagination) {
          return this.getTable(this.props.collection.map((model, index) => this.createRowComponent(index)));
        }
        return (
          <ReactList
            itemRenderer={this.createRowComponent}
            length={this.props.collection.size()}
            itemsRenderer={this.getTable}
          />
        );
      }
      return <h5 className="text-center">{this.props.emptyListMsg}</h5>;
    }

    getTable = (items, ref) => (
      <div ref={ref} className={classNames('table', 'table-hover', 'thiamis-list', { is_opened: this.props.isOpened })}>
        <div className="thead">
          { this.props.columns.map(this.createTh) }
        </div>
        { items }
        { this.props.footer ? this.createFooterComponent() : undefined }
      </div>
    );

    getSortName(column) {
      if (_.isString(column.sortable)) { return column.sortable; } return column.name;
    }

    getReversedSortOrder() {
      if (this.state.sortOrder === 'asc') { return 'desc'; } return 'asc';
    }

    isCurrentSort(name) {
      return this.state.sortName === name;
    }

    createTh = (column, index) => (
      <div key={index} className={classNames('th', { [`${_.dasherizeField(column.name)}-col`]: column.name }, column.className)}>
        <div>
          { this.createThTitle(column) }
        </div>
      </div>
    );

    createFooterComponent() {
      return (
        <tfoot>
          <tr>
            {_.map(this.props.footer, this.createFooterColumn.bind(this))}
          </tr>
        </tfoot>
      );
    }

    createFooterColumn(column, index) {
      return (
        <div className="td" key={index} colSpan={column.colSpan}>
          { column.component
            ? this.createFooterColumnComponent(column)
            : <b>{column.title}</b> }
        </div>
      );
    }

    createFooterColumnComponent(column) {
      return React.createElement(column.component.name, column.component.props);
    }

    allIsChosen() {
      return !this.someIsUnChosen();
    }

    someIsUnChosen() {
      return this.props.collection.some((model) => !model.isChosen());
    }

    someIsChosen() {
      return this.props.collection.some((model) => model.isChosen());
    }

    handleChangeChooseAll() {
      this.props.collection.each((model) => {
        if (this.state.isChosen) {
          return model.unchoose({ silent: true });
        } return model.choose({ silent: true });
      });
      return this.setState((prevState) => ({
        isChosen: !prevState.isChosen
      }));
    }

    createThTitle(column) {
      let { title } = column;
      if (_.isObject(title)) {
        title = this.createThTitleComponent(title);
      }
      if (column.sortable) {
        let style;
        if (!_.isString(title)) {
          if (title.props['data-withtag']) { style = { top: '-5px', position: 'relative' }; }
        }
        return (
          <a
            role="button"
            tabIndex={0}
            style={style}
            className={classNames('sort', { [this.state.sortOrder]: this.isCurrentSort(this.getSortName(column)) })}
            onClick={_.partial(this.handleSortClick, column)}
          >
            {title || undefined }
            <i className="fa fa-sm" />
          </a>
        );
      } if (column.choosable && this.props.choosableAll) {
        return this.createChooseAllCheckbox();
      }
      return title;
    }

    createChooseAllCheckbox() {
      return (
        <Checkbox
          isChosen={this.state.isChosen}
          handleChange={this.handleChangeChooseAll}
          indeterminate={this.someIsUnChosen() && this.someIsChosen()}
        />
      );
    }

    createRowComponent = (index) => {
      const entry = this.props.collection.at(index);
      const props = _.extend({ model: entry, key: entry.cid }, _.pick(this.props, 'columns', 'expandable', 'isOpened', 'choosableAll'));
      if (this.props.choosable) {
        props.isChosen = entry.isChosen();
      }
      if (this.props.getTbodyClassName) {
        props.tbodyClassName = this.props.getTbodyClassName(entry);
      }
      return <ListTableRow {...props} />;
    };

    createThTitleComponent(title) {
      if (title.component.name) {
        return React.createElement(title.component.name,
          _.extend({ collection: this.props.collection }, _.pick(this.props, 'isRequesting')));
      }
      return title.component;
    }
  }

  ListTable.defaultProps = {
    choosableAll: false,
    pagination: false,
    expandable: {},
    emptyListMsg: I18n.t('base.labels.no_data_available')
  };

  return ListTable;
};

export default ListTableHoc;
