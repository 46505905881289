import {
  call,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';
import { normalizeResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import requestApi from '~/utils/axios';
import * as Actions from './reducer';

export function* fetchReferenceDevicesRequestSaga() {
  try {
    const { data } = yield call(
      requestApi,
      'get',
      'calibrations/references'
    );
    yield put(Actions.fetchRerefenceDevicesSuccess(data));
  } catch (e) {
    yield put(Actions.fetchRerefenceDevicesFailure(e.toString()));
  }
}

function* watchFetchReferenceDevicesRequest() {
  yield takeLatest(Actions.fetchRerefenceDevicesRequest.toString(), fetchReferenceDevicesRequestSaga);
}

export function* calibrationFetchRequestSaga() {
  try {
    const { data } = yield call(
      requestApi,
      'get',
      'calibrations'
    );
    const response = normalizeResp(data, schema.calibrationsListSchema);
    yield put(Actions.fetchCalibrationsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchCalibrationsFailure(e.toString()));
  }
}

function* watchFetchCalibrationRequest() {
  yield takeLatest(Actions.fetchCalibrationsRequest.toString(), calibrationFetchRequestSaga);
}

export function* calibrationCreateRequestSaga({ payload }) {
  try {
    const {
      from, to, reference, serials, onSuccess
    } = payload;

    const { data } = yield call(
      requestApi,
      'post',
      'calibrations',
      {
        serials, from, to, reference
      }
    );

    yield put(Actions.createCalibrationSuccess());
    if (onSuccess) {
      onSuccess(data);
    }
  } catch (e) {
    yield put(Actions.createCalibrationFailure(e.toString()));
  }
}

function* watchCreateCalibrationRequest() {
  yield takeLatest(Actions.createCalibrationRequest.toString(), calibrationCreateRequestSaga);
}

export default function* app() {
  yield fork(watchFetchCalibrationRequest);
  yield fork(watchFetchReferenceDevicesRequest);
  yield fork(watchCreateCalibrationRequest);
}
