import { createReducer, createAction, combineReducers } from '@reduxjs/toolkit';

export const fetchStatsRequest = createAction('@@stats/FETCH_REQUEST');
export const fetchStatsSuccess = createAction('@@stats/FETCH_SUCCESS');
export const fetchStatsFailure = createAction('@@stats/FETCH_FAILURE');

const byId = createReducer({}, {
  [fetchStatsSuccess](state, { payload }) {
    if (payload) {
      return payload;
    }
    return state;
  }
});

const isFetched = createReducer(false, {
  [fetchStatsRequest]() {
    return false;
  },
  [fetchStatsFailure]() {
    return false;
  },
  [fetchStatsSuccess]() {
    return true;
  }
});

const stats = combineReducers({
  byId,
  isFetched
});

export const getStats = (state) => state.stats.byId;
export const getIsFetched = (state) => state.stats.isFetched;

export default stats;
