import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInvitationsRequest } from '~/store/reducers/invitations';
import { getMemberedOrganizations } from '~/store/selectors/organizations';
import FiltersFormTop from './filters_form_top';
import GridContainer from './grid_container';
import InvitationsList from './invitations';
import { TABS } from './filters_form_top/tabs';

const UsersScreen = (props) => {
  const { slug } = props;
  const memberedOrgs = useSelector(getMemberedOrganizations);
  const [tab, setTab] = useState(TABS.USERS);
  const handleSelectTab = useCallback((key) => setTab(key), []);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all' && memberedOrgs.length > 1) {
      data.organization_id = slug;
    }
    dispatch(fetchUserInvitationsRequest({ data }));
  }, [dispatch, slug, memberedOrgs]);

  return (
    <div className="content-region fill-height content">
      <div className="users-container">
        <Tab.Container id="tabs-list" defaultActiveKey={TABS.USERS} onSelect={handleSelectTab}>
          <Row className="clearfix">
            <Col sm={12}>
              <FiltersFormTop />
            </Col>
            <Col sm={12}>
              <Tab.Content animation={false} bsClass="tab-content list">
                <Tab.Pane eventKey={TABS.USERS} bsClass="list-content">
                  <GridContainer isActive={tab === TABS.USERS} slug={slug} />
                </Tab.Pane>
                <Tab.Pane eventKey={TABS.INVITATIONS} mountOnEnter bsClass="list-content">
                  <InvitationsList isActive={tab === TABS.INVITATIONS} slug={slug} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default UsersScreen;
