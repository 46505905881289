import React, { useCallback } from 'react';
import styled from 'styled-components';
import { HelpBlock, FormGroup } from 'react-bootstrap';
import uploadcare from 'uploadcare-widget';
import { useTranslation } from 'react-i18next';

const TextBlock = styled.div`
  color: #fff !important;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 5em;
  background: #e79f3c;
  align-items: center;
  justify-content: center;
`;

const PhotoUpload = React.memo(({
  photo, setPhoto, errorText, helpText, className, textPlaceholder = false, placeholder, cropConfig = '1:1', uploadcareKey = process.env.UPLOADCARE_PUBLIC_KEY
}) => {
  const { t } = useTranslation();
  const handleChangePicture = useCallback(() => uploadcare.openDialog(null, {
    publicKey: uploadcareKey,
    crop: cropConfig,
    imagesOnly: true
  }).done((file) => file.promise()
    .done((fileInfo) => {
      const {
        originalUrl,
        crop
      } = fileInfo;
      const url = `${originalUrl}-/crop/${crop.width}x${crop.height}/${crop.left},${crop.top}/`;
      setPhoto(url);
    })), [setPhoto, cropConfig, uploadcareKey]);
  return (
    <FormGroup validationState={errorText ? 'error' : undefined}>
      <div
        role="button"
        className={`${className} center-block ${errorText ? 'is-invalid' : ''}`}
        onClick={handleChangePicture}
      >
        {
          photo
            ? (<img className="img-responsive" src={photo} alt="" />)
            : (
              <>
                {textPlaceholder ? <TextBlock>{placeholder}</TextBlock> : <img src={placeholder} alt="" />}
              </>
            )
        }
      </div>
      {
        errorText && (
          <HelpBlock>
            {errorText}
          </HelpBlock>
        )
      }
      {
        helpText && !errorText && (
          <HelpBlock>
            {helpText}
          </HelpBlock>
        )
      }
      <div
        className="change-picture text-center"
        onClick={handleChangePicture}
        role="button"
      >
        <i className="fa fa-sm fa-cog" />
        {` ${t('user_profile.change_picture')}`}
      </div>
    </FormGroup>
  );
});

export default PhotoUpload;
