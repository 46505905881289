import React, { useRef, useEffect } from 'react';
import _ from 'lodash';

const { API_URL } = process.env;

const DownloadForm = ({
  actionUrl, method, model, callback = null
}) => {
  const formRef = useRef(null);

  const handleDownloadForm = () => _.defer(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
    if (callback) {
      // make sure submit is triggered
      setTimeout(() => {
        callback();
      }, 500);
    }
  });

  useEffect(() => {
    // make sure form is rendered
    setTimeout(() => {
      handleDownloadForm();
    }, 500);
  });

  const createInput = (value, name, index) => {
    if (_.isArray(value)) {
      return _.map(value, (_value, _index) => createInput(_value, `${name}[]`, _index));
    }
    return <input type="hidden" value={value} name={name} key={_.isNumber(index) ? `${name}_${index}` : name} />;
  };

  return (
    <form
      action={`${API_URL}/${actionUrl}`}
      method={method}
      ref={formRef}
    >
      <fieldset>
        { _(model).chain().map(createInput).flatten()
          .value() }
      </fieldset>
    </form>
  );
};

export default DownloadForm;
