import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Nav, NavItem } from 'react-bootstrap';
import AccessForm from './access_form';
import AccountForm from './account_form';
import ResetPasswordForm from './password_reset_form';
import { TabContainer, PanelContainer, ProfileContainer } from './styles';

const TABS = {
  ACCESS: 'access',
  ACCOUNT: 'account',
  NOTIFICATIONS: 'notifications',
  RESET_PASSWORD: 'reset-password'
};

const Profile = () => {
  const { t } = useTranslation();

  return (
    <ProfileContainer>
      <Tab.Container id="profile-tabs" defaultActiveKey={TABS.ACCOUNT}>
        <>
          <TabContainer>
            <Nav bsStyle="tabs">
              <NavItem eventKey={TABS.ACCOUNT}>
                <i className="fa fa-md fa-fw fa-info-circle" />
                <span className="title">
                  {t('user_profile.general_info')}
                </span>
              </NavItem>
              {/* <NavItem eventKey={TABS.NOTIFICATIONS}>
                <i className="fa fa-md fa-fw fa-bell" />
                <span className="title">
                  {t('filters.message.type.notifications')}
                </span>
              </NavItem> */}
              <NavItem eventKey={TABS.ACCESS}>
                <i className="fa fa-md fa-fw fa-group" />
                <span className="title">
                  {t('users.roles_title')}
                </span>
              </NavItem>
              <NavItem eventKey={TABS.RESET_PASSWORD}>
                <i className="fa fa-md fa-fw fa-lock" />
                <span className="title">
                  {t('user_profile.change_password')}
                </span>
              </NavItem>
            </Nav>
          </TabContainer>
          <Tab.Content animation>
            <Tab.Pane eventKey={TABS.ACCOUNT}>
              <PanelContainer>
                <AccountForm />
              </PanelContainer>
            </Tab.Pane>
            {/* <Tab.Pane eventKey={TABS.NOTIFICATIONS}>
              <PanelContainer>
                <NotificationsForm />
              </PanelContainer>
            </Tab.Pane> */}
            <Tab.Pane eventKey={TABS.ACCESS}>
              <PanelContainer>
                <AccessForm />
              </PanelContainer>
            </Tab.Pane>
            <Tab.Pane eventKey={TABS.RESET_PASSWORD}>
              <PanelContainer>
                <ResetPasswordForm />
              </PanelContainer>
            </Tab.Pane>
          </Tab.Content>
        </>
      </Tab.Container>
    </ProfileContainer>
  );
};

export default Profile;
