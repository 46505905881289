/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { View, CollectionView } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import I18n from 'app/config/i18n';
import Syncable from 'app/backbone/lib/concerns/views/syncable';
import WidgetsConfig from 'app/backbone/lib/widgets/config';
import FormControls from 'app/backbone/lib/concerns/views/form_controls';
import MonitorConfig from 'app/backbone/apps/thiamises/monitor/widgets/config';
import { __guardMethod__ } from 'app/utils/custom-fns';
import widgetFaceLayoutTemplate from './templates/widget_face_layout.hbs';
import widgetConfLayoutTemplate from './templates/widget_conf_layout.hbs';

require('webpack-jquery-ui/resizable');

export const WidgetLayout = View.extend({
  behaviors: [Syncable],
  template: widgetFaceLayoutTemplate,

  regions: {
    titleRegion: '.title',
    contentRegion: '.content-region'
  },

  triggers: {
    'click .handle a.action-config': 'click:config',
    'click .handle a.action-remove': 'click:remove',
    'click .handle a.action-resize': 'click:resize'
  },

  modelEvents: {
    'change:selected_data_point_ids': 'resetWidgetContent',
    'change:config': 'showWidgetContent'
  },

  className() {
    return `widget ${_(this.widgetType()).underscored()}`;
  },

  bindings() {
    return {
      ':el': {
        observe: 'size',
        initialize($el, model) {
          if (model.has('resizable')) {
            $el.resizable({
              handles: {
                nw: $el.find('.nwgrip'),
                ne: $el.find('.negrip'),
                sw: $el.find('.swgrip'),
                se: $el.find('.segrip')
              },
              stop(event, { size }) {
                model.set('size', JSON.stringify(size));
              },
              maxWidth: $el.parent().width(),
              refreshPositions: true
            });
          }
        },
        update($el, val, model) {
          if (_.isNumber(val) || _.isEmpty(val)) {
            $el
              .toggleClass('col-sm-4', model.isSmall())
              .toggleClass('col-sm-8', !model.isSmall())
              .toggleClass('height-extended', !model.isSmall());
          } else {
            try {
              const { width, height } = JSON.parse(val);
              $el
                .width(width)
                .height(height);
            } catch (e) {
              console.warn('Error processing widget size JSON', e); // eslint-disable-line no-console
            }
          }
          _.defer(() => {
            const contentView = this.getContentView();
            if (contentView.isRendered()) {
              contentView.triggerMethod('after:resize');
            }
            this.triggerMethod('after:resize');
            App.getChannel().trigger('window:resized');
          });
        }
      },
      '.title': {
        observe: 'node_id',
        update: ($el) => {
          const widgetTitle = this._getWidgetTitle();
          if (widgetTitle instanceof View || widgetTitle instanceof CollectionView) {
            return this.getRegion('titleRegion').show(widgetTitle);
          }
          if (React.isValidElement(widgetTitle)) {
            return ReactDOM.render(widgetTitle, $el[0]);
          }
          $el.html(widgetTitle);
        }
      }
    };
  },

  onDragged(newPosition) {
    if (this.model.get('position') !== newPosition) {
      this.model.set('position', newPosition);
      const contentView = this.getContentView();
      return contentView.triggerMethod('dragged');
    }
  },

  onClickRemove() {
    return this.model.destroy({ wait: true });
  },

  onClickResize() {
    this.triggerMethod('resize');
    const contentView = this.getContentView();
    return contentView.triggerMethod('resize');
  },

  serializeData() {
    const allButtons = this.getWidgetConfigs().buttons;
    const widgetButtons = this.getWidgetConfigs().widgets[this.widgetType()].buttons;
    return {
      resizable: this.model.has('resizable'),
      buttons: _.map(_.intersection(allButtons, widgetButtons || allButtons), (button) => ({ title: I18n.t(`widgets.actions.${button}`), name: button }))
    };
  },

  resetWidgetContent() {
    this.contentView.destroy();
    this.contentView = null;
    return this.showWidgetContent();
  },

  showWidgetContent() {
    const contentView = this.getContentView();
    return this.getRegion('contentRegion').show(contentView);
  },

  componentViewOptions() {
    return _(this.options).pick(this.getWidgetConfigs().requiredOptionNames);
  },

  getContentView() {
    if (this.contentView) { return this.contentView; }
    const FaceViewClass = this.getWidgetConfigs().widgets[this.widgetType()].faceView();
    return this.contentView = new FaceViewClass(_(this.componentViewOptions()).extend({ model: this.model }));
  },

  widgetType() {
    return this.model.get('_type');
  },

  getWidgetConfigs() {
    return WidgetsConfig;
  },

  onAttach() {
    return this.showWidgetContent();
  },

  onRender() {
    return this.stickit();
  },

  _getWidgetTitle() {
    const contentView = this.getContentView();
    // eslint-disable-next-line no-use-before-define
    return this.options.title || __guardMethod__(contentView, 'getWidgetTitle', (o) => o.getWidgetTitle());
  }
});

export const WidgetConfigForm = View.extend({
  behaviors: [FormControls],
  template: widgetConfLayoutTemplate,

  regions:
    { configRegion: '.config-region' },

  bindings: {
    '.save-form': {
      observe: '_id',
      update($el) {
        if (this.model.isNew()) {
          return $el.text(I18n.t('base.buttons.create'));
        } return $el.text(I18n.t('base.buttons.save_changes'));
      }
    },
    '.delete-form': {
      attributes: [{
        observe: '_id',
        name: 'class',
        onGet() { return 'hidden'; }
      }
      ]
    }
  },

  getConfigLayoutTitle() {
    return I18n.t(`${_(this.model.get('_type')).underscored()}.config_dialog_title`);
  }
});

export const NodeWidgetConfigLayout = WidgetConfigForm.extend({

  triggers() {
    return _(this.constructor.__super__.triggers).extend({
      'click .delete-form': 'delete:form'
    });
  },

  bindings() {
    return _(super.bindings(...arguments)).extend({ // eslint-disable-line prefer-rest-params
      '.delete-form': {
        attributes: [{
          name: 'class',
          onGet() {
            if (this.model.isNew()) { return 'hidden'; }
          }
        }
        ]
      }
    });
  },

  onDeleteForm() {
    this.$el.find('.delete-form').attr('disabled', true);
    return this.model.destroy({
      wait: true,
      success() {
        return App.dialogRegion.hideDialog();
      },
      error: (model, xhr) => {
        this.$el.find('.delete-form').attr('disabled', false);
        if (xhr.status === 422) { return this._renderErrors(xhr.responseJSON.errors); }
      }
    });
  }
});

export const WidgetContentView = View;

export const ShowNodeWidgetLayout = WidgetLayout.extend({
  getWidgetConfigs() {
    return App.NodesApp.Show.Config;
  },

  getConfigLayoutTitle() {
    switch (this.widgetType()) {
      case 'NodeInfoWidget': return this.model.get('display_name');
      case 'SensorWidget':
        if (this.model.isNew()) {
          return I18n.t('sensor_widget.config_dialog_title_new');
        } return this.model.get('display_name');
      default:
        return super.getConfigLayoutTitle(...arguments); // eslint-disable-line prefer-rest-params
    }
  },

  onRender() {
    return this.stickit();
  }
});

export const NodeMonitorWidgetLayout = ShowNodeWidgetLayout.extend({
  getWidgetConfigs() {
    return App.NodesApp.Monitor.Config;
  }
});

export const ThiamisMonitorWidgetLayout = ShowNodeWidgetLayout.extend({
  getWidgetConfigs() {
    return MonitorConfig;
  }
});
