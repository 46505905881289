import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const Text = styled.p`
  max-width: 500px;
  margin: 0 auto;
`;

export const Button = styled.button.attrs({
  className: 'save-form btn btn-primary'
})`
  margin-top: 35px;
`;
