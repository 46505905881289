import React, { useState, useCallback } from 'react';
import GroupTitle from './group_title';
import { GroupContainer, ItemsContainer } from './styles';

const Group = ({ title, initState, children }) => {
  const [expanded, setExpanded] = useState(initState);
  const handleToggleExpanded = useCallback(() => setExpanded((value) => !value), []);

  return (
    <GroupContainer>
      <GroupTitle label={title} expanded={expanded} onClick={handleToggleExpanded} />
      <ItemsContainer expanded={expanded}>{children}</ItemsContainer>
    </GroupContainer>
  );
};

export default Group;
