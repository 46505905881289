import React from 'react';
import { Checkbox, FormGroup } from 'react-bootstrap';

const FormCheckbox = React.memo(({
  label, id, errorText, ...restProps
}) => (
  <FormGroup validationState={errorText ? 'error' : undefined}>
    <Checkbox
      {...restProps}
      id={id}
    >
      {label}
    </Checkbox>
  </FormGroup>
));

export default FormCheckbox;
