/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppController from 'app/backbone/lib/controllers/app_controller';
import {
  BaseDashboardCtrlMethods, LayoutedContainerCtrlMethods
} from 'app/backbone/lib/concerns/controllers/base_dashboard_ctrl_methods';
import { app as App } from 'app/backbone/app';
import CompareDevicesWidget from 'app/backbone/entities/widgets/compare_devices_widget';
import Layout, { AnalyticsTabsContentCollectionView, AnalyticsTabsCollectionView } from './show_views';

export default class Controller extends BaseDashboardCtrlMethods(LayoutedContainerCtrlMethods(AppController)) {
  showAnalytics() {
    return this.showWithDashboardLoading(this.getLayout(), {
      loading: {
        entities: [this.analytics]
      }
    });
  }

  getTabsCollectionView() {
    if (this.tabsCollectionView) {
      return this.tabsCollectionView;
    }
    this.tabsCollectionView = new AnalyticsTabsCollectionView({
      collection: this.analytics
    });
    return this.tabsCollectionView;
  }

  getTabsContentView() {
    if (this.tabsContentView) {
      return this.tabsContentView;
    }
    this.tabsContentView = new AnalyticsTabsContentCollectionView({
      collection: this.analytics
    });
    return this.tabsContentView;
  }

  getLayout() {
    this.layout = new Layout({ collection: this.analytics });
    this.listenToOnce(this.getTabsCollectionView(), 'childview:attach', (view) => {
      view.ui.tabLink.tab('show');
    });
    this.listenTo(this.getTabsContentView(), 'childview:add:devices:comparison:widget', (view) => {
      const newWidget = new CompareDevicesWidget();
      newWidget.collection = view.model.get('widgets');
      this.listenToOnce(newWidget, 'sync', () => view.model.get('widgets').add(newWidget, {
        failure(msg) {
          return App.getChannel().trigger('flash:message', 'error', msg);
        }
      }));
      this.showConfigDialog(this._getConfigLayoutFor(newWidget));
    });
    this.listenTo(this.analytics, 'collection:chose:one', (model) => {
      const tabContent = this.getTabsContentView().children.findByModel(model);
      if (!tabContent) {
        return this.listenToOnce(this.getTabsContentView(), 'childview:attach', (view) => {
          this._showWidgets(model, view.getRegion('widgetsRegion'));
        });
      }
      if (!tabContent.getRegion('widgetsRegion').currentView) {
        this._showWidgets(model, tabContent.getRegion('widgetsRegion'));
      }
    });
    this.listenTo(this.layout, 'attach', () => {
      this.layout.getRegion('controlsRegion').show(this.getTabsCollectionView());
      this.layout.getRegion('tabsContentRegion').show(this.getTabsContentView());
    });
    return this.layout;
  }

  initialize({ analytics, currentUser }) {
    this.analytics = analytics;
    this.currentUser = currentUser;
  }
}