var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-default reset-access-context\">"
    + container.escapeExpression(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"base.buttons.reset_access",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":13,"column":61},"end":{"line":13,"column":97}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-horizontal form access-context-form\">\n  <fieldset>\n    <div class=\"form-group\"><input type=\"radio\" name=\"design\" value=\"email\" class=\"input-sm\"><label class=\"col-sm-3 control-label\">"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.user.email",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":3,"column":131},"end":{"line":3,"column":163}}}))
    + "</label>\n      <div class=\"col-sm-9\"><input type=\"text\" name=\"email\" class=\"input-sm email-input form-control\"></div>\n    </div>\n    <div class=\"separator\"><span>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.labels.or",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":58}}}))
    + "</span></div>\n    <div class=\"form-group\"><input type=\"radio\" name=\"design\" value=\"org\" class=\"input-sm\"><label class=\"col-sm-3 control-label\">"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.organization.external_id",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":7,"column":129},"end":{"line":7,"column":175}}}))
    + "</label>\n      <div class=\"col-sm-9\"><input type=\"text\" name=\"external_id\" class=\"input-sm form-control\"></input></div>\n    </div>\n    <div class=\"form-group form-actions\">\n      <button type=\"submit\" class=\"btn btn-primary save-form\">"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"header.design_access_context",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":101}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isContextDesigned") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </fieldset>\n</form>\n";
},"useData":true});