/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const fetchUserInvitationsRequest = createAction('@@userInvitations/FETCH_REQUEST');
export const fetchUserInvitationsSuccess = createAction('@@userInvitations/FETCH_SUCCESS');
export const fetchUserInvitationsFailure = createAction('@@userInvitations/FETCH_FAILURE');

export const sendUserInvitationRequest = createAction('@@userInvitations/CREATE_REQUEST');
export const sendUserInvitationSuccess = createAction('@@userInvitations/CREATE_SUCCESS');
export const sendUserInvitationFailure = createAction('@@userInvitations/CREATE_FAILURE');

export const deleteUserInvitationRequest = createAction('@@userInvitations/DELETE_REQUEST');
export const deleteUserInvitationSuccess = createAction('@@userInvitations/DELETE_SUCCESS');
export const deleteUserInvitationFailure = createAction('@@userInvitations/DELETE_FAILURE');

export const resendUserInvitationRequest = createAction('@@userInvitations/RESEND_REQUEST');
export const resendUserInvitationSuccess = createAction('@@userInvitations/RESEND_SUCCESS');
export const resendUserInvitationFailure = createAction('@@userInvitations/RESEND_FAILURE');

export const acceptUserInvitationRequest = createAction('@@userInvitations/ACCEPT_REQUEST');
export const acceptUserInvitationSuccess = createAction('@@userInvitations/ACCEPT_SUCCESS');
export const acceptUserInvitationFailure = createAction('@@userInvitations/ACCEPT_FAILURE');

export const declineUserInvitationRequest = createAction('@@userInvitations/DECLINE_REQUEST');
export const declineUserInvitationSuccess = createAction('@@userInvitations/DECLINE_SUCCESS');
export const declineUserInvitationFailure = createAction('@@userInvitations/DECLINE_FAILURE');

const ids = createReducer([], {
  [fetchUserInvitationsSuccess](state, { payload: { result } }) {
    return result;
  },
  [sendUserInvitationSuccess](state, { payload }) {
    return [
      ...state,
      payload._id
    ];
  },
  [deleteUserInvitationSuccess](state, { payload }) {
    return state.filter((id) => id !== payload);
  }
});

const byId = createReducer({}, {
  [fetchUserInvitationsSuccess](state, { payload }) {
    const { entities: { invitations } } = payload;
    if (invitations) {
      return invitations;
    }
    return state;
  },
  [sendUserInvitationSuccess](state, { payload }) {
    return {
      ...state,
      [payload._id]: payload
    };
  },
  [deleteUserInvitationSuccess](state, { payload }) {
    const updatedState = { ...state };
    delete updatedState[payload];
    return updatedState;
  },
  [declineUserInvitationSuccess](state, { payload }) {
    if (state[payload]) {
      state[payload].acceptance_state = 'declined';
    }
  },
  [acceptUserInvitationSuccess](state, { payload }) {
    if (state[payload]) {
      state[payload].acceptance_state = 'accepted';
    }
  }
});

const isFetched = createReducer(false, {
  [fetchUserInvitationsRequest]() {
    return false;
  },
  [fetchUserInvitationsFailure]() {
    return false;
  },
  [fetchUserInvitationsSuccess]() {
    return true;
  }
});

const userInvitations = combineReducers({
  ids,
  byId,
  isFetched
});

export const getUserInvitationById = (state, id) => state.userInvitations.byId[id];
export const getUserInvitations = (state) => state.userInvitations.byId;
export const getUserInvitationsIds = (state) => state.userInvitations.ids;
export const getUserInvitationsIsFetched = (state) => state.userInvitations.isFetched;

export default userInvitations;
