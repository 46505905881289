/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import countriesData from 'app/config/common_data/countries';
import industriesData from 'app/config/common_data/industries';
import usStatesData from 'app/config/common_data/us_states';

const API = MnObject.extend({
  channelName: 'entities:countries',

  radioRequests: {
    'get:phone_prefixes': 'getPhonePrefixes',
    'get:us_states': 'getUsStates',
    'get:countries': 'getCountries',
    'get:industries': 'getIndustries',
    'get:country': 'getCountry'
  },

  getCountries() { return countriesData; },
  getIndustries() { return industriesData; },
  getUsStates() {
    return _(usStatesData).map((item) => ({ value: item.code, label: item.name }));
  },

  getPhonePrefixes() {
    if (this.phonePrefixes) { return this.phonePrefixes; }

    return this.phonePrefixes = _(this.getCountries()).map((country) =>
      ({
        label: `${country.name}: +${country.calling_code}`,
        value: `${country.code.toLowerCase()}_${country.calling_code}`
      })
    );
  },

  getCountry(code) {
    return _(this.getCountries()).findWhere({ code });
  }
});

export const api = new API();
