import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { isMobile } from 'app/utils/resolution_helpers';
import { api as alertsApi } from 'app/backbone/apps/alerts/alerts_app';
import { hasAlerts } from '~/store/selectors/alerts';
import { getCurrentUserIsManager } from '~/store/selectors/current_user';
import ManageAlertsActions from './manage_devices';
import MultiAlertModal from './multi_alert_modal';
import { filterListByQuery } from '../reducer';

export default function FiltersFormTop() {
  const dispatch = useDispatch();
  const onFilterInputChange = _.debounce((e) => dispatch(filterListByQuery(e.target.value)), 200);
  const handleInputChange = (e) => {
    e.persist();
    return onFilterInputChange(e);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const handleToggleModal = useCallback(() => setModalOpen((open) => !open), [setModalOpen]);
  const isManager = useSelector((state) => getCurrentUserIsManager(state));
  const handleCollapseClick = () => {
    $('body').toggleClass('filters-collapsed');
    if (isMobile()) { $('body').removeClass('nav-minified'); }
  };

  const handleCreateAlertClick = () => alertsApi.getChannel().request('app:alerts:new');
  const handleCreateMultiAlertClick = useCallback(() => setModalOpen(true), [setModalOpen]);
  const alertsAvailable = useSelector((state) => hasAlerts(state));

  return (
    <div className="content-top">
      <div className="filter-input-wrap pull-left"><input className="filter-input" type="text" placeholder="Filter..." onChange={handleInputChange} /></div>
      <div className="pull-left">
        { alertsAvailable && <ManageAlertsActions /> }
      </div>
      <div className="pull-right">
        { isManager
          && (
            <Dropdown>
              <Dropdown.Toggle bsStyle="primary">
                <i className="fa fa-fw fa-alert-plus" />
                {' '}
                {t('alerts.create_new')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <MenuItem onClick={handleCreateAlertClick}>{t('alerts.create_simple_alert')}</MenuItem>
                <MenuItem onClick={handleCreateMultiAlertClick}>{t('alerts.create_group_alert')}</MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          )}
        <a onClick={handleCollapseClick} role="button" className="btn btn-default filters-collapse">
          <i className="fa fa-fw fa-md fa-bars" />
        </a>
      </div>
      <MultiAlertModal isVisible={modalOpen} toggleModal={handleToggleModal} />
    </div>
  );
}
