/* eslint-disable guard-for-in */
import I18n from 'app/config/i18n';

function magic(o, a) {
  let j = JSON.stringify(o);
  // eslint-disable-next-line no-restricted-syntax
  for (const k in a) {
    j = j.split(`\${${k}}`).join(a[k]);
  }
  return JSON.parse(j);
}

export default function (key, options = {}) {
  if (options && options.hash.json) {
    const data = magic(JSON.parse(options.hash.json), options.hash);
    return I18n.t(key, data);
  }
  return I18n.t(key, options);
}
