import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Overlay, Tooltip } from 'react-bootstrap';
import { AIRTHIX_PARAMETER_PATHS } from 'app/react/utils/constants';
import { api as thiamisApi } from 'app/backbone/apps/thiamises/thiamises_app';
import useHover from 'app/react/hooks/useHover';
import { getNodeByIdDisplayName } from '~/store/selectors/nodes';
import { getAirthinxDataPointsByNodeId, getDataPointForHeatmap } from '../selectors';
import { HeatmapItem, HeatmapItemButton } from '../styles';

const HeatmapItemWithTooltip = ({
  id, widgetId, ...restProps
}) => {
  const {
    name, unit, path, lastValue: value, status, node_id: nodeId
  } = useSelector((state) => getDataPointForHeatmap(state, id));
  const isAQ = path === AIRTHIX_PARAMETER_PATHS.AQ;

  const hasValue = typeof value === 'number';
  const displayName = useSelector((state) => getNodeByIdDisplayName(state, nodeId));
  const allDataPoints = useSelector((state) => getAirthinxDataPointsByNodeId(state, nodeId));
  const handleOpenMonitorPage = useCallback(() => thiamisApi.getChannel().request('app:devices:monitor:params', nodeId, {
    data_points: id
  }), [nodeId, id]);

  const [hoverRef, isHovered] = useHover(500);

  return (
    <HeatmapItemButton onClick={handleOpenMonitorPage} ref={hoverRef}>
      <Overlay placement="top" show={isHovered} target={hoverRef?.current}>
        <Tooltip id={`${widgetId}_${path}`}>
          <span>
            <b>{displayName}</b>
            <br />
            {
          isAQ
            ? allDataPoints.map((item) => (
              <span id={`${widgetId}_param_${item.path}`}>
                {item.name}
                :
                {' '}
                {item.lastValue}
                {' '}
                {item.unit}
                <br />
              </span>
            ))
            : (
              <>
                {name}
                :
                {' '}
                {hasValue ? value : 'N/A'}
                {' '}
                {hasValue && unit}
                <br />
              </>
            )
        }
          </span>

        </Tooltip>
      </Overlay>
      <HeatmapItem
        {...restProps}
        value={value}
        status={status}
        unit={unit}
        parameterPath={path}
      />
    </HeatmapItemButton>
  );
};

export default HeatmapItemWithTooltip;
