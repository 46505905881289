/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppModel from 'app/backbone/lib/entities/app_model';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import { MnObject } from 'backbone.marionette';

export class Role extends AppModel {
  isManager() {
    return (this.get('role') === 'manager') || (this.get('role') === 'owner');
  }

  isOwner() {
    return this.get('role') === 'owner';
  }

  isAdmin() {
    return this.get('role') === 'admin';
  }

  isSupport() {
    return this.get('role') === 'support';
  }
}

export class Roles extends AppCollection {
  get model() { return Role; }

  toJSON(options = {}) {
    if (!options.savedByForm) { return super.toJSON(...arguments); } // eslint-disable-line prefer-rest-params
    const json = {};
    this.each((role) => {
      const name = role.get('role');
      if (!json[name]) { json[name] = []; }
      return json[role.get('role')].push(role.get('organization_id'));
    });
    return json;
  }

  getAvailableOrgId({ in: organizations }) {
    const allOrgIds = organizations.pluck('_id');
    const selOrgIds = this.chain()
      .filter((role) => !role.has('_destroy'))
      .collect((role) => role.get('organization_id')).value();
    return _.difference(allOrgIds, selOrgIds)[0];
  }
}

export const API = MnObject.extend({
  channelName: 'entities:role',

  radioRequests: {
    'new:role': 'newRoleEntity'
  },

  newRoleEntity(attrs) {
    return new Role(attrs);
  }
});

export const api = new API();
