import React from 'react';
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap';
import withTooltip from '~/hocs/withTooltip';
import Input from './input';

const FormInput = React.memo(({
  label, tooltip, errorText, helpText, id, alignTextRight = true, ...restProps
}) => {
  const IconTooltip = ({ title, tooltipRef }) => (
    <span ref={tooltipRef} data-tip={title}><i className="fa fa-fw fa-info-circle" /></span>
  );
  const Tooltip = withTooltip(IconTooltip);
  return (
    <FormGroup validationState={errorText ? 'error' : undefined}>
      {
        label && (
          <ControlLabel htmlFor={id}>
            {label}
            {tooltip && (<Tooltip title={tooltip} />)}
          </ControlLabel>
        )
      }
      <Input
        {...restProps}
        id={id}
        hasError={!!errorText}
      />
      {
        errorText
          ? (
            <HelpBlock className={alignTextRight && 'align-right'}>
              {errorText}
            </HelpBlock>
          )
          : null
      }
      {
        helpText && (
          <HelpBlock style={{ color: '#666' }}>{helpText}</HelpBlock>
        )
      }
    </FormGroup>
  );
});

export default FormInput;
