/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Obscura from 'backbone.obscura';

export default class FilteredCollection extends Obscura {
  filterCnd(filterCnd) {
    if (_(filterCnd).isFunction()) {
      return this.filter(filterCnd);
    } return this.where(filterCnd);
  }

  collectAttrs() {
    return this.map((model) => model.attributes);
  }

  superExec(methodName, ...args) {
    const fn = this.getSuperset()[methodName];
    if (typeof fn === 'function') { return _.bind(fn, this.getSuperset())(...args); }
  }

  getSuperset(superset) {
    // eslint-disable-next-line no-param-reassign
    if (!superset) { superset = this.superset(); }
    if (!(typeof superset.superset === 'function' ? superset.superset() : undefined)) { return superset; }
    return this.getSuperset(superset.superset());
  }
}
