/* eslint-disable global-require */
import i18next from 'i18next/i18next';
import { initReactI18next } from 'react-i18next';
import en from 'app/locales/en';
import zh from 'app/locales/zh';
import es from 'app/locales/es';
import pt from 'app/locales/pt';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      zh: { translation: zh },
      es: { translation: es },
      pt: { translation: pt }
    },
    lng: 'en',
    interpolation: { prefix: '%{', suffix: '}', escapeValue: false },
    fallbackLng: 'en',
    nsSeparator: '::'
  });

export default i18next;
