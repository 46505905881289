import { createSelector } from 'reselect';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import union from 'lodash/union';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import I18n from 'app/config/i18n';
import * as fromFirmwares from './reducer';

export const getFirmwaresFilterTags = createSelector(
  fromFirmwares.getFilter,
  ({ tags }) => tags
);

function getCounts(tags) {
  return tags.reduce((result, tag) => {
    if (!result[tag]) {
      result[tag] = 0; // eslint-disable-line no-param-reassign
    }
    result[tag] += 1; // eslint-disable-line no-param-reassign
    return result;
  }, {});
}

const createThiamisFilterTags = ({ thiamis }) => [thiamis];

export const getFirmwaresThiamisFilterTags = createSelector(
  fromFirmwares.getFirmwares,
  (firmwares) => {
    const result = {};
    const list = Object.values(firmwares);
    result.counts = getCounts(flatten(list.map(createThiamisFilterTags)));
    result.all = Object.values(result.counts).length;
    return result;
  }
);

const createStatusFilterTags = ({ status }) => [startCase(status)];

export const getFirmwaresStatusFilterTags = createSelector(
  fromFirmwares.getFirmwares,
  (firmwares) => {
    const result = {};
    const list = Object.values(firmwares);
    result.counts = getCounts(flatten(list.map(createStatusFilterTags)));
    result.all = Object.values(result.counts).length;
    return result;
  }
);

const createChannelFilterTags = ({ channel }) => (isEmpty(channel) ? I18n.t('base.labels.n_a') : channel);

export const getFirmwaresChannelFilterTags = createSelector(
  fromFirmwares.getFirmwares,
  (firmwares) => {
    const result = {};
    const list = Object.values(firmwares);
    result.counts = getCounts(flatten(list.map(createChannelFilterTags)));
    result.all = Object.values(result.counts).length;
    return result;
  }
);

export const getFilteredList = createSelector(
  fromFirmwares.getFirmwares,
  fromFirmwares.getFilter,
  (firmwares, { query, tags }) => {
    let results = Object.values(firmwares).map((firmware) => ({
      ...firmware
    }));
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((firmware) => {
        const { version, channel, thiamis } = firmware;
        const searchString = flatten([version, channel, thiamis]).join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    if (!isEmpty(tags)) {
      results = results.filter((firmware) => {
        const allTags = union(createStatusFilterTags(firmware), createChannelFilterTags(firmware), createThiamisFilterTags(firmware));
        return intersection(tags, allTags).length === tags.length;
      });
    }
    return results;
  }
);

export const getAvailableFirmwaresValueLabelPairs = createSelector(
  fromFirmwares.getFirmwares,
  (firmwares) => Object.values(firmwares)
    .filter(({ status }) => status === 'available')
    .map(({ version }) => version)
    .sort()
    .map((version) => ({ label: version, value: version }))
);

export const getAvailableChannelsById = createSelector(
  fromFirmwares.getFirmwareById,
  ({ channel: usedChannel }) => difference(['stable', 'testing', 'unstable', 'tsi'], usedChannel)
    .map((channel) => ({ value: channel, label: startCase(channel) }))
);
