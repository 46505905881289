/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const setFilter = createAction('@@organizationsList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@organizationsList/CLEAR_FILTERS');
export const expandList = createAction('@@organizationsList/EXPAND_ROW');

const filter = createReducer(null, {
  [clearFilters]() {
    return null;
  },
  [setFilter](state, { payload }) {
    return payload;
  }
});

const organizationsList = combineReducers({
  filter
});

export const getFilter = (state) => state.organizationsList.filter;

export default organizationsList;
