export default [
  {
    label: 'English',
    locale: 'en'
  },
  {
    label: 'Chinese',
    locale: 'zh'
  },
  {
    label: 'Spanish',
    locale: 'es'
  }
];