/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import WidgetNodeSelect from 'app/backbone/lib/concerns/views/widget_node_select';
import NodeWidgetFetchThiamisesMixin from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamises';
import confTemplate from './templates/conf.hbs';

const MapWidgetConfigView = View.extend({
  behaviors: [NodeWidgetFetchThiamisesMixin, WidgetNodeSelect],
  template: confTemplate,
  ui: {
    loading: '.loading-overlay'
  },
  regions:
    { nodeSelectRegion: '.nodeselect-region' }
});

export default MapWidgetConfigView;
