import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class ParamInfoIcon extends React.Component {
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).tooltip({
      html: true,
      title: this.props.title,
      container: 'body'
    });
  }

  componentDidUpdate(nextProps) {
    $(ReactDOM.findDOMNode(this)).attr('title', nextProps.title).tooltip('fixTitle');
  }

  render() {
    return (
      <div className={_.isDefined(this.props.value) ? 'param-info-icon' : 'param-info-icon empty'}>
        <i className={this.props.iconClass} />
        <span className="truncated">{ _.isDefined(this.props.value) ? this.props.displayValue : this.props.noValueText }</span>
      </div>
    );
  }
}

ParamInfoIcon.defaultProps = {
  title: '',
  value: '',
  displayValue: '',
  noValueText: ''
};

ParamInfoIcon.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  displayValue: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  noValueText: PropTypes.string
};

export default ParamInfoIcon;
