import {
  put,
  fork,
  takeEvery
} from 'redux-saga/effects';
import * as Actions from '~/store/reducers/alerts';
import * as AlertActions from './reducer';

export function* handleModalActionRequestSaga({ payload }) {
  try {
    switch (payload.type) {
      case 'activate':
        yield put(Actions.activateRequest(payload));
        break;
      case 'deactivate':
        yield put(Actions.deactivateRequest(payload));
        break;
      case 'delete':
        yield put(Actions.destroyAlertsRequest(payload.alertId));
        break;
      default:
        throw new Error('Action not supported');
    }
  } catch (e) {
    yield put(AlertActions.modalActionFailure(e.toString()));
  }
}

function* watchModalActionRequest() {
  yield takeEvery(AlertActions.modalActionRequest.toString(), handleModalActionRequestSaga);
}

export default function* app() {
  yield fork(watchModalActionRequest);
}
