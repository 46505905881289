import React from 'react';
import { useSelector } from 'react-redux';
import { Field as FinalField, useFormState } from 'react-final-form';
import { FlexWrap, Label } from '~/components/layout';
import Group from '~/components/group';
import Select from '~/components/form/select';
import Checkbox from '~/components/form/checkbox';
import { getDataPointsWithProfileByNodeId } from '~/store/selectors/data_points';
import { getCommonParamsForDevices, getCommonProfilesForDevices } from '../selectors';

const Sensors = () => {
  const { values: { devices } } = useFormState();

  const { parameters, profiles } = useSelector((state) => {
    const nodeIds = (devices || []).map((device) => device.value);
    const dataPoints = nodeIds.map((nodeId) => getDataPointsWithProfileByNodeId(state, nodeId));
    const params = getCommonParamsForDevices(state, dataPoints);
    const commonProfiles = getCommonProfilesForDevices(state, nodeIds);
    return {
      parameters: params,
      profiles: commonProfiles
    };
  });

  return (
    <Group title="Sensors" initState>
      <FinalField name="profiles">
        {
          ({ input }) => (
            <Select
              label="Band Categorization:"
              value={input.value}
              options={profiles}
              onChange={input.onChange}
            />
          )
        }
      </FinalField>
      <FlexWrap>
        {
          !parameters.length && (
            (!devices || !devices.length)
              ? (<Label>Select devices to see possible parameter choices</Label>)
              : (<Label>No common parameters for devices selected</Label>)
          )
        }
        {
          parameters.map((item) => (
            <FinalField name={`params.${item.parameterPath}`} key={item.parameterPath}>
              {
                  ({ input }) => (
                    <Checkbox
                      label={(
                        <span>
                          {item.name}
                          {' '}
                          <small>{!!item.unit && `(${item.unit})`}</small>
                        </span>
)}
                      checked={input.value}
                      onClick={() => input.onChange(!input.value)}
                    />
                  )
              }
            </FinalField>
          ))
        }
      </FlexWrap>
    </Group>
  );
};

export default Sensors;
