import * as Sentry from '@sentry/browser';
import { getReleaseName } from 'app/utils/custom-fns';

const { SENTRY_DSN } = process.env;
const { CI } = process.env;

(() => {
  if (CI) {
    Sentry.init({
      ignoreErrors: [
        'Non-Error exception captured'
      ],
      dsn: SENTRY_DSN,
      release: getReleaseName()
    });
  }
})();
