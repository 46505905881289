import {
  call,
  put,
  take,
  fork,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects';
import { api as organizationApi } from 'app/backbone/entities/organization';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';
import { api as navsApi } from 'app/backbone/entities/abstract/nav';
import { api as auth0Api } from 'app/utils/auth0_handler';
import { app as App } from 'app/backbone/app';
import * as schema from '~/store/schema';
import { normalizeResp } from '~/store/normalizr';
import requestApi from './requestApi';
import { fetchOrganizationsListRequest, fetchOrganizationsListSuccess } from '~/store/reducers/organizations';
import { fetchUserProfileSuccess, setGlobalOrganizationId } from '~/store/reducers/global';
import { getTotalCountsByDeviceType } from '~/store/selectors/nodes';
import * as Actions from '~/store/reducers/organizations';
import { fetchNodesRequest, fetchNodesSuccess, clearNodes } from '~/store/reducers/nodes';

export function* organizationsFetchRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'get',
      'organizations'
    );
    const response = normalizeResp(data, schema.organizationsListSchema);
    yield put(Actions.fetchOrganizationsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchOrganizationsFailure(e.toString()));
  }
}

function* watchFetchOrganizationsRequest() {
  yield takeLatest(Actions.fetchOrganizationsRequest.toString(), organizationsFetchRequestSaga);
}

export function* organizationsFetchListRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'get',
      'organizations',
      {
        only: 'id,slug,name,destroyable,managed,membered,modifiable,partnered,is_rental,project_id,external_id'
      }
    );
    const response = normalizeResp(data, schema.organizationsListSchema);
    yield put(Actions.fetchOrganizationsListSuccess(response));
    const organizationsColl = organizationApi.getChannel().request('new:organizations', data);
    orgNavsApi.getChannel().request('reset:choosable:orgs', organizationsColl);
  } catch (e) {
    yield put(Actions.fetchOrganizationsListFailure(e.toString()));
  }
}

function* watchFetchOrganizationsListRequest() {
  yield takeEvery(Actions.fetchOrganizationsListRequest.toString(), organizationsFetchListRequestSaga);
}

export function* organizationDetailsFetchRequestSaga({ payload = {} }) {
  const { organizationId } = payload;
  try {
    yield put(clearNodes());
    yield put(fetchNodesRequest({
      data: {
        organization_id: organizationId, includes: ['configuration']
      }
    }));
    yield take(fetchNodesSuccess.toString());
    const deviceCounts = yield select(getTotalCountsByDeviceType, organizationId);
    yield put(Actions.fetchOrganizationDetailsSuccess({ organizationId, deviceCounts }));
  } catch (e) {
    yield put(Actions.fetchOrganizationDetailsFailure({ organizationId, e }));
  }
}

function* watchFetchOrganizationDetailsRequest() {
  yield takeEvery(Actions.fetchOrganizationDetailsRequest.toString(), organizationDetailsFetchRequestSaga);
}

export function* organizationDeleteRequestSaga({ payload: { organizationId } }) {
  try {
    yield call(
      requestApi,
      'delete',
      `organizations/${organizationId}`
    );
    yield put(Actions.destroyOrganizationSuccess({ organizationId }));
  } catch (e) {
    yield put(Actions.destroyOrganizationFailure({ organizationId, e }));
  }
}

function* watchDeleteOrganizationsRequest() {
  yield takeEvery(Actions.destroyOrganizationRequest.toString(), organizationDeleteRequestSaga);
}

const updateProfileRequest = () => auth0Api.getChannel().request('get:instance').updateCurrentUser();

export function* organizationCreateRequestSaga({ payload: { organization, onSuccess } }) {
  try {
    const data = yield call(
      requestApi,
      'post',
      'organizations',
      { organization }
    );
    yield put(Actions.createOrganizationSuccess(data));
    const userProfile = yield call(updateProfileRequest);
    App.getChannel().request('get:current:user').updateProfile(userProfile);
    const response = normalizeResp(userProfile, schema.userSchema);
    yield put(fetchUserProfileSuccess(response));
    if (onSuccess) {
      onSuccess(data);
    }
    yield put(fetchOrganizationsListRequest());
    yield take(fetchOrganizationsListSuccess.toString());
    yield put(setGlobalOrganizationId({ value: data._id, label: data.name }));
    navsApi.getChannel().request('rebuild:navs');
  } catch (e) {
    yield put(Actions.createOrganizationFailure({ e }));
  }
}

function* watchCreateOrganizationsRequest() {
  yield takeEvery(Actions.createOrganizationRequest.toString(), organizationCreateRequestSaga);
}

export function* organizationUpdateRequestSaga({ payload: { organizationId, organization, onSuccess } }) {
  try {
    const data = yield call(
      requestApi,
      'put',
      `organizations/${organizationId}`,
      { organization }
    );
    yield put(Actions.updateOrganizationSuccess({ data }));
    if (onSuccess) {
      onSuccess(data);
    }
  } catch (e) {
    yield put(Actions.updateOrganizationFailure({ organizationId, e }));
  }
}

function* watchUpdateOrganizationsRequest() {
  yield takeEvery(Actions.updateOrganizationRequest.toString(), organizationUpdateRequestSaga);
}

export default function* app() {
  yield fork(watchFetchOrganizationsRequest);
  yield fork(watchFetchOrganizationsListRequest);
  yield fork(watchDeleteOrganizationsRequest);
  yield fork(watchFetchOrganizationDetailsRequest);
  yield fork(watchCreateOrganizationsRequest);
  yield fork(watchUpdateOrganizationsRequest);
}
