import { MnObject } from 'backbone.marionette';
import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load({ writeKey: process.env.SEGMENT_WRITE_KEY });

export const API = MnObject.extend({
  channelName: 'segment',

  radioRequests: {
    'segment:analytics:get': 'analyticsGet',
    'segment:analytics:identify': 'analyticsIdentify',
    'segment:analytics:track': 'analyticsTrack',
    'segment:analytics:page': 'analyticsPage'
  },

  getAnalytics() {
    return analytics;
  },

  analyticsIdentify(user, options) {
    analytics.identify(user.id, {
      email: user.get('email'),
      name: user.get('full_name')
    }, options);
  },

  analyticsTrack(event, props) {
    analytics.track(event, props);
  },

  analyticsPage(...attrs) {
    analytics.page(...attrs);
  }

});

export const api = new API();
