/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { MnObject } from 'backbone.marionette';
import Radio from 'backbone.radio';
import pluralize from 'pluralize';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import { app as App } from 'app/backbone/app';
import I18n from 'app/config/i18n';
import { SingleChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import { modelName, modelPluralName } from 'app/utils/i18n_helpers';

export class NavModel extends Chooser(Backbone.Model) {
  isDivider() { return this.get('divider'); }
  getRef() { return this.get('ref'); }

  initialize() {
    super.initialize();
    return this.on('change:chosen', (model, value, options = {}) => {
      if (value) {
        if (options.triggeredByUser) { return Radio.channel('router').request(this.get('command')); }
      }
    });
  }
}

export class NavsColl extends SingleChooser(Backbone.Collection) {
  get model() { return NavModel; }

  addDivider(attrs) {
    return this.add(_(attrs).extend({ divider: true }));
  }

  chooseByRef(ref) {
    return this.choose((this.findWhere({ ref }) || this.first()));
  }

  getPrevChoosedRef() { return this._prevChoosedRef; }

  initialize() {
    super.initialize();
    return this.on('collection:chose:one', function (model) {
      return this._prevChoosedRef = model.getRef();
    });
  }
}

export const API = MnObject.extend({
  channelName: 'entities:nav',

  radioRequests: {
    'get:navs': 'getNavsCollection',
    'rebuild:navs': 'rebuildNavsCollection',
    'get:top:navs': 'getTopNavsCollection',
    'get:side:navs': 'getSideNavsCollection'
  },

  getSideNavsCollection() {
    if (this.sideNavsColl) {
      return this.sideNavsColl;
    }

    this.sideNavsColl = new FilteredCollection(this.getNavsCollection());
    this.sideNavsColl.filterBy((nav) => nav.get('location') === 'sidebar');

    return this.sideNavsColl;
  },

  rebuildNavsCollection() {
    this.navsColl.reset();
    this._populateNavsCollection();
  },

  getTopNavsCollection() {
    if (this.topNavsColl) {
      return this.topNavsColl;
    }

    this.topNavsColl = new FilteredCollection(this.getNavsCollection());
    this.topNavsColl.filterBy((nav) => nav.get('location') === 'top');

    return this.topNavsColl;
  },

  getNavsCollection() {
    if (this.navsColl) { return this.navsColl; }

    this._populateNavsCollection();

    return this.navsColl;
  },

  _populateNavsCollection() {
    this.abilities = App.getChannel().request('get:abilities');
    this.currentUser = App.getChannel().request('get:current:user');
    this.navsColl = this.navsColl || new NavsColl();
    this._addMainSidebarNavs();
    this._addRestSidebarNavs();
    this._addTopNavItems();
  },

  _addNavItem(itemName) {
    return this.navsColl.add({
      ref: itemName,
      name: modelName(itemName),
      url: `/${itemName}`,
      command: `app:${itemName}`,
      location: 'sidebar'
    });
  },

  _addNavPluralItem(itemName) {
    const pluralName = pluralize(itemName);
    return this.navsColl.add({
      ref: itemName,
      name: modelPluralName(itemName),
      url: `/${pluralName}`,
      command: `app:${pluralName}`,
      location: 'sidebar'
    });
  },

  _addMainSidebarNavs() {
    this._addNavItem('dashboard');
    if (this.currentUser.isSupport()) { this._addNavItem('analytics'); }
    this._addNavItem('map');
    this._addNavPluralItem('device');
    if (this.currentUser.isSupport()) {
      this._addNavItem('certificates');
    }
    this._addNavPluralItem('alert');
    return this._addNavPluralItem('user');
  },

  _addRestSidebarNavs() {
    const fetchableItems = _(this.abilities).select((entity) => entity.fetchable);
    const restNavItems = ['organization', 'partnership'];

    const menuItems = _(restNavItems).pick(_(fetchableItems).keys());

    _(menuItems).each((entityName) => {
      if (this.abilities[entityName].fetchable) {
        return this._addNavPluralItem(entityName);
      }
    });

    if (this.currentUser.isAdmin()) {
      this._addNavItem('calibration');
      this._addNavItem('firmware');
    }
    if (this.currentUser.isManager()) { this._addNavItem('api'); }
    this._addNavPluralItem('user_file');
  },

  _addTopNavItems() {
    const topNavItems = [{
      ref: 'profile',
      name: 'profile',
      url: '/profile',
      location: 'top'
    },
    {
      divider: true,
      location: 'top'
    },
    {
      name: I18n.t('header.logout'),
      url: '/logout',
      external: true,
      location: 'top'
    }];

    return this.navsColl.add(topNavItems);
  }
});

export const api = new API();
