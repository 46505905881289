import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from 'app/config/i18n';
import { getCurrentUserIsSupport } from '~/store/selectors/current_user';
import withBackboneLink from '~/hocs/with_backbone_link';
import { filterListByQuery } from '../reducer';

const CreateNewCertificateButton = withBackboneLink((props) => (
  <button {...props} type="button" className="btn btn-primary">
    <span>
      {I18n.t('certificates.create_new')}
    </span>
  </button>
));

export default function FiltersFormTop() {
  const dispatch = useDispatch();
  const isSupport = useSelector(getCurrentUserIsSupport);
  const onFilterInputChange = _.debounce((e) => dispatch(filterListByQuery(e.target.value)), 200);
  const handleInputChange = useCallback((e) => {
    e.persist();
    return onFilterInputChange(e);
  }, [onFilterInputChange]);
  return (
    <div className="content-top">
      <div className="filter-input-wrap pull-left">
        <input className="filter-input" type="text" placeholder="Filter..." onChange={handleInputChange} />
      </div>
      <div className="pull-right">
        { isSupport && (<CreateNewCertificateButton to="certificates/create" />)}
      </div>
    </div>
  );
}
