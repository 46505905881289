import styled from 'styled-components';
import icons from '~/theme/icons';

const EmptyDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeStatusEmpty}
`;

const OnlineDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGreen};
  i::before {
    content: '${icons.circle}';
  }
`;

const OfflineDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGreen};
  i::before {
    content: '${icons.circleO}';
  }
`;

const ActivatedDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGreen};
  i::before {
    content: '${icons.circleO}';
  }
`;

const DisabledDeviceStatus = styled.b`
  i::before {
    content: '${icons.circleO}';
  }
`;

const HibernatedDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGray};
  i::before {
    content: '${icons.circle}';
  }
`;

const InactiveDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGray};
  i::before {
    content: '${icons.circle}';
  }
`;

const IdleDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGray};
  i::before {
    content: '${icons.circle}';
  }
`;

const DeactivatedDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGray};
  i::before {
    content: '${icons.circleO}';
  }
`;

const OkDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGreen};
  i::before {
    content: '${icons.circle}';
  }
`;

const ReportingDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGreen};
  i::before {
    content: '${icons.circle}';
  }
`;

const ActiveDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.redColor};
  i::before {
    content: '${icons.circle}';
  }
`;

const ResolvedDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.nodeGreen};
  i::before {
    content: '${icons.circle}';
  }
`;

const ErrorDeviceStatus = styled.b`
  color: ${(props) => props.theme.colors.redColor};
`;

export const DeviceStatuses = {
  empty: EmptyDeviceStatus,
  online: OnlineDeviceStatus,
  offline: OfflineDeviceStatus,
  activated: ActivatedDeviceStatus,
  disabled: DisabledDeviceStatus,
  hibernated: HibernatedDeviceStatus,
  inactive: InactiveDeviceStatus,
  idle: IdleDeviceStatus,
  deactivated: DeactivatedDeviceStatus,
  ok: OkDeviceStatus,
  reporting: ReportingDeviceStatus,
  active: ActiveDeviceStatus,
  resolved: ResolvedDeviceStatus,
  error: ErrorDeviceStatus
};
