import React, {
  useCallback, useContext, useMemo, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '~/components/modal';
import {
  createCalibrationRequest, fetchRerefenceDevicesRequest, fetchCalibrationsRequest, getCalibration
} from '../../../../reducer';
import { getIsReferenceDevicesFetched } from '../../../../selectors';
import ManageCalibrationContext from '../../../../manage_context';
import ModalForm from './form';

const RolloutCalibrationModal = ({ toggleModal }) => {
  const { t } = useTranslation();
  const isReferencesDevicesFetched = useSelector(getIsReferenceDevicesFetched);
  const {
    selectedCalibrations
  } = useContext(ManageCalibrationContext);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const calibrations = useSelector(getCalibration);
  const selectedSerials = useMemo(() => selectedCalibrations.map((id) => calibrations[id]?.serial), [calibrations, selectedCalibrations]);

  const handleSuccess = useCallback(({ calibrated, missing }) => {
    toast.success(t('notifications.success.calibration_run', { calibrated: calibrated.join(', '), missing: missing.join(', ') }), {
      toastId: 'success_calibration_request',
      closeOnClick: true,
      autoClose: false
    });
    toggleModal();
    dispatch(fetchCalibrationsRequest());
  }, [dispatch, t, toggleModal]);

  const handleRolloutCalibration = useCallback((values) => {
    const {
      serials, from, to, reference
    } = values;
    const payload = {
      serials: serials.map(({ value }) => value), from: from.valueOf(), to: to.valueOf(), reference: reference.value
    };
    dispatch(createCalibrationRequest({
      ...payload,
      onSuccess: handleSuccess
    }));
  }, [dispatch, handleSuccess]);

  useEffect(() => {
    if (!isReferencesDevicesFetched) {
      dispatch(fetchRerefenceDevicesRequest());
    }
  }, [dispatch, isReferencesDevicesFetched]);

  const initialValues = useMemo(() => ({ serials: selectedSerials?.map((serial) => ({ value: serial, label: serial })) }), [selectedSerials]);

  return (
    <Modal
      isVisible
      handleCancel={handleClose}
      title={t('calibration.create_new')}
    >
      <div className="dialog-content-region">
        <FinalForm
          initialValues={initialValues}
          onSubmit={handleRolloutCalibration}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit }) => (
            <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default RolloutCalibrationModal;
