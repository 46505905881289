var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"location-description\"><span data-toggle=\"tooltip\" data-container=\"body\" class=\"location-icon\"><i></i></span><span title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"thiamis.location",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":1,"column":133},"end":{"line":1,"column":160}}}))
    + "\"><span class=\"address\"></span><span class=\"gps\"></span></span></div>\n<div class=\"map-canvas fill\"></div>\n<div class=\"no-location-icon hidden\"></div>\n<div class=\"no-location hidden\">\n  <h3>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"map_widget.no_location",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":39}}}))
    + "</h3>\n</div>";
},"useData":true});