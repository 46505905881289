import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { getAlertDataPointsIds } from '~/store/selectors/alerts';
import StatsItem from './stats_item';

const StatsContainer = (props) => {
  const {
    dataPointIds, id
  } = props;
  const { t } = useTranslation();
  return (
    <div className="stats-region">
      {
        isEmpty(dataPointIds)
          ? (<h4 className="text-center">{t('alerts.events.empty_nodes')}</h4>)
          : dataPointIds.map((dataPointId, index) => <StatsItem key={dataPointId} dataPointId={dataPointId} alertId={id} defaultExpanded={index === 0} />)
      }
    </div>
  );
};

const mapStateToProps = (state, { id }) => ({
  dataPointIds: getAlertDataPointsIds(state, id)
});

export default connect(mapStateToProps)(StatsContainer);
