import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { app as App } from 'app/backbone/app';
import { isMobile } from 'app/utils/resolution_helpers';
import { toast, ToastContainer } from 'react-toastify';

import withBackboneLink from '~/hocs/with_backbone_link';
import ProfilePopover from './profile_popover';
import OrganizationsDropdown from './organizations_dropdown';

export const CreateNewOrganization = withBackboneLink((props) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn btn-default btn-sm"
      {...props}
    >
      <i className="fa fa-fw fa-building-plus" />
      <span>{t('organizations.create_new')}</span>
    </button>
  );
});

const Header = () => {
  const handleToggleNav = useCallback(() => {
    $('body').toggleClass('nav-minified');
    if (isMobile()) { $('body').removeClass('filters-collapsed'); }
    return _.delay(() => App.getChannel().trigger('window:resized', { originalEvent: true }),
      300);
  }, []);

  useEffect(() => {
    toast.info(
      <span className="next-environet-promo">
        <i className="fa flashing-icon" aria-hidden="true" />
        We are thrilled to announce that
        <a href="https://next.environet.io" target="_blank" rel="noreferrer">new Environet Console</a>
        {' '}
        will be launching on Mon, 29 July 2024 — stay tuned!
      </span>,
      {
        closeButton: true,
        closeOnClick: false,
        autoClose: false
      }
    );
  });

  return (
    <>
      <header className="container-fluid">
        <div className="header-logo pull-left" />
        <div role="button" className="header-nav pull-left toggle-nav" onClick={handleToggleNav}>
          <i className="fa fa-lg fa-navicon" />
        </div>
        <div className="header-choose-account pull-left organization-filter">
          <OrganizationsDropdown />
        </div>
        <ProfilePopover />
      </header>
      <ToastContainer
        autoClose={2000}
        position="top-center"
        closeButton={null}
        hideProgressBar
      />
    </>

  );
};

export default Header;
