import { combineReducers } from 'redux';
import nodes from '~/store/reducers/nodes';
import devices from '~/store/reducers/devices';
import dataPoints from '~/store/reducers/data_points';
import measurements from '~/store/reducers/measurements';
import organizations from '~/store/reducers/organizations';
import devicesList from '~/screens/devices/reducer';
import alertsList from '~/screens/alerts/reducer';
import filesList from '~/screens/files/reducer';
import firmwaresList from '~/screens/firmwares/reducer';
import calibrations from '~/screens/calibration/reducer';
import usersList from '~/screens/users/reducer';
import organizationsList from '~/screens/organizations/reducer';
import heatmapWidget from '~/components/heatmap_widget/reducer';
import partnershipsList from '~/screens/partnerships/reducer';
import certificatesList from '~/screens/certificates/reducer';
import alertMonitor from '~/screens/alert_monitor/reducer';
import apisList from '~/screens/api/reducer';
import webhooksList from '~/screens/api/webhooks/reducer';
import users from './users';
import partnerships from './partnerships';
import reports from './reports';
import stats from './stats';
import widgets from './widgets';
import alerts from './alerts';
import userInvitations from './invitations';
import apis from './apis';
import webhooks from './webhooks';
import templates from './templates';
import global from './global';
import certificates from './certificates';
import profile from './profile';

export default () => combineReducers({
  organizations,
  nodes,
  alerts,
  devices,
  dataPoints,
  measurements,
  devicesList,
  alertsList,
  users,
  filesList,
  firmwaresList,
  calibrations,
  partnerships,
  usersList,
  userInvitations,
  organizationsList,
  partnershipsList,
  apis,
  apisList,
  webhooks,
  webhooksList,
  reports,
  widgets,
  certificates,
  certificatesList,
  heatmapWidget,
  stats,
  alertMonitor,
  templates,
  global,
  profile
});
