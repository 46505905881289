import React from 'react';
import FiltersFormTop from './filters_form_top';
import GridContainer from './grid_container';

const PartnershipsScreen = (props) => {
  const { slug } = props;

  return (
    <div className="content-region fill-height content">
      <div className="partnerships-container">
        <FiltersFormTop />
        <div className="tab-content list-content">
          <GridContainer slug={slug} />
        </div>
      </div>
    </div>
  );
};

export default PartnershipsScreen;
