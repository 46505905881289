/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import ReactDOM from 'react-dom';
import React from 'react';
import { View } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import WidgetNodeTitle from 'app/backbone/lib/components/shared_views/widget_node_title';
import { ChartLayout } from 'app/backbone/lib/components/shared_views/chart';
import LocalTime from 'app/backbone/lib/react/components/lists/thiamises/LocalTime/LocalTime';
import NodeWidgetFetchThiamis from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamis';
import faceTemplate from './templates/face.hbs';

const ChartWidgetContentView = View.extend({
  behaviors: [NodeWidgetFetchThiamis],
  template: faceTemplate,
  className: 'fill-height',

  regions:
    { chartRegion: '.chart-region' },

  ui:
    { thiamisTime: '.thiamis-time' },

  onBeforeDetach() {
    return ReactDOM.unmountComponentAtNode(this.ui.thiamisTime[0]);
  },

  showThiamisTime() {
    ReactDOM.render(this.getThiamisTimeComponent(), this.ui.thiamisTime[0]);
  },

  getThiamisTimeComponent() {
    return React.createElement(LocalTime,
      // eslint-disable-next-line react/no-this-in-sfc
      { dataPointsColl: this.model.getDataPointsColl() });
  },

  showChart() {
    this.chartView = new ChartLayout({
      model: this.model,
      collection: this.model.getFilteredDataPointsColl(),
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        resetZoomButton: {
          position: {
            x: -10,
            y: 10
          },
          relativeTo: 'chart'
        },
        time: {
          timezone: this.model.getDataPointsColl().getDefaultTimezone()
        }
      }
    });

    this.listenTo(App.getChannel(), 'window:resized', () => this.chartView.getChartView().triggerMethod('after:resize'));
    this.listenTo(this.model.getDataPointsColl(), 'updates:set', () => {
      this.chartView.getChartView().getHighchart().update({
        time: {
          timezone: this.model.getDataPointsColl().getDefaultTimezone()
        }
      });
    });
    return this.getRegion('chartRegion').show(this.chartView);
  },

  getWidgetTitle() {
    return new WidgetNodeTitle({
      model: this.options.thiamis
    });
  },

  onAttach() {
    this.showChart();
    return this.showThiamisTime();
  }
});

export default ChartWidgetContentView;
