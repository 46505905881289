import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  RowDetailState,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import {
  fetchUsersRequest, getUsersIsFetched
} from 'app/react/store/reducers/users';

import Spinner from '~/components/spinner';
import ListTable from '~/components/list_table';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from '~/components/react_grid/expand_button';

import { getFilteredList } from '../selectors';
import GridDetailContainer from './detail_row';
import {
  AvatarProvider,
  NameProvider,
  RoleProvider,
  CreatedAtProvider,
  ContactProvider
} from './info_providers';

const columns = [
  { name: 'avatar', title: ' ', sortingEnabled: false },
  { name: 'full_name', title: 'Name' },
  { name: 'role', title: 'Role', sortingEnabled: false },
  { name: 'email', title: 'Contact' },
  { name: 'created_at', title: 'Created' }
];

function compareDates(a, b) {
  return (b != null) - (a != null) || b - a;
}

const getRowId = (row) => row._id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = ({ isActive, slug }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'asc' }]);
  const dispatch = useDispatch();

  const Row = React.memo((props) => {
    const { row: { _id: userId } } = props;
    const isExpanded = includes(expandedRowIds, userId);

    const handleExpandRowClick = useCallback(
      () => {
        if (includes(expandedRowIds, userId)) {
          setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== userId));
        } else {
          setExpandedRowIds([...expandedRowIds, ...[userId]]);
        }
      },
      [userId]
    );
    return (
      <VirtualTable.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded })}
      />
    );
  });

  const { rows } = useSelector(
    (state) => ({
      rows: getFilteredList(state)
    })
  );

  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    dispatch(fetchUsersRequest({ data }));
  }, [dispatch, slug]);

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  const isFetched = useSelector(getUsersIsFetched);
  if (!isFetched) {
    return (
      <Spinner />
    );
  }

  return (
    <ListTable className="users-list-table react-users-list" isActive={isActive}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        rootComponent={Root}
      >
        <SortingState
          sorting={sorting}
          onSortingChange={handleSortingChange}
        />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'created_at', compare: compareDates }
          ]}
        />
        <CreatedAtProvider for={['created_at']} />
        <AvatarProvider for={['avatar']} />
        <NameProvider for={['full_name']} />
        <RoleProvider for={['role']} />
        <ContactProvider for={['email']} />
        <VirtualTable
          rowComponent={Row}
          columnExtensions={[
            {
              columnName: 'avatar',
              width: 65
            }
          ]}
        />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <TableRowDetail
          contentComponent={GridDetailContainer}
          toggleCellComponent={ExpandButton}
        />
        <DetailRowColumnOrder />
        <TableHeaderRow
          align="center"
          showSortingControls
        />
      </Grid>
    </ListTable>
  );
};

export default GridContainer;
