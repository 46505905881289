/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import MarionetteAppRouter from 'marionette.approuter';
import { app as App } from 'app/backbone/app';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';
import { api as searchInputApi } from 'app/backbone/entities/abstract/search_input';
import SpinnerView from 'app/backbone/lib/components/loading/spinner_view';

export default class AppRouter extends MarionetteAppRouter {
  initialize() {
    this.options = _.extend({}, this.options, { appRoutes: {} });
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
  }

  before() {
    searchInputApi.getChannel().request('clear:search_input');
    return (typeof this.chooseNav === 'function' ? this.chooseNav() : undefined);
  }

  appRoute(route, methodName) {
    const _route = _.insert(route, 2, '([a-z0-9._-]+)\\/');
    this.getOption('appRoutes')[_route] = methodName;
    return super.appRoute(new RegExp(_.trim(_route, '/')), methodName);
  }

  isOnSubpage(name) {
    // eslint-disable-next-line no-restricted-globals
    return _.indexOf(location.hash.split('/'), name) !== -1;
  }

  onRoute(method, route, ...rest) {
    const [orgSlug] = Array.from(rest[0]);
    App.sideNavs.find((model) => {
      const pageName = model.get('name').toLowerCase();
      if (this.isOnSubpage(pageName)) {
        model.choose();
        return true;
      }
    });
    if (this.chooseNav) { this.chooseNav(); }
    return orgNavsApi.getChannel().request('get:choosable:orgs').chooseBySlug(orgSlug, { triggeredByUser: false });
  }

  execute(callback, args) {
    if (callback) {
      const result = callback.apply(this, args.slice(1));
      if (result && result.then) {
        const region = App.getChannel().request('get:default:region');
        region.show(new SpinnerView({
          coveredView: region.currentView
        }));
      }
      return result;
    }
  }
}
