import { createSelector } from 'reselect';
import some from 'lodash/some';
import * as fromCertificatesSelector from '~/store/selectors/certificates';
import { getFilter } from './reducer';

export const getFilteredList = createSelector(
  fromCertificatesSelector.getCertificates,
  getFilter,
  (state, slug) => slug,
  (certificates, { query }, slug) => {
    let results = Object.values(certificates);
    if (slug && slug !== 'all') {
      results = results.filter(({ organization_id }) => organization_id === slug);
    }
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((certificate) => {
        const { name } = certificate;
        const searchString = name.toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return results;
  }
);
