import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field as FinalField } from 'react-final-form';
import Select from 'react-select';
import useValidation from '~/hooks/useValidation';
import { getUserOrganizationRolesForAPI } from '~/store/selectors/users';
import { getCurrentUserId } from '~/store/selectors/global';

const OrganizationDropdown = () => {
  const { t } = useTranslation();
  const { validateRequired } = useValidation();
  const organizations = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return getUserOrganizationRolesForAPI(state, currentUserId);
  });

  const mappedOrganizations = useMemo(() => organizations.map((item) => ({
    value: item.organizationId,
    label: item.organizationName,
    ...item
  })), [organizations]);

  return (
    <FinalField
      name="organization"
      validate={validateRequired}
    >
      {({ input }) => (
        <div className="form-group">
          <label className="col-sm-4">
            {t('models.organization')}
          </label>
          <div className="col-sm-8">
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 })
              }}
              options={mappedOrganizations}
              value={input.value}
              onChange={input.onChange}
            />
          </div>
        </div>
      )}
    </FinalField>
  );
};

export default OrganizationDropdown;
