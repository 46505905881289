import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

export function flatJson(obj) {
  return Object.assign({}, ...Object.keys(obj).map((k) => ({ [k]: JSON.stringify(obj[k]) })));
}

export function isDefined(value) {
  return !isUndefined(value) && !isNull(value);
}

export function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function urlIsValid(url) {
  const pattern = new RegExp('^(https?:\\/\\/)?'
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))'
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
    + '(\\?[;&a-z\\d%_.~+=-]*)?'
    + '(\\#[-a-z\\d_]*)?$', 'i');
  return !!pattern.test(url);
}
