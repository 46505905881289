import React, {
  useEffect, useState, useCallback, useContext, useRef
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import saveAs from 'file-saver';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  SortingState,
  RowDetailState,
  DataTypeProvider,
  SelectionState,
  IntegratedSelection,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableSelection,
  VirtualTable, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import {
  getNodeById, isNodeMonitorable, isNodeModifiable, isNodeDeletable, isNodeCreatable, getThiamisDeviceModel
} from '~/store/selectors/nodes';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import withTooltip from '~/hocs/withTooltip';
import ListTable from '~/components/list_table';

import NoDevicesScreen from '../no_devices';
import { getFilteredList, getIsExportingData } from '../selectors';
import { exportTableDataSuccess } from '../reducer';
import ExpandButton from './expand_button';
import { SharedLeasedFormatter } from './shared_leased_formatter';
import { StatusFormatter } from './status_formatter';
import { SerialFormatter } from './serial_formatter';
import GridDetailContainer from './detail_row';
import ManageDevicesContext from '../manage_context';

const SharedLeasedProvider = (props) => (
  <DataTypeProvider
    formatterComponent={withTooltip(SharedLeasedFormatter)}
    {...props}
  />
);

const StatusTypeProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={withTooltip(StatusFormatter)}
    className="status-cell"
  />
);

const SerialProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={SerialFormatter}
    className="name-cell"
  />
);

const statusWeights = {
  deactivated: 0,
  hibernated: 1,
  offline: 2,
  online: 3
};

const compareStatuses = (a, b) => {
  const statusA = statusWeights[a];
  const statusB = statusWeights[b];
  if (statusA === statusB) {
    return 0;
  }
  return (statusA < statusB) ? -1 : 1;
};

export const columns = [
  { name: 'serial', title: 'S/N' },
  { name: 'organization_name', title: 'Owner' },
  { name: 'sharedLeased', title: ' ' },
  { name: 'name', title: 'Name' },
  { name: 'description', title: 'Description' },
  { name: 'status', title: 'Status' }
];

const getRowId = ({ _id }) => _id;
const statusColumns = ['status'];
const serialColumns = ['serial'];
const sharedLeasedColumns = ['sharedLeased'];

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = ({ slug }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'status', direction: 'desc' }, { columnName: 'serial', direction: 'asc' }]);
  const {
    isActive: selectionActive,
    selectedDevices: selection,
    setSelectedDevices: setSelection
  } = useContext(ManageDevicesContext);

  const isExportingData = useSelector((state) => getIsExportingData(state));
  const exporterRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isExportingData) {
      exporterRef.current.exportGrid();
      dispatch(exportTableDataSuccess());
    }
  }, [dispatch, isExportingData]);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'devices.xlsx');
    });
  };

  useEffect(() => {
    setSelection([]);
  }, [slug, setSelection]);

  const Row = React.memo((props) => {
    const { row: { _id: nodeId } } = props;
    const isExpanded = includes(expandedRowIds, nodeId);
    const {
      isCreatable, isMonitorable, isModifiable, isDeletable
    } = useSelector(
      (state) => {
        const node = getNodeById(state, nodeId);
        const actions = node?.actions || [];
        return {
          isCreatable: isNodeCreatable(actions),
          deviceModel: getThiamisDeviceModel(state, nodeId),
          isMonitorable: isNodeMonitorable(actions),
          isModifiable: isNodeModifiable(actions),
          isDeletable: isNodeDeletable(actions)
        };
      }
    );

    const isRowExpandable = !isCreatable && (isModifiable || isMonitorable || isDeletable);
    const handleExpandRowClick = useCallback(
      () => {
        if (!isRowExpandable) return;
        if (includes(expandedRowIds, nodeId)) {
          setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== nodeId));
        } else {
          setExpandedRowIds([...expandedRowIds, ...[nodeId]]);
        }
      },
      [nodeId, isRowExpandable]
    );
    return (
      <VirtualTable.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded, disabled: !isRowExpandable })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);
  const rows = useSelector((state) => getFilteredList(state));

  return (
    <div className="list-table-container react-list-container">
      <ListTable>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting
            columnExtensions={[
              { columnName: 'status', compare: compareStatuses }
            ]}
          />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <SerialProvider
            for={serialColumns}
          />
          <SharedLeasedProvider
            for={sharedLeasedColumns}
          />
          <StatusTypeProvider
            for={statusColumns}
          />
          <VirtualTable
            rowComponent={Row}
            noDataRowComponent={NoDevicesScreen}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={onSave}
          />
          { selectionActive && <TableSelection showSelectAll /> }
          <TableHeaderRow align="center" showSortingControls />
          <TableRowDetail
            contentComponent={GridDetailContainer}
            toggleCellComponent={ExpandButton}
          />
          <DetailRowColumnOrder />
        </Grid>
      </ListTable>
    </div>
  );
};

export default GridContainer;
