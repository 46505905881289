import { fontSizes } from './fonts';
import { borderRadius, borderColors } from './borders';
import shadows from './shadows';
import colors from './colors';

const BASE_PADDING = 15;

export default {
  fontSize: `${fontSizes.panelHeader}px`,
  color: colors.panelGray,
  boxShadow: shadows.basic,
  background: colors.white,
  borderRadius: borderRadius.medium,
  borderTop: borderColors.panelDivider,
  headerPaddings: `${BASE_PADDING}px`,
  bodyPaddings: `${BASE_PADDING}px`
};
