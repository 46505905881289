/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import moment from 'app/config/moment';
import get from 'lodash/get';

export const fetchAlertsRequest = createAction('@@alerts/FETCH_REQUEST');
export const fetchAlertsSuccess = createAction('@@alerts/FETCH_SUCCESS');
export const fetchAlertsFailure = createAction('@@alerts/FETCH_FAILURE');

export const fetchAlertRequest = createAction('@@alert/FETCH_REQUEST');
export const fetchAlertSuccess = createAction('@@alert/FETCH_SUCCESS');
export const fetchAlertFailure = createAction('@@alert/FETCH_FAILURE');

export const fetchAlertDetailsRequest = createAction('@@alerts/FETCH_DETAILS_REQUEST');
export const fetchAlertDetailsSuccess = createAction('@@alerts/FETCH_DETAILS_SUCCESS');
export const fetchAlertDetailsFailure = createAction('@@alerts/FETCH_DETAILS_FAILURE');

export const destroyAlertsRequest = createAction('@@alerts/DELETE_REQUEST');
export const destroyAlertsSuccess = createAction('@@alerts/DELETE_SUCCESS');
export const destroyAlertsFailure = createAction('@@alerts/DELETE_FAILURE');

export const activateRequest = createAction('@@alerts/ACTIVATE_REQUEST');
export const activateSuccess = createAction('@@alerts/ACTIVATE_SUCCESS');
export const activateFailure = createAction('@@alerts/ACTIVATE_FAILURE');

export const deactivateRequest = createAction('@@alerts/DEACTIVATE_REQUEST');
export const deactivateSuccess = createAction('@@alerts/DEACTIVATE_SUCCESS');
export const deactivateFailure = createAction('@@alerts/DEACTIVATE_FAILURE');

const idsInitialState = [];
const ids = createReducer(idsInitialState, {
  [fetchAlertsSuccess](state, { payload: { result } }) {
    return result;
  },
  [destroyAlertsSuccess](state, { payload: alertId }) {
    state.splice(state.findIndex((id) => id === alertId), 1);
  }
});

const byIdData = {};
const byId = createReducer(byIdData, {
  [fetchAlertsSuccess](state, { payload }) {
    if (payload.entities) {
      return get(payload.entities, 'alerts', byIdData);
    }
    return state;
  },
  [destroyAlertsRequest](state, { payload: alertId }) {
    state[alertId].isSyncing = true;
  },
  [destroyAlertsFailure](state, { payload: alertId }) {
    state[alertId].isSyncing = false;
  },
  [destroyAlertsSuccess](state, { payload: alertId }) {
    delete state[alertId];
  },
  [activateSuccess](state, { payload: { alertId, alert } }) {
    state[alertId].status = alert.status;
    state[alertId].actions = alert.actions;
  },
  [deactivateSuccess](state, { payload: { alertId, alert } }) {
    state[alertId].status = alert.status;
    state[alertId].actions = alert.actions;
  },
  [fetchAlertDetailsSuccess](state, { payload: { alertId } }) {
    state[alertId].isSyncing = false;
    state[alertId].isSynced = true;
  },
  [fetchAlertSuccess](state, { payload: { entities: { alerts }, alertId } }) {
    if (alerts) {
      return {
        ...state,
        [alertId]: {
          ...state[alertId],
          ...get(alerts, alertId, byIdData)
        }
      };
    }
    return state;
  },
  [fetchAlertDetailsRequest](state, { payload: { alertId } }) {
    if (state[alertId]) {
      state[alertId].isSynced = false;
      state[alertId].isSyncing = true;
    }
  },
  [fetchAlertDetailsFailure](state, { payload: { alertId } }) {
    if (state[alertId]) {
      state[alertId].isSyncing = false;
      state[alertId].isSynced = false;
    }
  }
});

const isUpdating = createReducer(false, {
  [activateRequest]() {
    return true;
  },
  [activateSuccess]() {
    return false;
  },
  [activateFailure]() {
    return false;
  },
  [deactivateRequest]() {
    return true;
  },
  [deactivateSuccess]() {
    return false;
  },
  [deactivateFailure]() {
    return false;
  }
});

const updateError = createReducer(null, {
  [activateRequest]() {
    return null;
  },
  [activateFailure](state, { payload }) {
    return payload;
  },
  [deactivateRequest]() {
    return null;
  },
  [deactivateFailure](state, { payload }) {
    return payload;
  }
});

const isFetched = createReducer(false, {
  [fetchAlertsRequest]() {
    return false;
  },
  [fetchAlertsFailure]() {
    return false;
  },
  [fetchAlertsSuccess]() {
    return true;
  }
});

const whenFetched = createReducer(null, {
  [fetchAlertsSuccess]() {
    return moment().utc().valueOf();
  }
});

const alerts = combineReducers({
  ids,
  byId,
  isFetched,
  isUpdating,
  updateError,
  whenFetched
});

export const getAlerts = (state) => state.alerts.byId;
export const getAlertById = (state, id) => state.alerts.byId[id];
export const getIds = (state) => state.alerts.ids;
export const getIsFetched = (state) => state.alerts.isFetched;
export const getIsUpdating = (state) => state.alerts.isUpdating;
export const getUpdateError = (state) => state.alerts.updateError;
export const getWhenFetched = (state) => state.alerts.whenFetched;
export const getAlertIsSyncing = (state, id) => state.alerts.byId[id]?.isSyncing;
export const getAlertIsSynced = (state, id) => state.alerts.byId[id]?.isSynced;

export default alerts;
