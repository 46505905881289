import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '~/components/modal';
import { rolloutFirmwaresRequest } from '../reducer';
import ModalForm from './form';

const RolloutFirmwareModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSuccess = useCallback(({ version, channel, serials }) => {
    const toastOptions = {
      toastId: 'success_firmware_rolledout',
      closeOnClick: true,
      autoClose: false
    };
    if (serials) {
      toast.success(t('notifications.success.firmware_rolledout_serials', { version, serials: serials.join(', ') }), toastOptions);
    } else {
      toast.success(t('notifications.success.firmware_rolledout_channel', { version, channel }), toastOptions);
    }
    toggleModal();
  }, [t, toggleModal]);

  const dispatch = useDispatch();
  const handleRolloutFirmware = useCallback((values) => {
    const { version, channel, serials } = values;
    const data = {
      version: version?.value
    };
    if (channel?.value) {
      data.channel = channel.value;
    } else {
      data.serials = serials.map(({ value }) => value);
    }
    dispatch(rolloutFirmwaresRequest({
      ...data,
      onSuccess: handleSuccess
    }));
  }, [dispatch, handleSuccess]);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={handleClose}
      title={t('firmwares.rollout')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleRolloutFirmware}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit }) => (
            <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default RolloutFirmwareModal;
