import { View } from 'backbone.marionette';
import ReactDOM from 'react-dom';
import { unmountComponentAtNode } from 'app/backbone/lib/react/utils';

const ReactView = View.extend({
  className: 'clearfix fill-height',
  render() {
    ReactDOM.render(this.router(), this.el);
  },

  destroy(...args) {
    unmountComponentAtNode(this.el);
    View.prototype.destroy.apply(this, args);
  },

  router() {
    return _.abstractMethod();
  }
});

export default ReactView;