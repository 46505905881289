/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { app as App } from 'app/backbone/app';
import { ContainerLayout, LayoutingContainer, Toolbar } from 'app/backbone/lib/widgets/widgets_base/container_views';
import WidgetsConfig from 'app/backbone/lib/widgets/config';
import { api as apiWidget } from 'app/backbone/entities/widgets/widgets';
import { WidgetConfigForm } from 'app/backbone/lib/widgets/widgets_base/widget_views';
import { Header, HeaderTitle, StaticHeaderTitle } from 'app/backbone/lib/widgets/widgets_base/report_views';
import { View } from 'backbone.marionette';

export const BaseDashboardCtrlMethods = (superclass) => class extends superclass {
  initialize(options) {
    super.initialize(options);
    const { requiredOptionNames } = this.getWidgetConfigs();
    const addOptions = _(options).pick(requiredOptionNames);
    if (_(addOptions).size() < _(requiredOptionNames).size()) {
      const missingOptions = _.difference(requiredOptionNames, _(addOptions).keys());
      throw new Error(`missing option(s): ${missingOptions.join()}`);
    }
    return _(this).extend(addOptions);
  }

  showWithDashboardLoading(layout, options = {}) {
    return this.show(layout, _(options).defaults({
      loading: {
        loadingType: 'opacity'
      }
    })
    );
  }

  showConfigDialog(widgetConfigView) {
    return this.showDialog(widgetConfigView, {
      title: widgetConfigView.getConfigLayoutTitle(),
      class: this._getWidgetConfigClass(widgetConfigView)
    }
    );
  }

  _getWidgetConfigClass(widgetConfigView) {
    return `${widgetConfigView.formModel.isNew() ? 'is-new' : undefined} ${_.underscored(widgetConfigView.formModel.getType())}_modal`;
  }

  _showHeader(report) {
    return this.layout.getRegion('headerRegion').show(this._getHeader(report));
  }

  _getHeader(report) {
    this.headerView = new Header();
    this.listenTo(this.headerView, 'attach', () => {
      this.headerView.getRegion('titleRegion').show(this.getHeaderTitle(report));
      return this.headerView.toolbarRegion.show(this.getHeaderToolbar(report.get('widgets')));
    });
    return this.headerView;
  }

  _showWidgets(report, region) {
    if (!region) {
      region = this.layout.getRegion('widgetsRegion'); // eslint-disable-line no-param-reassign
    }
    return region.show(this._getWidgetsView(report));
  }

  _getWidgetsView(report) {
    const containerLayout = new ContainerLayout({
      collection: report.get('widgets') });
    this.listenTo(containerLayout, 'attach', () => {
      const container = this.getContainer(report.get('widgets'));
      containerLayout.getRegion('containerRegion').show(container);

      this.listenTo(container, 'childview:click:config', (widgetLayout) => this.showConfigDialog(this._getConfigLayoutFor(widgetLayout.model)));

      return this.listenTo(container, 'childview:click:resize', (widgetLayout) => widgetLayout.model.toggleSize());
    });
    return containerLayout;
  }

  componentViewOptions() {
    return _(this).pick(this.getWidgetConfigs().requiredOptionNames);
  }

  getConfigView(formParams) {
    const ConfigViewClass = this.getWidgetConfigs().widgets[formParams.model.get('_type')].confView();
    return new ConfigViewClass(_(this.componentViewOptions()).extend(formParams));
  }

  _getConfigLayoutFor(model, options) {
    const configLayout = this.getConfigLayoutView(model);
    const { formModel } = configLayout;
    const formParams = (typeof formModel.formParams === 'function' ? formModel.formParams() : undefined) || {};
    const configView = this.getConfigView(_(formParams).extend({ model: formModel }, options));

    this.listenTo(configLayout, 'attach', () => configLayout.getRegion('configRegion').show(configView));
    this.listenTo(configLayout, 'save:success', () => App.getView().getRegion('dialogRegion').hideDialog());
    return configLayout;
  }

  getConfigLayoutView(model) {
    return new WidgetConfigForm({
      model });
  }

  getWidgetConfigs() {
    return WidgetsConfig;
  }
};

export const StaticTitleCtrlMethods = (superclass) => class extends superclass {
  getHeaderTitle(report) {
    return new StaticHeaderTitle({
      model: report });
  }
};

export const ChangeableTitleCtrlMethods = (superclass) => class extends superclass {
  getHeaderTitle(report) {
    const headerView = new HeaderTitle({
      model: report });
    this.listenTo(headerView, 'click:destroy', () =>
      report.destroy({
        wait: true,
        success() { return App.getChannel().request('app:analytics'); }
      })
    );
    return headerView;
  }
};

export const MonitorHeaderCtrlMethods = (superclass) => class extends superclass {
  getHeaderToolbar() {
    return new View();
  }
};

export const WidgetOpsHeaderCtrlMethods = (superclass) => class extends superclass {
  getHeaderToolbar(widgets) {
    const toolbar = new Toolbar({
      collection: widgets });
    this.listenTo(toolbar, 'click:new:text_widget', () => this._addWidget(widgets, { _type: 'TextWidget' }));
    this.listenTo(toolbar, 'click:new:node_widget', () => this._addWidget(widgets, { _type: 'NodeWidget' }, _(this).pick('thiamises')));
    this.listenTo(toolbar, 'click:new:chart_widget', () => this._addWidget(widgets, { _type: 'ChartWidget' }, _(this).pick('thiamises')));
    this.listenTo(toolbar, 'click:new:map_widget', () => this._addWidget(widgets, { _type: 'MapWidget' }, _(this).pick('thiamises')));
    this.listenTo(toolbar, 'click:new:table_widget', () => this._addWidget(widgets, { _type: 'TableWidget' }));
    this.listenTo(toolbar, 'click:new:blocks_widget', () => this._addWidget(widgets, { _type: 'BlocksWidget' }, _(this).pick('thiamises')));
    this.listenTo(toolbar, 'click:new:heatmap_widget', () => this._addWidget(widgets, { _type: 'HeatmapWidget' }, _(this).pick('thiamises')));
    this.listenTo(toolbar, 'click:new:twitter_widget', () => {
      if (widgets.hasTwitterWidget()) { return; }
      return this._addWidget(widgets, { _type: 'TwitterWidget' });
    });
    return toolbar;
  }

  _addWidget(widgets, widgetAttrs, options) {
    const newWidget = apiWidget.getChannel().request('new:widget', widgetAttrs, options);
    newWidget.collection = widgets;
    this.listenToOnce(newWidget, 'sync', () => widgets.add(newWidget, {
      failure(msg) {
        return App.getChannel().trigger('flash:message', 'error', msg);
      }
    }
    ));
    if (newWidget.get('_type') === 'TwitterWidget') {
      return newWidget.save();
    }
    return this.showConfigDialog(this._getConfigLayoutFor(newWidget, options));
  }
};

export const FixedContainerCtrlMethods = (superclass) => class extends superclass {
  getContainer(widgets) {
    // eslint-disable-next-line no-undef
    return new Container(
      _({ collection: widgets }).extend(
        _(this).pick(WidgetsConfig.requiredOptionNames))
    );
  }
};

export const LayoutedContainerCtrlMethods = (superclass) => class extends superclass {
  getContainer(widgets) {
    return new LayoutingContainer(
      _({ collection: widgets }).extend(
        _(this).pick(WidgetsConfig.requiredOptionNames))
    );
  }
};
