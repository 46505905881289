import React, { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganizations } from '~/store/reducers/organizations';
import { transferRequest } from '~/store/reducers/nodes';
import Modal from '~/components/modal';
import { getCalibrationById, fetchCalibrationsRequest } from '../../../reducer';
import Select from '~/components/form/select';
import ManageCalibrationsContext from '../../../manage_context';
import ModalTable from './modal_table';

const TransferModal = ({ type, toggleModal }) => {
  const { selectedCalibrations, setSelectedCalibrations, setIsActive } = useContext(ManageCalibrationsContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    organizations
  } = useSelector((state) => {
    const nodes = selectedCalibrations.map((deviceId) => getCalibrationById(state, deviceId));
    const orgs = getOrganizations(state);
    const organizationsMapped = Object.values(orgs)
      .map(({ _id, name }) => ({
        value: _id,
        label: name
      }))
      .filter(({ label }) => !!label)
      .sort((organization1, organization2) => organization1.label.localeCompare(organization2.label));
    const nodeOrganizations = nodes.map((node) => node.organization_id);
    return {
      deviceOrganizations: nodeOrganizations,
      organizations: organizationsMapped
    };
  });

  const handleValidate = useCallback((values) => {
    const errors = {};
    if (!values.selectedOrganization) {
      errors.selectedOrganization = 'Required';
    }
    if (!values.selectedBilling) {
      errors.selectedBilling = 'Required';
    }
    return errors;
  }, []);

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);

  const handleSuccess = useCallback(() => {
    toast.success(
      t('thiamis.manage_dialog.success', { action: t('thiamis.manage_actions.transfer_past') }),
      {
        toastId: 'success_transfer',
        position: toast.POSITION.TOP_CENTER
      }
    );
    setSelectedCalibrations([]);
    setIsActive(false);
    toggleModal();
    dispatch(fetchCalibrationsRequest());
  }, [dispatch, t, setSelectedCalibrations, setIsActive, toggleModal]);

  const handleAction = useCallback((values) => {
    const { selectedOrganization, selectedBilling } = values;
    selectedCalibrations.forEach((nodeId) => {
      dispatch(transferRequest({
        type, nodeId, orgId: selectedOrganization?.value, billingId: selectedBilling?.value, onSuccess: handleSuccess
      }));
    });
  }, [dispatch, type, selectedCalibrations, handleSuccess]);

  return (
    <Modal
      isVisible={!!type}
      handleCancel={handleCancel}
      title="Manage Thiamis"
    >
      <div className="dialog-content-region">
        <FinalForm
          initialValues={{ selectedBilling: null, selectedOrganization: null, disabledNodes: [] }}
          onSubmit={handleAction}
          validate={handleValidate}
          render={({ handleSubmit, valid }) => (
            <div className="form">
              <div className="form-region">
                <FinalField name="selectedOrganization">
                  {({ input }) => (
                    <div className="row">
                      <div className="col-sm-6">
                        <Select
                          label={t('thiamis.owner')}
                          tooltip={t('thiamis.tooltips.manage_dialog_transfer_orgazanition')}
                          options={organizations}
                          id="selectOrganizations"
                          helpText={!organizations.length && t('thiamis.manage_dialog.no_partnerships')}
                          {...input}
                        />
                      </div>
                    </div>
                  )}
                </FinalField>
                <FinalField name="selectedBilling">
                  {({ input }) => (
                    <div className="row">
                      <div className="col-sm-6">
                        <Select
                          label={t('thiamis.billing')}
                          tooltip={t('thiamis.tooltips.manage_dialog_transfer_billing_orgazanition')}
                          options={organizations}
                          id="selectBillingOrganizations"
                          helpText={!organizations.length && t('thiamis.manage_dialog.no_partnerships')}
                          {...input}
                        />
                      </div>
                    </div>
                  )}
                </FinalField>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-responsive manage-node-table table-region">
                      <ModalTable showBilling />
                    </div>
                    <p className="help-block">
                      {t('thiamis.manage_dialog.transfer_help_text')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group form-actions clearfix">
                <button type="button" className="btn btn-default" onClick={handleCancel}>
                  {t('base.buttons.cancel')}
                </button>
                <button
                  className="save-form btn btn-primary"
                  disabled={!valid}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {t('thiamis.manage_actions.transfer')}
                </button>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default TransferModal;
