import React from 'react';

export const ContactFormatter = ({ value }) => (
  <a href={`mailto:${value}`}>
    <span>
      <i className="fa fa-fw fa-envelope" />
      <span>{value}</span>
    </span>
  </a>
);
