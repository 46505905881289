import { createSelector } from 'reselect';
import some from 'lodash/some';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import {
  map as fpMap, sortBy as fpSortBy
} from 'lodash/fp';
import union from 'lodash/union';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import * as fromCalibration from './reducer';

export function getStatus(calibration) {
  const { calibrated, timestamp } = calibration;
  if (timestamp) {
    return calibrated ? 'ok' : 'fail';
  }
  return 'not_run';
}

export const getCalibratedParameters = (calibrations) => flow(
  fpMap((calibration) => ({ ...calibration, status: getStatus(calibration) })),
  fpSortBy(['name'])
)(calibrations);

export const getCalibrationFilterTags = createSelector(
  fromCalibration.getFilter,
  ({ tags }) => tags
);

export const getIsReferenceDevicesFetched = createSelector(
  fromCalibration.getReferenceDevices,
  (references) => !!references.length
);

export const getReferenceDevicesValueLabelPairs = createSelector(
  fromCalibration.getReferenceDevices,
  (references) => _.chain(references)
    .groupBy(({ sensor }) => sensor)
    .map((items, group) => ({ label: group, options: items.map(({ name, path, serial }) => ({ label: `[${serial}] ${name}`, value: path })) }))
    .value()
);

function getCounts(tags) {
  return tags.reduce((result, tag) => {
    if (!result[tag]) {
      result[tag] = 0; // eslint-disable-line no-param-reassign
    }
    result[tag] += 1; // eslint-disable-line no-param-reassign
    return result;
  }, {});
}

const createStatusFilterTags = (calibration) => [getStatus(calibration)];

export const getCalibrationStatusFilterTags = createSelector(
  fromCalibration.getCalibration,
  (calibration) => {
    const result = {};
    const list = Object.values(calibration);
    result.counts = getCounts(flatten(list.map(createStatusFilterTags)));
    result.all = Object.values(result.counts).length;
    return result;
  }
);

export const getFilteredList = createSelector(
  fromCalibration.getCalibration,
  fromCalibration.getFilter,
  (calibrations, { query, tags }) => {
    let results = Object.values(calibrations).map((calibration) => {
      const {
        serial, timestamp, measurements, calibrated, node_id
      } = calibration;
      return {
        serial,
        node_id,
        timestamp,
        calibrated,
        status: getStatus(calibration),
        metrics: measurements.metrics,
        parameters: getCalibratedParameters(measurements)
      };
    });
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((calibration) => {
        const { serial } = calibration;
        const searchString = flatten([serial]).join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    if (!isEmpty(tags)) {
      results = results.filter((calibration) => {
        const allTags = union(createStatusFilterTags(calibration));
        return intersection(tags, allTags).length === tags.length;
      });
    }
    return results;
  }
);
