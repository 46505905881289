/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app'; // eslint-disable-line import/no-cycle
import { SingleChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import { api as membershipApi } from 'app/backbone/entities/membership'; // eslint-disable-line import/no-cycle
import { __guard__ } from 'app/utils/custom-fns';
import reduxStore from 'app/react/store';
import { setGlobalOrganizationId } from '~/store/reducers/global';
import { OrganizationsBase } from './org_base'; // eslint-disable-line import/no-cycle

class OrgNav extends Chooser(Backbone.Model) {
  presentName() {
    return this.get('name');
  }

  isAll() {
    return (this.get('is_all') != null);
  }
}

class OrgNavsColl extends SingleChooser(OrganizationsBase) {
  get model() { return OrgNav; }

  getChosedSlug() {
    const firstChosen = this.getFirstChosen();
    return firstChosen != null ? firstChosen.get('id') : null;
  }

  chooseBySlug(id, options) {
    const nav = this.get(id) || this.first();
    if (nav != null) {
      this.choose(nav, options);
      reduxStore.dispatch(setGlobalOrganizationId({ value: nav.get('id'), label: nav.get('name'), external_id: nav.get('external_id') }));
    }
  }
}

export const API = MnObject.extend({
  channelName: 'entities:org_navs',

  radioRequests: {
    'get:choosable:orgs': 'getOrgNavs',
    'get:chosen:org': 'getChosenOrg',
    'reset:choosable:orgs': 'resetOrgNavs'
  },

  onOrgNavChoose: _.throttle((orgNav) => {
    App.changeOrgSlugRoute(orgNav.get('id'));
    return App.getChannel().request('enable:org_filter', true);
  }, 200, { leading: false }),

  getChosenOrg() {
    const orgNavs = this.getOrgNavs();
    return orgNavs.getFirstChosen();
  },

  getOrgNavs() {
    if (this.orgNavs) { return this.orgNavs; }
    this.orgNavs = new OrgNavsColl();
    return this._buildOrgNavs();
  },

  resetOrgNavs(organizations) {
    membershipApi.getChannel().request('reset:memberships', {
      organizations,
      success: () => {
        this._buildOrgNavs();
        App.getChannel().trigger('reset:org_navs');
      }
    });
  },

  _buildOrgNavs() {
    const memberships = membershipApi.getChannel().request('get:memberships').collectAttrs();
    this.getOrgNavs().reset(memberships);

    if (this.orgNavs.size() > 1 || this.orgNavs.size() === 0) {
      this.orgNavs.add({ name: I18n.t('base.labels.all'), is_all: true, id: 'all' }, { at: 0 });
    }

    const orgSlug = __guard__(__guard__(Backbone.history.location.hash.split('/'), (x1) => x1['0']), (x) => x.substring(1));

    if (orgSlug === 'all' || orgSlug === '') {
      __guard__(this.orgNavs.first(), (x2) => x2.choose({ triggeredByUser: false }));
    } else { this.orgNavs.chooseById(orgSlug, { triggeredByUser: false }); }

    this.orgNavs.on('collection:chose:one', this.onOrgNavChoose);

    return this.orgNavs;
  }
});

export const api = new API();