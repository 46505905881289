import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { api as alertsApi } from 'app/backbone/apps/alerts/alerts_app';
import Modal from '~/components/modal';
import ModalForm from './modal_form';

const MultiAlertModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleDownload = useCallback((values) => {
    const { expr: { value: expr }, value, organizationId: { value: organization_id } } = values;
    const dataPoints = values.parameters.dataPoints.map(({ _id, node_id }) => ({
      node_id,
      conditions: {
        [expr]: [_id, value]
      }
    }));
    alertsApi.getChannel().request('app:alerts:new', {
      payload: {
        data_points: dataPoints,
        organization_id
      }
    });
  }, []);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={handleClose}
      title={t('alerts.create_new_group_alert')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleDownload}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit }) => (
            <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default MultiAlertModal;
