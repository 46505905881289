import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import withTooltip from '~/hocs/withTooltip';
import { getOrganizationNamesByIds } from '~/store/selectors/organizations';

export const SharedLeasedFormatter = ({ row: { shared_to, leased_to } }) => {
  const isShared = shared_to && shared_to.length;
  const isLeased = leased_to && leased_to.length;

  const { type, count, ids } = useMemo(() => {
    if (isShared) {
      return {
        count: shared_to.length,
        ids: [...shared_to],
        type: 'shared'
      };
    }
    if (isLeased) {
      return {
        ids: [leased_to],
        type: 'leased'
      };
    }
    return {};
  }, [shared_to, leased_to, isLeased, isShared]);

  const { list } = useSelector(
    (state) => ({
      list: getOrganizationNamesByIds(ids)(state)
    })
  );

  const IconTooltip = ({ tooltipRef }) => (
    <a ref={tooltipRef} data-tip={list.join('<br/>')} type="button">
      <i className={`fa small-icon icon-${type}`}>
        {count > 1 ? <span className="badge">{count}</span> : null}
      </i>
    </a>
  );

  const Tooltip = withTooltip(IconTooltip);

  if (!isLeased && !isShared) return null;

  return (
    <Tooltip />
  );
};
