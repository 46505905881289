/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import moment from 'app/config/moment';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

export const fetchNodesRequest = createAction('@@nodes/FETCH_NODES_REQUEST');
export const fetchNodesSuccess = createAction('@@nodes/FETCH_NODES_SUCCESS');
export const fetchNodesFailure = createAction('@@nodes/FETCH_NODES_FAILURE');

export const fetchNodeRequest = createAction('@@nodes/FETCH_NODE_REQUEST');
export const fetchNodeSuccess = createAction('@@nodes/FETCH_NODE_SUCCESS');
export const fetchNodeFailure = createAction('@@nodes/FETCH_NODE_FAILURE');

export const editNodeRequest = createAction('@@nodes/EDIT_REQUEST');
export const editNodeSuccess = createAction('@@nodes/EDIT_SUCCESS');
export const editNodeFailure = createAction('@@nodes/EDIT_FAILURE');

export const editNodeDeviceAutomationRequest = createAction('@@nodes/EDIT_NODE_DEVICE_ATOMATION_REQUEST');
export const editNodeDeviceAutomationSuccess = createAction('@@nodes/EDIT_NODE_DEVICE_ATOMATION_SUCCESS');
export const editNodeDeviceAutomationFailure = createAction('@@nodes/EDIT_NODE_DEVICE_ATOMATION_FAILURE');

export const destroyNodesRequest = createAction('@@nodes/DELETE_REQUEST');
export const destroyNodesSuccess = createAction('@@nodes/DELETE_SUCCESS');
export const destroyNodesFailure = createAction('@@nodes/DELETE_FAILURE');

export const fetchDataPointsRequest = createAction('@@nodes/FETCH_DATA_POINTS_REQUEST');
export const fetchDataPointsSuccess = createAction('@@nodes/FETCH_DATA_POINTS_SUCCESS');
export const fetchDataPointsFailure = createAction('@@nodes/FETCH_DATA_POINTS_FAILURE');

export const activateRequest = createAction('@@nodes/ACTIVATE_REQUEST');
export const activateSuccess = createAction('@@nodes/ACTIVATE_SUCCESS');
export const activateFailure = createAction('@@nodes/ACTIVATE_FAILURE');

export const deactivateRequest = createAction('@@nodes/DEACTIVATE_REQUEST');
export const deactivateSuccess = createAction('@@nodes/DEACTIVATE_SUCCESS');
export const deactivateFailure = createAction('@@nodes/DEACTIVATE_FAILURE');

export const shareRequest = createAction('@@nodes/SHARE_REQUEST');
export const shareSuccess = createAction('@@nodes/SHARE_SUCCESS');
export const shareFailure = createAction('@@nodes/SHARE_FAILURE');

export const removeShareRequest = createAction('@@nodes/REMOVE_SHARE_REQUEST');
export const removeShareSuccess = createAction('@@nodes/REMOVE_SHARE_SUCCESS');
export const removeShareFailure = createAction('@@nodes/REMOVE_SHARE_FAILURE');

export const leaseRequest = createAction('@@nodes/LEASE_REQUEST');
export const leaseSuccess = createAction('@@nodes/LEASE_SUCCESS');
export const leaseFailure = createAction('@@nodes/LEASE_FAILURE');

export const transferRequest = createAction('@@nodes/TRANSFER_REQUEST');
export const transferSuccess = createAction('@@nodes/TRANSFER_SUCCESS');
export const transferFailure = createAction('@@nodes/TRANSFER_FAILURE');

export const claimRequest = createAction('@@nodes/CLAIM_REQUEST');
export const claimSuccess = createAction('@@nodes/CLAIM_SUCCESS');
export const claimFailure = createAction('@@nodes/CLAIM_FAILURE');

export const identifyByLedRequest = createAction('@@nodes/IDENTIFY_BY_LED_REQUEST');
export const identifyByLedSuccess = createAction('@@nodes/IDENTIFY_BY_LED_SUCCESS');
export const identifyByLedFailure = createAction('@@nodes/IDENTIFY_BY_LED_FAILURE');

export const removeLeaseRequest = createAction('@@nodes/REMOVE_LEASE_REQUEST');
export const removeLeaseSuccess = createAction('@@nodes/REMOVE_LEASE_SUCCESS');
export const removeLeaseFailure = createAction('@@nodes/REMOVE_LEASE_FAILURE');

export const clearNodes = createAction('@@nodes/CLEAR_ALL');

const idsInitialState = [];
const ids = createReducer(idsInitialState, {
  [fetchNodesSuccess](state, { payload: { result } }) {
    return uniq([...state, ...result]);
  },
  [fetchNodeSuccess](state, { payload: { result } }) {
    return uniq([...state, ...result]);
  },
  [destroyNodesSuccess](state, { payload: { nodeId } }) {
    return state.filter((id) => id !== nodeId);
  },
  [clearNodes]() {
    return idsInitialState;
  }
});

const byIdData = {};
const byId = createReducer(byIdData, {
  [fetchNodesSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'nodes', byIdData)
      };
    }
    return state;
  },
  [fetchNodeSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'nodes', byIdData)
      };
    }
    return state;
  },
  [clearNodes]() {
    return byIdData;
  },
  [editNodeDeviceAutomationSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'nodes', byIdData)
      };
    }
    return state;
  },
  [destroyNodesRequest](state, { payload: { nodeId } }) {
    state[nodeId].isSyncing = true;
  },
  [destroyNodesFailure](state, { payload: nodeId }) {
    state[nodeId].isSyncing = false;
  },
  [destroyNodesSuccess](state, { payload: { nodeId } }) {
    delete state[nodeId];
  },
  [activateSuccess](state, { payload: { nodeId, node } }) {
    state[nodeId].status = node.status;
    state[nodeId].actions = node.actions;
  },
  [deactivateSuccess](state, { payload: { nodeId, node } }) {
    state[nodeId].status = node.status;
    state[nodeId].actions = node.actions;
  },
  [shareSuccess](state, { payload: { nodeId, node } }) {
    state[nodeId].shared_to = node.shared_to;
    state[nodeId].actions = node.actions;
  },
  [removeShareSuccess](state, { payload: { nodeId, orgId, node } }) {
    state[nodeId].shared_to = (state[nodeId].shared_to || []).filter((item) => item !== orgId);
    state[nodeId].actions = node.actions;
  },
  [leaseSuccess](state, { payload: { nodeId, orgId, node } }) {
    state[nodeId].leased_to = orgId;
    state[nodeId].actions = node.actions;
  },
  [removeLeaseSuccess](state, { payload: { nodeId, node } }) {
    state[nodeId].leased_to = undefined;
    state[nodeId].actions = node.actions;
  },
  [transferSuccess](state, {
    payload: {
      nodeId, orgId, billingId, node
    }
  }) {
    state[nodeId].organization_id = orgId;
    state[nodeId].payment = billingId;
    state[nodeId].actions = node.actions;
  },
  [fetchDataPointsSuccess](state, { payload }) {
    const dataPoints = get(payload, 'entities.data_points', byIdData);
    if (dataPoints) {
      const grouped = groupBy(Object.values(dataPoints), 'node_id');
      let nodeDataPoints = [];
      Object.keys(grouped).forEach((nodeId) => {
        nodeDataPoints = grouped[nodeId] || [];
        if (state[nodeId]) {
          state[nodeId].data_points = nodeDataPoints.map(({ _id }) => _id);
          state[nodeId].isSyncing = false;
          state[nodeId].isSynced = true;
        }
      });
    }
  },
  [fetchDataPointsRequest](state, { payload: { node_id: nodeIds } }) {
    nodeIds?.forEach((nodeId) => {
      if (state[nodeId]) {
        state[nodeId].isSynced = false;
        state[nodeId].isSyncing = true;
      }
    });
  },
  [fetchDataPointsFailure](state, { payload: { node_id: nodeIds } }) {
    nodeIds?.forEach((nodeId) => {
      if (state[nodeId]) {
        state[nodeId].isSyncing = false;
        state[nodeId].isSynced = false;
      }
    });
  }
});

const isUpdating = createReducer(false, {
  [activateRequest]() {
    return true;
  },
  [activateSuccess]() {
    return false;
  },
  [activateFailure]() {
    return false;
  },
  [deactivateRequest]() {
    return true;
  },
  [deactivateSuccess]() {
    return false;
  },
  [deactivateFailure]() {
    return false;
  },
  [leaseRequest]() {
    return true;
  },
  [leaseSuccess]() {
    return false;
  },
  [leaseFailure]() {
    return false;
  },
  [removeLeaseRequest]() {
    return true;
  },
  [removeLeaseSuccess]() {
    return false;
  },
  [removeLeaseFailure]() {
    return false;
  },
  [shareRequest]() {
    return true;
  },
  [shareSuccess]() {
    return false;
  },
  [shareFailure]() {
    return false;
  },
  [removeShareRequest]() {
    return true;
  },
  [removeShareSuccess]() {
    return false;
  },
  [removeShareFailure]() {
    return false;
  },
  [transferRequest]() {
    return true;
  },
  [transferSuccess]() {
    return false;
  },
  [transferFailure]() {
    return false;
  },
  [editNodeDeviceAutomationRequest]() {
    return true;
  },
  [editNodeDeviceAutomationSuccess]() {
    return false;
  },
  [editNodeDeviceAutomationFailure]() {
    return false;
  }
});

const updateError = createReducer(null, {
  [activateRequest]() {
    return null;
  },
  [activateFailure](state, { payload }) {
    return payload;
  },
  [deactivateRequest]() {
    return null;
  },
  [deactivateFailure](state, { payload }) {
    return payload;
  },
  [leaseRequest]() {
    return null;
  },
  [leaseFailure](state, { payload }) {
    return payload;
  },
  [removeLeaseRequest]() {
    return null;
  },
  [removeLeaseFailure](state, { payload }) {
    return payload;
  },
  [shareRequest]() {
    return null;
  },
  [shareFailure](state, { payload }) {
    return payload;
  },
  [removeShareRequest]() {
    return null;
  },
  [removeShareFailure](state, { payload }) {
    return payload;
  },
  [transferRequest]() {
    return null;
  },
  [transferFailure](state, { payload }) {
    return payload;
  }
});

const isFetched = createReducer(false, {
  [fetchNodesRequest]() {
    return false;
  },
  [fetchNodesFailure]() {
    return false;
  },
  [fetchNodesSuccess]() {
    return true;
  },
  [clearNodes]() {
    return false;
  }
});

const isFetching = createReducer(false, {
  [fetchNodesRequest]() {
    return true;
  },
  [fetchNodesFailure]() {
    return false;
  },
  [fetchNodesSuccess]() {
    return false;
  },
  [clearNodes]() {
    return false;
  }
});

const whenFetched = createReducer(null, {
  [fetchNodesSuccess]() {
    return moment().utc().valueOf();
  }
});

const nodes = combineReducers({
  ids,
  byId,
  isFetched,
  isUpdating,
  isFetching,
  updateError,
  whenFetched
});

export const getNodes = (state) => state.nodes.byId;
export const getNodeById = (state, id) => state.nodes.byId[id];
export const getIds = (state) => state.nodes.ids;
export const getIsFetched = (state) => state.nodes.isFetched;
export const getIsFetching = (state) => state.nodes.isFetching;
export const getIsUpdating = (state) => state.nodes.isUpdating;
export const getUpdateError = (state) => state.nodes.updateError;
export const getWhenFetched = (state) => state.nodes.whenFetched;
export const getNodeIsSyncing = (state, id) => state.nodes.byId[id]?.isSyncing;
export const getNodeIsSynced = (state, id) => state.nodes.byId[id]?.isSynced;

export default nodes;
