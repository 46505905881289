import { createSelector } from 'reselect';
import moment from 'app/config/moment';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import compact from 'lodash/compact';
import endsWith from 'lodash/endsWith';
import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';
import I18n from 'app/config/i18n';
import * as Config from '~/../backbone/entities/nodes/config';
import * as fromNodes from '~/store/reducers/nodes';
import * as fromDevices from '~/store/reducers/devices';

export const getNodesIsFetched = (state) => fromNodes.getIsFetched(state);
export const getNodesIsFetching = (state) => fromNodes.getIsFetching(state);
export const getNodeById = (state, id) => fromNodes.getNodeById(state, id);
export const getNodesIds = (state) => fromNodes.getIds(state);
export const getNodes = (state) => Object.values(fromNodes.getNodes(state));

export const isNodeActivated = (status) => status === 'activated';
export const hasNodeAction = (actions, name) => includes(actions, name);
export const isNodeCreatable = (actions) => hasNodeAction(actions, 'create');
export const isNodeMonitorable = (actions) => hasNodeAction(actions, 'read');
export const isNodeModifiable = (actions) => hasNodeAction(actions, 'configure');
export const isNodeDeletable = (actions) => hasNodeAction(actions, 'delete');

export const getNodesLength = createSelector(
  getNodesIds,
  (nodes) => nodes.length
);

export const hasNodes = createSelector(
  getNodesLength,
  (size) => !!size
);

function getExtraReportingInterval(interval) {
  if (interval < moment.duration(5, 'minutes').asMilliseconds()) {
    return interval * 2;
  } return interval + moment.duration(2, 'minutes').asMilliseconds();
}

export function getReportingInterval(interval) {
  return interval ? (interval + 60000) : Config.MIN_REPORTING_INTERVAL;
}

export const getNodeReportingInterval = createSelector(
  getNodeById,
  (node) => getReportingInterval(node?.interval)
);

export const getNodeByIdSerial = createSelector(
  getNodeById,
  (node) => node?.serial
);

export const getNodeByIdName = createSelector(
  getNodeById,
  (node) => node?.name
);

export const isNodeReporting = (measurements, reportingInterval, now = moment().utc().valueOf()) => {
  const lastTs = Array.isArray(measurements) ? last((compact(measurements).sort())) : measurements;
  return (now - lastTs) < getExtraReportingInterval(reportingInterval);
};

export const getNodeStatus = createSelector(
  [getNodeById, getNodeReportingInterval, fromNodes.getWhenFetched, (state, nodeId, measurements) => measurements],
  (node = {}, interval, whenFetched, measurements) => {
    const { last_online, status } = node;
    if (isNodeActivated(status)) {
      const now = moment().utc().valueOf() - whenFetched > interval ? whenFetched : undefined;
      if (isNodeReporting(measurements || last_online, interval, now)) {
        return 'online';
      } return 'offline';
    }
    return status;
  }
);

export function isThiamisDevice(serial) {
  return endsWith(serial, ':0');
}

export function isSensor(serial) {
  return !isThiamisDevice(serial);
}

export function isAirthinx(serial) {
  return startsWith(serial, '0C') || startsWith(serial, '1C');
}

export function isDriq(serial) {
  return startsWith(serial, 'D0');
}

export function isFluidmatix(serial) {
  return startsWith(serial, 'A0');
}

export function isHealthway(serial) {
  return startsWith(serial, 'A1');
}

export function isHeraeus(serial) {
  return startsWith(serial, '0E');
}

export const filterSensorIds = (deviceIds = []) => deviceIds.filter(isSensor);
export const findThiamisDeviceId = (deviceIds = []) => deviceIds.find(isThiamisDevice);

export const getNodesByOrganizationId = createSelector(
  [fromNodes.getIds, fromNodes.getNodes, (state, organizationId) => organizationId],
  (ids, nodes, organizationId) => ids.filter((id) => nodes[id]?.organization_id === organizationId).map((id) => nodes[id])
);

export const getNodeDeviceIds = createSelector(
  [getNodeById],
  (node) => node?.devices
);

export const getNodeThiamisDevice = createSelector(
  [getNodeDeviceIds, fromDevices.getDevices],
  (deviceIds, devices) => devices[findThiamisDeviceId(deviceIds)]
);

export const getThiamisDeviceModel = createSelector(
  [getNodeThiamisDevice],
  (device) => device?.name
);

export const getNodeDataPointsIds = createSelector(
  getNodeById,
  (node) => node?.data_points || []
);

export const getNodeSensorIds = createSelector(
  getNodeDeviceIds,
  (devices) => filterSensorIds(devices)
);

export function getNodeDisplayName({ serial, name }) {
  if (isEmpty(serial)) {
    return null;
  }
  if (isEmpty(name)) {
    return serial;
  }
  return `${serial} - ${name}`;
}

export function getNodeValueLabelPair(node = {}) {
  const { _id: value } = node;
  return {
    value,
    label: getNodeDisplayName(node)
  };
}

export const getNodeByIdDisplayName = createSelector(
  [getNodeByIdSerial, getNodeByIdName],
  (serial, name) => getNodeDisplayName({ serial, name })
);

export const getTotalCountsByDeviceType = createSelector(
  getNodes,
  fromDevices.getDevices,
  (nodes, devicesById) => _.chain(nodes)
    .groupBy(({ actions, devices, leased_to }) => {
      if (isNodeCreatable(actions)) {
        return I18n.t('thiamis.inventory');
      }
      const device = findThiamisDeviceId(devices);
      if (leased_to) {
        return I18n.t('thiamis.states.leased');
      }
      return devicesById[device]?.name;
    }).map((thiamises, thiamisName) => {
      if (thiamisName === 'undefined') {
        return null;
      }
      return [thiamisName, thiamises.length];
    })
    .compact()
    .sortBy(([thiamisName]) => thiamisName)
    .value()
);

export const getSharedDevices = createSelector(
  getNodes,
  (state, organizationId) => organizationId,
  (nodes, organizationId) => _.chain(nodes)
    .filter(({ shared_to }) => !isEmpty(shared_to) && includes(shared_to, organizationId))
    .map((node) => getNodeDisplayName(node))
    .value()
);

export const getLeasedDevices = createSelector(
  getNodes,
  (state, organizationId) => organizationId,
  (nodes, organizationId) => _.chain(nodes)
    .filter(({ leased_to }) => !isEmpty(leased_to) && leased_to === organizationId)
    .map((node) => getNodeDisplayName(node))
    .value()
);

export const getNodesSerialsValueLabelPairs = createSelector(
  getNodes,
  (nodes) => nodes.map(({ serial }) => serial).sort().map((serial) => ({ value: serial, label: serial }))
);

export const getAirthinxSerialsValueLabelPairs = createSelector(
  getNodesSerialsValueLabelPairs,
  (nodes) => nodes.filter(({ value }) => isAirthinx(value))
);

const getNodesSortedByName = createSelector(
  getNodes,
  (nodes) => nodes
    .sort((node1, node2) => (node1.name || '').localeCompare(node2.name || ''))
);

export const getConfiguredDevicesForDropdown = createSelector(
  getNodesSortedByName,
  (nodes) => _(nodes)
    .filter((node) => !isNodeCreatable(node.actions))
    .map(({ _id, ...node }) => ({
      ...node,
      _id,
      value: _id,
      isSynced: node.isSynced,
      isSyncing: node.isSyncing,
      label: getNodeDisplayName(node)
    }))
);

export const getAirthinxValueLabelPairs = createSelector(
  getNodesSortedByName,
  (nodes) => nodes
    .filter((node) => !isNodeCreatable(node.actions) && isAirthinx(node.serial))
    .map((node) => ({
      ...getNodeValueLabelPair(node),
      isSynced: node.isSynced,
      isSyncing: node.isSyncing
    }))
);

export const getNodesValueLabelPairs = createSelector(
  getNodesSortedByName,
  (nodes) => nodes
    .map((node) => getNodeValueLabelPair(node))
);
