/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default (superclass) => class extends superclass {
  getValueLabelPairs() {
    return this.map((model) => ({ label: model.presentName(), value: model.id }));
  }
};
