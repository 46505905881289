import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getCurrentUserId } from '~/store/selectors/global';
import * as fromUsers from '~/store/selectors/users';
import { NOTICE_METHODS } from '~/utils/constants';

export const getCurrentUser = (state) => {
  const userId = getCurrentUserId(state);
  return fromUsers.getUserById(state, userId);
};

export const getCurrentUserEmail = createSelector(
  getCurrentUser,
  (user) => user?.email
);

export const getCurrentUserRoles = createSelector(
  getCurrentUser,
  (user) => fromUsers.getAppMetadata(user).roles
);

export const getCurrentUserHasEmptyRoles = (state) => {
  const userId = getCurrentUserId(state);
  return fromUsers.getUserByIdHasEmptyRoles(state, userId);
};

export const getCurrentUserIsSupport = createSelector(
  getCurrentUser,
  (user) => fromUsers.getUserIsSupport(fromUsers.getAppMetadata(user))
);

export const getCurrentUserIsAdmin = createSelector(
  getCurrentUser,
  (user) => fromUsers.getUserIsAdmin(fromUsers.getAppMetadata(user))
);

export const getCurrentUserIsManager = createSelector(
  getCurrentUser,
  (user) => fromUsers.getUserIsManager(fromUsers.getAppMetadata(user))
);

export const getCurrentUserIsOwner = createSelector(
  getCurrentUser,
  (user) => fromUsers.getUserIsOwner(fromUsers.getAppMetadata(user))
);

export const getCurrentUserMetaData = createSelector(
  getCurrentUser,
  (user) => fromUsers.getUserMetadata(user)
);

export const getCurrentUserNoticeMethods = createSelector(
  getCurrentUserMetaData,
  (metadata) => ({
    infoNotice: get(metadata, 'info_notice', NOTICE_METHODS.EMAIL),
    warningNotice: get(metadata, 'warning_notice', NOTICE_METHODS.EMAIL),
    criticalNotice: get(metadata, 'critical_notice', NOTICE_METHODS.EMAIL_SMS)
  })
);
