import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormState, Field as FinalField } from 'react-final-form';
import DatePicker from '~/components/form/datepicker';

import Select from '~/components/form/select';
import { getAirthinxSerialsValueLabelPairs } from '~/store/selectors/nodes';
import { getReferenceDevicesValueLabelPairs } from '../../../../selectors';
import useValidation from '~/hooks/useValidation';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const serialsOptions = useSelector(getAirthinxSerialsValueLabelPairs);
  const { validateRequired } = useValidation();
  const { hasValidationErrors } = useFormState();

  const referenceDevicesOptions = useSelector(getReferenceDevicesValueLabelPairs);

  return (
    <div className="form">
      <div className="form-region form-margins">
        <FinalField
          name="serials"
          validate={validateRequired}
        >
          {
            ({ input, meta }) => (
              <Select
                label={t('calibration.serials')}
                value={input.value}
                onChange={input.onChange}
                options={serialsOptions}
                isMulti
                onBlur={input.onBlur}
                hasError={meta.touched && meta.error}
              />
            )
          }
        </FinalField>
        <FinalField
          name="reference"
          validate={validateRequired}
        >
          {
            ({ input, meta }) => (
              <Select
                label={t('attributes.calibration.reference_value')}
                value={input.value}
                onChange={input.onChange}
                options={referenceDevicesOptions}
                onBlur={input.onBlur}
                hasError={meta.touched && meta.error}
              />
            )
          }
        </FinalField>
        <div className="flex-row">
          <FinalField
            name="from"
            validate={validateRequired}
          >
            {
            ({ input, meta }) => (
              <DatePicker
                label={t('thiamis.download_csv.from')}
                value={input.value}
                onBlur={input.onBlur}
                onChange={input.onChange}
                hasError={meta.touched && meta.error}
              />
            )
          }
          </FinalField>
          <FinalField
            name="to"
          >
            {
            ({ input }) => (
              <DatePicker
                label={t('thiamis.download_csv.to')}
                value={input.value}
                onChange={input.onChange}
              />
            )
          }
          </FinalField>
        </div>
      </div>
      <div className="form-group form-actions clearfix">
        <button type="button" className="btn btn-default" onClick={handleCancel}>
          {t('base.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="save-form btn btn-primary"
          disabled={hasValidationErrors}
          onClick={handleSubmit}
        >
          {t('base.buttons.run')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
