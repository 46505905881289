import styled from 'styled-components';
import icnArrowDown from '~/assets/icons/icn-arrow-down.svg';
import { Flex, Column, Image } from '../layout';
import { space } from '~/theme/space';

export const ExpandButton = styled(Image).attrs({
  src: icnArrowDown,
  mr: space(1),
  p: '2px'
})`
  transform: ${({ arrowDown }) => `rotate(${arrowDown ? '180deg' : '0deg'})`};
`;

export const TitleContainer = styled(Flex).attrs({
  mb: space(2)
})`
  cursor: pointer;
  user-select: none;
  ${({ expanded }) => !expanded
    && `
    margin-bottom: 0;
  `};
`;

export const GroupContainer = styled(Column).attrs({
  pb: space(3),
  mb: space(3),
  borderBottom: '1px solid',
  borderColor: 'midGrey'
})`
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;
export const ItemsContainer = styled(Column).attrs({
  pl: space(3)
})`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
`;
