import { createSelector } from 'reselect';
import some from 'lodash/some';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import I18n from 'app/config/i18n';
import * as fromNodes from '~/store/reducers/nodes';
import * as fromDevices from '~/store/reducers/devices';
import { getOrganizations } from '~/store/reducers/organizations';
import * as NodesSelectors from '~/store/selectors/nodes';
import { getPartnershipsValueLabelPairs } from '~/store/selectors/partnerships';
import { getManagedOrganizationsValueLabelPairs } from '~/store/selectors/organizations';

import * as fromReducer from './reducer';

export const getIsExportingData = (state) => fromReducer.getIsExportingData(state);

export const getNodesFilterTags = createSelector(
  fromReducer.getFilter,
  ({ tags }) => tags
);

function getCounts(tags) {
  return tags.reduce((result, tag) => {
    if (!result[tag]) {
      result[tag] = 0; // eslint-disable-line no-param-reassign
    }
    result[tag] += 1; // eslint-disable-line no-param-reassign
    return result;
  }, {});
}

const createPartnersFilterTags = (node) => {
  let tags = [];
  const { shared_to, leased_to } = node;

  if (!isEmpty(shared_to)) {
    tags = [...tags, ...shared_to];
  }

  if (leased_to) {
    tags.push(leased_to);
  }

  return tags;
};

const createSensorsFilterTags = (node, devices) => {
  let tags = [];

  const sensors = NodesSelectors.filterSensorIds(node.devices);
  if (!isEmpty(sensors)) {
    tags = compact(sensors.map((id) => devices[id]?.name));
  }

  return tags;
};

const createDevicesFilterTags = (node, devices) => {
  let tags = [];

  if (NodesSelectors.isNodeCreatable(node.actions)) {
    return [I18n.t('thiamis.inventory')];
  }
  const id = NodesSelectors.findThiamisDeviceId(node.devices);
  if (devices[id]) {
    const device = devices[id];
    tags = [device.name];
  }

  return tags;
};

export const createStatusFilterTags = (node, whenFetched) => {
  const {
    last_online, status, shared_to, leased_to, interval
  } = node;
  const tags = [];

  if (NodesSelectors.isNodeActivated(status)) {
    const reportingInterval = NodesSelectors.getReportingInterval(interval);
    tags.push('activated', NodesSelectors.isNodeReporting(last_online, reportingInterval, whenFetched) ? 'online' : 'offline');
  } else {
    tags.push(status);
  }

  if (!isEmpty(shared_to)) {
    tags.push('shared');
  }
  if (leased_to) {
    tags.push('leased');
  }
  return tags;
};

export const getNodes = createSelector(
  fromNodes.getIds, fromNodes.getNodes,
  (ids, nodes) => ids.map((id) => nodes[id])
);

export const getNodeStatusFilterTags = createSelector(
  [getNodes, fromNodes.getWhenFetched],
  (nodes, whenFetched) => {
    const counts = getCounts(flatten(nodes.map((node) => createStatusFilterTags(node, whenFetched))));
    return {
      counts,
      all: Object.values(counts).length
    };
  }
);

export const getNodeDevicesFilterTags = createSelector(
  [getNodes, fromDevices.getDevices],
  (nodes, devices) => {
    const counts = getCounts(flatten(nodes.map((node) => createDevicesFilterTags(node, devices))));
    return {
      counts,
      all: Object.values(counts).length
    };
  }
);

export const getNodeSensorsFilterTags = createSelector(
  [getNodes, fromDevices.getDevices],
  (nodes, devices) => {
    const counts = getCounts(flatten(nodes.map((node) => createSensorsFilterTags(node, devices))));
    return {
      counts,
      all: Object.values(counts).length
    };
  }
);

export const getNodePartnersFilterTags = createSelector(
  [getNodes],
  (nodes) => {
    const counts = getCounts(flatten(nodes.map(createPartnersFilterTags)));
    return {
      counts,
      all: Object.values(counts).length
    };
  }
);

export const getFilteredList = createSelector(
  getNodes,
  fromNodes.getWhenFetched,
  fromDevices.getDevices,
  getOrganizations,
  fromReducer.getFilter,
  (state) => state,
  (nodes, whenFetched, devices, organizations, { query, tags }, state) => {
    let results = nodes.map((node) => ({
      ...node,
      organization_name: organizations[node.organization_id]?.name,
      original_status: node.status,
      status: NodesSelectors.getNodeStatus(state, node?._id)
    }));
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((node) => {
        const { serial, name, description } = node;
        const searchString = [serial, name, description].join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    if (!isEmpty(tags)) {
      results = results.filter((node) => {
        const allTags = union(
          createStatusFilterTags({ ...node, status: node.original_status }, whenFetched),
          createDevicesFilterTags(node, devices),
          createSensorsFilterTags(node, devices),
          createPartnersFilterTags(node)
        );
        return intersection(tags, allTags).length === tags.length;
      });
    }
    return results;
  }
);

export const getFilteredListValueLabelPairs = createSelector(
  getFilteredList,
  (nodes) => nodes.map(NodesSelectors.getNodeValueLabelPair)
);

export const getNodeActions = createSelector(
  getNodes,
  (nodes) => _(nodes.map((node) => node.actions))
    .chain()
    .flatten()
    .uniq()
    .value()
);

export const getActionsForNodeId = createSelector(
  fromNodes.getNodeById,
  (node = {}) => node.actions
);

export const getOrganizationsValueLabelPairsForShareLease = createSelector(
  [getPartnershipsValueLabelPairs, getManagedOrganizationsValueLabelPairs],
  (partnerships, organizations) => sortBy(uniqBy([...partnerships, ...organizations], ({ value }) => value), ({ label }) => label)
);
