/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import { api as thiamisApi } from 'app/backbone/entities/nodes/thiamis';
import { WidgetNodesTitle } from 'app/backbone/lib/components/shared_views/widget_node_title';
import { ChartLayout } from 'app/backbone/lib/components/shared_views/chart';
import faceTemplate from './templates/face.hbs';

const ChartWidgetContentView = View.extend({
  template: faceTemplate,
  className: 'fill-height',

  regions:
    { chartRegion: '.chart-region' },

  initialize() {
    this.listenTo(this.model, 'change:node_ids', async () => {
      thiamisApi.getChannel().request('fetch:thiamises', { reset: true, data: { node_id: this.model.get('node_ids') } }).done(([thiamises]) => {
        this.options.thiamises.reset(thiamises);
      });
    });
    this.options.thiamises = thiamisApi.getChannel().request('fetch:thiamises', { reset: true, data: { node_id: this.model.get('node_ids') } });
  },

  showChart() {
    this.chartView = new ChartLayout({
      model: this.model,
      collection: this.model.getFilteredDataPointsColl(),
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        resetZoomButton: {
          position: {
            x: -10,
            y: 10
          },
          relativeTo: 'chart'
        },
        time: {
          timezone: this.model.getDataPointsColl().getDefaultTimezone()
        }
      }
    });

    App.getChannel().request('when:fetched', [this.model, this.options.thiamises], () => {
      this.model.getDataPointsColl().each((dp) => {
        const oldPresentName = dp.presentName;
        const _this = this;
        // eslint-disable-next-line no-param-reassign
        dp.presentName = () => {
          const name = oldPresentName.call(dp);
          const device = _this.options.thiamises.findWhere({ _id: dp.get('node_id') });
          if (device) {
            return `${device.get('name')} - ${name}`;
          }
        };
      });
    });

    this.listenTo(App.getChannel(), 'window:resized', () => this.chartView.getChartView().triggerMethod('after:resize'));
    this.listenTo(this.model.getDataPointsColl(), 'updates:set', () => {
      this.chartView.getChartView().getHighchart().update({
        time: {
          timezone: this.model.getDataPointsColl().getDefaultTimezone()
        }
      });
    });
    return this.getRegion('chartRegion').show(this.chartView);
  },

  getWidgetTitle() {
    return new WidgetNodesTitle({
      collection: this.options.thiamises
    });
  },

  onRender() {
    this.showChart();
  }
});

export default ChartWidgetContentView;
