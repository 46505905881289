import React from 'react';
import PropTypes from 'prop-types';
import ParamInfoIcon from 'app/backbone/lib/react/sensors/paramInfoIcon/paramInfoIcon';

const BatteryLevel = (props) => {
  const { value, noValueText, unit } = props;
  function getTitle() {
    return props.title(value);
  }

  function getBatteryLevel() {
    if (value >= 12) return '5';
    if ((value >= 11.5) && (value < 12)) return '4';
    if ((value >= 11) && (value < 11.5)) return '3';
    if ((value >= 10.6) && (value < 11)) return '2';
    return '1';
  }

  function isUSB() {
    return value != null && value >= 0 && value < 1.1;
  }

  function getIconClass() {
    if (isUSB()) return 'fa fa-sm fa-usb';
    return `icon-battery-${getBatteryLevel()}`;
  }

  function getBatteryDisplayValue() {
    if (isUSB()) return 'USB';
    if (!value) return noValueText;
    return `${value}${unit || 'V'}`;
  }

  if (value == null) {
    return null;
  }

  return (
    <ParamInfoIcon
      value={value}
      displayValue={getBatteryDisplayValue()}
      noValueText={noValueText}
      iconClass={getIconClass()}
      title={isUSB() ? 'USB' : `${getTitle()}${unit || 'V'}`}
    />
  );
};

BatteryLevel.defaultProps = {
  value: null,
  unit: ''
};

BatteryLevel.propTypes = {
  title: PropTypes.func.isRequired,
  value: PropTypes.number,
  noValueText: PropTypes.string.isRequired,
  unit: PropTypes.string
};

export default BatteryLevel;
