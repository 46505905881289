import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { acceptUserInvitationRequest } from '~/store/reducers/invitations';
import withTooltip from '~/hocs/withTooltip';

const AcceptInvitation = ({ id }) => {
  const { t } = useTranslation();

  const handleSuccess = useCallback(() => {
    toast.success(t('notifications.success.forms.user_invitation_accepted'), {
      toastId: 'success_user_invitation_accepted'
    });
  }, [t]);

  const dispatch = useDispatch();
  const handleAction = useCallback(
    () => dispatch(acceptUserInvitationRequest({ id, onSuccess: handleSuccess })), [dispatch, id, handleSuccess]
  );

  const IconTooltip = ({ title, tooltipRef }) => (
    <a
      ref={tooltipRef}
      data-tip={title}
      className="info"
      role="button"
      title={t('base.buttons.accept')}
      onClick={handleAction}
    >
      <i className="fa fa fa-fw fa-check" />
    </a>
  );
  const Tooltip = withTooltip(IconTooltip);

  return (
    <div
      className="expand-button"
      style={{ position: 'relative' }}
    >
      <Tooltip title={t('base.buttons.accept')} />
    </div>
  );
};

export default AcceptInvitation;
