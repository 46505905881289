import React, { useCallback, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import locales from 'app/config/common_data/locales';
import timezones from 'app/config/common_data/timezones';
import Input from '~/components/form/input';
import Select from '~/components/form/select';
import useValidation from '~/hooks/useValidation';
import PhotoUpload from '~/components/form/photo_upload';
import {
  Title,
  SaveButton,
  CancelButton,
  ButtonContainer
} from '~/styles';
import { Row, FormContainer } from './styles';

const languageOptions = locales.map(({ label, locale: value }) => ({ label, value }));

const tzOptions = Object.entries(timezones).map(([key, values]) => ({
  label: key,
  options: values.map(({ label, timezone: value }) => ({ label, value }))
}));

const findTimezoneInOptions = (timezone) => {
  const parentOpt = tzOptions.find(({ options }) => (
    options.some(({ value }) => value === timezone)
  ));

  return parentOpt ? parentOpt.options.find(({ value }) => value === timezone) : null;
};

const ProfileForm = ({
  onlyView = false,
  syncing,
  picture,
  metadata,
  initials,
  onSubmit,
  onCancel,
  withImageUploader = true
}) => {
  const {
    mobile,
    locale,
    timezone,
    given_name,
    family_name
  } = metadata;
  const { t } = useTranslation();
  const { validatePhone, validateRequired } = useValidation();

  const initialData = useMemo(() => ({
    mobile,
    upload_picture: picture,
    firstName: given_name || '',
    lastName: family_name || '',
    timezone: findTimezoneInOptions(timezone),
    locale: languageOptions.find(({ value }) => value === locale) || languageOptions[0]
  }), [mobile, locale, timezone, given_name, family_name, picture]);

  const handleFormSubmit = useCallback((data) => {
    onSubmit({
      user_metadata: {
        mobile: data.mobile,
        given_name: data.firstName,
        family_name: data.lastName,
        locale: data.locale.value,
        timezone: data.timezone.value,
        critical_notice: metadata.critical_notice,
        info_notice: metadata.info_notice,
        warning_notice: metadata.warning_notice,
        upload_picture: withImageUploader ? data.upload_picture : metadata.upload_picture
      }
    });
  }, [onSubmit, metadata, withImageUploader]);

  return (
    <FormContainer disabled={onlyView}>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialData}
        render={({ handleSubmit }) => (
          <div className="row">
            {withImageUploader && (
              <div className="col-sm-3 text-center">
                <Field
                  name="upload_picture"
                >
                  {
                    ({ input }) => (
                      <PhotoUpload
                        photo={input.value}
                        setPhoto={input.onChange}
                        textPlaceholder={!input.value}
                        placeholder={initials}
                        className={`profile-picture ${!input.value ? 'no-logo' : ''}`}
                      />
                    )
                  }
                </Field>
              </div>
            )}
            <div className={withImageUploader ? 'col-sm-9' : ''}>
              <Title>{t('users.more_info.contact_info')}</Title>
              <Row>
                <Field
                  name="firstName"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Input
                      label={t('attributes.user.given_name')}
                      {...input}
                      disabled={onlyView}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field
                  name="lastName"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Input
                      label={t('attributes.user.family_name')}
                      {...input}
                      disabled={onlyView}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Row>
              <Row>
                <Field
                  name="mobile"
                  validate={validatePhone}
                >
                  {({ input, meta }) => (
                    <Input
                      id="phone"
                      label={t('attributes.user.phone')}
                      type="phone"
                      {...input}
                      placeholder="+1 201 555 0123"
                      disabled={onlyView}
                      withCountryCallingCode
                      errorText={meta.touched && meta.error}
                      defaultCountry="US"
                    />
                  )}
                </Field>
                <Field
                  name="locale"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Select
                      label={t('attributes.user.locale')}
                      {...input}
                      isDisabled={onlyView}
                      options={languageOptions}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Row>
              <Row>
                <Field
                  name="timezone"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Select
                      label={t('attributes.user.timezone')}
                      {...input}
                      isDisabled={onlyView}
                      options={tzOptions}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Row>
              <ButtonContainer>
                {onCancel && (
                  <CancelButton onClick={onCancel}>
                    {t('base.buttons.cancel')}
                  </CancelButton>
                )}
                <SaveButton
                  disabled={syncing || onlyView}
                  onClick={handleSubmit}
                >
                  {t('base.buttons.save_changes')}
                </SaveButton>
              </ButtonContainer>
            </div>
          </div>
        )}
      />
    </FormContainer>
  );
};

export default ProfileForm;
