import React from 'react';
import { connect } from 'react-redux';
import ListTable from '~/components/list_table';

import { getFilteredList } from '../selectors';
import Table from './table';

const GridContainer = ({ rows }) => (
  <div className="list-table-container react-list-container">
    <ListTable>
      <Table rows={rows} />
    </ListTable>
  </div>
);

const mapStateToProps = (state) => ({
  rows: getFilteredList(state)
});

export default connect(mapStateToProps)(GridContainer);
