import React from 'react';
import ReactTooltip from 'react-tooltip';

const tooltipPosition = (_, currentEvent, currentTarget, node) => {
  if (!node.getBoundingClientRect) return null;
  /* use default positioning depending on cursor */
  if (currentTarget.hasAttribute('data-use-default-pos')) {
    return _;
  }
  /* use custom positioning depending on target element */
  const hoveredOptions = currentTarget.getBoundingClientRect();
  const tooltipOptions = node.getBoundingClientRect();
  const x = hoveredOptions.left - tooltipOptions.width / 2 + hoveredOptions.width / 2;
  const y = hoveredOptions.top - tooltipOptions.height;
  return { top: y - 5, left: x };
};

const Tooltip = () => (
  <ReactTooltip
    className="react-tooltip"
    scrollHide
    multiline
    globalEventOff="click"
    arrowColor="#8691a1"
    overridePosition={tooltipPosition}
  />
);

export default Tooltip;
