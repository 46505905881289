/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { __guard__ } from 'app/utils/custom-fns';

export const DiffDataPointConditionMixin = (superclass) => class extends superclass {
  getJsonValueAttrs(data_point_id, attrs, options) {
    let { data_point_id2 } = attrs;
    if (this.getReferenceById(data_point_id2)) {
      data_point_id2 = __guard__(__guard__(this.get('references'), (x1) => x1.get(data_point_id2)), (x) => x.toJSON(options));
    }
    return [data_point_id, data_point_id2];
  }
};

export const AlertDataPointMixin = (superclass) => class extends superclass {
  getAlert() {
    return __guard__(this.collection != null ? this.collection.parents : undefined, (x) => x[0]);
  }

  get defaults() {
    return {
      _type: 'alert',
      conditions: []
    };
  }
};
