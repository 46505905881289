import { fonts, fontSizes } from './fonts';
import colors from './colors';
import shadows from './shadows';
import panels from './panels';
import grid from './grid';
import { borderRadius, borderColors, borderWidth } from './borders';
import { switchDefault } from './inputs';

export default {
  colors,
  fonts,
  fontSizes,
  shadows,
  panels,
  borderRadius,
  borderWidth,
  borderColors,
  switchDefault,
  grid
};
