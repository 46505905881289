import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import RunCalibrationModal from './manage_modal/run_calibration_modal';
import IdentifyWithLedModal from './manage_modal/identify_with_led_modal';
import TransferModal from './manage_modal/transfer_modal';

const manageActions = [
  ['run', 'identify'], 'transfer'
];
const actions = flatten(map(manageActions, (action) => (_.isArray(action) ? [...action, 'separator'] : action)));

const ActionsDropdown = ({ handleToggleDropdown }) => {
  const [actionType, setActionType] = useState(null);
  const { t } = useTranslation();

  const deselectActionType = useCallback(() => setActionType(null), [setActionType]);

  const handleActionClick = useCallback((action) => () => {
    setActionType(action);
    handleToggleDropdown();
  }, [handleToggleDropdown]);

  return (
    <>
      <button
        type="button"
        key="dropdown"
        className="btn btn-default dropdown-toggle"
        onClick={handleToggleDropdown}
        title={t('thiamis.tooltips.available_actions')}
      >
        <span className="caret-arrow">
          <span className="dropdown-actions">{t('base.labels.actions')}</span>
          <i className="fa" />
        </span>
      </button>
      ,
      <ul key="actions" className="dropdown-menu dropdown-menu-right">
        { actions.map((action) => {
          if (action === 'separator') {
            return <li role="separator" className="divider" key={action} />;
          }
          return (
            <li key={action}>
              <a role="button" tabIndex={0} onClick={handleActionClick(action)}>
                {t(`calibration.manage_actions.${action}`)}
              </a>
            </li>
          );
        }) }
      </ul>
      {(() => {
        switch (actionType) {
          case 'transfer':
            return <TransferModal type={actionType} toggleModal={deselectActionType} />;
          case 'identify':
            return <IdentifyWithLedModal type={actionType} toggleModal={deselectActionType} />;
          case 'run':
            return <RunCalibrationModal type={actionType} toggleModal={deselectActionType} />;
          default:
            return <></>;
        }
      })()}
    </>
  );
};

export default ActionsDropdown;
