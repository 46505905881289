import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  delay
} from 'redux-saga/effects';
import { api as auth0Api } from 'app/utils/auth0_handler';
import * as Actions from '~/store/reducers/invitations';
import { normalizeResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import { fetchOrganizationsListRequest } from '~/store/reducers/organizations';
import { fetchUserProfileSuccess } from '~/store/reducers/global';
import requestApi from './requestApi';

export function* userInvitationsFetchRequestSaga({ payload }) {
  try {
    let params = null;
    if (payload?.data?.organization_id) {
      const { data: { organization_id } } = payload;
      params = { organization_id };
    }
    const invitations = yield call(
      requestApi,
      'get',
      'user_invitations',
      params
    );
    const response = normalizeResp(invitations, schema.invitationsSchema);
    yield put(Actions.fetchUserInvitationsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchUserInvitationsFailure(e.toString()));
  }
}

function* watchFetchUserInvitationsRequest() {
  yield takeLatest(Actions.fetchUserInvitationsRequest.toString(), userInvitationsFetchRequestSaga);
}

export function* createInvitationRequestSaga({ payload }) {
  try {
    const { onSuccess, ...rest } = payload;
    const data = yield call(
      requestApi,
      'post',
      'user_invitations',
      {
        user_invitation: rest
      }
    );
    const response = {
      ...data,
      organization_name: rest.organization_name,
      created_at: Date.now()
    };
    yield put(Actions.sendUserInvitationSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.sendUserInvitationFailure(e.toString()));
  }
}

function* watchSendUserInvitationRequest() {
  yield takeEvery(Actions.sendUserInvitationRequest.toString(), createInvitationRequestSaga);
}

export function* deleteUserInvitationRequestSaga({ payload }) {
  try {
    yield call(
      requestApi,
      'delete',
      `user_invitations/${payload.id}`,
      {}
    );
    yield put(Actions.deleteUserInvitationSuccess(payload.id));
  } catch (e) {
    yield put(Actions.deleteUserInvitationFailure(e.toString()));
  }
}

function* watchDeleteInvitationRequest() {
  yield takeEvery(Actions.deleteUserInvitationRequest.toString(), deleteUserInvitationRequestSaga);
}

export function* resendUserInvitationRequestSaga({ payload }) {
  try {
    const { id, onSuccess } = payload;
    yield call(
      requestApi,
      'put',
      `user_invitations/${id}/remind`,
      {}
    );
    yield put(Actions.resendUserInvitationSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.resendUserInvitationFailure(e.toString()));
  }
}

function* watchResendUserInvitationRequest() {
  yield takeEvery(Actions.resendUserInvitationRequest.toString(), resendUserInvitationRequestSaga);
}

export function* declineUserInvitationRequestSaga({ payload }) {
  try {
    const { id, onSuccess } = payload;
    yield call(
      requestApi,
      'put',
      `user_invitations/${id}/reject`,
      {}
    );
    yield put(Actions.declineUserInvitationSuccess(payload.id));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.declineUserInvitationFailure(e.toString()));
  }
}

function* watchDeclineUserInvitationRequest() {
  yield takeEvery(Actions.declineUserInvitationRequest.toString(), declineUserInvitationRequestSaga);
}

export function* acceptUserInvitationRequestSaga({ payload }) {
  try {
    const { id, onSuccess } = payload;
    yield call(
      requestApi,
      'put',
      `user_invitations/${id}/accept`,
      {}
    );
    yield put(Actions.acceptUserInvitationSuccess(payload.id));
    yield delay(500);
    yield put(fetchOrganizationsListRequest());
    auth0Api.getChannel().request('update:current:user', (userProfile) => {
      const response = normalizeResp(userProfile, schema.userSchema);
      fetchUserProfileSuccess(response);
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.acceptUserInvitationFailure(e.toString()));
  }
}

function* watchAcceptUserInvitationRequest() {
  yield takeEvery(Actions.acceptUserInvitationRequest.toString(), acceptUserInvitationRequestSaga);
}

export default function* app() {
  yield fork(watchDeclineUserInvitationRequest);
  yield fork(watchAcceptUserInvitationRequest);
  yield fork(watchFetchUserInvitationsRequest);
  yield fork(watchSendUserInvitationRequest);
  yield fork(watchDeleteInvitationRequest);
  yield fork(watchResendUserInvitationRequest);
}
