/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppModel from 'app/backbone/lib/entities/app_model';

export default class LastUpdateDataPoint extends AppModel {
  get defaults() {
    return {
      name: 'Last Update',
      _type: 'last_update',
      timestamp: -Infinity,
      value: -Infinity,
      unit: ''
    };
  }
}
