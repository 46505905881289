import { createSelector } from 'reselect';
import { getNodes } from '~/store/reducers/nodes';
import { isAirthinx, getNodeValueLabelPair } from '~/store/selectors/nodes';

export const getAvailableNodes = createSelector(
  getNodes,
  (nodes) => Object.values(nodes)
    .filter((item) => isAirthinx(item.serial))
    .map(getNodeValueLabelPair)
);
