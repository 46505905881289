import React from 'react';
import styled from 'styled-components';
import {
  Flex, Image, Button, Label, Placeholder
} from '~/components/layout';
import icnCalendar from '~/assets/icons/icn-calendar.svg';

export const ClearContainer = styled(Flex).attrs({
  justifyContent: 'flex-end',
  alignItems: 'center'
})`
width: 30px;
cursor: pointer;
margin-right: 8px;
color: hsl(0,0%,80%);
`;

const IconCalendar = styled(Image).attrs({
  src: icnCalendar
})``;
const ButtonLabel = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})`
  width: 100%;
  font-weight: bold;
  padding: 8px 5px;
`;
export const Toggle = ({
  label, placeholder, onClick, onClear, hasError
}) => (
  <Button onClick={onClick} style={{ borderColor: hasError ? 'red' : '#cccccc' }}>
    <ButtonLabel>
      {
          label
            ? <Label>{label}</Label>
            : <Placeholder>{placeholder}</Placeholder>
        }
      <Flex>
        {
            label && (
              <ClearContainer onClick={onClear}>
                <i className="fa fa-fw fa-lg fa-times" />
              </ClearContainer>
            )
          }
        <IconCalendar />
      </Flex>
    </ButtonLabel>
  </Button>
);
export const CalendarContainer = styled(Flex)`
  position: absolute;
  top: 110%;
  z-index: 10;
`;
