import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavButtons from '~/components/quick_nav_buttons';
import { getFilteredListValueLabelPairs, getAlertValueLabelPair } from '~/screens/alerts/selectors';
import { getAlertById, getAlertsIsFetched } from '~/store/selectors/alerts';
import { fetchAlertsRequest } from '~/store/reducers/alerts';

const QuickNavButtonsAlertsContainer = (props) => {
  const { onChange, selectedId, slug } = props;

  const {
    nodes,
    selectedAlert,
    isFetched
  } = useSelector((state) => ({
    nodes: getFilteredListValueLabelPairs(state),
    isFetched: getAlertsIsFetched(state),
    selectedAlert: getAlertValueLabelPair(getAlertById(state, selectedId))
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (isFetched) {
      return;
    }
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    dispatch(fetchAlertsRequest({ data }));
  }, [dispatch, isFetched, slug]);

  return (
    <NavButtons options={nodes} selectedValue={selectedAlert} onChange={onChange} isLoading={!isFetched} />
  );
};

export default QuickNavButtonsAlertsContainer;
