/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';
import getMap from 'app/config/google_maps';
import { api as thiamisApi } from 'app/backbone/entities/nodes/thiamis';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';

class Router extends AppRouter {
  initialize({ controller }) {
    this.controller = controller;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    return this.appRoute(/^map$/, 'showMap');
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'map');
  }
}

const controller = {
  async showMap(options = {}) {
    App.getChannel().request('enable:org_filter');
    const maps = await getMap({ withMarkerClusterer: true, withMarkerWithLabel: true, withInfoBox: true });
    const promise = import('./show/show_controller');
    promise.then((Controller) => {
      const chosenOrg = orgNavsApi.getChannel().request('get:chosen:org');
      // eslint-disable-next-line new-cap
      const ctrl = new Controller.default({
        organizations: App.getChannel().request('get:organizations'),
        thiamises: thiamisApi.getChannel().request('fetch:thiamises', {
          data: {
            ...(chosenOrg?.id !== 'all' && {
              organization_id: chosenOrg?.id
            })
          }
        }),
        thiamisesWithLocation: thiamisApi.getChannel().request('fetch:thiamises', {
          data: { last: 1,
            path: '0:8',
            ...(chosenOrg?.id !== 'all' && {
              organization_id: chosenOrg?.id
            }) }
        }),
        filterGroups: options.filterGroups,
        maps
      });
      return ctrl.showPageView();
    });
    return promise;
  }
};

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:map': 'appMap'
  },

  appMap(options) {
    App.navigate('map', options);
  }
});

export const api = new API();

const MapApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default MapApp;
