/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import WidgetNodeSelectMixin from 'app/backbone/lib/concerns/views/widget_node_select';
import NodeWidgetFetchThiamisesMixin from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamises';
import WidgetDataPointdSelectMixin from 'app/backbone/lib/concerns/views/widget_data_points_select';
import { ChartDataPointsFilter, ChartEmptyDataPointsFilter } from 'app/backbone/lib/components/shared_views/widget_node_select';
import timespanSelectTemplate from './templates/timespan_select.hbs';
import confTemplate from './templates/conf.hbs';

export const ChartWidgetTimeSpanView = View.extend({
  template: timespanSelectTemplate,
  bindings: {
    '.config-timespan': {
      observe: 'time_period',
      selectOptions: {
        collection() {
          return [
            { value: 'hour_1', label: '1 hour' },
            { value: 'hours_3', label: '3 hours' },
            { value: 'hours_6', label: '6 hours' },
            { value: 'hours_12', label: '12 hours' },
            { value: 'day_1', label: '1 day' }
          ];
        },
        defaultOption: {
          label() { return I18n.t('widgets.choose_period'); },
          value: null
        }
      }
    }
  },

  onRender() {
    return this.stickit();
  }
});

const ChartWidgetConfigView = View.extend({
  behaviors: [NodeWidgetFetchThiamisesMixin, WidgetNodeSelectMixin, WidgetDataPointdSelectMixin],
  template: confTemplate,
  ui: {
    loading: '.loading-overlay'
  },
  regions: {
    dataPointsFilterRegion: '.datapointsselector-region',
    nodeSelectRegion: '.nodeselect-region',
    timespanRegion: '.timespan-region'
  },

  dataPointsFilterClass: ChartDataPointsFilter,
  emptyDataPointsFilterClass: ChartEmptyDataPointsFilter,

  onRender() {
    return this.showTimeSpanSelect();
  },

  showTimeSpanSelect() {
    return this.getRegion('timespanRegion').show(new ChartWidgetTimeSpanView(
      _(this).pick('model'))
    );
  }
});

export default ChartWidgetConfigView;
