import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';

import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';
import { fetchOrganizationsListRequest, getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { getGlobalOrganization } from '~/store/reducers/global';
import { getMemberedOrganizationsValueLabelPairs } from '~/store/selectors/organizations';

const SingleValueWithIndicator = (props) => {
  const { SingleValue, LoadingIndicator } = components;
  const { children, ...restProps } = props;
  const { selectProps: { menuIsOpen, isLoading, isDisabled } } = restProps;
  return (
    <SingleValue {...restProps}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { isLoading ? (<LoadingIndicator {...restProps} />) : children }
        { isDisabled ? null : <i className={`fa fa-fw pl-75 fa-angle-${menuIsOpen ? 'up' : 'down'}`} /> }
      </div>
    </SingleValue>
  );
};

const formatOptionLabel = ({ label, external_id }) => (
  <div>
    {label}
    {
        external_id && (
          <b style={{ opacity: 0.6, display: 'block', lineHeight: '16px' }}>
            <small>{external_id}</small>
          </b>
        )
      }
  </div>
);

const OrganizationsDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrganizationsListRequest());
  }, [dispatch]);

  const isFetched = useSelector((state) => getOrganizationsIsFetched(state));
  const globalOrganizationId = useSelector((state) => getGlobalOrganization(state));
  const organizationOptions = useSelector((state) => getMemberedOrganizationsValueLabelPairs(state));

  const handleOrganizationChange = useCallback((option = {}) => {
    if (option) {
      orgNavsApi.getChannel().request('get:choosable:orgs').chooseBySlug(option.value);
    }
  }, []);

  if (isFetched && isEmpty(organizationOptions)) {
    return null;
  }

  const moreThenOneOrganization = organizationOptions.length > 1;

  return (
    <Select
      styles={{
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: () => ({ display: 'none' }),
        singleValue: (provided) => ({ ...provided, color: '#666' }),
        control: (provided) => ({
          ...provided, border: 0, boxShadow: 'none', backgroundColor: '#fff'
        }),
        container: (provided) => ({ ...provided, width: '100%' }),
        menu: (provided) => ({ ...provided, width: '150%' }),
        valueContainer: (base) => ({
          ...base,
          padding: '8px'
        })
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral80: '#666'
        }
      })}
      formatOptionLabel={formatOptionLabel}
      components={{ SingleValue: SingleValueWithIndicator }}
      value={globalOrganizationId}
      onChange={handleOrganizationChange}
      isDisabled={!moreThenOneOrganization}
      isLoading={!isFetched}
      options={moreThenOneOrganization ? [...[{ value: 'all', label: t('base.labels.all') }], ...organizationOptions] : organizationOptions}
      isSearchable={moreThenOneOrganization}
    />
  );
};

export default OrganizationsDropdown;
