import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import theme from '~/theme';
import AnalyticsScreen from '~/screens/analytics';
import APIListScreen from '~/screens/api';
import DevicesScreen from '~/screens/devices';
import PartnershipsScreen from '~/screens/partnerships';
import AlertsScreen from '~/screens/alerts';
import AlertMonitorScreen from '~/screens/alert_monitor';
import FilesScreen from '~/screens/files';
import FirmwaresScreen from '~/screens/firmwares';
import UsersScreen from '~/screens/users';
import OrganizationsScreen from '~/screens/organizations';
import DashboardScreen from '~/screens/dashboard';
import CertificatesScreen from '~/screens/certificates';
import BackboneHider from '~/components/backbone_hider';
import store from '~/store';
import history from '~/store/history';
import Tooltip from '~/components/tooltip';
import withErrorBoundary from '~/hocs/withErrorBoundary';
import WithBackboneNavChoose from '~/hocs/with_backbone_nav_choose';
import EditCertificateScreen from './screens/edit_certificate';
import ClaimDeviceScreen from './screens/claim_devices';
import EditOrganizationScreen from './screens/edit_organization';
import Profile from '~/screens/profile';
import EditUserScreen from '~/screens/edit_user';
import CalibrationScreen from '~/screens/calibration';

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/:slug/api"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const APIListScreenComponent = withErrorBoundary(APIListScreen);
              return (
                <BackboneHider>
                  <APIListScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/react-analytics"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const AnalyticsScreenComponent = withErrorBoundary(AnalyticsScreen);
              return (
                <BackboneHider>
                  <AnalyticsScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/devices/claim"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const ClaimDevicesScreenComponent = withErrorBoundary(ClaimDeviceScreen);
              return (
                <BackboneHider>
                  <ClaimDevicesScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/devices"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const DevicesScreenComponent = withErrorBoundary(DevicesScreen);
              return (
                <BackboneHider>
                  <DevicesScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/alerts"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const AlertsScreenComponent = withErrorBoundary(AlertsScreen);
              return (
                <BackboneHider>
                  <AlertsScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/certificates"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const CertificateScreenComponent = withErrorBoundary(CertificatesScreen);
              return (
                <BackboneHider>
                  <CertificateScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/certificates/create"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const EditCertificateScreenComponent = WithBackboneNavChoose(withErrorBoundary(EditCertificateScreen));
              return (
                <BackboneHider>
                  <EditCertificateScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/certificates/:id"
            render={(navigation) => {
              const { params: { slug, id }, url } = navigation?.match;
              const EditCertificateScreenComponent = WithBackboneNavChoose(withErrorBoundary(EditCertificateScreen));
              return (
                <BackboneHider>
                  <EditCertificateScreenComponent slug={slug} id={id} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/alerts/:id/monitor"
            render={(navigation) => {
              const { params: { slug, id }, url } = navigation?.match;
              const AlertMonitorScreenComponent = withErrorBoundary(AlertMonitorScreen);
              return (
                <BackboneHider>
                  <AlertMonitorScreenComponent slug={slug} id={id} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/files"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const FilesScreenComponent = withErrorBoundary(FilesScreen);
              return (
                <BackboneHider>
                  <FilesScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/users"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const UsersScreenComponent = withErrorBoundary(UsersScreen);
              return (
                <BackboneHider>
                  <UsersScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/organizations"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const OrganizationsScreenComponent = withErrorBoundary(OrganizationsScreen);
              return (
                <BackboneHider>
                  <OrganizationsScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/organizations/new"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const EditOrganizationScreenComponent = withErrorBoundary(EditOrganizationScreen);
              return (
                <BackboneHider>
                  <EditOrganizationScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            path="/:slug/organizations/:id"
            render={(navigation) => {
              const { params: { id, slug }, url } = navigation?.match;
              const EditOrganizationScreenComponent = withErrorBoundary(EditOrganizationScreen);
              return (
                <BackboneHider>
                  <EditOrganizationScreenComponent slug={slug} organizationId={id} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/firmware"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const FirmwaresScreenComponent = withErrorBoundary(FirmwaresScreen);
              return (
                <BackboneHider>
                  <FirmwaresScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/calibration"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const CalibrationScreenComponent = withErrorBoundary(CalibrationScreen);
              return (
                <BackboneHider>
                  <CalibrationScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/partnerships"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const PartnershipsScreenComponent = withErrorBoundary(PartnershipsScreen);
              return (
                <BackboneHider>
                  <PartnershipsScreenComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/react-dashboard"
            render={(navigation) => {
              const { params: { slug }, url } = navigation?.match;
              const DashboardComponent = withErrorBoundary(DashboardScreen);
              return (
                <BackboneHider>
                  <DashboardComponent slug={slug} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            exact
            path="/:slug/profile"
            render={(navigation) => {
              const { url } = navigation?.match;
              const ProfileComponent = withErrorBoundary(Profile);
              return (
                <BackboneHider>
                  <ProfileComponent path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route
            path="/:slug/users/:id"
            render={(navigation) => {
              const { params: { slug, id }, url } = navigation?.match;
              const EditUserScreenComponent = withErrorBoundary(EditUserScreen);
              return (
                <BackboneHider>
                  <EditUserScreenComponent slug={slug} userId={id} path={url} />
                </BackboneHider>
              );
            }}
          />
          <Route render={() => (<div>Miss</div>)} />
        </Switch>
        <Tooltip />
      </Router>
    </ThemeProvider>
  </Provider>
);

export default App;
