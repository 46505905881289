/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import ReactDOM from 'react-dom';
import React from 'react';
import { View } from 'backbone.marionette';
import classNames from 'classnames';
import I18n from 'app/config/i18n';
import Radio from 'backbone.radio';
import { app as App } from 'app/backbone/app';
import { NodeDataPoint } from 'app/backbone/lib/concerns/views/node_data_point';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import { NodeWidgetContent, NoDataPoints } from 'app/backbone/lib/components/shared_views/widget_node_views';
import LocalTime from 'app/backbone/lib/react/components/lists/thiamises/LocalTime/LocalTime';
import SparkLine from 'app/backbone/lib/components/shared_views/sparkline_chart';
import LastUpdatedTs from 'app/backbone/lib/react/sensors/lastUpdatedTs/lastUpdatedTs';
import techDataPointsContainerTmpl from './templates/tech_data_points_container.hbs';
import dataPointTmpl from './templates/data_point.hbs';
import dataPointContainerTmpl from './templates/data_point_container.hbs';

const monitorChannel = Radio.channel('monitor');

export const RecentMeasurementsTechDataPointsContainer = View.extend({
  template: techDataPointsContainerTmpl,
  className: 'tech-datapoints row',

  ui: {
    lastUpdatedTs: '.last-updated-ts',
    localTime: '.local-time'
  },

  initialize({ lastUpdate, dataPointsColl }) {
    this.lastUpdate = lastUpdate;
    this.dataPointsColl = dataPointsColl;
  },

  onBeforeDetach() {
    ReactDOM.unmountComponentAtNode(this.ui.localTime[0]);
    return ReactDOM.unmountComponentAtNode(this.ui.lastUpdatedTs[0]);
  },

  onAttach() {
    ReactDOM.render(React.createElement(LocalTime, { dataPointsColl: this.dataPointsColl }), this.ui.localTime[0]);
    ReactDOM.render(React.createElement(LastUpdatedTs, {
      model: this.lastUpdate,
      title: I18n.t('thiamis.more_info.last_updated',
        { noValueText: I18n.t('base.labels.n_a') })
    }), this.ui.lastUpdatedTs[0]);
  }
});

export const RecentMeasurementsDataPoint = View.extend({
  behaviors: [NodeDataPoint],
  template: dataPointTmpl,
  tagName: 'tr',

  ui: {
    name: '.name',
    value: '.value',
    unit: '.unit'
  },

  regions: {
    sparklineRegion: '.sparkline'
  },

  bindings: {
    td: {
      attributes: [{
        observe: 'is_laoding',
        name: 'class',
        onGet(isLoading) {
          return classNames({ active: isLoading });
        }
      }
      ]
    },
    '@ui.value': {
      observe: 'value',
      updateView(value) {
        return _.isDefined(value);
      },
      onGet() {
        return this.model.getDisplayValue();
      }
    },
    '@ui.unit': {
      observe: 'unit',
      onGet(unit) {
        const value = this.model.get('value');
        return `${_.isDefined(unit && _.isDefined(value)) ? unit : ''}`;
      }
    },
    ':el': {
      attributes: [{
        observe: ['chosen_on_chart', 'chosen_on_table', 'name', 'color'],
        name: 'class',
        onGet(...args) {
          const [chosenOnGraph, chosenOnTable, name] = Array.from(args[0]);
          const isChosen = (chosenOnGraph && this.currentTabIsGraph()) || (chosenOnTable && this.currentTabIsTable());
          return classNames({ chosen: isChosen }, `dp-${_.slugify(name)}`);
        }
      }
      ]
    },
    '@ui.name': {
      attributes: [{
        observe: ['chosen_on_chart', 'chosen_on_table', 'color'],
        name: 'style',
        onGet(...args) {
          const [chosenOnGraph, color] = Array.from(args[0]);
          if (!chosenOnGraph) { return; }
          return `color: ${color};`;
        }
      }
      ]
    }
  },

  initialize({ widget, MAX_DATA_POINT_SELECTION, thiamis }) {
    this.widget = widget;
    this.MAX_DATA_POINT_SELECTION = MAX_DATA_POINT_SELECTION;
    this.thiamis = thiamis;
    this.listenTo(monitorChannel, 'show:tab', this.onShowTab);
    return this.listenTo(monitorChannel, 'remove:chart:param', this.onRemoveChartParam);
  },

  events() {
    return { 'click td': 'onToggleChosed' };
  },

  onToggleChosed() {
    if (this.currentTabIsGraph()) {
      if (this.selectionExceeded()) {
        App.getChannel().trigger('flash:message', 'warning', 'You can only view 3 data points at one time');
        return;
      }
      this.setLoading();
      return _.delay(() => this.model.set('chosen_on_chart', !this.model.get('chosen_on_chart'), { triggeredByUser: true }),
        100);
    } if (this.currentTabIsTable()) {
      this.setLoading();
      return _.delay(() => this.model.set('chosen_on_table', !this.model.get('chosen_on_table'), { triggeredByUser: true }),
        100);
    }
  },

  onRemoveChartParam(dataPoint) {
    return this.model.collection.get(dataPoint).set('chosen_on_chart', false);
  },

  setLoading() {
    this.model.set('is_laoding', true, { silent: true });
    return this.model.trigger('change:is_laoding', this.model, { stickitChange: true });
  },

  selectionExceeded() {
    return !this.model.get('chosen_on_chart') && ((this.model.collection.filter((dp) => dp.get('chosen_on_chart')).length + 1) > this.MAX_DATA_POINT_SELECTION);
  },

  onShowTab(tab) {
    this.currentTab = tab.id;
    return this.model.trigger('change:name', this.model, this.model.get('name'));
  },

  currentTabIsGraph() {
    return this.getCurrentTab() === 'chart';
  },

  currentTabIsTable() {
    return this.getCurrentTab() === 'table';
  },

  getCurrentTab() {
    return this.currentTab || 'chart';
  },

  onRender() {
    return this.stickit();
  },

  onDomRefresh() {
    const sparkline = new SparkLine({ model: this.model });
    return this.getRegion('sparklineRegion').show(sparkline);
  }
});

export const RecentMeasurementsDataPointContainer = AppCollectionView.extend({
  template: dataPointContainerTmpl,
  childView: RecentMeasurementsDataPoint,
  emptyView: NoDataPoints,
  childViewContainer: 'tbody',
  className: 'table-responsive thiamis-widget-content',

  childViewOptions() {
    return _(this.options).pick('widget', 'MAX_DATA_POINT_SELECTION', 'thiamis');
  },

  initialize({ widget, MAX_DATA_POINT_SELECTION }) {
    this.widget = widget;
    this.MAX_DATA_POINT_SELECTION = MAX_DATA_POINT_SELECTION;
    return this.listenTo(monitorChannel, 'chart:redraw', () => {
      this.$el.toggleClass('disabled', (this.collection.filter((dp) => dp.get('chosen_on_chart')).length + 1) > this.MAX_DATA_POINT_SELECTION);
      return this.collection.each((dataPoint) => dataPoint.set({ color: this.getParamColor(dataPoint), is_laoding: false }));
    });
  },

  getParamColor(dataPoint) {
    return monitorChannel.request('get:chart:series:color', dataPoint);
  }
});

const RecentMeasurementsWidgetContentView = NodeWidgetContent.extend({
  MAX_DATA_POINT_SELECTION: 3,

  getDataPointsContainer() {
    return this.dataPointsContainer != null ? this.dataPointsContainer : (this.dataPointsContainer = new RecentMeasurementsDataPointContainer({
      collection: this.model.getUserDataPointsColl(),
      widget: this.model,
      thiamis: this.thiamis,
      MAX_DATA_POINT_SELECTION: this.MAX_DATA_POINT_SELECTION
    }));
  },

  getTechDataPointsContainer() {
    return this.techDataPointsContainer != null ? this.techDataPointsContainer : (this.techDataPointsContainer = new RecentMeasurementsTechDataPointsContainer({
      lastUpdate: this.model.getDataPointsLastUpdate(),
      dataPointsColl: this.model.getDataPointsColl()
    }));
  }
});

export default RecentMeasurementsWidgetContentView;
