import React, { Component } from 'react';
import PropTypes from 'prop-types';

const WithPopoverHoc = (WrappedComponent) => {
  class PopoverComponent extends Component {
    constructor() {
      super();
      this.state = {
        popoverContent: ''
      };
    }

    componentDidMount() {
      const defaults = {
        html: true,
        placement: 'top auto',
        trigger: 'click',
        container: 'body',
        className: '',
        global_close: true
      };
      const popoverOptions = _.extend(
        this.props.popover,
        defaults,
        {
          content: this.getPopoverContent(),
          template: this.props.popover.template || `<div class="popover ${this.props.popover.className || ''}">
                      <div class="arrow"></div>
                      <h3 class="popover-title"></h3>
                      <div class="popover-content"></div>
                    </div>`
        }
      );

      $(this.popoverEl).clickover(popoverOptions);
    }

    componentDidUpdate() {
      if ($(this.popoverEl).data('bs.popover')) $(this.popoverEl).data('bs.popover').options.content = this.getPopoverContent();
    }

    componentWillUnmount() {
      $(this.popoverEl).clickover('hide');
    }

    static getDerivedStateFromProps(nextProps) {
      return { popoverContent: nextProps.popover.content };
    }

    getPopoverContent() {
      if (typeof this.state.popoverContent === 'function') return this.state.popoverContent(this.popoverEl);
      return this.state.popoverContent;
    }

    render() {
      return <WrappedComponent popoverRef={(popoverEl) => this.popoverEl = popoverEl} {...this.props} />;
    }
  }

  PopoverComponent.propTypes = {
    popover: PropTypes.any.isRequired
  };

  return PopoverComponent;
};

export default WithPopoverHoc;
