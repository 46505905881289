/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import classNames from 'classnames';
import DeviceStatus from '~/components/device_status';

const DeviceTitle = (props) => {
  const {
    statusText, statusType, displayName, children
  } = props;
  return (
    <h4 className={classNames('device-name', isArray(statusText) ? statusText.join(' ') : statusText)}>
      <DeviceStatus statusText={statusText} statusType={statusType} />
      <b className="display-name" title={displayName}>
        <b className="truncated">{displayName}</b>
        <i className="fa fa-sm" />
      </b>
      {children}
    </h4>
  );
};

DeviceTitle.defaultProps = {
  statusText: '',
  displayName: ''
};

DeviceTitle.propTypes = {
  statusText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  displayName: PropTypes.string,
  statusType: PropTypes.string.isRequired
};

export default DeviceTitle;
