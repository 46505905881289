/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppController from 'app/backbone/lib/controllers/app_controller';
import {
  BaseDashboardCtrlMethods, LayoutedContainerCtrlMethods, WidgetOpsHeaderCtrlMethods
} from 'app/backbone/lib/concerns/controllers/base_dashboard_ctrl_methods';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';
import { api as apiDataStream } from 'app/backbone/entities/data_stream';
import { Layout, HeaderView, HeaderStatsView } from './show_views';

export default class Controller extends BaseDashboardCtrlMethods(WidgetOpsHeaderCtrlMethods(LayoutedContainerCtrlMethods(AppController))) {
  showDashboard() {
    return this.showWithDashboardLoading(this._getDashboardLayout(), {
      loading: {
        entities: [this.dashboard]
      }
    });
  }

  _getDashboardLayout() {
    this.layout = new Layout({ model: this.dashboard });

    this.listenTo(this.layout, 'attach', () => {
      this._showHeader(this.dashboard);
      return this._showWidgets(this.dashboard);
    });
    return this.layout;
  }

  initialize({ dashboard, stats, currentUser }) {
    this.dashboard = dashboard;
    this.stats = stats;
    this.currentUser = currentUser;
    return this.listenTo(orgNavsApi.getChannel().request('get:choosable:orgs'), 'collection:chose:one', (org) => {
      this.stats.fetch({
        data: {
          organization_id: org.id
        }
      });
    });
  }

  _getHeader(report) {
    if (this.headerView) { return this.headerView; }
    this.headerView = new HeaderView();
    this.listenTo(this.headerView, 'attach', () => {
      this.headerView.getRegion('statsRegion').show(this._getHeaderStats());
      return this.headerView.getRegion('toolbarRegion').show(this.getHeaderToolbar(report.get('widgets')));
    });
    return this.headerView;
  }

  _getHeaderStats() {
    if (this.headerStatsView) { return this.headerStatsView; }
    this.headerStatsView = new HeaderStatsView({
      model: this.stats
    });
    return this.headerStatsView;
  }

  onBeforeDestroy() {
    apiDataStream.getChannel().request('clear:node:data:stream');
  }
}