/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppRouter from 'app/backbone/lib/routers/app_router';
import { app as App } from 'app/backbone/app';
import { MnObject } from 'backbone.marionette';

class Router extends AppRouter {
  initialize() {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'partnership');
  }
}

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:partnerships': 'listPartnerships'
  },

  listPartnerships() {
    Router.prototype.before();
    App.navigate('partnerships');
    App.getChannel().request('enable:org_filter', true);
  }
});

const controller = {};

export const api = new API();

const PartnershipsApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default PartnershipsApp;
