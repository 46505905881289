import React from 'react';
import { Plugin, Getter } from '@devexpress/dx-react-core';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap3';

const tableColumnsWithLastDetailColumn = ({ tableColumns }) => {
  const columns = [...tableColumns];
  const detailColumnIndex = tableColumns.findIndex(
    (col) => col.type === TableRowDetail.COLUMN_TYPE
  );
  if (detailColumnIndex > -1) {
    const [detailColumn] = columns.splice(detailColumnIndex, 1);
    return [...columns, { ...detailColumn, width: '4%' }];
  }
  return columns;
};

const DetailRowColumnOrder = () => (
  <Plugin dependencies={[{ name: 'TableRowDetail' }]}>
    <Getter name="tableColumns" computed={tableColumnsWithLastDetailColumn} />
  </Plugin>
);

export default DetailRowColumnOrder;
