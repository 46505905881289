import AppController from 'app/backbone/lib/controllers/app_controller';
import PageNotFoundView from './show_view';

export default class ShowController extends AppController {
  showView() {
    return this.show(this._getPageNotFoundView());
  }

  _getPageNotFoundView() {
    return new PageNotFoundView;
  }
}
