/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Behavior } from 'backbone.marionette';
import { api as thiamisApi } from 'app/backbone/entities/nodes/thiamis';

const NodeWidgetFetchThiamisesMixin = Behavior.extend({
  initialize(behavior, { options: { model } }) {
    this.model = model;
    this.view.thiamises = thiamisApi.getChannel().request('fetch:thiamises');
    this.view.listenTo(this.view.thiamises, 'sync', () => {
      this.ui.loading.hide();
    });
  },

  onRender() {
    this.ui.loading.show();
  }
});

export default NodeWidgetFetchThiamisesMixin;
