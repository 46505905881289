/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import AppCollection from 'app/backbone/lib/entities/app_collection';

class ThiamisDebugRow extends Backbone.Model {
  get idAttribute() { return 'timestamp'; }

  get mutators() {
    return {
      filter_tags: {
        transient: true,
        get() {
          return [_.slugify(this.get('tag'))];
        }
      },
      search_text: {
        transient: true,
        get() {
          return _([this.get('message'), _.slugify(this.get('tag'))]).join(' ').toLowerCase();
        }
      }
    };
  }

  parse(...args) {
    const [timestamp, value] = Array.from(args[0]);
    return _.extend(value, { timestamp });
  }
}

export class ThiamisDebugCollection extends AppCollection {
  get model() { return ThiamisDebugRow; }
}

export class ThiamisDebugFilteredListColl extends FilteredCollection {
  // this.include('LiveCollection');
}
