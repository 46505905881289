import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import DeviceTitle from '~/components/device_title';
import { getDeviceById } from '~/store/reducers/devices';
import {
  getDataPointIdByName, getDataPointIdsByTypeError, getDataPointIdsByTypeDefault
} from '~/store/selectors/data_points';
import { getLastMeasurementValue, getLastMeasurementsTs } from '~/store/selectors/measurements';
import {
  getDeviceStatusType, hasDeviceError, getDeviceStatusText, getDeviceErrors, getDeviceIsEnabled
} from '~/store/selectors/devices';

import { getNodeReportingInterval, isNodeReporting } from '~/store/selectors/nodes';

const DeviceTitleContainer = ({ id, nodeId, displayName }) => {
  const {
    status,
    errors,
    lastMeasurementsTs,
    reportingInterval,
    isEnabled,
    tag
  } = useSelector(
    (state) => {
      const sensor = getDeviceById(state, id);
      const dataPoints = getDataPointIdByName(state, nodeId, id);
      const errorMeasurementIds = getDataPointIdsByTypeError(state, nodeId, id);
      const defaultMeasurementIds = getDataPointIdsByTypeDefault(state, nodeId, id);
      const statusMeasurementId = dataPoints('status');
      return {
        tag: sensor.tag,
        lastMeasurementsTs: getLastMeasurementsTs(state, defaultMeasurementIds),
        reportingInterval: getNodeReportingInterval(state, nodeId),
        isEnabled: getDeviceIsEnabled(state, id),
        status: getLastMeasurementValue(state, statusMeasurementId),
        errors: getDeviceErrors(state, errorMeasurementIds)
      };
    }
  );
  const isReporting = isNodeReporting(lastMeasurementsTs, reportingInterval);
  const hasError = hasDeviceError(status, errors);
  const statusType = getDeviceStatusType(isEnabled, status, hasError, isReporting);
  const statusText = getDeviceStatusText(statusType, status, hasError, errors);
  return (
    <DeviceTitle displayName={displayName} statusType={statusType} statusText={statusText}>
      { !isEmpty(tag)
        ? <small>{tag}</small> : undefined }
    </DeviceTitle>
  );
};

export default DeviceTitleContainer;
