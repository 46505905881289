/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import WidgetBase from 'app/backbone/entities/widgets/widget_base';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import SelectsDataPoints from 'app/backbone/lib/concerns/entities/selects_data_points';
import { NodeStreamSubscribable } from 'app/backbone/lib/concerns/entities/measurement_updatable';

export default class NodeWidget extends SelectsDataPoints(NodeStreamSubscribable(WidgetBase)) {
  get transientAttrs() {
    return ['last_measurements'];
  }

  get validation() {
    return {
      node_id: {
        required: true
      }
    };
  }

  defaults() {
    return {
      size: 0,
      _type: 'NodeWidget',
      selected_data_point_ids: ['all'],
      filtered_data_point_ids: null,
      last_measurements: 8,
      data_points_filter_type: 'included'
    };
  }

  parse(resp) {
    // eslint-disable-next-line no-param-reassign
    resp.data_points_filter_type = _.isArray(resp.filtered_data_point_ids) && resp.filtered_data_point_ids.length
      ? 'excluded'
      : 'included';
    // eslint-disable-next-line no-param-reassign
    resp.selected_data_point_ids = _.isEmpty(resp.selected_data_point_ids)
      ? _.result(this, 'defaults').selected_data_point_ids
      : resp.selected_data_point_ids;
    return resp;
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      const { data_points_filter_type } = json;
      if (data_points_filter_type === 'excluded' && json.filtered_data_point_ids != null && json.filtered_data_point_ids.length) {
        json.selected_data_point_ids = null;
        return json;
      }
      if (data_points_filter_type === 'included') {
        if (_.contains(json.selected_data_point_ids, 'all')) {
          json.selected_data_point_ids = null;
          json.filtered_data_point_ids = [];
          return json;
        }
        if (json.selected_data_point_ids != null && json.selected_data_point_ids.length) {
          json.filtered_data_point_ids = null;
          return json;
        }
      }
    }
    return json;
  }

  getUserDataPointsColl() {
    if (this.userDataPointsColl) { return this.userDataPointsColl; }
    this.userDataPointsColl = new FilteredCollection(this.getFilteredDataPointsColl());
    this.userDataPointsColl.filterBy('non-tech', (dp) => dp.isLocation() || dp.isDefault());
    return this.userDataPointsColl;
  }

  getDataPointsLastUpdate() {
    return this.getDataPointsColl().getLastUpdate();
  }

  getThiamisBatteryVoltage() {
    return this.getDataPointsColl().getThiamisBatteryVoltage();
  }

  getThiamisSignal() {
    return this.getDataPointsColl().getThiamisSignal();
  }
}
