/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import AppModel from 'app/backbone/lib/entities/app_model';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';

export default class DashboardStats extends ClusternetBridge(AppModel) {
  get defaults() {
    return {
      configured_devices: 0,
      online_devices: 0,
      alert_enabled: 0,
      total_airthinx: 0
    };
  }

  url() { return this.clusternet.batchFetchStatsUrl(); }

  parse(resp) {
    return _.reduce(resp, (result, val, key) => {
      result[_.underscored(key).replace('.', '_')] = val; // eslint-disable-line no-param-reassign
      return result;
    }, {});
  }

  sync(method, model, options) {
    if (method === 'read') {
      options.type = 'POST'; // eslint-disable-line no-param-reassign
    }

    return super.sync(method, model, options);
  }
}

export const API = MnObject.extend({
  channelName: 'entities:stats',
  radioRequests: {
    'fetch:stats': 'fetchStats'
  },

  fetchStats(attrs, options) {
    const stats = new DashboardStats(attrs, options);
    stats.fetch(options);
    return stats;
  }
});

export const api = new API();
