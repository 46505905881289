/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = (props) => (
  <input className="checkbox" type="checkbox" checked={props.isChosen} onChange={props.handleChange} />
);

Checkbox.propTypes = {
  isChosen: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  isChosen: false
};

export default Checkbox;
