/* eslint-disable no-multi-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MultiChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import AppModel from 'app/backbone/lib/entities/app_model';
import { Sensor } from 'app/backbone/entities/nodes/sensor';
import I18n from 'app/config/i18n';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import { DiffDataPointConditionMixin } from 'app/backbone/lib/concerns/entities/data_point_condition';
import HumanizeDuration from 'app/backbone/lib/concerns/views/humanize_duration';
import { __guard__ } from 'app/utils/custom-fns';

export class DataPointCondition extends HumanizeDuration(Chooser(AppModel)) {
  get defaults() { return { references: [] }; }

  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'references',
        collectionType: DataPointConditions
      }
    ];
  }

  validation() {
    return {
      data_point_id: {
        required: true
      },
      func: {
        required: true
      }
    };
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      try {
        const jsonToSave = {};
        if (json._destroy) { return null; }
        const { func } = json;
        let { data_point_id } = json;
        if (this.getReferenceById(data_point_id)) {
          data_point_id = __guard__(__guard__(this.get('references'), (x1) => x1.get(data_point_id)), (x) => x.toJSON(options));
        }
        jsonToSave[func] = this.getJsonValueAttrs(data_point_id, json, options);
        return jsonToSave;
      } catch (e) {
        console.error(`DataPointCondition serialization error, ${e}`); // eslint-disable-line no-console
        return {};
      }
    }
    return json;
  }

  presentName() {
    const func = I18n.t(`conditions.expressions.${this.get('func')}`);
    const dp = this.get('references').at(0) || this.getLinkedDataPoint(this.get('data_point_id'));
    let name = dp != null ? dp.presentName() : undefined;
    if (this.hasReferences()) {
      name = `(${name})`;
    }
    let value = this.get('value');
    if (_.contains(['true', 'false', Sensor.STATUSES.REPORTING], value)) {
      value = I18n.t(`data_points.status_${value}`);
    }
    if (func === 'ABS') {
      return `${name} ${func}`;
    }
    if (_.contains(['AVG', 'MIN', 'MAX'], func) && _.isNumber(value)) {
      value = this.humanizeDuration(value);
    }
    return `${name} ${func} ${value}`;
  }

  getNewFormModel() {
    const formModel = new this.constructor(this.toJSON());
    formModel.isFormModel = true;
    return formModel;
  }

  getIdAttr(attrs) {
    const { func } = attrs;
    switch (func) {
      case 'diff': return `${func}_${attrs.data_point_id}_${attrs.data_point_id2}`;
      default: return `${func}_${attrs.data_point_id}`;
    }
  }

  parse(resp) {
    let data_point_id; let
      node_id;
    if (resp.func || resp.cid) { return resp; }
    const func = _.keys(resp)[0];
    const values = _.values(resp)[0];
    const result = { func };

    if (func === 'diff') {
      let node_id2;
      data_point_id = values[0];
      let data_point_id2 = values[1];

      if (_.isObject(data_point_id) || _.isObject(data_point_id2)) {
        result.references = new DataPointConditions();
      }

      if (_.isObject(data_point_id)) {
        result.references.add(this.parse(data_point_id));
        node_id = data_point_id = this.getIdAttr(result.references.first().attributes); // eslint-disable-line no-multi-assign
        result.references.last().set('_used', true);
      }

      if (_.isObject(data_point_id2)) {
        result.references.add(this.parse(data_point_id2));
        node_id2 = data_point_id2 = this.getIdAttr(result.references.last().attributes); // eslint-disable-line no-multi-assign
        result.references.last().set('_used', true);
      }

      _.extend(result, { data_point_id, data_point_id2, node_id, node_id2 });
    } else if (func === 'scale') {
      let max; let min; let range1; let
        range2;
      [data_point_id, range1, range2, min, max] = Array.from(values);
      _.extend(result, { data_point_id, range1, range2, min, max });
    } else {
      let interval; let
        value;
      [data_point_id, value, interval] = Array.from(values);
      if (_.isBoolean(value)) {
        value = value ? 'true' : 'false';
      }
      if (_.isObject(data_point_id)) {
        result.references = new DataPointConditions(this.parse(data_point_id));
        node_id = (data_point_id = this.getIdAttr(result.references.first().attributes)); // eslint-disable-line no-multi-assign
        result.references.last().set('_used', true);
      }
      _.extend(result, { data_point_id, node_id, value, interval });
    }
    return _.extend(result, { _id: this.getIdAttr(result) });
  }

  getJsonValueAttrs(data_point_id, { value, interval }) {
    if (_.isString(value)) {
      if (_.contains(['true', 'false'], value)) {
        value = value === 'true';
      } else if (_.isNumeric(value)) {
        value = +value;
      }
    }
    return _.reject([data_point_id, value, interval], (val) => !_.isDefined(val));
  }

  getLinkedDataPoint(dataPointId) {
    return __guard__(__guard__(this.getThiamis(dataPointId), (x1) => x1.getDataPoints()), (x) => x.get(dataPointId));
  }

  getDataPoint() {
    return (this.collection != null ? this.collection.getDataPoint() : undefined);
  }

  getThiamis(dataPointID) {
    return __guard__(__guard__(this.getAlert(), (x1) => x1.getThiamises()), (x) => x.getByDataPointId(dataPointID || this.get('data_point_id')));
  }

  getAlert() {
    return (this.collection != null ? this.collection.getAlert() : undefined);
  }

  getReferenceById(id) {
    return __guard__(this.get('references'), (x) => x.get(id));
  }

  hasReferences() {
    return !!__guard__(this.get('references'), (x) => x.size());
  }

  isValidConditionType(type) {
    return _.isDefined(DataPointConditions.modelTypes[type]);
  }

  hasReference(id) {
    return this.isValidConditionType(id != null ? id.split('_')[0] : undefined) || !!this.getReferenceById(id);
  }

  setReferences(references) {
    let reference;
    this.get('references').reset();
    if (this.hasReference(this.get('node_id'))) {
      let data_point_id;
      const node_id = data_point_id = this.get('node_id');
      reference = references.get(node_id);
      this.get('references').add(reference);
      this.set({ node_id, data_point_id }, { silent: true });
    }
    if (this.hasReference(this.get('node_id2'))) {
      let data_point_id2;
      const node_id2 = (data_point_id2 = this.get('node_id2'));
      reference = references.get(node_id2);
      this.get('references').add(reference);
      return this.set({ node_id2, data_point_id2 }, { silent: true });
    }
  }

  getModel() {
    return DataPointConditions.prototype.model(_.defaults(this.attributes, { _id: this.getIdAttr(this.attributes) }));
  }

  updateAttrs(newRef) {
    newRef = newRef.getModel();
    const attrs = newRef.parse(newRef.toJSON({ savedByForm: true }));
    return this.set(_.extend(attrs, { _id: newRef.getIdAttr(attrs) }));
  }
}

export class LinkDataPointCondition extends DataPointCondition {
  getJsonValueAttrs(dataPointId) {
    return [dataPointId];
  }
}

export class SimpleDataPointCondition extends DataPointCondition {
  validation() {
    return _(super.validation(...arguments)).extend({ // eslint-disable-line prefer-rest-params
      value(val, attr, { func }) {
        if ((val === 'true') || (val === 'false') || (val === Sensor.STATUSES.REPORTING) || func === 'abs') { return null; }
        return Backbone.Validation.validators.pattern(val, attr, 'number', this);
      }
    }
    );
  }
}

export class DiffDataPointCondition extends DiffDataPointConditionMixin(DataPointCondition) {
  validation() {
    return _(super.validation(...arguments)).extend({ // eslint-disable-line prefer-rest-params
      data_point_id2: {
        required: true
      }
    }
    );
  }

  getLinkedDataPoint(dataPointId) {
    return __guard__(__guard__(this.getThiamis(dataPointId), (x1) => x1.getDataPoints()), (x) => x.get(dataPointId));
  }

  presentName() {
    const dp1 = this.get('references').at(0) || this.getLinkedDataPoint(this.get('data_point_id'));
    const dp2 = this.get('references').at(1) || this.getLinkedDataPoint(this.get('data_point_id2'));
    return `${dp1.presentName()} - ${dp2.presentName()}`;
  }
}

export class SumDataPointCondition extends DiffDataPointConditionMixin(DataPointCondition) {}

export class ScaleDataPointCondition extends DataPointCondition {
  validation() {
    _(super.validation(...arguments)).extend({ // eslint-disable-line prefer-rest-params
      range1: {
        required: true
      }
    }
    );
    return {
      range2: {
        required: true
      },
      min: {
        pattern: 'number',
        required: true
      },
      max: {
        pattern: 'number',
        required: true
      }
    };
  }

  getJsonValueAttrs(dataPointId, { range1, range2, min, max }) {
    return [dataPointId, +range1, +range2, +min, +max];
  }
}

export default class DataPointConditions extends MultiChooser(AppCollection.extend({
  model(attrs, options) {
    const ModelFactory = DataPointConditions.modelsFactory(attrs.func);
    return new ModelFactory(attrs, _.extend(options, { parse: false }));
  }
})) {
  static modelTypes = {
    diff: DiffDataPointCondition,
    min: SimpleDataPointCondition,
    max: SimpleDataPointCondition,
    avg: SimpleDataPointCondition,
    abs: SimpleDataPointCondition,
    sum: SimpleDataPointCondition,
    scale: ScaleDataPointCondition,
    lt: SimpleDataPointCondition,
    lte: SimpleDataPointCondition,
    gt: SimpleDataPointCondition,
    gte: SimpleDataPointCondition,
    eq: SimpleDataPointCondition,
    link: LinkDataPointCondition,
    default: DataPointCondition
  }

  static modelsFactory = (type, returnAll) => {
    if (returnAll == null) { returnAll = false; }
    if (returnAll) { return _.values(DataPointConditions.modelTypes); }
    return DataPointConditions.modelTypes[type] || DataPointConditions.modelTypes.default;
  }

  modelId(attrs) {
    return DataPointCondition.prototype.getIdAttr(attrs);
  }

  toJSON(options = {}) {
    let json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      json = _.compact(json);
      if (json.length === 1) { return json.pop(); }
    }
    return json;
  }

  parse(resp) {
    if (resp.and) { resp = resp.and; }
    if (!_.isArray(resp)) { resp = [resp]; }
    return _.map(resp, (condition) => DataPointCondition.prototype.parse(condition));
  }

  initialize() {
    return this.on('change:chosen', (...args) => this.getDataPoint().trigger(...Array.from(args || [])));
  }

  getDataPoint() {
    return (this.parents != null ? this.parents[0] : undefined);
  }

  getAlert() {
    if (this.parents[0].collection instanceof DataPointConditions) {
      return __guard__(this.parents[0] != null ? this.parents[0].collection : undefined, (x) => x.getAlert());
    } return __guard__(this.parents[0] != null ? this.parents[0].collection : undefined, (x1) => x1.parents[0]);
  }

  findAndUpdate(oldRef, newRef) {
    return this.each((reference) => {
      const updatedRef = reference.get('references').get(oldRef.id);
      if (updatedRef) {
        const oldReference = reference.getNewFormModel();
        updatedRef.updateAttrs(newRef);
        if (oldReference.get('data_point_id') === oldRef.id) {
          reference.set({ data_point_id: updatedRef.id });
        }
        if (oldReference.get('data_point_id2') === oldRef.id) {
          reference.set({ data_point_id2: updatedRef.id });
        }
        reference.set({ _id: reference.getIdAttr(reference.attributes) });
        return this.findAndUpdate(oldReference, reference);
      } if (reference.get('references').size()) {
        return this.findAndUpdate.call(reference.get('references'), oldRef, newRef);
      }
    });
  }
}

export class RefDataPointCondition extends DataPointCondition {}

export class RefDataPointConditions extends MultiChooser(DataPointConditions) {
  getLetter(condition) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
    return (alphabet[this.indexOf(condition)] || alphabet[0]).toUpperCase();
  }

  comparator(condition) {
    return __guard__(condition.get('_id'), (x) => x.split('_').length);
  }

  getAlert() {
    return this.alert;
  }
}
