/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { OrgFilteredBase, FilterGroup, FilterGroupsColl } from 'app/backbone/filters/base';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';
import FilteredListColl from 'app/backbone/entities/abstract/filtered_list_coll';

export class OrgFilteredThiamises extends OrgFilteredBase {}

export class ThiamisDebugTagsFilterGroup extends FilterGroup {
  defaults() {
    return _(super.defaults(...arguments)).extend({ id: 'thiamis-debug-tag' }); // eslint-disable-line prefer-rest-params
  }

  initialize(attrs, { filteredColl }) {
    this.filteredColl = filteredColl;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.populate();
    return this.listenTo(this.filteredColl, 'reset add', this.populate);
  }

  populate() {
    const slugFreqs = _.freqs(this.filteredColl
      .chain()
      .map((debugInfo) => debugInfo.get('tag')).compact()
      .sort()
      .value());
    const attrs = _(slugFreqs).map((freq, tagName) =>
      ({
        id: _.slugify(tagName),
        label: tagName,
        count: freq
      })
    );
    this.set('count', attrs.length);
    return this.get('filters').set(attrs, { parse: true, remove: false, merge: true });
  }
}

export class ThiamisTypeFilterGroup extends FilterGroup {
  defaults() {
    return _(super.defaults(...arguments)).extend({ id: 'devices' }); // eslint-disable-line prefer-rest-params
  }

  initialize(attrs, { filteredColl }) {
    this.filteredColl = filteredColl;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.populate();
    return this.listenTo(this.filteredColl, 'reset', this.populate);
  }

  populate() {
    const slugFreqs = _.freqs(this.filteredColl
      .chain()
      .map((thiamis) => thiamis.get('type')).compact()
      .sort()
      .value());
    const attrs = _(slugFreqs).map((freq, thiamisType) =>
      ({
        id: _.slugify(thiamisType),
        label: thiamisType,
        count: freq
      })
    );
    this.set('count', attrs.length);
    return this.get('filters').set(attrs, { parse: true, merge: true });
  }
}

export class ThiamisSensorFilterGroup extends FilterGroup {
  defaults() {
    return _(super.defaults(...arguments)).extend({ id: 'sensors' }); // eslint-disable-line prefer-rest-params
  }

  initialize(attrs, { filteredColl }) {
    this.filteredColl = filteredColl;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.populate();
    return this.listenTo(this.filteredColl, 'reset', this.populate);
  }

  populate() {
    const slugFreqs = _.freqs(this.filteredColl
      .chain()
      .map((n) => n.getSensors().map((sensor) => sensor.get('name'))).flatten()
      .compact()
      .sort()
      .value());
    const attrs = _(slugFreqs).map((freq, sensorName) =>
      ({
        id: _.slugify(sensorName),
        label: sensorName,
        count: freq
      })
    );
    this.set('count', attrs.length);
    return this.get('filters').set(attrs, { parse: true, merge: true });
  }
}

export class ThiamisPartnerFilterGroup extends FilterGroup {
  defaults() {
    return _(super.defaults(...arguments)).extend({ id: 'partners' }); // eslint-disable-line prefer-rest-params
  }

  initialize(attrs, { organizations, filteredColl }) {
    this.organizations = organizations;
    this.filteredColl = filteredColl;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.populate();
    return this.listenTo(this.filteredColl, 'reset', this.populate);
  }

  populate() {
    const slugFreqs = _.freqs(this.filteredColl
      .chain()
      .map((n) => _.union(n.get('shared_to_org_slugs'), [n.get('leased_to_org_slug')]))
      .flatten()
      .compact()
      .sort()
      .value());
    const attrs = _(slugFreqs).map((freq, slug) => {
      const org = this.organizations.find((o) => o.get('slug') === slug);
      return {
        id: slug,
        label: org.get('name'),
        count: freq
      };
    });
    this.set('count', attrs.length);
    return this.get('filters').set(attrs, { parse: true, merge: true });
  }
}

const API = MnObject.extend({
  channelName: 'filters:thiamis',

  radioRequests: {
    'filter:groups:thiamis:debug': 'getThiamisDebugTagsFilterGroupsColl',
    'filter:groups:thiamises': 'getThiamisFilterGroupsColl',
    'get:filtered:thiamises': 'getFilteredThiamises',
    'get:app:filtered:thiamises': 'getAppFilteredThiamises'
  },

  getThiamisDebugTagsFilterGroupsColl({ filteredColl }) {
    const groups = new FilterGroupsColl(null, { filteredColl });
    groups.add(new ThiamisDebugTagsFilterGroup(null, { filteredColl }));
    return groups;
  },

  getFilteredThiamises(thiamisesColl, filterInputModel) {
    return new FilteredListColl(thiamisesColl, { filterInputModel });
  },

  getAppFilteredThiamises(thiamisesColl) {
    return new OrgFilteredThiamises(thiamisesColl,
      { orgFilters: orgNavsApi.getChannel().request('get:choosable:orgs') });
  },

  getThiamisFilterGroupsColl({ organizations, filteredColl }) {
    const groups = FilterGroupsColl.build(
      { states: ['shared', 'leased', 'activated', 'online', 'offline', 'hibernated', 'deactivated'] },
      { filteredColl });

    const partnerGroup = new ThiamisPartnerFilterGroup(
      null, { organizations, filteredColl });
    groups.add(partnerGroup);

    const sensorGroup = new ThiamisSensorFilterGroup(
      null, { filteredColl });
    groups.add(sensorGroup);

    const thiamisTypeGroup = new ThiamisTypeFilterGroup(
      null, { filteredColl });
    groups.add(thiamisTypeGroup);

    groups.computeCounts();
    return groups;
  }
});

export const api = new API();
