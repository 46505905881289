/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';
import { api as dashboardApi } from 'app/backbone/entities/dashboard';
import { api as statsApi } from 'app/backbone/entities/dashboard_stats';
import { api as orgNavsApi } from 'app/backbone/entities/abstract/org_navs';
import DashboardController from './show/show_controller';

class Router extends AppRouter {
  initialize() {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    return this.appRoute(/^dashboard$/, 'showDashboard');
  }
}

const controller = {
  showDashboard() {
    App.getChannel().request('enable:org_filter');
    const chosenOrg = orgNavsApi.getChannel().request('get:chosen:org');
    const stats = statsApi.getChannel().request('fetch:stats', {}, {
      data: {
        ...(chosenOrg?.id !== 'all' && {
          organization_id: chosenOrg?.id
        })
      }
    });
    const dashboard = new DashboardController({
      currentUser: App.getChannel().request('get:current:user'),
      stats,
      dashboard: dashboardApi.getChannel().request('fetch:dashboard')
    });
    return dashboard.showDashboard();
  }
};

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:dashboard': 'showDashboard'
  },

  showDashboard() {
    App.navigate('dashboard');
    App.getChannel().trigger('nav:choose', 'dashboard');
  }
});

export const api = new API();

const DashboardApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default DashboardApp;
