import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '~/components/modal';
import { createPartnershipRequest, fetchPartnershipsRequest } from '~/store/reducers/partnerships';
import ModalForm from './form';

const InvitePartnerModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSuccess = useCallback(() => {
    toast.success(t('notifications.success.forms.partnership_invitations'), {
      toastId: 'success_partnership_invitation'
    });
    dispatch(fetchPartnershipsRequest());
    toggleModal();
  }, [t, toggleModal, dispatch]);

  const handleCreateNewPartner = useCallback((values) => {
    dispatch(createPartnershipRequest({
      organization_id: values.organization_id.value,
      external_id: values.external_id.external_id || values.external_id.value,
      onSuccess: handleSuccess
    }));
  }, [dispatch, handleSuccess]);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={handleClose}
      title={t('partnership_invitations.create_partnership')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleCreateNewPartner}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit }) => (
            <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default InvitePartnerModal;
