/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import { api as apiThiamises } from 'app/backbone/apps/thiamises/thiamises_app';
import { api as userApi } from 'app/backbone/entities/user';
import FormControls from 'app/backbone/lib/concerns/views/form_controls';
import history from '~/store/history';
import designAccessContextFormTemplate from './templates/design_access_context.hbs';

export default View.extend({
  behaviors: [FormControls],
  template: designAccessContextFormTemplate,

  triggers: {
    'click @ui.resetBtn': 'reset:access:context'
  },

  ui: {
    externalIdInput: 'input[name="external_id"]',
    radioBtns: 'input[type="radio"]',
    emailInput: 'input.email-input',
    resetBtn: 'button.reset-access-context'
  },

  bindings: {
    '@ui.emailInput': 'email',
    '@ui.externalIdInput': 'external_id',
    '@ui.radioBtns': 'option'
  },

  formModelEvents: {
    'change:option': 'onChangeRadio'
  },

  serializeData() {
    return {
      isContextDesigned: App.getChannel().request('get:current:user').hasAccessContextDesigned()
    };
  },

  onChangeRadio(model, option) {
    this.$el.find('input[type="text"]').attr('disabled', false);
    if (option === 'email') {
      this.ui.externalIdInput.attr('disabled', true);
      return this.formModel.set({ external_id: null });
    }
    this.ui.emailInput.attr('disabled', true);
    return this.formModel.set({ email: null });
  },

  onSaveSuccess() {
    $('body').addClass('context-designed');
    this.model.store();
    userApi.getChannel().request('handle:updated:context');
    const currentRoute = App.getCurrentRoute();
    if (currentRoute.indexOf('/devices')) {
      history.push('/empty');
    }
    apiThiamises.getChannel().request('app:devices');
  },

  onRender() {
    return this.formModel.set('option', _.isEmpty(this.formModel.get('external_id')) ? 'email' : 'org');
  }
});
