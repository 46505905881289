/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import humanizeDuration from 'humanize-duration';
import AppModel from 'app/backbone/lib/entities/app_model';
import I18n from 'app/config/i18n';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import DeviceStatus from 'app/backbone/lib/concerns/entities/device_status';
import { SingleChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import { MIN_REPORTING_INTERVAL } from 'app/backbone/entities/nodes/config';
import { __guard__, __guardMethod__ } from 'app/utils/custom-fns';

export class Device extends ClusternetBridge(AppModel) {
  transientAttrs = ['profiles', 'thiamis_serial', 'com_port', 'com_type', 'address', 'configuration', 'extra_configuration', 'auto_location', 'channel_options', 'profile_options']

  parse(resp) {
    const configurationAttrs = _.omit(..._.flatten([resp.configuration || {}, _.result(this, 'omitConfAttrs') || []]));
    if (configurationAttrs.filters) {
      configurationAttrs.filters = [];
    }
    this.prepareBooleanAttrs(resp);
    this.prepareBooleanAttrs(configurationAttrs);
    this.defaults = _.defaults(_.result(this, 'defaults') || {}, configurationAttrs);
    return resp;
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      _(_.result(this, 'booleanAttrs')).each((attr) => {
        if (!_.isUndefined(json[attr])) { return json[attr] = (json[attr] ? 1 : 0); }
      });
      for (const k in json) {
        if (_.contains(k, ',')) { delete json[k]; }
      }
    }
    return json;
  }

  prepareBooleanAttrs(resp) {
    _(_.result(this, 'booleanAttrs')).each((attr) => {
      if (!_.isUndefined(resp[attr])) { return resp[attr] = !!resp[attr]; }
    });
  }

  getConfiguration() {
    return this.get('configuration');
  }

  hasConfiguration(attrs) {
    const configuration = this.getConfiguration();
    if (_.isString(attrs)) {
      return !_.isUndefined(configuration[attrs]);
    } if (_.isArray(attrs)) {
      return _.some(attrs, (attr) => !_.isUndefined(configuration[attr]));
    }
    return !_.isEmpty(configuration);
  }

  sync(method, model, options) {
    let devices = _.clone(options.devices || []);
    if (method === 'create') {
      devices.push(model.toJSON(options));
    }
    if (method === 'delete') {
      devices = _(devices).reject((device) => device._id === model.id);
    }
    options = _.extend(options, {
      attrs: { devices },
      url: `${this.clusternet.nodesUrl()}/${options.thiamisId}`
    }
    );

    return super.sync('update', model, options);
  }

  getNewFormModel() {
    const formModel = super.getNewFormModel(...arguments); // eslint-disable-line prefer-rest-params
    formModel.set(_.defaults(formModel.attributes, this.getConfiguration()));
    return formModel;
  }

  getDataPoints() {
    if (this.dataPointsColl) { return this.dataPointsColl; }
    this.dataPointsColl = new FilteredCollection(__guard__(this.getThiamis(), (x) => x.getDataPoints()));
    this.dataPointsColl.filterBy((dp) => {
      if (dp.isMetric()) {
        const linked = _.map(dp.get('linked'), (id) => this.getThiamis().getDataPoints().findWhere({ _id: id }));
        return _.some(_.compact(linked), (linkedDp) => this.id === linkedDp.get('device_id'));
      }
      return this.id === dp.get('device_id');
    });
    return this.dataPointsColl;
  }

  getDefaultOnlyDataPoints() {
    if (this.defaultsDataPointsColl) { return this.defaultsDataPointsColl; }
    this.defaultsDataPointsColl = new FilteredCollection(this.getDataPoints());
    this.defaultsDataPointsColl.setSort('name');
    this.defaultsDataPointsColl.filterBy((dp) => dp.isDefault());
    return this.defaultsDataPointsColl;
  }
}

export class ThiamisDevice extends Chooser(Device) {
  get mutators() {
    return {
      display_name: {
        transient: true,
        get() {
          if (this.get('model')) {
            return this.get('model');
          } return this.get('name');
        }
      }
    };
  }

  booleanAttrs() {
    return ['bluetooth_share', 'enable_temperature', 'enable_pressure', 'enable_battery', 'led_enabled', 'led_aq', 'temp_f', 'enable_press', 'enable_rh', 'enable_gps', 'dfs', 'automation'];
  }

  omitConfAttrs() {
    return ['debug_level', 'debug_tags', 'firmware', 'precipitation_unit', 'pressure_unit', 'temperature_unit', 'windspeed_unit'];
  }

  defaults() {
    return {
      firmware: 'stable'
    };
  }

  parse(resp) {
    if (resp[0]) { resp = resp[0]; }
    if (resp.devices) { return; }
    super.parse(resp);
    resp.auto_location = _.isEmpty(resp.location) && _.isEmpty(resp.location_description);
    return resp;
  }

  getLocationType() {
    if (this.get('auto_location')) { return 'auto'; } return 'manual';
  }

  getThiamis() {
    return this.parents[0].parents[0];
  }

  presentName() {
    return this.get('name');
  }
}

export class Sensor extends DeviceStatus(Chooser(Device)) {
  static STATUSES = {
    REPORTING: 'OK',
    NOT_REPORTING: ''
  }

  booleanAttrs() { return ['enabled', 'debug', 'auto_zero_enabled', 'pm_microgram', 'us_units']; }

  calibration = {
    auto_zero: {
      sliderOptions: { min: 0,
        max: 24 * 60 * 60 * 1000,
        step: 15 * 60 * 1000,
        inputDisabled: true,
        formatter(value) {
          if (value) {
            return humanizeDuration(value);
          } return I18n.t('sensor.auto_zero_min_label');
        }
      }
    },
    flow_calibration: {
      sliderOptions: { min: 0.5, max: 1.5, step: 0.01 }
    },
    photometric_calibration: {
      sliderOptions: { min: 0.05, max: 10, step: 0.001 }
    },
    size_correction: {
      sliderOptions: { min: 0.1, max: 10, step: 0.001 }
    },
    calfactor: {
      sliderOptions: { min: 0.1, max: 10, step: 0.001 }
    },
    fan_dac_value: {
      sliderOptions: { min: 0, max: 255, step: 1 }
    },
    laser_dac_value: {
      sliderOptions: { min: 0, max: 255, step: 1 }
    }
  }

  get mutators() {
    return {
      status_text: {
        transient: true,
        get() {
          const status = this.get('status_type');
          // eslint-disable-line
          if (this.hasError()) {
            const errors = this.getErrors();
            if (errors.isEmpty()) { return this.get('status'); } return errors.pluck('name');
          }
          if (status) { return I18n.t(`devices.statuses.${status}`, { defaultValue: I18n.t('devices.statuses.empty') }); }
        }
      },
      connection() {
        return _([this.get('thiamis_serial'), this.get('com_port'), this.get('com_type'), this.get('address')]).compact().join('.');
      },
      serial: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('serial'), (x) => x.getLastMeasurement());
        }
      },
      status: {
        transient: true,
        get() {
          return __guardMethod__(__guard__(this.findDataPointByName('status'), (x) => x.getLastMeasurement()), 'toLowerCase', (o) => o.toUpperCase());
        }
      },
      status_type: {
        transient: true,
        get() {
          const status = this.get('status');
          if (this.hasError()) { return 'error'; }
          if (this.get('enabled') === false) { return 'disabled'; }
          if (!status) {
            if (this.isReporting()) { return 'online'; }
            return 'offline';
          }
          return status === Sensor.STATUSES.REPORTING && this.isReporting() ? 'online' : 'offline';
        }
      },
      display_firmware: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('firmware'), (x) => x.getLastMeasurement());
        }
      },
      calibration: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('calibration'), (x) => x.getLastMeasurement());
        }
      },
      cc_calibration_display_value: {
        transient: true,
        get() {
          const concentrationCalibration = this.findDataPointByName('cumulative concentration (calibration)');
          if (!concentrationCalibration) { return; }
          return `${(concentrationCalibration != null
            ? concentrationCalibration.getLastMeasurement() : undefined)} ${(concentrationCalibration != null
            ? concentrationCalibration.get('unit') : undefined) || ''}`;
        }
      },
      filter_last_changed: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('filter last changed'), (x) => x.getLastMeasurement());
        }
      },
      pump_run_time: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('pump run time'), (x) => x.getLastMeasurement());
        }
      },
      pump_run_time_unit: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('pump run time'), (x) => x.get('unit'));
        }
      },
      cc_filter_change_display_value: {
        transient: true,
        get() {
          const filterChange = this.findDataPointByName('cumulative concentration (filter change)');
          if (!filterChange) { return; }
          return `${(filterChange != null ? filterChange.getLastMeasurement() : undefined)} ${(filterChange != null ? filterChange.get('unit') : undefined) || ''}`;
        }
      },
      mode: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('mode'), (x) => x.getLastMeasurement());
        }
      },
      sample_method: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('sample method'), (x) => x.getLastMeasurement());
        }
      },
      instrument_mode: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('instrument mode'), (x) => x.getLastMeasurement());
        }
      },
      exp_bd_status_alarm: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('i/o exp. bd. status alarm'), (x) => x.getLastMeasurement());
        }
      },
      mib_status_alarm: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('mib status alarm'), (x) => x.getLastMeasurement());
        }
      },
      motherboard_status_alarm: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('motherboard status alarm'), (x) => x.getLastMeasurement());
        }
      },
      external_alarm_3: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('external alarm 3'), (x) => x.getLastMeasurement());
        }
      },
      external_alarm_2: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('external alarm 2'), (x) => x.getLastMeasurement());
        }
      },
      external_alarm_1: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('external alarm 1'), (x) => x.getLastMeasurement());
        }
      },
      model: {
        transient: true,
        get() {
          return __guard__(this.findDataPointByName('model'), (x) => x.getLastMeasurement());
        }
      },
      display_name_und: {
        transient: true,
        get() {
          return _.dasherize(this.get('display_name').toLowerCase());
        }
      },
      display_name: {
        transient: true,
        get() {
          if (this.get('model')) {
            return this.get('model');
          } return this.get('name');
        }
      }
    };
  }

  get validation() {
    return {
      ...super.validation,
      ...{
        com_type: {
          required: true
        },
        com_port: {
          required: true
        },
        name: {
          required: true
        }
      }
    };
  }

  parse(resp) {
    if (resp.devices) { return; }
    resp = super.parse(...arguments); // eslint-disable-line prefer-rest-params
    try {
      const model = __guard__(this.findDataPointByName('model', this.getThiamis().get('data_points'), resp._id), (x) => x.getLastMeasurement());
      if (resp.extra_configuration && model) {
        this.defaults = _.extend(_.result(this, 'defaults') || {}, resp.extra_configuration[model]);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`parsing extra_configuration, ${resp._id}`);
    }
    if (resp.profiles) {
      resp.profiles = _.map(resp.profiles, (params, name) => ({ name, params }));
    }
    return _.extend(_.omit(resp, 'connection'), this.parseConnection(resp.connection));
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      if (json.profile !== 'custom') {
        return _.omit(..._.flatten([[json], _.filter(_.keys(this.getCalibration()), (attr) => !_.contains(['auto_zero', 'flow_calibration'], attr))]));
      }
    }
    return json;
  }

  parseConnection(string) {
    const [thiamis_serial, com_port, com_type, address] = Array.from(string.split('.'));
    return { thiamis_serial, com_port, com_type, address };
  }

  getCalibration() {
    const calibration = _.clone(this.calibration);
    if (this.get('name') === 'DataRAM') {
      calibration.flow_calibration.sliderOptions.max = 3.5;
    }
    return calibration;
  }

  getConfiguration() {
    return _.extend({}, this.get('configuration'), __guard__(this.get('extra_configuration'), (x) => x[this.mutators.model.get.call(this)]));
  }

  isReporting() {
    const measurements = _(__guard__(__guard__(this.getThiamis(), (x1) => x1.getDataPoints()), (x) => x.filter((dp) => dp.get('device_id') === this.id && dp.isDefault())))
      .chain()
      .map((dp) => dp.getLastMeasurementTs())
      .compact()
      .sort((ts1, ts2) => ts1 - ts2)
      .value();
    const reportingInterval = __guard__(this.getThiamis(), (x2) => x2.getReportingInterval()) || MIN_REPORTING_INTERVAL;
    return this.isDeviceReporting(_.last(measurements), reportingInterval);
  }

  findDataPointByName(name, dataPoints, deviceId) {
    if (!dataPoints) { dataPoints = __guard__(this.getThiamis(), (x) => x.getDataPoints()); }
    if (_.isArray(dataPoints)) {
      return _.find(dataPoints, (dp) => ((dp.name != null ? dp.name.toLowerCase() : undefined) === name.toLowerCase()) && (dp.device_id === (deviceId || this.id)));
    }
    return (dataPoints != null ? dataPoints.find((dp) => (__guard__(dp.get('name'), (x1) => x1.toLowerCase()) === name.toLowerCase()) && (dp.get('device_id') === (deviceId || this.id))) : undefined);
  }

  getConnectionId() {
    return this.id.split(':')[1];
  }

  getDefaultDataPoints() {
    if (this.defaultsDataPointsColl) { return this.defaultsDataPointsColl; }
    this.defaultsDataPointsColl = new FilteredCollection(this.getDataPoints());
    this.defaultsDataPointsColl.setSort('name');
    this.defaultsDataPointsColl.filterBy((dp) => dp.isDefault() || dp.isRaw() || dp.isActiveError());
    return this.defaultsDataPointsColl;
  }

  getRawDataPoints() {
    if (this.rawDataPointsColl) { return this.rawDataPointsColl; }
    this.rawDataPointsColl = new FilteredCollection(this.getDataPoints());
    this.rawDataPointsColl.setSort('name');
    this.rawDataPointsColl.filterBy((dp) => dp.isRaw());
    return this.rawDataPointsColl;
  }

  getLinkedDataPoint(dpId) {
    return this.getDataPoints().find((dp) => _.contains(dp.get('linked'), dpId));
  }

  getRawDataPointsByChannel() {
    if (this.rawDataPointsByChannelColl) { return this.rawDataPointsByChannelColl; }
    this.rawDataPointsByChannelColl = new FilteredCollection(this.getRawDataPoints());
    this.rawDataPointsByChannelColl.setSort('name');
    this.rawDataPointsByChannelColl.filterBy((dp) => {
      const { path } = dp.parsePath();
      return this.getChannelConfigurationBy(path);
    });
    return this.rawDataPointsByChannelColl;
  }

  getChannelConfigurationBy(path) {
    return __guard__(this.get('channels'), (x) => x[path - 10]);
  }

  getErrors() {
    if (this.errorsColl) { return this.errorsColl; }
    this.errorsColl = new FilteredCollection(this.getDataPoints());
    this.errorsColl.filterBy((dp) => dp.isActiveError());
    return this.errorsColl;
  }

  getThiamis() {
    return (this.collection != null ? this.collection.getThiamis() : undefined);
  }

  isDeletable() {
    return !this.getThiamis().isAirthinx() && !this.getThiamis().isHeraeus();
  }

  isADAM() {
    return this.get('name').toLowerCase() === 'adam';
  }

  isAirmar() {
    return this.get('name').toLowerCase() === 'airmar';
  }

  isYSI() {
    return this.get('name').toLowerCase() === 'ysi';
  }

  isGM460() {
    return this.get('name').toLowerCase() === 'gm460' || this.isGX6000();
  }

  isGX6000() {
    return this.get('name').toLowerCase() === 'gx6000';
  }

  isSoundPro() {
    return this.get('name').toLowerCase() === 'soundpro';
  }

  hasCustomProfile() {
    return this.get('profile') === 'custom';
  }

  presentName() {
    return `${this.get('name')} ${this.get('tag')}`;
  }

  hasError() {
    const status = this.get('status');
    return (!_.isEmpty(status) && (status !== Sensor.STATUSES.REPORTING)) || !this.getErrors().isEmpty();
  }
}

export class Sensors extends SingleChooser(AppCollection) {
  get model() {
    return Sensor;
  }

  getThiamis() {
    // TODO: use methods?
    return this.parents[0].parents[0];
  }

  toJSON() {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    return _.filter(json, (device) => !_.isEmpty(device.name));
  }
}

export class Devices extends AppModel {
  defaults() {
    return {
      thiamis: {},
      sensors: []
    };
  }

  get relations() {
    return [
      {
        type: Backbone.One,
        key: 'thiamis',
        relatedModel: ThiamisDevice,
        options: {
          parse: true
        }
      },
      {
        type: Backbone.Many,
        key: 'sensors',
        collectionType: Sensors,
        relatedModel: Sensor
      }
    ];
  }

  getThiamis() {
    return this.parents[0];
  }

  toJSON(options) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      if (__guard__(this.getThiamis(), (x) => x.isCreatable())) { return []; }
      const devices = json.sensors;
      devices.push(json.thiamis);
      return devices;
    }
    return json;
  }
}
