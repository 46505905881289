import { View } from 'backbone.marionette';
import * as d3 from 'd3/d3';

const SparkLine = View.extend({
  template: false,

  id() { return `sparkline-wrapper-${this.cid}`; },

  initialize() {
    this.margin = { top: 2, right: 2, bottom: 2, left: 4 };
    this.width = 86 - this.margin.left - this.margin.right;
    this.height = 20 - this.margin.top - this.margin.bottom;
    this.data = [];
    return this.listenTo(this.model, 'augment:measurements', this.tick, this);
  },

  getXScale() {
    return this.xScale != null ? this.xScale : (this.xScale = d3.scale.linear()
      .domain([0, this.pointNumber - 1])
      .range([0, this.width]));
  },

  getYScale() {
    if (this.yScale) { return this.yScale; }
    this.yScale = d3.scale.linear()
      .domain([0, 1])
      .range([this.height, 0]);
    this.updateYScaleRange();
    return this.yScale;
  },

  updateYScaleRange() {
    const min = d3.min(this.data);
    const max = d3.max(this.data);
    const margin = 0.06 * (max - min);
    return this.yScale = this.getYScale().domain([min - margin, max + margin]);
  },

  getChart() {
    if (this.chart) { return this.chart; }
    this.chart = d3
      .select(`#${this.id()}`)
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
    this.chart
      .append('defs')
      .append('clipPath')
      .attr('id', `clip-${this.cid}`)
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height);
    return this.chart;
  },

  getPath() {
    return this.path != null ? this.path : (this.path = this.getChart()
      .append('g')
      .attr('clip-path', `url(#clip-${this.cid})`)
      .append('path')
      .datum(this.data)
      .attr('class', 'line')
      .attr('d', this.getLine())
      .attr('fill', 'none')
      .attr('stroke', '#9B9B9B')
      .attr('stroke-width', '2px')
      .attr('shape-rendering', 'geometricPrecision'));
  },

  getLine() {
    return this.line != null ? this.line : (this.line = d3.svg.line()
      .x((d, i) => this.getXScale()(i))
      .y((d) => this.getYScale()(d)));
  },

  tick(measurement) {
    // push a new data point onto the back
    if (!measurement.get('value')) { return; }
    this.data.push(measurement.get('value'));
    this.updateLine();
    return (() => {
      const result = [];
      while (this.data.length > this.pointNumber) {
        result.push(this.data.shift());
      }
      return result;
    })();
  },

  updateLine() {
    this.updateYScaleRange();
    this.getPath()
      .attr('d', this.getLine());
    if (this.data.length > this.pointNumber) {
      return this.getPath()
        .attr('transform', null)
        .transition()
        .duration(1000)
        .attr('transform', `translate(${this.getXScale()(-1)},0)`);
    }
  },

  onDomRefresh() {
    this.pointNumber = 8;
    this.data = _(this.model.get('measurements')).map((m) => m[1]);
    return this.updateLine();
  }
});

export default SparkLine;
