import { normalize } from 'normalizr';

function getData(data) {
  // eslint-disable-next-line no-prototype-builtins
  return data.hasOwnProperty('data') ? data.data : data;
}

export function normalizeResp(data, schema) {
  return normalize(getData(data), schema);
}

export function normalizeDataPointsResp(data) {
  let dataPoints = getData(data);
  if (!Array.isArray(dataPoints)) dataPoints = [dataPoints]; // eslint-disable-line
  return dataPoints.map((dp) => ({
    ...dp,
    measurements: { data: dp.measurements, data_point_id: dp._id } // eslint-disable-line no-underscore-dangle
  }));
}

export function normalizeNodesResp(data) {
  const nodes = getData(data);
  return nodes.map((node) => {
    if (node.data_points) {
      // eslint-disable-next-line no-param-reassign
      node.data_points = normalizeDataPointsResp(node.data_points);
    }
    return node;
  });
}

export function normalizeUsersWithTotalsResp(resp, schema) {
  const {
    users, length, limit, start, total
  } = getData(resp);
  return {
    length, limit, start, total, ...normalize(users, schema)
  };
}
