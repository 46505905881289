import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import trim from 'lodash/trim';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '~/components/modal';
import { sendUserInvitationRequest } from '~/store/reducers/invitations';
import ModalForm from './form';

const InvitePartnerModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.user_invitations'),
      { toastId: 'success_invitation' }
    );
    toggleModal();
  }, [t, toggleModal]);

  const dispatch = useDispatch();
  const handleInviteUser = useCallback((values) => {
    const {
      email, organization: { value: organization_id }, organization: { label: organization_name }, role: { value: role }
    } = values;
    dispatch(sendUserInvitationRequest({
      email: trim(email),
      role,
      organization_id,
      organization_name,
      onSuccess: handleSuccess
    }));
  }, [dispatch, handleSuccess]);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={handleClose}
      title={t('users.invite_new_user')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleInviteUser}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit }) => (
            <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default InvitePartnerModal;
