/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import I18n from 'app/config/i18n';
import GsmSignalLevel from 'app/backbone/lib/react/sensors/gsmSignalLevel/gsmSignalLevel';
import WifiSignalLevel from 'app/backbone/lib/react/sensors/wifiSignalLevel/wifiSignalLevel';
import { PATHS } from '~/utils/constants';
import { isDefined } from '~/utils/helpers';
import { getDataPointIdByPath, getDataPointById } from '~/store/selectors/data_points';
import { getLastMeasurementValue } from '~/store/selectors/measurements';
import { getDeviceIsGsmSignal } from './selectors';

function getLevel(value) {
  const signalLevels = [-65, -75, -85, -95, -105];
  if (!value) { return 0; }
  let signalLevel = signalLevels.find((level) => value >= level);
  signalLevel = signalLevels.indexOf(signalLevel);
  // eslint-disable-next-line no-bitwise
  if (~signalLevel) { return signalLevel + 1; } return 0;
}

function getDisplayValue(value, network, unit) {
  if ((!isDefined(value) || !!network) && !isDefined(value)) { return ''; }
  return `${value}${unit || ''}`;
}

function getTitle(value, network, isGsm) {
  if (isGsm) {
    if (value) {
      if (network) {
        return I18n.t('thiamis.more_info.gsm_signal_with_network', { value, network });
      }
      return I18n.t('thiamis.more_info.gsm_signal', { value });
    }
    return I18n.t('thiamis.more_info.gsm_signal_na');
  }

  if (value) {
    return I18n.t('thiamis.more_info.wifi_signal', { value: getDisplayValue(), network });
  }

  return I18n.t('thiamis.more_info.wifi_signal_na');
}

const SignalLevelContainer = ({ nodeId }) => {
  const {
    isGsm,
    value,
    network,
    unit
  } = useSelector(
    // eslint-disable-next-line arrow-body-style
    (state) => {
      const _isGsm = getDeviceIsGsmSignal(state, nodeId); // eslint-disable-line no-underscore-dangle
      const signalId = getDataPointIdByPath(state, nodeId, _isGsm ? PATHS.GSM_SIGNAL : PATHS.WIFI_SIGNAL);
      const networkId = getDataPointIdByPath(state, nodeId, _isGsm ? PATHS.GSM_NETWORK : PATHS.WIFI_NETWORK);
      return {
        isGsm: _isGsm,
        value: getLastMeasurementValue(state, signalId),
        network: getLastMeasurementValue(state, networkId),
        unit: get(getDataPointById(state, signalId), 'unit')
      };
    }
  );
  const level = getLevel(value);
  const displayValue = getDisplayValue(value, network, unit);
  const props = {
    networkName: network,
    value: displayValue,
    level,
    title: getTitle(displayValue, network, isGsm)
  };
  if (isGsm) {
    return <GsmSignalLevel {...{ ...props }} />;
  }
  return <WifiSignalLevel {...{ ...props }} />;
};

SignalLevelContainer.propTypes = {
  nodeId: PropTypes.string.isRequired
};

export default SignalLevelContainer;
