import { createSelector } from 'reselect';
import first from 'lodash/first';
import * as fromReports from '~/store/reducers/reports';

export const getReports = (state) => fromReports.getReports(state);
export const getReportById = (state, id) => fromReports.getReportById(state, id);
export const getReportsIsFetched = (state) => fromReports.getIsFetched(state);

export const getDashboardReport = createSelector(
  getReports,
  (reports = []) => first(reports)
);

export const getReportByIdWidgets = createSelector(
  getReportById,
  (report) => report?.widgets || []
);
