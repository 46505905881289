import React, { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import I18n from 'app/config/i18n';
import { getUsersQuery, setUsersQuery } from '~/store/reducers/users';
import { getCurrentUserIsManager } from '~/store/selectors/current_user';
import InviteUserModal from './invite_user_modal';
import Tabs from './tabs';
import { filterListByQuery } from '../reducer';

export default function FiltersFormTop({ activeTab, setTab }) {
  const [inviteActive, setInviteActive] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const dispatch = useDispatch();
  const usersQuery = useSelector(getUsersQuery);
  const isManager = useSelector(getCurrentUserIsManager);

  useEffect(() => {
    if (usersQuery === debouncedQuery) {
      return;
    }
    dispatch(setUsersQuery(debouncedQuery));
  }, [debouncedQuery, dispatch, usersQuery]);

  const toggleInviteActive = useCallback(() => setInviteActive((b) => !b), [setInviteActive]);

  return (
    <div className="content-top">
      <Tabs activeTab={activeTab} setTab={setTab} />
      <div className="pull-right">
        { isManager
          ? (
            <button
              type="button"
              className="btn btn-primary btn-invite"
              onClick={toggleInviteActive}
            >
              <i className="fa fa fa-fw fa-user-plus" />
              <span>
                {' '}
                {I18n.t('users.invite_new_user')}
              </span>
            </button>
          ) : null }
        <div className="users-filter-input-region filter-input-wrap">
          <input
            className="filter-input"
            type="text"
            placeholder="Filter..."
            onChange={(e) => {
              setQuery(e.target.value);
              dispatch(filterListByQuery(e.target.value));
            }}
            value={query}
          />
        </div>
      </div>
      <InviteUserModal isVisible={inviteActive} toggleModal={toggleInviteActive} />
    </div>
  );
}
