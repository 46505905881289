import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { getDataPointByIdLinked, getDataPointByIdPresentName } from '~/store/selectors/data_points';
import { getMeasurementsByIdData } from '~/store/selectors/measurements';
import { fetchDataPointsRequest } from '~/store/reducers/nodes';
import MeasurementsTable from './measurements_table';

const DetailRow = ({ row }) => {
  const { triggered: from, duration, dataPointId } = row;
  let to = from + duration;
  if (moment().diff(moment(to), 'minutes') < 1) {
    to = null;
  }
  const linkedDataPointIds = useSelector((state) => getDataPointByIdLinked(state, dataPointId));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDataPointsRequest({ data_point_id: linkedDataPointIds, from, to: to || moment().valueOf() }));
  }, [dispatch, linkedDataPointIds, from, to]);

  const triggeredColumn = { name: 'triggered', title: 'Timestamp' };
  const columns = useSelector((state) => linkedDataPointIds.map((id) => {
    const name = getDataPointByIdPresentName(state, id);
    return { name: id, title: name };
  }));

  const measurements = useSelector((state) => linkedDataPointIds.map((id) => {
    const data = getMeasurementsByIdData(state, id);
    return data.map(([ts, value]) => ({ ts, [id]: value }));
  }));
  const rows = _.chain(measurements)
    .flatten()
    .reduce((acc, { ts, ...rest }) => {
      acc[ts] = rest;
      return acc;
    }, {})
    .map((value, ts) => ({ triggered: +ts, ...value }))
    .filter(({ triggered }) => moment(triggered).isBetween(from, to || moment().valueOf(), null, '[]'))
    .value();
  return (
    <div style={{ marginLeft: '20px' }}>
      <MeasurementsTable rows={rows} columns={[triggeredColumn, ...columns]} />
    </div>
  );
};

export default DetailRow;
