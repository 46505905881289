/* eslint-disable func-names */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import WidgetNodeTitle from 'app/backbone/lib/components/shared_views/widget_node_title';
import { NodeWidgetContent, NoDataPoints } from 'app/backbone/lib/components/shared_views/widget_node_views';
import { NodeWidgetDataPoint, NodeDataPoint } from 'app/backbone/lib/concerns/views/node_data_point';
import NodeWidgetFetchThiamis from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamis';
import SparkLine from 'app/backbone/lib/components/shared_views/sparkline_chart';
import dataPointTemplate from './templates/data_point.hbs';
import dataPointContainerTemplate from './templates/data_point_container.hbs';

export const DataPoint = View.extend({
  behaviors: [NodeDataPoint, NodeWidgetDataPoint],
  template: dataPointTemplate,
  tagName: 'tr',

  regions: {
    sparklineRegion: '.sparkline'
  },

  ui: {
    name: '.name',
    value: '.value',
    unit: '.unit'
  },

  bindings: {
    '@ui.value': {
      observe: 'value',
      updateView(value) {
        return _.isDefined(value);
      },
      onGet() {
        return this.model.getDisplayValue();
      }
    },
    '@ui.unit': {
      observe: 'unit',
      onGet(unit) {
        const value = this.model.get('value');
        return `${_.isDefined(unit && _.isDefined(value)) ? unit : ''}`;
      }
    }
  },

  onRender() {
    this.stickit();
    _.defer(() => {
      if (this.isRendered()) {
        const sparkline = new SparkLine({ model: this.model });
        return this.getRegion('sparklineRegion').show(sparkline);
      }
    });
  }
});

export const DataPointContainer = AppCollectionView.extend({
  template: dataPointContainerTemplate,
  childView: DataPoint,
  childViewContainer: 'tbody',
  emptyView: NoDataPoints,

  collectionEvents: {
    reset: '_render'
  },

  _render: _.throttle(function () {
    this.render();
  }, 300, { leading: false }),

  childViewOptions() {
    return _(this.options).pick('thiamis');
  }
});

const NodeWidgetContentView = NodeWidgetContent.extend({
  behaviors: [NodeWidgetFetchThiamis],

  getWidgetTitle() {
    return new WidgetNodeTitle({
      model: this.options.thiamis
    });
  },

  getDataPointsContainer() {
    return this.dataPointsContainer != null ? this.dataPointsContainer : (this.dataPointsContainer = new DataPointContainer({
      collection: this.model.getUserDataPointsColl(), thiamis: this.options.thiamis }));
  }
});

export default NodeWidgetContentView;
