import React, { useCallback } from 'react';
import I18n from 'app/config/i18n';
import { api as alertsApi } from 'app/backbone/apps/alerts/alerts_app';
import { isAlertMonitorable } from '~/store/selectors/alerts';

export const NameFormatter = (props) => {
  const { row: { _id: nodeId, name, actions } } = props;
  const isMonitorable = isAlertMonitorable(actions);
  const handleMonitorAlertClick = useCallback(
    () => alertsApi.getChannel().request('app:alerts:monitor', nodeId),
    [nodeId]
  );
  if (isMonitorable) {
    return (
      <a
        title={I18n.t('alerts.open_monitor')}
        role="button"
        tabIndex={0}
        className="blue"
        onClick={handleMonitorAlertClick}
      >
        { name }
      </a>
    );
  }
  return <>{name}</>;
};
