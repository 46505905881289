import React, { useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Overlay, Popover, Button, ButtonGroup
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { destroyPartnershipRequest, fetchPartnershipsRequest } from '~/store/reducers/partnerships';

const RevokePartnership = ({ id }) => {
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const trigger = useRef();
  const dispatch = useDispatch();

  const toggleOverlay = useCallback(() => setShowOverlay((o) => !o),
    [setShowOverlay]);

  const onSuccess = useCallback(() => {
    dispatch(fetchPartnershipsRequest());
  }, [dispatch]);

  const handleAction = useCallback(() => {
    dispatch(destroyPartnershipRequest({ id, onSuccess }));
    toggleOverlay();
  }, [dispatch, id, toggleOverlay, onSuccess]);

  const renderPopover = (
    <Popover
      title={t('base.buttons.revoke')}
      id="delete-invite-popover"
    >
      <div className="confirmation-buttons text-center">
        <ButtonGroup>
          <Button bsStyle="danger" bsSize="small" onClick={handleAction}>
            <i className="glyphicon glyphicon-ok" />
            {' '}
            {t('base.labels.yes')}
          </Button>
          <Button bsSize="small" onClick={toggleOverlay}>
            <i className="glyphicon glyphicon-remove" />
            {' '}
            {t('base.labels.no')}
          </Button>
        </ButtonGroup>
      </div>
    </Popover>
  );

  return (
    <div
      className="expand-button"
      style={{ position: 'relative' }}
    >
      <Overlay
        placement="left"
        show={showOverlay}
        // eslint-disable-next-line react/no-find-dom-node
        target={() => ReactDOM.findDOMNode(trigger?.current)}
      >
        {renderPopover}
      </Overlay>
      <a
        className="info"
        role="button"
        ref={trigger}
        onClick={toggleOverlay}
      >
        <i className="fa fa fa-fw fa-trash" />
      </a>
    </div>
  );
};

export default RevokePartnership;
