/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { NodeDataPointsFilter, NodeEmptyDataPointsFilter } from 'app/backbone/lib/components/shared_views/widget_node_select';
import WidgetNodeSelect from 'app/backbone/lib/concerns/views/widget_node_select';
import WidgetDataPointdSelect from 'app/backbone/lib/concerns/views/widget_data_points_select';
import TechDataPointContainer from 'app/backbone/lib/components/shared_views/widget_tech_data_points';
import NodeWidgetFetchThiamisesMixin from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamises';
import { app as App } from 'app/backbone/app';
import noDataPointTemplate from './templates/no_data_point.hbs';
import widgetNodeContentTemplate from './templates/widget_node_content.hbs';
import widgetNodeConfTemplate from './templates/widget_node_conf.hbs';

export const NodeWidgetConfig = View.extend({
  behaviors: [NodeWidgetFetchThiamisesMixin, WidgetNodeSelect, WidgetDataPointdSelect],
  template: widgetNodeConfTemplate,
  regions: {
    nodeSelectRegion: '.nodeselect-region',
    dataPointsFilterRegion: '.datapointsfilter-region'
  },
  ui: {
    loading: '.loading-overlay'
  },
  dataPointsFilterClass: NodeDataPointsFilter,
  emptyDataPointsFilterClass: NodeEmptyDataPointsFilter
});

export const NoDataPoints = View.extend({
  className: 'no_data_points',
  template: noDataPointTemplate
});

export const NodeWidgetContent = View.extend({
  template: widgetNodeContentTemplate,
  regions: {
    dataPointsRegion: '.node-widget-content',
    techDataPointsRegion: '.tech-datapoints-region'
  },

  initialize({ thiamis }) {
    this.thiamis = thiamis || this.options.thiamis;
    return this.listenTo(this.model.getUserDataPointsColl(), 'reset', () => this.showUserDataPointsContainer());
  },

  showUserDataPointsContainer() {
    return this.getRegion('dataPointsRegion').show(this.getDataPointsContainer());
  },

  showTechDataPointsContainer() {
    return this.getRegion('techDataPointsRegion').show(this.getTechDataPointsContainer());
  },

  getTechDataPointsContainer() {
    return this.techDataPointsContainer != null ? this.techDataPointsContainer : (this.techDataPointsContainer = new TechDataPointContainer({
      collection: this.model.getDataPointsColl(),
      model: this.model,
      thiamis: this.thiamis,
      lastUpdate: this.model.getDataPointsLastUpdate()
    }));
  },

  getDataPointsContainer() {
    return _.abstractMethod();
  },

  onRender() {
    App.getChannel().request('when:fetched', this.thiamis, () => {
      this.showUserDataPointsContainer();
      this.showTechDataPointsContainer();
    });
  }
});
