import React, { useMemo } from 'react';
import Loader from 'react-loader';
import PropTypes from 'prop-types';

const Spinner = ({ className }) => {
  const options = useMemo(() => ({
    lines: 10, // The number of lines to draw
    length: 6, // The length of each line
    width: 2.5, // The line thickness
    radius: 7, // The radius of the inner circle
    corners: 1, // Corner roundness (0..1)
    rotate: 9, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#000', // #rgb or #rrggbb
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    shadow: false, // Whether to render a shadow
    // hwaccel: true, // Whether to use hardware acceleration
    className: 'spinner', // The CSS class to assign to the spinner
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    zIndex: 2e9
  }), []);

  return (
    <div className={`react-loading-container ${className}`}>
      <Loader options={options} />
    </div>
  );
};

Spinner.defaultProps = {
  className: ''
};

Spinner.propTypes = {
  className: PropTypes.string
};

export default Spinner;
