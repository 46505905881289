import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '~/components/spinner';
import { fetchNodesRequest } from '~/store/reducers/nodes';
import { getNodesIsFetched } from '~/store/selectors/nodes';
import {
  clearFilters, fetchFirmwaresRequest, getIsFetched
} from './reducer';
import GridContainer from './grid_container';
import FiltersFormTop from './filters_form_top';
import FiltersSidebar from './filters_sidebar';
import RolloutModalContext from './rollout_firmware_modal/context';
import RolloutFirmwareModal from './rollout_firmware_modal';

const FirmwaresScreen = React.memo((props) => {
  const { slug } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFirmwaresRequest());
  }, [dispatch]);

  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    dispatch(fetchNodesRequest({ data }));
  }, [dispatch, slug]);

  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const { isFetched, isNodesFetched } = useSelector(
    (state) => ({
      isFetched: getIsFetched(state),
      isNodesFetched: getNodesIsFetched(state)
    })
  );

  const [isActive, setIsActive] = useState(false);
  const toggleActive = useCallback(() => setIsActive((b) => !b), [setIsActive]);
  const [selectedFirmware, setSelectedFirmware] = useState(null);

  if (!isFetched || !isNodesFetched) {
    return <Spinner />;
  }

  return (
    <RolloutModalContext.Provider value={{
      isActive,
      setIsActive,
      selectedFirmware,
      setSelectedFirmware
    }}
    >
      <div className="content-region fill-height content filtered-content">
        <div className="files-container">
          <FiltersFormTop />
          <GridContainer />
          <FiltersSidebar />
        </div>
        <RolloutFirmwareModal isVisible={isActive} toggleModal={toggleActive} />
      </div>
    </RolloutModalContext.Provider>
  );
});

export default FirmwaresScreen;
