/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Layout from 'app/backbone/lib/views/layout';
import dialogLayoutTemplate from './templates/dialog_layout.hbs';

export default Layout.extend({
  template: dialogLayoutTemplate,

  attributes: {
    tabindex: '-1',
    role: 'dialog'
  },

  regions: {
    contentRegion: '.dialog-content-region'
  },

  events: {
    'hidden.bs.modal': 'onDialogClose'
  },

  className() {
    return `modal fade ${this.options.class || ''}`;
  },

  initialize({ title }) {
    this.title = title;
  },

  serializeData() {
    return _(this).pick('title');
  },

  onDialogClose() {
    this.$el.find('.select2').select2().trigger('select2:close');
    return this.destroy();
  },

  openDialog() {
    return this.$el.modal();
  },

  closeDialog() {
    return this.$el.modal('hide');
  },

  onAttach() {
    return this.openDialog();
  }
});
