import {
  call,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';
import requestApi from '~/utils/axios';
import * as Actions from '~/store/reducers/templates';

export function* templatesFetchRequestSaga() {
  try {
    const { data } = yield call(
      requestApi,
      'post',
      'search/nodes',
      {
        type: 'template'
      }
    );
    yield put(Actions.fetchTemplatesSuccess(data));
  } catch (e) {
    yield put(Actions.fetchTemplatesError(e.toString()));
  }
}

function* watchFetchTemplatesRequest() {
  yield takeLatest(Actions.fetchTemplatesRequest.toString(), templatesFetchRequestSaga);
}

export default function* app() {
  yield fork(watchFetchTemplatesRequest);
}
