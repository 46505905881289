/* eslint-disable no-param-reassign */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';

import { app as App } from 'app/backbone/app';
import { Node, Nodes } from 'app/backbone/entities/nodes/node';
import AppModel from 'app/backbone/lib/entities/app_model';
import I18n from 'app/config/i18n';
import { api as apiBridge } from 'app/backbone/lib/clusternet/bridge';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import { Chooser, MultiChooser } from 'app/backbone/lib/concerns/entities/chooser';
import { ThiamisStreamSubscribable } from 'app/backbone/lib/concerns/entities/measurement_updatable';
import NodeEntitySubscribable from 'app/backbone/lib/concerns/entities/node_entity_subscribable';
import DeviceStatus from 'app/backbone/lib/concerns/entities/device_status';
import DataPoint from 'app/backbone/entities/data_point';
import { DataPointsColl } from 'app/backbone/entities/data_points';
import { Devices } from 'app/backbone/entities/nodes/sensor';
import UserMetadata from 'app/backbone/entities/nodes/user_metadata';
import * as Config from 'app/backbone/entities/nodes/config';
import { __guard__, __guardMethod__ } from 'app/utils/custom-fns';

export default class Thiamis extends ThiamisStreamSubscribable(NodeEntitySubscribable(DeviceStatus(Chooser(Node)))) {
  get transientAttrs() { return ['status']; }

  static DEBUG_LEVELS = {
    ERROR: 'ERROR',
    INFO: 'INFO'
  }

  LATLNG_PRECISION = 7

  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'data_points',
        relatedModel: DataPoint,
        collectionType: DataPointsColl,
        isTransient: true
      },
      {
        type: Backbone.One,
        key: 'devices',
        relatedModel: Devices
      },
      {
        type: Backbone.One,
        key: 'user_metadata',
        relatedModel: UserMetadata
      }
    ];
  }

  get defaults() {
    return {
      _type: 'thiamis',
      interval: 60000,
      shared_to: [],
      leased_to: null,
      devices: {},
      user_metadata: {},
      data_points: [],
      name: null,
      description: null
    };
  }

  validation = {
    name: {
      required: true
    }
  }

  get mutators() {
    return {
      node_id: {
        transient: true,
        get() { return this.id; }
      },
      status_text: {
        transient: true,
        get() {
          const status = this.get('status_type');
          // eslint-disable-line
          if (this.hasError()) {
            const errors = this.getErrors();
            if (errors.isEmpty()) { return this.get('status'); } return errors.pluck('name');
          }
          if (status) { return I18n.t(`devices.statuses.${status}`, { defaultValue: I18n.t('devices.statuses.empty') }); }
        }
      },
      display_name: {
        transient: true,
        get() {
          return this.get('name');
        }
      },
      status_type: {
        transient: true,
        get() {
          const status = this.get('status');
          if (status === 'activated') {
            if (this.isReporting()) {
              return 'online';
            } return 'offline';
          }
          if (this.hasError()) { return 'error'; }
          if (status) { return status; }
        }
      },
      model: {
        transient: true,
        get() {
          return __guardMethod__(this.getDevice(), 'get', (x) => x.get('name')) || this.get('name');
        }
      },
      iccid: {
        transient: true,
        get() {
          return __guardMethod__(this.getDataPoint(DataPoint.PATHS.ICCID), 'getLastMeasurement', (x) => x.getLastMeasurement());
        }
      },
      firmware: {
        transient: true,
        get() {
          return __guardMethod__(this.getDataPoint(DataPoint.PATHS.FIRMWARE), 'getLastMeasurement', (x) => x.getLastMeasurement());
        }
      },
      mac_address: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.MAC_ADDRESS), (x) => x.getLastMeasurement());
        }
      },
      imei: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.IMEI), (x) => x.getLastMeasurement());
        }
      },
      imsi: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.IMSI), (x) => x.getLastMeasurement());
        }
      },
      location_description: {
        transient: true,
        get() {
          return this.get('location_descr_manual') || __guard__(this.getDataPoint(DataPoint.PATHS.LOCATION_DESCRIPTION), (x) => x.getLastMeasurement());
        }
      },
      location_ts: {
        transient: true,
        get() {
          return this.get('location_manual') || __guard__(this.getDataPoint(DataPoint.PATHS.LOCATION), (x) => x.getLastMeasurementTs());
        }
      },
      location_manual: {
        transient: true,
        get() {
          return __guardMethod__(this.getDevice(), 'get', (o) => o.get('location'));
        }
      },
      location_descr_manual: {
        transient: true,
        get() {
          return __guardMethod__(this.getDevice(), 'get', (o) => o.get('location_description'));
        }
      },
      display_latlng: {
        transient: true,
        get() {
          if (!this.get('latitude' || !this.get('longitude'))) { return false; }
          return `${this.get('latitude')}, ${this.get('longitude')}`;
        }
      },
      lat_manual: {
        transient: true,
        get() {
          const lat = __guard__(this.get('location_manual'), (x) => x[0]);
          if (lat) { return _.round(lat, this.LATLNG_PRECISION); }
        }
      },
      lng_manual: {
        transient: true,
        get() {
          const lng = __guard__(this.get('location_manual'), (x) => x[1]);
          if (lng) { return _.round(lng, this.LATLNG_PRECISION); }
        }
      },
      latitude: {
        transient: true,
        get() {
          return this.get('lat_manual') || _.round(__guard__(__guard__(this.getLocation(), (x1) => x1.getLastMeasurement()), (x) => x[0]), this.LATLNG_PRECISION);
        }
      },
      longitude: {
        transient: true,
        get() {
          return this.get('lng_manual') || _.round(__guard__(__guard__(this.getLocation(), (x1) => x1.getLastMeasurement()), (x) => x[1]), this.LATLNG_PRECISION);
        }
      },
      batt_current: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.BATTERY_CURRENT), (x) => x.getLastMeasurement());
        }
      },
      batt_current_unit: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.BATTERY_CURRENT), (x) => x.get('unit'));
        }
      },
      gsm_signal: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.GSM_SIGNAL), (x) => x.getLastMeasurement());
        }
      },
      wifi_network: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.WIFI_NETWORK), (x) => x.getLastMeasurement());
        }
      },
      wifi_security: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.WIFI_SECURITY), (x) => x.getLastMeasurement());
        }
      },
      wifi_bssid: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.WIFI_BSSID), (x) => x.getLastMeasurement());
        }
      },
      wifi_signal: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.WIFI_SIGNAL), (x) => x.getLastMeasurement());
        }
      },
      wifi_networks: {
        transient: true,
        get() {
          return __guard__(this.getDataPoint(DataPoint.PATHS.WIFI_NETWORKS), (x) => x.getLastMeasurement());
        }
      },
      timezone: {
        transient: true,
        get() {
          return __guard__(this.getDataPoints().getThiamisTimezone(), (x) => x.getLastMeasurement());
        }
      },
      default_timezone: {
        transient: true,
        get() {
          return this.getDataPoints().getDefaultTimezone();
        }
      },
      sensors_slugs: {
        transient: true,
        get() {
          return this.getSensors().map((sensor) => _.slugify(sensor.get('name')));
        }
      },
      type_slug: {
        transient: true,
        get() {
          return _.slugify(this.get('type'));
        }
      },
      type: {
        transient: true,
        get() {
          if (this.isCreatable()) {
            return I18n.t('thiamis.inventory');
          } return __guard__(this.getDevice(), (x) => x.get('name'));
        }
      },
      shared_to_org_slugs: {
        transient: true,
        get() {
          const organizations = App.getChannel().request('get:organizations');
          return this.get('shared_to').map((organization_id) => __guard__(organizations.get(organization_id), (x) => x.get('slug')));
        }
      },
      leased_to_org_slug: {
        transient: true,
        get() {
          return __guard__(App.getChannel().request('get:organizations').get(this.get('leased_to')), (x) => x.get('slug'));
        }
      },
      filter_tags: {
        transient: true,
        get() {
          let tags;
          if (this.isActivated()) {
            tags = this.isReporting() ? ['online'] : ['offline'];
          } else {
            tags = [this.get('status')];
          }
          if (this.isActivated()) { tags.push('activated'); }
          if (!_(this.get('shared_to')).isEmpty()) { tags.push('shared'); }
          if (!_(this.get('leased_to')).isEmpty()) { tags.push('leased'); }
          tags.push(this.get('leased_to_org_slug'));
          if (this.get('type')) { tags.push(this.get('type_slug')); }
          tags = _(tags).union(this.get('shared_to_org_slugs'), this.get('sensors_slugs'));
          return tags;
        }
      },
      search_text: {
        transient: true,
        get() {
          return _([this.get('serial'), this.get('name'), this.get('description')]).union(this.get('sensors_slugs'), this.get('type_slug')).join(' ').toLowerCase();
        }
      }
    };
  }

  urlRoot() {
    return apiBridge.getChannel().request('get:instance').nodesUrl();
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.onSave) {
      return _.pick(json, 'name', 'description', 'lat', 'lng', 'devices', 'interval', 'serial', '_type', 'user_metadata');
    }
    return json;
  }

  parse(resp) {
    if (resp[0]) { resp = resp[0]; }
    resp.devices = _(resp.devices || []).groupBy((device) => {
      if (_.endsWith(device._id, ':0')) {
        return 'thiamis';
      } return 'sensors';
    });
    return resp;
  }

  sync(method, model, options) {
    if (method === 'read') {
      options.data = _.defaults(options.data || {}, { includes: ['profiles', 'configuration'], node_id: this.id, type: 'thiamis' });
      options.url = apiBridge.getChannel().request('get:instance').batchFetchCurrentDevicesUrl();
      options.type = 'POST';
    }
    if ((method === 'update') && this.isCreatable()) {
      options.type = 'POST';
      options.url = apiBridge.getChannel().request('get:instance').nodesUrl();
    }

    return super.sync(method, model, options);
  }

  delete() {
    if (!this.isDeletable()) { return; }
    this.trigger('request', null, this, {});
    return this.sync('delete', this, { silent: true,
      success: (resp) => {
        resp.last_online = null;
        resp = _.defaults(resp, this.defaults);
        this.set(resp, { parse: true });
        return this.trigger('sync', resp, this, {});
      }
    });
  }

  getDefaultDataPoints() {
    if (this.defaultsDataPointsColl) { return this.defaultsDataPointsColl; }
    this.defaultsDataPointsColl = new FilteredCollection(this.getDataPoints());
    this.defaultsDataPointsColl.filterBy((dp) => dp.isDefault() || dp.isRaw() || dp.isActiveError());
    return this.defaultsDataPointsColl;
  }

  getNewFormModel() {
    const formModel = new this.constructor(this.toJSON());
    if (this.isSubscribable) {
      formModel.getDataPoints = () => this.getDataPoints();
    }
    formModel.isFormModel = true;
    return formModel;
  }

  getReportingInterval() {
    const interval = this.get('interval');
    return interval ? (interval + 60000) : Config.MIN_REPORTING_INTERVAL;
  }

  hasConfigurationKey(attr) {
    const configuration = __guard__(this.getDevice(), (x) => x.get('configuration'));
    if (!configuration) { return false; }
    return !_.isUndefined(configuration[attr]);
  }

  presentName() {
    let presentName = this.get('serial');
    if (this.get('name')) { presentName += ` - ${this.get('name')}`; }
    return presentName;
  }

  seenByOrg(org) {
    const orgId = org.id;
    return (this.get('organization_id') === orgId)
    || (this.get('leased_to') === orgId)
    || _.contains(this.get('shared_to'), orgId);
  }

  getDataPointsBySensor(sensor) {
    return this.getDataPoints().filter((dataPoint) => sensor.id === dataPoint.get('device_id'));
  }

  getDevice() {
    return __guardMethod__(this.get('devices'), 'get', (o) => o.get('thiamis'));
  }

  getSensors() {
    return __guardMethod__(this.get('devices'), 'get', (o) => o.get('sensors'));
  }

  hasDebug() {
    const configuration = __guardMethod__(this.getDevice(), 'get', (x) => x.get('configuration')) || {};
    return configuration.debug_level && configuration.debug_tags;
  }

  getDataPoint(pathId) {
    return this.getDataPoints().getThiamisDataPoint(pathId);
  }

  getBatteryVoltage() {
    return this.getDataPoints().getThiamisBatteryVoltage();
  }

  getSignal() {
    return this.getDataPoints().getThiamisSignal();
  }

  hasWifiGpsSource() {
    return __guard__(this.getDataPoints().getThiamisGpsSource(), (x) => x.isWifiSource());
  }

  getLocation() {
    return this.getDataPoints().getThiamisLocation();
  }

  getLastUpdatedTs() {
    return this.getDataPoints().getLastUpdate();
  }

  isLocationExpired() {
    if (!_.isEmpty(this.get('location_manual'))) { return false; }
    return this.getDataPoints().find((dp) => dp.isGpsError());
  }

  isLocationDetermined() {
    return !!((this.getLocation() && this.isLocationExpired()) || (this.get('latitude') && this.get('longitude')));
  }

  isReporting() {
    if (this.isActivated()) {
      const online = this.getDataPoint(DataPoint.PATHS.ONLINE);
      if (online) {
        return online.getLastMeasurement();
      }
      return this.isDeviceReporting(this.get('last_online'), Config.MIN_REPORTING_INTERVAL);
    }
    return false;
  }

  fetchAvailableDevices() {
    const json = _.pick(this.toJSON({ savedByForm: true }), '_id', 'organization_id', 'serial', 'devices');
    return $.ajax(apiBridge.getChannel().request('get:instance').availableDevicesUrl(), {
      type: 'PUT',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(json)
    }
    );
  }

  hasDevice(id) {
    return _.contains(_(this.get('devices').toJSON({ savedByForm: true })).pluck('_id'), id);
  }

  isAirthinx2() {
    return _.startsWith(this.get('serial'), '1C');
  }

  isAirthinxPro() {
    return _.startsWith(this.get('serial'), '2C');
  }

  isAirthinx() {
    return _.startsWith(this.get('serial'), '0C') || this.isAirthinx2() || this.isAirthinxPro();
  }

  isDriq() {
    return _.startsWith(this.get('serial'), 'D0');
  }

  isFluidmatix() {
    return _.startsWith(this.get('serial'), 'A0');
  }

  isHealthway() {
    return _.startsWith(this.get('serial'), 'A1');
  }

  isHeraeus() {
    return _.startsWith(this.get('serial'), '0E');
  }
}

export class Thiamises extends Nodes {
  get model() { return Thiamis; }

  url() {
    return apiBridge.getChannel().request('get:instance').batchFetchCurrentDevicesUrl();
  }

  sync(method, model, options = {}) {
    if (method === 'read') {
      if (this.isPending()) { this._fetch.abort(); }
      options.data = _.defaults(options.data || {}, { type: 'thiamis' });
    }
    return super.sync(method, model, options);
  }

  isPending() {
    return ((this._fetch != null ? this._fetch.readyState : undefined) > 0) && ((this._fetch != null ? this._fetch.readyState : undefined) < 4);
  }

  getByDataPointId(dpId) {
    return this.find((thiamis) => thiamis.getDataPoints().get(dpId));
  }

  getDataPointById(dpId) {
    return __guard__(this.getByDataPointId(dpId), (x) => x.getDataPoints().get(dpId));
  }

  getByNodeIds(nodeIds) {
    if (nodeIds == null) { nodeIds = []; }
    return this.filter((thiamis) => _.contains(nodeIds, thiamis.id));
  }
}

export class ThiamisNavsMultiple extends MultiChooser(Thiamises) {
  noneChosen() {
    return !this.some((m) => m.isChosen());
  }
}

export class ManageThiamis extends AppModel {
  get defaults() {
    return {
      organization_id: null,
      payment: null
    };
  }

  get validation() {
    return {
      organization_id(value, attr, computedAttrs) {
        if (_.contains(['share', 'lease', 'remove_share', 'transfer'], computedAttrs.type)) {
          return Backbone.Validation.validators.required(value, attr, 'organization_id', this, computedAttrs);
        }
      }
    };
  }

  isTransfer() {
    return this.get('type') === 'transfer';
  }
}

export const API = MnObject.extend({
  channelName: 'entities:thiamis',
  radioRequests: {
    'fetch:thiamises': 'fetchThiamises',
    'fetch:multi:choosable:thiamises': 'fetchThiamisesMultiNavs',
    'new:thiamises': 'newThiamises',
    'new:thiamis': 'newThiamis',
    'fetch:thiamis': 'getThiamisEntity',
    'fetch:subscribable:thiamis': 'getSubscribableThiamisEntity',
    'get:multi:choosable:thiamises': 'getThiamisMultiNavs'
  },

  fetchThiamises(options = {}) {
    const thiamises = new Thiamises();
    thiamises.fetch(options);
    return thiamises;
  },

  fetchThiamisesMultiNavs(options = {}) {
    const thiamises = new ThiamisNavsMultiple();
    thiamises.fetch(options);
    return thiamises;
  },

  newThiamises(thiamises, options = {}) {
    return new Thiamises(thiamises, options);
  },

  newThiamis(attrs, options = {}) {
    return new Thiamis(attrs, options);
  },

  newThiamisesShared(thiamises, options = {}) {
    // eslint-disable-next-line no-undef
    return new ThiamisesShared(thiamises, options);
  },

  getThiamisEntity(id, options = {}, fetchOptions = {}) {
    const thiamis = new Thiamis({ _id: id }, options);
    thiamis.fetch(
      _.defaults(fetchOptions, { reset: true }));
    return thiamis;
  },

  getSubscribableThiamisEntity(id) {
    return this.getThiamisEntity(id, { isSubscribable: true }, { data: { last: 1 } });
  },

  getThiamisMultiNavs(thiamisesColl) {
    return new ThiamisNavsMultiple(thiamisesColl);
  }
});

export const api = new API();
