import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field as FinalField, useForm, useFormState } from 'react-final-form';
import { Flex } from '~/components/layout';
import Group from '~/components/group';
import Select from '~/components/form/select';
import {
  getOrganizationsForDropdown,
  getDevicesForDropdown
} from '../selectors';

const Devices = () => {
  const form = useForm();
  const { values: { organization: organizationValue, devices: devicesValue } } = useFormState();

  const organizations = useSelector((state) => getOrganizationsForDropdown(state));
  const devices = useSelector((state) => getDevicesForDropdown(state, organizationValue && organizationValue.value));

  useEffect(() => {
    // When selected organization changes, devices should be reset
    form.change('devices', []);
  }, [form, organizationValue]);

  useEffect(() => {
    // When selected device list changes, parameters are reset
    form.change('params', {});
  }, [form, devicesValue]);

  return (
    <Group title="Devices" initState>
      <Flex>
        <FinalField name="organization">
          {
              ({ input }) => {
                if (!organizations || (organizations.length === 1)) {
                  return null;
                }
                return (
                  <div style={{ width: 250, marginRight: 20 }}>
                    <Select
                      label="Organization:"
                      value={input.value}
                      options={organizations}
                      onChange={input.onChange}
                    />
                  </div>
                );
              }
          }
        </FinalField>
        <FinalField name="devices">
          {
              ({ input }) => (
                <div style={{ width: 250 }}>
                  <Select
                    label="Devices:"
                    value={input.value}
                    onChange={input.onChange}
                    options={devices}
                    isMulti
                  />
                </div>
              )
          }
        </FinalField>
      </Flex>
    </Group>
  );
};

export default Devices;
