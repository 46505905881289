/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const fetchFilesRequest = createAction('@@files/FETCH_REQUEST');
export const fetchFilesSuccess = createAction('@@files/FETCH_SUCCESS');
export const fetchFilesFailure = createAction('@@files/FETCH_FAILURE');

export const filterListByOrganizationId = createAction('@@filesList/FILTER_BY_OID');
export const filterListByQuery = createAction('@@filesList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@filesList/CLEAR_FILTERS');
export const filterListByTag = createAction('@@filesList/FILTER_BY_TAG');
export const expandList = createAction('@@filesList/EXPAND_ROW');

const initialFilter = { query: null, tags: [], oid: null };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByOrganizationId](state, { payload }) {
    state.oid = payload;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  },
  [filterListByTag](state, { payload }) {
    state.tags = payload;
  }
});

const idsInitialState = [];
const ids = createReducer(idsInitialState, {
  [fetchFilesSuccess](state, { payload: { result } }) {
    return result;
  }
});

const byIdData = {};
const byId = createReducer(byIdData, {
  [fetchFilesSuccess](state, { payload }) {
    if (payload.entities) {
      return get(payload.entities, 'files', byIdData);
    }
    return state;
  }
});

const isFetched = createReducer(false, {
  [fetchFilesRequest]() {
    return false;
  },
  [fetchFilesFailure]() {
    return false;
  },
  [fetchFilesSuccess]() {
    return true;
  }
});

const filesList = combineReducers({
  filter,
  isFetched,
  ids,
  byId
});

export const getFiles = (state) => state.filesList.byId;
export const getFileById = (state, id) => state.filesList.byId[id];
export const getIds = (state) => state.filesList.ids;
export const getIsFetched = (state) => state.filesList.isFetched;
export const getFilter = (state) => state.filesList.filter;

export default filesList;
