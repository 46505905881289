import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'app/config/i18n';
import ParamInfoIcon from 'app/backbone/lib/react/sensors/paramInfoIcon/paramInfoIcon';

const GsmSignalLevel = (props) => (
  <div className="signal">
    <ParamInfoIcon
      value={props.value}
      displayValue={props.networkName || props.NoGsmNetworkName}
      noValueText={I18n.t('base.labels.n_a')}
      iconClass={`icon-gsm-signal-${props.level}`}
      title={props.title}
    />
  </div>
);

GsmSignalLevel.defaultProps = {
  title: '',
  value: '',
  noValueText: '',
  networkName: '',
  NoGsmNetworkName: ''
};

GsmSignalLevel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  noValueText: PropTypes.string,
  networkName: PropTypes.string,
  NoGsmNetworkName: PropTypes.string,
  level: PropTypes.number.isRequired
};

export default GsmSignalLevel;
