import React from 'react';

const TopBar = () => (
  <div className="content-top profile-content-top">
    <div className="controls">
      <ul className="nav nav-tabs">
        <li className="active">
          <a className="btn-tab" role="tab" type="button">
            <i className="fa fa fa-fw fa-plus" />
            <span>Claim devices</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default TopBar;
