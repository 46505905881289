import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUserOrganizationWithHighestRole, getRolesExceptHighest } from '../selectors';

export const RoleFormatter = ({ row }) => {
  const { t } = useTranslation();
  const { organization, otherRoles } = useSelector(
    (state) => ({
      organization: getUserOrganizationWithHighestRole(state, row?._id),
      otherRoles: getRolesExceptHighest(state, row?._id)
    })
  );
  return (
    <span>
      {t(`users.roles.${organization?.role}_at`, { org_name: organization?.organizationName })}
      {
        !!otherRoles.length && (
          <a>{` + ${otherRoles.length} more`}</a>
        )
      }
    </span>
  );
};
