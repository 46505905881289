var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"col-sm-4 fact-container\">\n  <div class=\"fact total-thiamis\"><span class=\"number\"></span><span class=\"thiamises\">"
    + ((stack1 = __default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"dashboard.total_devices",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":2,"column":86},"end":{"line":2,"column":122}}})) != null ? stack1 : "")
    + "</span><span class=\"icon\"><i class=\"fa fa-fw fa-thiamis-1000-2\"></i></span></div>\n</div>\n<div class=\"col-sm-4 fact-container\">\n  <div class=\"fact thiamis-online\"><span class=\"number\"></span><span>"
    + ((stack1 = __default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"dashboard.devices_online",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":69},"end":{"line":5,"column":106}}})) != null ? stack1 : "")
    + "</span><span class=\"icon\"><i class=\"fa fa-fw fa-node\"></i></span></div>\n</div>\n<div class=\"col-sm-4 fact-container\">\n  <div class=\"fact active-alerts\"><span class=\"number\"></span><span>"
    + ((stack1 = __default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"dashboard.active_alerts",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":8,"column":68},"end":{"line":8,"column":104}}})) != null ? stack1 : "")
    + "</span><span class=\"icon\"><i class=\"fa fa-fw fa-warning\"></i></span></div>\n</div>";
},"useData":true});