import React from 'react';
import { useSelector } from 'react-redux';
import I18n from 'app/config/i18n';
import { getDeviceById } from '~/store/reducers/devices';
import { getDataPointIdByName } from '~/store/selectors/data_points';
import { getLastMeasurementValue } from '~/store/selectors/measurements';
import { getDeviceDisplayName } from '~/store/selectors/devices';
import DeviceTitleContainer from './device_title_container';

const SensorsListItemContainer = ({ id, nodeId }) => {
  const handleEditSensorClick = () => {};
  const {
    serial,
    displayName
  } = useSelector(
    (state) => {
      const sensor = getDeviceById(state, id);
      const dataPoints = getDataPointIdByName(state, nodeId, id);
      const serialMeasurementId = dataPoints('serial');
      const modelMeasurementId = dataPoints('model');
      const model = getLastMeasurementValue(state, modelMeasurementId);
      return {
        tag: sensor.tag,
        serial: getLastMeasurementValue(state, serialMeasurementId),
        displayName: getDeviceDisplayName(state, id, model)
      };
    }
  );
  return (
    <div className="sensor-box-container">
      <div className="sensor-box">
        <div className="row">
          { handleEditSensorClick
            ? (
              <a role="button" className="edit-config" tabIndex={0} onClick={handleEditSensorClick}>
                <i className="fa fa-fw fa-cog" />
              </a>
            ) : undefined }
          <div className="col-sm-7 sensor-caption">
            <DeviceTitleContainer {...{ id, nodeId, displayName }} />
            { serial
              ? (
                <span className="serial">
                  <b>
                    {I18n.t('attributes.sensor.serial')}
                    {' '}
                  </b>
                  {serial}
                </span>
              ) : undefined }
            <br />
          </div>
          <div className="col-sm-5">
            <div className={`sensor-icon ${_.dasherize(displayName?.toLowerCase())}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorsListItemContainer;
