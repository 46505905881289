/* eslint-disable no-nested-ternary */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import BatteryLevel from 'app/backbone/lib/react/sensors/batteryLevel/batteryLevel';
import SignalLevel from 'app/backbone/lib/react/components/lists/thiamises/SignalLevel/SignalLevel';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';
import widgetThiamisInfoParamsTmpl from './templates/widget_thiamis_info_params.hbs';

const ParamsComponent = (props) => {
  const model = props.getModel();
  return (
    <div className="dev-status">
      <BatteryLevel
        model={model.getBatteryVoltage()}
        title={(value) => I18n.t('thiamis.more_info.battery', { value })}
        unit=""
        noValueText={I18n.t('base.labels.n_a')}
      />
      <SignalLevel model={model.getSignal()} />
    </div>
  );
};

ParamsComponent.propTypes = {
  getModel: PropTypes.func.isRequired
};

const ThiamisInfoParams = View.extend({
  template: widgetThiamisInfoParamsTmpl,
  className: 'clearfix thiamis-info-params',

  ui: {
    paramsContainer: '.params-container',
    thiamisImg: '.thiamis-img',
    thiamisSerial: '.thiamis-serial',
    thiamisModel: '.thiamis-model',
    thiamisImei: '.thiamis-imei',
    thiamisIccid: '.thiamis-iccid',
    thiamisFirmware: '.thiamis-firmware',
    thiamisMacAddress: '.thiamis-mac-address'
  },

  bindings() {
    return {
      '@ui.thiamisImg': {
        attributes: [{
          name: 'class',
          observe: 'model',
          onGet(thiamisModel) {
            return _.slugify(thiamisModel);
          }
        }
        ]
      },
      '@ui.thiamisSerial': {
        observe: 'serial',
        onGet() {
          return this.model.get('serial');
        },
        update: this.updateThiamisInfo
      },
      '@ui.thiamisModel': {
        observe: 'model',
        onGet() {
          return this.model.get('model');
        },
        update: this.updateThiamisInfo
      },
      '@ui.thiamisImei': {
        observe: 'imei',
        onGet() {
          return this.model.get('imei');
        },
        update: this.updateThiamisInfo
      },
      '@ui.thiamisIccid': {
        observe: 'iccid',
        onGet() {
          return this.model.get('iccid');
        },
        update: this.updateThiamisInfo
      },
      '@ui.thiamisFirmware': {
        observe: 'firmware',
        onGet() {
          return this.model.get('firmware');
        },
        update: this.updateThiamisInfo
      },
      '@ui.thiamisMacAddress': {
        observe: 'mac_address',
        onGet() {
          return _.macAddress(this.model.get('mac_address'));
        },
        update: this.updateThiamisInfo
      }
    };
  },

  initialize() {
    return this.listenTo(this.model.getDataPoints(), 'change', (dataPoint) => this.model.trigger('change', dataPoint, dataPoint.getLastMeasurement()));
  },

  updateThiamisInfo($el, val) {
    this.getParamContainer($el).toggleClass('hidden', _.isEmpty(val != null ? val.toString() : undefined));
    return $el.text(val);
  },

  getParamContainer($el) {
    return $el.parent();
  },

  _createParamsComponent() {
    return BackboneReactComponent(ParamsComponent);
  },

  _createParamsContainer() {
    return React.createElement(this._createParamsComponent(), _.pick(this, 'model'));
  },

  onRender() {
    return this.stickit();
  },

  onAttach() {
    ReactDOM.render(this._createParamsContainer(), this.ui.paramsContainer[0]);
  }
});

export default ThiamisInfoParams;
