import 'timeago';

(($) => $.timeago.settings.strings = { // eslint-disable-line no-param-reassign
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '<1min',
  minute: '1min',
  minutes: '%dmin',
  hour: '1hr',
  hours: '%dhr',
  day: '1d',
  days: '%dd',
  month: '1mo',
  months: '%dmo',
  year: '1yr',
  years: '%dyr',
  wordSeparator: ' ',
  numbers: []
})(jQuery);
