import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ResultsData = ({ results }) => {
  const { t } = useTranslation();
  return (
    <div className="form-group">
      <label className="control-label">
        {t('claim.result')}
      </label>
      <div className="results-table">
        <Table
          bordered={false}
          striped
        >
          <thead>
            <tr>
              <th>{t('claim.serial')}</th>
              <th>{t('claim.organization')}</th>
              <th>{t('claim.status')}</th>
            </tr>
          </thead>
          <tbody>
            {
              results.map((item) => (
                <tr key={item.serial} className={!item.status ? 'failed' : ''}>
                  <td>{item.serial}</td>
                  <td>{item.organization}</td>
                  <td className="status-column">
                    <svg viewBox="0 0 12 12" width="12" height="12">
                      <circle className="circle" cx="6" cy="6" r="6" />
                    </svg>
                    {item.status ? t('claim.claimed') : t('claim.failed')}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ResultsData;
