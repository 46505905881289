import React from 'react';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import ResendInvitation from './resend_invitation';
import DeleteInvitation from './delete_invitation';
import AcceptInvitation from './accept_invitation';
import DeclineInvitation from './decline_invitation';

export const CreatedAtFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    value && moment(value).isValid()
      ? <TimeAgo date={value} />
      : t('alerts.triggered_never')
  );
};

const UserRoleFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    <span>
      {capitalize(t(`users.roles.${value}`))}
    </span>
  );
};

export const ActionsFormatter = ({ row }) => {
  const { _id: id, incoming, link } = row;

  return (
    <div className="actions">
      {
        incoming ? (
          <>
            <AcceptInvitation id={id} />
            <DeclineInvitation id={id} />
          </>
        ) : (
          <>
            <ResendInvitation id={id} link={link} />
            <DeleteInvitation id={id} />
          </>
        )
      }
    </div>
  );
};

const EmailFormatter = ({ row: { incoming }, value }) => (
  <span style={incoming ? { fontWeight: 'bold' } : {}}>
    {value}
  </span>
);

export const CreatedAtProvider = (props) => (
  <DataTypeProvider
    formatterComponent={CreatedAtFormatter}
    {...props}
  />
);

export const ActionsProvider = (props) => (
  <DataTypeProvider
    formatterComponent={ActionsFormatter}
    {...props}
  />
);

export const UserRoleProvider = (props) => (
  <DataTypeProvider
    formatterComponent={UserRoleFormatter}
    {...props}
  />
);

export const EmailProvider = (props) => (
  <DataTypeProvider
    formatterComponent={EmailFormatter}
    {...props}
  />
);
