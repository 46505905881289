import React from 'react';
import RModal from 'react-modal';

RModal.setAppElement('#root');

const Modal = ({
  title, handleCancel, isVisible, children
}) => (
  <RModal
    isOpen={isVisible}
    onRequestClose={handleCancel}
    contentLabel={title}
    className="react-modal-dialog modal-dialog"
    overlayClassName="react-modal-overlay"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" onClick={handleCancel}>×</button>
        <h4 className="modal-title">
          <i className="fa fa-md" />
          <span>{title}</span>
        </h4>
      </div>
      <div className="modal-body">
        {children}
      </div>
    </div>
  </RModal>
);

export default Modal;
