import React from 'react';
import { useTranslation } from 'react-i18next';

const Toolbar = () => {
  const { t } = useTranslation();
  return (
    <div className="dropdown">
      <div data-toggle="dropdown" href="#" className="fact dropdown-toggle">
        <span className="number add-number">+</span>
        <span>{t('dashboard.add_widget')}</span>
      </div>
      <div className="row dropdown-menu">
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.chart_widget')}" className="new_chart_widget">
            <i className="fa fa-fw fa-line-chart" />
          </a>
        </div>
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.node_widget')}" className="new_node_widget">
            <i className="fa fa-fw fa-list" />
          </a>
        </div>
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.map_widget')}" className="new_map_widget">
            <i className="fa fa-fw fa-map" />
          </a>
        </div>
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.blocks_widget')}" className="new_blocks_widget">
            <i className="fa fa-fw fa-th-large" />
          </a>
        </div>
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.text_widget')}" className="new_text_widget">
            <i className="fa fa-fw fa-file-text-o" />
          </a>
        </div>
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.twitter_widget')}" className="new_twitter_widget">
            <i className="fa fa-fw fa-twitter" />
          </a>
        </div>
        <div className="col-sm-2">
          <a role="menuitem" title="{t('models.heatmap_widget')}" className="new_airthinx_widget">
            <i className="fa fa-fw fa-table" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
