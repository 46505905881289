import { datadogRum as datadog } from '@datadog/browser-rum';
import { MnObject } from 'backbone.marionette';
import 'app/backbone/config/datadog';

export const API = MnObject.extend({
  channelName: 'datadog',

  radioRequests: {
    'datadog:analytics:identify': 'analyticsIdentify',
    'datadog:analytics:track': 'analyticsTrack',
    'datadog:analytics:page': 'analyticsPage'
  },

  analyticsIdentify(user) {
    datadog.setUser(user.pick('id', 'user_id', 'email'));
  },

  analyticsTrack(event, props) {
    datadog.addAction(event, props);
  },

  analyticsPage(pageName) {
    datadog.startView(pageName);
  }
});

export const api = new API();
