import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { app as App } from 'app/backbone/app';
import ProfileForm from '~/components/form/profile_form';
import { getUsersIsSyncing } from '~/store/reducers/users';
import { getUserByIdUserMetadata } from '~/store/selectors/users';

const UserProfileForm = ({ syncing, metadata }) => {
  const handleCancel = useCallback(() => {
    App.navigate('users');
  }, []);

  return (
    <ProfileForm
      onlyView
      syncing={syncing}
      metadata={metadata}
      withImageUploader={false}
      onCancel={handleCancel}
    />
  );
};

const mapStateToProps = (state, { userId }) => ({
  syncing: getUsersIsSyncing(state),
  metadata: getUserByIdUserMetadata(state, userId)
});

export default connect(mapStateToProps)(UserProfileForm);
