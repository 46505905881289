import React, { useState, useCallback } from 'react';
import Tabs, { TABS, ApiTab, WebhooksTab } from './tabs';
import CreateAPIToken from './create_api_token';
import CreateWebhook from './create_webhook';

export default function FiltersFormTop({ activeTab, setTab }) {
  const [createActive, setCreateActive] = useState(false);
  const toggleCreateActive = useCallback(() => setCreateActive((b) => !b), [setCreateActive]);

  return (
    <div className="content-top">
      <Tabs activeTab={activeTab} setTab={setTab} />
      { activeTab === TABS.API && (
        <>
          <ApiTab onClick={toggleCreateActive} />
          <CreateAPIToken isVisible={createActive} toggleModal={toggleCreateActive} />
        </>
      )}
      { activeTab === TABS.WEBHOOKS && (
        <>
          <WebhooksTab onClick={toggleCreateActive} />
          <CreateWebhook isVisible={createActive} toggleModal={toggleCreateActive} />
        </>
      )}
    </div>
  );
}
