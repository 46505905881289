import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  fetchNodesRequest
} from '~/store/reducers/nodes';
import { getNodeByIdDisplayName } from '~/store/selectors/nodes';

import DeleteWebhook from './delete_webhook';

const NodeDisplayName = ({ id }) => {
  const deviceName = useSelector((state) => getNodeByIdDisplayName(state, id));

  return (<span>{deviceName}</span>);
};

const GridDetailRow = ({ row }) => {
  const {
    conditions: { node_id: nodeIds, _type }
  } = row;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (nodeIds) {
      dispatch(fetchNodesRequest({
        data: {
          nodeIds
        }
      }));
    }
  }, [nodeIds, dispatch]);

  return (
    <div className="tr more-info">
      <div colSpan="1" className="subscriptions-list td">
        <ul className="keywords">
          <li>
            <b>X-Netronix-Auth</b>
            <br />
            <code>{row.auth}</code>
          </li>
          <li>
            <b>{t('webhooks.conditions')}</b>
            <br />
            {
              isEmpty(nodeIds)
                ? t('base.labels.all')
                : (
                  <ul>
                    {nodeIds.map((id) => (
                      <li><NodeDisplayName id={id} /></li>
                    ))}
                  </ul>
                )
            }
          </li>
          <li>
            <b>{t('attributes.webhook.type')}</b>
            <br />
            {isEmpty(_type) ? t('base.labels.all') : _type}
          </li>
        </ul>
      </div>
      <div className="actions-col td">
        <DeleteWebhook
          id={row._id}
        />
      </div>
    </div>
  );
};

export default GridDetailRow;
