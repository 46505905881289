import React, { useRef, useMemo, useCallback } from 'react';
import ReactSelect from 'react-select';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';
import colors from '~/theme/colors';

const Select = ({
  value, onChange, hasError, allowSelectAll, options, ...rest
}) => {
  const { t } = useTranslation();
  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = useMemo(() => ({
    value: '<SELECT_ALL>',
    label: t('base.labels.all')
  }), [t]);

  const isSelectAllSelected = useCallback(() => valueRef.current.length === options.length, [options.length, valueRef]);

  const isOptionSelected = useCallback((option) => {
    if (!valueRef.current) {
      return false;
    }
    const isCurrent = isArray(valueRef.current) ? valueRef.current.some((item) => item.value === option.value) : valueRef.current.value === option.value;
    return isCurrent || (allowSelectAll && isSelectAllSelected());
  }, [valueRef, allowSelectAll, isSelectAllSelected]);

  const memoOptions = useMemo(() => {
    if (allowSelectAll) {
      return [selectAllOption, ...options];
    }
    return options;
  }, [allowSelectAll, selectAllOption, options]);

  const memoValue = useMemo(() => {
    if (allowSelectAll) {
      if (!value || !value.length) {
        return value;
      }
      return isSelectAllSelected() ? [selectAllOption] : value;
    }
    return value;
  }, [allowSelectAll, value, selectAllOption, isSelectAllSelected]);

  const handleOnChange = useCallback((newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    const selectedOption = option || newValue;
    if (action === 'select-option' && selectedOption.value === selectAllOption.value) {
      onChange(options, actionMeta);
    } else if (
      (action === 'deselect-option'
        && selectedOption.value === selectAllOption.value)
      || (action === 'remove-value'
        && removedValue.value === selectAllOption.value)
    ) {
      onChange([], actionMeta);
    } else if (
      actionMeta.action === 'deselect-option'
      && isSelectAllSelected()
    ) {
      onChange(
        options.filter((item) => item.value !== selectedOption.value),
        actionMeta
      );
    } else {
      onChange(newValue || [], actionMeta);
    }
  }, [options, onChange, selectAllOption, isSelectAllSelected]);

  return (
    <ReactSelect
      {...rest}
      isOptionSelected={isOptionSelected}
      value={memoValue}
      options={memoOptions}
      onChange={handleOnChange}
      styles={{
        menu: (provided) => ({
          ...provided, zIndex: 9999, background: 'white', marginLeft: 2
        }),
        menuList: (provided) => ({ ...provided, maxHeight: 150 }),
        control: (provided) => ({
          ...provided,
          borderColor: hasError ? colors.redColor : colors.inputBorder
        }),
        singleValue: (provided, state) => ({ ...provided, color: state.isDisabled ? colors.disabledGreyColor : colors.greyColor })
      }}
    />
  );
};

export default Select;
