/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import AppModel from 'app/backbone/lib/entities/app_model';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import ValueLabelPairs from 'app/backbone/lib/concerns/entities/value_label_pairs';

export class ProjectModel extends AppModel {
  get idAttribute() { return 'id'; }

  presentName() {
    return this.get('name');
  }
}

export default class ProjectsCollection extends ClusternetBridge(ValueLabelPairs(AppCollection)) {
  get model() { return ProjectModel; }

  url() { return this.clusternet.projectsUrl(); }
}

export const API = MnObject.extend({
  channelName: 'entities:projects',
  radioRequests: {
    'fetch:projects': 'fetchProjectsEntity'
  },

  fetchProjectsEntity() {
    const projects = new ProjectsCollection();
    return projects;
  }
});

export const api = new API();
