/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import { api as thiamisAppApi } from 'app/backbone/apps/thiamises/thiamises_app';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import widgetNodeTitleTemplate from './templates/widget_node_title.hbs';

const WidgetNodeTitle = View.extend({
  template: widgetNodeTitleTemplate,
  className: 'pull-left',
  triggers:
    { click: 'node:title:click' },

  bindings: {
    a: {
      observe: 'name',
      attributes: [{
        observe: '_id',
        name: 'href',
        onGet(nodeId) {
          return `thiamis/${nodeId}/monitor`;
        }
      },
      {
        observe: '_id',
        name: 'title',
        onGet() {
          return I18n.t('widgets.open_monitor_page');
        }
      }]
    }
  },

  onNodeTitleClick() {
    return thiamisAppApi.getChannel().request('app:devices:monitor', this.model.id);
  },

  onRender() {
    this.stickit();
  }
});

export const WidgetNodesTitle = AppCollectionView.extend({
  template: false,
  className: 'clearfix',
  childView: WidgetNodeTitle
});

export default WidgetNodeTitle;
