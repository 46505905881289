import React from 'react';
import classNames from 'classnames';

const NavButton = (props) => {
  const { onClick, disabled, iconClass } = props;

  return (
    <a role="button" style={{ paddingTop: '9px' }} className={classNames('btn btn-default', { disabled })} onClick={onClick}>
      <i className={classNames('fa fa-md fa-fw', iconClass)} />
    </a>
  );
};

export default NavButton;
