/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppRouter from 'app/backbone/lib/routers/app_router';
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';

class Router extends AppRouter {
  initialize({ controller }) {
    this.controller = controller;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'api');
  }
}

const controller = {};

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:api': 'listApi'
  },

  listApi() {
    Router.prototype.before();
    App.navigate('api');
    App.getChannel().request('enable:org_filter', true);
  }
});

export const api = new API();

const ApiApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default ApiApp;
