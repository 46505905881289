var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"highcharts-region fill\">\n\n</div>\n<div class=\"control-icons\">\n  <a data-toggle=\"tooltip\" data-placement=\"right\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"chart_widget.ordinal_title",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":57},"end":{"line":5,"column":94}}}))
    + "\" class=\"toggle-ordinal-btn condensed\">\n    <i class=\"gap-toggle-icon\"></i>\n  </a>\n  <a data-toggle=\"tooltip\" data-placement=\"right\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"chart_widget.group_ungroup_title",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":100}}}))
    + "\" class=\"group-btn hidden\">\n    <i class=\"fa fa-fw\"></i>\n  </a>\n</div>\n";
},"useData":true});