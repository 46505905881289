import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  select,
  all
} from 'redux-saga/effects';
import requestApi from './requestApi';
import * as Actions from '~/store/reducers/users';
import { getUserByIdUserId } from '~/store/selectors/users';
import { normalizeUsersWithTotalsResp, normalizeResp } from '~/store/normalizr';
import * as schema from '~/store/schema';

export function* usersFetchRequestSaga({ payload = {} }) {
  try {
    const data = yield call(
      requestApi,
      'get',
      'users',
      payload.data
    );
    const response = normalizeUsersWithTotalsResp(data, schema.usersSchema);
    yield put(Actions.fetchUsersSuccess(response));
  } catch (e) {
    yield put(Actions.fetchUsersFailure(e.toString()));
  }
}

function* watchFetchUsersRequest() {
  yield takeLatest(Actions.fetchUsersRequest.toString(), usersFetchRequestSaga);
}

export function* userFetchRequestSaga({ payload: userId }) {
  try {
    const data = yield call(
      requestApi,
      'get',
      `users/${userId}`
    );
    const response = normalizeResp(data, schema.userSchema);
    yield put(Actions.fetchUserSuccess(response));
  } catch (e) {
    yield put(Actions.fetchUserFailure(e.toString()));
  }
}

function* watchFetchUserRequest() {
  yield takeEvery(Actions.fetchUserRequest.toString(), userFetchRequestSaga);
}

export function* userUpdateRequestSaga({ payload }) {
  try {
    const { userId, data, onSuccess } = payload;
    const id = yield select(getUserByIdUserId, userId);
    const response = yield call(
      requestApi,
      'put',
      `users/${id}`,
      data
    );
    if (onSuccess) {
      onSuccess();
    }
    yield put(Actions.fetchUserSuccess(normalizeResp(response, schema.userSchema)));
  } catch (e) {
    yield put(Actions.updateUserFailure());
  }
}

function* watchUpdateUserRequest() {
  yield takeEvery(Actions.updateUserRequest.toString(), userUpdateRequestSaga);
}

export function* userDeleteRequestSaga({ payload }) {
  const { onSuccess, userId } = payload;
  try {
    const id = yield select(getUserByIdUserId, userId);
    yield call(
      requestApi,
      'delete',
      `users/${id}`
    );
    yield put(Actions.destroyUserSuccess(userId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.destroyUserFailure(userId));
  }
}

function* watchDeleteUserRequest() {
  yield takeEvery(Actions.destroyUserRequest.toString(), userDeleteRequestSaga);
}

export function* userDeletePermissionsRequestSaga({ payload }) {
  const {
    onSuccess, userId, externalIds
  } = payload;
  try {
    yield all(externalIds.map((externalId) => call(
      requestApi,
      'delete',
      `users/${userId}/permissions/${externalId}`,
    )));
    yield put(Actions.fetchUserRequest(userId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.destroyUserFailure(userId));
  }
}

function* watchDeleteUserPermissionsRequest() {
  yield takeEvery(Actions.deleteUserPermissionsRequest.toString(), userDeletePermissionsRequestSaga);
}

export default function* app() {
  yield fork(watchDeleteUserRequest);
  yield fork(watchDeleteUserPermissionsRequest);
  yield fork(watchFetchUserRequest);
  yield fork(watchFetchUsersRequest);
  yield fork(watchUpdateUserRequest);
}
