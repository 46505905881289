/* eslint-disable no-param-reassign */
import { MnObject } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import AssociatedModel from 'app/backbone/lib/entities/associated_model';
import { app as App } from 'app/backbone/app';
import { Node, Nodes } from 'app/backbone/entities/nodes/node';
import { api as apiBridge } from 'app/backbone/lib/clusternet/bridge';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import { AlertStreamSubscribable } from 'app/backbone/lib/concerns/entities/measurement_updatable';
import { Chooser, MultiChooser } from 'app/backbone/lib/concerns/entities/chooser';
import { AlertDataPoint, AlertDataPointsColl } from 'app/backbone/entities/data_points';
import DataPointConditions, { RefDataPointConditions, DataPointCondition, RefDataPointCondition } from 'app/backbone/entities/data_point_condition';
import { UsersCollection } from 'app/backbone/entities/user';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import { Thiamises } from 'app/backbone/entities/nodes/thiamis';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import { __guard__ } from 'app/utils/custom-fns';

export class Alert extends ClusternetBridge(AlertStreamSubscribable(Chooser(Node))) {
  get transientAttrs() { return ['actions', 'last_measurements', 'status_type']; }
  static STATUS = {
    IDLE: 'idle',
    RESOLVED: 'resolved',
    ACTIVE: 'active',
    DEACTIVATED: 'deactivated'
  }

  static REL = { OR: 'OR', AND: 'AND' }

  get relations() {
    return [
      {
        type: Backbone.One,
        key: 'subscribers',
        relatedModel: AssociatedModel
      },
      {
        type: Backbone.Many,
        key: 'data_points',
        collectionType: AlertDataPointsColl,
        relatedModel: AlertDataPoint
      },
      {
        type: Backbone.Many,
        key: 'references',
        collectionType: RefDataPointConditions,
        isTransient: true
      }
    ];
  }

  get defaults() {
    return {
      _type: 'alert',
      organization_id: null,
      subscribers: {
        email: [],
        sms: []
      },
      data_points: [],
      last_measurements: 100
    };
  }

  get mutators() {
    return {
      filter_tags: {
        transient: true,
        get() {
          return [this.get('status_type')];
        }
      },
      search_text: {
        transient: true,
        get() {
          return [this.get('name'), this.get('description')].join(' ').toLowerCase();
        }
      },
      status_type: {
        transient: true,
        get() {
          if (this.get('status') === Alert.STATUS.DEACTIVATED) {
            return Alert.STATUS.DEACTIVATED;
          }
          return this.getStatusType(__guard__(this.getLastMeasurement(), (x) => x[1]));
        }
      },
      last_triggered_ts: {
        transient: true,
        get() {
          return __guard__(this.getLastMeasurement(), (x) => x[0]);
        }
      },
      linked_data_point_ids: {
        transient: true,
        get() {
          return _(this.get('data_points').pluck('linked')).chain().flatten().compact()
            .uniq()
            .value();
        }
      },
      timezone: {
        transient: true,
        get() {
          const thiamisWithTz = this.getAttachedNodes().find((thiamis) => !_.isEmpty(thiamis.get('timezone')));
          return (thiamisWithTz != null ? thiamisWithTz.get('timezone') : undefined) || App.getChannel().request('get:user:timezone');
        }
      }
    };
  }

  validation() {
    return {
      name: {
        required: true,
        maxLength: 30
      },
      description: {
        required: true,
        maxLength: 50
      },
      organization_id: {
        required: true
      },
      data_points() {
        const conditions = _.filter(this.getConditions(), (cond) => !cond.isNew() && !cond.has('_destroy'));
        if (_.isEmpty(conditions)) { return I18n.t('alerts.validation.conditions'); }
        if (_.some(conditions, (cond) => cond.isChosen())) { return I18n.t('alerts.validation.some_editing'); }
      }
    };
  }

  fetch(options) {
    if (options == null) { options = {}; }
    $.when(this.sync('read', this, _.extend(options, { deferred: true }))).done(([alert]) =>
      $.when(this.fetchThiamises(alert), this.fetchUsers(alert))
        .done(([thiamises], [users]) => {
          this.getUsers().reset(users, { parse: true });
          this.getThiamises().reset(thiamises);
          this.set(_.extend(this.parse(alert)), { parse: true });
          this.getDeferred().resolve();
          return this.trigger('sync', this, alert, {});
        })
        .fail(() => {
          if (options != null ? options.stopCatch : undefined) {
            this.trigger('error sync', this, {}, {});
          }
        })
    );
    return this.getDeferred().promise();
  }

  parse(resp) {
    if (resp.data_points.length > 1) {
      resp.relation = Alert.REL.OR;
    } else if (__guard__(resp.data_points[0] != null ? resp.data_points[0].conditions : undefined, (x) => x.and)) {
      resp.relation = Alert.REL.AND;
    }
    return resp;
  }

  save(attrs, options) {
    attrs.data_points = _.map(attrs.data_points, (dataPoint) => {
      if (dataPoint._id) {
        if (JSON.stringify(this.get('data_points').get(dataPoint._id).toJSON({ savedByForm: true })) !== JSON.stringify(dataPoint)) {
          return _.omit(dataPoint, '_id');
        }
      }
      return dataPoint;
    });
    options = _.extend(options, { attrs }, { silent: true });
    return super.save(null, options);
  }

  sync(method, model, options) {
    if (method === 'read') {
      options.data = _.defaults(options.data || {}, { node_id: this.id, last: 3, includes: 'data_points' });
      options.url = apiBridge.getChannel().request('get:instance').batchFetchCurrentDevicesUrl();
      options.type = 'POST';
    }

    return super.sync(method, model, options);
  }

  getNewFormModel() {
    const formModel = new this.constructor(this.toJSON());
    formModel.isFormModel = true;
    return formModel;
  }

  isAND() {
    return this.get('relation') === Alert.REL.AND;
  }

  getDeferred() {
    return this.dfd || (this.dfd = $.Deferred());
  }

  seenByOrg(org) {
    return this.get('organization_id') === org.id;
  }

  presentName() {
    return this.get('name');
  }

  isActive() {
    return this.get('status_type') === Alert.STATUS.ACTIVE;
  }

  getAttachedNodes() {
    if (this.attachedNodes) { return this.attachedNodes; }
    this.attachedNodes = new FilteredCollection(this.getThiamises());
    this.attachedNodes.filterBy('attached_nodes', (thiamis) => {
      if (_.some(this.get('linked_data_point_ids'), (dataPointId) => thiamis.getDataPoints().get(dataPointId))) { return true; }
    });
    return this.attachedNodes;
  }

  getThiamises() {
    return this.nodes != null ? this.nodes : (this.nodes = new Thiamises());
  }

  getUsers() {
    return this.users != null ? this.users : (this.users = new UsersCollection());
  }

  fetchThiamises(alert) {
    let params = _.extend({ permissions: 'read', last: 1, includes: ['profiles'] }, _.pick(alert, 'organization_id'));
    if (this.isSubscribable) {
      params = _.extend(params, { node_id: alert.attached_nodes });
    }
    return this.getThiamises().fetch({
      data: params,
      reset: true
    });
  }

  fetchUsers(alert) {
    return this.getUsers().fetch({
      data: _.pick(alert, 'organization_id'),
      traditional: true,
      parse: true,
      reset: true
    });
  }

  getLastMeasurement() {
    let measurements = [];
    this.get('data_points').map((dp) => measurements = measurements.concat(dp.get('measurements')));
    measurements = _.sortBy(measurements, (m) => (m != null ? m[0][0] : undefined));
    if (_.isEmpty(measurements)) { return; }
    return _.last(measurements);
  }

  getStatusType(measurement) {
    switch (measurement) {
      case true: return Alert.STATUS.ACTIVE;
      case false: return Alert.STATUS.RESOLVED;
      default: return Alert.STATUS.IDLE;
    }
  }

  getDataPointsByRelation() {
    const conditions = this.getConditions();
    if (this.isAND()) {
      return new AlertDataPointsColl([
        { conditions }
      ]);
    }
    // eslint-disable-next-line no-shadow
    return new AlertDataPointsColl(_.map(conditions, (conditions) => ({ conditions })));
  }

  getConditions() {
    return _.flatten(this.getConditionDataPoints().map((dp) => dp.get('conditions').models));
  }

  addEmptyCondition() {
    if (_.isEmpty(this.get('relation')) && this.getConditions().length) { return; }

    const condition = new DataPointCondition({
      relation: this.get('relation') });
    if (this.get('data_points').isEmpty()) {
      this.get('data_points').add(new AlertDataPoint({
        conditions: new DataPointConditions(condition),
        _type: 'alert'
      })
      );
    } else if (_.isEmpty(this.get('relation')) || this.isAND()) {
      const dataPoint = this.get('data_points').first();
      dataPoint.get('conditions').add(condition);
    } else {
      this.get('data_points').add(new AlertDataPoint({
        conditions: new DataPointConditions(condition) })
      );
    }
    return condition.choose();
  }

  addEmptyReference() {
    const condition = new RefDataPointCondition();
    this.getRefDataPointConditions().add(condition);
    return condition.choose();
  }

  getFilteredConditions() {
    if (this.filteredConditions) { return this.filteredConditions; }
    this.filteredConditions = new AppCollection(this.getConditions());
    return this.filteredConditions;
  }

  getRefDataPointConditions() {
    if (this.refConditions) { return this.refConditions; }
    const refs = _.flatten(
      this.get('data_points').map((dp) => dp.get('conditions').map((condition) => this.getConditionRefs(__guard__(condition.get('references'), (x) => x.models))))
    );
    this.refConditions = new RefDataPointConditions(_.map(refs, (ref) => ref.toJSON()), { alert: this });
    this.set('references', this.refConditions);
    return this.refConditions;
  }

  getConditionRefs(refs, accu) {
    if (refs == null) { refs = []; }
    if (accu == null) { accu = []; }
    _.map(refs, (ref) => {
      accu.push(ref);
      if (ref.get('references').size()) {
        return this.getConditionRefs(ref.get('references').models, accu);
      }
    });
    return accu;
  }

  getConditionDataPoints() {
    if (this.conditionDataPoints) { return this.conditionDataPoints; }
    this.conditionDataPoints = new FilteredCollection(this.get('data_points'));
    this.conditionDataPoints.filterBy('has_conditions', (dp) => dp.get('conditions') && !dp.get('conditions').isEmpty());
    return this.conditionDataPoints;
  }

  getXhrs() {
    if (this.isNew()) { return []; }
    const xhrs = [this.getDeferred()];
    if (this.isSubscribable) { xhrs.push(this.getNodeDataStream().getDeferred()); }
    return xhrs;
  }
}

export class Alerts extends Nodes {
  get model() { return Alert; }

  sync(method, model, options) {
    if (method === 'read') {
      options.data = _.defaults(options.data || {}, { type: 'alert', last: 3 });
    }

    return super.sync(method, model, options);
  }
}

export class AlertMultiNavs extends MultiChooser(Alerts) {
  noneChosen() {
    return !this.some((m) => m.isChosen());
  }
}

export const API = MnObject.extend({
  channelName: 'entities:alert',
  radioRequests: {
    'fetch:alerts': 'fetchAlerts',
    'fetch:multi:choosable:alerts': 'fetchAlertsMultiNavs',
    'new:alerts': 'newAlerts',
    'new:alert': 'newAlert',
    'get:alert': 'getAlertEntity',
    'get:subscribable:alert': 'getSubscribableAlertEntity',
    'get:multichoosable:alerts': 'getAlertMultiNavs',
    'get:alerts:filtered:users': 'getAlertsFilteredUsers'
  },

  fetchAlerts() {
    const alerts = new Alerts();
    alerts.fetch();
    return alerts;
  },

  fetchAlertsMultiNavs(options = {}) {
    const alerts = new AlertMultiNavs();
    alerts.fetch(options);
    return alerts;
  },

  newAlerts(alerts, options = {}) {
    return new Alerts(alerts, options);
  },

  newAlert(attrs = {}, options = {}) {
    return new Alert(attrs, options);
  },

  getAlertEntity(id, options = {}) {
    return this.newAlert({ _id: id }, options);
  },

  getSubscribableAlertEntity(id, options) {
    return this.getAlertEntity(id, _.defaults(options, { isSubscribable: true }));
  },

  getAlertMultiNavs(alertsColl) {
    return new AlertMultiNavs(alertsColl?.collectAttrs());
  },

  getAlertsFilteredUsers(usersColl) {
    const filteredUsers = new FilteredCollection(usersColl);
    return filteredUsers.setSort('full_name');
  }
});

export const api = new API();
