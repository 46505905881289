import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import requestApi from './requestApi';
import { normalizeResp } from '~/store/normalizr';
import * as Actions from '~/store/reducers/certificates';
import * as schema from '~/store/schema';

export function* certificatesFetchRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'post',
      'search/nodes',
      {
        type: 'report',
        includes: ['templates', 'user_metadata']
      }
    );
    const response = normalizeResp(data, schema.certificatesSchema);
    yield put(Actions.fetchCertificatesSuccess(response));
  } catch (e) {
    yield put(Actions.fetchCertificatesFailure(e.toString()));
  }
}

function* watchFetchCertificatesRequest() {
  yield takeLatest(Actions.fetchCertificatesRequest.toString(), certificatesFetchRequestSaga);
}

export function* certificateCreateRequestSaga({ payload: { formData, onSuccess } }) {
  try {
    const {
      name, description, devices, template, ...restData
    } = formData;
    const templates = [{
      _id: template.value,
      configuration: {
        node_id: devices.map((item) => item.value)
      }
    }];
    const request = {
      name,
      description,
      organization_id: template.organization,
      serial: 'AB',
      templates,
      _type: 'report',
      user_metadata: restData
    };
    const data = yield call(
      requestApi,
      'post',
      'nodes',
      request
    );
    yield put(Actions.createCertificateSuccess(data));
    if (onSuccess) {
      onSuccess(data);
    }
  } catch (e) {
    yield put(Actions.createCertificateFailure(e.toString()));
  }
}

function* watchCreateCertificateRequest() {
  yield takeEvery(Actions.createCertificateRequest.toString(), certificateCreateRequestSaga);
}

export function* certificateUpdateRequestSaga({ payload: { formData, id, onSuccess } }) {
  try {
    const {
      name, description, devices, template, ...restData
    } = formData;
    const templates = [{
      _id: template.value,
      configuration: {
        node_id: devices.map((item) => item.value)
      }
    }];
    const request = {
      name,
      description,
      organization_id: template.organization,
      serial: 'AB',
      templates,
      _type: 'report',
      user_metadata: restData
    };
    const data = yield call(
      requestApi,
      'put',
      `nodes/${id}`,
      request
    );
    yield put(Actions.updateCertificateSuccess(data));
    if (onSuccess) {
      onSuccess(data);
    }
  } catch (e) {
    yield put(Actions.updateCertificateFailure(e.toString()));
  }
}

function* watchUpdateCertificateRequest() {
  yield takeEvery(Actions.updateCertificateRequest.toString(), certificateUpdateRequestSaga);
}

export function* certificateDeleteRequestSaga({ payload: { nodeId, onSuccess } }) {
  try {
    yield call(
      requestApi,
      'delete',
      `nodes/${nodeId}`
    );
    yield put(Actions.deleteCertificateSuccess({ id: nodeId }));
    if (onSuccess) {
      onSuccess(nodeId);
    }
  } catch (e) {
    yield put(Actions.deleteCertificateFailure(e.toString()));
  }
}

function* watchDeleteCertificateRequest() {
  yield takeEvery(Actions.deleteCertificateRequest.toString(), certificateDeleteRequestSaga);
}

export default function* app() {
  yield fork(watchFetchCertificatesRequest);
  yield fork(watchCreateCertificateRequest);
  yield fork(watchUpdateCertificateRequest);
  yield fork(watchDeleteCertificateRequest);
}
