import React, { useState, useCallback } from 'react';
import { Tab, Row, Col } from 'react-bootstrap';
import GridContainer from './grid_container';
import WebhooksContainer from './webhooks';
import FiltersFormTop from './filters_form_top';
import { TABS } from './filters_form_top/tabs';

const APIListScreen = React.memo(() => {
  const [tab, setTab] = useState(TABS.API);
  const handleSelectTab = useCallback((key) => setTab(key), []);

  return (
    <div className="content-region fill-height content">
      <div className="api-container">
        <Tab.Container id="tabs-list" defaultActiveKey={TABS.API} onSelect={handleSelectTab}>
          <Row className="clearfix">
            <Col sm={12}>
              <FiltersFormTop activeTab={tab} />
            </Col>
            <Col sm={12}>
              <Tab.Content animation={false} bsClass="tab-content list">
                <Tab.Pane eventKey={TABS.API} bsClass="list-content">
                  <GridContainer isActive={tab === TABS.API} />
                </Tab.Pane>
                <Tab.Pane eventKey={TABS.WEBHOOKS} mountOnEnter bsClass="list-content">
                  <WebhooksContainer isActive={tab === TABS.WEBHOOKS} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
});

export default APIListScreen;
