/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { View } from 'backbone.marionette';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import { isMobile } from 'app/utils/resolution_helpers';
import layoutTemplate from './templates/navigation_layout.hbs';
import listItemTemplate from './templates/item.hbs';

const NavigationItem = View.extend({
  tagName: 'li',

  events: {
    'click a:first': 'onClickChooseItem'
  },

  modelEvents: {
    'change:chosen': 'changeChosen'
  },

  getTemplate() {
    if (this.model.isDivider()) { return false; } return listItemTemplate;
  },

  changeChosen(model, value) {
    return this.$el.toggleClass('active', value);
  },

  onClickChooseItem(e) {
    if (isMobile()) {
      $('body').removeClass('nav-minified', 'filters-collapsed');
    }
    if (!this.model.get('external')) {
      e.preventDefault();
      this.model.choose({ silent: true });
      return this.model.trigger('change:chosen', this.model, this.model.isChosen(), { triggeredByUser: true });
    }
  },

  onRender() {
    if (this.model.isDivider()) { this.$el.addClass('divider'); }
    if (this.model.isChosen()) { return this.$el.addClass('active'); }
  }
});

export const NavigationList = AppCollectionView.extend({
  template: false,
  tagName: 'ul',
  childView: NavigationItem
});

export const NavigationLayout = View.extend({
  template: layoutTemplate,

  regions: {
    headerRegion: '.header-region',
    listRegion: '.main-nav-region'
  },

  ui: {
    designAccessContext: '.design-access-context'
  },

  triggers: {
    'click .minifyme': 'minify:navigation',
    'click @ui.designAccessContext': 'switch:org',
    'click .ask-support': 'ask:support'
  },

  serializeData() {
    return {
      isSupport: this.options.user.isSupport()
    };
  },

  onMinifyNavigation() {
    $('body').toggleClass('minified');
    return _.delay((() => window.dispatchEvent(new Event('resize'))), 100);
  }
});
