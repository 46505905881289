/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Radio from 'backbone.radio';
import { View } from 'backbone.marionette';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import ReactDOM from 'react-dom';
import moment from 'app/config/moment';
import React from 'react';
import toastr from 'toastr';
import Routes from 'app/config/routes';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import { api as timezonesApi } from 'app/backbone/entities/abstract/timezones';
import { ChartLayout } from 'app/backbone/lib/components/shared_views/chart';
import MonitorTable from 'app/backbone/lib/react/components/lists/thiamises/MonitorTable/MonitorTable';
import WindChartView from 'app/backbone/lib/components/shared_views/wind_chart';
import WindChart from 'app/backbone/entities/widgets/wind_chart_widget';
import Base64 from 'app/config/base64';
import DownloadForm from 'app/backbone/lib/react/components/common/DownloadForm/DownloadForm';
import DownloadModel from 'app/backbone/entities/abstract/download';
import ChartParams from 'app/backbone/lib/components/shared_views/chart_params';
import ThiamisDebugEntityFetching from 'app/backbone/lib/concerns/controllers/thiamis_debug_entity_fetching';
import { api as apiThiamisFiles } from 'app/backbone/entities/nodes/thiamis_files';
import Config from 'app/backbone/apps/thiamises/monitor/widgets/config'; // eslint-disable-line import/no-cycle
import DataPoint from 'app/backbone/entities/data_point';
import { __guard__ } from 'app/utils/custom-fns';
import faceTemplate from './templates/face.hbs';

const monitorChannel = Radio.channel('monitor');

const ChartTableWidgetContentView = View.extend(_.extend(ThiamisDebugEntityFetching, ClusternetBridge.prototype, {
  template: faceTemplate,

  className: 'fill-height',
  currentTab: 'chart',

  regions: {
    chartRegion: '.chart-region',
    paramsRegion: '.params-region',
    polarChartRegion: '.polar-chart-region',
    thiamisDebugInfoRegion: '.thiamis-debug-info-region',
    thiamisFilesInfoRegion: '.thiamis-files-info-region',
    adminSettingsRegion: '.thiamis-admin_settins-region'
  },

  ui: {
    tabContents: '.page-tab-content',
    tableContentRegion: '#table-content-region',
    csvExportFormRegion: '.csv-export-form-region',
    filesExportFormRegion: '.files-export-form-region',
    chartExportFormRegion: '.chart-export-form-region'
  },

  modelEvents:
    { 'change:time_period': 'onChangeTimePeriod' },

  initialize({ thiamis }) {
    this.thiamis = thiamis;
    this.listenTo(monitorChannel, 'chart:datapoint:chosen', (dataPoint, isChosen) => {
      if (isChosen) {
        return this.getChartLayout().getChartView().addSeries(this.model.getDataPointsColl().get(dataPoint.id));
      } return this.getChartLayout().getChartView().removeSeries(dataPoint);
    });

    monitorChannel.reply('get:chart:series:color', (dataPoint) => __guard__(this.getChartLayout().getChartView().getSeries(dataPoint), (x) => x.color));

    monitorChannel.reply('get:chart:series:data', (dataPoint) => this.getChartLayout().getChartView().getProcessedData(dataPoint));

    this.listenTo(monitorChannel, 'export:data', this.onExportData);
    this.listenTo(monitorChannel, 'show:tab', (tab) => {
      this.currentTab = tab.id;
      return this[`onShow${_.classify(tab.id)}Tab`]();
    });

    this.listenTo(this.model.getDataPointsColl(), 'updates:set', () => {
      this.model.getTableMeasurementsColl().trigger('sync');
      this.model.fillTableMeasurementsColl();
      if (this.currentTab === 'chart') {
        return this.getChartLayout().getChartView().getHighchart().hideLoading();
      } if (this.currentTab === 'polar-chart') {
        return this.getWindChartView().getHighchart().hideLoading();
      }
    });

    this.listenTo(monitorChannel, 'change:debug_live_updates', (toggle) => this.getThiamisDebugFilteredColl().toggleLiveUpdates(toggle));

    return this.listenTo(this.model.getTableDataPointsColl(), 'reset', () => this.model.fillTableMeasurementsColl());
  },

  onBeforeDetach() {
    ReactDOM.unmountComponentAtNode(this.ui.tableContentRegion[0]);
    ReactDOM.unmountComponentAtNode(this.ui.csvExportFormRegion[0]);
    ReactDOM.unmountComponentAtNode(this.ui.filesExportFormRegion[0]);
  },

  onChangeTimePeriod() {
    if (this.currentTab === 'chart') {
      this.getChartLayout().getChartView().getHighchart().showLoading();
      this.getChartLayout().getChartView().triggerMethod('show:group:btn', false);
    } else if (this.currentTab === 'polar-chart') {
      this.getWindChartView().getHighchart().showLoading();
    }
    return this.model.getTableMeasurementsColl().trigger('request');
  },

  onExportData() {
    switch (this.currentTab) {
      case 'chart': return this.downloadChart();
      case 'table': return this.downloadCsv();
      case 'debug': return this.downloadDebug();
      case 'files': return this.downloadFiles();
      case 'polar-chart': return this.downloadWindChart();
      default: return '';
    }
  },

  getFilename() {
    return `${this.thiamis.get('serial')}_${moment(this.model.getPeriodFrom()).format('YYYYMMDDHHmm')}_${moment(this.model.getPeriodTo()).format('YYYYMMDDHHmm')}`;
  },

  downloadWindChart() {
    return this.downloadChart({
      chart_type: 'WindRose',
      series: this.getWindChartView().series
    }
    );
  },

  getColor(dataPointId) {
    return this.getChartLayout().getChartView().getHighchart().get(`${dataPointId}-series`).color;
  },

  downloadChart(params = {}) {
    const periodFrom = this.model.getPeriodFrom();
    const periodTo = this.model.getPeriodTo();
    const timezone = this.thiamis.get('default_timezone');
    const selected_data_point_ids = this.model.getParamsDataPointsColl().pluck('_id');
    const data_points = this.model.getDataPointsColl()
      // eslint-disable-next-line no-bitwise
      .filter((dp) => ~selected_data_point_ids.indexOf(dp.id))
      .map((dp) => dp.toJSON({ isTransient: false }));
    const colors = selected_data_point_ids.map(this.getColor.bind(this));
    const data = _.defaults({
      current_user: App.getChannel().request('get:current:user').toJSON(),
      timezone_label: __guard__(timezonesApi.getChannel().request('get:timezone', this.thiamis.get('default_timezone')), (x) => x.label),
      filename: `${this.getFilename()}.pdf`,
      thiamis: this.thiamis.toJSON({ isTransient: false }),
      widget: this.model.toJSON({ isTransient: false }),
      data_points,
      colors,
      selected_data_point_ids,
      period_from: periodFrom,
      period_fo: periodTo,
      extremes: this.getChartLayout().getChartView().getHighchart().xAxis[0].getExtremes(),
      selected_period_label: monitorChannel.request('get:time_range:label',
        moment(periodFrom).tz(timezone), moment(periodTo).tz(timezone))
    },
    params);

    this.getChartDownloadModel().set({ json: JSON.stringify(data) });
    // @getChartDownloadModel().trigger 'download:form'
    return this.getPdf(data);
  },

  getPdf(data) {
    const parsedData = _.reduce(data, ((argResult, value, key) => {
      const result = argResult;
      result[_.camelize(key)] = value;
      return result;
    }), {});
    App.getChannel().trigger('flash:message', 'info', I18n.t('notifications.info.creating_pdf_report'));

    return Backbone.ajax({
      url: '/pdf-export.html',
      contentType: 'text/html; charset=utf=8',
      success: (responseText) => {
        const html = responseText.replace('__DATA__', JSON.stringify(parsedData));
        return this.clusternet.downloadPdf(html, data.filename);
      }
    });
  },

  getPdfLink(html, data) {
    return $.ajax({
      type: 'POST',
      // eslint-disable-next-line no-undef
      url: AWS_LAMBDA_PDF_URL,
      data: JSON.stringify({ data: Base64.encode(html), filename: this.getFilename() }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      complete: () => monitorChannel.trigger('export:data:done'),
      success: ({ url }) => {
        toastr.clear();
        if (!url.startsWith('https://')) {
          return App.getChannel().trigger('flash:message', 'error', I18n.t('notifications.errors.invalid_pdf_response'));
        }
        return App.getChannel().trigger('flash:message', 'success', I18n.t('notifications.success.pdf_report_ready', { url, filename: `${this.getFilename()}.pdf` }), { extendedTimeOut: 0,
          timeOut: 0,
          onclick(e) {
            return toastr.clear($(e.currentTarget), { force: true });
          }
        }
        );
      },
      error: (xhr, textStatus, errorThrown) => {
        this.getChartDownloadModel().set({ json: JSON.stringify(data) });
        this.getChartDownloadModel().trigger('download:form');
        return App.getChannel().request('report:message', 'Error creating PDF report', { data, textStatus, errorThrown });
      }
    });
  },

  downloadDebug() {
    return this.downloadCsv({
      data_point_id: __guard__(this.model.getDataPointsColl().getThiamisDataPoint(DataPoint.prototype.PATHS.DEBUG), (x) => x.id) });
  },

  downloadCsv(options = {}) {
    this.getCsvDownloadModel().set(_.defaults(options, {
      from: this.model.getPeriodFrom(),
      to: this.model.getPeriodTo(),
      filename: `${this.getFilename()}.csv`,
      data_point_id: this.model.getTableDataPointsColl().pluck('_id'),
      timezone: this.thiamis.get('default_timezone')
    }));
    return this.getCsvDownloadModel().trigger('download:form');
  },

  downloadFiles() {
    return this.getFilesDownloadModel().trigger('download:form');
  },

  async onShowFilesTab() {
    if (this.filesView) {
      this.filesView.model.fetch({ reset: true });
      return this.filesView;
    }
    const ThiamisFiles = await import('app/backbone/apps/thiamises/monitor/views/files_view');
    const filesModel = apiThiamisFiles.getChannel().request('fetch:thiamis:files', this.thiamis.pick('serial'));
    return App.getChannel().request('when:fetched', filesModel, () => {
      // eslint-disable-next-line new-cap
      this.filesView = new ThiamisFiles.default({
        model: filesModel,
        collection: filesModel.get('files')
      });
      return this.getRegion('thiamisFilesInfoRegion').show(this.filesView);
    });
  },

  async onShowDebugTab() {
    if (this.debugView) { return this.debugView; }
    this.getThiamisDebugFilteredColl().setPerPage(20);
    const DebugInfo = await import('app/backbone/apps/thiamises/monitor/views/debug_view'); // eslint-disable-line import/no-cycle
    // eslint-disable-next-line new-cap
    this.debugView = new DebugInfo.default({
      filterGroups: this.getThiamisDebugFilterGroupsColl(),
      filteredColl: this.getThiamisDebugFilteredColl(),
      thiamis: this.thiamis
    });
    return this.getRegion('thiamisDebugInfoRegion').show(this.debugView);
  },

  async onShowAdminSettingsTab() {
    if (this.adminSettingsView) { return this.adminSettingsView; }

    const AdminSettings = await import('app/backbone/apps/thiamises/monitor/views/admin_settings_view');
    // eslint-disable-next-line new-cap
    this.adminSettingsView = new AdminSettings.default({
      thiamis: this.thiamis, model: new Backbone.Model });
    return this.getRegion('adminSettingsRegion').show(this.adminSettingsView);
  },

  onShowChartTab() {
    return this.getChartLayout().getChartView().onAfterResize();
  },

  onShowTableTab() {
    return this.showTableComponent();
  },

  onShowPolarChartTab() {
    if (!this.getRegion('polarChartRegion').hasView()) {
      return this.getRegion('polarChartRegion').show(this.getWindChartView());
    }
  },

  showChart() {
    return this.getRegion('chartRegion').show(this.getChartLayout());
  },

  getWindChartView() {
    return this.windChartView != null ? this.windChartView : (this.windChartView = new WindChartView({
      collection: this.model.getDataPointsColl(),
      model: new WindChart(),
      thiamis: this.thiamis
    }));
  },

  getChartLayout() {
    if (this.graphView) { return this.graphView; }
    this.graphView = new ChartLayout({
      model: this.model,
      collection: this.model.getGraphDataPointsColl(),
      chartOptions: this.getChartOptions()
    });

    this.listenTo(App.getChannel(), 'window:resized', (event) => {
      if ((event != null ? event.originalEvent : undefined) && (this.currentTab === 'chart')) {
        return this.graphView.getChartView().onAfterResize();
      }
    });

    return this.graphView;
  },

  showParams() {
    return this.getRegion('paramsRegion').show(this.getChartParamsView());
  },

  getChartParamsView() {
    return this.chartParamsView != null ? this.chartParamsView : (this.chartParamsView = new ChartParams({
      collection: this.model.getParamsDataPointsColl(),
      thiamis: this.thiamis
    }));
  },

  showTableComponent() {
    return this.chartTableComponent != null ? this.chartTableComponent : (this.chartTableComponent = ReactDOM.render(this.createTableComponent(), this.ui.tableContentRegion[0]));
  },

  showCsvDownloadFormComponent() {
    return this.downloadCsvFormComponent != null
      ? this.downloadCsvFormComponent
      : (this.downloadCsvFormComponent = ReactDOM.render(this.createCsvDownloadFormComponent(), this.ui.csvExportFormRegion[0]));
  },

  getCsvDownloadModel() {
    return this.csvDownloadModel != null ? this.csvDownloadModel : (this.csvDownloadModel = new DownloadModel({
      node_id: this.model.get('node_id') }));
  },

  getChartDownloadModel() {
    return this.chartDownloadModel != null ? this.chartDownloadModel : (this.chartDownloadModel = new DownloadModel());
  },

  createCsvDownloadFormComponent() {
    return React.createElement(DownloadForm, {
      model: this.getCsvDownloadModel(),
      actionUrl: this.clusternet.thiamisExportUrl(),
      eventChannel: monitorChannel
    }
    );
  },

  createChartDownloadFormComponent() {
    return React.createElement(DownloadForm, {
      model: this.getChartDownloadModel(),
      actionUrl: Routes.reports_monitor_path()
    }
    );
  },

  showChartDownloadFormComponent() {
    return this.downloadChartFormComponent != null
      ? this.downloadChartFormComponent
      : (this.downloadChartFormComponent = ReactDOM.render(this.createChartDownloadFormComponent(), this.ui.chartExportFormRegion[0]));
  },

  showFilesDownloadFormComponent() {
    return this.downloadFilesFormComponent != null
      ? this.downloadFilesFormComponent
      : (this.downloadFilesFormComponent = ReactDOM.render(this.createFilesDownloadFormComponent(), this.ui.filesExportFormRegion[0]));
  },

  getFilesDownloadModel() {
    return this.filesDownloadModel != null ? this.filesDownloadModel : (this.filesDownloadModel = new DownloadModel());
  },

  createFilesDownloadFormComponent() {
    return React.createElement(DownloadForm, {
      model: this.getFilesDownloadModel(),
      actionUrl: this.clusternet.downloadThiamisFilesUrl(this.thiamis.get('serial')),
      eventChannel: monitorChannel
    }
    );
  },

  createTableComponent() {
    this.model.getTableMeasurementsColl().setSort('timestamp', 'desc');
    return React.createElement(MonitorTable, {
      collection: this.model.getTableMeasurementsColl(),
      dataPointsColl: this.model.getTableDataPointsColl(),
      timezone: this.thiamis.get('default_timezone'),
      thiamis: this.thiamis,
      emptyMsg: I18n.t('monitor.select_measurements'),
      pagination: {
        perPage: Config.widgets.ChartTableWidget.perPage
      }
    }
    );
  },

  getChartOptions() {
    return {
      time: {
        timezone: this.thiamis.get('default_timezone')
      },
      chart: {
        reflow: false,
        events: {
          redraw: () => {
            this.model.getParamsDataPointsColl().refilter();
            return monitorChannel.trigger('chart:redraw');
          }
        }
      },
      legend: {
        enabled: false
      }
    };
  },

  getDataPointsColl() {
    return this.model.getDataPointsColl();
  },

  onAttach() {
    this.showCsvDownloadFormComponent();
    this.showFilesDownloadFormComponent();
    this.showChartDownloadFormComponent();
    this.showChart();
    return this.showParams();
  }
}));

export default ClusternetBridge(ChartTableWidgetContentView);
