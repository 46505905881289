var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sensor-box\">\n  <div class=\"settings\"><a title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"thiamis.remove_from_chart",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":70}}}))
    + "\" class=\"action-button remove\"><i class=\"fa fa-close fa-fw\"></i></a></div>\n  <div class=\"parameter-name\">\n    <h2 class=\"param-name\"></h2>\n  </div>\n  <h3 class=\"sensor-name\"><span></span><small></small></h3>\n  <div class=\"parameter-statistics\">\n    <div>\n      <p>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.labels.min_short",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":41}}}))
    + "</p>\n      <h2 class=\"min\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"min") || (depth0 != null ? lookupProperty(depth0,"min") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"min","hash":{},"data":data,"loc":{"start":{"line":10,"column":22},"end":{"line":10,"column":29}}}) : helper)))
    + "</h2>\n    </div>\n  </div>\n  <div class=\"parameter-statistics\">\n    <div>\n      <p>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.labels.avg_short",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":15,"column":9},"end":{"line":15,"column":41}}}))
    + "</p>\n      <h2 class=\"avg\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"avg") || (depth0 != null ? lookupProperty(depth0,"avg") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"avg","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":29}}}) : helper)))
    + "</h2>\n    </div>\n  </div>\n  <div class=\"parameter-statistics\">\n    <div>\n      <p>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.labels.max_short",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":21,"column":9},"end":{"line":21,"column":41}}}))
    + "</p>\n      <h2 class=\"max\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"max") || (depth0 != null ? lookupProperty(depth0,"max") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"max","hash":{},"data":data,"loc":{"start":{"line":22,"column":22},"end":{"line":22,"column":29}}}) : helper)))
    + "</h2>\n    </div>\n  </div>\n</div>";
},"useData":true});