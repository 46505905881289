import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  SortingState,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow, Table
} from '@devexpress/dx-react-grid-bootstrap3';
import attributeName from 'app/config/hbs-helpers/attributeName';
import {
  fetchPartnershipsRequest, getPartnershipsIsFetched
} from '~/store/reducers/partnerships';

import Spinner from '~/components/spinner';
import ListTable from '~/components/list_table';
import { getFilteredList } from '../selectors';
import {
  CreatedAtProvider,
  Org1NameProvider, Org2NameProvider, ActionsProvider
} from './info_providers';

function compareDates(a, b) {
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'org1_name', title: attributeName('partnership', 'org1_name') },
  { name: 'org2_name', title: attributeName('partnership', 'org2_name') },
  { name: 'created_at', title: 'Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = (row) => row._id; // eslint-disable-line no-underscore-dangle

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = (props) => {
  const {
    handleFetchPartnershipsRequest, isActive, rows, isFetched, slug
  } = props;
  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'asc' }]);

  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    handleFetchPartnershipsRequest({ data });
  }, [handleFetchPartnershipsRequest, slug]);

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  if (!isFetched) {
    return (
      <Spinner />
    );
  }

  return (
    <ListTable className="partnerships-list-table react-partnerships-list" isActive={isActive}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        rootComponent={Root}
      >
        <SortingState
          sorting={sorting}
          onSortingChange={handleSortingChange}
        />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'created_at', compare: compareDates }
          ]}
        />
        <CreatedAtProvider for={['created_at']} />
        <Org1NameProvider for={['org1_name']} />
        <Org2NameProvider for={['org2_name']} />
        <ActionsProvider for={['actions']} />
        <Table
          columnExtensions={[
            {
              columnName: 'actions',
              width: 120
            }
          ]}
        />
        <VirtualTable />
        <TableHeaderRow
          align="center"
          showSortingControls
        />
      </Grid>
    </ListTable>
  );
};

const mapStateToProps = (state) => ({
  rows: getFilteredList(state),
  isFetched: getPartnershipsIsFetched(state)
});

export default connect(mapStateToProps, {
  handleFetchPartnershipsRequest: fetchPartnershipsRequest
})(GridContainer);
