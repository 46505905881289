import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Overlay, Popover } from 'react-bootstrap';
import { app as App } from 'app/backbone/app';
import { api as auth0Api } from 'app/utils/auth0_handler';
import { getCurrentUserId } from '~/store/selectors/global';
import {
  getUserByIdFullName, getUserByIdNickname, getUserByIdEmail, getUserByIdPicture, getUserByIdInitials
} from '~/store/selectors/users';

const ProfilePopover = () => {
  const { t } = useTranslation();
  const [show, setShowPopover] = useState(false);
  const [target, setPopoverTarget] = useState(null);
  const {
    fullName,
    nickname,
    email,
    picture,
    initials
  } = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return {
      fullName: getUserByIdFullName(state, currentUserId),
      email: getUserByIdEmail(state, currentUserId),
      picture: getUserByIdPicture(state, currentUserId),
      initials: getUserByIdInitials(state, currentUserId),
      nickname: getUserByIdNickname(state, currentUserId)
    };
  });
  const handleLogoutClick = useCallback(() => auth0Api.getChannel().request('get:instance').backToLoginPage({ logout: true }), []);
  const handleOpenProfileClick = useCallback(() => {
    App.navigate('profile');
    setShowPopover(false);
  }, []);
  return (
    <>
      <div className="profile-pop">
        <a
          role="button"
          id="profile-link"
          className="pop header-account-info pull-right"
          onClick={(e) => {
            setShowPopover(!show);
            setPopoverTarget(e.target);
          }}
        >
          <span className="header-username">{nickname}</span>
          <span className="user-image-container">
            <span className="user-picture-wrap">
              <span className="user-picture img-rounded">
                {picture ? <img className="img-responsive" alt="" src={picture} /> : <h2 className="avatar">{initials}</h2>}
              </span>
            </span>
          </span>
        </a>
      </div>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={this}
        containerPadding={20}
      >
        <Popover bsClass="header-popover popover">
          <div className="profile-popover clearfix">
            <div className="pp-content clearfix">
              <div className="popover-user-picture">
                <span className="user-picture-wrap">
                  <span className="user-picture img-rounded">
                    {picture ? <img className="img-responsive" alt="" src={picture} /> : <h2 className="avatar">{initials}</h2>}
                  </span>
                </span>
              </div>
              <div className="profile-info">
                <h1 className="header-user-fullname">{fullName}</h1>
                <span className="truncated header-user-email">{email}</span>
              </div>
            </div>
            <div className="pp-bottom clearfix">
              <div className="col-sm-6">
                <a role="button" onClick={handleOpenProfileClick} className="profile">{t('header.account')}</a>
              </div>
              <div className="col-sm-6">
                <a role="button" onClick={handleLogoutClick}>{t('header.sign_out')}</a>
              </div>
            </div>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

export default ProfilePopover;
