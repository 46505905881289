var Handlebars = require("../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n      <a title=\""
    + container.escapeExpression(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"base.buttons.configure",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":49}}}))
    + "\" data-toggle=\"popover\" class=\"btn btn btn-sm btn-default btn_toggle btn-configure\"><i class=\"fa fa-gear\"></i></a>";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "\n    <div class=\"switch-button\"><input type=\"checkbox\" class=\"checkbox-switch sensor-enabled\"><label off=\""
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.labels.switched_off",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":15,"column":105},"end":{"line":15,"column":140}}}))
    + "\" on=\""
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"base.labels.switched_on",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":15,"column":146},"end":{"line":15,"column":180}}}))
    + "\" class=\"checkbox form-control\"></label></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-box\">\n  <div class=\"settings\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModifiable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":3,"column":170}}})) != null ? stack1 : "")
    + "</div>\n  <div class=\"sensor-name\"></div>\n  <div class=\"row configuration-info\">\n    <div class=\"col-sm-12 info-captions\">\n      <div class=\"clearfix\">\n        <div class=\"sensor-icon\"></div>\n        <ul class=\"sensor-info-list\">\n          <li class=\"sensor-serial\"><b>"
    + container.escapeExpression(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.sensor.serial",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":74}}}))
    + "</b><span></span></li>\n        </ul>\n      </div>\n    </div>\n  </div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfigurationAndIsModifiable") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});