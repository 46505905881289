import PageNotFoundApp from 'app/backbone/apps/404/page_not_found_app';
import ProfileApp from 'app/backbone/apps/profile/profile_app';
import 'app/backbone/apps/user_files/user_files_app';
import DashboardApp from 'app/backbone/apps/dashboard/dashboard_app';
import ThiamisesApp from 'app/backbone/apps/thiamises/thiamises_app';
import AlertsApp from 'app/backbone/apps/alerts/alerts_app';
import AnalyticsApp from 'app/backbone/apps/analytics/analytics_app';
import NavigationApp from 'app/backbone/apps/navigation/navigation_app';
import MapApp from 'app/backbone/apps/map/map_app';
import ApiApp from 'app/backbone/apps/api/api_app';
import CertificateApp from 'app/backbone/apps/certificates/app_certificate';
import BackboneToReactApp from 'app/backbone/apps/backbone_to_react';
import OrganizationsApp from 'app/backbone/apps/organizations/organizations_app';
import { app as App } from 'app/backbone/app';
import PartnershipsApp from 'app/backbone/apps/partnerships/partnerships_app';
import FirmwareApp from 'app/backbone/apps/firmware/firmware_app';
import CalibrationApp from 'app/backbone/apps/calibration/calibration_app';
import UsersApp from 'app/backbone/apps/users/users_app';

App.on('start', (app) => {
  NavigationApp.start({
    navs: app.sideNavs,
    currentUser: app.currentUser,
    region: app.getView().getRegion('navigationRegion')
  });
  App.getChannel().once('reset:org_navs', () => {
    if (app.currentUser.isSupport()) {
      CertificateApp.start();
      AnalyticsApp.start();
    } else if (app.currentUser.isAdmin()) {
      FirmwareApp.start();
    }
    PageNotFoundApp.start();
    ProfileApp.start();
    AlertsApp.start();
    ThiamisesApp.start();
    OrganizationsApp.start();
    PartnershipsApp.start();
    UsersApp.start();
    ApiApp.start();
    DashboardApp.start();
    MapApp.start();
    CalibrationApp.start();
    BackboneToReactApp.start();
    Backbone.history.start();
    app.appStarted = true; // eslint-disable-line no-param-reassign
  });
});
