/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { api as apiDataPoints } from 'app/backbone/entities/data_points';

export default (superclass) => class extends superclass {
  getAvailableDataPointsColl() {
    if (this.defaultFormDataPoints) { return this.defaultFormDataPoints; }
    this.formDataPoints = apiDataPoints.getChannel().request('fetch:form:datapoints', this.get('node_id'));
    this.defaultFormDataPoints = apiDataPoints.getChannel().request('new:filtered:datapoints', this.formDataPoints).filterDefault();
    this.on('change:node_id', () => this.formDataPoints.changeNodeId(this.get('node_id')));
    return this.defaultFormDataPoints;
  }

  formParams() {
    return { collection: this.getAvailableDataPointsColl() };
  }
};
