import { createSelector } from 'reselect';
import * as fromWebhooks from '~/store/reducers/webhooks';

export const getWebhooksIsFetched = (state) => fromWebhooks.getIsFetched(state);
export const getWebhookById = (state, id) => fromWebhooks.getWebhookById(state, id);
export const getWebhookIds = (state) => fromWebhooks.getWebhookIds(state);
export const getWebhooks = (state) => fromWebhooks.getWebhooks(state);

export const getWebhookByIdIsSyncing = createSelector(
  getWebhookById,
  (webhook) => webhook?.isSyncing
);

export const getWebhookByIdIsEnabled = createSelector(
  getWebhookById,
  (webhook) => webhook?.enabled
);
