import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { declineUserInvitationRequest } from '~/store/reducers/invitations';
import withTooltip from '~/hocs/withTooltip';

const DeclineInvitation = ({ id }) => {
  const { t } = useTranslation();

  const handleSuccess = useCallback(() => {
    toast.success(t('notifications.success.forms.partnership_invitation_declined'), {
      toastId: 'success_partnership_invitation_declined'
    });
  }, [t]);

  const dispatch = useDispatch();
  const handleAction = useCallback(
    () => dispatch(declineUserInvitationRequest({ id, onSuccess: handleSuccess })), [dispatch, id, handleSuccess]
  );

  const IconTooltip = ({ title, tooltipRef }) => (
    <a
      ref={tooltipRef}
      data-tip={title}
      className="info"
      role="button"
      title={t('base.buttons.decline')}
      onClick={handleAction}
    >
      <i className="fa fa fa-fw fa-ban" />
    </a>
  );
  const Tooltip = withTooltip(IconTooltip);

  return (
    <div
      className="expand-button"
      style={{ position: 'relative' }}
    >
      <Tooltip title={t('base.buttons.decline')} />
    </div>
  );
};

export default DeclineInvitation;
