import React from 'react';
import classNames from 'classnames';
import useWindowSize from '~/hooks/useWindowSize';

const ListTable = ({ className, isActive, children }) => {
  const size = useWindowSize();
  const height = isActive === false ? 0 : `${size.height - 125}px`;

  return (
    <div className={classNames(className, 'list-table')} style={{ height }}>
      {children}
    </div>
  );
};

export default ListTable;
