/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'app/config/moment';
import { app as App } from 'app/backbone/app';

function getTimezone(timezone) {
  return timezone || App.getChannel().request('get:user:timezone');
}

const Timestamp = (props) => (
  <span>{ moment(props.timestamp).tz(getTimezone(props.timezone)).format(props.format) }</span>
);

Timestamp.propTypes = {
  format: PropTypes.string,
  timestamp: PropTypes.number.isRequired,
  timezone: PropTypes.string
};

Timestamp.defaultProps = {
  format: 'MM-DD-YYYY HH:mm:ss',
  timezone: 'America/New_York'
};

export default Timestamp;
