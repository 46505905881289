import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  IntegratedSorting,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import ExpandButton from '~/components/react_grid/expand_button';
import ListTable from '~/components/list_table';
import DetailRowColumnOrder from './detail_row_column_order';
import GridDetailContainer from './detail_row';
import Spinner from '~/components/spinner';
import { getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { fetchAPIListRequest } from '~/store/reducers/apis';
import { getAPIsIsFetched } from '~/store/selectors/apis';
import { getFilteredList } from '../selectors';
import { clearFilters } from '../reducer';
import {
  TokenProvider,
  TimeAgoProvider,
  DeviceScopeProvider
} from './info_providers';

const columns = [
  { name: 'token', title: 'Token', sortingEnabled: false },
  { name: 'organization_name', title: 'Organization' },
  { name: 'device_scope', title: 'Device Scope' },
  { name: 'created_at', title: 'Created' },
  { name: 'requests_number', title: 'Requests' },
  { name: 'last_used_at', title: 'Last Used' }
];

const getRowId = ({ id }) => id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = () => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' }]);

  const Row = React.memo((props) => {
    const { row: { id } } = props;
    const isExpanded = includes(expandedRowIds, id);

    const handleExpandRowClick = useCallback(
      () => {
        if (isExpanded) {
          setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== id));
        } else {
          setExpandedRowIds([...expandedRowIds, ...[id]]);
        }
      },
      [isExpanded, id]
    );
    return (
      <Table.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);
  const { rows } = useSelector(
    (state) => ({
      rows: getFilteredList(state)
    })
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAPIListRequest());
  }, [dispatch]);
  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const { isFetched } = useSelector(
    (state) => ({
      isFetched: getAPIsIsFetched(state) && getOrganizationsIsFetched(state)
    })
  );

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <div className="api-list-table">
      <ListTable>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting />
          <TokenProvider for={['token']} />
          <TimeAgoProvider for={['created_at', 'last_used_at']} />
          <DeviceScopeProvider for={['device_scope']} />
          <Table
            rowComponent={Row}
            columnExtensions={[
              { columnName: 'token', width: '30%' },
              { columnName: 'organization_name', width: '20%' },
              { columnName: 'device_scope', width: '10%' },
              { columnName: 'created_at', width: '10%' },
              { columnName: 'requests_number', width: '10%' },
              { columnName: 'last_used_at', width: '15%' }
            ]}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <TableRowDetail
            contentComponent={GridDetailContainer}
            toggleCellComponent={ExpandButton}
          />
          <DetailRowColumnOrder />
          <TableHeaderRow align="center" showSortingControls />
        </Grid>
      </ListTable>
    </div>
  );
};

export default GridContainer;
