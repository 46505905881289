/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Model } from 'backbone';
import { MnObject } from 'backbone.marionette';

export default class Abilities extends Model {
  get defaults() {
    return {
      organization: {
        creatable: true,
        fetchable: true
      },
      partnership: {
        creatable: false,
        fetchable: false
      },
      partnership_invitation: {
        creatable: false,
        fetchable: false
      }
    };
  }

  initialize(attrs, options = {}) {
    const { currentUser } = options;
    this.currentUser = currentUser;
    if (!this.currentUser) {
      throw new Error('currentUser should be provided');
    }
    this.setAbilities();
  }

  setAbilities() {
    const isManager = this.currentUser.isManager();
    this.set({
      partnership: {
        creatable: false,
        fetchable: isManager
      },
      partnership_invitation: {
        creatable: isManager,
        fetchable: isManager
      }
    });
  }
}

const API = MnObject.extend({
  channelName: 'entities:abilities',

  radioRequests: {
    'get:instance': 'getInstance'
  },

  getInstance(currentUser) {
    return new Abilities(null, { currentUser });
  }
});

export const api = new API();
