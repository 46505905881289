import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { isMobile } from 'app/utils/resolution_helpers';
import { getCurrentUserIsManager, getCurrentUserHasEmptyRoles } from '~/store/selectors/current_user';
import ManageDevicesActions from './manage_devices';
import DownloadModal from './download_csv';
import { ClaimDeviceButton } from '../no_devices';
import { filterListByQuery, exportTableDataRequest } from '../reducer';

export default function FiltersFormTop({ hasDevices }) {
  const dispatch = useDispatch();
  const onFilterInputChange = _.debounce((e) => dispatch(filterListByQuery(e.target.value)), 200);
  const isManager = useSelector(getCurrentUserIsManager);
  const hasEmptyRoles = useSelector(getCurrentUserHasEmptyRoles);

  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleToggleModal = useCallback(() => setModalOpen((open) => !open), [setModalOpen]);

  const handleInputChange = (e) => {
    e.persist();
    return onFilterInputChange(e);
  };

  const handleDownloadTableData = useCallback(() => {
    dispatch(exportTableDataRequest());
  }, [dispatch]);

  const handleCollapseClick = () => {
    $('body').toggleClass('filters-collapsed');
    if (isMobile()) { $('body').removeClass('nav-minified'); }
  };

  return (
    <div className="content-top">
      <div className="filter-input-wrap pull-left">
        <input className="filter-input" type="text" placeholder="Filter..." onChange={handleInputChange} />
      </div>
      <div className="pull-left">
        { hasDevices && <ManageDevicesActions /> }
      </div>
      <div className="pull-right">
        { hasDevices
        && (
          <Dropdown>
            <Dropdown.Toggle>
              <i className="fa fa-fw fa-download" />
              {t('alerts.events.download_csv')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem onClick={handleDownloadTableData}>{t('thiamis.download_csv.table_data')}</MenuItem>
              <MenuItem onClick={handleToggleModal}>{t('thiamis.download_csv.parameters_data')}</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {
          (isManager || hasEmptyRoles) && <ClaimDeviceButton to="devices/claim" primary />
        }
        <button onClick={handleCollapseClick} type="button" className="btn btn-default filters-collapse">
          <i className="fa fa-fw fa-md fa-bars" />
        </button>
      </div>
      { hasDevices && <DownloadModal isVisible={modalOpen} toggleModal={handleToggleModal} /> }
    </div>
  );
}
