import styled from 'styled-components';

export const FormContainer = styled.div.attrs({
  className: 'form'
})`
  input:disabled {
    background-color: rgba(0, 0, 0, .05);
  }

  ${({ disabled }) => (disabled ? `
    .PhoneInput {
      background-color: rgba(0, 0, 0, .05);

      input {
        background: transparent;
        &::-webkit-input-placeholder {
          color: #999;
        }
        &:-moz-placeholder {
          color: #999;
        }
        &::-moz-placeholder {
          color: #999;
        }
        &:-ms-input-placeholder {
          color: #999;
        }
      }
    }
  ` : '')}
`;

export const Row = styled.div`
  display: grid;
  position: relative;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
`;
