import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import first from 'lodash/first';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import * as fromDevices from '~/store/reducers/devices';
import * as fromMeasurements from '~/store/selectors/measurements';
import * as fromDatapoints from '~/store/selectors/data_points';

export const getProfile = createSelector(
  fromDatapoints.getDataPointById,
  (state) => state,
  (dataPoint, state) => {
    const device = fromDevices.getDeviceById(state, dataPoint?.device_id);
    return first(device?.profiles || []);
  }
);

export const getProfileRawRanges = createSelector(
  fromDatapoints.getDataPointById,
  getProfile,
  (dataPoint, profile) => {
    const path = fromDatapoints.getPath(dataPoint);
    return get(profile, `ranges[${path}]`);
  }
);

export const getProfilePaths = createSelector(
  getProfile,
  (profile) => Object.keys(profile?.ranges)
);

export const getProfileRanges = createSelector(
  getProfileRawRanges,
  (ranges) => {
    if (ranges) {
      return ranges
        .filter(({
          gte, lte, gt, lt
        }) => !(isNil(gt) && isNil(gte) && isNil(lt) && isNil(lte)));
    }

    return undefined;
  }
);

export const getProfileDefaultStatus = createSelector(
  getProfileRawRanges,
  getProfile,
  (ranges, profile) => {
    if (!ranges) return undefined;

    const lastRange = last(ranges);

    if (!lastRange) {
      return undefined;
    }

    const {
      gte, lte, gt, lt
    } = lastRange;
    if (!(isNil(gt) && isNil(gte) && isNil(lt) && isNil(lte))) {
      return undefined;
    }

    return camelCase(profile.status_name[lastRange.status]);
  }
);

export const getDataPointStatusById = createSelector(
  getProfileRawRanges,
  fromMeasurements.getLastMeasurementValue,
  getProfile,
  (ranges, value, profile) => {
    if (isNil(ranges) || isNil(value)) {
      return {
        value
      };
    }

    const selectedRange = ranges.find((range) => {
      const {
        gte, lte, gt, lt
      } = range;
      const exp = [];
      if (!isNil(gte)) exp.push(`value >= ${gte}`);
      if (!isNil(lte)) exp.push(`value <= ${lte}`);
      if (!isNil(gt)) exp.push(`value > ${gt}`);
      if (!isNil(lt)) exp.push(`value < ${lt}`);
      return eval(exp.join(' && ')); // eslint-disable-line no-eval
    }) || last(ranges);

    const status = profile.status_name[selectedRange.status];

    return {
      value,
      status: camelCase(status)
    };
  }
);
