import React from 'react';
import kebabCase from 'lodash/kebabCase';
import TimeAgo from 'react-timeago';
import moment from 'moment';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import attributeName from 'app/config/hbs-helpers/attributeName';
import { useTranslation } from 'react-i18next';
import withTooltip from '~/hocs/withTooltip';
import { getStatus } from '../selectors';

function getStatusColorIcon(status, theme) {
  switch (status) {
    case 'fail':
      return { color: theme.colors.redColor, icon: '\f071' };
    case 'ok':
      return { color: '#777777', icon: '\f058' };
    default:
      return { color: '#777777', icon: '\f110' };
  }
}

const StyledBadge = styled(Badge)`
  padding: 4px 8px;
  background-color: ${({ status, theme }) => getStatusColorIcon(status, theme).color};
`;

const StyledIcon = styled('span')`
  padding: 4px 8px;
  color: ${({ status, theme }) => getStatusColorIcon(status, theme).color};
  i::before {
    content: '${({ status, theme }) => getStatusColorIcon(status, theme).icon}';
  }
`;

const StyledParameter = styled('span')`
background-color: #fff;
color: #666;
margin-right: 12px;
padding: 4px 8px;
padding-right: 0;
border: 1px solid #dfdfdf;
border-radius: 5px;
`;

export const CreatedAtFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    value && moment(value).isValid()
      ? <TimeAgo date={value} />
      : t('alerts.triggered_never')
  );
};

export const StatusFormatter = ({ value }) => <StyledBadge status={value}>{attributeName('calibration', `statuses.${kebabCase(value)}`)}</StyledBadge>;

export const ParamertsFormatter = ({ value }) => {
  if (!value) {
    return <></>;
  }
  return value.map(({ name, ...rest }) => {
    const status = getStatus(rest);
    const IconTooltip = ({ title, tooltipRef }) => (
      <StyledIcon status={status} ref={tooltipRef} data-tip={title}><i className="fa fa-fw" /></StyledIcon>
    );
    const Tooltip = withTooltip(IconTooltip);
    return (
      <StyledParameter>
        {name}
        <Tooltip title={attributeName('calibration', `statuses.${kebabCase(status)}`)} />
      </StyledParameter>
    );
  });
};
