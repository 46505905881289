import React from 'react';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import moment from 'moment';
import LazyLoad from 'react-lazy-load';
import TimeAgo from 'react-timeago';
import { useTranslation } from 'react-i18next';
import ImgValid from 'app/backbone/lib/react/components/common/ImgValid/ImgValid';
import { OrganizationNameWithExernalId } from '~/screens/partnerships/grid_container/info_providers';

const AvatarFormatter = ({ row: { logo_url } }) => (
  <span className="user-picture-wrap">
    <span className="user-picture img-rounded">
      {
        logo_url && (
          <LazyLoad offsetTop={130} width={55} height={55}>
            <ImgValid src={logo_url} />
          </LazyLoad>
        )
      }
    </span>
  </span>
);

export const AvatarProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={AvatarFormatter}
    className="avatar"
  />
);

export const CreatedAtFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    value && moment(value).isValid()
      ? <TimeAgo date={value} />
      : t('alerts.triggered_never')
  );
};

export const CreatedAtProvider = (props) => (
  <DataTypeProvider
    formatterComponent={CreatedAtFormatter}
    {...props}
  />
);

export const NameFormatter = ({ row }) => (
  <OrganizationNameWithExernalId name={row.name} external_id={row.external_id} />
);

export const NameProvider = (props) => (
  <DataTypeProvider
    formatterComponent={NameFormatter}
    {...props}
  />
);
