/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import classNames from 'classnames';
import ChartView from 'app/backbone/lib/concerns/views/chart_view';
import I18n from 'app/config/i18n';
import { __guard__ } from 'app/utils/custom-fns';
import chartTemplate from './templates/chart.hbs';

export const Chart = ChartView.extend({
  className: 'fill',

  collectionEvents: {
    reset: 'resetSeries',
    add: 'addSeries',
    remove: 'removeSeries'
  },

  redrawChart: _.throttle(function () {
    if (!this.isChartDestroyed()) { return this.getHighchart().redraw(); }
  }, 3000, { leading: false }),

  onAfterResize() {
    const width = this.$el.width();
    const height = this.model.has('resizable') ? this.$el.closest('.widget-container').height() - 40 : this.$el.height();
    return this.getHighchart().setSize(width, height);
  },

  clearChart() {
    this.setExtremes({ min: null, max: null });
    const chart = this.getHighchart();
    while (chart.series.length > 0) { chart.series[0].remove(false); }
    return (() => {
      const result = [];
      while (chart.yAxis.length > 0) {
        result.push(chart.yAxis[0].remove(false));
      }
      return result;
    })();
  },

  onBeforeDetach() {
    this.redrawChart.cancel();
  },

  resetSeries() {
    this.clearChart();
    return this.collection.each(this.addSeries, this);
  },

  _addDataPointToChart(dataPoint) {
    if (this.isChartDestroyed()) { return; }
    if (this.getSeries(dataPoint)) { return; }

    if (dataPoint.get('_type') === 'metric') {
      dataPoint.set('is_metric_chart', true);
    }

    this.addAxis(dataPoint);
    this.addSeriesToChart(dataPoint);

    this.groupAxisByUnit();

    if (!this.getHighchart().xAxis[0].hasData()) {
      this.getHighchart().showLoading(I18n.t('dashboard.chart_no_data'));
      this.triggerMethod('show:group:btn', false);
    }

    return this.listenTo(dataPoint, 'augment:measurements', this.augmentMeasurements);
  },

  addSeries(dataPoint) {
    if (dataPoint.measurementsInitiated) {
      return _.defer(() => this._addDataPointToChart(dataPoint));
    }
    return this.listenToOnce(dataPoint, 'initiate:measurements', () => this._addDataPointToChart(dataPoint));
  },

  removeSeries(dataPoint) {
    dataPoint.off('augment:measurements', this.augmentMeasurements);
    __guard__(this.getSeries(dataPoint), (x) => x.remove(false));
    __guard__(this.getYAxis(dataPoint), (x1) => x1.remove(false));
    this.groupAxisByUnit({ redraw: false });
    return this.getHighchart().redraw();
  },

  augmentMeasurements(dataPoint, newMeasurements) {
    return _(newMeasurements).each(_.partial(this.addMeasurement, dataPoint), this);
  },

  addMeasurement(dataPoint, measurement) {
    const series = this.getSeries(dataPoint);
    if (series != null ? series.data : undefined) {
      series.addPoint(measurement, false, false);
    }
    return this.redrawChart();
  },

  removeMeasurement(dataPoint, measurement) {
    const series = this.getSeries(dataPoint);
    if (series != null ? series.data : undefined) {
      series.removePoint(measurement.toJSON(), false);
    }
    return this.redrawChart();
  },

  onDomRefresh() {
    this.renderChart();
    this.renderSeries();
    return _.delay(() => {
      if (!this.isChartDestroyed()) { return this.getHighchart().redraw(); }
    }, 1000);
  }
});

export const ChartLayout = View.extend({
  template: chartTemplate,
  className: 'chart-container fill',

  regions:
    { chartRegion: '.highcharts-region' },

  ui: {
    groupBtn: '.group-btn',
    toggleOrdinalBtn: '.toggle-ordinal-btn'
  },

  triggers: {
    'click @ui.groupBtn': 'group:click',
    'click @ui.toggleOrdinalBtn': 'toggle:ordinal:click'
  },

  bindings: {
    '@ui.groupBtn': {
      observe: 'yaxis_grouped',
      update($el) {
        return $el.tooltip();
      },
      attributes: [{
        observe: 'yaxis_grouped',
        name: 'class',
        onGet(yaxisGrouped) {
          return classNames({ grouped: yaxisGrouped });
        }
      }]
    },
    '@ui.toggleOrdinalBtn': {
      observe: 'ordinal',
      update($el) {
        return $el.tooltip();
      },
      attributes: [{
        observe: 'ordinal',
        name: 'class',
        onGet(ordinal) {
          return classNames({ condensed: ordinal });
        }
      }]
    }
  },

  initialize() {
    return this.listenTo(this.getChartView(), 'show:group:btn', (showGroupBtn) => this.ui.groupBtn.toggleClass('hidden', !showGroupBtn));
  },

  onGroupClick() {
    this.model.set('yaxis_grouped', !this.model.get('yaxis_grouped'), { savedBySync: true, stickitChange: true });
    return this.getChartView().groupAxisByUnit();
  },

  onToggleOrdinalClick() {
    const ordinal = !this.model.get('ordinal');
    this.model.set('ordinal', ordinal, { savedBySync: true, stickitChange: true });
    return this.getChartView().toggleOrdinal({ ordinal, gapSize: 5 });
  },

  onDomRefresh() {
    return this.getRegion('chartRegion').currentView.triggerMethod('dom:refresh');
  },

  getChartView() {
    if (this.chartView) { return this.chartView; }
    return this.chartView = new Chart(this.options);
  },

  onRender() {
    this.stickit();
    return this.getRegion('chartRegion').show(this.getChartView());
  }
});
