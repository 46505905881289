import React, { useCallback, useState } from 'react';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel
} from '@devexpress/dx-react-grid-bootstrap3';

import {
  EmailProvider,
  UserRoleProvider,
  ActionsProvider,
  CreatedAtProvider
} from './formatters';

const columns = [
  { name: 'email', title: 'Email To' },
  { name: 'role', title: 'User Role' },
  { name: 'organization_name', title: 'Organization' },
  { name: 'created_at', title: 'Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const PAGE_SIZE = 13;

function compareDates(a, b) {
  return (b != null) - (a != null) || b - a;
}

const getRowId = (row) => row._id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const InvitationsListTable = ({ rows }) => {
  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'asc' }]);
  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  return (
    <Grid
      rows={rows}
      columns={columns}
      getRowId={getRowId}
      rootComponent={Root}
    >
      <SortingState
        sorting={sorting}
        onSortingChange={handleSortingChange}
      />
      <PagingState
        defaultCurrentPage={0}
        pageSize={PAGE_SIZE}
      />
      <IntegratedPaging />
      <IntegratedSorting
        columnExtensions={[
          { columnName: 'created_at', compare: compareDates }
        ]}
      />
      <EmailProvider for={['email']} />
      <UserRoleProvider for={['role']} />
      <CreatedAtProvider for={['created_at']} />
      <ActionsProvider for={['actions']} />
      <Table
        columnExtensions={[
          {
            columnName: 'actions',
            width: 120
          }
        ]}
      />
      <TableHeaderRow
        align="center"
        showSortingControls
      />
      {rows.length > PAGE_SIZE && <PagingPanel />}
    </Grid>
  );
};

export default InvitationsListTable;
