import React, { useCallback } from 'react';
import { app as App } from 'app/backbone/app';

const withBackboneLink = (WrappedComponent) => (props) => {
  const { to } = props;
  const navigateTo = useCallback(() => {
    App.navigate(to);
  }, [to]);
  return <WrappedComponent {...props} onClick={navigateTo} />;
};

export default withBackboneLink;
