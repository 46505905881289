/* eslint-disable no-param-reassign */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import I18n from 'app/config/i18n';
import Routes from 'app/config/routes';
import AppModel from 'app/backbone/lib/entities/app_model';
import { Roles } from 'app/backbone/entities/role';
import Addressable from 'app/backbone/lib/concerns/entities/addressable';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import ValueLabelPairs from 'app/backbone/lib/concerns/entities/value_label_pairs';
import { __guard__ } from 'app/utils/custom-fns';
import reduxStore from 'app/react/store';
import { api as auth0Api } from 'app/utils/auth0_handler';
import { fetchOrganizationsListRequest } from '~/store/reducers/organizations';

const { AUTH0_DOMAIN } = process.env;
const { AUTH0_CLIENT_ID } = process.env;

export class UserModel extends Addressable(AppModel) {
  get idAttribute() { return 'user_id'; }

  get metaDataAttrs() {
    return [
      'critical_notice', 'family_name', 'given_name', 'info_notice', 'phone', 'locale',
      'timezone', 'notifying_methods', 'warning_notice', 'upload_picture'];
  }

  get defaults() {
    return {
      info_notice: 'email',
      warning_notice: 'email',
      critical_notice: 'email_sms',
      phone_country_code: 'us',
      locale: 'en'
    };
  }

  get mutators() {
    return {
      full_name: {
        get() {
          if (this.get('given_name') && this.get('family_name')) {
            return `${this.get('given_name')} ${this.get('family_name')}`;
          } return this.get('nickname');
        }
      },
      activity_state: {
        get() {
          if (this.get('online_sessions') >= 0) {
            return I18n.t('users.states.online');
          } return I18n.t('users.states.offline');
        }
      },
      search_text: {
        get() {
          return [this.get('family_name'), this.get('given_name'), this.get('email')].join(' ').toLowerCase();
        }
      },
      initials: {
        get() { return _.getInitials(this.get('full_name')); }
      },
      profile_picture: {
        get() { return this.get('upload_picture') || this.get('picture'); }
      }
    };
  }

  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'roles',
        collectionType: Roles,
        isTransient: true
      }];
  }

  urlRoot() {
    return Routes.users_path();
  }

  auth0Url(path) {
    if (!path) { throw new Error('path must be specified'); }
    return `https://${AUTH0_DOMAIN}${path}`;
  }

  validation() {
    return _(super.validation(...arguments)).extend({ // eslint-disable-line prefer-rest-params
      new_password_confirmation(value, attr, computedAttrs) {
        return Backbone.Validation.validators.equalTo(value, attr, 'new_password', this, computedAttrs);
      },
      given_name: {
        required: true
      },
      family_name: {
        required: true
      },
      phone: {
        mobile: true
      }
    });
  }

  toJSON(options = {}) {
    if (!options.onSave) { return super.toJSON(...arguments); } // eslint-disable-line prefer-rest-params
    let json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    const rolesHash = _(this.get('roles').toJSON(options)).chain()
      .groupBy((role) => role.role)
      .reduce(
        ((rolesMap, roleList, roleKey) => {
          rolesMap[roleKey] = _(roleList).chain().filter((org) => !org._destroy).pluck('organization_id')
            .uniq()
            .value();
          return rolesMap;
        }),
        {})
      .value();
    json = _.extend(json, {
      app_metadata: {
        roles: rolesHash
      },
      user_metadata: _.pick(..._.flatten([json, this.metaDataAttrs]))
    }
    );
    return _.pick(json, 'app_metadata', 'user_metadata');
  }

  parse(resp, options = {}) {
    if (options.silent) { return resp; }
    resp = _.defaults(resp, resp.user_metadata, { app_metadata: {} });
    resp.roles = _(resp.app_metadata.roles).chain().map((orgIds, role) => {
      if (!_.isEmpty(orgIds)) {
        return _(orgIds).map((orgId) => ({ organization_id: orgId, role, _id: `${role}_${orgId}` }));
      }
    }).flatten()
      .compact()
      .value();
    return _(resp).omit('user_metadata', 'clientID', 'connection', 'global_client_id', 'identities', 'provider', 'sub', 'iss', 'aud', 'exp', 'iat');
  }

  resetPassword(options) {
    return $.ajax(_.extend(options, {
      url: this.auth0Url('/dbconnections/change_password'),
      type: 'POST',
      data: {
        email: this.get('email'),
        client_id: AUTH0_CLIENT_ID,
        connection: 'Username-Password-Authentication'
      }
    }
    )
    );
  }

  presentName() {
    return this.get('full_name');
  }

  isModifiable() {
    if (App.getChannel().request('get:current:user').isSupport()) { return true; }
    const currentUserRoles = App.getChannel().request('get:current:user').groupByRoles();
    const roles = this.groupByRoles();
    if (!_.isEmpty(_.intersection(currentUserRoles.manager, roles.user))) { return true; }
    if (!_.isEmpty(_.intersection(currentUserRoles.owner, roles.user))
      || !_.isEmpty(_.intersection(currentUserRoles.owner, roles.manager))) { return true; }
    if (this.isCurrentUser()) { return true; }
    return false;
  }

  isAdmin() {
    return this.get('app_metadata').admin;
  }

  isSupport() {
    return this.isAdmin() || this.get('app_metadata').support;
  }

  getOrgIdsByRole(...as) {
    return this.get('roles').map((role) => {
      if (_(as).contains(role.get('role'))) { return role.get('organization_id'); }
    });
  }

  isCurrentUser() {
    return this.id === App.getChannel().request('get:current:user').id;
  }

  isSimpleUser() {
    return this.get('roles').every((role) => role.get('role') === 'user');
  }

  hasOrgMembership(orgId) {
    return this.get('roles').some((role) => orgId === role.get('organization_id'));
  }

  isManagerInOrg(org) {
    if (this.isSupport()) { return true; }
    return this.get('roles').some((role) => (org.id === role.get('organization_id')) && role.isManager());
  }

  isRentalManager() {
    const organizations = App.getChannel().request('get:organizations');
    return this.get('roles').some((role) => role.isManager() && __guard__(organizations.get(role.get('organization_id')), (x) => x.isRental()));
  }

  isManager() {
    if (this.isSupport()) { return true; }
    return this.get('roles').some((role) => role.isManager());
  }

  isOwner() {
    if (this.isSupport()) { return true; }
    return this.get('roles').some((role) => role.isOwner());
  }

  hasEmptyRoles() {
    return this.get('roles').isEmpty();
  }

  isOrgOwner(org) {
    if (this.isSupport()) { return true; }
    return this.get('roles').some((role) => (org?.id === role.get('organization_id')) && role.isOwner());
  }

  getRoleName(org) {
    const roles = this.get('roles').reduce(((memo, o, k) => {
      if (o.id === org.id) { memo.push(k); }
      return memo;
    }), []);
    return roles[0];
  }

  getUserRoles() {
    return App.getChannel().request('get:current:user').groupByRoles();
  }

  getPhoneContryCodeNumber(ccn) {
    return (ccn != null ? ccn.split('_')[1] : undefined);
  }

  getPhoneContryCode(ccn) {
    return (ccn != null ? ccn.split('_')[0].toUpperCase() : undefined);
  }

  seenByOrg(org) {
    return this.get('roles').some((role) => org.id === role.get('organization_id'));
  }

  groupByRoles() {
    const result = {};
    this.get('roles').each((role) => {
      const roleName = role.get('role');
      if (!result[roleName]) { result[roleName] = []; }
      return result[roleName].push(role.get('organization_id'));
    });
    return result;
  }
}

export class CurrentUserModel extends UserModel {
  isAdmin() {
    return this.isHiddenAdmin() && !this.hasAccessContextDesigned();
  }

  isSupport() {
    return this.isHiddenSupport() && !this.hasAccessContextDesigned();
  }

  hasDefaultLocale() {
    return this.defaults.locale === this.get('locale');
  }

  hasAccessContextDesigned() { return !!this.origRoles; }

  getDesignedRoles() { return this.parse(this.metadata).roles; }

  isHiddenAdmin() {
    return this.get('app_metadata').admin;
  }

  isHiddenSupport() {
    return this.isHiddenAdmin() || this.get('app_metadata').support;
  }

  designRoles(metadata) {
    this.metadata = metadata;
    if (!this.origRoles) { this.origRoles = this.get('roles').toJSON(); }
    return this.set('roles', this.getDesignedRoles());
  }

  resetRoles() {
    if (!this.origRoles) { return; }
    this.set({ roles: this.origRoles });
    this.metadata = null;
    return this.origRoles = null;
  }

  updateProfile(newProfile) {
    const parsedProfile = this.parse(newProfile);
    if (this.hasAccessContextDesigned()) { parsedProfile.roles = this.getDesignedRoles(); }
    return this.set(parsedProfile);
  }

  hasAlertReferences() {
    return this.isAdmin() || this.isSupport();
  }
}

export class UsersCollection extends ValueLabelPairs(AppCollection) {
  get model() { return UserModel; }

  url() { return Routes.users_path(); }

  parse(resp) {
    if (!_.isArray(resp)) {
      const { users } = resp;
      resp = users;
    }
    return _(resp).chain()
      .filter((user) => !user.app_metadata.deleted).map((user) => UserModel.prototype.parse(user))
      .value();
  }

  toJSON() {
    return _(super.toJSON(...arguments)).filter((user) => !user.app_metadata.deleted); // eslint-disable-line prefer-rest-params
  }

  getPagesCount() {
    return Math.ceil(this.total / this.limit);
  }
}

export const API = MnObject.extend({
  channelName: 'entities:user',

  radioRequests: {
    'fetch:users': 'fetchUsers',
    'get:users': 'getUsers',
    'fetch:user': 'getUserEntity',
    'new:user': 'newUserEntity',
    'new:current_user': 'newCurrentUserEntity',
    'handle:updated:context': 'handleUpdatedContext',
    'has:alert:references': () => true
  },

  handleUpdatedContext() {
    auth0Api.getChannel().request('get:instance').updateCurrentUser((userProfile) => {
      App.getChannel().request('get:current:user').updateProfile(userProfile);
      reduxStore.dispatch(fetchOrganizationsListRequest());
    });
  },

  fetchUsers(params) {
    if (params == null) { params = {}; }
    const users = new UsersCollection();
    users.fetch({
      data: params,
      reset: true,
      parse: true
    });
    return users;
  },

  getUsers() {
    return new UsersCollection();
  },

  getUserEntity(id) {
    const user = new UserModel({ user_id: id });
    user.fetch();
    return user;
  },

  newUserEntity(attrs, options = {}) {
    options = _.defaults(options, { parse: true });
    return new UserModel(attrs, options);
  },

  newCurrentUserEntity(attrs, options = {}) {
    options = _.defaults(options, { parse: true });
    return new CurrentUserModel(attrs, options);
  }
});

export const api = new API();
