/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default (superclass) => class extends superclass {
  initialize(...args) {
    super.initialize(...args);
    return this.on('change:size change:position change:size', (model, value, options) => {
      // eslint-disable-next-line no-param-reassign
      if (options == null) { options = {}; }
      if (!options.savedByForm && !options.onSave && !options.savedBySync && !this.isFormModel) {
        this.save(null, { savedBySync: true });
      }
    });
  }
};
