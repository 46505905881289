/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';

import { saveAs } from 'file-saver';
import { app as App } from 'app/backbone/app';
import { api as auth0Api } from 'app/utils/auth0_handler';

export default class Bridge {
  constructor({ clusternetUrl }) {
    this.clusternetUrl = clusternetUrl;
  }

  getMeasurements(args, ...opts) {
    const params = {
      url: `${this.clusternetUrl}/search/measurements`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(args)
    };
    return Backbone.ajax(_.extend(params, ...Array.from(opts)));
  }

  getDataPoints(args, ...opts) {
    const params = {
      url: `${this.clusternetUrl}/search/data_points`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(_.extend(args))
    };
    return Backbone.ajax(_.extend(params, ...Array.from(opts)));
  }

  downloadPdf(args, filename, opts) {
    return Backbone.ajax(_.extend({
      url: `${this.clusternetUrl}/pdf?pdf.landscape=true&pdf.scale=1.5`,
      type: 'POST',
      contentType: 'text/html',
      data: args,
      headers: {
        Authorization: auth0Api.getChannel().request('get:instance').getAuthToken()
      },
      xhrFields: {
        responseType: 'blob'
      },
      success(response) {
        return saveAs(response, filename);
      }
    },
    opts)
    );
  }

  createStream() {
    return Backbone.ajax({
      url: `${this.clusternetUrl}/stream`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({ node_ids: [] })
    });
  }

  updateStream(uuid, data) {
    return Backbone.ajax({
      url: `${this.clusternetUrl}/stream/${uuid}`,
      type: 'PUT',
      contentType: 'application/json',
      data
    });
  }

  streamEventsUrl(uuid) {
    return `${this.clusternetUrl}/stream/${uuid}/events`;
  }

  thiamisFilesUrl(serial) {
    return `${this.clusternetUrl || ''}/thiamis/${serial}/lua`;
  }

  downloadThiamisFilesUrl(serial) {
    return `${this.clusternetUrl || ''}/thiamis/${serial}.tgz`;
  }

  thiamisSetEndpoint(serial, data) {
    return Backbone.ajax({
      url: `${this.clusternetUrl}/thiamis/${serial}/endpoint`,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify(data)
    });
  }

  thiamisRunAction(nodeId, action) {
    return Backbone.ajax({
      url: `${this.clusternetUrl}/nodes/${nodeId}/actions/${action}`,
      type: 'PUT',
      contentType: 'application/json'
    });
  }

  thiamisExportUrl() {
    return `${this.clusternetUrl || ''}/search/export`;
  }

  filesUrl() {
    return `${this.clusternetUrl || ''}/snapshots`;
  }

  nodesUrl() {
    return `${this.clusternetUrl || ''}/nodes`;
  }

  firmwareUrl() {
    return `${this.clusternetUrl || ''}/firmware`;
  }

  projectsUrl() {
    return `${this.clusternetUrl || ''}/projects`;
  }

  permissionSchemasUrl() {
    return `${this.clusternetUrl || ''}/permission_schemas`;
  }

  apiUrl() {
    return `${this.clusternetUrl || ''}/api_keys`;
  }

  nodesMetricsUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/metrics`;
  }

  nodesCalibrateActionUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/actions/calibrate`;
  }

  availableDevicesUrl() {
    return `${this.clusternetUrl || ''}/nodes/available_devices`;
  }

  availableConditionsUrl() {
    return `${this.clusternetUrl || ''}/nodes/available_conditions`;
  }

  batchFetchCurrentDevicesUrl() {
    return `${this.clusternetUrl || ''}/search/nodes`;
  }

  batchFetchStatsUrl() {
    return `${this.clusternetUrl || ''}/search/stats`;
  }

  batchFetchDataPointsUrl() {
    return `${this.clusternetUrl || ''}/search/data_points`;
  }

  shareNodesUrl(nodeId, orgId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/shares/${orgId}`;
  }

  paymentUpdateUrl(nodeId, orgId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/payment/${orgId}`;
  }

  leaseNodesUrl(nodeId, orgId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/lease/${orgId}`;
  }

  removeLeaseNodesUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/lease`;
  }

  transferNodesUrl(nodeId, orgId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/transfer/${orgId}`;
  }

  activateNodesUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/activate`;
  }

  deactivateNodesUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/deactivate`;
  }

  hibernateNodesUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/hibernate`;
  }

  resetNodesUrl(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/reset`;
  }

  refreshNodesWifiList(nodeId) {
    return `${this.clusternetUrl || ''}/nodes/${nodeId}/actions/wifi_scan`;
  }
}

const API = MnObject.extend({
  channelName: 'clusternet:bridge',

  radioRequests: {
    'get:instance': 'getBridgeInstance'
  },

  getBridgeInstance() {
    if (!_.isUndefined(this.bridge)) { return this.bridge; }
    const clusternetUrl = App.getChannel().request('clusternet:url');
    return this.bridge = new Bridge({ clusternetUrl });
  }
});

export const api = new API();
