import React from 'react';
import { connect } from 'react-redux';

import { getUserInvitationsIsFetched } from '~/store/reducers/invitations';
import Spinner from '~/components/spinner';
import ListTable from '~/components/list_table';

import { getFilteredInvitationsList } from '../selectors';
import Table from './table';

const InvitationsList = ({ isActive, rows, isFetched }) => {
  if (!isFetched) {
    return (
      <Spinner />
    );
  }

  return (
    <ListTable className="invitations-list-table" isActive={isActive}>
      <Table rows={rows} />
    </ListTable>
  );
};

const mapStateToProps = (state, { slug }) => ({
  isFetched: getUserInvitationsIsFetched(state),
  rows: getFilteredInvitationsList(state, slug)
});

export default connect(mapStateToProps)(InvitationsList);
