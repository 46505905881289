import {
  call,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';
import { normalizeResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import requestApi from '~/utils/axios';
import * as Actions from './reducer';

export function* firmwaresFetchRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'get',
      'firmware'
    );
    const response = normalizeResp(data, schema.firmwaresListSchema);
    yield put(Actions.fetchFirmwaresSuccess(response));
  } catch (e) {
    yield put(Actions.fetchFirmwaresFailure(e.toString()));
  }
}

function* watchFetchFirmwaresRequest() {
  yield takeLatest(Actions.fetchFirmwaresRequest.toString(), firmwaresFetchRequestSaga);
}

export function* firmwaresRolloutRequestSaga({ payload }) {
  try {
    const {
      version, channel, serials, onSuccess
    } = payload;
    const data = yield call(
      requestApi,
      'put',
      'firmware/rollout',
      { version, channel, serials }
    );
    const response = normalizeResp(data, schema.firmwaresListSchema);
    yield put(Actions.rolloutFirmwaresSuccess(response));
    if (onSuccess) {
      onSuccess({ version, channel, serials });
    }
  } catch (e) {
    yield put(Actions.rolloutFirmwaresFailure(e.toString()));
  }
}

function* watchRolloutFirmwaresRequest() {
  yield takeLatest(Actions.rolloutFirmwaresRequest.toString(), firmwaresRolloutRequestSaga);
}

export default function* app() {
  yield fork(watchFetchFirmwaresRequest);
  yield fork(watchRolloutFirmwaresRequest);
}
