import React from 'react';
import I18n from 'app/config/i18n';
import DeviceStatus from '~/components/device_status';

export const StatusFormatter = ({ row: { status } }) => (
  <DeviceStatus
    statusType={status}
    statusText={I18n.t(`thiamis.states.${status}`, { defaultValue: I18n.t('base.labels.n_a') })}
    iconClassName="fa-lg"
  />
);
