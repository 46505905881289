import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import I18n from 'app/config/i18n';
import ConfirmLinkButton from 'app/backbone/lib/react/components/common/ConfirmLinkButton/ConfirmLinkButton';
import { api as countriesApi } from 'app/backbone/entities/abstract/countries';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import { api as organizationsApi } from 'app/backbone/apps/organizations/organizations_app';
import {
  fetchOrganizationDetailsRequest, getOrganizationIsSyncing, getOrganizationIsSynced, destroyOrganizationRequest
} from '~/store/reducers/organizations';
import {
  getOrganizationByIdContactPhone,
  getOrganizationByIdContactEmail,
  getOrganizationByIdShippingAddressCity,
  getOrganizationByIdShippingAddressStreet,
  getOrganizationByIdShippingAddressState,
  getOrganizationByIdShippingAddressZipCode,
  getOrganizationByIdShippingAddressCountry,
  getOrganizationByIdIsDestroyable,
  getOrganizationByIdIsModifiable
} from '~/store/selectors/organizations';
import { getOrganizationByIdDeviceCounts } from '../../selectors';

const GridDetailContainer = ({ row }) => {
  const organizationId = row?._id;
  const {
    isSyncing,
    isSynced,
    isModifiable,
    isDeletable,
    phoneNumber,
    email,
    shippingAddressStreet,
    shippingAddressState,
    shippingAddressCity,
    shippingAddressZipCode,
    shippingAddressCountry,
    deviceCounts
  } = useSelector(
    (state) => ({
      phoneNumber: getOrganizationByIdContactPhone(state, organizationId),
      email: getOrganizationByIdContactEmail(state, organizationId),
      shippingAddressStreet: getOrganizationByIdShippingAddressStreet(state, organizationId),
      shippingAddressState: getOrganizationByIdShippingAddressState(state, organizationId),
      shippingAddressCity: getOrganizationByIdShippingAddressCity(state, organizationId),
      shippingAddressZipCode: getOrganizationByIdShippingAddressZipCode(state, organizationId),
      shippingAddressCountry: getOrganizationByIdShippingAddressCountry(state, organizationId),
      isSyncing: getOrganizationIsSyncing(state, organizationId),
      isSynced: getOrganizationIsSynced(state, organizationId),
      deviceCounts: getOrganizationByIdDeviceCounts(state, organizationId),
      isModifiable: getOrganizationByIdIsModifiable(state, organizationId),
      isDeletable: getOrganizationByIdIsDestroyable(state, organizationId)
    })
  );
  const isValid = email && phoneNumber;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isSynced && !isSyncing && isValid) {
      dispatch(fetchOrganizationDetailsRequest({ organizationId }));
    }
  }, [organizationId, dispatch, isSynced, isSyncing, isValid]);

  const handleEditOrganizationClick = () => organizationsApi.getChannel().request('app:organizations:show', organizationId);
  const handleDeleteOrganization = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(destroyOrganizationRequest({ organizationId }));
  };
  const country = countriesApi.getChannel().request('get:country', shippingAddressCountry);
  return (
    <div className={classNames('more-info', 'tr', { loading: isSyncing })}>
      <div className="gray-text td">
        <ul>
          {
            deviceCounts.map(([name, count]) => (
              <li key={name}>
                <a role="button">{name}</a>
                <span>{count}</span>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="td contact" />
      <div className="td location-col">
        <ul>
          <li>{shippingAddressStreet}</li>
          <li>{shippingAddressCity && `${shippingAddressCity}, ${shippingAddressState || ''} ${shippingAddressZipCode}`}</li>
          <li>{country?.name}</li>
        </ul>
      </div>
      <div className="actions-container td actions-col">
        { isModifiable
        && (
          <LinkButton
            className="info"
            icon="fa-md fa-fw fa-pencil"
            title={I18n.t('alerts.edit_configuration')}
            onClick={handleEditOrganizationClick}
          />
        ) }
        <br />
        { isDeletable
        && (
          <ConfirmLinkButton
            className="info"
            icon="fa-md fa-fw fa-trash"
            title={I18n.t('base.buttons.delete')}
            onConfirm={handleDeleteOrganization}
            btnOkLabel={I18n.t('base.buttons.delete')}
            placement="left"
          />
        ) }
      </div>
    </div>
  );
};

export default GridDetailContainer;
