/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import RecentMeasurementsWidgetContentView from 'app/backbone/apps/thiamises/monitor/widgets/recent_measurements_widget/face_view';
import ThiamisInfoWidgetContentView from 'app/backbone/apps/thiamises/monitor/widgets/thiamis_info_widget/face_view';
import ChartTableWidgetContentView from 'app/backbone/apps/thiamises/monitor/widgets/chart_table_widget/face_view'; // eslint-disable-line import/no-cycle
import SensorWidgetContentView from 'app/backbone/apps/thiamises/monitor/widgets/sensor_widget/face_view';
import StaticMapWidgetContentView from 'app/backbone/lib/widgets/static_map_widget/face_view';

export default {
  requiredOptionNames: ['thiamises', 'thiamis'],
  buttons: [],
  widgets: {
    RecentMeasurementsWidget: {
      confView() { throw new Error('No config for RecentMeasurementsWidget'); },
      faceView() { return RecentMeasurementsWidgetContentView; }
    },
    ThiamisInfoWidget: {
      confView() { throw new Error('No config view for ThiamisInfoWidget'); },
      faceView() { return ThiamisInfoWidgetContentView; }
    },
    StaticMapWidget: {
      confView() { throw new Error('No config view for StaticMapWidget'); },
      faceView() { return StaticMapWidgetContentView; }
    },
    ChartTableWidget: {
      confView() { throw new Error('No config view for ChartTableWidget'); },
      faceView() { return ChartTableWidgetContentView; },
      perPage: 13,
      debugPerPage: 20
    },
    SensorWidget: {
      confView() { throw new Error('No config view for SensorWidget'); },
      faceView() { return SensorWidgetContentView; }
    }
  }
};
