import { createSelector } from 'reselect';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import * as fromUsers from '~/store/reducers/users';
import { getCurrentUserEmail } from '~/store/selectors/current_user';
import * as fromUserSelectors from '~/store/selectors/users';
import { getOrganizations } from '~/store/reducers/organizations';
import { getFiledUserInvitations } from '~/store/selectors/user_invitations';
import { getFilter } from './reducer';

const searchFields = ['email', 'user_metadata.family_name', 'user_metadata.given_name'];

export const getUsers = createSelector(
  fromUsers.getUsersIds,
  fromUsers.getUsers,
  (userIds, users) => userIds.map((id) => users[id])
);

export const getFilteredList = createSelector(
  getUsers,
  getFilter,
  (users, { query }) => {
    if (!isEmpty(query)) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      return users.filter((user) => {
        const searchString = searchFields.map((path) => get(user, path)).join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return users;
  }
);

export const getFilteredInvitationsList = createSelector(
  getFiledUserInvitations,
  getFilter,
  getCurrentUserEmail,
  (invitations, { query }, currentUserEmail) => {
    let results = invitations
      .map((invitation) => ({
        ...invitation,
        incoming: invitation.email === currentUserEmail
      }));
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((user) => {
        const { email } = user;
        const searchString = email.toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return results;
  }
);

export const getUserContactDetails = createSelector(
  fromUserSelectors.getUserByIdUserMetadata,
  (user_metadata = {}) => ({
    phone: user_metadata.phone || '',
    mobile: user_metadata.mobile || ''
  })
);

export const getUserByIdAllOrganizations = createSelector(
  fromUserSelectors.getUserByIdRoles,
  getOrganizations,
  (roles, organizations) => fromUserSelectors.getUserOrganizationsMapping(roles, Object.values(organizations))
);

export const getUserOrganizationWithHighestRole = createSelector(
  fromUserSelectors.getUserOrganizationIdWithHighestRole,
  getOrganizations,
  (role = {}, organizations) => ({
    ...role,
    organizationName: organizations[role?.organizationId]?.name
  })
);

export const getRolesExceptHighest = createSelector(
  getUserByIdAllOrganizations,
  getUserOrganizationWithHighestRole,
  (organizations, role) => organizations.filter((item) => item.organizationId !== role?.organizationId)
);
