/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import classNames from 'classnames';
import I18n from 'app/config/i18n';
import { withTooltip } from 'app/backbone/lib/react/hocs';

export default withTooltip((props) => {
  const isValueDefined = () => _.isDefined(props.value);

  const getDisplayValue = () => {
    if (isValueDefined()) { return props.displayValue; } return I18n.t('base.labels.n_a');
  };

  return (
    <div ref={props.tooltipRef} title={props.title} className={classNames('param-info-icon', { empty: !isValueDefined() })}>
      <i className={props.iconClass} />
      <span className="truncated">{getDisplayValue()}</span>
    </div>
  );
});
