import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { app as App } from 'app/backbone/app';
import Spinner from '~/components/spinner';
import QuickNavButtonsAlertsContainer from '~/components/alerts_quick_nav_buttons_container';
import { fetchAlertDetailsRequest, fetchAlertsRequest } from '~/store/reducers/alerts';
import {
  isAlertModifiable, getAlertIsSynced, getAlertsIsFetched, getAlertByIdActions, getAlertIsSyncing
} from '~/store/selectors/alerts';
import InfoBlock from './info_block';
import NodesBlock from './nodes_block';
import SubscriptionsBlock from './subscriptions_block';
import ConditionsBlock from './conditions_block';
import StatsContainer from './stats_container';

const AlertMonitorScreen = (props) => {
  const { slug, id } = props;
  const { t } = useTranslation();

  const {
    isSynced, isFetched, isModifiable, isSyncing
  } = useSelector((state) => ({
    isFetched: getAlertsIsFetched(state),
    isSynced: getAlertIsSynced(state, id),
    isSyncing: getAlertIsSyncing(state, id),
    isModifiable: isAlertModifiable(getAlertByIdActions(state, id))
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFetched) {
      dispatch(fetchAlertsRequest());
    }
  }, [dispatch, isFetched]);

  useEffect(() => {
    if (!isSyncing && !isSynced) {
      dispatch(fetchAlertDetailsRequest({ alertId: id, params: { from: moment().subtract(1, 'year').valueOf() } }));
    }
  }, [dispatch, isFetched, id, isSynced, isSyncing]);

  if (!isSynced || isSyncing) {
    return <Spinner />;
  }

  return (
    <div className="alerts-monitor-page">
      <div className="content-top clearfix">
        <div className="pull-left">
          {
            isModifiable
            && (
              <a role="button" className="btn btn-default modify-alert" onClick={() => App.navigate(`alerts/${id}`)}>
                <i className="fa fa-md fa-fw fa-gear" />
                <span>{t('thiamis.edit_configuration')}</span>
              </a>
            )
          }
        </div>
        <div className="nav-buttons-region">
          <QuickNavButtonsAlertsContainer
            selectedId={id}
            slug={slug}
            onChange={(alertId) => App.navigate(`alerts/${alertId}/monitor`)}
          />
        </div>
      </div>
      <div className="alert-monitor-region">
        <div div className="row">
          <div className="col-sm-8 pr-75">
            <StatsContainer id={id} />
          </div>
          <div className="col-sm-4 pl-75">
            <InfoBlock id={id} />
            <NodesBlock id={id} />
            <ConditionsBlock id={id} />
            <SubscriptionsBlock id={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

AlertMonitorScreen.propTypes = {
  slug: PropTypes.string.isRequired
};

export default AlertMonitorScreen;
