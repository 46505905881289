/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
import endsWith from 'lodash/endsWith';
import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import flatten from 'lodash/flatten';
import I18n from 'app/config/i18n';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import get from 'lodash/get';
import filter from 'lodash/filter';
import HumanizeDuration from 'app/backbone/lib/concerns/views/humanize_duration';
import { PATHS, SENSOR_STATUSES } from 'app/react/utils/constants';
import * as fromNodes from '~/store/selectors/nodes';
import * as fromDataPoints from '~/store/reducers/data_points';

class Humanizer extends HumanizeDuration(class {}) {}
const humanizer = new Humanizer(class {});

export function parsePath(fullPath) {
  try {
    // eslint-disable-next-line no-param-reassign
    if (!fullPath) { return {}; }
    const [serial, device, path] = Array.from(fullPath.split(':'));
    return { serial, device, path };
  } catch (e) {
    // eslint-disable-next-line no-console
    return console.error(`invalid path ${fullPath}`);
  }
}

export const getPath = (dataPoint = {}) => {
  const { path = '' } = dataPoint;
  const [, device, pathId] = path.split(':');
  return `${device}:${pathId}`;
};

function isDefault(dataPoint = {}) {
  const { _type, path: dataPointPath } = dataPoint;
  const { device, path } = parsePath(dataPointPath);
  const isCorrectType = !includes(['info', 'status', 'location', 'debug', 'error', 'raw', 'config'], _type);
  // if (App.getChannel().request('get:current:user').isSupport()) { return isCorrectType; }
  return isCorrectType && !includes(['0:23', '0:24', '0:25'], `${device}:${path}`);
}

export const getDataPointById = (state, id) => fromDataPoints.getDataPoint(state, id);
export const getDataPointsByIds = (state, ids = []) => ids.map((id) => fromDataPoints.getDataPoint(state, id));

export const getDataPointByIdNodeId = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?.node_id
);

export const getDataPointByIdLinked = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?.linked
);

export const getDataPointByIdName = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?.name
);

export const getDataPointByIdPath = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?.path
);

export const getDataPointByIdUnit = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?.unit
);

export const getDataPointByIdConditions = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?.conditions
);

export const getDataPointByIdType = createSelector(
  fromDataPoints.getDataPoint,
  (dataPoint) => dataPoint?._type
);

export const getDataPointByIdPresentName = createSelector(
  [getDataPointByIdName, getDataPointByIdUnit],
  (name, unit) => {
    if (isEmpty(unit)) {
      return name;
    } return `${name} ${unit}`;
  }
);

export const getDataPointParameterById = createSelector(
  fromDataPoints.getDataPoint, getDataPointByIdPresentName,
  (dataPoint, name) => ({
    path: getPath(dataPoint),
    name
  })
);

export const getConditionName = (conditions = [], state) => {
  let name = '';
  return conditions.map((condition = {}) => {
    const {
      func, dataPointId, dataPointId2, conditions: refConditions
    } = condition;
    let { value } = condition;
    const funcLabel = I18n.t(`conditions.expressions.${func}`);
    if (refConditions) {
      if (func === 'diff') {
        const firstDiff = getConditionName([refConditions[0]], state);
        let secondDiff;
        if (refConditions[1]) {
          secondDiff = getConditionName([refConditions[1]], state);
        } else if (getDataPointByIdType(state, value) === 'metric') {
          secondDiff = getConditionName(getDataPointByIdConditions(state, value), state);
        }
        return `(${firstDiff}) ${I18n.t('conditions.expressions.diff')} (${secondDiff})`;
      }
      name += `(${getConditionName(refConditions, state)})`;
    } else {
      if (func === 'diff') {
        let name1 = getDataPointByIdPresentName(state, dataPointId);
        let name2 = getDataPointByIdPresentName(state, dataPointId2);
        const type1 = getDataPointByIdType(state, dataPointId);
        const type2 = getDataPointByIdType(state, dataPointId2);
        let path1 = getDataPointByIdPath(state, dataPointId);
        if (!path1 && type1 === 'metric') {
          const linked = get(getDataPointByIdLinked(state, dataPointId), '[0]', []);
          path1 = getDataPointByIdPath(state, linked);
        }
        let path2 = getDataPointByIdPath(state, dataPointId2);
        if (!path2 && type2 === 'metric') {
          const linked = get(getDataPointByIdLinked(state, dataPointId2), '[0]', []);
          path2 = getDataPointByIdPath(state, linked);
        }
        const serial1 = get(parsePath(path1), 'serial');
        const serial2 = get(parsePath(path2), 'serial');
        name1 = `[${serial1}] ${name1}`;
        name2 = `[${serial2}] ${name2}`;
        return `${name1} ${funcLabel} ${name2}`;
      }
      name = getDataPointByIdPresentName(state, dataPointId);
    }
    if (includes(['true', 'false', SENSOR_STATUSES.REPORTING, true, false], value)) {
      value = I18n.t(`data_points.status_${isBoolean(value) ? value.toString() : value}`);
    }
    if (funcLabel === 'ABS') {
      return name && `${name} ${funcLabel}`;
    }
    if (includes(['AVG', 'MIN', 'MAX'], funcLabel) && isNumber(value)) {
      value = humanizer.humanizeDuration(value);
    }
    return name && `${name} ${funcLabel} ${value}`;
  });
};

export const getDataPointByIdConditionsPresentName = createSelector(
  [getDataPointByIdConditions, (state) => state],
  getConditionName
);

const isDataPointWithProfile = (dataPoint) => !!dataPoint.profile;

export const getDefaultDataPointIdsByNodeId = createSelector(
  [fromNodes.getNodeDataPointsIds, fromDataPoints.getDataPoints],
  (dataPointsIds, dataPoints = {}) => dataPointsIds.filter((dataPointId) => isDefault(dataPoints[dataPointId]))
);

export const getDataPointTimezoneDatapoint = createSelector(
  [fromNodes.getNodeById, fromDataPoints.getDataPoints],
  (node, dataPoints = {}) => get(node, 'data_points', [])
    .find((datapointId) => _.endsWith(dataPoints[datapointId]?.path, PATHS.TIMEZONE))
);

export const getDataPointsWithProfileByNodeId = createSelector(
  [fromNodes.getNodeById, fromDataPoints.getDataPoints],
  (node, dataPoints = {}) => get(node, 'data_points', [])
    .filter((dataPointId) => (isDefault(dataPoints[dataPointId]) && isDataPointWithProfile(dataPoints[dataPointId])))
    .map((dataPointId) => dataPoints[dataPointId])
);

export const getDefaultDataPointsByNodeId = createSelector(
  [fromNodes.getNodeById, fromDataPoints.getDataPoints],
  (node, dataPoints = {}) => get(node, 'data_points', [])
    .filter((dataPointId) => (isDefault(dataPoints[dataPointId])))
    .map((dataPointId) => dataPoints[dataPointId])
);

export const getDataPointIdByPath = createSelector(
  [fromNodes.getNodeDataPointsIds, fromDataPoints.getDataPoints, (state, nodeId, path) => path],
  (dataPointsIds, dataPoints = {}, path) => dataPointsIds.find((id) => endsWith(dataPoints[id].path, path))
);

export const getDataPointIdsByPaths = createSelector(
  [fromNodes.getNodeDataPointsIds, fromDataPoints.getDataPoints, (state, nodeId, ...paths) => paths],
  (dataPointsIds, dataPoints = {}, paths) => dataPointsIds.filter((id) => paths.some((path) => endsWith(dataPoints[id].path, path)))
);

export const getDataPointsByDeviceId = createSelector(
  [fromNodes.getNodeDataPointsIds, fromDataPoints.getDataPoints, (state, nodeId, deviceId) => deviceId],
  (dataPointsIds, dataPoints = {}, deviceId) => dataPointsIds
    .filter((dpId) => dataPoints[dpId]?.device_id === deviceId)
    .map((dataPointId) => dataPoints[dataPointId])
);

export const getDataPointIdByName = createSelector(
  [getDataPointsByDeviceId],
  (dataPoints) => memoize((name) => get(find(dataPoints, (dataPoint) => dataPoint?.name?.toLowerCase() === name), '_id'))
);

export const getDataPointIds = (dataPoints) => dataPoints.map(({ _id }) => _id);

export const getDataPointIdsByTypeError = createSelector(
  [getDataPointsByDeviceId],
  (dataPoints) => getDataPointIds(filter(dataPoints, ['_type', 'error']))
);

export const getDataPointIdsByTypeDefault = createSelector(
  [getDataPointsByDeviceId],
  (dataPoints) => getDataPointIds(filter(dataPoints, isDefault))
);

export const getParametersForDropdown = (dataPoints) => {
  const mapResult = {};
  flatten(dataPoints)
    .forEach(({ _id, node_id, ...dataPoint }) => {
      const { device, path } = parsePath(dataPoint.path);
      const value = `${device}:${path}`;
      if (mapResult[value]) {
        mapResult[value].dataPoints = [
          ...mapResult[value].dataPoints,
          { _id, node_id }
        ];
      } else {
        mapResult[value] = {
          value,
          label: dataPoint.unit ? `${dataPoint.name} (${dataPoint.unit})` : dataPoint.name,
          dataPoints: [{ _id, node_id }]
        };
      }
    });
  return Object.values(mapResult)
    .sort((node1, node2) => (node1.label || '').localeCompare(node2.label || ''));
};
