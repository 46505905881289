/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import humanizeDuration from 'humanize-duration';

export default (superclass) => class extends superclass {
  getShortHumanizer() {
    return this.shortEnglishHumanizer != null ? this.shortEnglishHumanizer : (this.shortEnglishHumanizer = humanizeDuration.humanizer({
      language: 'shortEn',
      languages: {
        shortEn: {
          d() { return 'd'; },
          h() { return 'h'; },
          m() { return 'm'; }
        }
      }
    }));
  }

  humanizeDuration(valueInMins) {
    return this.getShortHumanizer()(valueInMins * 60 * 1000, { spacer: '', units: ['d', 'h', 'm'] });
  }
};
