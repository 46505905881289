/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default (superclass) => class extends superclass {
  initialize(attrs, options = {}) {
    super.initialize(attrs, options);
    return { isSubscribable: this.isSubscribable } = options;
  }

  getDataPoints() {
    if (this.isSubscribable) {
      return this.getNodeDataStream().getDataPointsColl();
    } return this.get('data_points');
  }

  getXhrs() {
    const xhrs = [this._fetch];
    if (this.isSubscribable) { xhrs.push(this.getNodeDataStream().getDeferred()); }
    return xhrs;
  }
};
