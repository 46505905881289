var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n      <li><a role=\"button\" class=\"design-access-context nav-item\"><span>"
    + container.escapeExpression(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"links.access_context",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":4,"column":72},"end":{"line":4,"column":103}}}))
    + "</span><i class=\"fa fa-md fa-rocket\"></i></a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"main-nav-region\"></nav>\n<nav class=\"support-nav\">\n  <ul>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSupport") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "    <li><a href=\"#\" class=\"ask-support nav-item\"><span>"
    + container.escapeExpression(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"links.support",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":55},"end":{"line":6,"column":79}}}))
    + "</span><i class=\"fa fa-md fa-question\"></i></a></li>\n  </ul>\n</nav>";
},"useData":true});