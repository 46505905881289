import React from 'react';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';

const FiltersSidebarPanelItem = React.memo((props) => {
  const {
    type, label, count, active, handleChoose
  } = props;

  const handleChooseFilter = () => {
    handleChoose(type);
  };

  return (
    <li className={classNames(`filter-${kebabCase(type)}`, { active })}>
      <a role="button" className="filters" onClick={handleChooseFilter}>
        <span className="truncated">{label}</span>
        <span className="count badge">{count}</span>
      </a>
    </li>
  );
});

export default FiltersSidebarPanelItem;
