import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import includes from 'lodash/includes';
import startCase from 'lodash/startCase';
import {
  SortingState,
  RowDetailState,
  DataTypeProvider,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import attributeName from 'app/config/hbs-helpers/attributeName';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from './expand_button';
import GridDetailContainer from './detail_row';
import { UnitsFormatter, ChannelFormatter, StatusFormatter } from './formatters';

const UnitsProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={UnitsFormatter}
  />
);

const StatusProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={StatusFormatter}
  />
);

const ChannelProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={ChannelFormatter}
  />
);

const statusWeights = {
  removed: 0,
  available: 1
};

const compareStatuses = (a, b) => {
  const statusA = statusWeights[a];
  const statusB = statusWeights[b];
  if (statusA === statusB) {
    return 0;
  }
  return (statusA < statusB) ? -1 : 1;
};

const compareUnits = (a, b) => {
  const lengthA = a.length;
  const lengthB = b.length;
  if (lengthA === lengthB) {
    return 0;
  }
  return (lengthA < lengthB) ? -1 : 1;
};

const columns = [
  { name: 'version', title: attributeName('firmware', 'version') },
  { name: 'thiamis', title: attributeName('firmware', 'thiamis') },
  {
    name: 'channel',
    title: attributeName('firmware', 'channel'),
    getCellValue({ channel }) {
      return channel?.map((value) => startCase(value)).join(', ');
    }
  },
  { name: 'current_units', title: attributeName('firmware', 'current_units') },
  { name: 'pending_units', title: attributeName('firmware', 'pending_units') },
  { name: 'status', title: attributeName('firmware', 'status') }
];

const getRowId = ({ version }) => version;
const unitsColumns = ['current_units', 'pending_units'];
const channelColumns = ['channel'];
const statusColumns = ['status'];

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const Table = ({ rows }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'status', direction: 'desc' }, { columnName: 'version', direction: 'desc' }]);

  const Row = React.memo((props) => {
    const { row: { version, status } } = props;
    const isExpanded = includes(expandedRowIds, version);
    const isDisabled = status === 'removed';

    const handleExpandRowClick = useCallback(() => {
      if (isDisabled) {
        return;
      }
      if (includes(expandedRowIds, version)) {
        setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== version));
      } else {
        setExpandedRowIds([...expandedRowIds, ...[version]]);
      }
    }, [version, isDisabled]);
    return (
      <VirtualTable.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded, disabled: isDisabled })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  return (
    <Grid
      rows={rows}
      columns={columns}
      getRowId={getRowId}
      rootComponent={Root}
    >
      <SortingState
        sorting={sorting}
        onSortingChange={handleSortingChange}
      />
      <IntegratedSorting
        columnExtensions={[
          { columnName: 'status', compare: compareStatuses },
          { columnName: 'current_units', compare: compareUnits },
          { columnName: 'pending_units', compare: compareUnits }
        ]}
      />
      <ChannelProvider
        for={channelColumns}
      />
      <StatusProvider
        for={statusColumns}
      />
      <UnitsProvider
        for={unitsColumns}
      />
      <VirtualTable
        rowComponent={Row}
      />
      <RowDetailState
        expandedRowIds={expandedRowIds}
        onExpandedRowIdsChange={setExpandedRowIds}
      />
      <TableRowDetail
        contentComponent={GridDetailContainer}
        toggleCellComponent={ExpandButton}
      />
      <DetailRowColumnOrder />
      <TableHeaderRow align="center" showSortingControls />
    </Grid>
  );
};

export default Table;
