export default {
  brandBlue: '#00608F',
  brandGreen: '#3CB773',
  brandRed: '#DF5C4F',
  black: '#000000',
  darkGrey: '#343434',
  grey: '#9a9a9a',
  midGrey: '#CCCCCC',
  white: '#ffffff',
  nodeStatusEmpty: '#ccc',
  nodeGray: '#a9a9a9',
  nodeGreen: '#2fd273',
  redColor: '#d87f77',
  panelGray: '#666666',
  powermodeOff: '#e9e9e9',
  powermode25: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
  powermode50: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
  powermode75: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
  powermode100: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
  disabledColor: '#b7b7b7',
  autoOn: 'linear-gradient(90deg, #599bd9, #7aafe1)',
  autoOff: 'linear-gradient(90deg, #215b91, #2970b3)',
  textDefault: '#444444',
  inputBorder: '#ddd',
  greyColor: '#555',
  disabledGreyColor: '#999'
};
