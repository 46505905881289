import React, { useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useFormState, Field as FinalField, useForm } from 'react-final-form';
import Select from '~/components/form/select/select';
import { getNodesSerialsValueLabelPairs } from '~/store/selectors/nodes';
import { getAvailableFirmwaresValueLabelPairs, getAvailableChannelsById } from '../selectors';
import RolloutFirmwareContext from './context';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const {
    selectedFirmware
  } = useContext(RolloutFirmwareContext);
  const form = useForm();
  const { versionOptions, channelOptions, serialsOptions } = useSelector((state) => ({
    versionOptions: getAvailableFirmwaresValueLabelPairs(state),
    channelOptions: getAvailableChannelsById(state, selectedFirmware.value),
    serialsOptions: getNodesSerialsValueLabelPairs(state)
  }));
  const [firmwareType, setFirmwareType] = useState('channel');
  const { hasValidationErrors } = useFormState();
  const handleFirmwareTypeChange = useCallback((e) => {
    setFirmwareType(e.currentTarget.value);
    form.change('channel', null);
    form.change('serials', null);
  }, [setFirmwareType, form]);
  return (
    <div className="form form-horizontal access-context-form">
      <div className="form-region form-margins">
        <FinalField
          name="version"
          initialValue={selectedFirmware}
        >
          {
            ({ input }) => (
              <div className={classNames('form-group')}>
                <label className="col-sm-4">
                  {t('models.firmware')}
                </label>
                <div className="col-sm-8">
                  <Select
                    value={input.value}
                    onChange={input.onChange}
                    options={versionOptions}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
        <FinalField
          name="channel"
          validate={(value) => {
            if (firmwareType === 'channel' && isEmpty(value)) {
              return t('validation.errors.required');
            }
            return null;
          }}
        >
          {
            ({ input }) => (
              <div className={classNames('form-group')}>
                <input
                  type="radio"
                  onChange={handleFirmwareTypeChange}
                  checked={firmwareType === 'channel'}
                  value="channel"
                  style={{ left: 0, top: '-2px' }}
                  className="input-sm"
                />
                <label className="col-sm-4">
                  {t('attributes.firmware.channel')}
                </label>
                <div className="col-sm-8">
                  <Select
                    isDisabled={firmwareType === 'serials'}
                    value={input.value}
                    onChange={input.onChange}
                    options={channelOptions}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
        <div className="separator"><span>{t('base.labels.or')}</span></div>
        <FinalField
          name="serials"
          validate={(value) => {
            if (firmwareType === 'serials' && isEmpty(value)) {
              return t('validation.errors.required');
            }
            return null;
          }}
        >
          {
            ({ input }) => (
              <div className={classNames('form-group')}>
                <input
                  type="radio"
                  onChange={handleFirmwareTypeChange}
                  checked={firmwareType === 'serials'}
                  value="serials"
                  style={{ left: 0, top: '-2px' }}
                  className="input-sm"
                />
                <label className="col-sm-4">
                  {t('attributes.firmware.serials')}
                </label>
                <div className="col-sm-8">
                  <Select
                    isDisabled={firmwareType === 'channel'}
                    isMulti
                    allowSelectAll
                    value={input.value}
                    onChange={input.onChange}
                    options={serialsOptions}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
      </div>
      <div className="form-group form-actions clearfix">
        <button type="button" className="btn btn-default" onClick={handleCancel}>
          {t('base.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="save-form btn btn-danger"
          disabled={hasValidationErrors}
          onClick={handleSubmit}
        >
          {t('base.buttons.rollout')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
