import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  IntegratedSorting,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import ExpandButton from '~/components/react_grid/expand_button';
import ListTable from '~/components/list_table';
import DetailRowColumnOrder from './detail_row_column_order';
import GridDetailContainer from './detail_row';
import Spinner from '~/components/spinner';
import { getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { fetchWebhookListRequest } from '~/store/reducers/webhooks';
import { getWebhooksIsFetched, getWebhookByIdIsSyncing } from '~/store/selectors/webhooks';
import { getFilteredList } from './selectors';
import { clearFilters } from './reducer';
import {
  EnabledProvider,
  LastUpdatedProvider,
  LastResultProvider
} from './info_providers';

const columns = [
  { name: 'url', title: 'Url' },
  { name: 'organization_name', title: 'Organization' },
  { name: 'enabled', title: 'Enabled' },
  { name: 'timestamp', title: 'Last Updated' },
  { name: 'status', title: 'Result' }
];

const getRowId = ({ _id }) => _id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container webhooks-dx-grid-container" />;

const WebhooksContainer = () => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' }]);

  const Row = React.memo((props) => {
    const { row: { _id } } = props;
    const isExpanded = includes(expandedRowIds, _id);
    const isSyncing = useSelector((state) => getWebhookByIdIsSyncing(state, _id));

    return (
      <Table.Row
        {...props}
        className={classNames('body-row', { 'expanded-row': isExpanded, loading: isSyncing })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);
  const rows = useSelector(getFilteredList);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWebhookListRequest());
  }, [dispatch]);
  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const { isFetched } = useSelector(
    (state) => ({
      isFetched: getWebhooksIsFetched(state) && getOrganizationsIsFetched(state)
    })
  );

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <div className="webhook-list-table">
      <ListTable>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting />
          <EnabledProvider for={['enabled']} />
          <LastUpdatedProvider for={['timestamp']} />
          <LastResultProvider for={['status']} />
          <Table
            rowComponent={Row}
            columnExtensions={[
              { columnName: 'url', width: '30%' },
              { columnName: 'organization_name', width: '20%' },
              { columnName: 'enabled', width: '15%' },
              { columnName: 'timestamp', width: '15%' },
              { columnName: 'status', width: '15%' }
            ]}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <TableRowDetail
            contentComponent={GridDetailContainer}
            toggleCellComponent={({ expanded, row: { _id } }) => (
              <ExpandButton onClick={() => {
                if (expanded) {
                  setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== _id));
                } else {
                  setExpandedRowIds([...expandedRowIds, ...[_id]]);
                }
              }}
              />
            )}
          />
          <DetailRowColumnOrder />
          <TableHeaderRow align="center" showSortingControls />
        </Grid>
      </ListTable>
    </div>
  );
};

export default WebhooksContainer;
