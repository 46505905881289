/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppModel from 'app/backbone/lib/entities/app_model';
import { MnObject } from 'backbone.marionette';

class SearchInput extends AppModel {
  clean() {
    return this.unset('query');
  }
}

export const API = MnObject.extend({
  channelName: 'entities:search_input',
  radioRequests: {
    'get:search_input': 'getSearchInput',
    'set:search_input': 'setSearchInput',
    'clear:search_input': 'resetSearchInput'
  },

  getSearchInput() {
    return this.searchInput != null ? this.searchInput : (this.searchInput = new SearchInput());
  },

  setSearchInput(query) {
    return this.getSearchInput().set('query', query);
  },

  resetSearchInput() {
    return this.getSearchInput().clean();
  }
});

export const api = new API();
