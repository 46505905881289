/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import classNames from 'classnames';
import { DashboardLayout } from 'app/backbone/lib/widgets/widgets_base/report_views';
import { api as apiThiamises } from 'app/backbone/apps/thiamises/thiamises_app';
import { api as apiAlerts } from 'app/backbone/apps/alerts/alerts_app';
import layoutTemplate from './templates/layout.hbs';
import statsTemplate from './templates/stats.hbs';

export const Layout = DashboardLayout.extend({
  className() {
    return classNames('dashboard-page');
  },
  pageName: 'Dashboard'
});

export const HeaderView = View.extend({
  template: layoutTemplate,
  regions: {
    toolbarRegion: '.report-toolbar-region',
    statsRegion: '.report-stats-region'
  }
});

export const HeaderStatsView = View.extend({
  template: statsTemplate,
  className: 'row',

  ui: {
    statsContainer: '.fact-container',
    thiamisTotal: '.total-thiamis',
    thiamisOnline: '.thiamis-online',
    alertsActive: '.active-alerts'
  },

  triggers: {
    'click @ui.thiamisTotal': 'click:thiamis:total',
    'click @ui.thiamisOnline': 'click:thiamis:online',
    'click @ui.alertsActive': 'click:alerts:active'
  },

  bindings() {
    return {
      '@ui.statsContainer': {
        attributes: [{
          observe: 'configured_devices',
          name: 'class',
          onGet(thiamises) {
            if (thiamises > 0) { return 'clickable'; }
          }
        }]
      },
      '@ui.thiamisTotal': {
        attributes: [{
          observe: 'configured_devices',
          name: 'class',
          onGet(thiamises) {
            if (thiamises > 0) { return 'clickable'; }
          }
        }
        ]
      },
      '@ui.alertsActive': {
        attributes: [{
          observe: 'alert_enabled',
          name: 'class',
          onGet(alerts) {
            if (alerts > 0) { return 'clickable'; }
          }
        }
        ]
      },
      '@ui.thiamisOnline': {
        attributes: [{
          observe: 'online_devices',
          name: 'class',
          onGet(thiamises) {
            if (thiamises > 0) { return 'clickable'; }
          }
        }
        ]
      },
      '@ui.thiamisTotal .number': {
        observe: 'configured_devices',
        onGet(thiamises) {
          return thiamises;
        }
      },
      '@ui.thiamisOnline .number': {
        observe: 'online_devices',
        onGet(thiamises) {
          return thiamises;
        }
      },
      '@ui.alertsActive .number': {
        observe: 'alert_enabled',
        onGet(alerts) {
          return alerts;
        }
      }
    };
  },

  modelEvents: {
    sync: 'onSync',
    request: 'onRequest'
  },

  onSync() {
    this.ui.statsContainer.removeClass('syncing');
  },

  onRequest() {
    this.ui.statsContainer.addClass('syncing');
  },

  onClickThiamisTotal() {
    if (this.model.get('configured_devices') === 0) { return; }
    return apiThiamises.getChannel().request('app:devices');
  },

  onClickThiamisOnline() {
    if (this.model.get('online_devices') === 0) { return; }
    return apiThiamises.getChannel().request('app:devices', { filter_id: 'online' });
  },

  onClickAlertsActive() {
    if (this.model.get('alert_enabled') === 0) { return; }
    return apiAlerts.getChannel().request('app:alerts', { filter_id: 'active' });
  },

  onRender() {
    return this.stickit();
  }
});
