/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';

const API = MnObject.extend({
  channelName: 'utils:registry',

  radioRequests: {
    'register:instance': 'register',
    'unregister:instance': 'unregister',
    'reset:registry': 'resetRegistry'
  },

  register(instance, id) {
    if (App.environment === 'development') {
      if (!App._registry) { App._registry = {}; }
      return App._registry[id] = instance;
    }
  },

  unregister(instance, id) {
    if (App.environment === 'development') {
      return delete App._registry[id];
    }
  },

  resetRegistry() {
    const oldCount = this.getRegistrySize();
    for (const key in App._registry) {
      const controller = App._registry[key];
      controller.region.close();
    }

    const ret = {
      count: this.getRegistrySize(),
      previous: oldCount,
      msg: `There were ${oldCount} controllers in the registry, there are now ${this.getRegistrySize()}`
    };

    // eslint-disable-next-line no-console
    console.info(ret);
    return ret;
  },

  getRegistrySize() {
    return _.size(App._registry);
  }
});

// eslint-disable-next-line import/prefer-default-export
export const api = new API();
