/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { MnObject } from 'backbone.marionette';
import AppModel from 'app/backbone/lib/entities/app_model';
import { app as App } from 'app/backbone/app';
import Routes from 'app/config/routes';
import Widgets from 'app/backbone/entities/widgets/widgets';
import reduxStore from 'app/react/store';
import { fetchReportsSuccess } from '~/store/reducers/reports';
import * as schema from '~/store/schema';
import { normalizeResp } from '~/store/normalizr';

export default class Dashboard extends AppModel {
  get jsonRootKey() {
    return 'report';
  }

  get relations() {
    return [{
      type: Backbone.Many,
      key: 'widgets',
      collectionType: Widgets,
      isTransient: true,
      options: {
        parse: true
      }
    }
    ];
  }

  get defaults() {
    return {
      widgets: [],
      dashboard: true
    };
  }

  parse(json) {
    if (_.isArray(json.reports)) {
      return json.reports[0];
    }
    return json;
  }

  sync(method, model, options) {
    if (method === 'read') {
      const success = options.success;
      options.success = (data) => { // eslint-disable-line no-param-reassign
        if (success) {
          success(data);
        }
        try {
          const { reports } = data;
          const response = normalizeResp(reports, schema.reportsSchema);
          reduxStore.dispatch(fetchReportsSuccess(response));
        } catch (e) {
          App.getChannel().request('report:message', 'Error with dispatching fetchReportsSuccess', e);
        }
      };
    }
    return super.sync(method, model, options);
  }

  urlRoot() { return Routes.reports_path(); }
}

export const API = MnObject.extend({
  channelName: 'entities:dashboard',
  radioRequests: {
    'fetch:dashboard': 'fetchDashboardEntity'
  },

  fetchDashboardEntity(options) {
    const dashboard = new Dashboard(null, options);
    dashboard.fetch({
      silent: true,
      parse: true,
      data: {
        dashboard: true
      }
    });
    return dashboard;
  }
});

export const api = new API();
