import React, {
  useState, useCallback, useContext, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/modal';
import { getIsUpdating, getUpdateError } from '~/store/reducers/nodes';
import { modalActionRequest } from '../../../reducer';
import ManageAlertsContext from '../../../manage_context';
import ModalTable from './modal_table';

const ActionsModal = ({ type, toggleModal }) => {
  const { selectedAlerts, setSelectedAlerts, setIsActive } = useContext(ManageAlertsContext);
  const [actionCalled, setActionCalled] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isUpdating,
    updateError
  } = useSelector((state) => ({
    isUpdating: getIsUpdating(state),
    updateError: getUpdateError(state)
  }));

  useEffect(() => {
    if (!actionCalled) {
      return;
    }
    if (isUpdating) {
      return;
    }
    setActionCalled(false);
    if (!updateError) {
      toast.success(
        t('thiamis.manage_dialog.success', { action: t(`thiamis.manage_actions.${type}_past`) }),
        {
          toastId: `success_${type}`,
          position: toast.POSITION.TOP_CENTER
        }
      );
    }
    setSelectedAlerts([]);
    setIsActive(false);
    toggleModal();
  }, [setIsActive, setSelectedAlerts, t, type, toggleModal, isUpdating, updateError, actionCalled]);

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);
  const handleAction = useCallback(() => {
    setActionCalled(true);
    selectedAlerts.forEach((alertId) => {
      dispatch(modalActionRequest({ type, alertId }));
    });
  }, [dispatch, type, selectedAlerts]);

  return (
    <Modal
      isVisible={!!type}
      handleCancel={handleCancel}
      title="Manage Alerts"
    >
      <div className="dialog-content-region">
        <form className="form" onSubmit={handleAction}>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive manage-node-table table-region">
                  <ModalTable />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group form-actions clearfix">
            <button type="button" className="btn btn-default" onClick={handleCancel}>
              {t('base.buttons.cancel')}
            </button>
            <button
              type="submit"
              className="save-form btn btn-primary"
            >
              {t(`thiamis.manage_actions.${type}`)}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ActionsModal;
