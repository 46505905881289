/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const withBackbone = (WrappedComponent) => {
  class BackboneReactComponent extends Component {
    // Types of the context passed to child components. Only
    // `hasParentBackboneMixin` is required all of the others are optional.
    static childContextTypes = {
      hasParentBackboneMixin: PropTypes.bool.isRequired,
      parentModel: PropTypes.any,
      parentCollection: PropTypes.any
    }

    // Types of the context received from the parent component. All of them are
    // optional.
    static contextTypes = {
      hasParentBackboneMixin: PropTypes.bool,
      parentModel: PropTypes.any,
      parentCollection: PropTypes.any
    }

    constructor(props) {
      super(props);
      this.state = {};
      this.getModel = this.getModel.bind(this);
      this.getCollection = this.getCollection.bind(this);
      if (!this.wrapper) {
        this.wrapper = new Wrapper(this, this.state);
      }
    }

    // Passes data to our child components.
    getChildContext() {
      return {
        hasParentBackboneMixin: true,
        parentModel: this.getModel(),
        parentCollection: this.getCollection()
      };
    }

    // Sets `this.el` and `this.$el` when the component mounts.
    componentDidMount() {
      this.setElement(ReactDOM.findDOMNode(this));
    }
    // Sets `this.el` and `this.$el` when the component updates.
    componentDidUpdate() {
      this.setElement(ReactDOM.findDOMNode(this));
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} {..._.pick(this, 'getCollection', 'getModel')} />;
    }

    // When the component mounts, instance a `Wrapper` to take care
    // of models and collections binding with `this.state`.
    componentDidMount() {
      if (!this.wrapper) {
        this.wrapper = new Wrapper(this);
      }
    }
    // When the component unmounts, dispose listeners and delete
    // `this.wrapper` reference.
    componentWillUnmount() {
      if (this.wrapper) {
        this.wrapper.stopListening();
        delete this.wrapper;
      }
    }
    // In order to allow passing nested models and collections as reference we
    // filter `nextProps.model` and `nextProps.collection`.
    UNSAFE_componentWillReceiveProps(nextProps) {
      var model = nextProps.model;
      var collection = nextProps.collection;

      if (this.wrapper.model && model) {
        if (this.wrapper.model !== model) {
          this.wrapper.stopListening();
          this.wrapper = new Wrapper(this, undefined, nextProps);
        }
      } else if (model) {
        this.wrapper = new Wrapper(this, undefined, nextProps);
      }

      if (this.wrapper.collection && collection) {
        if (this.wrapper.collection !== collection) {
          this.wrapper.stopListening();
          this.wrapper = new Wrapper(this, undefined, nextProps);
        }
      } else if (collection) {
        this.wrapper = new Wrapper(this, undefined, nextProps);
      }
    }
    // Shortcut to `@$el.find` if jQuery ins present, else if fallbacks to DOM
    // native `querySelector`. Inspired by `Backbone.View`.
    $() {
      var els;

      if (this.$el) {
        els = this.$el.find.apply(this.$el, arguments);
      } else {
        var el = ReactDOM.findDOMNode(this);
        els = el.querySelector.apply(el, arguments);
      }

      return els;
    }
    // Grabs the collection from `@wrapper.collection` or `@context.parentCollection`
    getCollection() {
      return this.wrapper.collection || this.context.parentCollection;
    }
    // Grabs the model from `@wrapper.model` or `@context.parentModel`
    getModel() {
      return this.wrapper.model || this.context.parentModel;
    }
    // Sets a DOM element to render/mount this component on this.el and this.$el.
    setElement(el) {
      if (el && Backbone.$ && el instanceof Backbone.$) {
        if (el.length > 1) {
          throw new Error('You can only assign one element to a component');
        }
        this.el = el[0];
        this.$el = el;
      } else if (el) {
        this.el = el;
        if (Backbone.$) {
          this.$el = Backbone.$(el);
        }
      }
      return this;
    }
  }

  return BackboneReactComponent;
};

function Wrapper (component, initialState, nextProps) {
  // Object to store wrapper state (not the component state)
  this.state = {};
  // 1:1 relation with the `component`
  this.component = component;
  // Use `nextProps` or `component.props` and grab `model` and `collection`
  // from there
  var props = nextProps || component.props || {};
  var model, collection;

  if (component.overrideModel && typeof component.overrideModel === 'function'){
    // Define overrideModel() method on your `React class` to programatically supply a model object
    // Will override `this.props.model`
    model = component.overrideModel();
  } else {
    model = props.model;
  }

  if (component.overrideCollection && typeof component.overrideCollection === 'function'){
    // Define overrideCollection() method on your `React class` to programatically supply a collection object
    // Will override `this.props.collection`
    collection = component.overrideCollection();
  } else {
    collection = props.collection;
  }

  this.setModels(model, initialState);
  this.setCollections(collection, initialState);
}

_.extend(Wrapper.prototype, Backbone.Events, {
  // Sets `this.state` when a model/collection request results in error. It delegates
  // to `this.setState`. It listens to `Backbone.Model#error` and `Backbone.Collection#error`.
  onError: function (modelOrCollection, res, options) {
    // Set state only if there's no silent option
    if (!options.silent) {
      this.component.setState({
        isRequesting: false,
        hasError: true,
        error: res
      });
    }
  },
  onInvalid: function (model, res, options) {
    if (!options.silent) {
      this.component.setState({
        isInvalid: true
      });
    }
  },
  // Sets `this.state` when a model/collection request starts. It delegates to
  // `this.setState`. It listens to `Backbone.Model#request` and
  // `Backbone.Collection#request`.
  onRequest: function (modelOrCollection, xhr, options) {
    // Set `state` only if there's no silent option
    if (!options.silent) {
      this.component.setState({
        isRequesting: true,
        hasError: false,
        isInvalid: false
      });
    }
  },
  // Sets `this.state` when a model/collection syncs. It delegates to `this.setState`.
  // It listens to `Backbone.Model#sync` and `Backbone.Collection#sync`
  onSync: function (modelOrCollection, res, options) {
    // Calls `setState` only if there's no silent option
    if (!options.silent) {
      this.component.setState({isRequesting: false});
    }
  },
  // Check if `models` is a `Backbone.Model` or an hashmap of them, sets them
  // to the component state and binds to update on any future changes
  setModels: function (models, initialState, isDeferred) {
    var isValid = typeof models !== 'undefined';

    if (isValid) {
      if (!models.attributes) {
        if (typeof models === 'object') {
          var _values = _.values(models);
          isValid = _values.length > 0 && _values[0].attributes;
        } else {
          isValid = false;
        }
      }
    }

    if (isValid) {
      this.model = models;
      // Set model(s) attributes on `initialState` for the first render
      this.setStateBackbone(models, undefined, initialState, isDeferred);
      this.startModelListeners(models);
    }
  },
  // Check if `collections` is a `Backbone.Model` or an hashmap of them,
  // sets them to the component state and binds to update on any future changes
  setCollections: function (collections, initialState, isDeferred) {
    if (typeof collections !== 'undefined' && (collections.models ||
        typeof collections === 'object' && _.values(collections)[0].models)) {
      // The collection(s) bound to this component
      this.collection = collections;
      // Set collection(s) models on `initialState` for the first render
      this.setStateBackbone(collections, undefined, initialState, isDeferred);
      this.startCollectionListeners(collections);
    }
  },
  // Used internally to set `this.collection` or `this.model` on `this.state`. Delegates to
  // `this.setState`. It listens to `Backbone.Collection` events such as `update`,
  // `change`, `sort`, `reset` and to `Backbone.Model` `change`.
  setStateBackbone: function (modelOrCollection, key, target, isDeferred) {
    if (!(modelOrCollection.models || modelOrCollection.attributes)) {
      for (key in modelOrCollection)
          this.setStateBackbone(modelOrCollection[key], key, target);
      return;
    }
    this.setState.apply(this, arguments);
  },
  // Get the attributes for the collection or model as array or hash
  getAttributes: function (modelOrCollection){
    var attrs = [];

    // if a collection, get the attributes of each, otherwise return modelOrCollection
    if (modelOrCollection instanceof Backbone.Collection) {
      for (var i = 0; i < modelOrCollection.models.length; i++) {
        attrs.push(_.clone(modelOrCollection.models[i].attributes));
      }
      return attrs;
    } else {
      return _.clone(modelOrCollection.attributes);
    }
  },
  // Sets a model, collection or object into state by delegating to `this.component.setState`.
  setState: function (modelOrCollection, key, target, isDeferred) {
    var state = {};
    var newState = this.getAttributes(modelOrCollection);

    if (key) {
      state[key] = newState;
    } else if (modelOrCollection.models) {
      state.collection = newState;
    } else {
      state.model = newState;
    }

    if (target) {
      _.extend(target, state);
    } else if (isDeferred) {
      this.nextState = _.extend(this.nextState || {}, state);
      _.defer(_.bind(function () {
        if (this.nextState) {
          this.component.setState(this.nextState);
          this.nextState = null;
        }
      }, this));
    } else {
      this.component.setState(state);
    }
  },
  // Binds the component to any collection changes.
  startCollectionListeners: function (collection, key) {
    if (!collection) collection = this.collection;
    if (collection) {
      if (collection.models)
        this
          .listenTo(collection, 'update change sort reset',
            _.partial(this.setStateBackbone, collection, key, undefined, true))
          .listenTo(collection, 'error', this.onError)
          .listenTo(collection, 'request', this.onRequest)
          .listenTo(collection, 'sync', this.onSync);
      else if (typeof collection === 'object')
        for (key in collection)
          if (collection.hasOwnProperty(key))
            this.startCollectionListeners(collection[key], key);
    }
  },
  // Binds the component to any model changes.
  startModelListeners: function (model, key) {
    if (!model) model = this.model;
    if (model) {
      if (model.attributes)
        this
          .listenTo(model, 'change',
            _.partial(this.setStateBackbone, model, key, undefined, true))
          .listenTo(model, 'error', this.onError)
          .listenTo(model, 'request', this.onRequest)
          .listenTo(model, 'sync', this.onSync)
          .listenTo(model, 'invalid', this.onInvalid);
      else if (typeof model === 'object')
        for (key in model)
          this.startModelListeners(model[key], key);
    }
  }
});

export default withBackbone;
