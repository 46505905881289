import { Behavior } from 'backbone.marionette';
import { NullDataPointsFilter } from 'app/backbone/lib/components/shared_views/widget_node_select';

export default Behavior.extend({
  collectionEvents: {
    reset: 'showDataPointsFilter'
  },

  showDataPointsFilter() {
    return this.view.getRegion('dataPointsFilterRegion').show(this.getDataPointsFilter());
  },

  getDataPointsFilter() {
    if (this.view.model.get('node_id') || this.view.model.get('node_ids')) {
      // eslint-disable-next-line new-cap
      return new this.view.dataPointsFilterClass(_(this.view).pick('model', 'collection'));
    }
    return new NullDataPointsFilter();
  },

  onRender() {
    this.showDataPointsFilter();
  }
});
