/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
*/
import AppRouter from 'app/backbone/lib/routers/app_router';
import { app as App } from 'app/backbone/app';
import { MnObject } from 'backbone.marionette';
import { api as orgApi } from 'app/backbone/entities/organization';
import { api as countriesApi } from 'app/backbone/entities/abstract/countries';

class Router extends AppRouter {
  initialize() {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.appRoute(/^organizations\/(\w+)$/, 'showOrganization');
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'organization');
  }
}

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:organizations': 'listOrganizations',
    'app:formation_requests': 'listOrganizations',
    'app:organizations:show': 'showOrganization',
    'app:organizations:new': 'newOrganization'
  },

  listOrganizations() {
    Router.prototype.before();
    App.navigate('organizations');
    App.getChannel().request('enable:org_filter', true);
  },

  showOrganization(orgId) {
    App.navigate(`organizations/${orgId}`);
  },

  newOrganization() {
    App.navigate('organizations/new');
  }
});

const controller = {
  showOrganization(id) {
    const promise = import('app/backbone/apps/organizations/show/show_controller');
    promise.then((OrganizationsShowController) => {
      let model;
      if (id === 'new') {
        model = orgApi.getChannel().request('new:organization');
      } else {
        model = orgApi.getChannel().request('fetch:organization', id);
      }
      // eslint-disable-next-line new-cap
      const showController = new OrganizationsShowController.default({
        model,
        countries: countriesApi.getChannel().request('get:countries'),
        industries: countriesApi.getChannel().request('get:industries'),
        usStates: countriesApi.getChannel().request('get:us_states')
      });
      return showController.showOrganization();
    });
    return promise;
  }
};

export const api = new API();

const OrganizationsApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default OrganizationsApp;
