import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import compact from 'lodash/compact';
import { useForm, useFormState, Field as FinalField } from 'react-final-form';
import { fetchDataPointsRequest, fetchNodesRequest, clearNodes } from '~/store/reducers/nodes';
import Select from '~/components/form/select';
import Input from '~/components/form/input';
import { getDefaultDataPointsByNodeId, getParametersForDropdown } from '~/store/selectors/data_points';
import { getAirthinxValueLabelPairs, getNodesIsFetching } from '~/store/selectors/nodes';
import { getMemberedOrganizationsValueLabelPairsWithExternalId } from '~/store/selectors/organizations';
import useValidation from '~/hooks/useValidation';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const form = useForm();
  const dispatch = useDispatch();
  const { validateRequired } = useValidation();
  const { values: { devices }, hasValidationErrors } = useFormState();
  const deviceOptions = useSelector((state) => getAirthinxValueLabelPairs(state));
  const organizationOptions = useSelector((state) => getMemberedOrganizationsValueLabelPairsWithExternalId(state));
  const isDevicesFetching = useSelector((state) => getNodesIsFetching(state));
  const parameterOptions = useSelector((state) => {
    const nodeIds = (devices || []).map((device) => device.value);
    const dataPoints = nodeIds.map((nodeId) => getDefaultDataPointsByNodeId(state, nodeId));
    return getParametersForDropdown(dataPoints);
  });

  const isLoadingParameters = useMemo(() => !!deviceOptions.find((node) => node.isSyncing), [deviceOptions]);
  const conditionsOptions = ['lt', 'lte', 'gt', 'gte', 'eq', 'neq'].map((exp) => ({ value: exp, label: t(`conditions.expressions.${exp}_title`) }));

  const onOrganizationChange = useCallback((option) => {
    form.change('organizationId', option);
    form.change('devices', []);
    dispatch(clearNodes());
    dispatch(fetchNodesRequest({ data: { organization_id: option.value } }));
  }, [dispatch, form]);

  useEffect(() => {
    form.change('parameters', {});

    if (!devices || !devices.length) {
      return;
    }
    // fetch datapoints for devices not fetched yet
    const nonSyncedNodeIds = compact(devices
      .filter(({ value: deviceId }) => !deviceOptions.find(({ value: optionId, isSynced }) => optionId === deviceId && isSynced))
      .map(({ value }) => value));
    if (!nonSyncedNodeIds.length) {
      return;
    }
    dispatch(fetchDataPointsRequest({ node_id: nonSyncedNodeIds }));
  }, [deviceOptions, dispatch, form, devices]);

  return (
    <div className="form">
      <div className="form-region form-margins">
        <div className="form-group">
          <FinalField
            name="organizationId"
            validate={validateRequired}
          >
            {
              ({ input, meta }) => (
                <Select
                  label={t('models.organization')}
                  value={input.value}
                  onChange={onOrganizationChange}
                  options={organizationOptions}
                  onBlur={input.onBlur}
                  hasError={meta.touched && meta.error}
                />
              )
            }
          </FinalField>
        </div>
        <div className="form-group">
          <FinalField
            name="devices"
            validate={validateRequired}
          >
            {
              ({ input, meta }) => (
                <Select
                  label={t('thiamis.download_csv.devices')}
                  value={input.value}
                  onChange={input.onChange}
                  options={deviceOptions}
                  isLoading={isDevicesFetching}
                  isMulti
                  onBlur={input.onBlur}
                  allowSelectAll
                  hasError={meta.touched && meta.error}
                />
              )
            }
          </FinalField>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <FinalField
                name="parameters"
                validate={validateRequired}
              >
                {
                  ({ input, meta }) => (
                    <Select
                      label={t('attributes.alert_rule.name')}
                      value={input.value}
                      onChange={input.onChange}
                      options={parameterOptions}
                      onBlur={input.onBlur}
                      isLoading={isLoadingParameters}
                      hasError={meta.touched && meta.error}
                    />
                  )
                }
              </FinalField>
            </div>
            <div className="col-sm-4" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <FinalField
                name="expr"
              >
                {
                  ({ input, meta }) => (
                    <Select
                      label={t('attributes.alert_rule.func')}
                      value={input.value}
                      onChange={input.onChange}
                      options={conditionsOptions}
                      onBlur={input.onBlur}
                      hasError={meta.touched && meta.error}
                    />
                  )
                }
              </FinalField>
            </div>
            <div className="col-sm-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <FinalField
                name="value"
                validate={validateRequired}
              >
                {
                  ({ input, meta }) => (
                    <Input
                      id="value"
                      label={t('attributes.alert_rule.value')}
                      {...input}
                      errorText={meta.touched && meta.error}
                    />
                  )
                }
              </FinalField>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group form-actions clearfix">
        <button type="button" className="btn btn-default" onClick={handleCancel}>
          {t('base.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="save-form btn btn-primary"
          disabled={hasValidationErrors}
          onClick={handleSubmit}
        >
          {t('base.buttons.submit')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
