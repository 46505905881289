/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import NodeWidgetFetchThiamisesMixin from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamises';
import WidgetDataPointdSelectMixin from 'app/backbone/lib/concerns/views/widget_data_points_select';
import { ChartDataPointsFilter, ChartEmptyDataPointsFilter, NodeSelect } from 'app/backbone/lib/components/shared_views/widget_node_select';
import timespanSelectTemplate from './templates/timespan_select.hbs';
import confTemplate from './templates/conf.hbs';

export const CompareDevicesWidgetTimeSpanView = View.extend({
  template: timespanSelectTemplate,
  bindings: {
    '.config-timespan': {
      observe: 'time_period',
      selectOptions: {
        collection() {
          return [
            { value: 'hour_1', label: '1 hour' },
            { value: 'hours_3', label: '3 hours' },
            { value: 'hours_6', label: '6 hours' },
            { value: 'hours_12', label: '12 hours' },
            { value: 'day_1', label: '1 day' }
          ];
        },
        defaultOption: {
          label() { return I18n.t('widgets.choose_period'); },
          value: null
        }
      }
    }
  },

  onRender() {
    return this.stickit();
  }
});

export const NodeMultipleSelect = NodeSelect.extend({
  initialize(options) {
    this.index = options.index;
    NodeSelect.prototype.initialize.call(this, options);
  },

  bindings: {
    ...NodeSelect.prototype.bindings,
    '.config-node_id': {
      ...NodeSelect.prototype.bindings['.config-node_id'],
      observe: 'node_ids',
      attributes: [{
        observe: 'node_ids',
        name: 'multiple',
        onGet() {
          return 'multiple';
        }
      }]
    }
  }
});

const CompareDevicesDataPointsFilter = ChartDataPointsFilter.extend({
  bindings: _.extend(_.clone(ChartDataPointsFilter.prototype.bindings), {
    '@ui.dataPointsFilter': {
      ...ChartDataPointsFilter.prototype.bindings['@ui.dataPointsFilter'],
      onGet(ids = []) {
        const result = ids.map((id) => {
          const dp = this.collection.find((model) => _.contains(model.get('ids'), id));
          if (dp) {
            return dp.get('_id');
          }
        });
        return _.chain(result).compact().first().value();
      },
      onSet(path) {
        const dp = this.collection.findWhere({ _id: path });
        if (dp) {
          return dp.get('ids');
        }
      },
      attributes: [{
        observe: 'selected_data_point_ids',
        name: 'multiple',
        onGet() {
          return false;
        }
      }]
    }
  })
});

const CompareDevicesWidgetConfigView = View.extend({
  behaviors: [NodeWidgetFetchThiamisesMixin, WidgetDataPointdSelectMixin],
  template: confTemplate,
  ui: {
    loading: '.loading-overlay'
  },
  regions: {
    dataPointsFilterRegion: '.datapointsselector-region',
    node2SelectRegion: '.nodeselect-2-region',
    nodeSelectRegion: '.nodeselect-region',
    timespanRegion: '.timespan-region'
  },

  dataPointsFilterClass: CompareDevicesDataPointsFilter,
  emptyDataPointsFilterClass: ChartEmptyDataPointsFilter,

  showNodeSelect() {
    this.getRegion('nodeSelectRegion').show(this.getNodeSelect());
  },

  getNodeSelect(options) {
    return new NodeMultipleSelect(_.extend(_(this).pick('model', 'thiamises'), options));
  },

  onRender() {
    App.getChannel().request('when:fetched', this.thiamises, () => {
      this.showNodeSelect();
    });
    return this.showTimeSpanSelect();
  },

  showTimeSpanSelect() {
    return this.getRegion('timespanRegion').show(new CompareDevicesWidgetTimeSpanView(
      _(this).pick('model'))
    );
  }
});

export default CompareDevicesWidgetConfigView;
