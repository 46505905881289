import { app } from 'app/backbone/app';
import AppRouter from 'marionette.approuter';
import ShowController from './show/show_controller';

const PageNotFound = AppRouter.extend({
  appRoutes: {
    '*path': 'pageNotFound'
  }
});

const API = {
  pageNotFound: () => {
    if (!app.getCurrentRoute()) {
      return app.getChannel().request('app:index');
    }
    const controller = new ShowController();
    return controller.showView();
  }

};

PageNotFound.start = () => (
  new PageNotFound({
    controller: API
  })
);

export default PageNotFound;
