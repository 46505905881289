/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import confTemplate from './templates/conf.hbs';

const TextWidgetConfigView = View.extend({
  template: confTemplate,

  bindings: {
    '.config-text': {
      observe: 'text',
      events: ['blur']
    }
  },

  onRender() {
    return this.stickit();
  }
});

export default TextWidgetConfigView;
