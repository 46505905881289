import React from 'react';
import I18n from 'app/config/i18n';
import { api as thiamisApi } from 'app/backbone/apps/thiamises/thiamises_app';
import { isNodeMonitorable } from '~/store/selectors/nodes';

export const SerialFormatter = (props) => {
  const { row: { _id: nodeId, serial, actions } } = props;
  const isMonitorable = isNodeMonitorable(actions);
  if (isMonitorable) {
    return (
      <a
        title={I18n.t('thiamis.open_monitor')}
        role="button"
        tabIndex={0}
        className="blue"
        onClick={() => thiamisApi.getChannel().request('app:devices:monitor', nodeId)}
      >
        { serial }
      </a>
    );
  }
  return <>{serial}</>;
};
