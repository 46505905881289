import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

export const fetchCertificatesRequest = createAction('@@certificates/FETCH_REQUEST');
export const fetchCertificatesSuccess = createAction('@@certificates/FETCH_SUCCESS');
export const fetchCertificatesFailure = createAction('@@certificates/FETCH_FAILURE');

export const createCertificateRequest = createAction('@@certificates/CREATE_REQUEST');
export const createCertificateSuccess = createAction('@@certificates/CREATE_SUCCESS');
export const createCertificateFailure = createAction('@@certificates/CREATE_FAILURE');

export const updateCertificateRequest = createAction('@@certificates/UPDATE_REQUEST');
export const updateCertificateSuccess = createAction('@@certificates/UPDATE_SUCCESS');
export const updateCertificateFailure = createAction('@@certificates/UPDATE_FAILURE');

export const deleteCertificateRequest = createAction('@@certificates/DELETE_REQUEST');
export const deleteCertificateSuccess = createAction('@@certificates/DELETE_SUCCESS');
export const deleteCertificateFailure = createAction('@@certificates/DELETE_FAILURE');

const ids = createReducer([], {
  [fetchCertificatesSuccess](state, { payload: { result } }) {
    return result || state;
  },
  [createCertificateSuccess](state, { payload: { _id } }) {
    return [...state, _id];
  },
  [deleteCertificateSuccess](state, { payload: { id } }) {
    return state.filter((objectId) => objectId !== id);
  }
});

const byId = createReducer({}, {
  [fetchCertificatesSuccess](state, { payload }) {
    const { entities: { certificates } } = payload;
    if (certificates) {
      return certificates;
    }
    return state;
  },
  [createCertificateSuccess](state, { payload }) {
    return {
      ...state,
      [payload._id]: payload
    };
  },
  [deleteCertificateSuccess](state, { payload: { id } }) {
    return omit(state, [id]);
  }
});

const isFetched = createReducer(false, {
  [fetchCertificatesRequest]() {
    return false;
  },
  [fetchCertificatesFailure]() {
    return false;
  },
  [fetchCertificatesSuccess]() {
    return true;
  }
});

const certificates = combineReducers({
  ids,
  byId,
  isFetched
});

export const getCertificateById = (state, id) => state.certificates.byId[id];
export const getCertificates = (state) => state.certificates.byId;
export const getCertificateIds = (state) => state.certificates.ids;
export const getIsFetched = (state) => state.certificates.isFetched;

export default certificates;
