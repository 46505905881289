import React, {
  useCallback, useEffect, useMemo
} from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { app as App } from 'app/backbone/app';
import { fetchNodesRequest, clearNodes } from '~/store/reducers/nodes';
import {
  createCertificateRequest,
  updateCertificateRequest,
  fetchCertificatesRequest,
  getCertificateById
} from '~/store/reducers/certificates';
import withBackboneLink from '~/hocs/with_backbone_link';
import { fetchTemplatesRequest } from '~/store/reducers/templates';
import { getAvailableNodes } from './selectors';
import { getBlueCertificateTemplate } from '~/store/selectors/templates';
import CreateCertificateForm from './create_form';

const BackToCertificatesButton = withBackboneLink((props) => (
  <button {...props} type="button" className="btn btn-default">
    Back to Certificates
  </button>
));

const CreateCertificateScreen = React.memo(({ id }) => {
  const { t } = useTranslation();
  const certificate = useSelector((state) => getCertificateById(state, id));
  const dispatch = useDispatch();
  const template = useSelector(getBlueCertificateTemplate);
  const nodes = useSelector(getAvailableNodes);

  const preselectedNodes = useMemo(() => {
    if (!certificate) {
      return [];
    }
    const preselected = get(certificate, 'templates[0].configuration.node_id', []);
    return preselected.map((node_id) => nodes.find((item) => item.value === node_id)).filter((item) => item);
  }, [certificate, nodes]);

  const initialData = useMemo(() => ({
    name: certificate?.name,
    description: certificate?.description,
    devices: preselectedNodes,
    phone: certificate?.user_metadata?.phone,
    address: certificate?.user_metadata?.address,
    photo: certificate?.user_metadata?.photo
  }), [certificate, preselectedNodes]);

  useEffect(() => {
    if (id && !certificate) {
      dispatch(fetchCertificatesRequest());
    }
  }, [id, certificate, dispatch]);

  useEffect(() => {
    dispatch(fetchTemplatesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (template) {
      const data = {
        organization_id: template.organization
      };
      dispatch(clearNodes());
      dispatch(fetchNodesRequest({ data }));
    }
  }, [template, dispatch]);

  const handleSuccess = useCallback(() => {
    if (id) {
      toast.success(
        t('notifications.success.forms.update_certificate'),
        { toastId: 'success_certificate_update' }
      );
    } else {
      toast.success(
        t('notifications.success.forms.create_certificate'),
        { toastId: 'success_certificate_create' }
      );
    }
    App.navigate('certificates');
  }, [id, t]);

  const submit = useCallback((values) => {
    const formData = {
      ...values,
      template
    };
    if (id) {
      dispatch(updateCertificateRequest({
        formData,
        id,
        onSuccess: handleSuccess
      }));
    } else {
      dispatch(createCertificateRequest({
        formData,
        onSuccess: handleSuccess
      }));
    }
  }, [template, dispatch, handleSuccess, id]);

  return (
    <div className="details-form-page certificate-form">
      <div className="content-top clearfix">
        <div className="pull-left">
          <BackToCertificatesButton to="certificates" />
        </div>
      </div>
      <FinalForm
        onSubmit={submit}
        initialValues={initialData}
      >
        {({ handleSubmit }) => (
          <div className="form-region form-margins organization-profile">
            <CreateCertificateForm
              handleSubmit={handleSubmit}
              nodes={nodes}
            />
          </div>
        )}
      </FinalForm>
    </div>
  );
});

export default CreateCertificateScreen;
