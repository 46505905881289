/* eslint-disable no-param-reassign */
import { schema } from 'normalizr';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import isString from 'lodash/isString';

export const measurementsSchema = new schema.Entity(
  'measurements',
  {},
  {
    idAttribute: 'data_point_id'
  }
);

function isDataPointId(value) {
  return isString(value) && value.length >= 24;
}

function extractCondition(cnd) {
  const [func] = Object.keys(cnd);
  const [[dataPointId, value]] = Object.values(cnd);
  return { func, dataPointId, value };
}

export const getConditionId = (condition) => {
  let id = '';
  const { dataPointId, value, func } = extractCondition(condition);
  if (isObject(dataPointId) && isObject(value)) { // diff reference [{ min: ['dp1', 15] }, { max: ['dp2', 15] }]
    return `${getConditionId(dataPointId)}_${func}_${getConditionId(value)}`;
  }
  if (isObject(dataPointId)) { // simple reference [{ min: ['dp1', 15] }, 12]
    id += getConditionId(dataPointId);
  } else {
    id = dataPointId;
  }
  return `${id}_${func}_${value}`;
};

export const processConditions = (cnds, result = []) => cnds.reduce((acc, cnd) => {
  const condition = extractCondition(cnd);
  const { dataPointId, value } = condition;
  if (isObject(dataPointId) && isObject(value)) { // diff reference [{ min: ['dp1', 15] }, { max: ['dp2', 15] }]
    acc = [...acc, {
      ...omit(condition, 'dataPointId', 'value'),
      _id: getConditionId(cnd),
      conditions: flatten([processConditions([dataPointId], acc), processConditions([value], acc)])
    }];
  } else if (isObject(dataPointId)) { // simple reference [{ min: ['dp1', 15] }, 12]
    acc = [...acc, {
      ...omit(condition, 'dataPointId'),
      _id: getConditionId(cnd),
      conditions: processConditions([dataPointId], acc)
    }];
  } else if (isDataPointId(value)) { // diff reference ['dp1', 'dp2'] where dp1, dp2 usually some metric data points with aggregated values
    acc = [...acc, {
      ...omit(condition, 'value'),
      _id: getConditionId(cnd),
      dataPointId,
      dataPointId2: value
    }];
  } else {
    acc = [...acc, { ...condition, _id: getConditionId(cnd) }];
  }
  return acc;
}, result);

export const dataPointSchema = new schema.Entity(
  'data_points', {
    measurements: measurementsSchema
  }, {
    idAttribute: '_id',
    processStrategy(dataPoint = {}) {
      let { conditions } = dataPoint;
      if (conditions) {
        if (isArray(conditions.and)) conditions = conditions.and.map((cnd) => cnd);
        if (!isArray(conditions)) conditions = [conditions];
        return { ...dataPoint, conditions: processConditions(conditions) };
      }
      return dataPoint;
    }
  }
);
export const dataPointsSchema = [dataPointSchema];

export const deviceSchema = new schema.Entity(
  'devices',
  {},
  { idAttribute: '_id' }
);
export const devicesSchema = [deviceSchema];

export const nodesSchema = new schema.Entity(
  'nodes',
  {
    data_points: dataPointsSchema,
    devices: devicesSchema
  },
  { idAttribute: '_id' }
);

export const nodesListSchema = [nodesSchema];

export const alertsSchema = new schema.Entity(
  'alerts',
  {
    data_points: dataPointsSchema
  },
  { idAttribute: '_id' }
);

export const alertsListSchema = [alertsSchema];

export const organizationSchema = new schema.Entity('organizations');
export const organizationsListSchema = [organizationSchema];

export const userSchema = new schema.Entity('users',
  {},
  { idAttribute: '_id' });
export const usersSchema = [userSchema];

export const invitationSchema = new schema.Entity('invitations');
export const invitationsSchema = [invitationSchema];

export const invitationOrganizationSchema = new schema.Entity(
  'invitations',
  {
    organization: organizationSchema
  }
);
export const invitationsOrganizationSchema = [invitationOrganizationSchema];

export const partnershipSchema = new schema.Entity('partnerships');
export const partnershipsListSchema = [partnershipSchema];

export const fileSchema = new schema.Entity('files',
  {},
  { idAttribute: '_id' });
export const filesListSchema = [fileSchema];

export const firmwareSchema = new schema.Entity('firmwares',
  {},
  { idAttribute: 'version' });
export const firmwaresListSchema = [firmwareSchema];

export const calibrationSchema = new schema.Entity('calibrations',
  {},
  { idAttribute: 'node_id' });
export const calibrationsListSchema = [calibrationSchema];

export const apiSchema = new schema.Entity('apis',
  {},
  { idAttribute: 'id' });
export const apiListSchema = [apiSchema];

export const webhookSchema = new schema.Entity('webhooks',
  {},
  { idAttribute: '_id' });
export const webhookListSchema = [webhookSchema];

export const widgetSchema = new schema.Entity(
  'widgets',
  {},
  { idAttribute: '_id' }
);
export const widgetsSchema = [widgetSchema];

export const reportSchema = new schema.Entity(
  'reports',
  {
    widgets: widgetsSchema
  },
  { idAttribute: '_id' }
);
export const reportsSchema = [reportSchema];

export const certificateSchema = new schema.Entity(
  'certificates',
  {},
  { idAttribute: '_id' }
);
export const certificatesSchema = [certificateSchema];
