/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import CalHeatMap from 'cal-heatmap';
import moment from 'app/config/moment';

export default class HeatMap {
  defaults() {
    return {
      domain: 'month',
      subDomain: 'x_day',
      cellSize: 24,
      range: 3,
      highlight: 'now',
      domainGutter: 10,
      legendColors: {
        min: '#FFCAB2',
        max: '#D87F77'
      },
      legend: [
        5,
        10,
        15,
        20
      ],
      weekStartOnMonday: false,
      start: moment().subtract(2, 'months').toDate(),
      displayLegend: true,
      label: {
        position: 'top'
      },
      tooltip: true,
      onTooltip: false,
      subDomainTextFormat: '%d',
      verticalOrientation: false,
      maxDate: moment().toDate(),
      minDate: moment().subtract(1, 'years').toDate(),
      onMinDomainReached(hit) {
        return $(this.options.previousSelector).toggleClass('hidden', hit);
      },
      onMaxDomainReached(hit) {
        return $(this.options.nextSelector).toggleClass('hidden', hit);
      }
    };
  }

  dateFormat(date, format = '%a, %B %-e, %Y') {
    return this.getCalHeatMap().formatDate(date, format);
  }

  getCalHeatMap() {
    return this.cal != null ? this.cal : (this.cal = new CalHeatMap());
  }

  constructor(options) {
    this.options = options;
  }

  init() {
    return this.getCalHeatMap().init(
      _.extend(this.defaults(), this.options));
  }
}
