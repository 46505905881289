import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const withTooltip = (WrappedComponent) => (props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return <WrappedComponent {...props} />;
};

export default withTooltip;
