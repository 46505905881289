import React from 'react';
import { connect } from 'react-redux';
import ProfileForm from '~/components/form/profile_form';
import { getProfileIsSyncing } from '~/store/selectors/profile';
import { getCurrentUserId } from '~/store/selectors/global';
import { getCurrentUserMetaData } from '~/store/selectors/current_user';
import { getUserByIdPicture, getUserByIdInitials } from '~/store/selectors/users';

const AccountForm = (props) => (
  <ProfileForm
    {...props}
  />
);

const mapStateToProps = (state) => {
  const userId = getCurrentUserId(state);

  return {
    syncing: getProfileIsSyncing(state),
    metadata: getCurrentUserMetaData(state),
    picture: getUserByIdPicture(state, userId),
    initials: getUserByIdInitials(state, userId)
  };
};

export default connect(mapStateToProps)(AccountForm);
