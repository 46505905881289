import React from 'react';
import { connect } from 'react-redux';
import { getCalibrationStatusFilterTags } from '../selectors';
import FiltersSidebarPanel from './filters_sidebar_panel';

const FiltersSidebar = React.memo((props) => {
  const { status } = props;
  return (
    <div className="filters-region right-panel">
      <div className="fill-height">
        <div className="filter-groups-list-region">
          <div id="filters-wrap" className="panel-group collapse in" aria-expanded="true">
            <FiltersSidebarPanel filters={status} group="status" />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  status: getCalibrationStatusFilterTags(state)
});

export default connect(mapStateToProps)(FiltersSidebar);
