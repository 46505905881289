import React from 'react';
import FiltersFormTop from './filters_form_top';
import GridContainer from './grid_container';

const OrganizationsScreen = () => (
  <div className="content-region fill-height content">
    <div className="organizations-container">
      <FiltersFormTop />
      <div className="tab-content list-content">
        <GridContainer />
      </div>
    </div>
  </div>
);

export default OrganizationsScreen;
