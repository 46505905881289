/* eslint-disable */
export default function (d = document, s, id) {
  let fjs; let js; let
    t;
  js = void 0;
  fjs = d.getElementsByTagName(s)[0];
  t = window.twttr || {};
  if (d.getElementById(id)) {
    return t;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://platform.twitter.com/widgets.js';
  fjs.parentNode.insertBefore(js, fjs);
  t._e = [];
  t.ready = f => t._e.push(f);
  return t;
}
