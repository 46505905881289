/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  modelEvents: {
    request: 'renderOutOfSync',
    sync: 'renderSynced',
    error: 'renderError'
  },

  renderOutOfSync(widget, ajax, options = {}) {
    if (!options.savedByForm) { return this._renderSyncState('outofsync'); }
  },

  renderSynced() {
    return this._renderSyncState('synced');
  },

  renderError(widget, ajax, options = {}) {
    if (!options.savedByForm) { return this._renderSyncState('error-state'); }
  },

  _renderSyncState(state) {
    this.$el.removeClass('synced outofsync error-state');
    return this.$el.addClass(state);
  },

  onRender() {
    if (this.view.model.isNew()) {
      return this.renderOutOfSync();
    } return this.renderSynced();
  }
});
