/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeviceTitle from 'app/backbone/lib/react/components/common/DeviceTitle/DeviceTitle';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';
import { __guard__ } from 'app/utils/custom-fns';

class SensorDeviceTitle extends Component {
  constructor() {
    super(...arguments); // eslint-disable-line prefer-rest-params
    this.dataPoints = __guard__(this.props.getModel().getThiamis(), (x) => x.getDataPoints());
  }

  componentDidMount() {
    return (this.dataPoints != null ? this.dataPoints.on('remove add change', this.onDataPointsUpdate, this) : undefined);
  }

  componentWillUnmount() {
    return (this.dataPoints != null ? this.dataPoints.off('remove add change', this.onDataPointsUpdate, this) : undefined);
  }

  onDataPointsUpdate(dataPoint) {
    if (dataPoint.get('device_id') === this.props.getModel().id) {
      if ((__guard__(dataPoint.get('name'), (x) => x.toLowerCase()) === 'status') || dataPoint.isError() || dataPoint.isRaw()) {
        return this.updateStatus();
      }
    }
  }

  updateStatus() {
    return this.forceUpdate();
  }

  render() {
    const displayName = this.props.getModel().get('display_name');
    const statusType = this.props.getModel().get('status_type');
    const statusText = this.props.getModel().get('status_text');
    return (
      <DeviceTitle displayName={displayName} statusType={statusType} statusText={statusText}>
        { !_.isEmpty(this.props.getModel().get('tag'))
          ? <small>{this.props.getModel().get('tag')}</small> : undefined }
      </DeviceTitle>
    );
  }
}

SensorDeviceTitle.propTypes = {
  getModel: PropTypes.func.isRequired
};

export default BackboneReactComponent(SensorDeviceTitle);
