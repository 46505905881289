import jwt_decode from 'jwt-decode';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import { getIndexUrl } from 'app/utils/custom-fns';
import Storage from 'app/utils/storage';
import I18n from '../config/i18n';

const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN;
const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE;
const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID;
const API_URL = process.env.API_URL;

export default class Auth0Handler {
  constructor() {
    this.auth0 = new Auth0Client(...arguments); // eslint-disable-line prefer-rest-params
    this.accessToken = null;
    this.idToken = null;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  getAuthToken() {
    const token = this.getIdToken();
    if (token) {
      return `Bearer ${token}`;
    }
    return null;
  }

  cleanTokens() {
    this.accessToken = null;
    this.idToken = null;
  }

  backToLoginPage(options = {}) {
    if (options.flash) Storage.setItem('flash', options.flash);

    this.auth0.logout({
      returnTo: `${getIndexUrl()}login.html`
    });
  }

  cleanUrl() {
    if (document.location.search.indexOf('?code=') !== -1) {
      window.history.pushState('', 'Environet', '/');
    }
  }

  initiateSession() {
    $.ajaxSetup({
      beforeSend: (xhr, settings) => {
        if (!settings.url.includes('uploadcare')) {
          xhr.setRequestHeader('Authorization', this.getAuthToken());
        }
      }
    });
  }

  authErrorDialog() {
    App.getChannel().trigger('flash:message', 'error', I18n.t('dialogs.titles.auth_issue'));
  }

  updateCurrentUser(cb) {
    if (!App.getChannel().request('get:current:user')) {
      return;
    }
    return this.getProfile((userProfile) => {
      App.getChannel().request('get:current:user').updateProfile(userProfile);
      return typeof cb === 'function' ? cb(userProfile) : undefined;
    });
  }

  handleLogin(callback) {
    this.auth0.getTokenSilently()
      .then((token) => {
        this.accessToken = token;
        this.auth0.getIdTokenClaims().then(({ __raw: idToken }) => {
          this.idToken = idToken;
          this.cleanUrl();
          this.initiateSession();
          callback();
        });
      })
      .catch((error) => {
        if (error.error === 'login_required' || error.error === 'unauthorized') {
          return this.backToLoginPage({ flash: { type: 'error', text: error.error_description } });
        }
      });
  }

  async requestToken() {
    this.accessToken = await this.auth0.getTokenSilently();
    const { __raw: idToken } = await this.auth0.getIdTokenClaims();
    this.idToken = idToken;
  }

  getProfile(cb) {
    return new Promise((resolve, reject) => $.ajax({
      type: 'GET',
      url: `${API_URL}/users/${encodeURIComponent(jwt_decode(this.getAccessToken()).sub)}`,
      contentType: 'application/json; charset=utf-8',
      error: (xhr) => {
        if (xhr.status === 403) {
          App.getChannel().request('report:message', '403 error with getProfile at auth0_handler');
        }
        reject(xhr);
        this.backToLoginPage({ flash: { type: 'error', text: I18n.t('notifications.errors.auth_error') } });
      },
      success: (user) => {
        if (!user.user_id && user.sub) user.user_id = user.sub; // eslint-disable-line no-param-reassign
        resolve(user);
        return typeof cb === 'function' ? cb(user) : undefined;
      }
    }));
  }
}

const API = MnObject.extend({
  channelName: 'auth0_handler',

  radioRequests: {
    'get:instance': 'getAuth0Handler',
    'update:current:user': 'updateCurrentUser'
  },

  initialize() {
    this.auth0Handler = null;
  },

  updateCurrentUser(cb) {
    return this.getAuth0Handler().updateCurrentUser(cb);
  },

  getAuth0Handler() {
    if (this.auth0Handler) { return this.auth0Handler; }
    this.auth0Handler = new Auth0Handler({
      scope: 'openid profile email',
      domain: AUTH0_DOMAIN,
      redirectUri: getIndexUrl(),
      client_id: AUTH0_CLIENT_ID,
      audience: AUTH0_AUDIENCE
    });
    return this.auth0Handler;
  }
});

export const api = new API();
