import React from 'react';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
import Widget from './widget';

const WidgetsContainer = (props) => {
  const { widgets } = props;
  const layouts = widgets.map((widget) => (<Widget widget={widget} key={widget} />));

  return (
    <GridLayout
      className="layout"
      layouts={layouts}
      breakpoints={{
        lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0
      }}
      cols={{
        lg: 12, md: 10, sm: 6, xs: 4, xxs: 2
      }}
    >
      {}
    </GridLayout>
  );
};

WidgetsContainer.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default WidgetsContainer;
