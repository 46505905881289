import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import { api as usersApi } from 'app/backbone/apps/users/users_app';
import { api as profileApi } from 'app/backbone/apps/profile/profile_app';
import { getCurrentUserId } from '~/store/selectors/global';
import { getCurrentUserIsSupport } from '~/store/selectors/current_user';
import {
  getUserByIdIsModifiable, getUserByIdRoles, getUserIsSyncing
} from '~/store/selectors/users';
import { getUserContactDetails, getRolesExceptHighest } from '../../selectors';

const GridDetailContainer = ({ row }) => {
  const userId = row?._id;
  const { t } = useTranslation();

  const {
    contacts, otherRoles, isModifiable, isCurrentUser, isSyncing
  } = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    const isSupport = getCurrentUserIsSupport(state);
    const currentUserRoles = getUserByIdRoles(state, currentUserId);
    return {
      isSyncing: getUserIsSyncing(state, userId),
      contacts: getUserContactDetails(state, userId),
      otherRoles: getRolesExceptHighest(state, userId),
      isDeletable: isSupport,
      isModifiable: isSupport || isCurrentUser || getUserByIdIsModifiable(state, userId, currentUserRoles),
      isCurrentUser: currentUserId === userId
    };
  });

  const handleEditClick = () => {
    if (isCurrentUser) {
      return profileApi.getChannel().request('app:profile');
    } return usersApi.getChannel().request('app:users:show', userId);
  };

  return (
    <div className={classNames('more-info', 'tr', { loading: isSyncing })}>
      <div className="user-lists-avatar" />
      <div className="user-lists-column" />
      <div className="user-lists-column-roles">
        <ul className="roles-list">
          {otherRoles.map(({ organizationName, role }) => (
            organizationName && <li>{t(`users.roles.${role}_at`, { org_name: organizationName })}</li>
          ))}
        </ul>
      </div>
      <div className="user-lists-column" />
      <div className="user-lists-column-contacts">
        <ul>
          { contacts.phone && (
          <li className="user-phone">
            <a href={`tel:${contacts.phone}`}>
              <i className="fa fa-fw fa-phone fa-sm" />
              {contacts.phone}
            </a>
          </li>
          ) }
          { contacts.mobile && (
          <li className="user-mobile">
            <a href={`tel:${contacts.mobile}`}>
              <i className="fa fa-fw fa-mobile fa-sm" />
              {contacts.mobile}
            </a>
          </li>
          ) }
        </ul>
      </div>
      <div className="actions user-lists-column-actions">
        { (isModifiable || isCurrentUser) && (
        <LinkButton
          className="info"
          icon="fa-md fa-fw fa-pencil"
          onClick={handleEditClick}
        />
        ) }
      </div>
    </div>
  );
};

export default GridDetailContainer;
