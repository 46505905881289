import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '~/components/spinner';
import { getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { fetchAlertsRequest } from '~/store/reducers/alerts';
import { getAlertsIsFetched } from '~/store/selectors/alerts';
import ManageAlertsContext from './manage_context';
import { clearFilters } from './reducer';
import GridContainer from './grid_container';
import FiltersFormTop from './filters_form_top';
import FiltersSidebar from './filters_sidebar';

const AlertsScreen = (props) => {
  const { slug } = props;
  const [manageActive, setManageActive] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    dispatch(fetchAlertsRequest({ data }));
  }, [dispatch, slug]);
  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);
  const { isFetched } = useSelector(
    (state) => ({
      isFetched: getAlertsIsFetched(state) && getOrganizationsIsFetched(state)
    })
  );

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <ManageAlertsContext.Provider value={{
      isActive: manageActive,
      setIsActive: setManageActive,
      selectedAlerts,
      setSelectedAlerts
    }}
    >
      <div className="content-region fill-height content filtered-content">
        <div className="alerts-container">
          <FiltersFormTop />
          <GridContainer slug={slug} />
          <FiltersSidebar />
        </div>
      </div>
    </ManageAlertsContext.Provider>
  );
};

AlertsScreen.propTypes = {
  slug: PropTypes.string.isRequired
};

export default AlertsScreen;
