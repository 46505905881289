import {
  call, takeEvery, fork, select, put
} from 'redux-saga/effects';
import requestApi from './requestApi';
import * as Actions from '~/store/reducers/profile';
import { getCurrentUser } from '~/store/selectors/current_user';
import { getCurrentUserId } from '~/store/selectors/global';

const { AUTH0_DOMAIN } = process.env;
const { AUTH0_CLIENT_ID } = process.env;

export function* resetPasswordSaga({ payload }) {
  const { onSuccess } = payload;
  try {
    const { email } = yield select(getCurrentUser);
    yield call(
      requestApi,
      'post',
      `https://${AUTH0_DOMAIN}/dbconnections/change_password`,
      {
        email,
        client_id: AUTH0_CLIENT_ID,
        connection: 'Username-Password-Authentication'
      }
    );
    if (onSuccess) {
      onSuccess(email);
    }
    yield put(Actions.resetPasswordSuccess());
  } catch (e) {
    yield put(Actions.resetPasswordFailure());
  }
}

function* watchResetPasswordSaga() {
  yield takeEvery(Actions.resetPasswordRequest, resetPasswordSaga);
}

export function* saveProfileAccessSaga({ payload }) {
  try {
    const { data, onSuccess } = payload;
    const userId = yield select(getCurrentUserId);

    const response = yield call(
      requestApi,
      'put',
      `users/${encodeURIComponent(userId)}`,
      data
    );
    if (onSuccess) {
      onSuccess();
    }
    yield put(Actions.saveProfileSuccess({ data: response, id: userId }));
  } catch (e) {
    yield put(Actions.saveProfileFailure());
  }
}

function* watchSaveProfileAccessSaga() {
  yield takeEvery(Actions.saveProfileRequest, saveProfileAccessSaga);
}

export default function* profile() {
  yield fork(watchResetPasswordSaga);
  yield fork(watchSaveProfileAccessSaga);
}
