/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { app as App } from 'app/backbone/app';
import I18n from 'app/config/i18n';
import WidgetNodeTitle from 'app/backbone/lib/components/shared_views/widget_node_title';
import { api as apiThiamis } from 'app/backbone/entities/nodes/thiamis';
import DeviceInfoIcon from 'app/backbone/lib/react/components/common/DeviceInfoIcon/DeviceInfoIcon';
import MapConfig from 'app/backbone/apps/map/show/configs';
import ThiamisLocation from 'app/backbone/lib/concerns/views/thiamis_location';
import NodeWidgetFetchThiamis from 'app/backbone/lib/concerns/views/node_widget_fetch_thiamis';
import getMap from 'app/config/google_maps';
import { __guard__ } from 'app/utils/custom-fns';
import faceTemplate from './templates/face.hbs';

const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_KEY;

const MapWidgetContentView = ThiamisLocation.extend({
  behaviors: [NodeWidgetFetchThiamis],
  template: faceTemplate,
  className: 'widget-map-container',

  ui: _.extend(_.clone(ThiamisLocation.prototype.ui), {
    map: '.map-canvas',
    noLocationText: '.no-location',
    noLocationIcon: '.no-location-icon'
  }),

  bindings: _.extend(_.clone(ThiamisLocation.prototype.bindings), {
    '.location-description .address': {
      observe: 'location_description',
      onGet() {
        return this.thiamis.get('location_description');
      }
    },
    '.location-description .gps': {
      observe: 'location_description',
      onGet() {
        return this.thiamis.get('display_latlng');
      }
    },
    '.location-description': {
      attributes: [{
        observe: 'location_description',
        name: 'class',
        onGet() {
          return classNames({
            'has-address': this.thiamis.get('location_description'),
            hidden: !this.thiamis.get('display_latlng')
          }
          );
        }
      }
      ]
    }
  }),

  modelEvents:
    { 'change:node_id': 'refreshMap' },

  async initialize({ thiamis }) {
    this.thiamis = thiamis;
    if (!this.thiamis) {
      this.thiamis = apiThiamis.getChannel().request('new:thiamis', { _id: this.model.get('node_id') });
      await this.thiamis.fetch({
        data: {
          last: 1
        }
      });
    }
    if (this.thiamis.getLocation()) {
      return this.listenTo(this.thiamis.getLocation(), 'change', () => {
        if (this.hasLocation()) { this.setPinPosition(); }
        return this.triggerMethod('data_point:changed');
      });
    }
  },

  getWidgetTitle() {
    return new WidgetNodeTitle({
      model: this.options.thiamis
    });
  },

  getStaticMapElement(mapConfig) {
    const pinIcon = __guard__(mapConfig.iconfile, (x) => x.replace('./media', 'media'));
    const latLng = encodeURI(this.thiamis.get('display_latlng'));
    const width = Math.floor(this.ui.map.width());
    const height = Math.floor(this.ui.map.height());
    const size = `${width}x${height}`;
    const url = `https://maps.googleapis.com/maps/api/staticmap?size=${size}&autoscale=2&zoom=16&scale=2&markers=scale:2%7Cicon:${pinIcon}%7C${latLng}&center=${latLng}&style=feature:landscape%7Celement:labels%7Cvisibility:off&style=feature:poi%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:geometry%7Clightness:57&style=feature:road%7Celement:labels.text.fill%7Cvisibility:on%7Clightness:24&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:transit%7Celement:labels%7Cvisibility:off&style=feature:water%7Celement:labels%7Cvisibility:off&key=${GOOGLE_MAPS_KEY}`;

    if (this.thiamis.get('display_latlng')) {
      return <div className="staticMap" style={{ backgroundImage: `url("${url}")` }} />;
    }
  },

  async getMap() {
    const map = await getMap();
    const mapConfig = MapConfig.getMarkerOptions(map, this.thiamis.get('status'));
    if (this.isRendered()) {
      ReactDOM.render(this.getStaticMapElement(mapConfig), this.ui.map[0]);
    }
  },

  getDeviceInfoComponent() {
    const props = {
      popover: {
        title: I18n.t('base.labels.warning'),
        content: I18n.t('notifications.errors.thiamis_location')
      }
    };

    return React.createElement(DeviceInfoIcon, props);
  },

  refreshMap() {
    if (this.model.get('node_id') !== this.thiamis.id) {
      this.thiamis.id = this.model.get('node_id');
      this.thiamis.fetch({
        data: {
          last: 1
        }
      });
    }
    return App.getChannel().request('when:fetched', this.thiamis, () => this.setPinPosition());
  },

  setPinPosition() {
    this.getMap();
    this.toggleNoLocationLabel();
    return this.toggleNoLocationIcon();
  },

  onAfterResize() {
    return this.getMap();
  },

  toggleNoLocationLabel() {
    return this.ui.noLocationText.toggleClass('hidden', this.hasLocation());
  },

  toggleNoLocationIcon() {
    return this.ui.noLocationIcon.toggleClass('hidden', this.thiamis.isLocationDetermined());
  },

  async getLatLng() {
    const { LatLng } = await getMap();
    return new LatLng(this.getLat(), this.getLng());
  },

  getLat() {
    return (this.thiamis != null ? this.thiamis.get('latitude') : undefined);
  },

  getLng() {
    return (this.thiamis != null ? this.thiamis.get('longitude') : undefined);
  },

  hasLocation() {
    return !_.isNaN(this.getLat()) && !_.isNaN(this.getLng());
  },

  getThiamisDevice() {
    return this.thiamisDevice || this.thiamis.getDevice();
  },

  onRender() {
    return this.stickit(this.getThiamisDevice());
  },

  onAttach() {
    ReactDOM.render(this.getDeviceInfoComponent(), this.ui.noLocationIcon[0]);
    return this.refreshMap();
  }
});

export default MapWidgetContentView;
