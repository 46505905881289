import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

export const fetchReportsRequest = createAction('@@reports/FETCH_REQUEST');
export const fetchReportsSuccess = createAction('@@reports/FETCH_SUCCESS');
export const fetchReportsFailure = createAction('@@reports/FETCH_FAILURE');

export const createReportRequest = createAction('@@reports/CREATE_REQUEST');
export const createReportSuccess = createAction('@@reports/CREATE_SUCCESS');
export const createReportFailure = createAction('@@reports/CREATE_FAILURE');

export const deleteReportRequest = createAction('@@reports/DELETE_REQUEST');
export const deleteReportSuccess = createAction('@@reports/DELETE_SUCCESS');
export const deleteReportFailure = createAction('@@reports/DELETE_FAILURE');

const ids = createReducer([], {
  [fetchReportsSuccess](state, { payload: { result } }) {
    return result || state;
  },
  [deleteReportSuccess](state, { payload: { id } }) {
    return state.filter((api) => api !== id);
  }
});

const byId = createReducer({}, {
  [fetchReportsSuccess](state, { payload }) {
    const { entities: { reports } } = payload;
    if (reports) {
      return reports;
    }
    return state;
  },
  [deleteReportSuccess](state, { payload: { id } }) {
    return omit(state, [id]);
  }
});

const isFetched = createReducer(false, {
  [fetchReportsRequest]() {
    return false;
  },
  [fetchReportsFailure]() {
    return false;
  },
  [fetchReportsSuccess]() {
    return true;
  }
});

const reports = combineReducers({
  ids,
  byId,
  isFetched
});

export const getReportById = (state, id) => state.reports.byId[id];
export const getReports = (state) => state.reports.byId;
export const getReportIds = (state) => state.reports.ids;
export const getIsFetched = (state) => state.reports.isFetched;

export default reports;
