/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import serverErrorTemplate from './templates/server_error.hbs';

export const ServerErrorView = View.extend({
  template: serverErrorTemplate
});
