import React from 'react';
import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from '~/components/form/input';
import PhotoUpload from '~/components/form/photo_upload';
import Checkbox from '~/components/form/checkbox';
import AddressForm from './address';
import FormActions from './form_actions';
import useValidation from '~/hooks/useValidation';

const CreateOrganizationForm = React.memo(({ handleSubmit }) => {
  const { t } = useTranslation();
  const { input: { value: sameBilling } } = useField('billing_same_as_shipping');
  const { validateRequired } = useValidation();

  return (
    <div className="tab-content">
      <div className="row">
        <div className="col-sm-3 text-center">
          <Field
            name="logo_url"
          >
            {
              ({ input }) => (
                <PhotoUpload
                  photo={input.value}
                  setPhoto={input.onChange}
                  className={`profile-picture ${!input.value ? 'no-logo' : ''}`}
                />
              )
            }
          </Field>
        </div>
        <div className="col-sm-9">
          <form className="invite-organization-form">
            <div className="form-box">
              <div className="row">
                <h3 style={{ fontWeight: 'bold' }}>
                  <i className="fa fa-fw fa-building" />
                  {t('attributes.formation_request.name')}
                </h3>
                <div className="row form">
                  <div className="col-md-12 col-sm-12">
                    <Field
                      name="name"
                      validate={validateRequired}
                    >
                      {({ input, meta }) => (
                        <Input
                          id="name"
                          {...input}
                          errorText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <AddressForm />
              <div className="checkbox-styled-wrapper">
                <Field
                  name="billing_same_as_shipping"
                >
                  {({ input }) => (
                    <Checkbox
                      label={t('attributes.organization.billing_same_as_shipping')}
                      checked={input.value}
                      onClick={() => input.onChange(!input.value)}
                    />
                  )}
                </Field>
              </div>
              {
                !sameBilling && <AddressForm address="billing" />
              }
            </div>
            <FormActions handleSubmit={handleSubmit} />
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateOrganizationForm;
