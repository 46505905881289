/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import { SingleChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import Routes from 'app/config/routes';
import Dashboard from 'app/backbone/entities/dashboard';

export class AnalyticsModel extends Chooser(Dashboard) {
  get jsonRootKey() {
    return 'report';
  }

  get defaults() {
    return {
      editing: false,
      name: 'Untitled report',
      widgets: []
    };
  }

  get transientAttrs() {
    return ['editing'];
  }
}

export default class AnalyticsCollection extends SingleChooser(AppCollection) {
  get model() { return AnalyticsModel; }

  url() { return Routes.reports_path(); }

  parse(json) {
    if (_.isArray(json.reports)) {
      return json.reports;
    }
    return json;
  }
}

export const API = MnObject.extend({
  channelName: 'entities:analytics',
  radioRequests: {
    'fetch:analytics': 'fetchAnalyticsEntity'
  },

  fetchAnalyticsEntity() {
    const analytics = new AnalyticsCollection();
    analytics.fetch({
      data: {
        dashboard: false
      }
    });
    return analytics;
  }
});

export const api = new API();
