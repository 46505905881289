import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import pick from 'lodash/pick';
import * as fromPartnerships from '~/store/selectors/partnerships';
import { getFilter } from './reducer';

const searchFields = ['org2_name', 'org2_external_id']; // eslint-disable-line max-len
export const getFilteredList = createSelector(
  [fromPartnerships.getPartnerships, getFilter],
  (partnerships, query) => {
    const result = Object.values(partnerships);
    if (!isEmpty(query)) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      return result.filter((partnership) => {
        const searchString = Object.values(pick(partnership, searchFields)).join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return result;
  }
);
