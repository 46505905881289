import { createSelector } from 'reselect';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import * as fromOrganizations from '~/store/reducers/organizations';

export const getOrganizationById = (state, id) => fromOrganizations.getOrganizationById(state, id);

function getValueLabelPairs(organizations) {
  return sortBy(organizations.map(({ _id, name, external_id }) => ({ value: _id, label: name, external_id })), ['label']);
}

function getValueLabelPairsWithExternalId(organizations) {
  return sortBy(organizations.map(({ _id, name, external_id }) => ({ value: _id, label: `${name} [${external_id}]`, external_id })), ['label']);
}

export const getMemberedOrganizations = createSelector(
  fromOrganizations.getOrganizations,
  (organizations) => Object.values(organizations).filter(({ membered }) => membered)
);

export const getManagedOrganizations = createSelector(
  fromOrganizations.getOrganizations,
  (organizations) => Object.values(organizations).filter(({ managed }) => managed)
);

export const getManagedOrganizationsValueLabelPairs = createSelector(
  getManagedOrganizations,
  getValueLabelPairs
);

export const getMemberedOrganizationsValueLabelPairs = createSelector(
  getMemberedOrganizations,
  getValueLabelPairs
);

export const getManagedOrganizationsValueLabelPairsWithExternalId = createSelector(
  getManagedOrganizations,
  getValueLabelPairsWithExternalId
);

export const getMemberedOrganizationsValueLabelPairsWithExternalId = createSelector(
  getMemberedOrganizations,
  getValueLabelPairsWithExternalId
);

export const getOrganizationIdsIsRental = createSelector(
  getMemberedOrganizations,
  (organizations) => organizations.filter(({ is_rental }) => is_rental).map(({ _id }) => _id)
);

export const getOrganizationNamesByIds = (ids = []) => createSelector(
  fromOrganizations.getOrganizations,
  (organizations) => ids.map((id) => get(organizations[id], 'name'))
);

export const getOrganizationByIdContactPhone = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.contact_phone
);

export const getOrganizationByIdContactEmail = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.contact_email
);

export const getOrganizationByIdShippingAddressStreet = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.shipping_address_street
);

export const getOrganizationByIdShippingAddressCity = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.shipping_address_city
);

export const getOrganizationByIdShippingAddressState = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.shipping_address_state
);

export const getOrganizationByIdShippingAddressZipCode = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.shipping_address_zip_code
);

export const getOrganizationByIdShippingAddressCountry = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.shipping_address_country
);

export const getOrganizationByIdIsModifiable = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.modifiable
);

export const getOrganizationByIdIsDestroyable = createSelector(
  fromOrganizations.getOrganizationById,
  (organization) => organization?.destroyable
);

export const getOrganizationsList = createSelector(
  fromOrganizations.getOrganizations,
  (organizations) => Object.values(organizations)
);
