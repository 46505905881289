import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import I18n from 'app/config/i18n';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { filterListByQuery as filterApiListByQuery } from '../../reducer';
import { filterListByQuery as filterWebhooksListByQuery } from '../../webhooks/reducer';

export const TABS = {
  API: 'API',
  WEBHOOKS: 'Webhooks'
};

export const TabsTemplate = ({ onClick, onSearchInputChange, slug }) => {
  const { t } = useTranslation();

  return (
    <ul className="pull-right clearfix controls">
      <li>
        <div className={`${slug}-filter-input-region filter-input-wrap pull-right`}>
          <input className="filter-input" type="text" placeholder="Filter..." onChange={onSearchInputChange} />
        </div>
      </li>
      <li>
        <a target="_blank" rel="noreferrer" href="http://docs.environet.io" className="btn btn-default">
          <i className="fa fa fa-fw fa-external-link" />
          <span>{t(`${slug}.docs`)}</span>
        </a>
      </li>
      <li>
        <button
          className="btn btn-primary btn-create-token"
          type="button"
          onClick={onClick}
        >
          <i className="fa fa fa-fw fa-plus" />
          <span>{t(`${slug}.create`)}</span>
        </button>
      </li>
    </ul>
  );
};

export default function Tabs() {
  return (
    <Nav bsStyle="tabs">
      <NavItem eventKey={TABS.API}>
        <span className="title">
          {I18n.t('models.api_plural')}
        </span>
      </NavItem>
      <NavItem eventKey={TABS.WEBHOOKS}>
        <span className="title">
          {I18n.t('models.webhook_plural')}
        </span>
      </NavItem>
    </Nav>
  );
}

export const ApiTab = ({ onClick }) => {
  const dispatch = useDispatch();

  const onFilterInputChange = _.debounce((e) => dispatch(filterApiListByQuery(e.target.value)), 200);
  const handleInputChange = (e) => {
    e.persist();
    return onFilterInputChange(e);
  };

  return (
    <TabsTemplate onClick={onClick} onSearchInputChange={handleInputChange} slug="api" />
  );
};

export const WebhooksTab = ({ onClick }) => {
  const dispatch = useDispatch();

  const onFilterInputChange = _.debounce((e) => dispatch(filterWebhooksListByQuery(e.target.value)), 200);
  const handleInputChange = (e) => {
    e.persist();
    return onFilterInputChange(e);
  };

  return (
    <TabsTemplate onClick={onClick} onSearchInputChange={handleInputChange} slug="webhooks" />
  );
};
