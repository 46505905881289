/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import { api as apiStream } from 'app/backbone/entities/data_stream';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import WidgetBase from 'app/backbone/entities/widgets/widget_base';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import reduxStore from 'app/react/store';
import { BaseWidgetNodeStreamSubscribable } from 'app/backbone/lib/concerns/entities/measurement_updatable';
import { api as apiDataPoints } from 'app/backbone/entities/data_points';
import { AIRTHIX_PARAMETER_PATHS } from '~/utils/constants';
import { updateWidgetSuccess } from '~/store/reducers/widgets';

export const NodeStreamSubscribable = (superclass) => class extends BaseWidgetNodeStreamSubscribable(superclass) {
  createNodeDataStream() {
    if (this.isFormModel) { return; }
    if (!this.dataStream) {
      this.dataStream = apiStream.getChannel().request('get:thiamis:data:stream', this.getNodeDataStreamAttrs());
      this.on('change:node_ids', (widget) => {
        this.dataStream.set(widget.getNodeDataStreamAttrs());
      });
      this.on('change:selected_data_point_ids', (widget) => {
        this.dataStream.set(widget.getNodeDataStreamAttrs());
      });
    }
    return undefined;
  }
};

export default class HeatmapWidget extends NodeStreamSubscribable(WidgetBase) {
  get transientAttrs() {
    return ['last_measurements', 'path'];
  }

  get defaults() {
    return {
      _type: 'HeatmapWidget',
      name: I18n.t('models.heatmap_widget'),
      last_measurements: 1,
      node_ids: [],
      selected_data_point_ids: [],
      size: 0
    };
  }

  get validation() {
    return {
      selected_data_point_ids: {
        required: true
      },
      node_ids: {
        required: true
      }
    };
  }

  getXhrs() {
    return [this.getNodeDataStream().getDeferred()];
  }

  getNodeIds() {
    return includes(this.get('node_ids'), 'all') ? [] : this.get('node_ids');
  }

  sync(method, model, options) {
    if (method === 'update') {
      const success = options.success;
      options.success = (data) => { // eslint-disable-line no-param-reassign
        if (success) {
          success(data);
        }
        try {
          _.delay(() => reduxStore.dispatch(updateWidgetSuccess(data)), 300);
        } catch (e) {
          App.getChannel().request('report:message', 'Error with dispatching updateWidgetSuccess', e);
        }
      };
    }
    return super.sync(method, model, options);
  }

  getCombinedCollection(data) {
    return _.reduce(data, (accu, dp) => {
      const result = accu;
      if (!dp.path) {
        return accu;
      }
      const [, device, path] = dp.path.split(':');
      const index = `${device}:${path}`;
      if (!result[index]) {
        result[index] = { ...dp, ids: [dp._id], _id: index };
      } else {
        result[index].ids.push(dp._id);
      }
      return result;
    }, {});
  }

  getAvailableDataPointsColl() {
    if (this.defaultFormDataPoints) { return this.defaultFormDataPoints; }
    this.formDataPoints = apiDataPoints.getChannel().request('fetch:form:datapoints', this.getNodeIds(), Object.values(AIRTHIX_PARAMETER_PATHS));
    this.formDataPoints.on('sync', (collection, response) => {
      const combined = this.getCombinedCollection(sortBy(response, ({ node_id }) => this.get('node_ids').indexOf(node_id)));
      collection.reset(Object.values(combined));
    });
    this.defaultFormDataPoints = apiDataPoints.getChannel().request('new:filtered:datapoints', this.formDataPoints).filterDefault();
    this.on('change:node_ids', (model, nodeIds) => this.formDataPoints.changeNodeId(nodeIds));
    return this.defaultFormDataPoints;
  }

  getDataPointsLastUpdate() {
    return this.getDataPointsColl().getLastUpdate();
  }

  getUserDataPointsColl() {
    if (this.userDataPointsColl) { return this.userDataPointsColl; }
    this.userDataPointsColl = new FilteredCollection(this.getFilteredDataPointsColl());
    return this.userDataPointsColl;
  }

  getNodeDataStreamAttrs() {
    const attrs = {
      last_measurements: this.get('last_measurements')
    };
    if (_.contains(this.get('node_ids'), 'all')) {
      attrs.paths = Object.values(AIRTHIX_PARAMETER_PATHS);
    } else {
      attrs.node_id = this.get('node_ids');
      attrs.data_point_id = this.get('selected_data_point_ids');
    }
    return attrs;
  }

  formParams() {
    return { collection: this.getAvailableDataPointsColl() };
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      const dp = this.formDataPoints?.findWhere({ _id: this.get('path') });
      if (dp) {
        json.selected_data_point_ids = dp.get('ids');
      }
    }
    return json;
  }
}
