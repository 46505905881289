/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const AppCollection = Backbone.Collection.extend({
  parse(resp) {
    return _(resp).map((attrs) => {
      const { cid } = attrs;
      // eslint-disable-next-line no-param-reassign
      delete attrs.cid;
      const existing = this.get(cid);
      if (existing) {
        return existing.set(attrs).attributes;
      } return attrs;
    });
  },

  collectAttrs() {
    return this.map((model) => model.attributes);
  },

  getXhrs() {
    return this._fetch;
  }
});

export default AppCollection;
