// import 'bootstrap-sass';
import 'bootstrap-slider';
import 'bootstrap-number-input';

_.extend($.fn.confirmation.Constructor.DEFAULTS, {
  btnOkClass: 'btn btn-xs btn-danger'
});

_.extend($.fn.popover.Constructor.prototype, {
  getCalculatedOffset(placement, pos, actualWidth, actualHeight) {
    switch (placement) {
      case 'bottom':
        return {
          top: pos.top + pos.height,
          left: pos.left + pos.width / 2 - actualWidth / 2
        };
      case 'top':
        return {
          top: pos.top - actualHeight,
          left: pos.left + pos.width / 2 - actualWidth / 2
        };
      case 'left':
        return {
          top: pos.top + pos.height / 2 - actualHeight / 2,
          left: pos.left - actualWidth
        };
      case 'right':
        return {
          top: pos.top + pos.height / 2 - actualHeight / 2,
          left: pos.left + pos.width
        };
      case 'topLeft':
        return {
          top: pos.top - actualHeight,
          left: pos.left + pos.width / 2 - actualWidth * 0.20
        };
      case 'topRight':
        return {
          top: {
            top: pos.top - actualHeight,
            left: pos.left + pos.width / 2 - actualWidth * 0.80
          }
        };
      case 'rightTop':
        return {
          top: pos.top + pos.height / 2 - actualHeight * 0.20,
          left: pos.left + pos.width
        };
      case 'rightBottom':
        return {
          top: pos.top + pos.height / 2 - actualHeight * 0.80,
          left: pos.left + pos.width
        };
      case 'bottomLeft':
        return {
          top: pos.top + pos.height,
          left: pos.left + pos.width / 2 - actualWidth * 0.20
        };
      case 'bottomRight':
        return {
          top: pos.top + pos.height,
          left: pos.left + pos.width / 2 - actualWidth * 0.80
        };
      case 'leftTop':
        return {
          top: pos.top + pos.height / 2 - actualHeight * 0.20,
          left: pos.left - actualWidth
        };
      case 'leftBottom':
        return {
          top: pos.top + pos.height / 2 - actualHeight * 0.80,
          left: pos.left - actualWidth
        };
      default:
        throw new Error(`${placement} is not valid placement`);
    }
  }
});
