/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { SingleChooser } from 'app/backbone/lib/concerns/entities/chooser';
import moment from 'app/config/moment';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import { app as App } from 'app/backbone/app';
import ValueLabelPairs from 'app/backbone/lib/concerns/entities/value_label_pairs';
import DataPointConditions, {
  ScaleDataPointCondition, LinkDataPointCondition, SimpleDataPointCondition, DataPointCondition, DiffDataPointCondition
} from 'app/backbone/entities/data_point_condition';
import { AlertDataPointMixin } from 'app/backbone/lib/concerns/entities/data_point_condition';
import HumanizeDuration from 'app/backbone/lib/concerns/views/humanize_duration';
import { api as apiBridge } from 'app/backbone/lib/clusternet/bridge';
import LastUpdateDataPoint from 'app/backbone/entities/data_point_last_update';
import { MIN_REPORTING_INTERVAL } from 'app/backbone/entities/nodes/config';
import DataPoint from 'app/backbone/entities/data_point';
import { __guard__, sortAlphaNum } from 'app/utils/custom-fns';

export class DataPointsColl extends ValueLabelPairs(AppCollection.extend({
  model(attrs, options) {
    const Model = DataPointsColl.modelsFactory(attrs._type);
    return new Model(attrs, options);
  }
})) {
  static modelsFactory = (type, returnAll = false) => {
    const models = {
      metric: MetricDataPoint, // eslint-disable-line no-use-before-define
      raw: RawDataPoint, // eslint-disable-line no-use-before-define
      alert: AlertDataPoint, // eslint-disable-line no-use-before-define
      default: DataPoint
    };

    if (returnAll) { return _.values(models); }
    return models[type] || models.default;
  }

  modelId(attrs) {
    return attrs._id;
  }

  initialize(models, opts) {
    ({ stream: this.stream } = opts || {});
    this.lastUpdate = new LastUpdateDataPoint();

    // no after:initialize event exists, needed to use this trick
    // http://stackoverflow.com/questions/9136815/getting-backbone-js-to-run-a-function-after-constructing-a-collection
    this._afterInitialize(() => this.each((dPoint) => this._checkLastUpdate(dPoint)));

    this.on('add change:timestamp', (dataPoint) => this._checkLastUpdate(dataPoint));
    return this.on('reset', function () {
      return this.each((dPoint) => this._checkLastUpdate(dPoint));
    });
  }

  getStatusToSort(dp) {
    return (dp != null ? dp.getStatusType(__guard__(this.parents, (x) => x[0])) : undefined) === 'offline' || dp.isMetric() ? 'B' : 'A';
  }

  comparator(dp1, dp2) {
    if (dp1.get('_type') === 'alert') {
      return 0;
    }
    const status1 = this.getStatusToSort(dp1);
    const status2 = this.getStatusToSort(dp2);
    return sortAlphaNum(`${status1}.${dp1.presentName()}`, `${status2}.${dp2.presentName()}`);
  }

  getReportingInterval() {
    return __guard__(this.stream != null ? this.stream.get('node') : undefined, (x) => x.getReportingInterval()) || MIN_REPORTING_INTERVAL;
  }

  getDefaultTimezone() {
    const timezone = __guard__(this.getThiamisTimezone(), (x) => x.getLastMeasurement());
    if (!_.isDefined(timezone) || !_.isDefined(moment().tz(`${timezone}`)._z)) {
      return App.getChannel().request('get:user:timezone');
    }
    return timezone;
  }

  getWindSpeedDataPoint(thiamis) {
    let windSpeedDataPoint = null;
    _(DataPoint.WIND_SPEED_NAMES).each((name) => {
      if (windSpeedDataPoint) { return; }
      return windSpeedDataPoint = this.find((dp) => name.test(__guard__(dp.get('name'), (x) => x.toLowerCase())) && thiamis.hasDevice(dp.get('device_id')));
    });
    return windSpeedDataPoint;
  }

  _afterInitialize(callback) {
    return _.defer(callback);
  }

  // OPTIMIZE: the comparisons already happened when the measurement was added, just take the first one
  _checkLastUpdate(dataPoint) {
    const newTs = dataPoint.get('timestamp');
    if (newTs > this.lastUpdate.get('timestamp')) {
      return this.lastUpdate.set({ timestamp: newTs, value: dataPoint.get('value') });
    }
  }

  getLastUpdate() { return this.lastUpdate; }

  trigger(event) {
    // TODO: add option silenceSort
    // was necessary to add because of sort event triggering reset in Obscura, although no real sorting happened
    if (event === 'sort') { return; }
    return super.trigger(...arguments); // eslint-disable-line prefer-rest-params
  }

  parse(resp, opts) {
    if (_.isArray(resp)) { return super.parse(resp, opts); }
    const parsedResp = _(resp).reduce(((accu, measurements, nodeId) => {
      accu.push({ _id: nodeId, measurements });
      return accu;
    }), []);
    return super.parse(parsedResp, opts);
  }

  getThiamisDataPoint(path) {
    // eslint-disable-next-line no-param-reassign
    if (!_.contains(path, ':')) { path = `0:${path}`; }
    return this.find((dp) => _.endsWith(dp.get('path'), `:${path}`));
  }

  getDataPointsByDevice(id) {
    return this.filter((dp) => dp.get('device_id') === id);
  }

  getThiamisBatteryVoltage() {
    return this.getThiamisDataPoint(DataPoint.PATHS.BATTERY_VOLTAGE);
  }

  getThiamisLocation() {
    return this.getThiamisDataPoint(DataPoint.PATHS.LOCATION);
  }

  getThiamisTimezone() {
    return this.getThiamisDataPoint(DataPoint.PATHS.TIMEZONE);
  }

  getThiamisGpsSource() {
    return this.getThiamisDataPoint(DataPoint.PATHS.GPS_SOURCE);
  }

  getThiamisSignal() {
    return this.getThiamisSignalsColl().first();
  }

  getThiamisSignalsColl() {
    if (this.signalsColl) { return this.signalsColl; }
    this.signalsColl = new FilteredCollection(this);
    this.signalsColl.filterBy((dp) => dp.isThiamisGsmSignal() || dp.isThiamisWifiSignal());
    this.signalsColl.setSort((dp) => -dp.get('timestamp'));
    return this.signalsColl;
  }

  findStelConfig(dataPoint) {
    const map = { 7: '101', 8: '102', 9: '103', 10: '104', 11: '105' };
    const { serial, device, path } = dataPoint.parsePath();
    return this.findWhere({ path: `${serial}:${device}:${map[path]}` });
  }
}

export class AlertDataPoint extends AlertDataPointMixin(DataPoint) {
  get transientAttrs() {
    return ['measurements', 'unit', '_measurements'];
  }

  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'conditions',
        collectionType: DataPointConditions
      }
    ];
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      if (json.conditions.length > 1) {
        json.conditions = { and: json.conditions };
      }
    }
    return json;
  }

  presentName() {
    const conditions = this.get('conditions').map((condition) => condition.presentName());
    const relation = __guard__(this.getAlert(), (x) => x.get('relation'));
    return conditions.join(` ${relation} `);
  }
}

export class AlertDataPointsColl extends SingleChooser(DataPointsColl) {
  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      return _.filter(json, (dataPoint) => !_.isEmpty(dataPoint.conditions) && (dataPoint._type === 'alert'));
    }
    return json;
  }
}

export class MetricDataPoint extends AlertDataPointMixin(HumanizeDuration(DataPoint)) {
  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'conditions',
        collectionType: DataPointConditions
      }
    ];
  }

  get validation() {
    return {
      name: {
        required: true
      }
    };
  }

  getDisplayFunc() {
    return __guard__(this.getCondition().get('func'), (x) => x.toUpperCase());
  }

  getDisplayPeriod() {
    return this.humanizeDuration(this.getCondition().get('value'));
  }

  presentName() {
    if (this.getCondition() instanceof ScaleDataPointCondition) {
      // eslint-disable-next-line prefer-rest-params
      let name = super.presentName(...arguments);
      const linkedName = this.getLinkedDataPointName();
      if (linkedName) {
        name += ` ${linkedName}`;
      }
      return name;
    }
    if (this.getCondition() instanceof LinkDataPointCondition) {
      return super.presentName(...arguments); // eslint-disable-line prefer-rest-params
    }
    if (this.getCondition() instanceof DiffDataPointCondition) {
      return this.get('name');
    }
    if (this.getCondition() instanceof SimpleDataPointCondition && this.get('is_metric_chart')) {
      return this.get('name');
    }
    return `${this.getDisplayFunc()} ${this.getDisplayPeriod()}`;
  }

  getLinkedDataPointName() {
    const linked = this.get('linked');
    if (linked) {
      return __guard__(this.collection.get(linked[0]), (x) => x.get('name'));
    }
  }

  getCondition() {
    return this.get('conditions').first();
  }
}

export class MetricDataPointsColl extends AlertDataPointsColl {
  get model() { return MetricDataPoint; }
}

export class FilteredFormDataPointsColl extends ValueLabelPairs(FilteredCollection) {
  filterDefault() {
    return this.filterBy((dataPoint) => dataPoint.isDefault());
  }

  getValueLabelPairs() {
    return this.map((model) => {
      let label;
      if (model instanceof MetricDataPoint) {
        label = model.get('name');
      } else { label = model.presentName(); }
      return { label, value: model.id };
    });
  }
}

export class FormDataPointsColl extends DataPointsColl {
  initialize(models, { nodeId, paths }) {
    this.nodeId = nodeId;
    this.paths = paths;
    return super.initialize(...arguments); // eslint-disable-line prefer-rest-params
  }

  changeNodeId(newNodeId) {
    if (this.nodeId !== newNodeId) {
      this.reset();
      this.nodeId = newNodeId;
      if (newNodeId) { return this.fetch({ reset: true }); }
    }
  }

  sync(method, model, ...opts) {
    if (method !== 'read') { throw new Error('Unsupported method for data points.'); }
    const data = { path: this.paths };
    if (!_.contains(this.nodeId, 'all')) {
      data.node_id = this.nodeId;
    }
    return apiBridge.getChannel().request('get:instance').getDataPoints(data, ...Array.from(opts));
  }
}

export class RawDataPoint extends DataPoint {
  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'conditions',
        collectionType: DataPointConditions,
        relatedModel: DataPointCondition
      }
    ];
  }
}

export class RawDataPointsColl extends AlertDataPointsColl {
  get model() { return RawDataPoint; }
}

export const API = MnObject.extend({
  channelName: 'entities:data_points',
  radioRequests: {
    'new:filtered:datapoints': 'getFilteredFormDataPoints',
    'new:datapoints': 'newDataPoints',
    'get:datapoints:with:series': 'getDataPointsWithSeries',
    'fetch:form:datapoints': 'fetchFormDataPoints'
  },
  newDataPoints(...opts) {
    return new DataPointsColl(null, ...Array.from(opts));
  },

  getDataPointsWithSeries(options) {
    _(options).extend({ clusternetUrl: App.getChannel().request('clusternet:url') });
    return new DataPointsColl(null, options);
  },

  fetchFormDataPoints(nodeId, paths, fetchOptions = {}) {
    const formDataPoints = new FormDataPointsColl(null, { nodeId, paths });
    if (nodeId || paths) {
      formDataPoints.fetch({ reset: true, ...fetchOptions });
    }
    return formDataPoints;
  },

  getFilteredFormDataPoints(dataPoints) {
    return new FilteredFormDataPointsColl(dataPoints);
  }
});

export const api = new API();
