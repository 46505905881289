import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'app/config/moment';
import I18n from 'app/config/i18n';
import { api as apiTimezone } from 'app/backbone/entities/abstract/timezones';
import DataPoint from 'app/backbone/entities/data_point';
import ParamInfoIcon from 'app/backbone/lib/react/components/lists/thiamises/ParamInfoIcon/ParamInfoIcon';
import { withSelfUpdatable } from 'app/backbone/lib/react/hocs';
import { __guard__ } from 'app/utils/custom-fns';

class LocalTime extends Component {
  componentDidMount() {
    return (this.props.dataPointsColl != null ? this.props.dataPointsColl.on('add reset', this.updateComponent, this) : undefined);
  }

  componentWillUnmount() {
    if (this.props.dataPointsColl != null) {
      this.props.dataPointsColl.off('add reset', this.updateComponent);
    }
    return (this.timezone != null ? this.timezone.off('change:value', this.updateComponent) : undefined);
  }

  getDisplayValue() {
    if (this.isValidTimezone()) { return moment().tz(this.getTimeZone()).format('h:mm A'); }
  }

  getTitle() {
    if (this.isValidTimezone()) {
      return I18n.t('recent_measurements_widget.local_time_title',
        { timezone: __guard__(apiTimezone.getChannel().request('get:timezone', this.getTimeZone()), (x) => x.label) });
    } return I18n.t('recent_measurements_widget.local_time');
  }

  getTimezoneDp() {
    if (this.timezone) { return this.timezone; }
    this.timezone = this.props.dataPointsColl.getThiamisDataPoint(DataPoint.PATHS.TIMEZONE);
    if (!this.timezone) { return; }
    this.timezone.on('change:value', this.updateComponent, this);
    return this.timezone;
  }

  getTimeZone() {
    return this.props.timezone || __guard__(this.getTimezoneDp(), (x) => x.getLastMeasurement());
  }

  updateComponent() {
    return this.forceUpdate();
  }

  isValidTimezone() {
    return apiTimezone.getChannel().request('get:timezone', this.getTimeZone());
  }

  render() {
    const classes = classNames('fa', 'fa-fw', 'fa-clock-o', { disabled: !this.isValidTimezone() });
    return <ParamInfoIcon value={this.getTimeZone()} displayValue={this.getDisplayValue()} iconClass={classes} title={this.getTitle()} />;
  }
}

LocalTime.defaultProps = {
  timezone: null,
  dataPointsColl: null
};

LocalTime.propTypes = {
  timezone: PropTypes.any,
  dataPointsColl: PropTypes.any
};

export default withSelfUpdatable(LocalTime);
