/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AppModel from 'app/backbone/lib/entities/app_model';
import Autosyncing from 'app/backbone/lib/concerns/entities/autosyncing';

export default class Widget extends Autosyncing(AppModel) {
  get jsonRootKey() { return 'widget'; }

  isSmall() {
    return this.getSize() === 0;
  }

  getSize() {
    return this.get('size') || 0;
  }

  getType() {
    return this.get('_type');
  }

  getXhrs() {
    return [];
  }

  toggleSize() {
    return this.set({ size: (1 - this.getSize()) });
  }

  formParams() { return {}; }

  urlRoot() {
    return this.collection.url();
  }
}
