import React, { useCallback } from 'react';
import { Container, Label } from './styles';

const Switch = ({ value, onChange }) => {
  const handleChange = useCallback(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <Container onClick={handleChange} status={value}>
      <Label status={value}>{value ? 'On' : 'Off'}</Label>
    </Container>
  );
};

export default Switch;
