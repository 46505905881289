/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app'; // eslint-disable-line import/no-cycle
import { api as organizationApi } from './organization'; // eslint-disable-line import/no-cycle

export const API = MnObject.extend({
  channelName: 'entities:membership',

  radioRequests: {
    'get:memberships': 'getMemberships',
    'reset:memberships': 'resetMemberships'
  },

  getMemberships() {
    return this.memberships != null ? this.memberships : (this.memberships = organizationApi.getChannel().request('get:membered:organizations',
      App.getChannel().request('get:organizations')));
  },

  resetMemberships() {
    // eslint-disable-next-line prefer-rest-params
    return App.getChannel().request('reset:organizations', ...Array.from(arguments));
  }
});

export const api = new API();
