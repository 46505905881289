import isEmpty from 'lodash/isEmpty';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import 'app/backbone/lib/utilities';
import { api as auth0Api } from 'app/utils/auth0_handler';
import validations from 'app/config/common_data/validations';
import locales from 'app/config/common_data/locales';
import userNotices from 'app/config/common_data/userNotices';
import reduxStore from 'app/react/store';
import * as schema from '~/store/schema';
import { normalizeResp } from '~/store/normalizr';
import { fetchUserProfileSuccess } from '~/store/reducers/global';
import { getEnvironment } from './custom-fns';
import 'app/backbone/config';

const RELEASE_UPDATE_INTERVAL = 600000;
const GITHUB_SHA = process.env.GITHUB_SHA;
const API_URL = process.env.API_URL;

export default class Boot {
  constructor(options = {}) {
    this.options = options;
    this.releaseTimer = null;
    this.dfrd = new $.Deferred();
  }

  checkReleaseLoop() {
    this.releaseTimer = setInterval(() => {
      if (typeof GITHUB_SHA !== 'undefined') {
        $.ajax({
          cache: false,
          url: 'static/release.json',
          dataType: 'json',
          success: (data) => {
            if (GITHUB_SHA !== data.commit_hash) {
              clearInterval(this.releaseTimer);
              $('body').addClass('new-release');
              App.getChannel().trigger('flash:message', 'info', I18n.t('notifications.info.new_release_available'), { timeOut: 0, closeButton: true });
              // eslint-disable-next-line no-restricted-globals
              setTimeout(() => location.reload(), 180000);
            }
          }
        });
      }
    }, RELEASE_UPDATE_INTERVAL);
  }

  startApp(data) {
    $('.page-loader').fadeOut();
    this.dfrd.resolve(data);
  }

  dispatchUserProfileSuccess(userProfile) {
    const response = normalizeResp(userProfile, schema.userSchema);
    reduxStore.dispatch(fetchUserProfileSuccess(response));
  }

  boot() {
    auth0Api.getChannel().request('get:instance').handleLogin(async () => {
      let userProfile;
      try {
        userProfile = await auth0Api.getChannel().request('get:instance').getProfile();
        if (isEmpty(userProfile.user_metadata)) { // when first login after signup empty profile returned, sync issue with auth0
          const fetchUserProfileInterval = setInterval(() => {
            auth0Api.getChannel().request('get:instance').getProfile().then((user) => {
              this.dispatchUserProfileSuccess(user);
              if (!isEmpty(user.user_metadata)) {
                clearInterval(fetchUserProfileInterval);
              }
            });
          }, 1000);
        }
        this.dispatchUserProfileSuccess(userProfile);
        this.parsedData = {
          environment: getEnvironment(),
          locales,
          currentUser: userProfile,
          filed_invitations: [],
          intercomIdentity: userProfile.intercom_identity,
          filed_partnership_invitations: [],
          userNotices,
          clusternetUrl: API_URL,
          clusternetWSUrl: `wss://${API_URL.split('://')[1]}/socket`,
          validations,
          defaultOrgName: 'Netronix'
        };
        I18n.changeLanguage(userProfile.user_metadata.locale || 'en');
        this.checkReleaseLoop();
        this.startApp(this.parsedData);
      } catch (error) {
        auth0Api.getChannel().request('get:instance').backToLoginPage({ flash: { type: 'error', text: I18n.t('notifications.environet_bootstrap_error') } });
      }
    });
    return this.dfrd.promise();
  }
}