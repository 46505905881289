/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import Syncable from 'app/backbone/lib/concerns/views/syncable';
import Autosaving from 'app/backbone/lib/concerns/views/autosaving';
import reportLayoutTemplate from './templates/report_layout.hbs';
import staticHeaderTitleTemplate from './templates/static_header_title.hbs';
import reportHeaderTitleTemplate from './templates/report_header_title.hbs';
import reportHeaderTemplate from './templates/report_header.hbs';

export const DashboardLayout = View.extend({
  behaviors: [Syncable, Autosaving],
  template: reportLayoutTemplate,
  className: 'report',

  regions: {
    headerRegion: '.header-region',
    widgetsRegion: '.widgets-region'
  }
});

export const StaticHeaderTitle = View.extend({
  template: staticHeaderTitleTemplate
});

export const HeaderTitle = View.extend({
  template: reportHeaderTitleTemplate,

  triggers:
    { 'click .destroy_report': 'click:destroy' },

  bindings: {
    '.config-report-title': {
      observe: 'title',
      events: ['blur']
    }
  },

  serializeData() {
    return _(super.serializeData(...arguments)).extend(_(this.model).pick('getModelSettings')); // eslint-disable-line prefer-rest-params
  },

  onRender() { return this.stickit(); }
});

export const Header = View.extend({
  template: reportHeaderTemplate,

  regions: {
    titleRegion: '.report-title-region',
    toolbarRegion: '.report-toolbar-region'
  }
});
