import {
  call,
  all,
  put,
  fork,
  takeLatest,
  takeEvery,
  select
} from 'redux-saga/effects';
import { normalizeResp, normalizeNodesResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import requestApi from './requestApi';
import * as Actions from '~/store/reducers/alerts';
import { fetchNodesSuccess } from '~/store/reducers/nodes';
import { fetchUsersSuccess } from '~/store/reducers/users';
import { getAlertByIdOrganizationId } from '~/store/selectors/alerts';

export function* alertsFetchRequestSaga({ payload = {} }) {
  try {
    const data = yield call(
      requestApi,
      'post',
      'search/nodes',
      {
        type: 'alert',
        last: 3,
        ...payload.data
      }
    );
    const response = normalizeResp(normalizeNodesResp(data), schema.alertsListSchema);
    yield put(Actions.fetchAlertsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchAlertsFailure(e.toString()));
  }
}

function* watchFetchAlertsRequest() {
  yield takeLatest(Actions.fetchAlertsRequest.toString(), alertsFetchRequestSaga);
}

export function* alertDestroyRequestSaga({ payload: alertId }) {
  try {
    yield call(
      requestApi,
      'delete',
      `nodes/${alertId}`
    );
    yield put(Actions.destroyAlertsSuccess(alertId));
  } catch (e) {
    yield put(Actions.destroyAlertsFailure(e.toString()));
  }
}

function* watchDestroyAlertRequest() {
  yield takeEvery(Actions.destroyAlertsRequest.toString(), alertDestroyRequestSaga);
}

export function* alertActivateRequestSaga({ payload: { alertId } }) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${alertId}/activate`
    );
    yield put(Actions.activateSuccess({ alertId, alert: response.data }));
  } catch (e) {
    yield put(Actions.activateFailure(e.toString()));
  }
}

function* watchAlertActivateRequest() {
  yield takeEvery(Actions.activateRequest.toString(), alertActivateRequestSaga);
}

export function* alertDeactivateRequestSaga({ payload: { alertId } }) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${alertId}/deactivate`
    );
    yield put(Actions.deactivateSuccess({ alertId, alert: response.data }));
  } catch (e) {
    yield put(Actions.deactivateFailure(e.toString()));
  }
}

function* watchAlertDeactivateRequest() {
  yield takeEvery(Actions.deactivateRequest.toString(), alertDeactivateRequestSaga);
}

export function* fetchAlertDetailsRequestSaga({ payload }) {
  const { alertId, params = { last: 1 } } = payload;
  try {
    const data = yield call(
      requestApi,
      'post',
      'search/nodes',
      { node_id: alertId, ...params }
    );
    const response = normalizeResp(normalizeNodesResp(data), schema.alertsListSchema);
    yield put(Actions.fetchAlertSuccess({ ...response, alertId }));
    const organizationId = yield select(getAlertByIdOrganizationId, alertId);
    const [nodesData, usersData] = yield all([
      call(
        requestApi,
        'post',
        'search/nodes',
        { organization_id: organizationId, type: 'thiamis', includes: 'data_points' }
      ),
      call(
        requestApi,
        'get',
        `users?organization_id=${organizationId}`
      )
    ]);
    const nodes = normalizeResp(normalizeNodesResp(nodesData), schema.nodesListSchema);
    yield put(fetchNodesSuccess(nodes));

    try {
      yield put(Actions.fetchAlertDetailsSuccess({ alertId }));
    } catch (e) {
      yield put(Actions.fetchAlertDetailsSuccess({ alertId }));
    }
    const users = normalizeResp(usersData.users || usersData, schema.usersSchema);
    yield put(fetchUsersSuccess(users));
  } catch (e) {
    yield put(Actions.fetchAlertDetailsFailure({ alertId, e }));
  }
}

function* watchFetchAlertDetailsRequest() {
  yield takeLatest(Actions.fetchAlertDetailsRequest.toString(), fetchAlertDetailsRequestSaga);
}

export default function* app() {
  yield fork(watchFetchAlertsRequest);
  yield fork(watchFetchAlertDetailsRequest);
  yield fork(watchDestroyAlertRequest);
  yield fork(watchAlertActivateRequest);
  yield fork(watchAlertDeactivateRequest);
}
