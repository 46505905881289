import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  clearNodes,
  editNodeSuccess,
  fetchNodesSuccess,
  fetchNodeSuccess,
  editNodeDeviceAutomationSuccess
} from '~/store/reducers/nodes';

const byIdData = {};
const byId = createReducer(byIdData, {
  [clearNodes]() {
    return byIdData;
  },
  [fetchNodesSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'devices', byIdData)
      };
    }
    return state;
  },
  [fetchNodeSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'devices', byIdData)
      };
    }
    return state;
  },
  [editNodeSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'devices', byIdData)
      };
    }
    return state;
  },
  [editNodeDeviceAutomationSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'devices', byIdData)
      };
    }
    return state;
  }
});

const devices = combineReducers({
  byId
});

export const getDevices = (state) => state.devices.byId;
export const getDeviceById = (state, id) => (id ? state.devices.byId[id] : null);

export default devices;
