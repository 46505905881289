import React from 'react';
import I18n from 'app/config/i18n';

export const StatusFormatter = ({ row: { status_type } }) => (
  <b
    className={`device-status ${status_type}`}
    data-tip={I18n.t(`alerts.states.${status_type}`, { defaultValue: I18n.t('base.labels.n_a') })}
    data-class="devices-status-tooltip"
  >
    <i className="fa fa-circle fa-lg" />
  </b>
);
