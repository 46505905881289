import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from '~/components/form/input';
import Select from '~/components/form/select';
import PhotoUpload from '~/components/form/photo_upload';
import cameraPlaceholder from '~/assets/icons/camera.svg';
import infoIcon from '~/assets/icons/icn-info.svg';
import CreateCertificateFormActions from './form_actions';
import useValidation from '~/hooks/useValidation';

const CreateCertificateForm = React.memo(({ nodes, handleSubmit }) => {
  const { t } = useTranslation();

  const { validatePhone, validateRequired } = useValidation();

  return (
    <div className="tab-content">
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="devices"
            formatOnBlur
            validate={validateRequired}
          >
            {({ input, meta }) => (
              <Select
                {...input}
                label={t('certificates.devices')}
                options={nodes}
                isMulti
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <Field
            name="photo"
            validate={validateRequired}
          >
            {
              ({ input, meta }) => (
                <PhotoUpload
                  photo={input.value}
                  setPhoto={input.onChange}
                  errorText={meta.touched && meta.error}
                  className="certificate-picture"
                  cropConfig="9:5"
                  placeholder={cameraPlaceholder}
                  uploadcareKey={process.env.UPLOADCARE_BLUE_CERTIFICATE_KEY}
                  helpText={(
                    <small className="certificate-picture-alt pull-right">
                      <img src={infoIcon} alt="" />
                      Min width 800px
                    </small>
                  )}
                />
              )
            }
          </Field>
        </div>
        <div className="col-md-7 col-sm-12">
          <form className="form invite-organization-form">
            <div className="form-box contact-info">
              <div className="row">
                <h3 style={{ fontWeight: 'bold' }}>
                  {t('certificates.common')}
                </h3>
                <Field
                  name="name"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Input
                      id="name"
                      label={t('certificates.name')}
                      helpText={(
                        <span className="certificate-example">
                          <small>
                            <b>Ex:</b>
                            {' '}
                            Apple Inc
                            <br />
                            <b>Note:</b>
                            {' '}
                            It will be a part of the page title
                          </small>
                        </span>
                      )}
                      {...input}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field
                  name="description"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Input
                      id="description"
                      label={t('certificates.description')}
                      type="textarea"
                      {...input}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field
                  name="phone"
                  validate={validatePhone}
                >
                  {({ input, meta }) => (
                    <Input
                      id="phone"
                      label={t('certificates.phone')}
                      type="phone"
                      {...input}
                      errorText={meta.touched && meta.error}
                      defaultCountry="US"
                    />
                  )}
                </Field>
                <Field
                  name="address"
                  validate={validateRequired}
                >
                  {({ input, meta }) => (
                    <Input
                      id="address"
                      label={t('certificates.address')}
                      type="textarea"
                      {...input}
                      errorText={meta.touched && meta.error}
                      helpText={(
                        <span className="certificate-example">
                          <small>
                            Ex:&nbsp;
                          </small>
                          <small>
                            3401 Grays Ferry Avenue
                            <br />
                            Philadelphia, PA 19146, USA
                          </small>
                        </span>
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
            <CreateCertificateFormActions handleSubmit={handleSubmit} />
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateCertificateForm;
