/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { __guard__ } from 'app/utils/custom-fns';

export default class OpacityView extends View {
  get template() { return false; }
  get className() { return 'loading-container'; }

  initialize(options) {
    super.initialize(options);
    ({ coveredView: this.coveredView } = options);
    return this.coveredEl = __guard__(this.coveredView != null ? this.coveredView.$el : undefined, (x) => x.clone());
  }

  onRender() {
    this.$el.html(this.coveredEl);
    return this.$el.css('opacity', 0.5);
  }
}
