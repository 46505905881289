import last from 'lodash/last';
import { createSelector } from 'reselect';
import { isDefined } from '~/utils/helpers';
import * as fromMeasurements from '~/store/reducers/measurements';

export function getMeasurementsById(state, id) {
  return fromMeasurements.getMeasurementsById(state, id);
}

export const getMeasurementsByIdData = createSelector(
  getMeasurementsById,
  (measurements = {}) => measurements.data || []
);

export const getLastMeasurement = createSelector(
  getMeasurementsByIdData,
  (data) => last(data)
);

export const getLastMeasurementValue = createSelector(
  getLastMeasurement,
  (measurements = []) => measurements[1]
);

export function getLastMeasurementsValuesById(state, ids) {
  return ids.reduce((result, id) => {
    result[id] = getLastMeasurementValue(state, id); // eslint-disable-line no-param-reassign
    return result;
  }, {});
}

export const getLastMeasurementTs = createSelector(
  getLastMeasurement,
  (measurements = []) => measurements[0]
);

export function getLastMeasurementsTs(state, ids) {
  return ids.map((id) => getLastMeasurementTs(state, id)).filter((value) => isDefined(value));
}
