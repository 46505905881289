import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNodesRequest
} from '~/store/reducers/nodes';
import DeleteAPIToken from './delete_api_token';
import { getNodeByIdDisplayName } from '~/store/selectors/nodes';

const GridDetailRow = ({ row }) => {
  const dispatch = useDispatch();
  const deviceName = useSelector((state) => getNodeByIdDisplayName(state, row.nodeId));

  useEffect(() => {
    if (row.device_scope) {
      dispatch(fetchNodesRequest({
        data: {
          node_id: row.nodeId
        }
      }));
    }
  }, [row.nodeId, row.device_scope, dispatch]);

  return (
    <div className="tr more-info">
      <div colSpan="3" className="subscriptions-list td">
        {
          row.device_scope && (
            <ul className="keywords">
              <li>{deviceName}</li>
            </ul>
          )
        }
      </div>
      <div className="actions-col td">
        <DeleteAPIToken
          id={row.id}
        />
      </div>
    </div>
  );
};

export default GridDetailRow;
