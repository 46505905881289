import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDataPointById } from '~/store/selectors/data_points';
import { getNodeByIdDisplayName } from '~/store/selectors/nodes';

const ConditionItem = (props) => {
  const {
    nodeName, displayName, value, func, relation
  } = props;
  const { t } = useTranslation();
  return (
    <div className="sensor-box-container">
      <div className="sensor-box">
        <div className="parameter-name">
          <h2>
            <p className="display-node">{nodeName}</p>
            <b className="parameter">
              <span className="name">{displayName}</span>
            </b>
          </h2>
        </div>
        <p className="func">{t(`conditions.expressions.${func}_title`)}</p>
        <h1 className="value">{value}</h1>
        <div className="relation">{relation}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { dataPointId }) => {
  const dataPoint = getDataPointById(state, dataPointId);
  return {
    nodeName: getNodeByIdDisplayName(state, get(dataPoint, 'node_id'))
  };
};

export default connect(mapStateToProps)(ConditionItem);
