import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import I18n from 'app/config/i18n';
import { useSelector } from 'react-redux';
import { getIncomingUserInvitations } from '~/store/selectors/user_invitations';

export const TABS = {
  USERS: 'users',
  INVITATIONS: 'invitations'
};

export default function Tabs() {
  const invitations = useSelector((state) => getIncomingUserInvitations(state));
  return (
    <Nav bsStyle="tabs">
      <NavItem eventKey={TABS.USERS}>
        <i className="fa fa-fw fa-list" />
        <span className="title">
          {I18n.t('models.user_plural')}
        </span>
      </NavItem>
      <NavItem eventKey={TABS.INVITATIONS}>
        <i className="fa fa-fw fa-paper-plane-o" />
        <span className="title">
          {I18n.t('models.user_invitation_plural')}
        </span>
        {
          !!invitations?.length && (
            <span className="badge">
              {invitations.length}
            </span>
          )
        }
      </NavItem>
    </Nav>
  );
}
