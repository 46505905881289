import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { getDataPointParameterById } from 'app/react/store/selectors/data_points';
import Spinner from '~/components/spinner';
import { getWidgetDataPointIds, getWidgetIsExpanded } from '~/store/selectors/widgets';
import { getHeatmapWidgetLoaded, getFilteredDataPointIds } from './selectors';
import { Heatmap, HeatmapContainer, HeatmapFooter } from './styles';
import HeatmapItemWithTooltip from './heatmap_item';
import { Flex } from '../layout';

const HeatmapWidget = ({ widgetId }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const allDataPointIds = useSelector((state) => getWidgetDataPointIds(state, widgetId));
  const dataPointIds = useSelector((state) => getFilteredDataPointIds(state, widgetId));
  const isExpanded = useSelector((state) => getWidgetIsExpanded(state, widgetId));
  const isLoaded = useSelector((state) => getHeatmapWidgetLoaded(state, widgetId));
  const isManyDataPoints = useMemo(() => dataPointIds.length > 7, [dataPointIds]);
  const isOnlyDevice = useMemo(() => dataPointIds.length === 1, [dataPointIds]);
  const parameter = useSelector((state) => getDataPointParameterById(state, dataPointIds[0]), [dataPointIds]);

  const handleWindowResize = useCallback(() => setWindowSize(window.innerWidth), [setWindowSize]);

  const debouncedWindowResize = debounce(handleWindowResize, 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedWindowResize);
    return () => window.removeEventListener('resize', debouncedWindowResize);
  }, [debouncedWindowResize]);

  const { gridColumnTemplate, itemSize } = useMemo(() => {
    if (isOnlyDevice) {
      return {
        gridColumnTemplate: 'repeat(1, minmax(120px, 1fr));',
        itemSize: 'large'
      };
    }
    if (!isManyDataPoints) {
      if (isExpanded && windowSize > 1130) {
        return {
          gridColumnTemplate: 'repeat(4, minmax(80px, 1fr));',
          itemSize: 'medium'
        };
      }
      return {
        gridColumnTemplate: 'repeat(2, minmax(120px, 1fr));',
        itemSize: 'medium'
      };
    }
    return {
      gridColumnTemplate: 'repeat(auto-fit, minmax(60px, 1fr));',
      itemSize: 'normal'
    };
  }, [isOnlyDevice, isManyDataPoints, isExpanded, windowSize]);

  if (!isLoaded) {
    return <Spinner className="react-modal-spinner" />;
  }

  return (
    <HeatmapContainer>
      <Flex className="node-widget-content">
        <Heatmap gridTemplateColumns={gridColumnTemplate}>
          {dataPointIds.map((dataPointId) => (
            <HeatmapItemWithTooltip
              key={`${widgetId}_${dataPointId}`}
              size={itemSize}
              id={dataPointId}
              widgetId={widgetId}
            />
          ))}
        </Heatmap>
      </Flex>
      <HeatmapFooter
        parameter={parameter}
        dataPointIds={allDataPointIds}
        widgetId={widgetId}
      />
    </HeatmapContainer>
  );
};

HeatmapWidget.propTypes = {
  widgetId: PropTypes.number.isRequired
};

export default HeatmapWidget;
