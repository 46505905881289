import {
  call,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';
import requestApi from './requestApi';
import * as Actions from '~/store/reducers/stats';

export function* statsFetchRequestSaga({ payload: data }) {
  try {
    const { data: response } = yield call(
      requestApi,
      'post',
      'search/stats',
      data
    );
    yield put(Actions.fetchStatsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchStatsFailure(e.toString()));
  }
}

function* watchFetchStatsRequest() {
  yield takeLatest(Actions.fetchStatsRequest.toString(), statsFetchRequestSaga);
}

export default function* app() {
  yield fork(watchFetchStatsRequest);
}
