/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { app as App } from 'app/backbone/app';

((app) => (
  app.getChannel().reply('when:fetched', (...args) => {
    const adjustedLength = Math.max(args.length, 1);

    const entities = args.slice(0, adjustedLength - 1);

    const callback = args[adjustedLength - 1];
    const xhrs = _.chain(entities).flatten().map((entity) => {
      if (typeof entity.getXhrs === 'function') {
        return entity.getXhrs();
      }
      if (typeof entity._fetch === 'function') {
        return entity._fetch;
      }
      if (typeof entity.then === 'function') {
        return entity;
      }
    }).flatten()
      .value();
    return $.when(...xhrs)
      .done((resp) => callback(resp)).fail((resp, textStatus) => {
        if (textStatus === 'abort') { return callback(resp); }
      });
  })
))(App);
