import { createSelector } from 'reselect';
import get from 'lodash/get';
import some from 'lodash/some';
import * as fromAPIs from '~/store/reducers/apis';
import { getOrganizations } from '~/store/reducers/organizations';
import { getGlobalOrganizationId } from '~/store/selectors/global';
import { getFilter } from './reducer';

export const getApiIds = createSelector(
  [fromAPIs.getAPIIds, fromAPIs.getAPIs, getGlobalOrganizationId],
  (ids, apis, organizationId) => ids.filter((id) => {
    if (organizationId && organizationId !== 'all') {
      return apis[id].organization_id === organizationId;
    }
    return true;
  })
);

export const getApis = createSelector(
  [getApiIds, fromAPIs.getAPIs],
  (ids, apis) => ids.map((id) => apis[id])
);

export const getFilteredList = createSelector(
  getApis,
  getOrganizations,
  getFilter,
  (apis, organizations, { query }) => {
    let results = apis.map((api) => {
      const nodeId = get(api, 'permissions[0].scope.query.node_id', null);
      return {
        ...api,
        organization_name: organizations[api.organization_id]?.name,
        device_scope: !!nodeId,
        nodeId
      };
    });
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((api) => {
        const { id } = api;
        const searchString = [id].join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return results;
  }
);
