/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app'; // eslint-disable-line import/no-cycle
import AppCollection from 'app/backbone/lib/entities/app_collection';
import Addressable from 'app/backbone/lib/concerns/entities/addressable';
import ValueLabelPairs from 'app/backbone/lib/concerns/entities/value_label_pairs';
import AppModel from 'app/backbone/lib/entities/app_model'; // eslint-disable-line import/no-cycle
import { __guard__ } from 'app/utils/custom-fns';
import { api as countriesApi } from 'app/backbone/entities/abstract/countries';

export class OrganizationBase extends Addressable(AppModel) {
  get defaults() { return { billing_same_as_shipping: true }; }

  get mutators() {
    return {
      contact_full_name() {
        return `${this.get('contact_first_name')} ${this.get('contact_last_name')}`;
      },
      current_user_role() {
        const currentUser = App.getChannel().request('get:current:user');
        if (currentUser.isAdmin()) {
          return I18n.t('users.roles.admin');
        } if (currentUser.isSupport()) {
          return I18n.t('users.roles.support');
        } return I18n.t(`users.roles.${currentUser.getRoleName(this)}`);
      },
      location() {
        const organizationCountry = this.get('shipping_address_country');
        let countryName = __guard__(countriesApi.getChannel().request('get:country', organizationCountry), (x) => x.name);
        if (organizationCountry === 'US') { countryName = organizationCountry; }
        return `${this.get('shipping_address_city')}, ${countryName}`;
      },
      search_text() {
        return _([this.get('name'), this.get('contact_first_name'), this.get('contact_last_name'), this.get('contact_email'),
          this.get('contact_phone'),
          this.get('shipping_address_street'),
          this.get('shipping_address_city'),
          this.get('shipping_address_zip_code'),
          this.get('shipping_address_state'),
          this.get('shipping_address_country'),
          this.get('billing_address_street'),
          this.get('billing_address_city'),
          this.get('billing_address_zip_code'),
          this.get('billing_address_state'),
          this.get('billing_address_country')
        ]).compact().join(' ').toLowerCase();
      }
    };
  }

  initialize() {
    this.hasAddress('shipping_address');
    return this.hasAddress('billing_address');
  }

  transientAttrs() {
    return ['slug', 'membered', 'partnered', 'member_count',
      'contact_full_name', 'current_user_role', 'location',
      'node_count', 'created_at', 'managed', 'calibration_profiles', 'contact_full_name', 'location'];
  }

  validation() {
    return _(super.validation(...arguments)).chain().map((validation, attr) => { // eslint-disable-line prefer-rest-params
      if (_(attr).startsWith('billing_address')) {
        return [attr, { requiredIfNotSet: 'billing_same_as_shipping' }];
      }
      return [attr, validation];
    })
      .object()
      .extend({
        shipping_address_state: this.validateState('shipping_address'),
        billing_address_state: this.validateState('billing_address'),
        contact_email: {
          pattern: 'email'
        },
        contact_phone: {
          mobile: true
        } })
      .value();
  }

  presentName() {
    return this.get('name');
  }
}

export class OrganizationsBase extends ValueLabelPairs(AppCollection) {
  comparator(organization) {
    return organization.get('name').toLowerCase();
  }
}
