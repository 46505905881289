import React, {
  useCallback, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form as FinalForm } from 'react-final-form';
import Modal from '~/components/modal';
import ManageCalibrationsContext from '../../../manage_context';
import { identifyByLedRequest } from '~/store/reducers/nodes';
import ModalTable from './modal_table';

const ActionsModal = ({ type, toggleModal }) => {
  const { selectedCalibrations, setSelectedCalibrations, setIsActive } = useContext(ManageCalibrationsContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    toast.success(
      t('thiamis.manage_dialog.success', { action: t(`thiamis.manage_actions.${type}_past`) }),
      {
        toastId: `success_${type}`,
        position: toast.POSITION.TOP_CENTER
      }
    );
    setSelectedCalibrations([]);
    setIsActive(false);
    toggleModal();
  }, [t, type, setSelectedCalibrations, setIsActive, toggleModal]);

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);
  const handleAction = useCallback(() => dispatch((identifyByLedRequest({ ids: selectedCalibrations, onSuccess: handleSuccess }))),
    [dispatch, handleSuccess, selectedCalibrations]);

  return (
    <Modal
      isVisible={!!type}
      handleCancel={handleCancel}
      title="Identify Device"
    >
      <div className="dialog-content-region">
        <FinalForm
          initialValues={{ disabledNodes: [] }}
          onSubmit={handleAction}
          render={({ handleSubmit }) => (
            <div className="form">
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-responsive manage-node-table table-region">
                      <ModalTable type={type} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-actions clearfix">
                <button type="button" className="btn btn-default" onClick={handleCancel}>
                  {t('base.buttons.cancel')}
                </button>
                <button
                  onClick={handleSubmit}
                  className="save-form btn btn-primary"
                  type="submit"
                >
                  {t(`thiamis.manage_actions.${type}`)}
                </button>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default ActionsModal;
