/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'app/config/moment';
import jwtDecode from 'jwt-decode';
import AppModel from 'app/backbone/lib/entities/app_model';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import reduxStore from 'app/react/store';
import { api as apiSockets } from 'app/backbone/lib/clusternet/sockets';
import { api as apiDataPoints } from 'app/backbone/entities/data_points';
import { api as apiBridge } from 'app/backbone/lib/clusternet/bridge';
import { api as auth0Api } from 'app/utils/auth0_handler'; // eslint-disable-line import/no-cycle
import { app as App } from 'app/backbone/app'; // eslint-disable-line import/no-cycle
import { __guard__ } from 'app/utils/custom-fns';
import { fetchDataPointsSuccess } from '~/store/reducers/nodes';
import { updateMeasurements } from '~/store/reducers/measurements';
import * as schema from '~/store/schema';
import { normalizeResp, normalizeDataPointsResp } from '~/store/normalizr';

export class NodeDataStream extends AppModel {
  initialize() {
    return this.on('change', function () {
      this.dataPointsColl.reset();
      return this.set('changed', true, { silent: true });
    });
  }

  defaults() {
    return { changed: true };
  }

  setUpdates(dpointAttrsArr, options) {
    this.getDataPointsColl().set(dpointAttrsArr,
      _(options).extend({ parse: true, remove: false, merge: true }));
    return this.getDataPointsColl().trigger('updates:set', options);
  }

  getDataPointsColl() { return _.abstractMethod(); }

  getRangeQuery() { return _.abstractMethod(); }

  getGrouper() {
    return JSON.stringify(this.getRangeQuery());
  }

  getDeferred() {
    return this.dfrd || (this.dfrd = $.Deferred());
  }
}

export class NodeRangeDataStream extends NodeDataStream {
  defaults() { return _(super.defaults(...arguments)).extend({ type: 'series' }); } // eslint-disable-line prefer-rest-params

  getDataPointsColl() {
    return this.dataPointsColl != null ? this.dataPointsColl : (this.dataPointsColl = apiDataPoints.getChannel().request('get:datapoints:with:series', { stream: this }));
  }

  getRangeQuery() {
    if (!this.periodTo()) {
      return { from: this.periodFrom() };
    } return { from: this.periodFrom(), to: this.periodTo() };
  }

  getLivePeriods() {
    return {
      hour_1: [moment().subtract(1, 'hours'), null],
      hours_3: [moment().subtract(3, 'hours'), null],
      hours_6: [moment().subtract(6, 'hours'), null],
      hours_12: [moment().subtract(12, 'hours'), null],
      day_1: [moment().subtract(1, 'days'), null]
    };
  }

  static getTimePeriods() {
    return {
      live: [moment().subtract(3, 'hours')],
      today: [moment().startOf('day')],
      yesterday: [moment().subtract(1, 'days').hour(0).minute(0), moment().startOf('day')],
      this_week: [moment().startOf('week').hour(0).minute(0), moment()],
      last_week: [moment().weekday(-7).hour(0).minute(0), moment().weekday(0).hour(0).minute(0)],
      last_month: [moment().subtract(1, 'month').startOf('month').hour(0)
        .minute(0), moment().subtract(1, 'month').endOf('month').hour(23)
        .minute(59)]
    };
  }

  periodFrom() {
    const timePeriods = _.extend(this.getLivePeriods(), NodeRangeDataStream.getTimePeriods());
    const from = _.isArray(this.get('time_period'))
      ? this.get('time_period')[0]
      : timePeriods[this.get('time_period')][0].valueOf();
    return moment.utc(from).valueOf();
  }

  periodTo() {
    const timePeriods = _.extend(this.getLivePeriods(), NodeRangeDataStream.getTimePeriods());
    const to = _.isArray(this.get('time_period'))
      ? this.get('time_period')[1]
      : __guard__(timePeriods[this.get('time_period')][1], (x) => x.valueOf());
    if (!to) { return; }
    return moment.utc(to).valueOf();
  }
}

export class NodePointDataStream extends NodeDataStream {
  defaults() { return _(super.defaults(...arguments)).extend({ type: 'point', last_measurements: 1 }); } // eslint-disable-line prefer-rest-params

  getDataPointsColl() {
    return this.dataPointsColl != null ? this.dataPointsColl : (this.dataPointsColl = apiDataPoints.getChannel().request('new:datapoints', { stream: this }));
  }

  getRangeQuery() {
    return { last: this.get('last_measurements') };
  }

  periodTo() { return null; }
}

export class NodeDataStreamsColl extends AppCollection.extend({
  model(attrs) {
    if (attrs.type === 'point') { return new NodePointDataStream(attrs); }
    if (attrs.type === 'series') { return new NodeRangeDataStream(attrs); }
    throw new Error('Unknown Stream Type');
  }
}) {
  save = _.debounce((function () { return this.sync('update'); }), 2000)

  initialize() {
    this.on('reset add remove change', this.onChange, this);

    return apiSockets.getChannel().request('subscribe', 'measurements', (data) => {
      try {
        const response = normalizeResp(normalizeDataPointsResp(data), schema.dataPointsSchema);
        reduxStore.dispatch(updateMeasurements(response));
      } catch (e) {
        App.getChannel().request('report:message', 'Error with updateMeasurements', e);
      }
      this.updateStreams(data, false, { liveUpdates: true });
    });
  }

  onChange() {
    this.save();
  }

  clearStreams(...args) {
    return this.reset(...Array.from(args || []));
  }

  requestChangedMeasurementRanges() {
    const changedStreams = this.where({ changed: true });
    const rangeGroups = _(changedStreams).groupBy((stream) => stream.getGrouper());
    _(rangeGroups).each((streams) => {
      const nodeIds = _(streams).chain().map((s) => s.get('node_id')).flatten()
        .compact()
        .uniq()
        .value();
      const paths = _(streams).chain().map((s) => s.get('paths')).flatten()
        .compact()
        .uniq()
        .value();
      const dataPointIds = _(streams).chain().map((s) => s.get('data_point_id')).flatten()
        .compact()
        .uniq()
        .value();
      const params = streams[0].getRangeQuery();
      if (!_.isEmpty(nodeIds)) {
        params.node_id = nodeIds;
      }
      if (!_.isEmpty(paths)) {
        params.path = paths;
      }
      if (!_.isEmpty(dataPointIds)) {
        params.data_point_id = dataPointIds;
      }
      return apiBridge.getChannel().request('get:instance').getDataPoints(params).then((data) => {
        const response = normalizeResp(normalizeDataPointsResp(cloneDeep(data)), schema.dataPointsSchema);
        reduxStore.dispatch(fetchDataPointsSuccess(response));
        this.updateStreams(data, streams, { initiate: true });
        return streams[0].getDeferred().resolve();
      });
    });
    return _(changedStreams).each((stream) => stream.set('changed', false, { silent: true }));
  }

  getLiveStreams() {
    return this.filter((s) => !s.periodTo());
  }

  updateStreams(dataPointUpdates, applyToStreams, options = {}) {
    const { liveUpdates } = options;
    const dataPointAttrsByNodeId = _(dataPointUpdates).groupBy('node_id');
    return _(dataPointAttrsByNodeId).each((nodeDpointAttrs, nodeId) => {
      const streams = liveUpdates ? this.getLiveStreams() : this.models;
      let nodeIdStreams = _(streams).filter((s) => {
        const streamNodeId = s.get('node_id');
        if (_.isArray(streamNodeId)) {
          return _.contains(streamNodeId, nodeId);
        }
        return streamNodeId === nodeId;
      });
      if (applyToStreams) { nodeIdStreams = _.intersection(nodeIdStreams, applyToStreams); }
      return _(nodeIdStreams).each((stream) => stream.setUpdates(nodeDpointAttrs, options));
    });
  }

  sync(method) {
    if (method !== 'update') { throw new Error(`Unsupported method ${method} for data streams.`); }
    const nodeIds = _(this.getLiveStreams()).chain().map((s) => s.get('node_id')).flatten()
      .compact()
      .uniq()
      .value();
    apiSockets.getChannel().request('update', { node_ids: nodeIds });
    return this.requestChangedMeasurementRanges();
  }
}

async function connectToSocket() {
  const token = auth0Api.getChannel().request('get:instance').getIdToken();
  try {
    const { exp } = jwtDecode(token);
    const isTokenExpired = exp < Math.floor(Date.now() / 1000);
    if (isTokenExpired) {
      await auth0Api.getChannel().request('get:instance').requestToken();
    }
    apiSockets.getChannel().request('connect');
  } catch (e) {
    App.getChannel().request('report:message', 'Error with getting new token for the stream', e);
  }
}

export const API = MnObject.extend({
  channelName: 'entities:data_stream',
  radioRequests: {
    'get:thiamis:data:stream': 'getNodeDataStream',
    'get:thiamis:data:series:stream': 'getNodeDataStreamWithSeries',
    'clear:node:data:stream': 'clearNodeDataStream',
    'get:data:streams': 'getDataStreamsColl'
  },

  initialize() {
    window.addEventListener('online', connectToSocket);
  },

  onBeforeDestroy() {
    window.removeEventListener('online', connectToSocket);
  },

  getDataStreamsColl() {
    if (this.subscriber) { return this.subscriber; }
    this.subscriber = new NodeDataStreamsColl();
    this.subscriber.save();
    return this.subscriber;
  },

  getNodeDataStream(options) {
    return this.getDataStreamsColl().add(_(options).extend({ type: 'point' }));
  },

  getNodeDataStreamWithSeries(options) {
    return this.getDataStreamsColl().add(_(options).extend({ type: 'series' }));
  },

  clearNodeDataStream() {
    apiSockets.getChannel().request('update', []);
    this.getDataStreamsColl().clearStreams();
    delete this.subscriber;
  }
});

export const api = new API();