import React from 'react';
import { connect } from 'react-redux';
import I18n from 'app/config/i18n';
import { getCurrentUserIsAdmin } from '~/store/selectors/current_user';
import { getUserByIdPicture, getUserByIdIsAdmin, getUserByIdInitials } from '~/store/selectors/users';

const AvatarFormatter = (props) => {
  const {
    profilePicture,
    initials,
    isAdmin,
    isCurrentUserAdmin
  } = props;
  const showAdminIcon = isAdmin && isCurrentUserAdmin;

  if (!profilePicture && !initials) {
    return null;
  }

  return (
    <span className="user-picture-wrap">
      {
        showAdminIcon && (
          <i className="fa fa-sm fa-shield" title={I18n.t('users.admin_user')} />
        )
      }
      <span className="user-picture img-rounded">
        {
          profilePicture
            ? <img className="img-responsive" alt="" src={profilePicture} />
            : <h2 className="avatar">{initials}</h2>
        }
      </span>
    </span>
  );
};

const mapStateToProps = (state, { row = {} }) => {
  const { id: userId } = row;
  return {
    profilePicture: getUserByIdPicture(state, userId),
    initials: getUserByIdInitials(state, userId),
    isAdmin: getUserByIdIsAdmin(state, userId),
    isCurrentUserAdmin: getCurrentUserIsAdmin(state)
  };
};

export default connect(mapStateToProps)(AvatarFormatter);
