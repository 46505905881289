/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { api as apiBridge } from 'app/backbone/lib/clusternet/bridge';

export default (superclass) => class extends superclass {
  initialize(...args) {
    super.initialize(...args);
    return this.clusternet = apiBridge.getChannel().request('get:instance');
  }
};
