import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useField } from 'react-final-form';
import { app as App } from 'app/backbone/app';
import Input from '~/components/form/input';
import Select from '~/components/form/select';
import { getCurrentUserId } from '~/store/selectors/global';
import { getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { getUserOrganizationRolesForAPI } from '~/store/selectors/users';
import useValidation from '~/hooks/useValidation';

const ClaimDeviceForm = React.memo(({ handleSubmit, invalid, submitting }) => {
  const { t } = useTranslation();
  const { input: { value: serials } } = useField('serials');

  const { validateRequired } = useValidation();
  const isOrganizationsFetched = useSelector((state) => getOrganizationsIsFetched(state));
  const organizations = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return getUserOrganizationRolesForAPI(state, currentUserId);
  });

  const mappedOrganizations = useMemo(() => organizations.map((item) => ({
    value: item.organizationId,
    label: item.organizationName,
    ...item
  })), [organizations]);
  const noOrganizations = useMemo(() => !organizations.length, [organizations]);

  const handleRedirect = useCallback(() => {
    App.navigate(`/organizations/new?serials=${serials}`);
  }, [serials]);

  return (
    <div className="form-group center-form">
      <Field
        name="serials"
        validate={validateRequired}
      >
        {
        ({ input, meta }) => (
          <Input
            {...input}
            label={t('claim.serials')}
            disabled={submitting}
            type="textarea"
            helpText={(
              <span className="certificate-example">
                {t('claim.serials_helper')}
              </span>
            )}
            errorText={meta.touched && meta.error}
          />
        )
        }
      </Field>
      <Field
        name="organization"
        formatOnBlur
        validate={validateRequired}
      >
        {
        ({ input, meta }) => (
          <div className="claim-organizations">
            <Select
              {...input}
              label={t('claim.organization')}
              options={mappedOrganizations}
              isDisabled={submitting}
              isLoading={!isOrganizationsFetched}
              errorText={meta.touched && meta.error}
            />
            {
              noOrganizations && (
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleRedirect}
                  style={{
                    marginBottom: (meta.touched && meta.error) ? 37 : 15
                  }}
                >
                  {t('organizations.create_new')}
                </button>
              )
            }
          </div>
        )
      }
      </Field>
      <button
        type="submit"
        className="btn btn-primary save-form"
        onClick={handleSubmit}
        disabled={invalid || submitting}
      >
        {
          submitting
            ? <span>{t('claim.claiming_devices')}</span>
            : (
              <>
                <i className="fa fa-md fa-fw fa-plus" />
                <span>
                  {t('claim.claim_devices')}
                </span>
              </>
            )
        }
      </button>
    </div>
  );
});

export default ClaimDeviceForm;
