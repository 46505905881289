import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '~/components/spinner';
import { getOrganizationsIsFetched } from '~/store/reducers/organizations';
import {
  clearFilters, fetchFilesRequest, getIsFetched, filterListByOrganizationId
} from './reducer';
import GridContainer from './grid_container';
import FiltersFormTop from './filters_form_top';
import FiltersSidebar from './filters_sidebar';

const FilesScreen = React.memo((props) => {
  const { slug } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchFilesRequest());
    if (slug && slug !== 'all') {
      dispatch(filterListByOrganizationId(slug));
    }
  }, [dispatch, slug]);

  const { isFetched } = useSelector(
    (state) => ({
      isFetched: getIsFetched(state) && getOrganizationsIsFetched(state)
    })
  );

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <div className="content-region fill-height content filtered-content">
      <div className="files-container">
        <FiltersFormTop />
        <GridContainer />
        <FiltersSidebar />
      </div>
    </div>
  );
});

FilesScreen.propTypes = {
  slug: PropTypes.string.isRequired
};

export default FilesScreen;
