import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field as FinalField } from 'react-final-form';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import Select from '~/components/form/select';
import { getNodeById } from '~/store/reducers/nodes';
import ManageDevicesContext from '../../../manage_context';
import { getOrganizationsValueLabelPairsForShareLease } from '../../../selectors';

const ModalOrganizationDropdown = ({ type }) => {
  const { selectedDevices } = useContext(ManageDevicesContext);
  const { t } = useTranslation();

  const organizations = useSelector((state) => {
    const nodes = selectedDevices.map((deviceId) => getNodeById(state, deviceId));
    const shared = flatten(nodes.map((node) => node?.shared_to));
    const organizationsAll = getOrganizationsValueLabelPairsForShareLease(state);
    let orgs = [];
    switch (type) {
      case 'share':
      case 'lease':
        orgs = organizationsAll;
        break;
      case 'remove_share':
        orgs = organizationsAll.filter((item) => includes(shared, item.value));
        break;
      default:
        break;
    }
    return orgs;
  });

  return (
    <FinalField name="selectedOrganization">
      {({ input }) => (
        <div className="row">
          <div className="col-sm-6">
            <Select
              label={t('models.organization')}
              options={organizations}
              id="selectOrganizations"
              helpText={!organizations.length && t('thiamis.manage_dialog.no_partnerships')}
              {...input}
            />
          </div>
        </div>
      )}
    </FinalField>
  );
};

export default ModalOrganizationDropdown;
