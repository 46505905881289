import React from 'react';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAlertSubscribersEmailIds, getAlertSubscribersSmsIds } from '~/store/selectors/alerts';
import { getUserByIdFullName } from '~/store/selectors/users';

const NodesBlock = (props) => {
  const {
    emailUsers, smsUsers
  } = props;
  const { t } = useTranslation();
  return (
    <div className="form-box">
      <h3>{t('attributes.alert.subscriptions')}</h3>
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="subscriptions-region">
          <b style={{ paddingLeft: '15px' }}>Email</b>
          <ul className="keywords col-sm-12">
            {
              isEmpty(emailUsers)
                ? (<li>{t('alerts.empty_subscribers')}</li>)
                : emailUsers.map((userName) => <li key={kebabCase(userName)}>{userName}</li>)
            }
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="subscriptions-region">
          <b style={{ paddingLeft: '15px' }}>SMS</b>
          <ul className="keywords col-sm-12">
            {
              isEmpty(smsUsers)
                ? (<li>{t('alerts.empty_subscribers')}</li>)
                : smsUsers.map((userName) => <li key={kebabCase(userName)}>{userName}</li>)
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { id }) => {
  const emailUserIds = compact(getAlertSubscribersEmailIds(state, id));
  const smsUserIds = compact(getAlertSubscribersSmsIds(state, id));
  return {
    emailUsers: compact(emailUserIds.map((userId) => getUserByIdFullName(state, userId))).sort(),
    smsUsers: compact(smsUserIds.map((userId) => getUserByIdFullName(state, userId))).sort()
  };
};

export default connect(mapStateToProps)(NodesBlock);
