import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { shallowCompare } from 'app/backbone/lib/react/utils';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';

class ListTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isOpened !== nextProps.isOpened) { return false; }
    return shallowCompare(this, nextProps, nextState);
  }

  getTbodyClassNames(...args) {
    return classNames(this.props.tbodyClassName, ...args);
  }

  getExpandedTbody() {
    const expandedComponent = React.createElement(this.props.expandable.component,
      _.extend({ model: this.props.getModel(), key: `tr3-${this.props.getModel().id}` }, this.props.expandable.props, _.pick(this.state, 'isExpanded'), _.pick(this.state, 'isRequesting')));
    return (
      <div className={this.getTbodyClassNames('expanded', { loading: this.props.isRequesting }, 'tbody')}>
        { [
          React.createElement('div', { className: 'tr gray-back', key: `tr1-${this.props.getModel().id}`, onClick: this.handleChooseClick }, this.createColumns()),
          expandedComponent
        ] }
      </div>
    );
  }

  handleChooseClick = (e) => {
    e.stopPropagation();
    if (this.props.isRequesting || !this.props.isOpened) { return; }
    if ($(e.target).is('td,input[type="checkbox"]')) {
      if (this.props.choosableAll) {
        return this.props.getModel().toggleChoose();
      }
      this.props.getModel().toggleChoose();
      return this.props.getModel().collection.trigger('collection:chose:one');
    }
  };

  handleExpandClick = () => this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));

  handleExpandingClick = (event) => {
    if (_.isEmpty(this.props.expandable)) { return; }
    if (event && $(event.target).is('input')) { return; }
    if (this.props.expandable.isExandable && !this.props.expandable.isExandable(this.props.getModel())) { return; }
    if (typeof this.props.expandable.beforeRenderPromise === 'function') {
      this.props.expandable.beforeRenderPromise({ model: this.props.getModel() });
    }
    return this.handleExpandClick();
  };

  createColumn = (column, index) => (
    <div role="button" tabIndex={0} onClick={this.handleExpandingClick} key={index} className={classNames({ [`${_.dasherizeField(column.name)}-col`]: column.name }, column.className, 'td')}>
      { _.isEmpty(column.component)
        ? this.props.getModel().get(column.name)
        : this.createColumnComponent(column) }
    </div>
  );

  createColumns() {
    return this.props.columns.map(this.createColumn);
  }

  isChosen() {
    return this.props.isOpened && this.props.getModel().get('chosen');
  }

  createColumnComponent(column) {
    const that = this;
    const props = _.chain([column.name])
      .flatten()
      .map((columnName) => [columnName, (that.props.model ? that.props.model[columnName] : null) || that.props.getModel().get(columnName)])
      .object()
      .value();

    const model = this.props.getModel();
    props.model = model;
    if (column.choosable) {
      props.handleChooseChange = this.handleChooseClick;
      props.id = model.id;
      props.isChosen = this.props.isChosen;
    }

    if (this.props.expandable) {
      props.onShowMoreInfoClick = this.handleExpandClick;
      props.beforeRenderPromise = this.props.expandable.beforeRenderPromise;
    }

    const componentProps = _.isFunction(column.component.props)
      ? column.component.props(model)
      : column.component.props;

    return React.createElement(column.component.name, _.extend(props, componentProps));
  }

  render() {
    if (this.state.isExpanded) {
      return this.getExpandedTbody();
    }
    return (
      <div className={this.getTbodyClassNames('tbody')}>
        <div
          role="button"
          tabIndex={0}
          onClick={this.handleChooseClick}
          className={classNames('tr', { chosen: this.isChosen(), expandable: !_.isEmpty(this.props.expandable) })}
          key={`tr2-${this.props.getModel().id}`}
        >
          { this.createColumns() }
        </div>
      </div>
    );
  }
}

ListTableRow.defaultProps = {
  isOpened: false,
  isChosen: false,
  isRequesting: false,
  tbodyClassName: ''
};

ListTableRow.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  model: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  expandable: PropTypes.object.isRequired,
  isOpened: PropTypes.bool,
  choosableAll: PropTypes.bool.isRequired,
  tbodyClassName: PropTypes.string,
  getModel: PropTypes.func.isRequired,
  isChosen: PropTypes.bool,
  isRequesting: PropTypes.bool
};

export default BackboneReactComponent(ListTableRow);
