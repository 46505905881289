import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Form from './form';
import { saveProfileRequest } from '~/store/reducers/profile';

const AccountForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.profile'),
      { toastId: 'account_info_updated' }
    );
  }, [t]);

  const handleSubmit = useCallback((data) => {
    dispatch(saveProfileRequest({ data, onSuccess: handleSuccess }));
  }, [dispatch, handleSuccess]);

  return (
    <Form
      onSubmit={handleSubmit}
    />
  );
};

export default AccountForm;
