import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';

class DownloadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: props.actionUrl
    };
    this.model = props.getModel();
    this.model.on('download:form', this.handleDownloadForm, this);
  }

  componentWillUnmount() {
    return this.model.off('download:form', this.handleDownloadForm, this);
  }

  attachNode(node) {
    this._form = ReactDOM.findDOMNode(node);
  }

  handleDownloadForm(options = {}) {
    if (options.urlParams) { this.setState({ action: `${this.props.actionUrl}/${options.urlParams}` }); }
    return _.defer(() => this._form.submit());
  }

  createInputArray(_value, index) {
    // eslint-disable-next-line no-restricted-globals
    return this.createInput(_value, `${name}[]`, index);
  }

  createInput(value, name, index) {
    if (_.isArray(value)) {
      return _.map(value, (_value, _index) => this.createInput(_value, `${name}[]`, _index));
    }
    return <input type="hidden" value={value} name={name} key={_.isNumber(index) ? `${name}_${index}` : name} />;
  }

  render() {
    return (
      <form
        action={this.state.action}
        method={this.props.method}
        ref={this.attachNode.bind(this)}
      >
        <fieldset>
          { _(this.props.model).chain().map(this.createInput.bind(this)).flatten()
            .value() }
        </fieldset>
      </form>
    );
  }
}

DownloadForm.defaultProps = {
  method: 'POST'
};

DownloadForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  method: PropTypes.string,
  model: PropTypes.object.isRequired,
  getModel: PropTypes.func.isRequired
};

export default BackboneReactComponent(DownloadForm);
