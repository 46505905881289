/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';

const ImgValid = (props) => (
  <object className="img-responsive" data={props.src} type="image/png" width={props.width} height={props.height}>
    {props.children}
  </object>
);

ImgValid.defaultProps = {
  width: '',
  height: '',
  children: []
};

ImgValid.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.any
};

export default ImgValid;
