import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WithPopoverHoc from 'app/backbone/lib/react/hocs/WithPopoverHoc';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';

const DeviceInfoIcon = (props) => {
  const defaults = { type: 'warning' };
  const properties = _.defaults(props.popover, defaults);

  return (
    <span ref={props.popoverRef}>
      <LinkButton className={classNames('device-info', properties.type)} icon={`fa fa-md fa-${properties.type}`} />
    </span>
  );
};

DeviceInfoIcon.propTypes = {
  popover: PropTypes.any.isRequired,
  popoverRef: PropTypes.any.isRequired
};

export default WithPopoverHoc(DeviceInfoIcon);
