import toastr from 'toastr';
import { MnObject } from 'backbone.marionette';

const API = MnObject.extend({
  channelName: 'utils:flash_messages',

  radioEvents: {
    'flash:message': 'showMessage',
    'flash:message:clear': 'clearMessage'
  },

  showMessage(type, text, options = {}) {
    toastr.options = _.defaults(options, {
      positionClass: 'toast-top-center',
      preventDuplicates: true
    });
    return typeof toastr[type] === 'function' ? toastr[type](text, null, options) : undefined;
  },

  clearMessage() {
    toastr.clear();
  }
});

// eslint-disable-next-line import/prefer-default-export
export const api = new API();
