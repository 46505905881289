import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Switch from './switch';

const SwitchInput = ({ errorText, label, ...restProps }) => (
  <FormGroup validationState={errorText ? 'error' : undefined}>
    {
        label && (
          <ControlLabel>
            {label}
          </ControlLabel>
        )
      }
    <Switch {...restProps} />
  </FormGroup>
);

export default SwitchInput;
