export const AIRTHIX_PARAMETER_PATHS = {
  AQ: '132:19',
  PM: '132:18',
  PM1: '132:4',
  PM10: '132:6',
  PM25: '132:5',
  CO2: '131:10',
  VOC_ETOH: '140:11',
  VOC_ISOBUTYLENE: '140:12',
  CH2O: '132:13',
  HUMIDITY: '132:15',
  TEMPERATURE_F: '132:24',
  TEMPERATURE_C: '132:14'
};

export const PATHS = {
  BATTERY_VOLTAGE: ':0:1',
  BATTERY_CURRENT: ':0:2',
  GSM_NETWORK: ':0:3',
  GSM_SIGNAL: ':0:4',
  IMEI: ':0:5',
  IMSI: ':0:7',
  ICCID: ':0:6',
  LOCATION: ':0:8',
  WIFI_SIGNAL: ':0:10',
  WIFI_SECURITY: ':0:11',
  WIFI_NETWORK: ':0:12',
  WIFI_BSSID: ':0:13',
  FIRMWARE: ':0:15',
  DEBUG: ':0:16',
  TIMEZONE: ':0:18',
  LOCATION_DESCRIPTION: ':0:17',
  TEMPERATURE: ':0:19',
  MAC_ADDRESS: ':0:21',
  ONLINE: ':0:22',
  GPS_ERROR: ':0:26',
  WIFI_NETWORKS: ':0:30',
  GPS_SOURCE: ':0:31',
  VOC_CALIBRATION_1_PPM: '140:21',
  VOC_CALIBRATION_1_VALUE: '140:22',
  VOC_CALIBRATION_2_PPM: '140:23',
  VOC_CALIBRATION_2_VALUE: '140:24',
  VOC_CALIBRATION_3_PPM: '140:25',
  VOC_CALIBRATION_3_VALUE: '140:26',
  AQ: '132:19',
  PM: '132:18',
  PM1: '132:4',
  PM10: '132:6',
  PM25: '132:5',
  CO2: '131:10',
  VOC_ETOH: '140:11',
  VOC_ISOBUTYLENE: '140:12',
  CH2O: '132:13',
  PRESSURE: '0:20',
  HUMIDITY: '132:15',
  TEMPERATURE_F: '132:24',
  TEMPERATURE_C: '132:14'
};

export const SENSOR_STATUSES = {
  REPORTING: 'OK',
  NOT_REPORTING: ''
};

export const AQI_LABELS = {
  GOOD: 'good',
  POOR: 'poor',
  VERYPOOR: 'veryPoor',
  SATISFACTORY: 'satisfactory',
  MODERATE: 'moderate',
  EXCELLENT: 'excellent',
  OFFLINE: 'offline'
};

export const ROLES = {
  USER: 'user',
  OWNER: 'owner',
  MANAGER: 'manager',
  ACCOUNTANT: 'accountant'
};

export const NOTICE_METHODS = {
  SMS: 'sms',
  EMAIL: 'email',
  EMAIL_SMS: 'email_sms'
};

export const APP_NAMES = {
  environet: 'environet',
  paythinx: 'paythinx',
  airthinx: 'airthinx',
  heraeus: 'heraeus',
  fluidmatix: 'fluidmatix'
};
