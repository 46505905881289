import React, {
  useEffect, useState, useCallback, useContext
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  RowDetailState,
  DataTypeProvider,
  SelectionState,
  IntegratedSelection,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableSelection,
  VirtualTable, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import {
  getAlertById, isAlertMonitorable, isAlertModifiable, isAlertDeletable
} from '~/store/selectors/alerts';
import { getFilteredList } from '../selectors';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from '~/components/react_grid/expand_button';
import ListTable from '~/components/list_table';
import { StatusFormatter } from './status_formatter';
import { NameFormatter } from './name_formatter';
import { LastTriggeredFormatter } from './last_triggered_formatter';
import GridDetailContainer from './detail_row';
import withTooltip from '~/hocs/withTooltip';
import ManageAlertsContext from '../manage_context';

const LastTriggeredProvider = (props) => (
  <DataTypeProvider
    formatterComponent={LastTriggeredFormatter}
    {...props}
  />
);

const StatusTypeProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={withTooltip(StatusFormatter)}
    className="status-cell"
  />
);

const NameProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={NameFormatter}
    className="name-cell"
  />
);

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'organization_name', title: 'Owner' },
  { name: 'description', title: 'Message' },
  { name: 'last_triggered', title: 'Last Triggered' },
  { name: 'status_type', title: 'Status' }
];

function compareDates(a, b) {
  return (b != null) - (a != null) || b - a;
}

const getRowId = ({ _id }) => _id;
const last_triggeredColumns = ['last_triggered'];
const statusColumns = ['status_type'];
const nameColumns = ['name'];

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = ({ slug }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'last_triggered', direction: 'asc' }]);
  const {
    isActive: selectionActive,
    selectedAlerts: selection,
    setSelectedAlerts: setSelection
  } = useContext(ManageAlertsContext);

  useEffect(() => {
    setSelection([]);
  }, [setSelection, slug]);

  const Row = React.memo((props) => {
    const { row: { _id: nodeId } } = props;
    const isExpanded = includes(expandedRowIds, nodeId);
    const {
      isCreatable, isMonitorable, isModifiable, isDeletable
    } = useSelector(
      (state) => {
        const node = getAlertById(state, nodeId);
        const actions = node?.actions || [];
        return {
          isMonitorable: isAlertMonitorable(actions),
          isModifiable: isAlertModifiable(actions),
          isDeletable: isAlertDeletable(actions)
        };
      }
    );
    const isRowExpandable = !isCreatable && (isModifiable || isMonitorable || isDeletable);
    const handleExpandRowClick = useCallback(
      () => {
        if (!isRowExpandable) return;
        if (includes(expandedRowIds, nodeId)) {
          setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== nodeId));
        } else {
          setExpandedRowIds([...expandedRowIds, ...[nodeId]]);
        }
      },
      [isRowExpandable, nodeId]
    );
    return (
      <VirtualTable.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded, disabled: !isRowExpandable })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  const { rows } = useSelector(
    (state) => ({
      rows: getFilteredList(state)
    })
  );

  return (
    <div className="list-table-container react-list-container">
      <ListTable>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting
            columnExtensions={[
              { columnName: 'last_triggered', compare: compareDates }
            ]}
          />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <LastTriggeredProvider
            for={last_triggeredColumns}
          />
          <NameProvider
            for={nameColumns}
          />
          <StatusTypeProvider
            for={statusColumns}
          />
          <VirtualTable
            rowComponent={Row}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          { selectionActive && <TableSelection showSelectAll /> }
          <TableRowDetail
            contentComponent={GridDetailContainer}
            toggleCellComponent={ExpandButton}
          />
          <DetailRowColumnOrder />
          <TableHeaderRow align="center" showSortingControls />
        </Grid>
      </ListTable>
    </div>
  );
};

export default GridContainer;
