import React, {
  useState, useRef, useEffect, useCallback
} from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Calendar from 'rc-calendar';
import TimePicker from 'rc-time-picker/lib/Panel';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import { useTranslation } from 'react-i18next';
import locale from 'rc-calendar/lib/locale/en_GB';
import moment from 'moment';
import { Toggle, CalendarContainer } from './styles';

const DatePicker = ({
  label, value, onChange, onBlur = null, minDate = null, maxDate = null, hasError = false
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const base = useRef();
  const toggle = useCallback(() => setShow((s) => !s), [setShow]);
  useEffect(() => {
    const clickProcessor = (event) => {
      // HTML DOM contains() Method
      const isInBase = base.current?.contains(event.target);
      if (!isInBase && show) {
        setShow(false);
      }
    };
    document.addEventListener('click', clickProcessor);
    return () => document.removeEventListener('click', clickProcessor);
  });

  const handleChange = useCallback((event) => {
    onChange(event);
  }, [onChange]);

  const handleClose = useCallback(() => {
    setShow(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur, setShow]);

  const handleClear = useCallback((event) => {
    event.stopPropagation();
    onChange(null);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur, onChange]);

  const handleDisabledDate = (current) => {
    let isBeforeMin;
    let isAfterMax;
    if (minDate) {
      isBeforeMin = moment(current).isBefore(minDate);
    }
    if (maxDate) {
      isAfterMax = moment(current).isAfter(maxDate);
    }
    return isBeforeMin || isAfterMax;
  };

  const handleSource = useCallback((event, additional) => {
    if (additional?.source === 'todayButton') {
      handleClose();
    }
  }, [handleClose]);

  return (
    <FormGroup
      validationState={hasError ? 'error' : undefined}
      className="calendar-input"
    >
      { label && (
        <ControlLabel onClick={toggle}>
          {label}
        </ControlLabel>
      )}
      <Toggle
        onClick={toggle}
        onClear={handleClear}
        label={value && moment(value).format('MM/DD/YYYY HH:mm')}
        placeholder={t('base.labels.select_date')}
        hasError={hasError}
      />
      {show && (
        <CalendarContainer ref={base}>
          <Calendar
            format="MM/DD/YYYY HH:mm"
            locale={locale}
            value={value}
            onChange={handleChange}
            onOk={handleClose}
            disabledDate={handleDisabledDate}
            onClear={handleClear}
            onSelect={handleSource}
            showDateInput={false}
            timePicker={<TimePicker showSecond={false} minuteStep={5} />}
          />
        </CalendarContainer>
      )}
    </FormGroup>
  );
};
export default DatePicker;
