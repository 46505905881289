import React, { useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ManageCalibrationsContext from '../../manage_context';
import ActionsDropdown from './actions_dropdown';

const ManageCalibrationsActions = () => {
  const {
    isActive, setIsActive, setSelectedCalibrations
  } = useContext(ManageCalibrationsContext);
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggleActive = useCallback(() => {
    setIsActive((active) => !active);
    setSelectedCalibrations([]);
  }, [setIsActive, setSelectedCalibrations]);
  const handleToggleDropdown = useCallback(() => setDropdownOpen((open) => !open), [setDropdownOpen]);

  return (
    <div className={classNames('btn-group', { open: dropdownOpen, active: isActive })}>
      <button
        type="button"
        className={classNames('btn', 'btn-default', { active: isActive })}
        onClick={handleToggleActive}
        title={t('thiamis.tooltips.manage_mode')}
      >
        <i className="fa fa-fw fa-md fa-wrench" />
        <span>{t('base.buttons.manage')}</span>
      </button>
      {
        isActive && (
          <ActionsDropdown handleToggleDropdown={handleToggleDropdown} />
        )
      }
    </div>
  );
};

export default ManageCalibrationsActions;
