/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const modalActionRequest = createAction('@@devices/MODAL_ACTION_REQUEST');
export const modalActionFailure = createAction('@@devices/MODAL_ACTION_FAILURE');

export const filterListByQuery = createAction('@@devicesList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@devicesList/CLEAR_FILTERS');
export const filterListByTag = createAction('@@devicesList/FILTER_BY_TAG');
export const expandList = createAction('@@devicesList/EXPAND_ROW');

export const exportTableDataRequest = createAction('@@devicesList/EXPORT_TABLE_DATA_REQUEST');
export const exportTableDataSuccess = createAction('@@devicesList/EXPORT_TABLE_DATA_SUCCESS');
export const exportTableDataFailure = createAction('@@devicesList/EXPORT_TABLE_DATA_FAILURE');

const initialFilter = { query: null, tags: [] };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  },
  [filterListByTag](state, { payload }) {
    state.tags = payload;
  }
});

const isExportingData = createReducer(false, {
  [exportTableDataRequest]() {
    return true;
  },
  [exportTableDataSuccess]() {
    return false;
  },
  [exportTableDataFailure]() {
    return false;
  }
});

const devicesList = combineReducers({
  filter,
  isExportingData
});

export const getFilter = (state) => state.devicesList.filter;
export const getIsExportingData = (state) => state.devicesList.isExportingData;

export default devicesList;
