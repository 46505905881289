import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCertificatesRequest } from '~/store/reducers/certificates';
import { fetchNodesRequest } from '~/store/reducers/nodes';
import { getCertificatesIsFetched } from '~/store/selectors/certificates';
import { fetchTemplatesRequest } from '~/store/reducers/templates';
import { getBlueCertificateTemplate } from '~/store/selectors/templates';
import Spinner from '~/components/spinner';
import GridContainer from './grid_container';
import FiltersFormTop from './filters_form_top';

const CertificatesScreen = React.memo((props) => {
  const { slug } = props;
  const dispatch = useDispatch();
  const template = useSelector(getBlueCertificateTemplate);

  useEffect(() => {
    dispatch(fetchCertificatesRequest());
    dispatch(fetchTemplatesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (template) {
      const data = {
        organization_id: template.organization
      };
      dispatch(fetchNodesRequest({ data }));
    }
  }, [template, dispatch]);

  const { isFetched } = useSelector(
    (state) => ({
      isFetched: getCertificatesIsFetched(state)
    })
  );

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <div className="content-region fill-height content">
      <div className="nodes-container thiamises-container">
        <FiltersFormTop />
        <GridContainer slug={slug} />
      </div>
    </div>
  );
});

CertificatesScreen.propTypes = {
  slug: PropTypes.string.isRequired
};

export default CertificatesScreen;
