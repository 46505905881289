import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import moment from 'moment';
import map from 'lodash/map';
import { getAuthToken } from '~/services/auth/selectors';
import Modal from '~/components/modal';
import DownloadForm from '~/components/download_form';
import ModalForm from './modal_form';

const DownloadModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();
  const [downloadModel, setDownloadModel] = useState(null);

  const handleClose = useCallback(() => {
    setDownloadModel(null);
    toggleModal();
  }, [toggleModal]);

  const handleDownload = useCallback(async (values) => {
    let dataPoints = [];
    let filename = '';
    values.devices.slice(0, 5).forEach((device, index) => {
      if (index && index) {
        filename = `${filename}-${device.serial}`;
      } else {
        filename = device.serial;
      }
    });
    values.parameters.forEach((param) => {
      dataPoints = [
        ...dataPoints,
        ...map(param.dataPoints, '_id')
      ];
    });
    const token = await getAuthToken();

    const from = moment(values.from).format('x');
    const to = moment(values.to).format('x');
    filename = `${filename}_${from}_${to}.csv`;
    filename = filename.replace(/\s/g, '');

    const model = {
      data_point_id: dataPoints,
      timezone: values.timezone,
      from,
      filename,
      token
    };

    if (values.to) {
      model.to = to;
    }

    setDownloadModel(model);
  }, []);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={handleClose}
      title={t('thiamis.download_csv.download')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleDownload}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit }) => (
            <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
          )}
        </FinalForm>
      </div>
      {
        !!downloadModel && (
          <DownloadForm
            model={downloadModel}
            method="POST"
            actionUrl="search/export"
            callback={handleClose}
          />
        )
      }
    </Modal>
  );
};

export default DownloadModal;
