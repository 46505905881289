import React from 'react';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { useTranslation } from 'react-i18next';
import { RoleFormatter } from './role_formatter';
import { NameFormatter } from './name_formatter';
import { LastLoginFormatter } from './last_login_formatter';
import { ContactFormatter } from './contact_formatter';
import AvatarFormatter from './avatar_formatter';

export const LastLoginProvider = (props) => (
  <DataTypeProvider
    formatterComponent={LastLoginFormatter}
    {...props}
  />
);

export const NameProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={NameFormatter}
  />
);

export const RoleProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={RoleFormatter}
  />
);

export const ContactProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={ContactFormatter}
  />
);

export const AvatarProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={AvatarFormatter}
    className="avatar"
  />
);

export const CreatedAtFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    value && moment(value).isValid()
      ? <TimeAgo date={value} />
      : t('alerts.triggered_never')
  );
};

export const CreatedAtProvider = (props) => (
  <DataTypeProvider
    formatterComponent={CreatedAtFormatter}
    {...props}
  />
);
