/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import I18n from 'app/config/i18n';
import { api as thiamisApi } from 'app/backbone/apps/thiamises/thiamises_app';
import {
  getNodeById, isNodeMonitorable, isNodeModifiable, isNodeCreatable
} from '~/store/selectors/nodes';

const ExpandButton = (props) => {
  const { row: { _id: nodeId } } = props;
  const {
    isCreatable, isMonitorable, isModifiable
  } = useSelector(
    (state) => {
      const node = getNodeById(state, nodeId);
      const actions = node?.actions || [];
      return {
        isCreatable: isNodeCreatable(actions),
        isMonitorable: isNodeMonitorable(actions),
        isModifiable: isNodeModifiable(actions)
      };
    }
  );
  const handleCreateConfigurationClick = useCallback(
    () => thiamisApi.getChannel().request('app:devices:create', nodeId),
    [nodeId]
  );
  return (
    <td colSpan={1} className="expand-button">
      {
        isCreatable
          ? (
            <button
              title={I18n.t('thiamis.create_configuration')}
              className="info"
              type="button"
              tabIndex={0}
              onClick={handleCreateConfigurationClick}
            >
              <i className="fa fa-md fa-fw fa-magic" />
            </button>
          )
          : (isModifiable || isMonitorable)
            ? (
              <button className="info" type="button">
                <i className="fa fa-md fa-fw fa-info" />
              </button>
            )
            : (
              <span className="info empty" />
            )
      }
    </td>
  );
};

export default ExpandButton;
