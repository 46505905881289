import compact from 'lodash/compact';
import { createSelector } from 'reselect';
import I18n from 'app/config/i18n';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import * as fromNodes from '~/store/reducers/nodes';
import * as fromDevices from '~/store/reducers/devices';
import * as fromMeasurements from '~/store/selectors/measurements';
import * as fromDataPoints from '~/store/selectors/data_points';
import { SENSOR_STATUSES } from '~/utils/constants';

export const getDeviceDisplayName = createSelector(
  [fromDevices.getDeviceById, (state, deviceId, model) => model],
  (device, model) => model || device?.name
);

export const getDeviceIsEnabled = createSelector(
  [fromDevices.getDeviceById],
  (device) => device?.enabled
);

export const hasDeviceError = (status, errors) => (!isEmpty(status) && (status !== SENSOR_STATUSES.REPORTING)) || !isEmpty(errors);

export const getDeviceStatusType = (isEnabled, status, hasError, isReporting) => {
  if (hasError) { return 'error'; }
  if (isEnabled === false) { return 'disabled'; }
  if (!status) {
    if (isReporting) { return 'online'; }
    return 'offline';
  }
  return status === SENSOR_STATUSES.REPORTING && isReporting ? 'online' : 'offline';
};

export const getDeviceStatusText = (statusType, status, hasError, errors) => {
  if (hasError) {
    if (errors.length) { return errors; } return status;
  }
  return I18n.t(`devices.statuses.${statusType}`, { defaultValue: I18n.t('devices.statuses.empty') });
};

export const getDeviceErrors = createSelector(
  [fromMeasurements.getLastMeasurementsValuesById, fromDataPoints.getDataPointsByIds, (state, ids) => ids],
  (measurements, dataPoints, ids) => compact(ids.filter((id) => measurements[id]).map((id) => find(dataPoints, { _id: id })?.name)).sort()
);

export const getDevicesByNodeId = (state, nodeId) => {
  const devices = fromDevices.getDevices(state);
  const node = fromNodes.getNodeById(state, nodeId) || {};
  return (node.devices || [])
    .map((deviceId) => devices[deviceId])
    .filter((device) => !!device.profiles);
};

export const getDeviceProfileNamesByNodeId = createSelector(
  getDevicesByNodeId,
  (devices = []) => {
    const profiles = [];
    devices.forEach((device) => {
      device.profiles.forEach((profile) => {
        profiles.push(profile.name);
      });
    });
    return profiles;
  }
);

export const getAutomationIdsByNodeId = createSelector(
  fromNodes.getNodeById,
  (node) => get(node, 'templates[0].configuration.node_id', [])
);

export const getDeviceConfigById = createSelector(
  fromDevices.getDeviceById,
  (state, deviceId, configName) => configName,
  (device, configName) => get(device, configName, get(device.configuration, configName, {}))
);

export const getDeviceCronById = createSelector(
  fromDevices.getDeviceById,
  (device) => get(device, 'cron', null)
);
