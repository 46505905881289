/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const setFilter = createAction('@@partnershipsList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@partnershipsList/CLEAR_FILTERS');
export const expandList = createAction('@@partnershipsList/EXPAND_ROW');

export const sortInvitationsList = createAction('@@invitationsList/SORT_BY');

const filter = createReducer(null, {
  [clearFilters]() {
    return null;
  },
  [setFilter](state, { payload }) {
    return payload;
  }
});

const partnershipsList = combineReducers({
  filter
});

export const getFilter = (state) => state.partnershipsList.filter;

export default partnershipsList;
