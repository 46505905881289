import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import some from 'lodash/some';
import last from 'lodash/last';
import filter from 'lodash/filter';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';

import * as fromAlerts from '~/store/reducers/alerts';
import * as fromMeasurements from '~/store/selectors/measurements';

export const ALERT_STATUSES = {
  IDLE: 'idle',
  RESOLVED: 'resolved',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const getAlertsIsFetched = (state) => fromAlerts.getIsFetched(state);
export const getAlertById = (state, id) => fromAlerts.getAlertById(state, id);
export const getAlertsIds = (state) => fromAlerts.getIds(state);
export const getAlertIsSyncing = (state, id) => fromAlerts.getAlertIsSyncing(state, id);
export const getAlertIsSynced = (state, id) => fromAlerts.getAlertIsSynced(state, id);

export const isAlertActivated = (status) => status === 'activated';
export const hasAlertAction = (actions, name) => includes(actions, name);
export const isAlertMonitorable = (actions) => hasAlertAction(actions, 'read');
export const isAlertModifiable = (actions) => hasAlertAction(actions, 'configure');
export const isAlertDeletable = (actions) => hasAlertAction(actions, 'delete');

export const getAlertsLength = createSelector(
  getAlertsIds,
  (nodes) => nodes.length
);

export const hasAlerts = createSelector(
  getAlertsLength,
  (size) => !!size
);

export const getAlertDataPointsIds = createSelector(
  getAlertById,
  (alert) => alert?.data_points || []
);

export const getAlertSubscribers = createSelector(
  getAlertById,
  (alert) => alert?.subscribers || {}
);

export const getAlertSubscribersEmailIds = createSelector(
  getAlertSubscribers,
  (subscribers) => subscribers.email
);

export const getAlertSubscribersSmsIds = createSelector(
  getAlertSubscribers,
  (subscribers) => subscribers.sms
);

export const getAlertByIdActions = createSelector(
  getAlertById,
  (alert) => alert?.actions || []
);

export const getAlertByIdStatus = createSelector(
  getAlertById,
  (alert) => alert?.status
);

export const getAlertByIdName = createSelector(
  getAlertById,
  (alert) => alert?.name
);

export const getAlertByIdOrganizationId = createSelector(
  getAlertById,
  (alert) => alert?.organization_id
);

export const getAlertLastMeasurement = (measurements) => {
  const values = filter(Object.values(measurements), isBoolean);
  if (!isEmpty(values)) {
    return some(values, (measurement) => measurement === true);
  }
  return null;
};

export const getAlertLastMeasurementValue = createSelector(
  fromMeasurements.getLastMeasurementsValuesById,
  getAlertLastMeasurement
);

export const getAlertLastMeasurementTs = createSelector(
  fromMeasurements.getLastMeasurementsTs,
  (measurements) => last(measurements)
);

export const getAlertStatus = (status, measurement) => {
  if (status === ALERT_STATUSES.DEACTIVATED) {
    return status;
  }
  switch (measurement) {
    case true: return ALERT_STATUSES.ACTIVE;
    case false: return ALERT_STATUSES.RESOLVED;
    default: return ALERT_STATUSES.IDLE;
  }
};
