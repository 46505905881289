/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */

import 'backbone.stickit';
import 'select2/dist/js/select2.full';
import intlTelInput from 'intl-tel-input';
import I18n from 'app/config/i18n';

((Backbone) => {
  const _stickit = Backbone.View.prototype.stickit;
  _.extend(Backbone.View.prototype, {
    stickit(optionalModel, optionalBindingsConfig) {
      const bindings = optionalBindingsConfig || _.result(this, 'bindings') || {};
      return _stickit.call(this, optionalModel, this.normalizeUIKeys(bindings));
    }
  });

  Backbone.Stickit.addHandler({
    selector: 'input,select,textarea',
    setOptions: {
      validate: true,
      validateAll: false
    }
  });

  Backbone.Stickit.addHandler({
    selector: 'input[type="password"]',
    initialize($el, model, opt) {
      return model.unset(opt.observe);
    }
  });

  Backbone.Stickit.addHandler({
    selector: '.truncated',
    initialize($el, model, opt) {
      return $el.attr('title', model.get(opt.observe));
    }
  });

  Backbone.Stickit.addHandler({
    selector: '.checkbox-switch',
    initialize($el, model, arg) {
      let elId; let
        observe;
      observe = arg.observe;
      elId = `checkbox-${model.cid}-${_.slugify(observe)}`;
      $el.attr('id', elId);
      return $el.siblings('label').attr('for', elId);
    },
    onGet(observed) {
      return !!observed;
    }
  });

  Backbone.Stickit.addHandler({
    selector: 'input.md[type="radio"]',
    initialize($el, model, arg) {
      let observe;
      observe = arg.observe;
      $el.parent('label').each((index, label) => {
        let $label; let elId; let
          input;
        $label = $(label);
        input = $label.find('input');
        elId = `${_.slugify(observe)}-${input.val()}`;
        input.attr('id', elId);
        return $label.attr('for', elId);
      });
      $el.on('change', (e) => {
        let $input;
        $input = $(e.currentTarget);
        $input.parents('.form-group').find('label.md-radio').removeClass('chosen');
        return $input.parent().addClass('chosen');
      });
      return $el.filter(`[value='${model.get(observe)}']`).triggerHandler('change');
    }
  });

  Backbone.Stickit.addHandler({
    selector: 'select.select2',
    initialize($el, model, opt) {
      let options; let
        defaults;
      defaults = { width: false };
      options = _.defaults(_.pick(opt, 'placeholder', 'disableSearch'), $el.data(), opt.select2Options, defaults);
      if (options.disableSearch) {
        delete options.disableSearch;
        options.minimumResultsForSearch = Infinity;
      }
      if (options.maximumSelectionSize) {
        options.formatSelectionTooBig = () => I18n.t('notifications.errors.too_many_selected_items');
      }
      const modal = $('.modal-body');
      if (modal.length) {
        options.dropdownParent = modal;
      }
      $el.select2(options);
      if (opt.afterUpdate) {
        if (_.isString(opt.afterUpdate)) {
          opt.afterUpdate = this[opt.afterUpdate];
        }
        let value;
        if (_.isArray(opt.observe)) {
          value = opt.onGet(_.map(opt.observe, (attr) => model.get(attr)));
        } else {
          value = model.get(opt.observe);
        }
        opt.afterUpdate.call(this, $el, value, opt);
      }
    }
  });

  Backbone.Stickit.addHandler({
    selector: 'input.slider',
    initialize($el, model, opt) {
      let options; let
        value;
      value = model.get(opt.observe);
      options = _.defaults(opt.sliderOptions, {
        tooltip: 'hide',
        value: parseFloat(value)
      });
      $el.on('slide', (e) => model.set(opt.observe, e.value));
      return $el.slider(options);
    }
  });

  Backbone.Stickit.addHandler({
    selector: 'input[type="tel"]',
    initialize($el, model, opt) {
      let options;
      let iti;
      options = _.defaults(opt.intlTelOptions || {}, {
        nationalMode: false,
        autoFormat: true,
        preferredCountries: ['us', 'cn'],
        utilsScript: '/utils.js',
        numberType: 'FIXED_LINE_OR_MOBILE'
      });
      $el.on('countrychange', () => {
        const { iso2 } = iti.selectedCountryData;
        return model.set(`${opt.observe}_country_code`, iso2);
      });
      iti = intlTelInput($el[0], options);
    },
    getVal($el) {
      const iti = window.intlTelInputGlobals.getInstance($el[0]);
      return iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL);
    }
  });

  Backbone.Stickit.addHandler({
    selector: '[data-role=\'tagsinput\']',
    events: [],
    initialize($el, model, opt) {
      this.model = model;
      $el.tagsinput(_.defaults({
        confirmKeys: [13, 188]
      }, opt.tagsInput));
      _.each(this.model.getTags(), (tag) => $el.tagsinput('add', tag));
      $el.on('itemAdded', ((_this) => (event) => _this.model.addTag(event.item))(this));
      return $el.on('itemRemoved', ((_this) => (event) => {
        if (event.item != null) {
          return _this.model.removeTag(event.item);
        }
      })(this));
    }
  });
})(Backbone);
