import { createSelector } from 'reselect';
import some from 'lodash/some';
import kebabCase from 'lodash/kebabCase';
import isArray from 'lodash/isArray';
import moment from 'moment';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import flatten from 'lodash/flatten';
import union from 'lodash/union';
import isEmpty from 'lodash/isEmpty';
import * as fromOrganizations from '~/store/reducers/organizations';
import * as fromFiles from './reducer';

export const getFilesFilterTags = createSelector(
  fromFiles.getFilter,
  ({ tags }) => tags
);

export const getFilename = (file = {}) => {
  const { from, to, serial } = file;
  const timePeriod = `${moment(from).format('L')}_${moment(to).format('L')}`;
  return `${serial}_${kebabCase(timePeriod)}.csv`;
};

function getCounts(tags) {
  return tags.reduce((result, tag) => {
    if (!result[tag]) {
      result[tag] = 0; // eslint-disable-line no-param-reassign
    }
    result[tag] += 1; // eslint-disable-line no-param-reassign
    return result;
  }, {});
}

const createDateToFilterTags = (file) => {
  const { to } = file;
  return [moment(to).format('YYYY/MM')];
};

export const getFilesDateToFilterTags = createSelector(
  fromFiles.getFiles,
  (files) => {
    const result = {};
    const list = Object.values(files);
    result.counts = getCounts(flatten(list.map((file) => createDateToFilterTags(file))));
    result.all = Object.values(result.counts).length;
    return result;
  }
);

export const getFilteredList = createSelector(
  fromFiles.getFiles,
  fromOrganizations.getOrganizations,
  fromFiles.getFilter,
  (files, organizations, { query, tags, oid }) => {
    let results = Object.values(files).map((file) => ({
      ...file,
      organization_name: organizations[isArray(file.oid) ? file.oid[0] : file.oid]?.name
    }));
    if (oid) {
      results = results.filter((file) => includes(file.oid, oid));
    }
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((file) => {
        const { serial } = file;
        const searchString = [serial].join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    if (!isEmpty(tags)) {
      results = results.filter((file) => {
        const allTags = union(createDateToFilterTags(file));
        return intersection(tags, allTags).length === tags.length;
      });
    }
    return results;
  }
);
