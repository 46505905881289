import {
  call,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';
import { normalizeResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import requestApi from '~/utils/axios';
import * as Actions from './reducer';

export function* filesFetchRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'post',
      'snapshots'
    );
    const response = normalizeResp(data, schema.filesListSchema);
    yield put(Actions.fetchFilesSuccess(response));
  } catch (e) {
    yield put(Actions.fetchFilesFailure(e.toString()));
  }
}

function* watchFetchFilesRequest() {
  yield takeLatest(Actions.fetchFilesRequest.toString(), filesFetchRequestSaga);
}

export default function* app() {
  yield fork(watchFetchFilesRequest);
}
