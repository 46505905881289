/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import React from 'react';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import withTooltip from '~/hocs/withTooltip';
import { DeviceStatuses } from './styles';

const DeviceStatus = withTheme((props) => {
  const IconTooltip = ({ title, tooltipRef }) => {
    const { iconClassName } = props;
    return (
      <span ref={tooltipRef} data-tip={title}><i className={classNames('fa', 'fa-circle', iconClassName || 'fa-sm')} /></span>
    );
  };

  const Tooltip = withTooltip(IconTooltip);
  const title = isArray(props.statusText) ? props.statusText.join('\n') : props.statusText;
  const DeviceStatusToBeRendered = DeviceStatuses[kebabCase(props.statusType)] || DeviceStatuses.empty;

  return (
    <DeviceStatusToBeRendered {...props}>
      <Tooltip title={title} />
    </DeviceStatusToBeRendered>
  );
});

DeviceStatus.propTypes = {
  statusText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  statusType: PropTypes.string.isRequired,
  iconClassName: PropTypes.string
};

export default DeviceStatus;
