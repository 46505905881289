import 'backbone-validation';
import I18n from 'app/config/i18n';

// eslint-disable-next-line no-param-reassign
_(Backbone.Validation.messages).each((value, key, list) => list[key] = _(I18n.t(`validation.backbone.errors.${key}`, {
  defaultValue: value
})).convertI18nStr());

_.extend(Backbone.Validation.patterns, {
  externalId: /AC[0-9]{6}$/i
});

_.extend(Backbone.Validation.validators, {
  requiredIfSet(value, attr, ifSetAttr, model, computed) {
    if (!_.isEmpty(computed[ifSetAttr])) {
      return this.required(value, attr, this, model);
    }
  },
  requiredIfNotSet(value, attr, ifNotSet, model, computed) {
    if (!computed[ifNotSet]) {
      return this.required(value, attr, this, model);
    }
  },
  mobile(value, attr, customValue, model, computed) {
    let errorCode; let
      example;
    if (!window.intlTelInputUtils.isValidNumber(value)) {
      errorCode = window.intlTelInputUtils.getValidationError(value);
      if (window.intlTelInputUtils.validationError.IS_POSSIBLE === errorCode) {
        return;
      }
      example = window.intlTelInputUtils.getExampleNumber(computed[`${attr}_country_code`], true, window.intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE);
      if (_.isEmpty(example)) {
        return I18n.t('validation.errors.models.user.attributes.phone');
      }
      return I18n.t('validation.errors.models.user.attributes.phone_with_example', {
        example: `${example}`
      });
    }
  }
});

_.extend(Backbone.Validation.messages, {
  required: I18n.t('validation.errors.required')
});

_.extend(Backbone.Validation.callbacks, {
  valid(view, attr, selector) {
    const control = this._controlEl({
      selector,
      attr
    });
    const group = control.closest('.form-group');
    group.removeClass('has-error');
    if (control.data('error-style') === 'inline') {
      return group.find('.help-inline.error-message').remove();
    }
    return group.find('.help-block.error-message').remove();
  },
  invalid(view, attr, error, selector) {
    let label; let
      target;
    const control = this._controlEl({
      selector,
      attr
    });
    const group = control.closest('.form-group');
    group.addClass('has-error');
    if (control.data('error-style') === 'inline') {
      if (group.find('.help-inline').length === 0) {
        label = group.find('.control-label');
        label.append('<span class="help-inline error-message"></span>');
      }
      target = group.find('.help-inline');
      return target.html(error);
    }
    if (group.find('.help-block').length === 0) {
      if (group.find('.form-group').length > 0) group.find('.form-group .input-error-wrapper').append('<p class="help-block error-message"></p>');
      else if (group.find('.input-error-wrapper').length > 0) {
        if (group.find('.intl-tel-input').length > 0) group.find('.intl-tel-input').append('<p class="help-block error-message"></p>');
        else group.find('.input-error-wrapper').append('<p class="help-block error-message"></p>');
      }
    }
    target = group.find('.help-block');
    return target.html(error);
  },
  _controlEl({ selector, attr }) {
    return $(`[${selector}='${attr}']:first`);
  }
});
