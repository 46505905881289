/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const exportTableDataRequest = createAction('@@alertMonitor/EXPORT_TABLE_DATA_REQUEST');
export const exportTableDataSuccess = createAction('@@alertMonitor/EXPORT_TABLE_DATA_SUCCESS');
export const exportTableDataFailure = createAction('@@alertMonitor/EXPORT_TABLE_DATA_FAILURE');

const byId = createReducer({}, {
  [exportTableDataRequest](state, { payload }) {
    state[payload] = {
      isExportingStarted: true
    };
  },
  [exportTableDataSuccess](state, { payload }) {
    state[payload] = {
      isExportingStarted: false
    };
  },
  [exportTableDataFailure](state, { payload }) {
    state[payload] = {
      isExportingStarted: false
    };
  }
});

const alertMonitor = combineReducers({
  byId
});

export const getIsExportingData = (state, id) => state.alertMonitor.byId[id]?.isExportingStarted;

export default alertMonitor;
