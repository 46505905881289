import countries from 'app/config/common_data/countries';
import usStates from 'app/config/common_data/us_states';

export function getCountryByCode(code) {
  return countries.find((item) => item.code === code);
}

export function getCountrySelectValue(code) {
  const value = getCountryByCode(code);
  if (value) {
    return {
      label: value.name,
      value: value.code
    };
  }
  return null;
}

export function getStateByCode(code) {
  return usStates.find((item) => item.code === code);
}

export function getStateSelectValue(code) {
  const value = getStateByCode(code);
  if (value) {
    return {
      label: value.name,
      value: value.code
    };
  }
  return null;
}
