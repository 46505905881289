/* eslint-disable new-cap */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';
import { api as membershipApi } from 'app/backbone/entities/membership';
import { api as alertsApi } from 'app/backbone/entities/nodes/alert';
import { api as userApi } from 'app/backbone/entities/user';

class Router extends AppRouter {
  initialize({ controller }) {
    this.controller = controller;
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    return this.appRoute(/^alerts\/(\w+)$/, 'showAlert');
  }

  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'alert');
  }
}

const controller = {
  async showAlert(alertId, options = {}) {
    let alert;
    if (alertId === 'new') {
      let attrs = {};
      if (!App.getChannel().request('get:current:user').isSupport()) {
        const currentUserId = App.getChannel().request('get:current:user').id;
        attrs = {
          subscribers: {
            email: [currentUserId],
            sms: [currentUserId]
          }
        };
      }
      const { payload } = options;
      if (payload) {
        attrs = {
          ...attrs,
          ...payload
        };
      }
      alert = alertsApi.getChannel().request('new:alert');
      if (attrs.organization_id) {
        const [thiamises, users] = await Promise.all([alert.fetchThiamises(attrs), alert.fetchUsers(attrs)]);
        alert.getUsers().reset(users, { parse: true });
        alert.getThiamises().reset(thiamises);
        alert.set(alert.parse(attrs), { parse: true });
      }
    } else {
      alert = alertsApi.getChannel().request('get:alert', alertId);
    }
    const promise = import('app/backbone/apps/alerts/show/show_controller'); // eslint-disable-line import/no-cycle
    promise.then((ShowController) => {
      const ctrl = new ShowController.default({
        hasReferences: userApi.getChannel().request('has:alert:references'),
        alert,
        alerts: alertsApi.getChannel().request(alert.isNew() ? 'get:multichoosable:alerts' : 'fetch:multi:choosable:alerts'),
        users: userApi.getChannel().request('get:users'),
        organizations: membershipApi.getChannel().request('get:memberships'),
        filteredAlerts: options.filteredAlerts
      });
      return ctrl.showPageView();
    });
    return promise;
  }
};

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:alerts': 'appAlerts',
    'app:alerts:modify': 'appAlertsModify',
    'app:alerts:new': 'appAlertsNew',
    'app:alerts:monitor': 'appAlertsMonitor'
  },

  appAlerts() {
    Router.prototype.before();
    App.navigate('alerts');
    App.getChannel().request('enable:org_filter', true);
  },

  appAlertsModify(alertId, options) {
    App.navigate(`alerts/${alertId}`, { trigger: false });
    return controller.showAlert(alertId, options);
  },

  appAlertsNew(options) {
    App.navigate('alerts/new', { trigger: false });
    return controller.showAlert('new', options);
  },

  appAlertsMonitor(alertId) {
    Router.prototype.before();
    App.navigate(`alerts/${alertId}/monitor`);
  }
});

export const api = new API();

const AlertsApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default AlertsApp;
