import React from 'react';
import { connect } from 'react-redux';
import { getFilesDateToFilterTags } from '../selectors';
import FiltersSidebarPanel from './filters_sidebar_panel';

const FiltersSidebar = React.memo((props) => {
  const { tags } = props;
  return (
    <div className="filters-region right-panel">
      <div className="fill-height">
        <div className="filter-groups-list-region">
          <div id="filters-wrap" className="panel-group collapse in" aria-expanded="true">
            <FiltersSidebarPanel filters={tags} group="tags" collapsedIn />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  tags: getFilesDateToFilterTags(state)
});

export default connect(mapStateToProps)(FiltersSidebar);
