/* eslint-disable */
/*
File generated by js-routes 1.2.5
Based on Rails routes of Environetui::Application
 */

const API_URL = process.env.API_URL;

let NodeTypes; let ParameterMissing; let ReservedOptions; let Utils; let defaults;


const hasProp = {}.hasOwnProperty;


const slice = [].slice;

ParameterMissing = function (message) {
  this.message = message;
};

ParameterMissing.prototype = new Error();

defaults = {
  prefix: '',
  default_url_options: {}
};

NodeTypes = { GROUP: 1, CAT: 2, SYMBOL: 3, OR: 4, STAR: 5, LITERAL: 6, SLASH: 7, DOT: 8 };

ReservedOptions = ['anchor', 'trailing_slash', 'host', 'port', 'protocol'];

Utils = {
  default_serializer(object, prefix) {
    let element; let i; let j; let key; let len; let prop; let
      s;
    if (prefix == null) {
      prefix = null;
    }
    if (object == null) {
      return '';
    }
    if (!prefix && !(this.get_object_type(object) === 'object')) {
      throw new Error('Url parameters should be a javascript hash');
    }
    s = [];
    switch (this.get_object_type(object)) {
      case 'array':
        for (i = j = 0, len = object.length; j < len; i = ++j) {
          element = object[i];
          s.push(this.default_serializer(element, `${prefix}[]`));
        }
        break;
      case 'object':
        for (key in object) {
          if (!hasProp.call(object, key)) continue;
          prop = object[key];
          if ((prop == null) && (prefix != null)) {
            prop = '';
          }
          if (prop != null) {
            if (prefix != null) {
              key = `${prefix}[${key}]`;
            }
            s.push(this.default_serializer(prop, key));
          }
        }
        break;
      default:
        if (object != null) {
          s.push(`${encodeURIComponent(prefix.toString())}=${encodeURIComponent(object.toString())}`);
        }
    }
    if (!s.length) {
      return '';
    }
    return s.join('&');
  },
  custom_serializer: null,
  serialize(object) {
    if ((this.custom_serializer != null) && this.get_object_type(this.custom_serializer) === 'function') {
      return this.custom_serializer(object);
    }
    return this.default_serializer(object);
  },
  clean_path(path) {
    let last_index;
    path = path.split('://');
    last_index = path.length - 1;
    path[last_index] = path[last_index].replace(/\/+/g, '/');
    return path.join('://');
  },
  extract_options(number_of_params, args) {
    let last_el;
    last_el = args[args.length - 1];
    if ((args.length > number_of_params && last_el === undefined) || ((last_el != null) && this.get_object_type(last_el) === 'object' && !this.looks_like_serialized_model(last_el))) {
      return args.pop() || {};
    }
    return {};
  },
  looks_like_serialized_model(object) {
    return 'id' in object || 'to_param' in object;
  },
  path_identifier(object) {
    let property;
    if (object === 0) {
      return '0';
    }
    if (!object) {
      return '';
    }
    property = object;
    if (this.get_object_type(object) === 'object') {
      if ('to_param' in object) {
        property = object.to_param;
      } else if ('id' in object) {
        property = object.id;
      } else {
        property = object;
      }
      if (this.get_object_type(property) === 'function') {
        property = property.call(object);
      }
    }
    return property.toString();
  },
  clone(obj) {
    let attr; let copy; let
      key;
    if ((obj == null) || this.get_object_type(obj) !== 'object') {
      return obj;
    }
    copy = obj.constructor();
    for (key in obj) {
      if (!hasProp.call(obj, key)) continue;
      attr = obj[key];
      copy[key] = attr;
    }
    return copy;
  },
  merge() {
    let tap; let
      xs;
    xs = arguments.length >= 1 ? slice.call(arguments, 0) : [];
    tap = function (o, fn) {
      fn(o);
      return o;
    };
    if ((xs != null ? xs.length : undefined) > 0) {
      return tap({}, (m) => {
        let j; let k; let len; let results; let v; let
          x;
        results = [];
        for (j = 0, len = xs.length; j < len; j++) {
          x = xs[j];
          results.push((function () {
            let results1;
            results1 = [];
            for (k in x) {
              v = x[k];
              results1.push(m[k] = v);
            }
            return results1;
          })());
        }
        return results;
      });
    }
  },
  normalize_options(default_parts, required_parameters, optional_parts, actual_parameters) {
    let i; let j; let key; let len; let options; let result; let url_parameters; let
      value;
    options = this.extract_options(required_parameters.length, actual_parameters);
    if (actual_parameters.length > required_parameters.length) {
      throw new Error('Too many parameters provided for path');
    }
    options = this.merge(defaults.default_url_options, default_parts, options);
    result = {};
    url_parameters = {};
    result.url_parameters = url_parameters;
    for (key in options) {
      if (!hasProp.call(options, key)) continue;
      value = options[key];
      if (ReservedOptions.indexOf(key) >= 0) {
        result[key] = value;
      } else {
        url_parameters[key] = value;
      }
    }
    for (i = j = 0, len = required_parameters.length; j < len; i = ++j) {
      value = required_parameters[i];
      if (i < actual_parameters.length) {
        url_parameters[value] = actual_parameters[i];
      }
    }
    return result;
  },
  build_route(required_parameters, optional_parts, route, default_parts, full_url, args) {
    let options; let parameters; let result; let url; let
      url_params;
    args = Array.prototype.slice.call(args);
    options = this.normalize_options(default_parts, required_parameters, optional_parts, args);
    parameters = options.url_parameters;
    result = `${this.get_prefix()}${this.visit(route, parameters)}`;
    url = Utils.clean_path(`${result}`);
    if (options.trailing_slash === true) {
      url = url.replace(/(.*?)[\/]?$/, '$1/');
    }
    if ((url_params = this.serialize(parameters)).length) {
      url += `?${url_params}`;
    }
    url += options.anchor ? `#${options.anchor}` : '';
    if (API_URL) { url = API_URL + url; } else if (full_url) { url = this.route_url(options) + url; }
    return url;
  },
  visit(route, parameters, optional) {
    let left; let left_part; let right; let right_part; let type; let
      value;
    if (optional == null) {
      optional = false;
    }
    type = route[0], left = route[1], right = route[2];
    switch (type) {
      case NodeTypes.GROUP:
        return this.visit(left, parameters, true);
      case NodeTypes.STAR:
        return this.visit_globbing(left, parameters, true);
      case NodeTypes.LITERAL:
      case NodeTypes.SLASH:
      case NodeTypes.DOT:
        return left;
      case NodeTypes.CAT:
        left_part = this.visit(left, parameters, optional);
        right_part = this.visit(right, parameters, optional);
        if (optional && (((left[0] === NodeTypes.SYMBOL || left[0] === NodeTypes.CAT) && !left_part) || ((right[0] === NodeTypes.SYMBOL || right[0] === NodeTypes.CAT) && !right_part))) {
          return '';
        }
        return `${left_part}${right_part}`;
      case NodeTypes.SYMBOL:
        value = parameters[left];
        if (value != null) {
          delete parameters[left];
          return this.path_identifier(value);
        }
        if (optional) {
          return '';
        }
        throw new ParameterMissing(`Route parameter missing: ${left}`);

        break;
      default:
        throw new Error('Unknown Rails node type');
    }
  },
  build_path_spec(route, wildcard) {
    let left; let right; let
      type;
    if (wildcard == null) {
      wildcard = false;
    }
    type = route[0], left = route[1], right = route[2];
    switch (type) {
      case NodeTypes.GROUP:
        return `(${this.build_path_spec(left)})`;
      case NodeTypes.CAT:
        return `${this.build_path_spec(left)}${this.build_path_spec(right)}`;
      case NodeTypes.STAR:
        return this.build_path_spec(left, true);
      case NodeTypes.SYMBOL:
        if (wildcard === true) {
          return `${left[0] === '*' ? '' : '*'}${left}`;
        }
        return `:${left}`;

        break;
      case NodeTypes.SLASH:
      case NodeTypes.DOT:
      case NodeTypes.LITERAL:
        return left;
      default:
        throw new Error('Unknown Rails node type');
    }
  },
  visit_globbing(route, parameters, optional) {
    let left; let right; let type; let
      value;
    type = route[0], left = route[1], right = route[2];
    if (left.replace(/^\*/i, '') !== left) {
      route[1] = left = left.replace(/^\*/i, '');
    }
    value = parameters[left];
    if (value == null) {
      return this.visit(route, parameters, optional);
    }
    parameters[left] = (function () {
      switch (this.get_object_type(value)) {
        case 'array':
          return value.join('/');
        default:
          return value;
      }
    }).call(this);
    return this.visit(route, parameters, optional);
  },
  get_prefix() {
    let prefix;
    prefix = defaults.prefix;
    if (prefix !== '') {
      prefix = (prefix.match('/$') ? prefix : `${prefix}/`);
    }
    return prefix;
  },
  route(required_parts, optional_parts, route_spec, default_parts, full_url) {
    let path_fn;
    path_fn = function () {
      return Utils.build_route(required_parts, optional_parts, route_spec, default_parts, full_url, arguments);
    };
    path_fn.required_params = required_parts;
    path_fn.toString = function () {
      return Utils.build_path_spec(route_spec);
    };
    return path_fn;
  },
  route_url(route_defaults) {
    let hostname; let port; let
      protocol;
    if (typeof route_defaults === 'string') {
      return route_defaults;
    }
    protocol = route_defaults.protocol || Utils.current_protocol();
    hostname = route_defaults.host || window.location.hostname;
    port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : undefined);
    port = port ? `:${port}` : '';
    return `${protocol}://${hostname}${port}`;
  },
  has_location() {
    return typeof window !== 'undefined' && typeof window.location !== 'undefined';
  },
  current_host() {
    if (this.has_location()) {
      return window.location.hostname;
    }
    return null;
  },
  current_protocol() {
    if (this.has_location() && window.location.protocol !== '') {
      return window.location.protocol.replace(/:$/, '');
    }
    return 'http';
  },
  current_port() {
    if (this.has_location() && window.location.port !== '') {
      return window.location.port;
    }
    return '';
  },
  _classToTypeCache: null,
  _classToType() {
    let j; let len; let name; let
      ref;
    if (this._classToTypeCache != null) {
      return this._classToTypeCache;
    }
    this._classToTypeCache = {};
    ref = 'Boolean Number String Function Array Date RegExp Object Error'.split(' ');
    for (j = 0, len = ref.length; j < len; j++) {
      name = ref[j];
      this._classToTypeCache[`[object ${name}]`] = name.toLowerCase();
    }
    return this._classToTypeCache;
  },
  get_object_type(obj) {
    if (jQuery && (jQuery.type != null)) {
      return jQuery.type(obj);
    }
    if (obj == null) {
      return `${obj}`;
    }
    if (typeof obj === 'object' || typeof obj === 'function') {
      return this._classToType()[Object.prototype.toString.call(obj)] || 'object';
    }
    return typeof obj;
  }
};

const Routes = {
  // accept_formation_request => /formation_requests/:id/accept(.:format)
  // function(id, options)
  accept_formation_request_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'formation_requests', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'accept', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // accept_partnership_invitation => /partnership_invitations/:id/accept(.:format)
  // function(id, options)
  accept_partnership_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'partnership_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'accept', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // accept_user_invitation => /user_invitations/:id/accept(.:format)
  // function(id, options)
  accept_user_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'accept', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // alert_notification_index => /alert_notification(.:format)
  // function(options)
  alert_notification_index_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'alert_notification', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // alert_subscription => /alert_subscriptions/:id(.:format)
  // function(id, options)
  alert_subscription_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'alert_subscriptions', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // alert_subscriptions => /alert_subscriptions(.:format)
  // function(options)
  alert_subscriptions_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'alert_subscriptions', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // app_bootstrap => /bootstrap(.:format)
  // function(options)
  app_bootstrap_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'bootstrap', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // app_index => /
  // function(options)
  app_index_path: Utils.route([], [], [7, '/', false], {}),
  // bulk_accept_user_invitations => /user_invitations/bulk_accept(.:format)
  // function(options)
  bulk_accept_user_invitations_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [6, 'bulk_accept', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // current_users => /users/current(.:format)
  // function(options)
  current_users_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'users', false], [2, [7, '/', false], [2, [6, 'current', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // edit_alert_subscription => /alert_subscriptions/:id/edit(.:format)
  // function(id, options)
  edit_alert_subscription_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'alert_subscriptions', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'edit', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // edit_formation_request => /formation_requests/:id/edit(.:format)
  // function(id, options)
  edit_formation_request_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'formation_requests', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'edit', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // edit_organization => /organizations/:id/edit(.:format)
  // function(id, options)
  edit_organization_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'organizations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'edit', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // formation_request => /formation_requests/:id(.:format)
  // function(id, options)
  formation_request_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'formation_requests', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // formation_requests => /formation_requests(.:format)
  // function(options)
  formation_requests_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'formation_requests', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // health => /health(.:format)
  // function(options)
  health_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'health', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // jasmine_rails.root => /jasmine/
  // function(options)
  jasmine_rails_root_path: Utils.route([], [], [2, [2, [7, '/', false], [6, 'jasmine', false]], [7, '/', false]], {}),
  // login => /login(.:format)
  // function(options)
  login_path() { return '/login.html'; },
  // message => /messages/:id(.:format)
  // function(id, options)
  message_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'messages', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // messages => /messages(.:format)
  // function(options)
  messages_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'messages', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // new_formation_request => /formation_requests/new(.:format)
  //   function(options)
  new_formation_request_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'formation_requests', false], [2, [7, '/', false], [2, [6, 'new', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // new_organization => /organizations/new(.:format)
  // function(options)
  new_organization_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'organizations', false], [2, [7, '/', false], [2, [6, 'new', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // organization => /organizations/:id(.:format)
  // function(id, options)
  organization_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'organizations', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // organizations => /organizations(.:format)
  // function(options)
  organizations_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'organizations', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // partners => /partners(.:format)
  // function(options)
  partners_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'partners', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // partnership => /partnerships/:id(.:format)
  // function(id, options)
  partnership_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'partnerships', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // partnership_invitation => /partnership_invitations/:id(.:format)
  // function(id, options)
  partnership_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'partnership_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // partnership_invitations => /partnership_invitations(.:format)
  // function(options)
  partnership_invitations_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'partnership_invitations', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // partnerships => /partnerships(.:format)
  // function(options)
  partnerships_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'partnerships', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'rails', false], [2, [7, '/', false], [2, [6, 'info', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'rails', false], [2, [7, '/', false], [2, [6, 'info', false], [2, [7, '/', false], [2, [6, 'properties', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'rails', false], [2, [7, '/', false], [2, [6, 'info', false], [2, [7, '/', false], [2, [6, 'routes', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'rails', false], [2, [7, '/', false], [2, [6, 'mailers', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // reject_formation_request => /formation_requests/:id/reject(.:format)
  // function(id, options)
  reject_formation_request_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'formation_requests', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'reject', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // reject_partnership_invitation => /partnership_invitations/:id/reject(.:format)
  // function(id, options)
  reject_partnership_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'partnership_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'reject', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // reject_user_invitation => /user_invitations/:id/reject(.:format)
  // function(id, options)
  reject_user_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'reject', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // remind_partnership_invitation => /partnership_invitations/:id/remind(.:format)
  // function(id, options)
  remind_partnership_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'partnership_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'remind', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // remind_user_invitation => /user_invitations/:id/remind(.:format)
  // function(id, options)
  remind_user_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'remind', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // reports_monitor => /reports/monitor(.:format)
  // function(options)
  reports_monitor_path: Utils.route([], ['format'], [3, [7, '/', false], [2, [6, 'reports', false], [2, [7, '/', false], [2, [6, 'monitor', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // search => /search/text(.:format)
  // function(options)
  search_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'search', false], [2, [7, '/', false], [2, [6, 'text', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], [], [2, [7, '/', false], [6, 'sidekiq', false]], {}),
  // signup_user_invitation => /user_invitations/:id/signup(.:format)
  // function(id, options)
  signup_user_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'signup', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // signup_user_invitation => /user_invitations/:id/signup(.:format)
  // function(id, options)
  validate_user_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [2, [7, '/', false], [2, [6, 'validate', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]]]], {}),
  // support_ticket_index => /support_ticket(.:format)
  // function(options)
  support_ticket_index_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'support_ticket', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'users', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // user_invitation => /user_invitations/:id(.:format)
  // function(id, options)
  user_invitation_path: Utils.route(['id'], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [2, [7, '/', false], [2, [3, 'id', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]]]], {}),
  // user_invitations => /user_invitations(.:format)
  // function(options)
  user_invitations_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'user_invitations', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  // users => /users(.:format)
  // function(options)
  users_path: Utils.route([], ['format'], [2, [7, '/', false], [2, [6, 'users', false], [1, [2, [8, '.', false], [3, 'format', false]], false]]], {}),
  reports_monitor_path() { return `${API_URL}/reports/monitor`; },
  reports_path() { return `${API_URL}/reports`; },
  bootstrap_user_invitations_path() { return `${API_URL}/user_invitations`; },
  bootstrap_partnership_invitations_path() { return `${API_URL}/partnership_invitations`; },
  admin_access_context_path() { return `${API_URL}/users/access_context/support`; },
};

export default Routes;
