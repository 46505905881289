import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Nav, NavItem } from 'react-bootstrap';
import { TabContainer, PanelContainer } from '~/styles';
import { fetchUserRequest } from '~/store/reducers/users';
import ManageRolesForm from './manage_roles_form';
import UserProfileForm from './user_profile_form';

const TABS = {
  USER: 'user',
  MANAGE_ROLES: 'manage_roles'
};

const EditUser = ({ handleFetchUser, userId, slug }) => {
  const { t } = useTranslation();

  useEffect(() => {
    handleFetchUser(userId);
  }, [handleFetchUser, userId]);

  return (
    <Tab.Container id="user-tabs" defaultActiveKey={TABS.MANAGE_ROLES}>
      <>
        <TabContainer>
          <Nav bsStyle="tabs">
            <NavItem eventKey={TABS.USER}>
              <i className="fa fa-md fa-fw fa-user" />
              <span className="title">
                {t('users.user_details')}
              </span>
            </NavItem>
            <NavItem eventKey={TABS.MANAGE_ROLES}>
              <i className="fa fa-md fa-fw fa-group" />
              <span className="title">
                {t('users.manage_roles')}
              </span>
            </NavItem>
          </Nav>
        </TabContainer>
        <Tab.Content animation>
          <Tab.Pane eventKey={TABS.USER}>
            <PanelContainer>
              <UserProfileForm userId={userId} slug={slug} />
            </PanelContainer>
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.MANAGE_ROLES}>
            <PanelContainer>
              <ManageRolesForm userId={userId} slug={slug} />
            </PanelContainer>
          </Tab.Pane>
        </Tab.Content>
      </>
    </Tab.Container>
  );
};

const mapDispatchToProps = {
  handleFetchUser: fetchUserRequest
};

export default connect(null, mapDispatchToProps)(EditUser);
