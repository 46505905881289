import styled from 'styled-components';
import { Text } from '~/components/layout';

export const Container = styled.div`
  width: 50px;
  height: 20px;
  cursor: pointer;
  background: ${({ status }) => (status ? '#2A74BB' : '#666666')};
  position: relative;
  border-radius: 50px;

  &:after {
    content: '';
    top: 50%;
    left: ${({ status }) => (status ? '31px' : '2px')};
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    border-radius: 100px;
    transform: translateY(-50%);
  }
`;

export const Label = styled(Text)`
  top: 50%;
  right: ${({ status }) => (status ? '26px' : '5px')};
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  transform: translateY(-50%);
`;
