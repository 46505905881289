((Backbone) => {
  const _sync = Backbone.sync;
  return Backbone.sync = (method, entity, options = {}) => { // eslint-disable-line no-param-reassign
    if (!options.wait && method === 'update') {
      if (!entity.changedAttributes()) {
        return;
      }
    }
    const sync = _sync(method, entity, options);
    if (method === 'read') {
      entity._fetch = sync; // eslint-disable-line no-param-reassign
    }
    return sync;
  };
})(Backbone);
