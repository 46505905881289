import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import trim from 'lodash/trim';
import Select from 'react-select';
import { useFormState, Field as FinalField, useForm } from 'react-final-form';
import { getUserOrganizationRolesForInviting } from '~/store/selectors/users';
import { getCurrentUserId } from '~/store/selectors/global';
import useValidation from '~/hooks/useValidation';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const { validateRequired, validateEmail } = useValidation();
  const organizations = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return getUserOrganizationRolesForInviting(state, currentUserId);
  });
  const form = useForm();
  const { values: { organization: selectedOrganization } } = useFormState();
  useEffect(() => {
    form.change('role', null);
  }, [form, selectedOrganization]);

  const possibleRoles = useMemo(() => {
    if (!selectedOrganization) {
      return [];
    }
    if (selectedOrganization?.role === 'manager') {
      return [{
        label: t('user'),
        value: 'user'
      }];
    }
    if (selectedOrganization?.role === 'owner') {
      return [{
        label: t('user'),
        value: 'user'
      }, {
        label: t('manager'),
        value: 'manager'
      },
      {
        label: t('owner'),
        value: 'owner'
      }];
    }
    return [];
  }, [t, selectedOrganization]);

  const mappedOrganizations = useMemo(() => organizations.map((item) => ({
    value: item.organizationId,
    label: item.organizationName,
    ...item
  })), [organizations]);

  const { hasValidationErrors } = useFormState();

  return (
    <div className="form form-horizontal profile">
      <div className="form-region form-margins">
        <FinalField
          name="email"
          formatOnBlur
          validate={validateEmail}
        >
          {
            ({ input }) => (
              <div className={classNames('form-group')}>
                <label className="col-sm-4">
                  {t('attributes.user.email')}
                </label>
                <div className="col-sm-8">
                  <input
                    className="config-email form-control"
                    value={trim(input.value)}
                    onChange={input.onChange}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
        <FinalField
          name="organization"
          validate={validateRequired}
        >
          {
            ({ input }) => (
              <div className="form-group">
                <label className="col-sm-4">
                  {t('models.organization')}
                </label>
                <div className="col-sm-8">
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    options={mappedOrganizations}
                    value={input.value}
                    onChange={input.onChange}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
        <FinalField
          name="role"
          validate={validateRequired}
        >
          {
            ({ input }) => (
              <div className="form-group">
                <label className="col-sm-4">
                  {t('attributes.user.role')}
                </label>
                <div className="col-sm-8">
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    options={possibleRoles}
                    value={input.value}
                    onChange={input.onChange}
                  />
                </div>
              </div>
            )
          }
        </FinalField>
      </div>
      <div className="form-group form-actions clearfix">
        <button type="button" className="btn btn-default" onClick={handleCancel}>
          {t('base.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="save-form btn btn-primary"
          disabled={hasValidationErrors}
          onClick={handleSubmit}
        >
          {t('partnership_invitations.invite_button')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
