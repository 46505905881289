import { createContext } from 'react';

const ManageCalibrationsContext = createContext({
  isActive: false,
  selectedCalibrations: [],
  setSelectedCalibrations: () => {},
  setIsActive: () => {}
});

export default ManageCalibrationsContext;
