var Handlebars = require("../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n    <a title=\""
    + container.escapeExpression(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"base.buttons.configure",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":47}}}))
    + "\" data-toggle=\"popover\" class=\"btn btn btn-sm btn-default btn_toggle edit-config\"><i class=\"fa fa-gear\"></i></a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModifiable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":2,"column":166}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"thiamis-status\"></div>\n<form class=\"form-horizontal\">\n  <div class=\"form-group\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.name",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":67}}}))
    + "</label><span class=\"thiamis-name\"></span></div>\n  <div class=\"form-group\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.description",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":74}}}))
    + "</label><span class=\"thiamis-description\"></span></div>\n  <div class=\"form-group reporting-interval\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.interval",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":7,"column":52},"end":{"line":7,"column":90}}}))
    + "</label><span>\n      <p class=\"thiamis-interval\"></p>\n    </span></div>\n  <div class=\"form-group\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.organization_id",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":78}}}))
    + "</label><span class=\"thiamis-owner\"></span></div>\n  <div class=\"form-group\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"thiamis.billing",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":59}}}))
    + "</label><span class=\"thiamis-billing\"></span></div>\n  <div class=\"form-group thiamis-shared-to\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"thiamis.more_info.shared_to",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":89}}}))
    + "</label><span></span></div>\n  <div class=\"form-group thiamis-leased-to\"><label>"
    + alias2(__default(require("../../../../../../../config/hbs-helpers/i18t.js")).call(alias1,"thiamis.more_info.leased_to",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":13,"column":51},"end":{"line":13,"column":89}}}))
    + "</label><span class=\"truncated\"></span></div>\n</form>\n<div class=\"info-params-region\"></div>";
},"useData":true});