/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { api as auth0Api } from 'app/utils/auth0_handler';

export default class DownloadModel extends Backbone.Model {
  initialize() {
    return this.on('download:form', function () {
      return this.set('token', auth0Api.getChannel().request('get:instance').getAuthToken());
    });
  }
}
