/* eslint-disable */
import { APP_NAMES } from '~/utils/constants';

export function b2a(a) {
  let c; let d; let e; let f; let g; let h; let i; let j; let o; const b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='; let k = 0; let l = 0; let m = ''; const
    n = [];
  if (!a) return a;
  do {
    c = a.charCodeAt(k++), d = a.charCodeAt(k++), e = a.charCodeAt(k++), j = c << 16 | d << 8 | e,
    f = 63 & j >> 18, g = 63 & j >> 12, h = 63 & j >> 6, i = 63 & j, n[l++] = b.charAt(f) + b.charAt(g) + b.charAt(h) + b.charAt(i);
  } while (k < a.length);
  return m = n.join(''), o = a.length % 3, (o ? m.slice(0, o - 3) : m) + '==='.slice(o || 3);
}

export function conceal(word) {
  if (!word) { return; }
  return b2a(word).replace(/[^a-z0-9_\-]/ig, '');
}

export function getLogoUrl() {
  const logo = $('.sample.header-logo');
  const urlInApostrophes = logo.css('background-image').match(/"(.*)"/);
  if (urlInApostrophes) { return urlInApostrophes[1]; }
  return logo.css('background-image').match(/\((.*)\)/)[1];
}

export function getIndexUrl() {
  const fullpath = location.pathname;
  return location.origin + fullpath.substring(0, fullpath.lastIndexOf('/') + 1);
}

export function getSubdomain() {
  const match = /^([^\.]*)\./.exec(location.host);
  return match && match[1];
}

export function getAppName() {
  const subDomain = isProduction() && getSubdomain();
  return subDomain ? APP_NAMES[subDomain] || 'environet' : 'environet';
}

export function getEnvironment() {
  return process.env.NODE_ENV || 'development';
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isIE11() {
  return !!window.msCrypto;
}

export function prependIndexUrl(file) {
  return getIndexUrl() + file;
}

export function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}

const reA = /[^a-zA-Z]/g;
const reN = /[^0-9]/g;
export function sortAlphaNum(a, b) {
  const aA = a.replace(reA, "");
  const bA = b.replace(reA, "");
  if (aA === bA) {
    const aN = parseInt(a.replace(reN, ""), 10);
    const bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}

export function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  }
  return undefined;
}

export function getReleaseName() {
  return process.env.GITHUB_REF_TYPE === 'tag' ? process.env.GITHUB_REF_NAME : process.env.GITHUB_SHA;
}

