/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'app/config/i18n';
import GsmSignalLevel from 'app/backbone/lib/react/sensors/gsmSignalLevel/gsmSignalLevel';
import WifiSignalLevel from 'app/backbone/lib/react/sensors/wifiSignalLevel/wifiSignalLevel';
import DataPoint from 'app/backbone/entities/data_point';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';
import { __guard__, __guardMethod__ } from 'app/utils/custom-fns';

class SignalLevel extends Component {
  constructor(props) {
    super(props);
    this.signalLevels = [-65, -75, -85, -95, -105];
  }

  getNetworkName(netType) {
    return __guard__(__guard__(this.props.model != null ? this.props.getModel().collection : undefined, (x1) => x1.getThiamisDataPoint(DataPoint.PATHS[netType])), (x) => x.getLastMeasurement());
  }

  getProps() {
    let networkName; let
      title;
    const props = {};
    if (__guardMethod__(this.props.getModel(), 'isThiamisWifiSignal', (o) => o.isThiamisWifiSignal())) {
      networkName = this.getNetworkName('WIFI_NETWORK');

      if ((this.props.model != null ? this.props.getModel().get('value') : undefined)) {
        title = I18n.t('thiamis.more_info.wifi_signal', { value: this.getDisplayValue(), network: networkName });
      } else {
        title = I18n.t('thiamis.more_info.wifi_signal_na');
      }
    } else {
      networkName = this.getNetworkName('GSM_NETWORK');

      if (_.isDefined(this.props.model != null ? this.props.getModel().get('value') : undefined) && networkName) {
        title = I18n.t('thiamis.more_info.gsm_signal_with_network', { value: this.getDisplayValue(), network: networkName });
      } else if (_.isDefined(this.props.model != null ? this.props.getModel().get('value') : undefined) && !networkName) {
        title = I18n.t('thiamis.more_info.gsm_signal', { value: this.getDisplayValue() });
        props.NoGsmNetworkName = I18n.t('base.labels.n_a');
      } else {
        title = I18n.t('thiamis.more_info.gsm_signal_na');
      }
    }

    props.title = title;
    props.networkName = networkName;

    if (!this.getDisplayValue()) {
      props.noValueText = I18n.t('base.labels.n_a');
    }

    return _.extend(props, { value: this.getDisplayValue(), level: this.getLevel() });
  }

  getComponent() {
    if (__guardMethod__(this.props.getModel(), 'isThiamisWifiSignal', (o) => o.isThiamisWifiSignal())) {
      return WifiSignalLevel;
    } return GsmSignalLevel;
  }

  getLevel() {
    const value = this.props.model != null ? this.props.getModel().get('value') : undefined;
    if (!value) { return 0; }
    let signalLevel = _.find(this.signalLevels, (level) => value >= level);
    signalLevel = _.indexOf(this.signalLevels, signalLevel);
    // eslint-disable-next-line no-bitwise
    if (~signalLevel) { return signalLevel + 1; } return 0;
  }

  getDisplayValue() {
    let networkName;
    const value = this.props.model != null ? this.props.getModel().get('value') : undefined;

    if (__guardMethod__(this.props.getModel(), 'isThiamisWifiSignal', (o) => o.isThiamisWifiSignal())) {
      networkName = this.getNetworkName('WIFI_NETWORK');
    } else { networkName = this.getNetworkName('GSM_NETWORK'); }

    if ((!_.isDefined(value) || !!networkName) && !_.isDefined(value)) { return; }
    return `${value}${this.props.model.unit || ''}`;
  }

  render() {
    return React.createElement(this.getComponent(), this.getProps());
  }
}

SignalLevel.defaultProps = {
  model: null,
  getModel: () => {}
};

SignalLevel.propTypes = {
  model: PropTypes.any,
  getModel: PropTypes.func
};

export default BackboneReactComponent(SignalLevel);
