import { createSelector } from 'reselect';
import * as fromGlobal from '~/store/reducers/global';

export const getGlobalOrganization = (state) => fromGlobal.getGlobalOrganization(state);
export const getCurrentUserId = (state) => fromGlobal.getCurrentUserId(state);

export const getGlobalOrganizationId = createSelector(
  getGlobalOrganization,
  (organization) => organization?.value
);
