import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  SortingState,
  DataTypeProvider,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow
} from '@devexpress/dx-react-grid-bootstrap3';
import ListTable from '~/components/list_table';

import { getFilteredList, getFilename } from '../selectors';
import { FilenameFormatter } from './filename_formatter';
import { DatetimeFormatter } from './datetime_formatter';

const FilenameProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={FilenameFormatter}
  />
);

const DatetimeProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={DatetimeFormatter}
  />
);

const columns = [
  { name: 'filename', title: 'File Name', getCellValue: getFilename },
  { name: 'from', title: 'Date From' },
  { name: 'to', title: 'Date To' },
  { name: 'organization_name', title: 'Uploaded By' }
];

const getRowId = ({ _id }) => _id;
const filenameColumns = ['filename'];
const dateColumns = ['to', 'from'];

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = () => {
  const [sorting, setSorting] = useState([]);

  const Row = React.memo((props) => (
    <VirtualTable.Row
      {...props}
      className={classNames('body-row')}
    />
  ));

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  const { rows } = useSelector(
    (state) => ({
      rows: getFilteredList(state)
    })
  );

  return (
    <div className="list-table-container react-list-container">
      <ListTable>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting />
          <DatetimeProvider
            for={dateColumns}
          />
          <FilenameProvider
            for={filenameColumns}
          />
          <VirtualTable
            rowComponent={Row}
          />
          <TableHeaderRow align="center" showSortingControls />
        </Grid>
      </ListTable>
    </div>
  );
};

export default GridContainer;
