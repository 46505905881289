import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import pick from 'lodash/pick';
import * as fromOrganizations from '~/store/selectors/organizations';
import { getOrganizationDeviceCounts } from '~/store/reducers/organizations';
import { getFilter } from './reducer';

const searchFields = ['name', 'external_id']; // eslint-disable-line max-len
export const getFilteredList = createSelector(
  [fromOrganizations.getMemberedOrganizations, getFilter],
  (organizations, query) => {
    const result = Object.values(organizations);
    if (!isEmpty(query)) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      return result.filter((organization) => {
        const searchString = Object.values(pick(organization, searchFields)).join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return result;
  }
);

export const getOrganizationByIdDeviceCounts = createSelector(
  getOrganizationDeviceCounts,
  (deviceCounts = []) => deviceCounts
);
