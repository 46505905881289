/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import I18n from 'app/config/i18n';
import { api as adminContextApi } from 'app/backbone/entities/designed_access_context';
import AppController from 'app/backbone/lib/controllers/app_controller';
import { NavigationLayout, NavigationList } from './list_view';
import DesignAccessContextForm from './design_access_context';

export default class ListController extends AppController {
  initialize({ navs, currentUser }) {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    this.navs = navs;
    this.currentUser = currentUser;
    this.layout = this.getNavigationLayout();
    this.layout.on('attach', () => {
      this.showNavigationList();
    });

    this.layout.on('switch:org', () => {
      this.showDialog(this.getDesignAccessContextForm(), {
        title: I18n.t('header.design_access_context')
      }
      );
    });

    this.layout.on('ask:support', () => {
      window.Intercom('show');
    });
    this.show(this.layout);
  }

  showNavigationList() {
    const listView = this.getNavigationListView();
    return this.layout.getRegion('listRegion').show(listView);
  }

  getNavigationLayout() {
    return new NavigationLayout({
      user: this.currentUser });
  }

  getNavigationListView() {
    return new NavigationList({
      collection: this.navs });
  }

  getDesignAccessContextForm() {
    return new DesignAccessContextForm({ model: adminContextApi.getChannel().request('get:admin_context') });
  }
}
