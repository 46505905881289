import React from 'react';

const SerialFormatter = ({ row: { serial } }) => (
  <>
    {serial}
    <a
      style={{
        padding: 5,
        paddingLeft: 10
      }}
      className="blue"
      target="_blank"
      rel="noreferrer"
      href={`https://blue.airthinx.io/${serial?.toLowerCase()}.html`}
    >
      <i className="fa fa-external-link" aria-hidden="true" />
    </a>
  </>
);

export default SerialFormatter;
