import React, { useEffect } from 'react';
import { app as App } from 'app/backbone/app';

const withBackboneNavChoose = (WrappedComponent) => (props) => {
  const { path } = props;
  useEffect(() => {
    App.getChannel().trigger('nav:choose', path.split('/')[2]);
  }, [path]);
  return <WrappedComponent {...props} />;
};

export default withBackboneNavChoose;
