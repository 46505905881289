import { call, delay, cancelled } from 'redux-saga/effects';
import { CancelToken } from 'axios';
import { api as auth0Api } from 'app/utils/auth0_handler'; // eslint-disable-line import/no-cycle
import request from '~/utils/axios';

export const NUMBER_OF_RETRIES = 3;

export function* parseError(error) {
  let parsed;

  try {
    parsed = yield error.response.json();
  } catch (err) {
    parsed = error.response
      ? { status: error.response.status, message: error.response.statusText }
      : { name: error.name, message: error.message };
  }

  return parsed;
}

export const isAccessExpired = (error = {}) => error.status === 403 || error.message === 'Forbidden';

export function* requestApiCall(method, url, params = null, options = {}) {
  const source = CancelToken.source();
  try {
    const { data } = yield call(request, method, url, params, { cancelToken: source.token, ...options });
    return data;
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export function* requestApiCallRetry(method, url, params = null, options = {}) { // eslint-disable-line consistent-return
  for (let i = 1; i <= NUMBER_OF_RETRIES; i += 1) {
    try {
      return yield call(requestApiCall, method, url, params, options);
    } catch (error) {
      if (i < NUMBER_OF_RETRIES) {
        yield delay(500);
      } else {
        throw new Error(error);
      }
    }
  }
}

export const refreshTokenRequest = () => (
  new Promise((resolve, reject) => {
    auth0Api.getChannel().request('get:instance').requestToken()
      .then((token) => resolve(token))
      .catch((error) => reject(error.error_description));
  })
);

export default function* requestApiSaga(method, url, params = null, options = {}) {
  try {
    return yield call(requestApiCall, method, url, params, options);
  } catch (e) {
    const { status, message } = yield call(parseError, e);
    if (isAccessExpired({ status, message })) {
      yield call(refreshTokenRequest);
      return yield call(requestApiCall, method, url, params, options);
    }
    if (status === 500) {
      return yield call(requestApiCallRetry, method, url, params, options);
    }
    throw new Error(e);
  }
}
