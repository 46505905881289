import React from 'react';
import { useSelector } from 'react-redux';
import { getUserByIdFullName } from '~/store/selectors/users';

export const NameFormatter = ({ row }) => {
  const userId = row?._id;
  const name = useSelector((state) => getUserByIdFullName(state, userId));
  return (
    <span className="userName">
      <span role="button">
        {name}
      </span>
    </span>
  );
};
