import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ParamInfoIcon from 'app/backbone/lib/react/sensors/paramInfoIcon/paramInfoIcon';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';

class BatteryLevel extends Component {
  getTitle() {
    const value = this.props.model && this.props.getModel().get('value');
    return this.props.title(value);
  }

  isUSB() {
    const value = this.props.model && this.props.getModel().get('value');
    return value >= 0 && value < 1.1;
  }

  getIconClass() {
    if (this.isUSB()) return 'fa fa-sm fa-usb';
    return `icon-battery-${this.getBatteryLevel()}`;
  }

  getBatteryDisplayValue() {
    const value = this.props.model && this.props.getModel().get('value');
    if (this.isUSB()) return '';
    if (!value) return this.props.noValueText;
    return `${value}${this.props.unit || 'V'}`;
  }

  getBatteryLevel() {
    const value = this.props.model && this.props.getModel().get('value');

    if (value >= 12) return '5';
    if ((value >= 11.5) && (value < 12)) return '4';
    if ((value >= 11) && (value < 11.5)) return '3';
    if ((value >= 10.6) && (value < 11)) return '2';
    return '1';
  }

  render() {
    if (this.props.getModel().get('value') == null) {
      return null;
    }
    return (
      <ParamInfoIcon
        value={this.props.model && this.props.getModel().get('value')}
        displayValue={this.getBatteryDisplayValue()}
        noValueText={this.props.noValueText}
        iconClass={this.getIconClass()}
        title={this.isUSB() ? 'USB' : `${this.getTitle()}${this.props.unit || 'V'}`}
      />
    );
  }
}

BatteryLevel.defaultProps = {
  model: {},
  unit: ''
};

BatteryLevel.propTypes = {
  title: PropTypes.func.isRequired,
  model: PropTypes.object,
  noValueText: PropTypes.string.isRequired,
  unit: PropTypes.string,
  getModel: PropTypes.func.isRequired
};

export default BackboneReactComponent(BatteryLevel);
