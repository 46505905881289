import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

export const setApisQuery = createAction('@@apis/SEARCH_REQUEST');
export const fetchAPIListRequest = createAction('@@apis/FETCH_REQUEST');
export const fetchAPIListSuccess = createAction('@@apis/FETCH_SUCCESS');
export const fetchAPIListFailure = createAction('@@apis/FETCH_FAILURE');

export const createAPITokenRequest = createAction('@@apis/CREATE_REQUEST');
export const createAPITokenSuccess = createAction('@@apis/CREATE_SUCCESS');
export const createAPITokenFailure = createAction('@@apis/CREATE_FAILURE');

export const deleteAPITokenRequest = createAction('@@apis/DELETE_REQUEST');
export const deleteAPITokenSuccess = createAction('@@apis/DELETE_SUCCESS');
export const deleteAPITokenFailure = createAction('@@apis/DELETE_FAILURE');

const ids = createReducer([], {
  [fetchAPIListSuccess](state, { payload: { result } }) {
    return result || state;
  },
  [deleteAPITokenSuccess](state, { payload: { id } }) {
    return state.filter((api) => api !== id);
  }
});

const byId = createReducer({}, {
  [fetchAPIListSuccess](state, { payload }) {
    const { entities: { apis } } = payload;
    if (apis) {
      return {
        ...state,
        ...apis
      };
    }
    return state;
  },
  [deleteAPITokenSuccess](state, { payload: { id } }) {
    return omit(state, [id]);
  }
});

const isFetched = createReducer(false, {
  [fetchAPIListRequest]() {
    return false;
  },
  [fetchAPIListFailure]() {
    return false;
  },
  [fetchAPIListSuccess]() {
    return true;
  }
});

const query = createReducer('', {
  [setApisQuery](state, { payload }) {
    return payload;
  }
});

const apis = combineReducers({
  ids,
  byId,
  isFetched,
  query
});

export const getAPIById = (state, id) => state.apis.byId[id];
export const getAPIs = (state) => state.apis.byId;
export const getAPIIds = (state) => state.apis.ids;
export const getIsFetched = (state) => state.apis.isFetched;
export const getAPIQuery = (state) => state.apis.query;

export default apis;
