import { createSelector } from 'reselect';
import * as fromWidgets from '~/store/reducers/widgets';

export const getWidgets = (state) => fromWidgets.getWidgets(state);
export const getWidgetById = (state, id) => fromWidgets.getWidgetById(state, id);

export const getWidgetDataPointIds = createSelector(
  getWidgetById,
  (widget = {}) => widget.selected_data_point_ids || []
);

export const getWidgetIsExpanded = createSelector(
  getWidgetById,
  (widget = {}) => widget.size === 1
);

export const getWidgetByIdName = createSelector(
  getWidgetById,
  (widget = {}) => widget.name
);
