import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '~/components/spinner';
import { getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { fetchNodesRequest, clearNodes } from '~/store/reducers/nodes';
import { getNodesIsFetched, hasNodes } from '~/store/selectors/nodes';
import ManageDevicesContext from './manage_context';
import { clearFilters } from './reducer';
import GridContainer from './grid_container';
import FiltersFormTop from './filters_form_top';
import FiltersSidebar from './filters_sidebar';

const DevicesScreen = React.memo((props) => {
  const { slug } = props;
  const [manageActive, setManageActive] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    dispatch(clearNodes());
    dispatch(fetchNodesRequest({ data }));
  }, [dispatch, slug]);
  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const isFetched = useSelector((state) => getNodesIsFetched(state) && getOrganizationsIsFetched(state));
  const nodesAvailable = useSelector((state) => hasNodes(state));

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <ManageDevicesContext.Provider value={{
      isActive: manageActive,
      setIsActive: setManageActive,
      selectedDevices,
      setSelectedDevices
    }}
    >
      <div className="content-region fill-height content filtered-content">
        <div className="nodes-container thiamises-container">
          <FiltersFormTop hasDevices={nodesAvailable} />
          <GridContainer slug={slug} />
          <FiltersSidebar />
        </div>
      </div>
    </ManageDevicesContext.Provider>
  );
});

DevicesScreen.propTypes = {
  slug: PropTypes.string.isRequired
};

export default DevicesScreen;
