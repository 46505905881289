import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  select,
  take
} from 'redux-saga/effects';
import * as schema from '~/store/schema';
import { normalizeResp } from '~/store/normalizr';
import requestApi from './requestApi';
import { getSharedDevices, getLeasedDevices } from '~/store/selectors/nodes';
import * as Actions from '~/store/reducers/partnerships';
import { fetchNodesRequest, fetchNodesSuccess } from '~/store/reducers/nodes';

export function* partnershipsFetchRequestSaga({ payload }) {
  try {
    let params = null;
    if (payload?.data?.partnership_ids) {
      const { data: { partnership_ids: partnershipIds = [] } } = payload;
      params = new URLSearchParams(partnershipIds.map((s) => ['partnership_ids[]', s]));
    }
    if (payload?.data?.organization_id) {
      const { data: { organization_id } } = payload;
      params = { organization_id };
    }
    const data = yield call(
      requestApi,
      'get',
      'partnerships',
      params
    );
    const response = normalizeResp(data, schema.partnershipsListSchema);
    yield put(Actions.fetchPartnershipsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchPartnershipsFailure(e.toString()));
  }
}

function* watchFetchPartnershipsRequest() {
  yield takeLatest(Actions.fetchPartnershipsRequest.toString(), partnershipsFetchRequestSaga);
}

export function* partnershipDetailsFetchRequestSaga({ payload = {} }) {
  const { organizationId, organization2Id, partnershipId } = payload;
  try {
    yield put(fetchNodesRequest({
      data: {
        organization_id: [organizationId]
      }
    }));
    yield take(fetchNodesSuccess.toString());
    const sharedDevices = yield select(getSharedDevices, organization2Id);
    const leasedDevices = yield select(getLeasedDevices, organization2Id);
    yield put(Actions.fetchPartnershipDetailsSuccess({ partnershipId, sharedDevices, leasedDevices }));
  } catch (e) {
    yield put(Actions.fetchPartnershipDetailsFailure({ partnershipId, e }));
  }
}

function* watchFetchPartnershipDetailsRequest() {
  yield takeEvery(Actions.fetchPartnershipDetailsRequest.toString(), partnershipDetailsFetchRequestSaga);
}

export function* destroyPartnershipRequestSaga({ payload }) {
  try {
    const { id, onSuccess } = payload;
    const { org1_id: organization_id, org2_external_id: external_id } = yield select(Actions.getPartnershipById, id);
    yield call(
      requestApi,
      'delete',
      'partnerships',
      {
        role: 'partner',
        organization_id,
        external_id
      }
    );
    yield put(Actions.destroyPartnershipSuccess(payload.id));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.destroyPartnershipFailure(e.toString()));
  }
}

function* watchDeletePartnershipsRequest() {
  yield takeEvery(Actions.destroyPartnershipRequest.toString(), destroyPartnershipRequestSaga);
}

export function* createPartnershipRequestSaga({ payload }) {
  try {
    const { external_id, organization_id, onSuccess } = payload;
    yield call(
      requestApi,
      'post',
      'partnerships',
      {
        role: 'partner',
        organization_id,
        external_id
      }
    );
    yield put(Actions.createPartnershipSuccess(payload.id));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.createPartnershipFailure(e.toString()));
  }
}

function* watchCreatePartnershipRequest() {
  yield takeEvery(Actions.createPartnershipRequest.toString(), createPartnershipRequestSaga);
}

export default function* app() {
  yield fork(watchFetchPartnershipsRequest);
  yield fork(watchDeletePartnershipsRequest);
  yield fork(watchCreatePartnershipRequest);
  yield fork(watchFetchPartnershipDetailsRequest);
}
