import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '~/components/spinner';
import { fetchNodesRequest } from '~/store/reducers/nodes';
import { getNodesIsFetched } from '~/store/selectors/nodes';
import {
  clearFilters, fetchCalibrationsRequest, getIsFetched
} from './reducer';
import GridContainer from './grid_container';
import FiltersFormTop from './filters_form_top';
import FiltersSidebar from './filters_sidebar';
import CalibrationModalContext from './manage_context';

const CalibrationScreen = React.memo((props) => {
  const { slug } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCalibrationsRequest());
  }, [dispatch]);

  useEffect(() => {
    const data = {};
    if (slug && slug !== 'all') {
      data.organization_id = slug;
    }
    dispatch(fetchNodesRequest({ data }));
  }, [dispatch, slug]);

  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const { isFetched, isNodesFetched } = useSelector(
    (state) => ({
      isFetched: getIsFetched(state),
      isNodesFetched: getNodesIsFetched(state)
    })
  );
  const [manageActive, setManageActive] = useState(false);
  const [selectedCalibrations, setSelectedCalibrations] = useState(null);

  if (!isFetched || !isNodesFetched) {
    return <Spinner />;
  }

  return (
    <CalibrationModalContext.Provider value={{
      selectedCalibrations,
      setSelectedCalibrations,
      isActive: manageActive,
      setIsActive: setManageActive
    }}
    >
      <div className="content-region fill-height content filtered-content">
        <div className="files-container">
          <FiltersFormTop />
          <GridContainer slug={slug} />
          <FiltersSidebar />
        </div>
      </div>
    </CalibrationModalContext.Provider>
  );
});

export default CalibrationScreen;
