/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import { AnalyticsModel } from 'app/backbone/entities/analytics';
import HeightFilling from 'app/backbone/lib/concerns/views/height_filling';
import layoutTmpl from './templates/layout.hbs';
import tabItemTmpl from './templates/tab_item.hbs';
import tabTmpl from './templates/tabs.hbs';
import tabContentTmpl from './templates/tab_content.hbs';
import tabItemEditingTmpl from './templates/tab_item_editing.hbs';
import tabsEmptyTmpl from './templates/tabs_empty.hbs';

const AnalyticsTabView = View.extend({
  getTemplate() {
    if (this.model.get('editing')) {
      return tabItemEditingTmpl;
    }
    return tabItemTmpl;
  },
  className: 'btn-tab btn-tab-editable',
  tagName: 'li',

  ui: {
    tabLink: 'a[role="tab"]',
    spanTitle: 'span.title',
    inputTitle: 'input.title'
  },

  events: {
    'shown.bs.tab': 'onChooseTab',
    'blur @ui.inputTitle': 'onBlur',
    'click @ui.spanTitle': 'onStartEditClick'
  },

  modelEvents: {
    'change:editing': 'onEditingChange',
    'change:name': 'onNameChange'
  },

  bindings: {
    '@ui.spanTitle': 'name',
    '@ui.inputTitle': {
      observe: 'name',
      events: ['blur']
    },
    '@ui.tabLink': {
      attributes: [{
        name: 'href',
        observe: '_id',
        onGet(id) {
          return `#report_${id}`;
        }
      }]
    },
    ':el': {
      attributes: [{
        name: 'class',
        observe: 'chosen',
        onGet(chosen) {
          if (chosen) { return `${this.className} active`; }
          return this.className;
        }
      }]
    }
  },

  onNameChange(model, name) {
    this.model.save({ name }, { silent: true });
  },

  onEditingChange(model, editing) {
    this.render();
    if (editing) {
      this.ui.inputTitle.focus();
    }
  },

  onBlur() {
    this.model.set('editing', false);
  },

  onStartEditClick(e) {
    if (this.model.isChosen()) {
      e.preventDefault();
      e.stopPropagation();
      this.model.set('editing', true);
    }
  },

  onChooseTab() {
    this.model.choose();
    App.getChannel().trigger('window:resized');
  },

  onRender() {
    this.stickit();
  }
});

export const AnalyticsTabsCollectionView = AppCollectionView.extend({
  template: tabTmpl,
  className: 'nav nav-tabs',
  tagName: 'ul',
  childView: AnalyticsTabView,

  ui: {
    addNew: 'a.btn-add-new'
  },

  triggers: {
    'click @ui.addNew': 'add:new'
  },

  onAddNew() {
    const model = new AnalyticsModel();
    this.collection.create(model);
    model.choose();
    model.set('editing', true);
  },

  attachHtml(els) {
    this.Dom.prependContents(this.el, els);
  }
});

AnalyticsTabsCollectionView.setDomApi({
  prependContents(el, els) {
    this.getEl(el).prepend(this.getEl(els));
  }
});

const AnalyticsTabContentView = View.extend({
  template: tabContentTmpl,
  className: 'tab-pane fade',

  regions: {
    widgetsRegion: '.widgets-region'
  },

  triggers: {
    'click #add_devices_comparison_widget': 'add:devices:comparison:widget',
    'click .btn-delete': 'remove:report'
  },

  bindings: {
    ':el': {
      attributes: [{
        name: 'class',
        observe: 'chosen',
        onGet(chosen) {
          if (chosen) { return `${this.className} active in`; }
          return this.className;
        }
      }, {
        name: 'id',
        observe: '_id',
        onGet(id) {
          return `report_${id}`;
        }
      }]
    },
    '.btn-expand': {
      attributes: [{
        name: 'data-parent',
        observe: '_id',
        onGet(id) {
          return `#report_${id}`;
        }
      }]
    }
  },

  onRemoveReport() {
    const index = this.model.collection.indexOf(this.model);
    const prevModel = this.model.collection.at(index - 1);
    this.model.destroy();
    if (prevModel) {
      return prevModel.choose();
    }
    this.model.collection.first().choose();
  },

  onRender() {
    this.stickit();
  }
});

const EmptyAnalyticsTabsContentCollectionView = View.extend({
  template: tabsEmptyTmpl
});

export const AnalyticsTabsContentCollectionView = AppCollectionView.extend({
  template: false,
  className: 'tab-content',
  childView: AnalyticsTabContentView,
  emptyView: EmptyAnalyticsTabsContentCollectionView
});

const Layout = View.extend({
  behaviors: [HeightFilling],
  template: layoutTmpl,
  regions: {
    controlsRegion: '.controls',
    tabsContentRegion: '.tabs-content-region'
  }
});

export default Layout;