import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getStats, getStatsIsFetched } from '~/store/selectors/stats';

const Stats = (props) => {
  const { stats, isFetched } = props;
  const isLoading = !isFetched;
  return (
    <div className="row">
      <div className="col-sm-4 fact-container">
        <div className={classNames('fact', { loading: isLoading })}>
          <span className="number">{stats['configured.devices']}</span>
          <span className="thiamises">
            <Trans i18nKey="dashboard.total_devices" />
          </span>
          <span className="icon">
            <i className="fa fa-fw fa-thiamis-1000-2" />
          </span>
        </div>
      </div>
      <div className="col-sm-4 fact-container">
        <div className={classNames('fact', { loading: isLoading })}>
          <span className="number">{stats['online.devices']}</span>
          <span>
            <Trans i18nKey="dashboard.devices_online" />
          </span>
          <span className="icon">
            <i className="fa fa-fw fa-node" />
          </span>
        </div>
      </div>
      <div className="col-sm-4 fact-container">
        <div className={classNames('fact', { loading: isLoading })}>
          <span className="number">{stats['alert.enabled']}</span>
          <span>
            <Trans i18nKey="dashboard.active_alerts" />
          </span>
          <span className="icon">
            <i className="fa fa-fw fa-warning" />
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stats: getStats(state),
  isFetched: getStatsIsFetched(state)
});

export default connect(mapStateToProps)(Stats);
