/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DeviceStatus from '~/components/device_status';
import {
  getDataPointById, getDataPointByIdConditions, getConditionName, getDataPointByIdLinked
} from '~/store/selectors/data_points';
import { getNodeByIdSerial } from '~/store/selectors/nodes';
import { getAlertByIdStatus, getAlertLastMeasurementValue, getAlertStatus } from '~/store/selectors/alerts';

const ThiamisTitle = (props) => {
  const {
    conditionName, statusType, t, nodeName
  } = props;
  return (
    <h4 className="device-name">
      <DeviceStatus statusText={t(`alerts.states.${statusType}`)} statusType={statusType} iconClassName="fa-lg" />
      <b className="truncated">{nodeName ? `[${nodeName}] ${conditionName}` : conditionName}</b>
    </h4>
  );
};

ThiamisTitle.propTypes = {
  conditionName: PropTypes.string.isRequired
};

const mapStateToProps = (state, { dataPointId, alertId }) => {
  const linkedDataPointId = getDataPointByIdLinked(state, dataPointId);
  const nodeName = linkedDataPointId?.map((dpId) => {
    const dataPoint = getDataPointById(state, dpId);
    return getNodeByIdSerial(state, get(dataPoint, 'node_id'));
  });
  const status = getAlertByIdStatus(state, alertId);
  const measurement = getAlertLastMeasurementValue(state, [dataPointId]);
  const conditions = getDataPointByIdConditions(state, dataPointId);
  return {
    conditionName: getConditionName(conditions, state),
    statusType: getAlertStatus(status, measurement),
    nodeName: [...new Set(nodeName)].join(', ')
  };
};

export default withTranslation()(connect(mapStateToProps)(ThiamisTitle));
