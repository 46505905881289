import React from 'react';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import I18n from 'app/config/i18n';

export const LastTriggeredFormatter = ({ row: { last_triggered } }) => (
  last_triggered && moment(last_triggered).isValid()
    ? <TimeAgo date={last_triggered} />
    : I18n.t('alerts.triggered_never')
);
