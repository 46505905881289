import React from 'react';
import { Title } from '../layout';
import {
  TitleContainer, ExpandButton
} from './styles';

const GroupTitle = ({ label, expanded, onClick }) => (
  <TitleContainer expanded={expanded} onClick={onClick}>
    <ExpandButton arrowDown={expanded} />
    <Title>{label}</Title>
  </TitleContainer>
);

export default GroupTitle;
