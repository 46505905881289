import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ParamInfoIcon from 'app/backbone/lib/react/sensors/paramInfoIcon/paramInfoIcon';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';

class LastUpdatedTs extends Component {
  componentDidMount() {
    this.interval = setInterval(() => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ updated: Date.now() });
    }, this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getDisplayValue() {
    if (this.props.getModel()) {
      const ts = this.props.getModel().get('timestamp');
      if (_.isUndefined(ts) || (ts === -Infinity)) return undefined;
      return jQuery.timeago(ts);
    }
  }

  render() {
    return (
      <ParamInfoIcon
        value={this.getDisplayValue()}
        displayValue={this.getDisplayValue()}
        iconClass="fa fa-fw fa-refresh"
        title={this.props.title}
        noValueText={this.props.noValueText}
      />
    );
  }
}

LastUpdatedTs.defaultProps = {
  title: '',
  interval: 3000,
  noValueText: ''
};

LastUpdatedTs.propTypes = {
  title: PropTypes.string,
  interval: PropTypes.number,
  noValueText: PropTypes.string,
  getModel: PropTypes.func.isRequired
};

export default BackboneReactComponent(LastUpdatedTs);
