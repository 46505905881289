/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { fetchReportsSuccess } from '~/store/reducers/reports';

export const updateWidgetRequest = createAction('@@widgets/UPDATE_REQUEST');
export const updateWidgetSuccess = createAction('@@widgets/UPDATE_SUCCESS');
export const updateWidgetFailure = createAction('@@widgets/UPDATE_FAILURE');

const byIdData = {};
const byId = createReducer(byIdData, {
  [updateWidgetSuccess](state, { payload }) {
    const { _id } = payload;
    if (_id) {
      return {
        ...state,
        [_id]: payload
      };
    }
    return state;
  },
  [fetchReportsSuccess](state, { payload }) {
    const { entities: { widgets } } = payload;
    if (widgets) {
      return widgets;
    }
    return state;
  }
});

const widgets = combineReducers({
  byId
});

export const getWidgets = (state) => state.widgets.byId;
export const getWidgetById = (state, id) => (id ? state.widgets.byId[id] : null);

export default widgets;
