import {
  call,
  all,
  put,
  fork,
  takeLatest,
  takeEvery,
  select,
  delay
} from 'redux-saga/effects';
import _ from 'lodash';
import identity from 'lodash/identity';
import { normalizeResp, normalizeDataPointsResp, normalizeNodesResp } from '~/store/normalizr';
import * as schema from '~/store/schema';
import requestApi from './requestApi';
import * as Actions from '~/store/reducers/nodes';
import { getDeviceById } from '~/store/reducers/devices';
import { getAqmsTemplateId } from '~/store/selectors/templates';
import { templatesFetchRequestSaga } from '~/store/sagas/templates';

export function* nodeFetchRequestSaga({ payload = { data: {} } }) {
  try {
    const data = yield call(
      requestApi,
      'post',
      'search/nodes',
      {
        ...payload.data
      }
    );
    const response = normalizeResp(normalizeNodesResp(data), schema.nodesListSchema);
    yield put(Actions.fetchNodeSuccess(response));
  } catch (e) {
    yield put(Actions.fetchNodeFailure(e.toString()));
  }
}

function* watchFetchNodeRequest() {
  yield takeLatest(Actions.fetchNodeRequest.toString(), nodeFetchRequestSaga);
}

export function* nodesFetchRequestSaga({ payload = { data: {} } }) {
  try {
    const data = yield call(
      requestApi,
      'post',
      'search/nodes',
      {
        type: 'thiamis',
        ...payload.data
      }
    );
    const response = normalizeResp(normalizeNodesResp(data), schema.nodesListSchema);
    yield put(Actions.fetchNodesSuccess(response));
  } catch (e) {
    yield put(Actions.fetchNodesFailure(e.toString()));
  }
}

function* watchFetchNodesRequest() {
  yield takeLatest(Actions.fetchNodesRequest.toString(), nodesFetchRequestSaga);
}

export function* dataPointsFetchRequestSaga({ payload }) {
  try {
    const params = _.chain(payload)
      .pick(['from', 'to', 'data_point_id', 'last', 'node_id'])
      .defaults({ last: 1 })
      .pickBy(identity)
      .value();
    if (params.from || params.to) {
      delete params.last;
    }
    const data = yield call(
      requestApi,
      'post',
      'search/data_points',
      params
    );
    const response = normalizeResp(normalizeDataPointsResp(data), schema.dataPointsSchema);
    yield put(Actions.fetchDataPointsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchDataPointsFailure(e.toString()));
  }
}

function* watchFetchDataPointsRequest() {
  yield takeLatest(Actions.fetchDataPointsRequest.toString(), dataPointsFetchRequestSaga);
}

export function* nodesDestroyRequestSaga({ payload: { nodeId, onSuccess } }) {
  try {
    const data = yield call(
      requestApi,
      'delete',
      `nodes/${nodeId}`
    );
    yield put(Actions.destroyNodesSuccess({ nodeId }));
    if (onSuccess) {
      onSuccess();
    }
    yield delay(1000);
    yield put(Actions.fetchNodeRequest({ data: { node_id: data._id, last: 1 } }));
  } catch (e) {
    yield put(Actions.destroyNodesFailure(nodeId));
  }
}

function* watchDestroyNodeRequest() {
  yield takeEvery(Actions.destroyNodesRequest.toString(), nodesDestroyRequestSaga);
}

export function* nodeShareRequestSaga({ payload: { nodeId, orgId, onSuccess } }) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${nodeId}/shares/${orgId}`
    );
    yield put(Actions.shareSuccess({ nodeId, orgId, node: response }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.shareFailure(e.toString()));
  }
}

function* watchNodeShareRequest() {
  yield takeEvery(Actions.shareRequest.toString(), nodeShareRequestSaga);
}

export function* nodeRemoveShareRequestSaga({ payload: { nodeId, orgId, onSuccess } }) {
  try {
    const response = yield call(
      requestApi,
      'delete',
      `nodes/${nodeId}/shares/${orgId}`
    );
    yield put(Actions.removeShareSuccess({ nodeId, orgId, node: response }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.removeShareFailure(e.toString()));
  }
}

function* watchNodeRemoveShareRequest() {
  yield takeEvery(Actions.removeShareRequest.toString(), nodeRemoveShareRequestSaga);
}

export function* nodeLeaseRequestSaga({ payload: { nodeId, orgId, onSuccess } }) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${nodeId}/lease/${orgId}`
    );
    yield put(Actions.leaseSuccess({ nodeId, orgId, node: response }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.leaseFailure(e.toString()));
  }
}

function* watchNodeLeaseRequest() {
  yield takeEvery(Actions.leaseRequest.toString(), nodeLeaseRequestSaga);
}

export function* nodeRemoveLeaseRequestSaga({ payload: { nodeId, onSuccess } }) {
  try {
    const response = yield call(
      requestApi,
      'delete',
      `nodes/${nodeId}/lease`
    );
    yield put(Actions.removeLeaseSuccess({ nodeId, node: response }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.removeLeaseFailure(e.toString()));
  }
}

function* watchNodeRemoveLeaseRequest() {
  yield takeEvery(Actions.removeLeaseRequest.toString(), nodeRemoveLeaseRequestSaga);
}

export function* nodeActivateRequestSaga({ payload: { nodeId, onSuccess } }) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${nodeId}/activate`
    );
    yield put(Actions.activateSuccess({ nodeId, node: response }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.activateFailure(e.toString()));
  }
}

function* watchNodeActivateRequest() {
  yield takeEvery(Actions.activateRequest.toString(), nodeActivateRequestSaga);
}

export function* nodeDeactivateRequestSaga({ payload: { nodeId, onSuccess } }) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${nodeId}/deactivate`
    );
    yield put(Actions.deactivateSuccess({ nodeId, node: response }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.deactivateFailure(e.toString()));
  }
}

function* watchNodeDeactivateRequest() {
  yield takeEvery(Actions.deactivateRequest.toString(), nodeDeactivateRequestSaga);
}

export function* nodeTransferRequestSaga({
  payload: {
    nodeId, orgId, billingId, onSuccess
  }
}) {
  try {
    const response = yield call(
      requestApi,
      'put',
      `nodes/${nodeId}/transfer/${orgId}`,
      { payment: billingId }
    );
    yield put(Actions.transferSuccess({
      nodeId, orgId, billingId, node: response
    }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.transferFailure(e.toString()));
  }
}

function* watchNodeTransferRequest() {
  yield takeEvery(Actions.transferRequest.toString(), nodeTransferRequestSaga);
}

export function* nodeClaimRequestSaga({
  payload: {
    serials, organization, onSuccess, onError
  }
}) {
  try {
    const response = yield call(
      requestApi,
      'post',
      'nodes/claim',
      { serials, organization_id: organization?.value }
    );
    yield put(Actions.claimSuccess({ devices: response }));
    if (onSuccess) {
      onSuccess(response, organization?.label);
    }
  } catch (e) {
    if (onError) {
      onError(e);
    }
    yield put(Actions.claimFailure(e.toString()));
  }
}

function* watchNodeClaimRequest() {
  yield takeEvery(Actions.claimRequest.toString(), nodeClaimRequestSaga);
}

export function* editNodeDeviceAutomationRequestSaga({ payload = {} }) {
  try {
    const {
      id, dfs, speed, automation, nodes, cron, onSuccess
    } = payload;

    const node = yield select(Actions.getNodeById, id);
    const device = yield select(getDeviceById, node.devices[0]);

    let templates = device.templates || [];
    if (automation) {
      yield call(templatesFetchRequestSaga);
      const templateId = yield select(getAqmsTemplateId);

      templates = [{
        _id: templateId,
        configuration: {
          node_id: nodes.map(({ value }) => value)
        }
      }];
    }

    const data = yield call(
      requestApi,
      'put',
      `nodes/${id}`,
      {
        ...node,
        devices: [{
          ...device,
          dfs,
          cron,
          speed,
          automation
        }],
        templates
      }
    );

    const response = normalizeResp(normalizeNodesResp([data]), schema.nodesListSchema);
    yield put(Actions.editNodeDeviceAutomationSuccess(response));

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.editNodeDeviceAutomationFailure(e.toString()));
  }
}

export function* editNodeRequestSaga({ payload = {} }) {
  try {
    const { id, content } = payload;

    const { data } = yield call(
      requestApi,
      'put',
      `nodes/${id}`,
      content
    );
    const response = normalizeResp(normalizeNodesResp([data]), schema.nodesListSchema);
    yield put(Actions.editNodeSuccess(response));
  } catch (e) {
    yield put(Actions.editNodeFailure(e.toString()));
  }
}

function* watchEditNodeRequest() {
  yield takeLatest(Actions.editNodeRequest.toString(), editNodeRequestSaga);
}

function* watchEditNodeDeviceAutomationReqeust() {
  yield takeLatest(Actions.editNodeDeviceAutomationRequest.toString(), editNodeDeviceAutomationRequestSaga);
}

export function* identifyWithLedSaga({ payload: { ids, onSuccess } }) {
  try {
    const { data } = yield all(ids.map((id) => call(
      requestApi,
      'put',
      `nodes/${id}/actions/led`,
      { value: 'FFFFFF1A' }
    )));
    yield put(Actions.identifyByLedSuccess(data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.identifyByLedFailure(e.toString()));
  }
}

function* watchNodesIdentifyWithLedRequest() {
  yield takeLatest(Actions.identifyByLedRequest.toString(), identifyWithLedSaga);
}

export default function* app() {
  yield fork(watchFetchNodesRequest);
  yield fork(watchFetchNodeRequest);
  yield fork(watchDestroyNodeRequest);
  yield fork(watchFetchDataPointsRequest);
  yield fork(watchNodeShareRequest);
  yield fork(watchNodeRemoveShareRequest);
  yield fork(watchNodeLeaseRequest);
  yield fork(watchNodeRemoveLeaseRequest);
  yield fork(watchNodeActivateRequest);
  yield fork(watchNodeDeactivateRequest);
  yield fork(watchNodeTransferRequest);
  yield fork(watchNodesIdentifyWithLedRequest);
  yield fork(watchNodeClaimRequest);
  yield fork(watchEditNodeRequest);
  yield fork(watchEditNodeDeviceAutomationReqeust);
}
