import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'app/config/i18n';
import classNames from 'classnames';

function __range__(left, right, inclusive) {
  const range = [];
  const ascending = left < right;
  // eslint-disable-next-line no-nested-ternary
  const end = !inclusive ? right : ascending ? right + 1 : right - 1;
  // eslint-disable-next-line no-plusplus
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}

class ListTablePagination extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      lastPage: this.getTotalPages(),
      pagesRange: __range__(0, this.getInitialEndRange(), true)
    };
  }

  componentWillUnmount = () => this.getCollection().off(null, this.onPageChange);

  componentDidMount = () => {
    this.getCollection().on('paginated:change:page', this.onPageChange);
    this.getCollection().setPerPage(this.props.perPage);
    return this.getCollection().on('add destroy reset', this.onPageChange);
  };

  getInitialEndRange = () => {
    if (this.props.pagesLength > this.getTotalPages()) { return this.getTotalPages() - 1; } return this.props.pagesLength - 1;
  };

  render = () => {
    if (this.props.perPage >= this.getCollection().getFilteredLength()) { return React.createElement('div'); }
    return (
      <nav className="text-center">
        <ul className="pagination pagination-sm pagination-expanded">
          <li className={classNames({ disabled: !this.getCollection().hasPrevPage() })} title={I18n.t('base.pagination.prev_page')}>
            <a role="button" tabIndex={0} onClick={_.partial(this.handlePageClick, this.prevPageNum())}>
              <i className="fa fa-sm fa-angle-left" />
              Previous
            </a>
          </li>
          { this.createPagesRanges() }
          <li className={classNames({ disabled: !this.getCollection().hasNextPage() })} title={I18n.t('base.pagination.next_page')}>
            <a role="button" tabIndex={0} onClick={_.partial(this.handlePageClick, this.nextPageNum())}>
              Next
              <i className="fa fa-sm fa-angle-right" />
            </a>
          </li>
        </ul>
      </nav>
    );
  };

  createPagesRanges = () => {
    const { pagesRange } = this.state;
    if (!_.contains(pagesRange, this.firstPageNum())) {
      pagesRange.splice(0, 0, this.firstPageNum());
      pagesRange.splice(1, 0, 'empty_first');
    }
    if (!_.contains(pagesRange, this.state.lastPage - 1)) {
      pagesRange.push('empty_last');
      pagesRange.push(this.state.lastPage - 1);
    }
    return _.map(_.uniq(pagesRange), (page) => {
      if (_.isNumber(page)) {
        return this.createPage(page);
      } return this.createPageSeparator(page);
    });
  };

  createPageSeparator = (page) => (
    <li className="disabled" key={page}>
      <a>...</a>
    </li>
  );

  createPage = (page) => {
    if (page === this.getTotalPages()) { return; }
    return (
      <li key={page} className={classNames({ active: page === this.getCurrentPage() })}>
        <a role="button" tabIndex={0} onClick={_.partial(this.handlePageClick, page)}>{page + 1}</a>
      </li>
    );
  };

  getCollection = () => this.props.collection;

  getCurrentPage = () => this.getCollection().getPage();

  getTotalPages = () => this.getCollection().getNumPages();

  handlePageClick = (page, event) => {
    event.preventDefault();
    event.stopPropagation();
    if ((page === this.getCurrentPage()) || (page === -1)) { return; }
    return this.getCollection().setPage(page);
  };

  nextPageNum = () => {
    if (!this.getCollection().hasNextPage()) { return -1; }
    return this.getCurrentPage() + 1;
  };

  prevPageNum = () => this.getCurrentPage() - 1;
  firstPageNum = () => 0;

  isFirstPage = () => this.getCurrentPage() === this.firstPageNum();

  isLastPage = () => this.getCurrentPage() === this.state.lastPage;

  onPageChange = () => this.setState({
    lastPage: this.getTotalPages(),
    pagesRange:
        (() => {
          if (this.isFirstPage()) {
            return __range__(0, this.getInitialEndRange(), true);
          } if (this.isLastPage()) {
            if (this.getTotalPages() > this.props.pagesLength) {
              return __range__(this.getTotalPages() - this.props.pagesLength, this.getTotalPages() - 1, true);
            }
            return __range__(0, this.getTotalPages() - 1, true);
          }
          let startIndex = 0;
          const pagesMid = this.props.pagesLength / 2;
          if (this.getCurrentPage() >= pagesMid) {
            if ((this.getTotalPages() - this.getCurrentPage()) > pagesMid) {
              startIndex = this.getCurrentPage() - (pagesMid - 1);
            } else if (this.getTotalPages() > this.props.pagesLength) {
              startIndex = this.getTotalPages() - this.props.pagesLength;
            }
          }
          return __range__(startIndex, (startIndex + (this.getTotalPages() < this.props.pagesLength ? this.getTotalPages() : this.props.pagesLength)) - 1, true);
        })() });
}

ListTablePagination.propTypes = {
  collection: PropTypes.any.isRequired,
  perPage: PropTypes.number,
  pagesLength: PropTypes.number
};

ListTablePagination.defaultProps = {
  pagesLength: 6,
  perPage: 25
};

export default ListTablePagination;
