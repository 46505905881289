/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const filterHeatmapWidget = createAction('@@heatmapWidget/FILTER_BY');

const byIdData = {};
const byId = createReducer(byIdData, {
  [filterHeatmapWidget](state, { payload: { widgetId, filter } }) {
    if (widgetId) {
      state[widgetId] = {
        filter
      };
    }
  }
});

const heatmapWidget = combineReducers({
  byId
});

export const getHeatmapWidgetById = (state, id) => (id ? state.heatmapWidget.byId[id] : null);

export default heatmapWidget;
