/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Behavior } from 'backbone.marionette';
import { NodeSelect } from 'app/backbone/lib/components/shared_views/widget_node_select';
import { app as App } from 'app/backbone/app';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';

export default Behavior.extend({
  showNodeSelect() {
    return this.view.getRegion('nodeSelectRegion').show(this.getNodeSelect());
  },

  getNodeSelect() {
    return this.nodeSelect != null ? this.nodeSelect : (this.nodeSelect = new NodeSelect(_(this.view).pick('model', 'thiamises')));
  },

  onRender() {
    App.getChannel().request('when:fetched', this.view.thiamises, () => {
      if (this.view.model.get('_type') === 'HeatmapWidget') {
        this.view.thiamises = new FilteredCollection(this.view.thiamises);
        this.view.thiamises.filterBy((thiamis) => thiamis.isAirthinx());
      }
      this.showNodeSelect();
    });
  }
});
