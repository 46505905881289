import React from 'react';
import { connect } from 'react-redux';
import { getIds } from '~/store/reducers/nodes';
import {
  getNodeStatusFilterTags, getNodeDevicesFilterTags, getNodeSensorsFilterTags, getNodePartnersFilterTags
} from '../selectors';
import FiltersSidebarPanel from './filters_sidebar_panel';
import { getOrganizations } from '~/store/reducers/organizations';

const statesOrder = ['shared', 'leased', 'activated', 'online', 'offline', 'hibernated', 'deactivated'];

const FiltersSidebar = React.memo((props) => {
  const {
    states, devices, sensors, partners, organizations, all
  } = props;
  return (
    <div className="filters-region right-panel">
      <div className="fill-height">
        <div className="filter-groups-list-region">
          <div id="filters-wrap" className="panel-group collapse in" aria-expanded="true">
            <FiltersSidebarPanel all={all} filters={states} group="states" order={statesOrder} collapsedIn />
            <FiltersSidebarPanel filters={devices} group="devices" />
            <FiltersSidebarPanel filters={sensors} group="sensors" />
            <FiltersSidebarPanel filters={partners} group="partners" mapper={(type) => organizations[type] && organizations[type].name} />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  all: getIds(state).length,
  organizations: getOrganizations(state),
  states: getNodeStatusFilterTags(state),
  sensors: getNodeSensorsFilterTags(state),
  partners: getNodePartnersFilterTags(state),
  devices: getNodeDevicesFilterTags(state)
});

export default connect(mapStateToProps)(FiltersSidebar);
