import React, {
  useState, useCallback
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  RowDetailState,
  DataTypeProvider,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow, TableRowDetail
} from '@devexpress/dx-react-grid-bootstrap3';
import ListTable from '~/components/list_table';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from '~/components/react_grid/expand_button';
import { getFilteredList } from '../selectors';
import GridDetailContainer from './detail_row';
import SerialFormatter from './serial_formatter';

const SerialProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={SerialFormatter}
    className="name-cell"
  />
);

const columns = [
  { name: 'serial', title: 'S/N' },
  { name: 'name', title: 'Name' },
  { name: 'description', title: 'Description' }
];

const getRowId = ({ _id }) => _id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = ({ slug }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'serial', direction: 'desc' }]);

  const Row = React.memo((props) => {
    const { row: { _id: nodeId } } = props;
    const isExpanded = includes(expandedRowIds, nodeId);

    const handleExpandRowClick = useCallback(
      () => {
        if (includes(expandedRowIds, nodeId)) {
          setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== nodeId));
        } else {
          setExpandedRowIds([...expandedRowIds, ...[nodeId]]);
        }
      },
      [nodeId]
    );
    return (
      <VirtualTable.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);
  const { rows } = useSelector(
    (state) => ({
      rows: getFilteredList(state, slug)
    })
  );

  return (
    <div className="list-table-container react-list-container">
      <ListTable className="certificates-table">
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting />
          <VirtualTable
            rowComponent={Row}
          />
          <SerialProvider
            for={['serial']}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <TableHeaderRow align="center" showSortingControls />
          <TableRowDetail
            contentComponent={GridDetailContainer}
            toggleCellComponent={ExpandButton}
          />
          <DetailRowColumnOrder />
        </Grid>
      </ListTable>
    </div>
  );
};

export default GridContainer;
