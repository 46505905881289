import React, { useState, useCallback } from 'react';
import {
  SortingState, IntegratedSorting, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable, TableHeaderRow
} from '@devexpress/dx-react-grid-bootstrap3';
import Spinner from '~/components/spinner';
import { TriggeredFormatter } from '../triggered_formatter';

const TriggeredProvider = (props) => (
  <DataTypeProvider
    formatterComponent={TriggeredFormatter}
    {...props}
  />
);

const getRowId = ({ triggered }) => triggered;
const triggeredColumns = ['triggered'];

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container" />;

const MeasurementsTable = ({ rows, columns }) => {
  const [sorting, setSorting] = useState([{ columnName: 'triggered', direction: 'desc' }]);

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);
  return (
    <div className="list-table-container react-list-container">
      <div className="list-table">
        { rows.length
          ? (
            <Grid
              rows={rows}
              columns={columns}
              getRowId={getRowId}
              rootComponent={Root}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={handleSortingChange}
              />
              <IntegratedSorting />
              <TriggeredProvider
                for={triggeredColumns}
              />
              <VirtualTable />
              <TableHeaderRow align="center" showSortingControls />
            </Grid>
          )
          : (<Spinner className="react-modal-spinner" />) }
      </div>
    </div>
  );
};

export default MeasurementsTable;
