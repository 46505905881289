import React, { useCallback, useState } from 'react';
import I18n from 'app/config/i18n';
import { getAuthToken } from '~/services/auth/selectors';
import DownloadForm from '~/components/download_form';

export const FilenameFormatter = ({ row = {}, value }) => {
  const { _id } = row;
  const [downloadModel, setDownloadModel] = useState(null);

  const handleDownload = useCallback(async () => {
    const token = await getAuthToken();
    const model = {
      filename: value,
      token
    };
    setDownloadModel(model);
  }, [value]);

  return (
    <>
      <button
        title={I18n.t('base.buttons.download')}
        type="button"
        tabIndex={0}
        className="blue"
        onClick={handleDownload}
      >
        { value }
      </button>
      {
        !!downloadModel && (
          <DownloadForm
            model={downloadModel}
            method="POST"
            actionUrl={`snapshots/${_id}.csv`}
          />
        )
      }
    </>
  );
};
