import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import I18n from 'app/config/i18n';
import * as fromUsers from '~/store/reducers/users';
import { getMemberedOrganizations, getOrganizationsList } from '~/store/selectors/organizations';

export const getUserById = (state, id) => fromUsers.getUserById(state, id);
export const getUserIsSynced = (state) => fromUsers.getUserIsSynced(state);
export const getUserIsSyncing = (state) => fromUsers.getUserIsSyncing(state);

export const getUserMetadata = (profile) => profile?.user_metadata || {};
export const getAppMetadata = (profile) => profile?.app_metadata || {};

export const getUserByIdUserMetadata = createSelector(
  getUserById,
  getUserMetadata
);

export const getUserByIdAppMetadata = createSelector(
  getUserById,
  getAppMetadata
);

export function getUserFirstName(userMetadata = {}) {
  return userMetadata.given_name;
}

export function getUserLastName(userMetadata = {}) {
  return userMetadata.family_name;
}

export const getUserByIdUserId = createSelector(
  getUserById,
  (user = {}) => user?.user_id
);

export const getUserByIdFullName = createSelector(
  getUserByIdUserMetadata,
  (userMetadata = {}) => {
    const firstName = getUserFirstName(userMetadata);
    const lastName = getUserLastName(userMetadata);
    return (firstName && lastName) && `${firstName} ${lastName}`;
  }
);

export const getUserByIdNickname = createSelector(
  getUserByIdUserMetadata,
  (userMetadata = {}) => {
    const { given_name, nickname } = userMetadata;
    return given_name || nickname;
  }
);

export const getUserByIdEmail = createSelector(
  getUserById,
  (profile) => profile?.email
);

export const getUserByIdRoles = createSelector(
  getUserByIdAppMetadata,
  (metadata) => metadata?.roles
);

export const getUserByIdHasEmptyRoles = createSelector(
  getUserByIdRoles,
  (roles) => isEmpty(roles)
);

export const getUserOrganizationIdWithHighestRole = createSelector(
  getUserByIdRoles,
  (roles) => {
    if (roles?.support?.length) {
      return {
        organizationId: roles?.support[0],
        role: 'support'
      };
    }
    if (roles?.owner?.length) {
      return {
        organizationId: roles?.owner[0],
        role: 'owner'
      };
    }
    if (roles?.manager?.length) {
      return {
        organizationId: roles?.manager[0],
        role: 'manager'
      };
    }
    return {
      organizationId: roles?.user[0],
      role: 'user'
    };
  }
);

export function hasAdminRole(metadata = {}) {
  return metadata.admin;
}

export function hasSupportRole(metadata = {}) {
  return metadata.support || !!(metadata.roles?.support?.length);
}

export function hasOwnerRole(metadata = {}) {
  return metadata.owner || !!(metadata.roles?.owner?.length);
}

export function isOwnerOrManager(metadata = {}) {
  const { roles } = metadata;
  return !!(roles?.owner?.length || roles?.manager?.length);
}

export function isOwnerOrManagerInOrganization(metadata = {}, organizationId) {
  const { roles } = metadata;
  return !!(roles?.owner?.includes(organizationId) || roles?.manager?.includes(organizationId));
}

export const getUserIsAdmin = (metadata) => hasAdminRole(metadata);
export const getUserIsSupport = (metadata) => hasAdminRole(metadata) || hasSupportRole(metadata);
export const getUserIsManager = (metadata) => getUserIsSupport(metadata) || isOwnerOrManager(metadata);
export const getUserIsOwner = (metadata) => getUserIsSupport(metadata) || hasOwnerRole(metadata);

export const getUserByIdIsAdmin = createSelector(
  getUserByIdAppMetadata,
  getUserIsAdmin
);

export const getIsUserSupport = createSelector(
  getUserByIdAppMetadata,
  getUserIsSupport
);

export const getUserByIdIsManager = createSelector(
  getUserByIdAppMetadata,
  getUserIsManager
);

export const getIsUserOwner = createSelector(
  getUserByIdAppMetadata,
  (metadata) => getUserIsSupport(metadata) || hasOwnerRole(metadata)
);

export const getUserByIdIsRentalManager = createSelector(
  getUserByIdAppMetadata, (state, userId, rentalOrgIds) => rentalOrgIds,
  (metadata = {}, rentalOrgIds = []) => {
    if (hasAdminRole(metadata) || hasSupportRole(metadata)) {
      return true;
    }
    const { roles: { manager = [], owner = [] } } = metadata;
    return !!intersection(flatten([manager, owner]), rentalOrgIds).length;
  }
);

export const getUserByIdPicture = createSelector(
  [getUserById, getUserByIdUserMetadata],
  (profile, userMetadata) => userMetadata?.upload_picture
);

export const getUserByIdInitials = createSelector(
  getUserByIdUserMetadata,
  (userMetadata) => {
    const firstName = getUserFirstName(userMetadata);
    const lastName = getUserLastName(userMetadata);
    return `${(firstName || '')[0] || ''}${(lastName || '')[0] || ''}`;
  }
);

export function getUserOrganizationsMapping(roles = {}, organizations) {
  const result = [];
  roles.owner?.forEach((organizationId) => {
    const organization = organizations?.find(({ id }) => id === organizationId);
    result.push({
      organizationId,
      organizationName: `${organization?.name} [${organization?.external_id}]`,
      role: 'owner'
    });
  });
  roles.manager?.forEach((organizationId) => {
    const organization = organizations?.find(({ id }) => id === organizationId);
    result.push({
      organizationId,
      organizationName: `${organization?.name} [${organization?.external_id}]`,
      role: 'manager'
    });
  });
  roles.user?.forEach((organizationId) => {
    const organization = organizations?.find(({ id }) => id === organizationId);
    result.push({
      organizationId,
      organizationName: `${organization?.name} [${organization?.external_id}]`,
      role: 'user'
    });
  });
  return result;
}

export function getUserOrganizationsMappingForOwners(roles = {}, organizations) {
  const result = [];
  roles.owner?.forEach((organizationId) => {
    const organization = organizations?.find(({ id }) => id === organizationId);
    result.push({
      organizationId,
      organizationName: `${organization?.name} [${organization?.external_id}]`,
      role: 'owner'
    });
  });
  return result;
}

export const getUserByIdIsModifiable = createSelector(
  [getUserByIdRoles, (state, userId, otherRoles) => otherRoles],
  (roles, otherRoles) => {
    if (!isEmpty(intersection(otherRoles.manager, roles.user))) { return true; }
    if (!isEmpty(intersection(otherRoles.owner, roles.user))
      || !isEmpty(intersection(otherRoles.owner, roles.manager))) { return true; }
    return false;
  }
);

export const getUserOrganizationRolesForInviting = createSelector(
  getUserByIdRoles,
  getMemberedOrganizations,
  getUserOrganizationsMapping
);

export const getUserOrganizationRolesForAPI = createSelector(
  getUserByIdRoles,
  getMemberedOrganizations,
  getUserOrganizationsMappingForOwners
);

export const getUserByIdAbilities = createSelector(
  [getUserByIdIsManager, getUserByIdHasEmptyRoles],
  (isManager, hasEmptyRoles) => ({
    organization: {
      creatable: true,
      fetchable: true
    },
    partnership: {
      creatable: false,
      fetchable: isManager || hasEmptyRoles
    },
    partnership_invitation: {
      creatable: isManager || hasEmptyRoles,
      fetchable: isManager || hasEmptyRoles
    }
  })
);

export const getUserByIdRolesOrganizationsValueLabelPairs = createSelector(
  getUserByIdRoles,
  getOrganizationsList,
  (roles, organizations) => (roles
    ? Object.entries(roles)
      .map(([role, orgIds]) => orgIds
        .map((orgId) => {
          const organization = organizations.find(({ _id: id }) => id === orgId);
          return {
            deleted: false,
            role: { label: I18n.t(`users.roles.${role}`), value: role },
            organization: { label: organization?.name, value: orgId, external_id: organization?.external_id }
          };
        }))
      .flat()
      .filter(({ organization }) => organization.label)
    : [])
);

export const getIsManagerInOrganization = createSelector(
  [getUserByIdAppMetadata, (state, userId, organizationId) => organizationId],
  (appMetadata, organizationId) => getUserIsSupport(appMetadata) || isOwnerOrManagerInOrganization(appMetadata, organizationId)
);
