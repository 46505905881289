export default class Storage {
  static prefix = 'environet'

  static setItem(key, value) {
    localStorage.setItem(Storage.getPath(key), JSON.stringify(value));
  }

  static removeItem(key) {
    localStorage.removeItem(Storage.getPath(key));
  }

  static getItem(key) {
    const value = localStorage.getItem(Storage.getPath(key));
    try {
      if (value != null) {
        return JSON.parse(value);
      }
    } catch (e) {
      throw new Error('Error parsing storage item', e);
    }
  }

  static getPath(key) {
    return `${Storage.prefix}.${key}`;
  }
}