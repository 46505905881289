/* eslint-disable no-param-reassign */
import { api as navApi } from 'app/backbone/entities/abstract/nav';
import { api as userApi } from 'app/backbone/entities/user';
import { api as organizationApi } from 'app/backbone/entities/organization';
import { api as segmentApi } from 'app/backbone/lib/utilities/segment_analytics';
import { api as datadogApi } from 'app/backbone/lib/utilities/datadog_analytics';
import { app as App } from 'app/backbone/app';

App.on('before:start', (app, data) => {
  const { currentUser } = data;
  app.environment = data.environment;
  app.locales = data.locales;
  app.accessContext = data.accessContext;
  app.userNotices = data.userNotices;
  app.clusternetUrl = data.clusternetUrl;
  app.clusternetWSUrl = data.clusternetWSUrl;
  app.defaultOrgName = data.defaultOrgName;
  app.validations = data.validations;
  app.intercomIdentity = data.intercomIdentity;
  app.currentUser = userApi.getChannel().request('new:current_user', currentUser);
  app.organizations = organizationApi.getChannel().request('new:organizations', []);
  segmentApi.getChannel().request('segment:analytics:identify', app.currentUser, {
    Intercom: {
      user_hash: app.intercomIdentity
    }
  });
  datadogApi.getChannel().request('datadog:analytics:identify', app.currentUser);
  app.getChannel().request('error:reporting:identify', app.currentUser);
  app.navs = navApi.getChannel().request('get:navs');
  app.topNavs = navApi.getChannel().request('get:top:navs');
  app.sideNavs = navApi.getChannel().request('get:side:navs');
});
