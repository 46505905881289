import { app as App } from 'app/backbone/app';
import { api as auth0Api } from 'app/utils/auth0_handler'; // eslint-disable-line import/no-cycle

(function ($) {
  $(document).ajaxError(async (event, xhr, settings, thrownError) => {
    if (xhr.statusText !== 'abort') {
      if (xhr.status === 403) {
        try {
          await auth0Api.getChannel().request('get:instance').requestToken();
        } catch (e) {
          App.getChannel().trigger('handle:error:response', xhr, { e, settings });
        }
      } else {
        App.getChannel().trigger('handle:error:response', xhr, { thrownError, settings });
      }
    }
  });
}(jQuery));