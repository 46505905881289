import { createAPITokenRequest } from 'app/react/store/reducers/apis';
import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '~/components/modal';
import OrganizationDropdown from './organization_dropdown';

const CreateAPITokenModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.api_token'),
      { toastId: 'success_api_token' }
    );
    toggleModal();
  }, [toggleModal, t]);

  const handleAction = useCallback((values) => {
    const { organization } = values;
    dispatch(createAPITokenRequest({
      organization: organization.value,
      onSuccess: handleSuccess
    }));
  }, [
    dispatch, handleSuccess
  ]);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={toggleModal}
      title={t('api.create')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleAction}
          subscription={{ submitting: true, pristine: true, valid: true }}
        >
          {({ handleSubmit, valid }) => (
            <div className="form form-horizontal profile">
              <OrganizationDropdown />
              <div className="form-group form-actions clearfix">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={toggleModal}
                >
                  {t('base.buttons.close')}
                </button>
                <button
                  disabled={!valid}
                  type="submit"
                  onClick={handleSubmit}
                  className={`save-form btn btn-primary ${!valid ? 'btn-disabled' : ''}`}
                >
                  {t('base.buttons.save')}
                </button>
              </div>
            </div>
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default CreateAPITokenModal;
