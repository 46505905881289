import React from 'react';
import I18n from 'app/config/i18n';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import withTooltip from '~/hocs/withTooltip';

export const ChannelFormatter = ({ value }) => {
  if (isEmpty(value)) {
    return I18n.t('base.labels.n_a');
  }
  return value;
};

export const UnitsFormatter = ({ value, row: { status } }) => {
  const count = value.length;
  if (count === 0 || status !== 'available') return count;
  const IconTooltip = ({ tooltipRef }) => (
    <a ref={tooltipRef} data-tip={value.join(', ')} type="button">
      <span className="badge">{count}</span>
    </a>
  );

  const Tooltip = withTooltip(IconTooltip);

  return (
    <Tooltip />
  );
};

export const StatusFormatter = ({ value }) => startCase(value);
