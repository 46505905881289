/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { prependIndexUrl } from 'app/utils/custom-fns';
import mcluster_0Svg from 'images/mcluster_0.svg';
import mcluster_1Svg from 'images/mcluster_1.svg';
import mcluster_2Svg from 'images/mcluster_2.svg';
import mcluster_3Svg from 'images/mcluster_3.svg';
import mcluster_4Svg from 'images/mcluster_4.svg';
import markerActivated from 'images/marker_activated.png';
import markerHibernated from 'images/marker_hibernated.png';
import markerDeactivated from 'images/marker_deactivated.png';
import markerOffline from 'images/marker_offline.png';

export default {
  getClusterStyles() {
    return [
      {
        height: 44,
        url: mcluster_0Svg,
        width: 44,
        textColor: '#fff',
        textSize: 12
      },
      {
        height: 53,
        url: mcluster_1Svg,
        width: 53,
        textColor: '#fff',
        textSize: 13
      },
      {
        height: 56,
        url: mcluster_2Svg,
        width: 56,
        textColor: '#fff',
        textSize: 14
      },
      {
        height: 66,
        url: mcluster_3Svg,
        width: 66,
        textColor: '#fff',
        textSize: 15
      },
      {
        height: 78,
        url: mcluster_4Svg,
        width: 78,
        textColor: '#fff',
        textSize: 16
      }
    ];
  },

  getMarkerOptions(map, type) {
    const { MarkerImage, Size } = map;
    const iconfile = (() => {
      switch (type) {
        case 'activated': return prependIndexUrl(markerActivated);
        case 'hibernated': return prependIndexUrl(markerHibernated);
        case 'deactivated': return prependIndexUrl(markerDeactivated);
        case 'offline': return prependIndexUrl(markerOffline);
        default: return '';
      }
    })();
    const icon = new MarkerImage(iconfile, null, null, null, new Size(24, 36));
    return {
      flat: true,
      clickable: true,
      icon,
      iconfile
    };
  }
};
