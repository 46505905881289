/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';

class Router extends AppRouter {
  chooseNav() {
    return App.getChannel().trigger('nav:choose', 'user_file');
  }
}

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:user_files': 'showUserFiles'
  },

  showUserFiles() {
    Router.prototype.before();
    App.navigate('files');
    App.getChannel().request('enable:org_filter', true);
  }
});

export const api = new API();
