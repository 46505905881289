/* eslint-disable */

import 'backbone-validation';

_.extend(Backbone.Model.prototype, Backbone.Validation.mixin);

(Backbone => Backbone.Model.prototype._validate = function (attrs, options) {
  let error;
  if (!options.validate || !this.validate) {
    return true;
  }
  if (options.validateAll !== false) {
    attrs = _.extend({}, this.attributes, attrs);
  }
  error = this.validationError = this.validate(attrs, options) || null;
  if (!error) {
    return true;
  }
  this.trigger('invalid', this, error, _.extend(options || {}, {
    validationError: error
  }));
  return false;
})(Backbone);
