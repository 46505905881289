import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { RemoveIconContainer, Row, RoleBlock } from './styles';

const RolesField = (props) => {
  const { t } = useTranslation();
  const {
    fields: {
      name,
      value: values
    }
  } = props;

  return (
    <>
      {values?.map(({ role, organization, deleted }, index) => (
        <Row
          deleted={deleted}
          // eslint-disable-next-line react/no-array-index-key
          key={`${role.label}_${organization.value}`}
        >
          <RoleBlock>
            {`${t(`users.roles.${role.label}_at`)}`}
            <b>{organization.label}</b>
            {` [${organization.external_id}]`}
          </RoleBlock>
          <Field
            name={`${name}[${index}].deleted`}
          >
            {({ input: { value, onChange } }) => (
              <RemoveIconContainer onClick={() => onChange(!value)}>
                {!value ? (
                  <i className="fa fa-lg fa-fw fa-minus-circle" />
                ) : (
                  <i className="fa fa-lg fa-fw fa-undo" />
                )}
              </RemoveIconContainer>
            )}
          </Field>
        </Row>
      ))}
    </>
  );
};

export default RolesField;
