/* eslint-disable no-param-reassign */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { app as App } from 'app/backbone/app';
import AppModel from 'app/backbone/lib/entities/app_model';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import ValueLabelPairs from 'app/backbone/lib/concerns/entities/value_label_pairs';

export class Node extends ClusternetBridge(AppModel) {
  urlRoot() {
    return this.clusternet.nodesUrl();
  }

  share({ organization_id }) {
    if (!this.isShareable()) { return; }
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.shareNodesUrl(this.id, organization_id),
      success: (resp) => this.set(_.omit(resp, '_id'))
    });
  }

  payment_update({ organization_id }) {
    if (!this.isPaymentable()) { return; }
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.paymentUpdateUrl(this.id, organization_id),
      success: (resp) => this.set(resp)
    });
  }

  lease({ organization_id }) {
    if (!this.isLeaseable()) { return; }
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.leaseNodesUrl(this.id, organization_id),
      success: (resp) => this.set(resp)
    });
  }

  remove_lease() {
    if (!this.hasAction('remove_lease')) { return; }
    return $.ajax({
      type: 'DELETE',
      url: this.clusternet.removeLeaseNodesUrl(this.id),
      success: (resp) => {
        resp = _.defaults(resp, this.defaults);
        return this.set(resp);
      }
    });
  }

  remove_share({ organization_id }) {
    if (!this.hasAction('remove_share')) { return; }
    return $.ajax({
      type: 'DELETE',
      url: this.clusternet.shareNodesUrl(this.id, organization_id),
      success: (resp) => {
        resp = _.defaults(_.omit(resp, '_id'), this.defaults);
        return this.set(resp);
      }
    });
  }

  transfer({ organization_id, payment }) {
    if (!this.isTransferable()) { return; }
    this.trigger('request', null, this, {});
    return $.ajax({
      type: 'PUT',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({ payment }),
      url: this.clusternet.transferNodesUrl(this.id, organization_id),
      success: (resp) => {
        resp = _.defaults(_.omit(resp, 'actions'), this.defaults); // remove omit after clusternet#252 is fixed
        this.set(resp, { parse: true });
        return this.trigger('sync', resp, this, {});
      } });
  }

  activate() {
    if (!this.isActivateable()) { return; }
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.activateNodesUrl(this.id),
      success: (resp) => this.set(resp)
    });
  }

  deactivate() {
    if (!this.isDeactivateable()) { return; }
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.deactivateNodesUrl(this.id),
      success: (resp) => this.set(resp)
    });
  }

  hibernate() {
    if (!this.isHibernateable()) { return; }
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.hibernateNodesUrl(this.id),
      success: (resp) => this.set(resp)
    });
  }

  refreshWifiList() {
    return $.ajax({
      type: 'PUT',
      url: this.clusternet.refreshNodesWifiList(this.id)
    });
  }

  isActivated() {
    return this.get('status') === 'activated';
  }

  isDeactivated() {
    return this.get('status') === 'deactivated';
  }

  isHibernated() {
    return this.get('status') === 'hibernated';
  }

  isShared() {
    return !_.isEmpty(this.get('shared_to'));
  }

  isLeased() {
    return !_.isEmpty(this.get('leased_to'));
  }

  hasAction(name) {
    return _.contains(this.get('actions'), name);
  }

  isMonitorable() {
    return this.hasAction('read');
  }

  isModifiable() {
    return this.hasAction('configure');
  }

  isDeletable() {
    return this.hasAction('delete');
  }

  isCreatable() {
    return this.hasAction('create');
  }

  isShareable() {
    return this.hasAction('share');
  }

  isPaymentable() {
    return this.hasAction('payment_update');
  }

  isLeaseable() {
    return this.hasAction('lease');
  }

  isTransferable() {
    return this.isSupport();
  }

  isDownloadable() {
    return this.hasAction('read');
  }

  isResetable() {
    return this.isSupport() || this.isOwner();
  }

  isSupport() {
    return App.getChannel().request('get:current:user').isSupport() || this.isAdmin();
  }

  isOwner() {
    return App.getChannel().request('get:current:user').isOrgOwner(App.getChannel().request('get:organizations').get(this.get('organization_id')));
  }

  isAdmin() {
    return App.getChannel().request('get:current:user').isAdmin();
  }

  isActivateable() {
    return this.hasAction('activate');
  }

  isDeactivateable() {
    return this.hasAction('deactivate');
  }

  isHibernateable() {
    return this.hasAction('hibernate');
  }
}

export class Nodes extends ClusternetBridge(ValueLabelPairs(AppCollection)) {
  url() { return this.clusternet.batchFetchCurrentDevicesUrl(); }

  sync(method, model, options) {
    if (method === 'read') {
      options.type = 'POST';
    }

    return super.sync(method, model, options);
  }

  getAvailableActions() {
    return _(this.map((node) => node.get('actions'))).chain().flatten().uniq()
      .value();
  }
}
