/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import NodeWidget from 'app/backbone/entities/widgets/node_widget';

export default class BlocksWidget extends NodeWidget {
  defaults() {
    return _(super.defaults(...arguments)).extend({ _type: 'BlocksWidget' }); // eslint-disable-line prefer-rest-params
  }
}
