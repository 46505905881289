/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import some from 'lodash/some';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { fetchNodesSuccess, fetchDataPointsSuccess, clearNodes } from '~/store/reducers/nodes';
import { fetchAlertsSuccess, fetchAlertSuccess } from '~/store/reducers/alerts';

export const updateMeasurements = createAction('@@measurements/UPDATE');

const actions = [fetchNodesSuccess, fetchDataPointsSuccess, fetchAlertsSuccess, fetchAlertSuccess, updateMeasurements];
const isMeasurementsUpdateAction = (action) => some(actions, ({ type }) => action.type === type);

const byIdData = {};
const byId = createReducer(byIdData, (builder) => {
  builder
    .addCase(clearNodes, () => byIdData)
    .addMatcher(isMeasurementsUpdateAction, (state, { payload }) => {
      const measurements = get(payload, 'entities.measurements', byIdData);
      if (!isEmpty(measurements)) {
        forOwn(measurements, (item, id) => {
          if (state[id]) {
            const data = [...get(state[id], 'data', []), ...get(item, 'data', [])];
            state[id].data = sortBy(uniqBy(data, ([ts]) => ts), ([ts]) => ts);
          } else {
            state[id] = item;
          }
        });
      }
      return state;
    });
});

const measurements = combineReducers({
  byId
});

export const getMeasurementsById = (state, id) => state.measurements.byId[id];
export const getMeasurements = (state) => state.measurements.byId;

export default measurements;
