/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  modelEvents: {
    change: 'saveModel'
  },

  saveModel(model, options) {
    if (!options.changedAfterSave) {
      return model.save(model.changedAttributes(), { wait: true, changedAfterSave: true });
    }
  }
});
