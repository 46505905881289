import colors from './colors';

export const switchDefault = {
  trackWidth: 48,
  trackHeight: 20,
  knobRadius: 9,
  gap: 1,
  borderWidth: 0,
  trackRadius: 10,
  borderColor: 'transparent',
  activeColor: colors.brandBlue,
  inactiveColor: colors.panelGray,
  disabledColor: colors.disabledColor,
  knobColor: colors.white,
  togglingTime: '0.3'
};
