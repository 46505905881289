import React, { useRef, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Overlay, Popover, Button, Tooltip
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { resendUserInvitationRequest } from '~/store/reducers/invitations';

const ResendInvitation = ({ id, link }) => {
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const trigger = useRef();
  const clipboardInput = useRef();
  const clipboardButton = useRef();
  const dispatch = useDispatch();

  const copyToClipboard = useCallback(() => {
    if (clipboardInput.current) {
      clipboardInput.current.select();
      document.execCommand('copy');
    }
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  }, [setIsCopied]);

  const toggleOverlay = useCallback(() => setShowOverlay((o) => !o),
    [setShowOverlay]);

  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.user_invitations'),
      { toastId: `success_invitation_${id}` }
    );
    toggleOverlay();
  }, [t, toggleOverlay, id]);

  const handleAction = useCallback(() => {
    dispatch(resendUserInvitationRequest({ id, onSuccess: handleSuccess }));
  }, [dispatch, id, handleSuccess]);

  const renderPopover = (
    <Popover
      title={t('user_invitations.resend')}
      id="resend-invite-popover"
    >
      <div className="resend-invitation-popover">
        <div className="input-group react-input-group">
          <input
            id="user-invitation-clp"
            readOnly
            className="form-control"
            type="text"
            value={link}
            ref={clipboardInput}
          />
          <span className="input-group-btn">
            <Overlay
              show={isCopied}
              placement="bottom"
              // eslint-disable-next-line react/no-find-dom-node
              target={() => ReactDOM.findDOMNode(clipboardButton?.current)}
            >
              <Tooltip>Copied</Tooltip>
            </Overlay>
            <button
              type="button"
              ref={clipboardButton}
              title={t('base.labels.copy_to_clipboard')}
              className="btn btn-default clipboard"
              onClick={copyToClipboard}
            >
              <i className="fa fa-sm fa-clipboard" />
            </button>
          </span>
        </div>
        <div className="pt-10">
          <Button onClick={toggleOverlay}>
            {t('base.buttons.cancel')}
          </Button>
          <Button bsStyle="success" onClick={handleAction}>
            {t('base.buttons.send')}
          </Button>
        </div>
      </div>
    </Popover>
  );

  return (
    <div
      className="expand-button"
      style={{ position: 'relative' }}
    >
      <Overlay
        placement="left"
        show={showOverlay}
        // eslint-disable-next-line react/no-find-dom-node
        target={() => ReactDOM.findDOMNode(trigger?.current)}
      >
        {renderPopover}
      </Overlay>
      <a
        className="info"
        role="button"
        ref={trigger}
        onClick={toggleOverlay}
      >
        <i className="fa fa fa-fw fa-send" />
      </a>
    </div>
  );
};

export default ResendInvitation;
