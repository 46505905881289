var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"https=//twitter.com/"
    + alias2(((helper = (helper = lookupProperty(helpers,"twitterName") || (depth0 != null ? lookupProperty(depth0,"twitterName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"twitterName","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":44}}}) : helper)))
    + "\" width=\"100\" class=\"twitter-timeline\"></a>"
    + alias2(__default(require("../../../../../config/hbs-helpers/twitter.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"document") : depth0),"script","twitter-wjs",{"name":"twitter","hash":{},"data":data,"loc":{"start":{"line":1,"column":87},"end":{"line":1,"column":130}}}));
},"useData":true});