import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import useClipboard from 'react-use-clipboard';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Overlay, Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import I18n from 'app/config/i18n';
import { useTranslation } from 'react-i18next';

export const TimeAgoFormatter = ({ value }) => (value && moment(value).isValid()
  ? <TimeAgo date={value} />
  : I18n.t('alerts.triggered_never'));

export const TimeAgoProvider = (props) => (
  <DataTypeProvider
    formatterComponent={TimeAgoFormatter}
    {...props}
  />
);

export const DeviceScopeFormatter = ({ value }) => (value ? 'Yes' : 'No');

export const DeviceScopeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={DeviceScopeFormatter}
    {...props}
  />
);

export const TokenFormatter = ({ value }) => {
  const [isCopied, setCopied] = useClipboard(value, {
    successDuration: 1000
  });

  const { t } = useTranslation();
  const clipboardButton = useRef();
  const copyToClipboard = useCallback((event) => {
    event.stopPropagation();
    setCopied(event);
  }, [setCopied]);

  return (
    <div className="token-col token-container">
      <span>
        {`****-****-${value.substring(24)}`}
      </span>
      <Overlay
        show={isCopied}
        placement="bottom"
        // eslint-disable-next-line react/no-find-dom-node
        target={() => ReactDOM.findDOMNode(clipboardButton?.current)}
      >
        <Tooltip>Copied</Tooltip>
      </Overlay>
      <button
        type="button"
        ref={clipboardButton}
        title={t('base.labels.copy_to_clipboard')}
        className="btn btn-default clipboard"
        onClick={copyToClipboard}
      >
        <i className="fa fa-sm fa-clipboard" />
      </button>
    </div>
  );
};

export const TokenProvider = (props) => (
  <DataTypeProvider
    formatterComponent={TokenFormatter}
    {...props}
  />
);
