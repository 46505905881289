import React, {
  useCallback, useMemo
} from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { app as App } from 'app/backbone/app';
import { createOrganizationRequest, updateOrganizationRequest } from '~/store/reducers/organizations';
import { getOrganizationById } from '~/store/selectors/organizations';
import { getCountrySelectValue, getStateSelectValue } from '~/utils/getCountryByCode';
import mapAddress from '~/utils/mapAddress';
import withBackboneLink from '~/hocs/with_backbone_link';
import Form from './form';

const BackToOrganizationsButton = withBackboneLink((props) => (
  <button {...props} type="button" className="btn btn-default">
    Back to Organizations
  </button>
));

const CreateUpdateOrganization = React.memo(({ organizationId }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const serials = useMemo(() => new URLSearchParams(search).get('serials'), [search]);

  const { t } = useTranslation();
  const organization = useSelector((state) => getOrganizationById(state, organizationId));

  const initialData = useMemo(() => (organization
    ? ({
      name: organization.name,
      logo_url: organization.logo_url,
      shipping: {
        country: getCountrySelectValue(organization.shipping_address_country),
        state: organization.shipping_address_country === 'US'
          ? getStateSelectValue(organization.shipping_address_state)
          : undefined,
        state_other: organization.shipping_address_country !== 'US'
          ? organization.shipping_address_state
          : undefined,
        city: organization.shipping_address_city,
        street: organization.shipping_address_street,
        zip_code: organization.shipping_address_zip_code
      },
      billing_same_as_shipping: organization.billing_same_as_shipping,
      billing: {
        country: getCountrySelectValue(organization.billing_address_country),
        state: organization.billing_address_country === 'US'
          ? getStateSelectValue(organization.billing_address_state)
          : undefined,
        state_other: organization.billing_address_country !== 'US'
          ? organization.billing_address_state
          : undefined,
        city: organization.billing_address_city,
        street: organization.billing_address_street,
        zip_code: organization.billing_address_zip_code
      }
    })
    : ({
      billing_same_as_shipping: true
    })),
  [organization]);

  const handleSuccess = useCallback((data) => {
    if (organizationId) {
      toast.success(
        t('notifications.success.forms.update_organization'),
        { toastId: 'success_organization_update' }
      );
    } else {
      toast.success(
        t('notifications.success.forms.create_organization'),
        { toastId: 'success_organization_create' }
      );
    }
    if (serials === null) {
      App.navigate('organizations');
    } else {
      App.navigate(`/devices/claim?serials=${serials}&organization=${data?._id}`);
    }
  }, [organizationId, t, serials]);

  const handleFormSubmit = useCallback((values) => {
    const {
      shipping,
      billing,
      billing_same_as_shipping,
      ...rest
    } = values;
    let formData = {
      ...rest,
      billing_same_as_shipping,
      ...mapAddress(shipping, 'shipping_address')
    };
    if (!billing_same_as_shipping) {
      formData = {
        ...formData,
        ...mapAddress(billing, 'billing_address')
      };
    }
    if (organizationId) {
      dispatch(updateOrganizationRequest({
        organizationId,
        organization: formData,
        onSuccess: handleSuccess
      }));
    } else {
      dispatch(createOrganizationRequest({
        organization: {
          ...formData,
          app: 'environet'
        },
        onSuccess: handleSuccess
      }));
    }
  }, [dispatch, organizationId, handleSuccess]);

  return (
    <div className="details-form-page">
      <div className="content-top clearfix">
        <div className="pull-left">
          <BackToOrganizationsButton to="organizations" />
        </div>
      </div>
      <FinalForm
        onSubmit={handleFormSubmit}
        initialValues={initialData}
      >
        {({ handleSubmit }) => (
          <div className="form-region form-margins organization-profile">
            <Form
              handleSubmit={handleSubmit}
            />
          </div>
        )}
      </FinalForm>
    </div>
  );
});

export default CreateUpdateOrganization;
