/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { MnObject } from 'backbone.marionette';
import AppModel from 'app/backbone/lib/entities/app_model';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import { SingleChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import AppCollection from 'app/backbone/lib/entities/app_collection';

export class ThiamisFile extends Chooser(AppModel) {
  get idAttribute() { return 'name'; }
}

export class ThiamisFilesColl extends SingleChooser(AppCollection) {
  get model() { return ThiamisFile; }
}

export class ThiamisFiles extends ClusternetBridge(AppModel) {
  get transientAttrs() { return ['count', 'serial']; }
  get idAttribute() { return 'serial'; }

  get defaults() { return { files: [] }; }

  get relations() {
    return [
      {
        type: Backbone.Many,
        key: 'files',
        relatedModel: ThiamisFile,
        collectionType: ThiamisFilesColl
      }
    ];
  }

  url() {
    return this.clusternet.thiamisFilesUrl(this.get('serial'));
  }

  parse(resp) {
    // eslint-disable-next-line no-param-reassign
    resp.files = _.map(resp.files || [], (content, name) => ({ name, content }));
    // eslint-disable-next-line no-param-reassign
    resp.count = resp.files.length;
    return resp;
  }

  toJSON(options = {}) {
    const json = super.toJSON(...arguments); // eslint-disable-line prefer-rest-params
    if (options.savedByForm) {
      const files = _.object(_.pluck(json.files, 'name'), _.pluck(json.files, 'content'));
      return { files };
    }
    return json;
  }

  getNewFormModel() {
    const formModel = new this.constructor(this.toJSON());
    formModel.isFormModel = true;
    return formModel;
  }
}

const API = MnObject.extend({
  channelName: 'entities:thiamis_files',

  radioRequests: {
    'fetch:thiamis:files': 'fetchThiamisFiles'
  },

  fetchThiamisFiles(data, options = {}) {
    const files = new ThiamisFiles(data, options);
    files.fetch();
    return files;
  }
});

export const api = new API();
