import React, { useMemo, useEffect } from 'react';
import { Field as FinalField, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '~/components/form/input';
import useValidation from '~/hooks/useValidation';
import Select from '~/components/form/select';
import { fetchNodesRequest, clearNodes } from '~/store/reducers/nodes';
import { getConfiguredDevicesForDropdown, getNodesIsFetching } from '~/store/selectors/nodes';
import { getUserOrganizationRolesForAPI } from '~/store/selectors/users';
import { getCurrentUserId } from '~/store/selectors/global';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const { values: { organization }, hasValidationErrors } = useFormState();

  const WebhookTypesOptions = useMemo(() => [
    { label: t('webhooks.types.alert'), value: 'alert' },
    { label: t('webhooks.types.device'), value: 'device' }
  ], [t]);

  const dispatch = useDispatch();
  const { validateRequired, validateUrl } = useValidation();

  const nodesOptions = useSelector(getConfiguredDevicesForDropdown);
  const isNodesFetching = useSelector(getNodesIsFetching);

  const organizations = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return getUserOrganizationRolesForAPI(state, currentUserId);
  });

  const mappedOrganizations = useMemo(() => organizations.map((item) => ({
    value: item.organizationId,
    label: item.organizationName,
    ...item
  })), [organizations]);

  useEffect(() => {
    if (organization) {
      dispatch(clearNodes());
      dispatch(fetchNodesRequest({ data: { organization_id: organization.value } }));
    }
  }, [organization, dispatch]);

  return (
    <div className="form-content">
      <FinalField
        name="url"
        validate={validateUrl}
      >
        {
          ({ input, meta }) => (
            <Input
              label={t('attributes.webhook.url')}
              {...input}
              errorText={meta.touched && meta.error}
            />
          )
        }
      </FinalField>
      <FinalField
        name="auth"
        validate={validateRequired}
      >
        {
          ({ input, meta }) => (
            <Input
              label={t('attributes.webhook.auth')}
              {...input}
              errorText={meta.touched && meta.error}
            />
          )
        }
      </FinalField>
      <FinalField
        name="organization"
        validate={validateRequired}
      >
        {({ input, meta }) => (
          <Select
            label={t('models.organization')}
            {...input}
            value={input.value}
            options={mappedOrganizations}
            errorText={meta.touched && meta.error}
          />
        )}
      </FinalField>
      <FinalField
        name="nodes"
      >
        {({ input, meta }) => (
          <Select
            label={t('attributes.webhook.nodes')}
            {...input}
            placeholder={t('base.labels.all')}
            value={input.value}
            options={nodesOptions}
            isMulti
            isLoading={isNodesFetching}
            errorText={meta.touched && meta.error}
          />
        )}
      </FinalField>

      <FinalField
        name="type"
      >
        {({ input, meta }) => (
          <Select
            label={t('attributes.webhook.type')}
            {...input}
            placeholder={t('base.labels.all')}
            options={WebhookTypesOptions}
            errorText={meta.touched && meta.error}
          />
        )}
      </FinalField>
      <div className="form-group form-actions clearfix">
        <button
          type="button"
          className="btn btn-default"
          onClick={handleCancel}
        >
          {t('base.buttons.close')}
        </button>
        <button
          disabled={hasValidationErrors}
          type="submit"
          onClick={handleSubmit}
          className={`save-form btn btn-primary ${hasValidationErrors ? 'btn-disabled' : ''}`}
        >
          {t('base.buttons.save')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
