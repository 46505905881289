import React from 'react';
import { useSelector } from 'react-redux';
import {
  getNodeSensorIds
} from '~/store/selectors/nodes';
import SensorListItemContainer from './sensor_list_item_container';

const SensorsListContainer = ({ nodeId }) => {
  const {
    sensorIds
  } = useSelector(
    (state) => ({ sensorIds: getNodeSensorIds(state, nodeId) })
  );

  return (
    <div>
      { sensorIds.map((sensorId) => (<SensorListItemContainer key={sensorId} id={sensorId} nodeId={nodeId} />)) }
    </div>
  );
};

export default SensorsListContainer;
