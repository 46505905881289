import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import LinkButton from 'app/backbone/lib/react/components/common/LinkButton/LinkButton';
import { api as alertsApi } from 'app/backbone/apps/alerts/alerts_app';
import ConfirmLinkButton from 'app/backbone/lib/react/components/common/ConfirmLinkButton/ConfirmLinkButton';
import I18n from 'app/config/i18n';
import {
  getAlertByIdActions, isAlertMonitorable, isAlertModifiable, isAlertDeletable, getAlertDataPointsIds, getAlertIsSyncing, getAlertIsSynced,
  getAlertSubscribersEmailIds, getAlertSubscribersSmsIds
} from '~/store/selectors/alerts';
import { fetchAlertDetailsRequest, destroyAlertsRequest } from '~/store/reducers/alerts';

import { getUserByIdFullName } from '~/store/selectors/users';
import { getNodeByIdDisplayName } from '~/store/selectors/nodes';
import { getDataPointByIdLinked, getDataPointByIdNodeId, getDataPointByIdConditionsPresentName } from '~/store/selectors/data_points';
import ManageAlertsContext from '../../manage_context';

const GridDetailContainer = ({ row }) => {
  const { isActive } = useContext(ManageAlertsContext);
  const alertId = row?._id;
  const {
    isSyncing, isSynced, emailUsers, smsUsers, nodes, dataPoints,
    isMonitorable, isModifiable, isDeletable
  } = useSelector(
    (state) => {
      const emailUserIds = compact(getAlertSubscribersEmailIds(state, alertId));
      const smsUserIds = compact(getAlertSubscribersSmsIds(state, alertId));
      const actions = getAlertByIdActions(state, alertId);
      const dataPointIds = getAlertDataPointsIds(state, alertId);
      const linkedDataPointIds = compact(flatten(dataPointIds.map((dataPointId) => getDataPointByIdLinked(state, dataPointId))));
      const nodeIds = uniq(compact(linkedDataPointIds.map((dataPointId) => getDataPointByIdNodeId(state, dataPointId))));
      return {
        isSyncing: getAlertIsSyncing(state, alertId),
        isSynced: getAlertIsSynced(state, alertId),
        isMonitorable: isAlertMonitorable(actions),
        isModifiable: isAlertModifiable(actions),
        isDeletable: isAlertDeletable(actions),
        dataPoints: dataPointIds
          .map((dataPointId) => ({ id: dataPointId, conditions: getDataPointByIdConditionsPresentName(state, dataPointId) }))
          .filter(({ conditions }) => compact(conditions).length),
        emailUsers: compact(emailUserIds.map((userId) => getUserByIdFullName(state, userId))).sort(),
        smsUsers: compact(smsUserIds.map((userId) => getUserByIdFullName(state, userId))).sort(),
        nodes: nodeIds.map((nodeId) => getNodeByIdDisplayName(state, nodeId)).sort()
      };
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isSynced) {
      dispatch(fetchAlertDetailsRequest({ alertId }));
    }
  }, [alertId, dispatch, isSynced]);
  const handleMonitorAlertClick = () => alertsApi.getChannel().request('app:alerts:monitor', alertId);
  const handleEditAlertClick = () => alertsApi.getChannel().request('app:alerts:modify', alertId);
  const handleDeleteAlert = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(destroyAlertsRequest(alertId));
  };

  return (
    <div className={classNames('more-info', 'tr', { loading: isSyncing })}>
      {
        isActive && (
          <div className="choosable-col td react-choosable-col" />
        )
      }
      <div className="name-col td react-name-col" />
      <div className="conditions-list td react-conditions-col">
        <div className="row">
          <div className="col-sm-12">
            <div className="caption-box">{I18n.t('attributes.alert.rules')}</div>
            <div className="conditions">
              <ul className="keywords">
                {
                  isEmpty(dataPoints)
                    ? (<li>{I18n.t('alerts.empty_subscribers')}</li>)
                    : isSynced && !isSyncing && dataPoints
                      .map(({ id, conditions }) => (
                        <li key={id}>
                          <span>{conditions.join(' AND ')}</span>
                          <label className="relation">{dataPoints.length > 1 ? 'OR' : null}</label>
                        </li>
                      ))
                }
              </ul>
            </div>
          </div>
          <div className="col-sm-12 react-nodes-col">
            <div className="caption-box">{I18n.t('attributes.alert.nodes')}</div>
            <ul className="keywords">
              {
                isEmpty(nodes)
                  ? (<li>{I18n.t('alerts.empty_subscribers')}</li>)
                  : isSynced && !isSyncing && nodes.map((nodeName) => <li key={kebabCase(nodeName)}>{nodeName}</li>)
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="subscriptions-list td react-subscribers-col">
        <div className="caption-box">{I18n.t('attributes.alert.subscriptions')}</div>
        <div className="row">
          <div className="col-sm-6">
            <b>Email</b>
            <ul className="keywords">
              {
            isEmpty(emailUsers) && isSynced
              ? (<li>{I18n.t('alerts.empty_subscribers')}</li>)
              : emailUsers.map((fullName) => <li key={kebabCase(fullName)}>{fullName}</li>)
          }
            </ul>
          </div>
          <div className="col-sm-6">
            <b>Sms</b>
            <ul className="keywords">
              {
            isEmpty(smsUsers) && isSynced
              ? (<li>{I18n.t('alerts.empty_subscribers')}</li>)
              : smsUsers.map((fullName) => <li key={kebabCase(fullName)}>{fullName}</li>)
          }
            </ul>
          </div>
        </div>

      </div>
      <div className="td status-col" />
      <div className="actions-container td actions-col">
        { isMonitorable
          ? (
            <LinkButton
              className="info"
              icon="fa fa-fw fa-line-chart"
              title={I18n.t('alerts.open_monitor')}
              onClick={handleMonitorAlertClick}
            />
          ) : null }
        { isModifiable
          ? (
            <LinkButton
              className="info"
              icon="fa-md fa-fw fa-pencil"
              title={I18n.t('alerts.edit_configuration')}
              onClick={handleEditAlertClick}
            />
          ) : null }
        { isDeletable
          ? (
            <ConfirmLinkButton
              className="info"
              icon="fa-md fa-fw fa-trash"
              title={I18n.t('base.buttons.delete')}
              onConfirm={handleDeleteAlert}
              btnOkLabel={I18n.t('base.buttons.delete')}
              placement="left"
            />
          ) : null }
      </div>
    </div>
  );
};

export default GridDetailContainer;
