/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import PropTypes from 'prop-types';

export const ConfirmationHoc = (WrappedComponent) => {
  class Confirmation extends WrappedComponent {
    componentDidMount() {
      const confirmOptions = _(this.props).pick('placement', 'popout', 'btnOkLabel');
      const { onConfirm } = this.props;
      return $(this.confirmButtonElem.linkButtonElem)
        .confirmation({ ...confirmOptions, rootSelector: this.confirmButtonElem.linkButtonElem }).on('click', onConfirm);
    }
  }

  return Confirmation;
};

ConfirmationHoc.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

ConfirmationHoc.defaultProps = {
  placement: 'auto',
  popout: true
};

export default ConfirmationHoc;
