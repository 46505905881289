/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import Radio from 'backbone.radio';
import ReactDOM from 'react-dom';
import React from 'react';
import I18n from 'app/config/i18n';
import moment from 'app/config/moment';
import { app as App } from 'app/backbone/app';
import { __guard__ } from 'app/utils/custom-fns';
import ThiamisInfoParams from 'app/backbone/lib/components/shared_views/widget_thiamis_info_params';
import ThiamisDeviceTitle from 'app/backbone/lib/react/components/common/ThiamisDeviceTitle/ThiamisDeviceTitle';
import faceTmpl from './templates/face.hbs';

const monitorChannel = Radio.channel('monitor');

const ThiamisInfoWidgetContentView = View.extend({
  template: faceTmpl,

  ui: {
    thiamisOwner: '.thiamis-owner',
    thiamisSharedTo: '.thiamis-shared-to',
    thiamisLeasedTo: '.thiamis-leased-to',
    thiamisStatus: '.thiamis-status',
    thiamisDescription: '.thiamis-description',
    thiamisName: '.thiamis-name',
    thiamisInterval: '.thiamis-interval',
    thiamisBilling: '.thiamis-billing'
  },

  bindings: {
    '@ui.thiamisOwner': {
      observe: 'organization_id',
      onGet(organizationId) {
        return __guard__(App.getChannel().request('get:organizations').get(organizationId), (x) => x.get('name'));
      }
    },
    '@ui.thiamisSharedTo span': {
      observe: 'shared_to',
      updateMethod: 'html',
      escape: false,
      onGet(sharedTo) {
        return this.getOrgsNameBy(sharedTo);
      }
    },
    '@ui.thiamisSharedTo': {
      attributes: [{
        observe: 'shared_to',
        name: 'class',
        onGet(sharedTo) {
          if (_.isEmpty(sharedTo)) { return 'hidden'; }
        }
      }
      ]
    },
    '@ui.thiamisLeasedTo span': {
      observe: 'leased_to',
      updateMethod: 'html',
      escape: false,
      onGet(leasedTo) {
        return this.getOrgsNameBy(leasedTo);
      }
    },
    '@ui.thiamisLeasedTo': {
      attributes: [{
        observe: 'leased_to',
        name: 'class',
        onGet(leasedTo) {
          if (_.isEmpty(leasedTo)) { return 'hidden'; }
        }
      }
      ]
    },
    '@ui.thiamisDescription': {
      observe: 'description',
      update($el, value) {
        return this.toggleVisibility($el, value);
      }
    },
    '@ui.thiamisName': 'name',
    '@ui.thiamisInterval': {
      observe: 'interval',
      onGet(interval) {
        const intervalMin = moment.duration(interval).asMinutes();
        if (intervalMin === 1) {
          return I18n.t('time.one_min');
        // eslint-disable-next-line no-else-return
        } else if (intervalMin < 1) {
          return I18n.t('time.number_secs', { n: moment.duration(interval).asSeconds() });
        }
        return I18n.t('time.number_mins', { n: intervalMin });
      }
    },
    '@ui.thiamisBilling': {
      observe: 'payment',
      onGet(payment_id) {
        if (!payment_id) { return false; }
        const name = __guard__(App.getChannel().request('get:organizations').get(payment_id), (x) => x.get('name'));
        return name || I18n.t('thiamis.external_organization');
      },
      update($el, value) {
        return this.toggleVisibility($el, value);
      }
    }
  },

  triggers:
    { 'click .edit-config': 'edit:config' },

  initialize({ thiamis }) {
    this.thiamis = thiamis;
  },

  regions: { infoParamsRegion: '.info-params-region' },

  serializeData() {
    return {
      isModifiable: this.thiamis.isModifiable()
    };
  },

  onEditConfig() {
    return monitorChannel.trigger('configure:thiamis', this.model.id);
  },

  updateThiamisInfo($el, val) {
    return $el.find('span').text(val);
  },

  toggleVisibility($el, val) {
    $el.closest('.form-group').toggleClass('hidden', _.isEmpty(val));
    return $el.html(val);
  },

  getWidgetTitle() {
    return I18n.t('monitor.general_info');
  },

  showInfoParamsView() {
    return this.getRegion('infoParamsRegion').show(new ThiamisInfoParams({
      model: this.thiamis })
    );
  },

  getDeviceNameComponent() {
    return React.createElement(ThiamisDeviceTitle, { model: this.thiamis });
  },

  getOrgsNameBy(argOrgIds) {
    const orgIds = _.isString(argOrgIds) ? [argOrgIds] : argOrgIds;
    return _.chain(orgIds)
      .map((orgId) => (
        __guard__(App.getChannel().request('get:organizations').get(orgId), (x) => x.get('name'))
      )).compact()
      .value()
      .join('<br />');
  },

  onBeforeDetach() {
    return ReactDOM.unmountComponentAtNode(this.ui.thiamisStatus[0]);
  },

  onRender() {
    ReactDOM.render(this.getDeviceNameComponent(), this.ui.thiamisStatus[0]);
    this.showInfoParamsView();
    return this.stickit(this.thiamis);
  }
});

export default ThiamisInfoWidgetContentView;
