import React, {
  useState, useCallback, useRef, useEffect
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import humanizeDuration from 'humanize-duration';
import { useTranslation } from 'react-i18next';
import isNaN from 'lodash/isNaN';
import includes from 'lodash/includes';
import saveAs from 'file-saver';
import {
  SortingState, RowDetailState, IntegratedSorting, DataTypeProvider, PagingState, IntegratedPaging, SummaryState, IntegratedSummary
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid, Table, TableHeaderRow, TableRowDetail, PagingPanel, TableSummaryRow
} from '@devexpress/dx-react-grid-bootstrap3';
import { getDurationsTableData } from '~/screens/alert_monitor/selectors';
import { getIsExportingData, exportTableDataSuccess } from '~/screens/alert_monitor/reducer';
import { getDataPointByIdConditionsPresentName } from '~/store/selectors/data_points';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from '~/components/react_grid/expand_button';
import DetailRow from './detail_row';
import { TriggeredFormatter } from './triggered_formatter';
import { DurationFormatter } from './duration_formatter';

export const columns = [
  { name: 'triggered', title: 'Triggered' },
  { name: 'duration', title: 'Duration' }
];

const TriggeredProvider = (props) => (
  <DataTypeProvider
    formatterComponent={TriggeredFormatter}
    {...props}
  />
);

const DurationProvider = (props) => (
  <DataTypeProvider
    formatterComponent={DurationFormatter}
    {...props}
  />
);

const getRowId = ({ triggered }) => triggered;
const triggeredColumns = ['triggered'];
const durationColumns = ['duration'];

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container nodes-dx-grid-container" />;

const GridContainer = ({ selectedDate, dataPointId, alertName }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'triggered', direction: 'desc' }]);
  const [pageSizes] = useState([5, 10, 15, 0]);
  const { t } = useTranslation();
  const [totalSummaryItems] = useState([
    { columnName: 'triggered', type: 'count' },
    { columnName: 'duration', type: 'max' },
    { columnName: 'duration', type: 'sum' }
  ]);

  const isExportingData = useSelector((state) => getIsExportingData(state, dataPointId));
  const exporterRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isExportingData) {
      exporterRef.current.exportGrid();
    }
  }, [dispatch, isExportingData, dataPointId]);

  const dataPointName = useSelector((state) => getDataPointByIdConditionsPresentName(state, dataPointId));
  const onSave = useCallback((workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${kebabCase(`${alertName}-${dataPointName}`)}.xlsx`);
      dispatch(exportTableDataSuccess(dataPointId));
    });
  }, [dispatch, alertName, dataPointName, dataPointId]);

  const Row = React.memo((props) => {
    const { row: { triggered } } = props;
    const isExpanded = includes(expandedRowIds, triggered);

    const handleExpandRowClick = useCallback(
      () => {
        if (includes(expandedRowIds, triggered)) {
          setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== triggered));
        } else {
          setExpandedRowIds([...expandedRowIds, ...[triggered]]);
        }
      },
      [triggered]
    );
    return (
      <Table.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);
  const rows = useSelector((state) => getDurationsTableData(state, dataPointId, selectedDate));

  if (isEmpty(rows)) {
    return (<h5 className="text-center">{t('alerts.durations.empty_list')}</h5>);
  }
  return (
    <div className="list-table-container react-list-container">
      <div className="list-table" style={{ minHeight: '150px', marginTop: '10px' }}>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          rootComponent={Root}
        >
          <SummaryState
            totalItems={totalSummaryItems}
          />
          <IntegratedSummary />
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <IntegratedSorting />
          <TriggeredProvider
            for={triggeredColumns}
          />
          <DurationProvider
            for={durationColumns}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={5}
          />
          <IntegratedPaging />
          <Table
            rowComponent={Row}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <TableHeaderRow align="center" showSortingControls />
          <TableRowDetail
            contentComponent={DetailRow}
            toggleCellComponent={ExpandButton}
          />
          <PagingPanel
            pageSizes={pageSizes}
          />
          <TableSummaryRow messages={{
            sum: 'Total Time Active',
            max: 'Longest Time Active',
            count: 'State Changes'
          }}
          />
          <DetailRowColumnOrder />
          <GridExporter
            ref={exporterRef}
            rows={rows}
            getCellValue={(row, columnName) => {
              const value = row[columnName];
              if (columnName === 'duration') {
                if (isNaN(value)) { return null; }
                return humanizeDuration(value, { round: true });
              }
              return moment(value).format('MM/DD/YYYY H:mm:ss');
            }}
            columns={columns}
            onSave={onSave}
          />
        </Grid>
      </div>
    </div>
  );
};

export default GridContainer;
