/* eslint-disable no-nested-ternary */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import I18n from 'app/config/i18n';
import Layout from 'app/backbone/lib/views/layout';
import BatteryLevel from 'app/backbone/lib/react/sensors/batteryLevel/batteryLevel';
import LastUpdatedTs from 'app/backbone/lib/react/sensors/lastUpdatedTs/lastUpdatedTs';
import SignalLevel from 'app/backbone/lib/react/components/lists/thiamises/SignalLevel/SignalLevel';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';
import { __guard__ } from 'app/utils/custom-fns';
import widgetTechDataPointContainerTemplate from './templates/widget_tech_data_point_container.hbs';

class TechParametersComponent extends Component {
  componentDidMount() {
    return this.timestamp = __guard__(__guard__(this.getModel(), (x1) => x1.getDataPointsLastUpdate()), (x) => x.get('timestamp'));
  }

  getModel() {
    return this.props.getModel();
  }

  render() {
    return (
      <div className="row">
        <div className="general-parameter">
          <BatteryLevel
            model={this.getModel().getThiamisBatteryVoltage()}
            title={(value) => I18n.t('thiamis.more_info.battery', { value })}
            unit=""
            noValueText={I18n.t('base.labels.n_a')}
          />
        </div>
        <div className="general-parameter">
          <SignalLevel model={this.getModel().getThiamisSignal()} />
        </div>
      </div>
    );
  }
}

TechParametersComponent.propTypes = {
  getModel: PropTypes.func.isRequired,
  isAirthinx: PropTypes.bool.isRequired
};

const TechDataPointContainer = Layout.extend({
  template: widgetTechDataPointContainerTemplate,
  className: 'tech-datapoints clearfix',

  ui: {
    paramsContainer: '.params-container',
    lastUpdatedParam: '.last-updated-ts'
  },

  initialize({ lastUpdate, thiamis }) {
    this.lastUpdate = lastUpdate;
    this.thiamis = thiamis;
  },

  _createLastUpdatedTs() {
    return React.createElement(LastUpdatedTs, {
      model: this.lastUpdate,
      title: I18n.t('thiamis.more_info.last_updated'),
      noValueText: I18n.t('base.labels.n_a')
    });
  },

  _createParamsContainer() {
    return React.createElement(BackboneReactComponent(TechParametersComponent), _.extend(_.pick(this, 'collection', 'model'), {
      isAirthinx: this.thiamis.isAirthinx()
    }));
  },

  onRender() {
    ReactDOM.render(this._createLastUpdatedTs(), this.ui.lastUpdatedParam[0]);
    ReactDOM.render(this._createParamsContainer(), this.ui.paramsContainer[0]);
  }
});

export default TechDataPointContainer;
