/* eslint-disable */
import * as Sentry from '@sentry/browser';
import I18n from 'app/config/i18n';

Number.prototype.pad = function (size) {
  let s;
  if (size == null) {
    size = 2;
  }
  s = String(this);
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
};

RegExp.escape = function (text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

_.mixin({
  abstractMethod() {
    throw new Error('abstract method');
  },
  pickFormParts() {
    let attrs; let object; let
      ref;
    object = arguments[0], attrs = arguments.length >= 2 ? slice.call(arguments, 1) : [];
    return _({
      model: object.formModel
    }).extend((ref = _(object)).pick.apply(ref, attrs));
  },
  isLike(str, needle) {
    return _.str.contains(str != null ? str.toLowerCase() : undefined, needle.toLowerCase());
  },
  formatNumber(num) {
    if (!_.isNumber(num)) {
      return num;
    }
    num = parseInt(num);
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num;
  },
  formatDate(val) {
    if (val) {
      return moment(val).format('MM/DD/YY HH:mm');
    }
  },
  extendObject(superObj, subObj) {
    return _(_.clone(superObj)).extend(subObj);
  },
  convertI18nStr(str) {
    let i;
    i = -1;
    return str.replace(/\%\{[^}]+\}/g, () => `{${i += 1}}`);
  },
  formatPhone(phone, code) {
    if (code == null) {
      code = '';
    }
    if (!phone) {
      return;
    }
    phone = phone.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3');
    if (_.isEmpty(code)) {
      return phone;
    }
    return `+${code}-${phone}`;
  },
  getVCard(data) {
    let vcardContent;
    vcardContent = 'BEGIN:VCARD\n';
    if (data.last_name && data.first_name) {
      vcardContent += `N:${data.last_name};${data.first_name}\n`;
    }
    if (data.org) {
      vcardContent += `ORG:${data.org}\n`;
    }
    if (data.job_title) {
      vcardContent += `TITLE:${data.job_title}\n`;
    }
    if (data.phone_work) {
      vcardContent += `TEL;TYPE=WORK,VOICE:${data.phone_work}\n`;
    }
    if (data.email) {
      vcardContent += `EMAIL:${data.email}\n`;
    }
    return vcardContent += 'END:VCARD';
  },
  stripHtmlTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  },
  qrCodeUrl(options = {}) {
    _.defaults(options, {
      color: '003c7f',
      bgcolor: 'FFFFFF',
      qzone: 1,
      margin: 0,
      ecc: 'L'
    });
    return `//api.qrserver.com/v1/create-qr-code/?${$.param(options)}`;
  },
  freqs(list) {
    return _(list).reduce((freqs, slug) => {
      if (freqs[slug] == null) {
        freqs[slug] = 0;
      }
      freqs[slug] += 1;
      return freqs;
    }, {});
  },
  lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },
  binaryIndexOf(coll, xsearchElement, computeFn, dir) {
    let currentElement; let currentIndex; let maxIndex; let minIndex; let
      searchElement;
    if (dir == null) {
      dir = 1;
    }
    minIndex = 0;
    maxIndex = coll.size() - 1;
    searchElement = dir * xsearchElement;
    while (minIndex <= maxIndex) {
      currentIndex = (minIndex + maxIndex) / 2 | 0;
      currentElement = dir * computeFn(coll.at(currentIndex));
      if (currentElement < searchElement) {
        minIndex = currentIndex + 1;
      } else if (currentElement > searchElement) {
        maxIndex = currentIndex - 1;
      } else {
        return currentIndex;
      }
    }
    return minIndex;
  },
  getAvgDecimalsPoint(values) {
    let decimals; let decimalsArr; let
      sum;
    decimalsArr = _(values).chain().map(v => `${v}`.split('.')[1]).compact()
      .value();
    sum = _(decimalsArr).reduce((memo, num) => memo + num.length, 0);
    decimals = Math.round(sum / decimalsArr.length);
    if (_.isNaN(decimals)) {
      return 0;
    }
    return decimals;
  },
  isInteger(x) {
    return Math.round(x) === x;
  },
  parseErrResp(response, settings = {}) {
    let errorMessages; let
      json;
    if (response.status === 500) {
      return I18n.t('notifications.errors.system_error');
    }

    const { url } = settings;
    try {
      json = JSON.parse(response.responseText || '{}');
      if (json.errors) {
        errorMessages = json.errors.base || json.errors.detail || (_.isString(json.errors) ? [json.errors] : json.errors);
      } else if (json.error_description) {
        errorMessages = [json.error_description];
      } else {
        errorMessages = json.error ? [json.error] : [I18n.t('notifications.errors.system_error')];
      }
    } catch (error) {
      return I18n.t('notifications.errors.system_error');
    }

    return _.isArray(errorMessages) ? errorMessages.join() : errorMessages;
  },
  urlParamsToObj(string) {
    return JSON.parse(`{"${ decodeURI(string.replace(/&/g, "\",\"").replace(/=/g, "\":\"")) }"}`);
  },
  compactObject(o) {
    let clone;
    clone = _.clone(o);
    _.each(clone, (v, k) => {
      if (!v) {
        return delete clone[k];
      }
    });
    return clone;
  },
  isDefined(value) {
    return !_.isUndefined(value) && !_.isNull(value);
  },
  macAddress(mac) {
    if (_.isNumber(mac)) {
      return mac.toString(16).padStart(12, '0').match(/../g).reverse().slice(0, 6).join(':');
    }
  },
  getInitials(name, glue) {
    let initials;
    if (glue == null) {
      glue = true;
    }
    if (_.isEmpty(name)) {
      return;
    }
    initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
    if (glue && initials) {
      return initials.join('');
    }
    return initials;
  },
  isBlank(string) {
    return (_.isUndefined(string) || _.isNull(string) || string.toString().trim().length === 0);
  },
  isBlankString(str) {
    return (!_.isDefined(str) || /^\s*$/.test(str));
  },
  getSwitchLabel(flag) {
    if (flag) {
      return I18n.t('base.labels.switched_on');
    }
    return I18n.t('base.labels.switched_off');
  },
  sortByKeys(obj, comparator) {
    const keys = _.sortBy(_.keys(obj), key => (comparator ? comparator(obj[key], key) : key));

    return _.object(keys, _.map(keys, key => obj[key]));
  },
  round(num, precision) {
    let multiplier;
    if (precision == null) {
      precision = 3;
    }
    if (_.isBoolean(num)) {
      return +num;
    }
    if (_.isBlankString(num)) {
      return '';
    }
    if (isNaN(num)) {
      return num;
    }
    if (parseInt(precision) < 0) {
      multiplier = Math.pow(10, Math.abs(parseInt(precision)));
      return multiplier * Math.floor((+num + multiplier / 2) / multiplier);
    }
    num = parseFloat((+num).toFixed(precision));
    if (_.isInteger(num)) {
      return num;
    }
    num = num.toString().replace(/0+$/, '');
    if (_(num).endsWith('.')) {
      num = num.slice(0, num.length - 1);
    }
    return parseFloat(num);
  },
  loadScript(src, callback) {
    let s;


    let r;


    let t;
    r = false;
    s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = src;
    s.onload = s.onreadystatechange = function () {
      // console.log( this.readyState ); //uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        if (callback) callback();
      }
    };
    t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
    return t;
  },
  cardinalDirections() {
    return ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
  },
  azimut2cardinal(degree) {
    let val;
    val = Math.floor(degree / 22.5 + 0.5);
    return _.cardinalDirections()[val % 16];
  },
  isLatLng(string) {
    let ck_lat_lng;
    ck_lat_lng = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)(,|;)\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    return ck_lat_lng.test(string);
  },
  dasherizeField(string) {
    return _.dasherize(_.trim(string, '_'));
  },
  isNumeric(num){
    return !isNaN(num)
  },
  insert: require('underscore.string/insert'),
  trim: require('underscore.string/trim'),
  endsWith: require('underscore.string/endsWith'),
  dasherize: require('underscore.string/dasherize'),
  capitalize: require('underscore.string/capitalize'),
  slugify: require('underscore.string/slugify'),
  underscored: require('underscore.string/underscored'),
  camelize: require('underscore.string/camelize'),
  repeat: require('underscore.string/repeat'),
  startsWith: require('underscore.string/startsWith'),
  strRight: require('underscore.string/strRight'),
  classify: require('underscore.string/classify')
});
