/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AssociatedModel from 'app/backbone/lib/entities/associated_model';
import { app as App } from 'app/backbone/app';

export default class AppModel extends AssociatedModel {
  get idAttribute() { return '_id'; }
  get clone() { return Backbone.Model.prototype.clone }

  isModifiable() { return this.get('modifiable') === true; }
  isDestroyable() { return this.get('destroyable') === true; }

  save(key, value, options) {
    // Handle both `"key", value` and `{key: value}` -style arguments.
    let attrs;
    if ((key == null) || _(key).isObject()) {
      attrs = key;
      options = value;
    } else {
      (attrs = {})[key] = value;
    }

    if (options == null) { options = {}; }
    _.extend(options, { renderRootKey: true, onSave: true });

    return super.save(attrs, options);
  }

  toJSON(options) {
    if (options == null) { options = {}; }
    const superJson = super.toJSON(_(options).omit('renderRootKey', 'onSave'));
    const dataJson =
      (() => {
      if (this.isSaving(options)) {
        const transientAttrs =
          _(this).chain()
          .result('transientAttrs')
          .union(['modifiable', 'destroyable', 'chosen', 'filter_tags', 'search_text', 'created_at'])
          .value();
        return _(superJson).omit(transientAttrs);
      } else { return superJson;
    }
    })();
    if (!options.renderRootKey || !_.isString(this.jsonRootKey)) { return dataJson; }
    const resJson = {};
    resJson[this.jsonRootKey] = dataJson;
    return resJson;
  }

  isSaving(options, mutator) {
    return options.onSave || options.savedByForm;
  }

  getXhrs() { return this._fetch; }

  validation() {
    return App.getChannel().request('get:validations')[this.jsonRootKey] || {};
  }

  getNewFormModel() {
    const formModel = this.clone();
    formModel.isFormModel = true;
    return formModel;
  }

  sync(method, model, options) {
    this.trigger('request', model, null, options);
    return super.sync(...arguments);
  }
};