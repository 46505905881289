import { createContext } from 'react';

const ManageAlertsContext = createContext({
  isActive: false,
  selectedAlerts: [],
  setSelectedAlerts: () => {},
  setIsActive: () => {}
});

export default ManageAlertsContext;
