import {
  put,
  fork,
  takeEvery
} from 'redux-saga/effects';
import * as Actions from '~/store/reducers/nodes';
import * as DeviceActions from './reducer';

export function* handleModalActionRequestSaga({ payload }) {
  try {
    switch (payload.type) {
      case 'activate':
        yield put(Actions.activateRequest(payload));
        break;
      case 'deactivate':
        yield put(Actions.deactivateRequest(payload));
        break;
      case 'share':
        yield put(Actions.shareRequest(payload));
        break;
      case 'lease':
        yield put(Actions.leaseRequest(payload));
        break;
      case 'remove_share':
        yield put(Actions.removeShareRequest(payload));
        break;
      case 'remove_lease':
        yield put(Actions.removeLeaseRequest(payload));
        break;
      case 'delete':
        yield put(Actions.destroyNodesRequest(payload));
        break;
      default:
        throw new Error('Action not supported');
    }
  } catch (e) {
    yield put(DeviceActions.modalActionFailure(e.toString()));
  }
}

function* watchModalActionRequest() {
  yield takeEvery(DeviceActions.modalActionRequest.toString(), handleModalActionRequestSaga);
}

export default function* app() {
  yield fork(watchModalActionRequest);
}
