import React from 'react';
import Select from 'react-select-virtualized';
import findIndex from 'lodash/findIndex';
import NavButton from './nav_button';

const QuickNavButtons = (props) => {
  const {
    onChange, options, selectedValue, isLoading
  } = props;

  function getChosenIndex() {
    return findIndex(options, ({ value }) => value === selectedValue.value);
  }

  function getNextItem() {
    return options[getChosenIndex() + 1];
  }

  function getPrevItem() {
    return options[getChosenIndex() - 1];
  }

  function onClickPrev() {
    const prevItemId = getPrevItem().value;
    if (prevItemId) {
      onChange(prevItemId);
    }
  }

  function onClickNext() {
    const nextItemId = getNextItem().value;
    if (nextItemId) {
      onChange(nextItemId);
    }
  }

  function onSelectEntity({ value }) {
    onChange(value);
  }

  return (
    <div className="btn-group">
      <NavButton disabled={!getPrevItem()} onClick={onClickPrev} iconClass="fa-chevron-left" />
      <Select
        isLoading={isLoading}
        styles={{
          indicatorSeparator: () => ({ display: 'none' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 })
        }}
        isSearchable
        isClearable={false}
        options={options}
        value={selectedValue}
        onChange={onSelectEntity}
      />
      <NavButton disabled={!getNextItem()} onClick={onClickNext} iconClass="fa-chevron-right" />
    </div>

  );
};

export default QuickNavButtons;
