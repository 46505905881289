import { createContext } from 'react';

const RolloutModalContext = createContext({
  isActive: false,
  setIsActive: () => {},
  selectedFirmware: { value: null, label: null },
  setSelectedFirmware: () => {}
});

export default RolloutModalContext;
