/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { Spinner } from 'spin.js';

export default class SpinnerView extends View {
  get template() { return false; }
  get className() { return 'loading-container'; }

  onAttach() {
    this.getSpinner().spin(this.el);
  }

  onDestroy() {
    return this.getSpinner().stop();
  }

  getSpinner() {
    if (this.spinner) {
      return this.spinner;
    }
    const opts = this._getOptions();
    return this.spinner = new Spinner(opts);
  }

  _getOptions() {
    return {
      lines: 10, // The number of lines to draw
      length: 6, // The length of each line
      width: 2.5, // The line thickness
      radius: 7, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 9, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: '#000', // #rgb or #rrggbb
      speed: 1, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      // hwaccel: true, // Whether to use hardware acceleration
      className: 'spinner', // The CSS class to assign to the spinner
      animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
      zIndex: 2e9
    };
  }
}
