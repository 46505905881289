import { createSelector } from 'reselect';
import { getUserInvitationsIds, getUserInvitations } from '~/store/reducers/invitations';
import { getCurrentUserEmail } from '~/store/selectors/current_user';

export const getFiledUserInvitations = createSelector(
  [getUserInvitationsIds, getUserInvitations],
  (ids, invitations) => ids.filter((id) => invitations[id].acceptance_state === 'filed').map((id) => invitations[id])
);

export const getIncomingUserInvitations = createSelector(
  [getFiledUserInvitations, getCurrentUserEmail],
  (invitations, currentUserEmail) => invitations.filter(({ email }) => email === currentUserEmail)
);
