/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Behavior } from 'backbone.marionette';
import React from 'react';
import ReactDOM from 'react-dom';
import { api as apiThiamises } from 'app/backbone/apps/thiamises/thiamises_app';
import DataPointTitle from 'app/backbone/lib/react/components/common/DataPointTitle/DataPointTitle';

export const NodeDataPoint = Behavior.extend({
  initialize(behavior, { options: { model, thiamis } }) {
    this.model = model;
    this.thiamis = thiamis;
    return this.interval = setInterval(() => this.$el.toggleClass('offline', this.model.getStatusType(this.thiamis) === 'offline'),
      3000);
  },

  onBeforeDetach() {
    clearInterval(this.interval);
    return ReactDOM.unmountComponentAtNode(this.ui.name[0]);
  },

  onRender() {
    ReactDOM.render(React.createElement(DataPointTitle,
      { model: this.model, interval: this.thiamis.getReportingInterval(), thiamis: this.thiamis }),
    this.ui.name[0]);
  }
});

export const NodeWidgetDataPoint = Behavior.extend({
  triggers: {
    click: 'click:datapoint'
  },

  bindings: {
    ':el': {
      attributes: [{
        observe: 'name',
        name: 'class',
        onGet(name) {
          return `dp-${_.slugify(name)}`;
        }
      }
      ]
    }
  },

  initialize(behavior, { options: { model } }) {
    this.model = model;
  },

  onClickDatapoint() {
    this.$el.find('.device-status').tooltip('hide');
    return apiThiamises.getChannel().request('app:devices:monitor:params', this.model.get('node_id'), {
      data_points: this.model.id });
  },

  onRender() {
    this.view.addBinding(this.model, this.bindings);
  }
});

// export const NodeDataPointSparkline = Behavior.extend({

// })
