import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import get from 'lodash/get';
import difference from 'lodash/difference';
import requestApi from './requestApi';
import { normalizeResp } from '~/store/normalizr';
import * as Actions from '~/store/reducers/apis';
import * as schema from '~/store/schema';

const SEARCH_NODES_REQUIRED_PROPS = ['access', 'organization_id', 'query'];

export function* apisFetchRequestSaga() {
  try {
    const data = yield call(
      requestApi,
      'get',
      'api_keys',
      {}
    );
    const response = normalizeResp(data, schema.apiListSchema);
    yield put(Actions.fetchAPIListSuccess(response));
  } catch (e) {
    yield put(Actions.fetchAPIListFailure(e.toString()));
  }
}

function* watchFetchApisRequest() {
  yield takeLatest(Actions.fetchAPIListRequest.toString(), apisFetchRequestSaga);
}

function* watchCreateApiSuccessRequest() {
  yield takeEvery(Actions.createAPITokenSuccess.toString(), apisFetchRequestSaga);
}

export function* apisDeleteRequestSaga({ payload = {} }) {
  try {
    const { id, onSuccess } = payload;
    yield call(
      requestApi,
      'delete',
      `api_keys/${id}`,
      {}
    );
    if (onSuccess) {
      onSuccess();
    }
    yield put(Actions.deleteAPITokenSuccess(payload));
  } catch (e) {
    yield put(Actions.deleteAPITokenFailure(e.toString()));
  }
}

function* watchDeleteApiRequest() {
  yield takeEvery(Actions.deleteAPITokenRequest.toString(), apisDeleteRequestSaga);
}

export function* apisCreateRequestSaga({ payload: { organization, onSuccess } }) {
  try {
    const permissionSchemas = yield call(
      requestApi,
      'get',
      'permission_schemas',
      {}
    );
    const organizations = yield call(
      requestApi,
      'get',
      'organizations',
      {}
    );
    const organizationDetails = organizations.find((org) => org.id === organization);
    const project_id = get(organizationDetails, 'project.id', null);

    const permissionSchema = permissionSchemas.find((o) => (
      get(o, 'project.id') === project_id // check whether it's schema for same project
      && !difference(get(o, 'schema.required', []), SEARCH_NODES_REQUIRED_PROPS).length // check that two arrays are equal
    ));

    const body = {
      organization_id: organization,
      device_scope: false,
      permissions: [{
        action: 'clusternet:SearchNodes',
        permission_schema_id: get(permissionSchema, 'id'),
        scope: {
          organization_id: organization,
          access: {
            roles: {
              user: organization
            }
          },
          query: {
            permissions: 'read',
            type: ['thiamis', 'alert']
          }
        }
      }]
    };
    const response = yield call(
      requestApi,
      'post',
      'api_keys',
      {
        api_key: body
      }
    );
    yield put(Actions.createAPITokenSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    yield put(Actions.createAPITokenFailure(e.toString()));
  }
}

function* watchCreateApiRequest() {
  yield takeEvery(Actions.createAPITokenRequest.toString(), apisCreateRequestSaga);
}

export default function* app() {
  yield fork(watchFetchApisRequest);
  yield fork(watchCreateApiSuccessRequest);
  yield fork(watchDeleteApiRequest);
  yield fork(watchCreateApiRequest);
}
