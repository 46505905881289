import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';

import { Elevated, FormContainer } from '~/components/layout';

import Devices from './devices';
import Sensors from './sensors';
import { fetchNodesRequest } from '~/store/reducers/nodes';
import { getNodesIsFetched } from '~/store/selectors/nodes';
import { fetchOrganizationsRequest, getOrganizationsIsFetched } from '~/store/reducers/organizations';
import { getAnalyticsFormInitialValues } from './selectors';

const AnalyticsScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNodesRequest({ data: { last: 1 } }));
    dispatch(fetchOrganizationsRequest());
  }, [dispatch]);
  const { isFetched } = useSelector(
    (state) => ({
      isFetched: getNodesIsFetched(state) && getOrganizationsIsFetched(state)
    })
  );

  const initialValues = useSelector((state) => getAnalyticsFormInitialValues(state));

  const handleSubmit = () => {
    // TODO;
  };

  if (!isFetched) return <h1>Loading...</h1>;

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      subscription={{ submitting: true, pristine: true }}
    >
      {
      () => (
        <FormContainer>
          <Elevated>
            <Devices />
            <Sensors />
          </Elevated>
        </FormContainer>
      )
    }
    </FinalForm>
  );
};

export default AnalyticsScreen;
