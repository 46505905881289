import React from 'react';
import compact from 'lodash/compact';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { getDataPointByIdConditions, getConditionName } from '~/store/selectors/data_points';
import { getAlertDataPointsIds } from '~/store/selectors/alerts';
import ConditionItem from './condition_item';

const ConditionsBlock = (props) => {
  const {
    dataPoints
  } = props;
  const { t } = useTranslation();
  const relation = dataPoints.length > 1 ? 'OR' : null;
  return (
    <div className="form-box conditions-rules">
      <h3>{t('attributes.alert.rules')}</h3>
      <div className="conditions-region">
        <div className="sensor-list clearfix">
          {
            isEmpty(dataPoints)
              ? (<li>{t('alerts.empty_subscribers')}</li>)
              : dataPoints
                .map(({ conditions, displayName }) => (
                  conditions.map(({ _id, ...rest }) => (
                    <ConditionItem key={_id} {...rest} displayName={displayName} relation={relation} />
                  ))
                ))
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { id }) => {
  const dataPointIds = getAlertDataPointsIds(state, id);

  return {
    dataPoints: dataPointIds
      .map((dataPointId) => {
        const conditions = getDataPointByIdConditions(state, dataPointId);
        return { conditions, displayName: getConditionName(conditions, state) };
      })
      .filter(({ conditions }) => compact(conditions).length)
  };
};

export default connect(mapStateToProps)(ConditionsBlock);
