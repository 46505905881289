/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import timezonesData from 'app/config/common_data/timezones';
import { __guard__ } from 'app/utils/custom-fns';

const API = MnObject.extend({
  channelName: 'entities:timezones',

  radioRequests: {
    'get:timezones': 'getTimezones',
    'get:timezone': 'getTimezone',
    'get:timezone:label': 'getTimezoneLabel'
  },

  getTimezones() {
    return _.extend(timezonesData, { opt_labels: _(timezonesData).keys() });
  },

  getTimezoneLabel(id) {
    return __guard__(this.getTimezone(id), (x) => x.label);
  },

  getTimezone(id) {
    let timezone = null;
    _.each(timezonesData, (tzGroup) => {
      if (timezone) { return timezone; }
      return timezone = _.findWhere(tzGroup, { timezone: id });
    });
    return timezone;
  }
});

export const api = new API();
