import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import isNull from 'lodash/isNull';

import { useTranslation } from 'react-i18next';
import { getDataPointUniqueStatuses, getHeatmapWidgetFilter } from './selectors';
import { AQI_LABELS } from '~/utils/constants';
import { filterHeatmapWidget } from './reducer';

import {
  Grid, Flex
} from '../layout';

export const ColorMapping = {
  [AQI_LABELS.EXCELLENT]: '#43aae6',
  [AQI_LABELS.GOOD]: '#1789F2',
  [AQI_LABELS.SATISFACTORY]: '#8fbf3b',
  [AQI_LABELS.MODERATE]: '#FFAB20',
  [AQI_LABELS.POOR]: '#ee3c3c',
  [AQI_LABELS.VERYPOOR]: '#bf0000',
  [AQI_LABELS.OFFLINE]: '#5f6a75'
};

export const HeatmapContainer = styled(Grid)``;

export const Heatmap = styled(Flex)`
  padding: 10px;
  overflow: auto;
  height: fit-content;
  flex-wrap: wrap;
`;

const HeatmapFooterContainer = styled(Flex)`
  font-size: 12px;
  color: #666;
  background: #fff;
  padding: 0px 15px;
  align-items: center;
  height: 38px;
  overflow: auto;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: space-between;
`;

const LegendCircle = styled(Flex)`
  height: 10px;
  width: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-right: 5px;
`;

const LegendItem = styled(Flex)`
  align-items: center;
  cursor: pointer;
  margin: 1px 3px;
  & b {
    font-size: 110%;
  }
`;

export const LegendItemContainer = ({ widgetId, item, isCurrent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const legendName = t(`heatmap_legend.${item}`);
  const handleLegendItemClick = useCallback(() => {
    dispatch(filterHeatmapWidget({ widgetId, filter: isCurrent ? null : item }));
  }, [dispatch, widgetId, isCurrent, item]);

  return (
    <LegendItem
      key={`${widgetId}_${item}`}
      onClick={handleLegendItemClick}
    >
      <LegendCircle background={ColorMapping[item]} />
      { isCurrent ? (<b>{legendName}</b>) : legendName}
    </LegendItem>
  );
};

export const Legend = ({ widgetId, legendItems }) => {
  const currentFilter = useSelector((state) => getHeatmapWidgetFilter(state, widgetId));
  return (
    <Flex flexWrap="wrap">
      {
        legendItems.map((item) => (
          <LegendItemContainer
            key={`${widgetId}_${item}`}
            widgetId={widgetId}
            isCurrent={currentFilter === item}
            item={item}
          />
        ))
      }
    </Flex>
  );
};

export const HeatmapFooter = ({ widgetId, parameter, dataPointIds }) => {
  const statuses = useSelector((state) => getDataPointUniqueStatuses(state, dataPointIds));
  return (
    <HeatmapFooterContainer>
      <Legend legendItems={statuses} widgetId={widgetId} />
      {parameter?.name}
    </HeatmapFooterContainer>
  );
};

const HeatmapItemNormal = styled(Flex)`
  height: 42px;
  width: 42px;
  border: 1px solid transparent;
  position: relative;
  line-height: 1;
  overflow: hidden;
  border-radius: 50%;
  margin: 5px;
  font-size: 110%;
  display: flex;
  align-items: center;
`;

export const HeatmapItemButton = styled('div').attrs({
  role: 'button'
})`
  position: relative;
  border: 1px solid transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
`;

const HeatmapSVGText = styled('svg').attrs({
  fontWeight: '900'
})`
  opacity: 0.7;
  fill: #FFFFFF;
`;

const HeatmapSVGTextSmall = styled(HeatmapSVGText)`
  font-weight: 400;
  opacity: 1;
`;

export const HeatmapItem = ({
  status = AQI_LABELS.OFFLINE, value
}) => (
  <HeatmapItemNormal background={ColorMapping[status]}>
    <HeatmapSVGTextSmall viewBox="0 0 60 18" height="18">
      <text x="50%" y="15" textAnchor="middle"><tspan>{isNull(value) ? '' : value}</tspan></text>
    </HeatmapSVGTextSmall>
  </HeatmapItemNormal>
);
