/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import WidgetBase from 'app/backbone/entities/widgets/widget_base';
import SelectsDataPoints from 'app/backbone/lib/concerns/entities/selects_data_points';
import { NodeSeriesStreamSubscribable } from 'app/backbone/lib/concerns/entities/measurement_updatable';

export default class ChartWidget extends SelectsDataPoints(NodeSeriesStreamSubscribable(WidgetBase)) {
  get transientAttrs() { return ['yaxis_grouped', 'ordinal']; }
  get defaults() {
    return {
      _type: 'ChartWidget',
      size: 1,
      time_period: 'hours_3',
      selected_data_point_ids: [],
      yaxis_grouped: true,
      ordinal: true
    };
  }

  get validation() {
    return {
      selected_data_point_ids: {
        required: true
      },
      node_id: {
        required: true
      }
    };
  }

  getPeriodFrom() {
    return this.getNodeDataStream().periodFrom();
  }
}
