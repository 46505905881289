import React from 'react';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getNodeByIdDisplayName } from '~/store/selectors/nodes';
import { getDataPointByIdLinked, getDataPointByIdNodeId } from '~/store/selectors/data_points';
import { getAlertDataPointsIds } from '~/store/selectors/alerts';

const NodesBlock = (props) => {
  const {
    nodes
  } = props;
  const { t } = useTranslation();
  return (
    <div className="form-box node-navigation">
      <h3>{t('attributes.alert.nodes')}</h3>
      <div className="row">
        <div className="nodes-region">
          <ul className="keywords col-sm-12">
            {
              isEmpty(nodes)
                ? (<li>{t('alerts.empty_subscribers')}</li>)
                : nodes.map((nodeName) => <li key={kebabCase(nodeName)}>{nodeName}</li>)
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { id }) => {
  const dataPointIds = getAlertDataPointsIds(state, id);
  const linkedDataPointIds = compact(flatten(dataPointIds.map((dataPointId) => getDataPointByIdLinked(state, dataPointId))));
  const nodeIds = uniq(compact(linkedDataPointIds.map((dataPointId) => getDataPointByIdNodeId(state, dataPointId))));
  return {
    nodes: nodeIds.map((nodeId) => getNodeByIdDisplayName(state, nodeId))
  };
};

export default connect(mapStateToProps)(NodesBlock);
