export default {
  levels: [
    'info',
    'warning',
    'critical'
  ],
  methods: [
    'email',
    'sms',
    'email_sms'
  ],
  types: [
    'formation_request',
    'message',
    'partnership',
    'invitation',
    'membership',
    'alert'
  ]
};
