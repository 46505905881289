/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { fetchUserProfileSuccess } from './global';
import { saveProfileSuccess } from '~/store/reducers/profile';

export const setUsersQuery = createAction('@@users/SEARCH_REQUEST');
export const fetchUsersRequest = createAction('@@users/FETCH_REQUEST');
export const fetchUsersSuccess = createAction('@@users/FETCH_SUCCESS');
export const fetchUsersFailure = createAction('@@users/FETCH_FAILURE');

export const fetchUserRequest = createAction('@@users/FETCH_USER_REQUEST');
export const fetchUserSuccess = createAction('@@users/FETCH_USER_SUCCESS');
export const fetchUserFailure = createAction('@@users/FETCH_USER_FAILURE');

export const updateUserRequest = createAction('@@users/UPDATE_REQUEST');
export const updateUserSuccess = createAction('@@users/UPDATE_SUCCESS');
export const updateUserFailure = createAction('@@users/UPDATE_FAILURE');

export const destroyUserRequest = createAction('@@users/DELETE_REQUEST');
export const destroyUserSuccess = createAction('@@users/DELETE_SUCCESS');
export const destroyUserFailure = createAction('@@users/DELETE_FAILURE');

export const deleteUserPermissionsRequest = createAction('@@users/DELETE_PERMISSIONS_REQUEST');
export const deleteUserPermissionsSuccess = createAction('@@users/DELETE_PERMISSIONS_SUCCESS');
export const deleteUserPermissionsFailure = createAction('@@users/DELETE_PERMISSIONS_FAILURE');

const ids = createReducer([], {
  [fetchUsersSuccess](state, { payload: { result } }) {
    return result || state;
  },
  [destroyUserSuccess](state, { payload: userId }) {
    state.splice(state.findIndex((id) => id === userId), 1);
  }
});

const total = createReducer(0, {
  [fetchUsersSuccess](state, { payload }) {
    return payload?.total || state;
  }
});

const byId = createReducer({}, {
  [fetchUsersSuccess](state, { payload }) {
    const { entities: { users } } = payload;
    if (users) {
      return {
        ...state,
        ...users
      };
    }
    return state;
  },
  [fetchUserProfileSuccess](state, { payload }) {
    const { entities: { users } } = payload;
    if (users) {
      return {
        ...state,
        ...users
      };
    }
    return state;
  },
  [saveProfileSuccess](state, { payload }) {
    const { data, id } = payload;
    state[id] = data;
  },
  [destroyUserRequest](state, { payload: { userId } }) {
    state[userId].isSyncing = true;
  },
  [destroyUserFailure](state, { payload: userId }) {
    state[userId].isSyncing = false;
  },
  [destroyUserSuccess](state, { payload: userId }) {
    delete state[userId];
  },
  [fetchUserSuccess](state, { payload }) {
    const { entities: { users } } = payload;
    if (users) {
      return {
        ...state,
        ...users
      };
    }
    return state;
  }
});

const isFetched = createReducer(false, {
  [fetchUsersRequest]() {
    return false;
  },
  [fetchUsersFailure]() {
    return false;
  },
  [fetchUsersSuccess]() {
    return true;
  }
});

const isSyncing = createReducer(false, {
  [fetchUserRequest]() {
    return true;
  },
  [fetchUserFailure]() {
    return false;
  },
  [fetchUserSuccess]() {
    return false;
  },
  [updateUserRequest]() {
    return true;
  },
  [updateUserFailure]() {
    return false;
  },
  [updateUserSuccess]() {
    return false;
  }
});

const query = createReducer('', {
  [setUsersQuery](state, { payload }) {
    return payload;
  }
});

const users = combineReducers({
  ids,
  byId,
  isFetched,
  total,
  query,
  isSyncing
});

export const getUserById = (state, id) => state.users.byId[id];
export const getUsers = (state) => state.users.byId;
export const getUsersIds = (state) => state.users.ids;
export const getUsersIsFetched = (state) => state.users.isFetched;
export const getUsersTotal = (state) => state.users.total;
export const getUsersQuery = (state) => state.users.query;
export const getUserIsSyncing = (state, id) => state.users.byId[id]?.isSyncing;
export const getUserIsSynced = (state, id) => state.users.byId[id]?.isSynced;
export const getUsersIsSyncing = (state) => state.users.isSyncing;

export default users;
