import toastr from 'toastr';
import { app as App } from 'app/backbone/app';
import { api as auth0Api } from 'app/utils/auth0_handler';

((app) => {
  app.getChannel().on('flash:message:clear', () => { toastr.clear(); });
  app.getChannel().on('flash:message', (type, text, options = {}) => {
    toastr.options = _.defaults(options, {
      positionClass: 'toast-top-center',
      preventDuplicates: true
    });
    return typeof toastr[type] === 'function' ? toastr[type](text, null, options) : undefined;
  });
  const auth0Handler = auth0Api.getChannel().request('get:instance');
  return app.getChannel().on('handle:error:response', (xhr, { settings }) => {
    const unauthorized = xhr.status === 401;
    if (unauthorized) {
      return auth0Handler.authErrorDialog();
    }
    return app.getChannel().trigger('flash:message', 'error', _.parseErrResp(xhr, settings));
  });
})(App);
