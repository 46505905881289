/* eslint-disable max-len */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { ThiamisDebugCollection, ThiamisDebugFilteredListColl } from 'app/backbone/entities/nodes/thiamis_debug';
import FilteredListColl from 'app/backbone/entities/abstract/filtered_list_coll';
import { api as apiThiamisFilters } from 'app/backbone/filters/thiamis_filters';
import { api as apiBaseFilters } from 'app/backbone/filters/base';
import { __guard__ } from 'app/utils/custom-fns';

const ThiamisDebugEntityFetching = {

  getThiamisDebugEntitiesColl() {
    return __guard__(this.getThiamisDebugDataPoint(), (x) => x.get('measurements'));
  },

  getThiamisDebugColl() {
    return this.thiamisDebugColl != null ? this.thiamisDebugColl : (this.thiamisDebugColl = new ThiamisDebugCollection(this.getThiamisDebugEntitiesColl(), { parse: true }));
  },

  getThiamisDebugDataPoint() {
    return this.getDataPointsColl().findWhere({ name: 'Debug' });
  },

  getThiamisDebugFilterGroupsColl() {
    return this.thiamisDebugFilterGroups != null ? this.thiamisDebugFilterGroups : (this.thiamisDebugFilterGroups = apiThiamisFilters.getChannel().request('filter:groups:thiamis:debug',
      { filteredColl: this.getThiamisDebugColl() }));
  },

  getThiamisDebugFilterInputModel() {
    return this.thiamisDebugFilterInput != null ? this.thiamisDebugFilterInput : (this.thiamisDebugFilterInput = apiBaseFilters.getChannel().request('new:filter_input', this.getThiamisDebugFilterGroupsColl()));
  },

  getThiamisDebugFilteredColl() {
    if (this.thiamisDebugFilteredColl) { return this.thiamisDebugFilteredColl; }
    this.thiamisDebugFilteredColl = new ThiamisDebugFilteredListColl(this.getThiamisDebugFilteredListColl());
    this.thiamisDebugFilteredColl.setSort('timestamp', 'desc');
    return this.thiamisDebugFilteredColl;
  },

  addMeasurements(dataPoint, newMeasurements) {
    _.each(newMeasurements, (newMeasurement) => {
      const [timestamp, rest] = newMeasurement;
      if (!this.getThiamisDebugColl().get(timestamp)) {
        this.getThiamisDebugColl().add({ timestamp, ...rest });
      }
    });
  },

  getThiamisDebugFilteredListColl() {
    this.thiamisDebugFilteredListColl = new FilteredListColl(this.getThiamisDebugColl(),
      { filterInputModel: this.getThiamisDebugFilterInputModel() });

    if (this.getThiamisDebugDataPoint()) {
      this.listenTo(this.getThiamisDebugDataPoint(), 'augment:measurements', this.addMeasurements);
    }

    this.listenTo(this.getDataPointsColl(), 'updates:set', ({ initiate }) => {
      if (initiate) {
        this.getThiamisDebugColl().reset(__guard__(this.getThiamisDebugDataPoint(), (x) => x.get('measurements')), { parse: true });
        return this.listenTo(this.getThiamisDebugDataPoint(), 'augment:measurements', this.addMeasurements);
      }
    });
    return this.thiamisDebugFilteredListColl;
  }
};

export default ThiamisDebugEntityFetching;
