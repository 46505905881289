import styled from 'styled-components';

export const ProfileContainer = styled.div`
  .nav-tabs > li.active i {
    color: #006092;
  }
`;

export const TabContainer = styled.div`
  background: #f5f5f5;
  padding: 15px 15px 0px 15px;
  border-bottom: 1px solid #ddd;
`;

export const PanelContainer = styled.div`
  width: 100%;
  max-width: 860px;
  margin: auto;
  padding: 0 40px;
  margin-top: 50px;
`;

export const Title = styled.h3`
  font-size: 14px;
  margin-top: 0;
  padding: 10px;
  border-radius: 5px;
  background: #f5f5f5;
`;

export const SaveButton = styled.button.attrs(() => ({
  className: 'btn btn-primary'
}))``;

export const Row = styled.div`
  display: grid;
  position: relative;
  margin-bottom: 10px;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  ${({ deleted }) => (deleted ? 'div { text-decoration: line-through; }' : '')}
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
  justify-content: flex-end;
`;
