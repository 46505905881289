export const borderRadius = {
  small: '4px',
  medium: '5px'
};

export const borderWidth = {
  small: '1px'
};

export const borderColors = {
  panelDivider: '1px dashed #ddd',
  scheduleDivider: 'rgba(0,0,0,0.15)',
  toggle: 'transparent'
};
