import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { fetchNodesSuccess, fetchDataPointsSuccess, clearNodes } from '~/store/reducers/nodes';
import { fetchAlertsSuccess } from '~/store/reducers/alerts';

const byIdData = {};
const byId = createReducer(byIdData, {
  [fetchNodesSuccess](state, { payload }) {
    const {
      entities: { data_points }
    } = payload;
    if (data_points) {
      return {
        ...state,
        ...data_points
      };
    }
    return state;
  },
  [fetchAlertsSuccess](state, { payload }) {
    const {
      entities: { data_points }
    } = payload;
    if (data_points) {
      return {
        ...state,
        ...data_points
      };
    }
    return state;
  },
  [clearNodes]() {
    return byIdData;
  },
  [fetchDataPointsSuccess](state, { payload }) {
    const dataPoints = get(payload, 'entities.data_points', byIdData);
    if (dataPoints) {
      return {
        ...state,
        ...dataPoints
      };
    }
    return state;
  }
});

const dataPoints = combineReducers({
  byId
});

export const getDataPoint = (state, id) => (id ? state.dataPoints.byId[id] : undefined);

export const getDataPoints = (state) => state.dataPoints.byId;

export default dataPoints;
