/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import I18n from 'app/config/i18n';
import nodeSelectTemplate from 'app/backbone/lib/widgets/node_widget/templates/node_select.hbs';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import dataPointsFilterTemplate from 'app/backbone/lib/widgets/node_widget/templates/data_points_filter.hbs';

export const NodeSelect = View.extend({
  template: nodeSelectTemplate,

  initialize({ thiamises }) {
    this.thiamises = thiamises;
  },

  bindings: {
    '.config-node_id': {
      observe: 'node_id',
      selectOptions: {
        collection() { return this.getFilteredMonitorableThiamises().map((node) => ({ label: node.presentName(), value: node.id })); },
        defaultOption: { label: I18n.t('nodes.choose_node'), value: null
        }
      }
    }
  },

  getFilteredMonitorableThiamises() {
    if (this.filteredMonitorableThiamises) { return this.filteredMonitorableThiamises; }
    this.filteredMonitorableThiamises = new FilteredCollection(this.thiamises);
    this.filteredMonitorableThiamises.filterBy('read', (thiamis) => thiamis.isMonitorable());
    return this.filteredMonitorableThiamises;
  },

  getFilteredConfigurableThiamises() {
    if (this.filteredConfigurableThiamises) { return this.filteredConfigurableThiamises; }
    this.filteredConfigurableThiamises = new FilteredCollection(this.getFilteredThiamises());
    this.filteredConfigurableThiamises.filterBy('configure', (thiamis) => thiamis.isModifiable() || thiamis.isCreatable());
    return this.filteredConfigurableThiamises;
  },

  onRender() { return this.stickit(); }
});

export const EmptyDataPointsFilter = View.extend({
  template: 'node_widget/data_points_filter_empty',
  labelText: I18n.t('attributes.node_widget.selected_data_point_ids'),

  serializeData() {
    return _(super.serializeData(...arguments)).extend(_(this).pick('labelText')); // eslint-disable-line prefer-rest-params
  }
});

export const NodeEmptyDataPointsFilter = EmptyDataPointsFilter;
export const ChartEmptyDataPointsFilter = EmptyDataPointsFilter;

export const NullDataPointsFilter = View.extend({
  template: false
});

export const NodeDataPointsFilter = View.extend({
  template: dataPointsFilterTemplate,
  maxSize: 3,
  labelText: I18n.t('attributes.node_widget.selected_data_point_ids'),
  className: 'data_points_filter_wrap',

  modelEvents:
  { 'change:data_points_filter_type': 'onChangeDataPointsFilterType' },

  ui: {
    dataPointsFilter: '.config-data_points_filter',
    dataPointsFilterType: '[name="data_points_filter_type"]'
  },

  bindings() {
    return {
      '@ui.dataPointsFilter': {
        observe: _.result(this, 'dataPointsFieldName'),
        selectOptions: {
          collection() {
            return this.getDataPointsValudLabelsPairs();
          }
        },
        select2Options: {
          templateResult(data, container) {
            if (data.id === 'all') {
              $(container).addClass('all_option');
            }
            return data.text;
          }
        },
        attributes: [{
          name: 'data-maximum-selection-size',
          observe: () => this.dataPointsFieldName(),
          onGet() { return this.maxSize; }
        }, {
          name: 'name',
          observe: 'data_points_filter_type',
          onGet(value) {
            return `${value}_data_point_ids`;
          }
        }]
      },
      '@ui.dataPointsFilterType': {
        observe: 'data_points_filter_type',
        disableSearch: true,
        selectOptions: {
          collection() {
            return [
              { value: 'included', label: I18n.t('attributes.node_widget.selected_data_point_ids') },
              { value: 'excluded', label: I18n.t('attributes.node_widget.filtered_data_point_ids') }];
          }
        }
      }
    };
  },

  dataPointsFieldName() {
    if (this.model.get('data_points_filter_type') === 'included') {
      return 'selected_data_point_ids';
    } return 'filtered_data_point_ids';
  },

  getDataPointsValudLabelsPairs() {
    const collection = _(this.collection.getValueLabelPairs()).sortBy('label');
    if (this.model.get('data_points_filter_type') === 'included') {
      collection.unshift({
        label: I18n.t('base.labels.all'),
        value: 'all'
      });
    }
    return collection;
  },

  onChangeDataPointsFilterType(model, value) {
    if (value === 'included') {
      this.model.set({ selected_data_point_ids: ['all'], filtered_data_point_ids: null });
    } else { this.model.set({ selected_data_point_ids: null, filtered_data_point_ids: null }); }
    return this.render();
  },

  onRender() {
    this.stickit();
  }
});

export const ChartDataPointsFilter = View.extend({
  template: dataPointsFilterTemplate,
  maxSize: 3,
  className: 'data_points_filter_wrap',

  modelEvents:
  { 'change:data_points_filter_type': 'onChangeDataPointsFilterType' },

  ui: {
    dataPointsFilter: '.config-data_points_filter'
  },

  bindings: {
    '@ui.dataPointsFilter': {
      observe: 'selected_data_point_ids',
      selectOptions: {
        collection() {
          return _(this.collection.getValueLabelPairs()).sortBy('label');
        }
      },
      attributes: [{
        name: 'data-maximum-selection-size',
        observe: 'selected_data_point_ids',
        onGet() { return this.maxSize; }
      }]
    },
    '.control-label': {
      observe: 'selected_data_point_ids',
      onGet() { return I18n.t('attributes.node_widget.selected_data_point_ids'); }
    }
  },

  onRender() {
    this.stickit();
  }
});
