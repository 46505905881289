/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { MnObject } from 'backbone.marionette';
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';
import AssociatedModel from 'app/backbone/lib/entities/associated_model';
import AppCollection from 'app/backbone/lib/entities/app_collection';
import { MultiChooser, Chooser } from 'app/backbone/lib/concerns/entities/chooser';
import { __guard__ } from 'app/utils/custom-fns';

export class OrgFilteredBase extends FilteredCollection {
  initialize({ orgFilters }) {
    this.orgFilters = orgFilters;
    return this.filterByChosenOrg();
  }

  filterByChosenOrg() {
    const chosenOrg = this.orgFilters.getFirstChosen();
    if (!chosenOrg) { return; }
    return this.filterBy('org', (model) => chosenOrg.isAll() || (typeof model.seenByOrg === 'function' ? model.seenByOrg(chosenOrg) : undefined));
  }
}

export class Filter extends Chooser(AssociatedModel) {
  defaults = { count: 0 }

  isEmpty() {
    return this.get('count') <= 0;
  }

  groupId() { return this.collection.parents[0].id; }
}

export class FiltersColl extends MultiChooser(AppCollection) {
  get model() {
    return Filter;
  }
}

export class FilterGroup extends AssociatedModel {
  get relations() {
    return [{
      type: Backbone.Many,
      key: 'filters',
      collectionType: FiltersColl }
    ];
  }

  defaults() {
    return {
      count: 0,
      filters: []
    };
  }

  initialize() {
    this.listenTo(this.get('filters'), 'collection:chose:some collection:chose:all', (filterModel, options) => this.collection.trigger('filter:chosen', filterModel, this, options));
    this.listenTo(this.get('filters'), 'collection:unchose:some collection:chose:none', (filterModel, options) => this.collection.trigger('filter:unchosen', filterModel, this, options));
    if (this.getFilteredColl()) {
      this.setCount();
      return this.listenTo(this.getFilteredColl(), 'remove reset', this.setCount);
    }
  }

  isEmpty() {
    return this.get('count') <= 0;
  }

  getFilteredColl() {
    return (this.collection != null ? this.collection.getFilteredColl() : undefined);
  }

  setCount() {
    return this.set('count', this.getFilteredColl().size());
  }
}

export class FilterGroupsColl extends AppCollection {
  get model() {
    return FilterGroup;
  }

  initialize(modelAttrs, options = { filteredColl: null }) {
    this.filteredColl = options.filteredColl;
    if (this.filteredColl) {
      this.listenTo(this.filteredColl, 'remove reset', () => this.computeCounts(true));
    }
    return this.on('filter:chosen', function (filter, chosenGroup, _options) {
      return this.each((group) => {
        if (group !== chosenGroup) { return group.get('filters').chooseNone(_options); }
      });
    });
  }

  clearCounts() {
    return this.each((group) => group.get('filters').each((filter) => filter.set('count', 0)));
  }

  computeCounts(force = false) {
    if (!force && !this.filteredColl) { return; }
    this.clearCounts();
    const filterMap = {};
    this.filteredColl.each((model) =>
      _(model.get('filter_tags')).each((filterTag) => {
        if (filterMap[filterTag] == null) { filterMap[filterTag] = 0; }
        return filterMap[filterTag] += 1;
      })
    );
    return _(filterMap).each((count, filterId) =>
      // FIXME: it should never happen that a filter is not found
      __guard__(this.findFilter(filterId), (x) => x.set('count', count))
    );
  }

  static build(args, options) {
    const parsedArgs = _(args).map((filters, groupName) =>
      ({
        id: groupName,
        filters: _(filters).map((filter) => ({ id: filter }))
      }));

    return new FilterGroupsColl(parsedArgs, options);
  }

  collectFilterIds() {
    return this.chain().map((group) => group.get('filters').pluck('id')).flatten().value();
  }

  chooseFilter(filterKey, options) {
    // TODO: this should never be called with unexisting filter name
    return __guard__(this.findFilter(filterKey), (x) => x.choose(options));
  }

  unchooseFilter(filterKey, options) {
    return this.findFilter(filterKey).unchoose(options);
  }

  findFilter(filterKey) {
    let matchingFilter = null;
    const matchingGroup = this.find((model) => matchingFilter = model.get('filters').get(filterKey));
    if (!matchingGroup) { return; }
    return matchingFilter;
  }

  getCollection() { return this; }

  getFilteredColl() { return this.filteredColl; }

  // TODO: rename
  getChosenFilterKey() {
    const ids = this.collectChosenFilterIds();
    if (_.isEmpty(ids)) { return 'all'; } return ids.join(',');
  }

  collectChosenFilterIds() {
    return this.chain()
      .map((group) => _(group.get('filters').getChosen()).pluck('id'))
      .flatten()
      .value();
  }

  setFilterIds(ids, options) {
    return this.each((group) => group.get('filters').chooseByIds(ids, options));
  }
}

export class FilterInputModel extends Backbone.Model {
  defaults() {
    return {
      query: '',
      filters: []
    };
  }

  initialize(options) {
    // super.initialize(options);
    const { filterGroups } = options;
    this.filterGroups = filterGroups;
    return this.listenTo(this.getFilterGroupsColl(), 'filter:chosen filter:unchosen', function () {
      return this.set('filters', this.getChosenFilterIds());
    });
  }

  updateFilterGroups(options) {
    return this.getFilterGroupsColl().setFilterIds(this.getFilterIds(), options);
  }

  getFilterIds() {
    return this.getFilterGroupsColl().collectFilterIds();
  }

  getChosenFilterIds() {
    return this.getFilterGroupsColl().collectChosenFilterIds();
  }

  getFilterGroupsColl() {
    return this.filterGroups;
  }

  getTokens() {
    return _(this.get('query').split(/\s+/)).chain().without('')
      .map((t) => t.toLowerCase())
      .value();
  }

  getKeywords() {
    return this.getTokens();
  }
}

const API = MnObject.extend({
  channelName: 'filters:base',

  radioRequests: {
    'new:filter_input': 'newFilterInput'
  },

  newFilterInput(filterGroups) {
    return new FilterInputModel({ filterGroups });
  }
});

export const api = new API();
