import React, { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import I18n from 'app/config/i18n';
import { getUserByIdAbilities } from '~/store/selectors/users';
import { getCurrentUserId } from '~/store/selectors/global';
import { getFilter, setFilter } from '../reducer';
import InvitePartnerModal from './invite_partner_modal';

export default function FiltersFormTop() {
  const [inviteActive, setInviteActive] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const dispatch = useDispatch();
  const {
    partnershipsQuery,
    isPartnershipCreatable
  } = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    const currentUserAbilities = getUserByIdAbilities(state, currentUserId);
    return {
      partnershipsQuery: getFilter(state),
      isPartnershipCreatable: currentUserAbilities?.partnership_invitation.creatable
    };
  });

  useEffect(() => {
    if (partnershipsQuery === debouncedQuery) {
      return;
    }
    dispatch(setFilter(debouncedQuery));
  }, [query, debouncedQuery, dispatch, partnershipsQuery]);

  const toggleInviteActive = useCallback(() => setInviteActive((b) => !b), [setInviteActive]);

  return (
    <div className="content-top">
      <div className="pull-left">
        <div className="partnerships-filter-input-region filter-input-wrap">
          <input
            className="filter-input"
            type="text"
            placeholder="Filter..."
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
          />
        </div>
      </div>
      <div className="pull-right">
        { isPartnershipCreatable
          && (
          <button type="button" className="btn btn-primary btn-invite new-org" onClick={toggleInviteActive}>
            <i className="fa fa-fw fa-building-plus" />
            <span>{I18n.t('partnership_invitations.create_partnership')}</span>
          </button>
          )}
      </div>
      <InvitePartnerModal isVisible={inviteActive} toggleModal={toggleInviteActive} />
    </div>
  );
}
