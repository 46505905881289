/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Behavior } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';

const HeightFilling = Behavior.extend({
  initialize() {
    return this.listenTo(App.getChannel(), 'window:resized', this.fillHeight, this);
  },

  onAttach() {
    return this.fillHeight();
  },

  fillInEl() { return $(window); },
  setHeightEl() { return this.$el; },

  offsetTop() { return this.setHeightEl().offset().top; },

  fillHeight() {
    if (this.setHeightEl().length === 0) { return; }
    return this.setHeightEl().height(this.fillInEl().height() - this.offsetTop());
  }
});

export default HeightFilling;
