import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import compact from 'lodash/compact';
import { useForm, useFormState, Field as FinalField } from 'react-final-form';
import { getLastMeasurement } from '~/store/selectors/measurements';
import { fetchDataPointsRequest } from '~/store/reducers/nodes';
import Select from '~/components/form/select';
import DatePicker from '~/components/form/datepicker';
import { getDefaultDataPointsByNodeId, getDataPointTimezoneDatapoint, getParametersForDropdown } from '~/store/selectors/data_points';
import { getConfiguredDevicesForDropdown } from '~/store/selectors/nodes';
import useValidation from '~/hooks/useValidation';

const ModalForm = ({ handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const form = useForm();
  const dispatch = useDispatch();
  const { validateRequired } = useValidation();
  const { values: { devices, from, to }, hasValidationErrors } = useFormState();
  const deviceOptions = useSelector((state) => getConfiguredDevicesForDropdown(state));
  const parameterOptions = useSelector((state) => {
    const nodeIds = (devices || []).map((device) => device.value);
    const dataPoints = nodeIds.map((nodeId) => getDefaultDataPointsByNodeId(state, nodeId));
    return getParametersForDropdown(dataPoints);
  });

  const isLoadingParameters = useMemo(() => !!deviceOptions.find((node) => node.isSyncing), [deviceOptions]);

  const timezone = useSelector((state) => {
    if (!devices || !devices.length) {
      return null;
    }
    const timezones = devices.map((node) => {
      const timezoneDpId = getDataPointTimezoneDatapoint(state, node.value); // get timezone datapoint
      const timezoneMeasure = getLastMeasurement(state, timezoneDpId); // get timezone measurement
      if (timezoneMeasure) {
        return timezoneMeasure[1]; // get timezone value
      }
      return null;
    }); // get timezones
    return timezones.find((tz) => !!tz); // get first timezone defined
  });

  // set timezone in form
  useEffect(() => {
    if (timezone) {
      form.change('timezone', timezone);
    }
  }, [form, timezone]);

  useEffect(() => {
    // When selected devices changes, params should be reset
    form.change('parameters', []);

    if (!devices || !devices.length) {
      return;
    }
    // fetch datapoints for devices not fetched yet
    const nonSyncedNodeIds = compact(devices
      .filter(({ _id: deviceId }) => !deviceOptions.find(({ _id: optionId, isSynced }) => optionId === deviceId && isSynced))
      .map(({ _id }) => _id));
    if (!nonSyncedNodeIds.length) {
      return;
    }
    dispatch(fetchDataPointsRequest({ node_id: nonSyncedNodeIds }));
  }, [deviceOptions, dispatch, form, devices]);

  const tomorrow = useMemo(() => {
    const date = new Date();
    date.setDate(new Date().getDate() + 1);
    return date;
  }, []);

  return (
    <div className="form">
      <div className="form-region form-margins">
        <FinalField
          name="devices"
          validate={validateRequired}
        >
          {
            ({ input, meta }) => (
              <Select
                label={t('thiamis.download_csv.devices')}
                value={input.value}
                onChange={input.onChange}
                options={deviceOptions}
                isMulti
                onBlur={input.onBlur}
                allowSelectAll
                hasError={meta.touched && meta.error}
              />
            )
          }
        </FinalField>
        <FinalField
          name="parameters"
          validate={validateRequired}
        >
          {
            ({ input, meta }) => (
              <Select
                label={t('thiamis.download_csv.parameters')}
                value={input.value}
                onChange={input.onChange}
                options={parameterOptions}
                isMulti
                allowSelectAll
                onBlur={input.onBlur}
                isLoading={isLoadingParameters}
                hasError={meta.touched && meta.error}
              />
            )
          }
        </FinalField>
        <div className="flex-row">
          <FinalField
            name="from"
            validate={validateRequired}
          >
            {
            ({ input, meta }) => (
              <DatePicker
                label={t('thiamis.download_csv.from')}
                value={input.value}
                onBlur={input.onBlur}
                onChange={input.onChange}
                hasError={meta.touched && meta.error}
                maxDate={to || tomorrow}
              />
            )
          }
          </FinalField>
          <FinalField
            name="to"
          >
            {
            ({ input }) => (
              <DatePicker
                label={t('thiamis.download_csv.to')}
                value={input.value}
                onChange={input.onChange}
                minDate={from || tomorrow}
                maxDate={tomorrow}
              />
            )
          }
          </FinalField>
        </div>
      </div>
      <div className="form-group form-actions clearfix">
        <button type="button" className="btn btn-default" onClick={handleCancel}>
          {t('base.buttons.cancel')}
        </button>
        <button
          type="submit"
          className="save-form btn btn-primary"
          disabled={hasValidationErrors}
          onClick={handleSubmit}
        >
          {t('thiamis.download_csv.download')}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
