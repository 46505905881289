export const fonts = { sansSerif: 'Source Sans Pro' };

export const scale = {
  small: '12px',
  fineprint: '14px',
  copy: '16px',
  subtitle: '20px',
  title: '24px'
};

export const fontSizes = {
  panelHeader: scale.fineprint,
  formTitle: scale.subtitle,
  inputLabel: scale.fineprint,
  inputValue: scale.fineprint
};
