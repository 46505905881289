import React from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import history from '~/store/history';

const withErrorBoundary = (WrappedComponent) => (props) => {
  const { t } = useTranslation();
  const { path, ...restProps } = props;
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        if (path) {
          scope.setTag('location', path);
        }
      }}
      fallback={() => (
        <div className="container-fluid">
          <div className="error-message">
            <h3>{t('notifications.errors.system_error')}</h3>
            <p>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  history.go(0);
                }}
              >
                Refresh Page
              </button>
            </p>
          </div>
        </div>
      )}
    >
      <WrappedComponent {...restProps} />
    </Sentry.ErrorBoundary>
  );
};

export default withErrorBoundary;
