import React, {
  useCallback, useMemo, useState
} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUserId } from '~/store/selectors/global';
import { getUserOrganizationRolesForAPI } from '~/store/selectors/users';
import { claimRequest } from '~/store/reducers/nodes';
import ClaimDeviceForm from './claim_form';
import ResultsData from './results_data';
import TopBar from './top_bar';

const ClaimDeviceScreen = React.memo(() => {
  const { t } = useTranslation();
  const [result, setResult] = useState([]);
  const [submitting, setSubmitting] = useState();
  const { search } = useLocation();
  const serials = useMemo(() => new URLSearchParams(search).get('serials'), [search]);
  const org = useMemo(() => new URLSearchParams(search).get('organization'), [search]);
  const dispatch = useDispatch();

  const organizations = useSelector((state) => {
    const currentUserId = getCurrentUserId(state);
    return getUserOrganizationRolesForAPI(state, currentUserId);
  });

  const createdOrganization = useMemo(() => (org
    ? organizations.find((item) => org === item.organizationId)
    : null),
  [organizations, org]);

  const initialData = useMemo(() => ({
    serials: serials || '',
    organization: createdOrganization
      ? { label: createdOrganization.organizationName, value: createdOrganization.organizationId }
      : undefined
  }), [serials, createdOrganization]);

  const handleSuccess = useCallback((response, organization) => {
    const saved = Object.keys(response).map((serial) => ({
      serial,
      organization,
      status: response[serial]
    }));
    const statusesFailed = saved.filter((item) => !item.status);
    if (!statusesFailed.length) {
      toast.success(
        t('claim.success_claim'),
        { toastId: 'success_device_claim' }
      );
    } else if (statusesFailed.length === saved.length) {
      toast.error(
        t('claim.error_claim'),
        { toastId: 'error_device_claim' }
      );
    } else {
      toast.warning(
        t('claim.warning_claim'),
        { toastId: 'error_device_claim' }
      );
    }
    setResult(saved);
    setSubmitting(false);
  }, [setResult, setSubmitting, t]);

  const handleError = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const submit = useCallback((values) => {
    setSubmitting(true);
    dispatch(claimRequest({
      serials: values.serials.trim(),
      organization: values.organization,
      onSuccess: handleSuccess,
      onError: handleError
    }));
  }, [dispatch, handleSuccess, handleError]);

  return (
    <div className="details-form-page claim-device-form tabed-details-page">
      <TopBar />
      <FinalForm
        onSubmit={submit}
        initialValues={initialData}
      >
        {({ handleSubmit, invalid }) => (
          <div className="row tab-content form">
            <div className="col-sm-6">
              <ClaimDeviceForm
                handleSubmit={handleSubmit}
                invalid={invalid}
                submitting={submitting}
              />
            </div>
            <div className="col-sm-6">
              <ResultsData
                results={result}
              />
            </div>
          </div>
        )}
      </FinalForm>
    </div>
  );
});

export default ClaimDeviceScreen;
