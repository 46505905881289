import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import includes from 'lodash/includes';
import kebabCase from 'lodash/kebabCase';
import without from 'lodash/without';
import sortBy from 'lodash/sortBy';
import I18n from 'app/config/i18n';
import FiltersSidebarPanelItem from './filters_sidebar_panel_item';
import { getFirmwaresFilterTags } from '../selectors';
import { filterListByTag } from '../reducer';

const FiltersSidebarPanel = React.memo((props) => {
  const {
    group, order, mapper, collapsedIn, filters
  } = props;

  const { currentTags } = useSelector(
    (state) => ({
      currentTags: getFirmwaresFilterTags(state)
    })
  );
  const dispatch = useDispatch();
  const handleChooseFilter = (type) => {
    const isSelected = includes(currentTags, type);
    dispatch(filterListByTag(isSelected ? without(currentTags, type) : [...currentTags, type]));
  };

  if (filters.all === 0) {
    return null;
  }

  let counts = order || Object.keys(filters.counts).sort();
  if (mapper) {
    counts = sortBy(counts, (type) => mapper(type));
  }

  return (
    <div className={`panel panel-default ${group}-filters-wrap`}>
      <div className="panel-heading">
        <h4 className="panel-title">
          <a data-toggle="collapse" data-parent="#filters-wrap" href={`#${group}`}>
            <span className="badge group-count">{filters.all}</span>
            <span>{I18n.t(`filters.${group}.title`)}</span>
            <i className="fa fa-chevron-down" />
          </a>
        </h4>
      </div>
      <div id={group} className={classNames('panel-collapse', 'collapse', { in: collapsedIn })}>
        <ul>
          {counts.map((type) => (
            filters.counts[type]
              ? (
                <FiltersSidebarPanelItem
                  handleChoose={handleChooseFilter}
                  active={includes(currentTags, type)}
                  key={type}
                  type={type}
                  label={I18n.t(`filters.${group}.${kebabCase(type)}`, { defaultValue: mapper ? mapper(type) : type })}
                  count={filters.counts[type]}
                />
              )
              : null
          ))}
        </ul>
      </div>
    </div>
  );
});

export default FiltersSidebarPanel;
