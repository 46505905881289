import React from 'react';
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap';
import withTooltip from '~/hocs/withTooltip';
import Select from './select';

const FormSelect = ({
  label, helpText, errorText, id, tooltip, alignTextRight = true, ...rest
}) => {
  const IconTooltip = ({ title, tooltipRef }) => (
    <span ref={tooltipRef} data-tip={title}><i className="fa fa-fw fa-info-circle" /></span>
  );
  const Tooltip = withTooltip(IconTooltip);
  return (
    <FormGroup validationState={errorText ? 'error' : undefined}>
      {
        label && (
          <ControlLabel htmlFor={id}>
            {label}
            {tooltip && (<Tooltip title={tooltip} />)}
          </ControlLabel>
        )
      }
      <Select
        id={id}
        hasError={!!errorText}
        {...rest}
      />
      {
        helpText && (
          <HelpBlock>{helpText}</HelpBlock>
        )
      }
      {
        errorText && (
          <HelpBlock className={alignTextRight && 'align-right'}>
            {errorText}
          </HelpBlock>
        )
      }
    </FormGroup>
  );
};

export default FormSelect;
