/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import MapWidgetContentView from 'app/backbone/lib/widgets/map_widget/face_view';
import HeightFilling from 'app/backbone/lib/concerns/views/height_filling';

const StaticMapHeightFilling = HeightFilling.extend({
  offsetTop() { return 0; },

  onResize() {
    return this.fillHeight();
  },

  fillInEl() {
    return this.$el.closest('.thiamis-box');
  }
});

const StaticMapWidgetContentView = MapWidgetContentView.extend({
  behaviors: [StaticMapHeightFilling],

  initialize({ thiamis, thiamisDevice }) {
    this.thiamis = thiamis;
    this.thiamisDevice = thiamisDevice;
    return this.listenTo(this.thiamisDevice, 'change:location_description', () => this.model.trigger('change:node_id', null, null, { stickitChange: true }));
  }
});

export default StaticMapWidgetContentView;
