import { createSelector } from 'reselect';
import get from 'lodash/get';
import some from 'lodash/some';
import * as fromWebhooks from '~/store/reducers/webhooks';
import { getOrganizations } from '~/store/reducers/organizations';
import { getGlobalOrganizationId } from '~/store/selectors/global';
import { getFilter } from './reducer';

export const getWebhookIds = createSelector(
  [fromWebhooks.getWebhookIds, fromWebhooks.getWebhooks, getGlobalOrganizationId],
  (ids, webhooks, organizationId) => ids.filter((id) => {
    if (organizationId && organizationId !== 'all') {
      return webhooks[id].organization_id === organizationId;
    }
    return true;
  })
);

export const getWebhooks = createSelector(
  [getWebhookIds, fromWebhooks.getWebhooks],
  (ids, webhooks) => ids.map((id) => ({ ...webhooks[id], ...webhooks[id].last_response }))
);

export const getFilteredList = createSelector(
  getWebhooks,
  getOrganizations,
  getFilter,
  (webhooks, organizations, { query }) => {
    let results = webhooks.map((webhook) => {
      const nodeId = get(webhook, 'permissions[0].scope.query.node_id', null);
      return {
        ...webhook,
        organization_name: organizations[webhook.organization_id]?.name,
        device_scope: !!nodeId,
        nodeId
      };
    });
    if (query) {
      const keywords = _(query.split(/\s+/)).chain().without('')
        .map((t) => t.toLowerCase())
        .value();
      results = results.filter((webhook) => {
        const { url } = webhook;
        const searchString = [url].join(' ').toLowerCase();
        return some(keywords, (kw) => searchString.match(RegExp.escape(kw)));
      });
    }
    return results;
  }
);
