import React, { useState, useEffect } from 'react';

const withLastTimeUpdated = (WrappedComponent) => (props) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((_counter) => _counter + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <WrappedComponent {...{ ...props }} counter={counter} />;
};

export default withLastTimeUpdated;
