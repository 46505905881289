/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import classNames from 'classnames';
import I18n from 'app/config/i18n';

const ThiamisLocation = View.extend({

  ui: {
    locationIcon: '.location-icon',
    locationIconI: '.location-icon i'
  },

  bindings: {
    '@ui.locationIconI': {
      attributes: [{
        name: 'class',
        observe: 'auto_location',
        onGet() {
          const locationType = `location-${this.getThiamisDevice().getLocationType()}`;
          return classNames(locationType, { 'is-wifi': this.getThiamis().hasWifiGpsSource() });
        }
      }
      ]
    },
    '@ui.locationIcon': {
      observe: 'auto_location',
      update($el) {
        const type = this.getThiamisDevice().getLocationType();
        let title = I18n.t(`thiamis.tooltips.location_determined_${type}`);
        if ((type === 'auto') && this.getThiamis().hasWifiGpsSource()) {
          title = I18n.t('thiamis.tooltips.location_determined_auto_wifi');
        }
        return $el.data('bs.tooltip', false).tooltip({
          html: true,
          title
        });
      }
    }
  },

  getThiamis() {
    return this.thiamis;
  }
});

export default ThiamisLocation;
