/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import classNames from 'classnames';
import DeviceStatus from 'app/backbone/lib/react/components/common/DeviceStatus/DeviceStatus';

const DeviceTitle = (props) => (
  <h4 className={classNames('device-name', isArray(props.statusText) ? props.statusText.join(' ') : props.statusText
  )}>
    <DeviceStatus statusText={props.statusText} statusType={props.statusType} />
    <b className="display-name" title={props.displayName}>
      <b className="truncated">{props.displayName}</b>
      <i className="fa fa-sm" />
    </b>
    {props.children}
  </h4>
);

DeviceTitle.defaultProps = {
  children: [],
  statusText: '',
  displayName: ''
};

DeviceTitle.propTypes = {
  children: PropTypes.any,
  statusText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  displayName: PropTypes.string,
  statusType: PropTypes.string.isRequired
};

export default DeviceTitle;
