/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import ReactDOM from 'react-dom';
import Radio from 'backbone.radio';
import React from 'react';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import I18n from 'app/config/i18n';
import { app as App } from 'app/backbone/app';
import SensorDeviceTitle from 'app/backbone/lib/react/components/common/SensorDeviceTitle/SensorDeviceTitle';
import faceTmpl from './templates/face.hbs';
import sensorTmpl from './templates/sensor.hbs';

const monitorChannel = Radio.channel('monitor');

export const SensorWidgetItemView = View.extend({
  template: sensorTmpl,
  className: 'col-sm-4',

  bindings: {
    '.sensor-serial': {
      observe: 'serial',
      onGet() {
        return this.model.get('serial');
      },
      update($el, val) {
        return this.updateSensorInfo($el, val);
      },
      attributes: [{
        name: 'class',
        onGet() {
          if (_.isUndefined(this.model.get('serial'))) { return 'hidden'; }
        }
      }
      ]
    },
    '.sensor-icon': {
      attributes: [{
        name: 'class',
        observe: 'name',
        onGet() {
          return this.model.get('display_name_und');
        }
      }
      ]
    },
    '.sensor-enabled': 'enabled'
  },

  ui:
    { sensorName: '.sensor-name' },

  triggers:
    { 'click .btn-configure': 'click:sensor:configure' },

  modelEvents:
    { 'change:enabled': 'onConfigurationChange' },

  initialize({ thiamis }) {
    this.thiamis = thiamis;
  },

  onConfigurationChange() {
    return this.model.save(null, {
      thiamisId: this.thiamis.id,
      devices: this.thiamis.get('devices').toJSON({ savedByForm: true }),
      success: () => App.getChannel().trigger('flash:message', 'success', I18n.t('thiamis.sesor_update_success'))
    }
    );
  },

  onClickSensorConfigure() {
    return monitorChannel.trigger('configure:sensor', this.model.id);
  },

  serializeData() {
    const hasConfiguration = this.model.hasConfiguration('enabled');
    const isModifiable = this.model.getThiamis().isModifiable();
    return {
      isModifiable,
      hasConfigurationAndIsModifiable: hasConfiguration && isModifiable
    };
  },

  updateSensorInfo($el, val) {
    return $el.find('span').text(val);
  },

  _getSensorNameComponent() {
    return React.createElement(SensorDeviceTitle, _(this).pick('model'));
  },

  onBeforeDetach() {
    return ReactDOM.unmountComponentAtNode(this.ui.sensorName[0]);
  },

  onRender() {
    ReactDOM.render(this._getSensorNameComponent(), this.ui.sensorName[0]);
    return this.stickit();
  }
});

export const SensorsCollectionView = AppCollectionView.extend({
  childView: SensorWidgetItemView,
  className: 'row',

  childViewOptions() {
    return _(this.options).pick('thiamis');
  }
});

const SensorWidgetContentView = View.extend({
  template: faceTmpl,

  regions:
    { sensorsRegion: '.sensors-region' },

  initialize({ thiamis }) {
    this.thiamis = thiamis;
  },

  onRender() {
    return this.getRegion('sensorsRegion').show(new SensorsCollectionView({
      collection: this.thiamis.getSensors(),
      thiamis: this.thiamis
    })
    );
  }
});

export default SensorWidgetContentView;
