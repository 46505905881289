import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import intersectionBy from 'lodash/intersectionBy';
import includes from 'lodash/includes';
import flatten from 'lodash/flatten';
import round from 'lodash/round';
import { getMeasurementsById } from '~/store/reducers/measurements';
import { getDeviceById } from '~/store/reducers/devices';
import * as fromDataPoints from '~/store/reducers/data_points';
import { getOrganizations } from '~/store/reducers/organizations';
import { getNodesByOrganizationId } from '~/store/selectors/nodes';
import { parsePath } from '~/store/selectors/data_points';
import { getDeviceProfileNamesByNodeId } from '~/store/selectors/devices';

export const isAirthinx = (serial) => startsWith(serial, '0C') || startsWith(serial, '1C');

export const getAnalyticsMeasurementsByIds = (state, ids) => flatten(ids.map((id) => get(getMeasurementsById(state, id), 'data', [])));

export const getIntersectedDataPoints = (state, dataPoints = []) => intersectionBy(
  ...dataPoints,
  (iter) => {
    const { device, path } = parsePath(iter.path);
    return `${device}:${path}`;
  }
);

export const getAnalyticsMeasurementsForWorkingHours = createSelector(
  getAnalyticsMeasurementsByIds,
  (measurements, from = 9, to = 17) => measurements.filter(([ts]) => {
    const hour = moment(ts).hour();
    const day = moment(ts).weekday();
    return hour >= from && hour <= to && day !== 6 && day !== 0;
  })
);

export const getProfileByDeviceId = (state, id) => {
  const device = getDeviceById(state, id);
  return (device && device.profiles && device.profiles[0]) || {};
};

export const getProfileNamesForNodes = (state, nodeIds = []) => nodeIds.map(
  (nodeId) => getDeviceProfileNamesByNodeId(state, nodeId)
);

export const getDataPoints = createSelector(
  fromDataPoints.getDataPoints,
  (dataPoints) => Object.values(dataPoints)
);

export const getDataPointIdsByPath = createSelector(
  getDataPoints,
  (state, path) => path,
  (dataPoints, path) => dataPoints.filter(({ path: dpPath }) => includes(dpPath, path)).map(({ _id: dataPointId }) => dataPointId)
);

export const getAnalyticsDataPointStatusPercentage = createSelector(
  (state, profile) => profile,
  (state, profile, measurements) => measurements,
  (state, profile, measurements, path) => path,
  (profile, measurements, path) => {
    const { ranges, status_name: statusNames } = profile;
    const selectedRange = ranges[path];
    const statuses = measurements.reduce(((result, [, value]) => { // eslint-disable-line no-unused-vars
      const { status } = selectedRange.find((range) => {
        const {
          gte, lte, gt, lt
        } = range;
        const func = [];
        if (!isNil(gte)) func.push(`value >= ${gte}`);
        if (!isNil(lte)) func.push(`value <= ${lte}`);
        if (!isNil(gt)) func.push(`value > ${gt}`);
        if (!isNil(lt)) func.push(`value < ${lt}`);
        return eval(func.join(' && ')); // eslint-disable-line no-eval
      }) || last(selectedRange);
      const statusName = statusNames[status];
      if (!result[statusName]) {
        result[statusName] = 0; // eslint-disable-line no-param-reassign
      }
      result[statusName] += 1; // eslint-disable-line no-param-reassign
      return result;
    }), {});
    return Object.entries(statuses).map(([status, num]) => ([status, round((num / measurements.length) * 100, 1)]));
  }
);

export const getOrganizationsForDropdown = createSelector(
  getOrganizations,
  (organizations = {}) => Object.values(organizations)
    .map(({ _id, name }) => ({
      value: _id,
      label: name
    }))
    .filter(({ label }) => !!label)
    .sort((organization1, organization2) => organization1.label.localeCompare(organization2.label))
);

export const getDevicesForDropdown = createSelector(
  getNodesByOrganizationId,
  (nodes) => nodes
    .filter((node) => !!node.name && isAirthinx(node.serial))
    .sort((node1, node2) => node1.name.localeCompare(node2.name))
    .map(({ _id, serial, name }) => ({
      value: _id,
      label: `${serial} - ${name}`
    }))
);

export const getAnalyticsFormInitialValues = createSelector(
  getOrganizationsForDropdown,
  (organizations = []) => {
    if (organizations.length === 1) {
      return {
        organization: organizations[0],
        devices: []
      };
    }
    return {
      organization: null, // TODO: select from top menu
      devices: []
    };
  }
);

export const getCommonParamsForDevices = createSelector(
  getIntersectedDataPoints,
  (intersected = []) => intersected
    .map((item) => {
      const { device, path } = parsePath(item.path);
      return {
        ...item,
        parameterPath: `${device}:${path}`
      };
    })
    .sort((dp1, dp2) => dp1.name.localeCompare(dp2.name))
);

export const getCommonProfilesForDevices = createSelector(
  getProfileNamesForNodes,
  (profileNames = []) => intersection(...profileNames)
    .sort()
    .map((item) => ({
      value: item,
      label: item
    }))
);
