/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { app as App } from 'app/backbone/app';
import I18n from 'app/config/i18n';
import AppModel from 'app/backbone/lib/entities/app_model';
import ClusternetBridge from 'app/backbone/lib/concerns/entities/clusternet_bridge';
import DeviceStatus from 'app/backbone/lib/concerns/entities/device_status';
import { MIN_REPORTING_INTERVAL } from 'app/backbone/entities/nodes/config';
import { __guard__, __guardMethod__ } from 'app/utils/custom-fns';

export default class DataPoint extends DeviceStatus(ClusternetBridge(AppModel)) {
  static WIND_SPEED_NAMES = [/^wind speed \(avg\)$/, /^wind speed \(10 min\)$/, /^wind speed$/, /^\(s1\) wind speed$/, /^\(s2\) wind speed$/, /^\(s3\) wind speed$/]
  static PATHS = {
    BATTERY_VOLTAGE: 1,
    BATTERY_CURRENT: 2,
    GSM_NETWORK: 3,
    GSM_SIGNAL: 4,
    IMEI: 5,
    IMSI: 7,
    ICCID: 6,
    LOCATION: 8,
    WIFI_SIGNAL: 10,
    WIFI_SECURITY: 11,
    WIFI_NETWORK: 12,
    WIFI_BSSID: 13,
    FIRMWARE: 15,
    DEBUG: 16,
    TIMEZONE: 18,
    LOCATION_DESCRIPTION: 17,
    TEMPERATURE: 19,
    MAC_ADDRESS: 21,
    ONLINE: 22,
    GPS_ERROR: 26,
    WIFI_NETWORKS: 30,
    GPS_SOURCE: 31,
    VOC_CALIBRATION_1_PPM: '140:21',
    VOC_CALIBRATION_1_VALUE: '140:22',
    VOC_CALIBRATION_2_PPM: '140:23',
    VOC_CALIBRATION_2_VALUE: '140:24',
    VOC_CALIBRATION_3_PPM: '140:25',
    VOC_CALIBRATION_3_VALUE: '140:26'
  }

  get defaults() {
    return {
      unit: '',
      measurements: [],
      _measurements: []
    };
  }

  get mutators() {
    return {
      measurements: {
        transient: true,
        get() { return this.get('_measurements'); },
        set(key, newMeasurements, options = {}) {
          const { initiate } = options;
          if (initiate) {
            return this._initiateMeasurements(newMeasurements, options);
          }
          if (_(newMeasurements).isEmpty()) { return; }
          if (this.measurementsInitiated) {
            return this._augmentMeasurements(newMeasurements, options);
          }
          if (!this.augmentedSets) {
            this.augmentedSets = [];
          }
          return this.augmentedSets.push([newMeasurements, options]);
        }
      },
      timestamp: {
        transient: true,
        get() { return this._timestamp; }
      },
      value: {
        transient: true,
        get() { return this._value; }
      },
      sluged_name: {
        transient: true,
        get() { return _.slugify(this.presentName()); }
      },
      status_text: {
        transient: true,
        get() {
          const lastTs = this.getLastMeasurementTs();
          return I18n.t('node_widget.last_time_reported', { value: _.isUndefined(lastTs) ? I18n.t('time.never') : jQuery.timeago(lastTs) });
        }
      },
      device_id: {
        transient: true,
        get() {
          if (this.get('linked')) { return __guard__(this.collection.get(this.get('linked')[0]), (x) => x.get('device_id')); }
          return this.attributes.device_id;
        }
      },
      path: {
        transient: true,
        get() {
          if (this.get('linked')) {
            return __guard__(this.collection != null ? this.collection.get(this.get('linked')) : undefined, (x) => x.get('path'));
          }
          return this.attributes.path;
        }
      }
    };
  }

  initialize(attrs) {
    super.initialize(attrs);
    // eslint-disable-next-line no-prototype-builtins
    if (attrs != null ? attrs.hasOwnProperty('measurements') : undefined) { return this.set('measurements', attrs.measurements, { initiate: true }); }
  }

  getStatusType(thiamis) {
    const reportingInterval = __guardMethod__(thiamis, 'getReportingInterval', (x) => x.getReportingInterval()) || MIN_REPORTING_INTERVAL;
    if (this.isDeviceReporting(this.getLastMeasurementTs(), reportingInterval)) {
      return 'online';
    }
    return 'offline';
  }

  getReportingInterval() {
    if (this.collection.parents != null && this.collection.parents[0] != null) {
      return __guardMethod__(this.collection.parents[0], 'getReportingInterval', (x) => x.getReportingInterval());
    }
  }

  sync(method) {
    if (!_.contains(['create', 'delete'], method)) {
      throw new Error('Unsupported method for nodes/:node_id/metric.');
    }
    return super.sync(...arguments); // eslint-disable-line prefer-rest-params
  }

  urlRoot() {
    return this.clusternet.nodesMetricsUrl(this.get('node_id'));
  }

  mergedMeasurements(updates) {
    return this.get('_measurements').concat(updates);
  }

  _initiateMeasurements(newMeasurements, options) {
    const updatedMeasurements = newMeasurements;
    this._value = undefined;
    this._timestamp = undefined;
    this._updateAggregatedVals(newMeasurements);
    this.set('_measurements', updatedMeasurements, options);
    this.trigger('change change:timestamp change:value', this);
    this.measurementsInitiated = true;
    this.trigger('initiate:measurements', this, newMeasurements);
    while (!_(this.augmentedSets).isEmpty()) {
      const [set, prevOptions] = this.augmentedSets.shift();
      this._augmentMeasurements(set, prevOptions);
    }
  }

  _augmentMeasurements(newMeasurements, options) {
    const updatedMeasurements = this.mergedMeasurements(newMeasurements);
    this._updateAggregatedVals(newMeasurements);
    this.set('_measurements', updatedMeasurements, options);
    this.trigger('change change:timestamp change:value', this);
    return this.trigger('augment:measurements', this, newMeasurements);
  }

  _updateAggregatedVals(newMeasurements) {
    const newTimestamp = __guard__(_.last(newMeasurements), (x) => x[0]);
    if (this._timestamp == null) { this._timestamp = newTimestamp; }
    if (newTimestamp >= this._timestamp) {
      this._value = __guard__(_.last(newMeasurements), (x1) => x1[1]);
      return this._timestamp = newTimestamp;
    }
  }

  presentName() {
    if (_.isEmpty(this.get('unit'))) {
      return this.get('name');
    } return `${this.get('name')} ${this.get('unit')}`;
  }

  getChartSeriesId() {
    // FIXME: move to chart view
    return `${this.id}-series`;
  }

  getChartAxisId() {
    // FIXME: move to chart view
    return `${this.id}-axis`;
  }

  getDisplayValue(value) {
    // eslint-disable-next-line no-param-reassign
    if (value == null) { value = this.get('value'); }
    if (!_.isDefined(value)) { return undefined; }
    if (this.isWindDirection()) {
      return _.azimut2cardinal(value);
    } return `${_.round(value)}`;
  }

  isError() {
    return this.get('_type') === 'error';
  }

  isMetric() {
    return this.get('_type') === 'metric';
  }

  isAdmin() {
    return this.get('access') === 'admin';
  }

  isActiveError() {
    return this.isError() && this.getLastMeasurement();
  }

  isLocation() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.LOCATION));
  }

  isGpsError() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.GPS_ERROR)) && (this.getLastMeasurement() === 1);
  }

  isWindDirection() {
    return /^wind direction$/.test(__guard__(this.get('name'), (x) => x.toLowerCase()));
  }

  isWindSpeed() {
    const name = __guard__(this.get('name'), (x) => x.toLowerCase());
    return _(this.WIND_SPEED_NAMES).some((windName) => windName.test(name));
  }

  isSTEL() {
    const { device, path } = this.parsePath();
    // eslint-disable-next-line radix
    return _.contains([181, 182, 183], parseInt(device)) && _.contains(_.range(7, 12), parseInt(path));
  }

  isThiamis() {
    return _.endsWith(this.get('device_id'), ':0');
  }

  isDefault() {
    const isCorrectType = !_.contains(['info', 'status', 'location', 'debug', 'error', 'raw', 'config'], this.get('_type'));
    if (App.getChannel().request('get:current:user').isSupport()) { return isCorrectType; }
    const { device, path } = this.parsePath();
    return isCorrectType && !_.contains(['0:23', '0:24', '0:25'], `${device}:${path}`);
  }

  isRaw() {
    return this.get('_type') === 'raw';
  }

  isThiamisBatteryVoltage() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.BATTERY_VOLTAGE));
  }

  isThiamisGsmSignal() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.GSM_SIGNAL));
  }

  isThiamisWifiSignal() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.WIFI_SIGNAL));
  }

  isThiamisGpsSoure() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.GPS_SOURCE));
  }

  isWifiSource() {
    return this.isThiamisGpsSoure() && (this.getLastMeasurement() === 'wifi');
  }

  isThiamisBatteryCurrent() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.BATTERY_CURRENT));
  }

  isThiamisOnline() {
    return this.isThiamis() && (this.get('path') === this.getPathFrom(DataPoint.PATHS.ONLINE));
  }

  isSensorOnline() {
    return !this.isThiamis() && this.get('name') === 'Status';
  }

  getPathTypeSlug() {
    return (_.invert(this.PATHS))[this.parsePath().path].toLowerCase();
  }

  getPathFrom(pathId) {
    return `${this.get('device_id')}:${pathId}`;
  }

  getShortPath() {
    const { device, path } = this.parsePath();
    return `${device}:${path}`;
  }

  parsePath(inputPath) {
    try {
      // eslint-disable-next-line no-param-reassign
      if (!inputPath) { inputPath = this.get('path'); }
      if (!inputPath) { return {}; }
      const [serial, device, path] = Array.from(inputPath.split(':'));
      return { serial, device, path };
    } catch (e) {
      // eslint-disable-next-line no-console
      return console.error(`invalid path for ${this.id} data_point`);
    }
  }

  getLastMeasurement() {
    return __guard__(_.last(this.get('measurements')), (x) => x[1]);
  }

  getLastMeasurementTs() {
    return __guard__(_.last(this.get('measurements')), (x) => x[0]);
  }
}
