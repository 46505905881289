/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const fetchFirmwaresRequest = createAction('@@firmwares/FETCH_REQUEST');
export const fetchFirmwaresSuccess = createAction('@@firmwares/FETCH_SUCCESS');
export const fetchFirmwaresFailure = createAction('@@firmwares/FETCH_FAILURE');

export const rolloutFirmwaresRequest = createAction('@@firmwares/ROLLOUT_REQUEST');
export const rolloutFirmwaresSuccess = createAction('@@firmwares/ROLLOUT_SUCCESS');
export const rolloutFirmwaresFailure = createAction('@@firmwares/ROLLOUT_FAILURE');

export const filterListByOrganizationId = createAction('@@firmwaresList/FILTER_BY_OID');
export const filterListByQuery = createAction('@@firmwaresList/FILTER_BY_QUERY');
export const clearFilters = createAction('@@firmwaresList/CLEAR_FILTERS');
export const filterListByTag = createAction('@@firmwaresList/FILTER_BY_TAG');
export const expandList = createAction('@@firmwaresList/EXPAND_ROW');

const initialFilter = { query: null, tags: [], oid: null };
const filter = createReducer(initialFilter, {
  [clearFilters]() {
    return initialFilter;
  },
  [filterListByOrganizationId](state, { payload }) {
    state.oid = payload;
  },
  [filterListByQuery](state, { payload }) {
    state.query = payload;
  },
  [filterListByTag](state, { payload }) {
    state.tags = payload;
  }
});

const idsInitialState = [];
const ids = createReducer(idsInitialState, {
  [fetchFirmwaresSuccess](state, { payload: { result } }) {
    return result;
  }
});

const byIdData = {};
const byId = createReducer(byIdData, {
  [fetchFirmwaresSuccess](state, { payload }) {
    if (payload.entities) {
      return get(payload.entities, 'firmwares', byIdData);
    }
    return state;
  },
  [rolloutFirmwaresSuccess](state, { payload }) {
    if (payload.entities) {
      return {
        ...state,
        ...get(payload.entities, 'firmwares', {})
      };
    }
    return state;
  }
});

const isFetched = createReducer(false, {
  [fetchFirmwaresRequest]() {
    return false;
  },
  [fetchFirmwaresFailure]() {
    return false;
  },
  [fetchFirmwaresSuccess]() {
    return true;
  }
});

const firmwaresList = combineReducers({
  filter,
  isFetched,
  ids,
  byId
});

export const getFirmwares = (state) => state.firmwaresList.byId;
export const getFirmwareById = (state, id) => state.firmwaresList.byId[id];
export const getIds = (state) => state.firmwaresList.ids;
export const getIsFetched = (state) => state.firmwaresList.isFetched;
export const getFilter = (state) => state.firmwaresList.filter;

export default firmwaresList;
