import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { getDataPointParameterById } from 'app/react/store/selectors/data_points';
import {
  getWidgetDataPointIds, getWidgetByIdName
} from '~/store/selectors/widgets';
import { AIRTHIX_PARAMETER_PATHS } from '~/utils/constants';

import AirQualityIcon from '~/assets/icons/parameters/icon-air-quality.jsvg';
import CO2Icon from '~/assets/icons/parameters/icon-carbon-dioxide.jsvg';
import FormaldehydeIcon from '~/assets/icons/parameters/icon-formaldehyde.jsvg';
import HumidityIcon from '~/assets/icons/parameters/icon-humidity.jsvg';
import PMIcon from '~/assets/icons/parameters/icon-particulare-matter.jsvg';
import TemperatureIcon from '~/assets/icons/parameters/icon-temperature.jsvg';
import VOCIcon from '~/assets/icons/parameters/icon-volatile-compounds.jsvg';

const ParameterIcon = (parameter) => {
  switch (parameter) {
    case AIRTHIX_PARAMETER_PATHS.AQ:
      return AirQualityIcon;
    case AIRTHIX_PARAMETER_PATHS.CO2:
      return CO2Icon;
    case AIRTHIX_PARAMETER_PATHS.CH2O:
      return FormaldehydeIcon;
    case AIRTHIX_PARAMETER_PATHS.HUMIDITY:
      return HumidityIcon;
    case AIRTHIX_PARAMETER_PATHS.PM:
    case AIRTHIX_PARAMETER_PATHS.PM1:
    case AIRTHIX_PARAMETER_PATHS.PM10:
    case AIRTHIX_PARAMETER_PATHS.PM25:
      return PMIcon;
    case AIRTHIX_PARAMETER_PATHS.TEMPERATURE_C:
    case AIRTHIX_PARAMETER_PATHS.TEMPERATURE_F:
      return TemperatureIcon;
    case AIRTHIX_PARAMETER_PATHS.VOC_ETOH:
    case AIRTHIX_PARAMETER_PATHS.VOC_ISOBUTYLENE:
      return VOCIcon;
    default:
      return null;
  }
};

const Icon = styled('div')`
  width: 28px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 5px;
  & svg {
    fill: #666;
    margin: 0 auto;
    display: block;
  }
`;

const TitleContainer = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
`;

const HeatmapWidgetTitle = ({ widgetId }) => {
  const name = useSelector((state) => getWidgetByIdName(state, widgetId));
  const dataPointIds = useSelector((state) => getWidgetDataPointIds(state, widgetId));
  const parameter = useSelector((state) => getDataPointParameterById(state, dataPointIds[0]), [dataPointIds]);
  const ParamIcon = ParameterIcon(parameter?.path);

  return (
    <TitleContainer>
      <OverlayTrigger
        placement="top"
        overlay={<BootstrapTooltip id={parameter?.path}>{parameter?.name}</BootstrapTooltip>}
      >
        <Icon>
          {ParamIcon && (<ParamIcon />)}
        </Icon>
      </OverlayTrigger>
      {name}
    </TitleContainer>
  );
};

HeatmapWidgetTitle.propTypes = {
  widgetId: PropTypes.string.isRequired
};

export default HeatmapWidgetTitle;
