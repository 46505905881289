import { datadogRum as datadog } from '@datadog/browser-rum';
import { getReleaseName } from 'app/utils/custom-fns';

const { CI } = process.env;
const { DATADOG_CLIENT_TOKEN } = process.env;
const { DATADOG_APPLICATION_ID } = process.env;

(() => {
  if (CI) {
    datadog.init({
      version: getReleaseName(),
      clientToken: DATADOG_CLIENT_TOKEN,
      applicationId: DATADOG_APPLICATION_ID,
      trackInteractions: true,
      sampleRate: 100,
      defaultPrivacyLevel: 'allow'
    });
    datadog.startSessionReplayRecording();
  }
})();
