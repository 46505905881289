/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { app as App } from 'app/backbone/app';
import * as Sentry from '@sentry/browser';

App.getChannel().reply('error:reporting:identify', (user) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user.pick('email'));
  });
});

App.getChannel().reply('report:exception', (message) => {
  Sentry.captureException(message);
});
App.getChannel().reply('report:message', (message, data) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('extra', data);
  });
  Sentry.captureMessage(message);
});
