/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import WebsocketsService from '@netronixgroup/phoenix-streams';
import * as Sentry from '@sentry/browser';
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import { api as auth0Api } from 'app/utils/auth0_handler'; // eslint-disable-line import/no-cycle

export const API = MnObject.extend({
  channelName: 'clusternet:sockets',
  radioRequests: {
    'get:instance': 'getSocketInstance',
    connect: 'connect',
    subscribe: 'subscribe',
    update: 'update'
  },

  subscribe(event, callback) {
    this.getSocketInstance().on(event).subscribe(callback, (e) => {
      Sentry.captureException(e);
    });
  },

  update(data) {
    this.getSocketInstance().send('update', data);
  },

  connect() {
    this.getSocketInstance().connect();
  },

  getSocketInstance() {
    if (this.socket) { return this.socket; }
    this.socket = new WebsocketsService(App.getChannel().request('clusternet:ws:url'), {
      params() {
        return {
          token: auth0Api.getChannel().request('get:instance').getIdToken()
        };
      }
    });
    this.socket.connect();
    return this.socket;
  }
});

export const api = new API();
