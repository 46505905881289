import { createWebhookRequest } from 'app/react/store/reducers/webhooks';
import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '~/components/modal';

import ModalForm from './modal_form';

const CreateWebhookModal = ({ isVisible, toggleModal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSuccess = useCallback(() => {
    toast.success(
      t('notifications.success.forms.create_webhook'),
      { toastId: 'success_webhook' }
    );
    toggleModal();
  }, [toggleModal, t]);

  const handleCreateWebhook = useCallback((values) => {
    const {
      organization, url, auth, type, nodes
    } = values;

    dispatch(createWebhookRequest({
      organization: organization.value,
      url,
      auth,
      _type: type?.value,
      nodes: nodes?.map((node) => node.value),
      onSuccess: handleSuccess
    }));
  }, [
    dispatch, handleSuccess
  ]);

  return (
    <Modal
      isVisible={isVisible}
      handleCancel={toggleModal}
      title={t('webhooks.create')}
    >
      <div className="dialog-content-region">
        <FinalForm
          onSubmit={handleCreateWebhook}
          subscription={{ submitting: true, pristine: true, valid: true }}
        >
          {({ handleSubmit }) => (
            <div className="form form-horizontal profile">
              <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />
            </div>
          )}
        </FinalForm>
      </div>
    </Modal>
  );
};

export default CreateWebhookModal;
