import { View } from 'backbone.marionette';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import ReactView from 'app/backbone/lib/views/react_view';
import DialogRegion from 'app/backbone/lib/regions/dialog_region';
import MainRegion from 'app/backbone/lib/regions/main_region';
import { app as App } from 'app/backbone/app';
import ReactApp from '~/app';
import Header from '~/components/header';
import store from '~/store';
import template from './app_layout.hbs';

const ReactAppView = ReactView.extend({
  router() {
    return React.createElement(ReactApp);
  }
});

export default View.extend({
  template,

  regions: {
    navigationRegion: '.left-panel',
    dialogRegion: {
      selector: '.dialog-region',
      regionClass: DialogRegion
    },
    mainRegion: MainRegion,
    reactAppRegion: '.main-content-react'
  },

  ui: {
    headerRegion: '.header-region'
  },

  getHeaderComponent() {
    return (
      <Provider store={store}>
        <Header />
      </Provider>
    );
  },

  onRender() {
    this.getRegion('reactAppRegion').show(new ReactAppView());
    App.on('before:start', () => {
      ReactDOM.render(this.getHeaderComponent(), this.ui.headerRegion[0]);
    });
  }
});
