import React, {
  useEffect, useState, useCallback, useContext
} from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import ManageAlertsContext from '../../manage_context';
import { getAlertActions, getActionsForAlertId } from '../../selectors';
import ActionsModal from './manage_modal/actions_modal';

const manageActions = [
  ['activate', 'deactivate']
];

const ActionsDropdown = ({ handleToggleDropdown }) => {
  const [showHelpText, setShowHelpText] = useState(false);
  const [actionType, setActionType] = useState(null);
  const { selectedAlerts } = useContext(ManageAlertsContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (showHelpText && !!selectedAlerts.length) {
      setShowHelpText(false);
    }
  }, [showHelpText, selectedAlerts]);

  const {
    actions,
    availableActions
  } = useSelector((state) => {
    const nodeActions = getAlertActions(state);
    const grouped = manageActions.map((group) => intersection(group, nodeActions)).filter((group) => !!group.length);
    const available = intersection(
      ...selectedAlerts.map((nodeId) => getActionsForAlertId(state, nodeId))
    );
    const all = grouped.map((item, index) => {
      if (index !== grouped.length - 1) {
        return [...item, 'separator'];
      }
      return item;
    });
    return {
      actions: all,
      availableActions: available
    };
  });

  const deselectActionType = useCallback(() => setActionType(null), [setActionType]);

  const isActionAvailable = useCallback((action) => includes(availableActions, action), [availableActions]);

  const handleActionClick = useCallback((action) => () => {
    if (!selectedAlerts.length) {
      setShowHelpText(true);
      return;
    }
    if (!isActionAvailable(action)) {
      return;
    }
    setActionType(action);
    handleToggleDropdown();
  }, [handleToggleDropdown, isActionAvailable, selectedAlerts, setShowHelpText]);

  return (
    <>
      <a
        role="button"
        key="dropdown"
        className="btn btn-default dropdown-toggle"
        onClick={handleToggleDropdown}
        title={t('thiamis.tooltips.available_actions')}
      >
        <span className="caret-arrow">
          <span className="dropdown-actions">{t('base.labels.actions')}</span>
          <i className="fa" />
        </span>
      </a>
      ,
      <ul key="actions" className="dropdown-menu dropdown-menu-right">
        { _.map(_.flatten(actions), (action, index) => {
          if (action === 'separator') {
            return <li role="separator" className="divider" key={index} />;
          }
          return (
            <li key={index}>
              <a role="button" tabIndex={0} onClick={handleActionClick(action)} disabled={!isActionAvailable(action)}>
                {t(`thiamis.manage_actions.${action}`)}
              </a>
            </li>
          );
        }) }
      </ul>
      { showHelpText && <span className={classNames('help-text')}>{t('thiamis.manage_help_text')}</span> }
      <ActionsModal type={actionType} toggleModal={deselectActionType} />
    </>
  );
};

export default ActionsDropdown;
