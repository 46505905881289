var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-toggle=\"dropdown\" href=\"#\" class=\"fact dropdown-toggle\"><span class=\"number add-number\">+</span><span>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"dashboard.add_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":1,"column":112},"end":{"line":1,"column":143}}}))
    + "</span></div>\n<div class=\"row dropdown-menu\">\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.chart_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":98}}}))
    + "\" class=\"new_chart_widget\"><i class=\"fa fa-fw fa-line-chart\"></i></a></div>\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.node_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":97}}}))
    + "\" class=\"new_node_widget\"><i class=\"fa fa-fw fa-list\"></i></a></div>\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.map_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":96}}}))
    + "\" class=\"new_map_widget\"><i class=\"fa fa-fw fa-map\"></i></a></div>\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.blocks_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":68},"end":{"line":6,"column":99}}}))
    + "\" class=\"new_blocks_widget\"><i class=\"fa fa-fw fa-th-large\"></i></a></div>\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.text_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":7,"column":68},"end":{"line":7,"column":97}}}))
    + "\" class=\"new_text_widget\"><i class=\"fa fa-fw fa-file-text-o\"></i></a></div>\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.twitter_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":8,"column":68},"end":{"line":8,"column":100}}}))
    + "\" class=\"new_twitter_widget\"><i class=\"fa fa-fw fa-twitter\"></i></a></div>\n  <div class=\"col-sm-2\"><a tabindex=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"-1") || (depth0 != null ? lookupProperty(depth0,"-1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"-1","hash":{},"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" title=\""
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"models.heatmap_widget",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":9,"column":68},"end":{"line":9,"column":100}}}))
    + "\" class=\"new_airthinx_widget\"><i class=\"fa fa-fw fa-table\"></i></a></div>\n</div>";
},"useData":true});