import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import compact from 'lodash/compact';
import I18n from 'app/config/i18n';
import ParamInfoIcon from 'app/backbone/lib/react/sensors/paramInfoIcon/paramInfoIcon';
import { getDefaultDataPointIdsByNodeId } from '~/store/selectors/data_points';
import { getLastMeasurementTs } from '~/store/selectors/measurements';
import withLastTimeUpdated from './with_last_time_updated';

function getDisplayValue(ts) {
  if (ts) {
    if (_.isUndefined(ts) || (ts === -Infinity)) return undefined;
    return jQuery.timeago(ts);
  }
  return undefined;
}

const LastTimeUpdatedContainer = ({ nodeId }) => {
  const {
    ts
  } = useSelector(
    (state) => {
      const ids = getDefaultDataPointIdsByNodeId(state, nodeId);
      return {
        ts: compact(ids.map((id) => getLastMeasurementTs(state, id))).sort().reverse()[0]
      };
    }
  );

  return (
    <ParamInfoIcon
      value={getDisplayValue(ts)}
      displayValue={getDisplayValue(ts)}
      iconClass="fa fa-fw fa-refresh"
      title={I18n.t('thiamis.more_info.last_updated')}
      noValueText={I18n.t('base.labels.n_a')}
    />
  );
};

LastTimeUpdatedContainer.propTypes = {
  nodeId: PropTypes.string.isRequired
};

export default withLastTimeUpdated(LastTimeUpdatedContainer);
