import {
  useEffect, useState, useRef, useCallback
} from 'react';
import debounce from 'lodash/debounce';

export default function useHover(delayMouseOver = 0) {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = useCallback(() => setValue(true), []);
  const debouncedMouseOver = debounce(handleMouseOver, delayMouseOver);

  const handleMouseOut = useCallback(() => {
    debouncedMouseOver.cancel();
    setValue(false);
  }, [debouncedMouseOver]);

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', debouncedMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', debouncedMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current]
  );
  return [ref, value];
}
