import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  VirtualTable,
  TableHeaderRow
} from '@devexpress/dx-react-grid-bootstrap3';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import includes from 'lodash/includes';
import { getOrganizationById } from '~/store/reducers/organizations';
import { getAlertById } from '~/store/reducers/alerts';

import ManageAlertsContext from '../../../manage_context';

const ModalTable = ({ disabled = [] }) => {
  const { selectedAlerts } = useContext(ManageAlertsContext);
  const { t } = useTranslation();

  const maxHeight = useMemo(() => (220), []);

  const Row = (props) => {
    const { row } = props;
    return (
      <VirtualTable.Row
        {...props}
        className={classNames('body-row', { 'disabled-row': includes(disabled, row?._id) })}
      />
    );
  };

  const Root = (props) => <Grid.Root {...props} style={{ maxHeight: 220, height: 'unset' }} />;

  const columns = useMemo(() => ([
    { name: 'displayName', title: t('attributes.node.name') },
    { name: 'owner', title: t('thiamis.owner') }
  ]), [t]);

  const {
    devices
  } = useSelector((state) => {
    const alerts = selectedAlerts.map((alertId) => getAlertById(state, alertId));
    const alertsWithOrganization = alerts.map((alert) => {
      const organization = getOrganizationById(state, alert?.organization_id);
      return {
        ...alert,
        displayName: alert.name,
        organization,
        owner: organization && organization.name
      };
    });
    const sortedAlerts = alertsWithOrganization
      .sort((alert1, alert2) => alert1.displayName?.localeCompare(alert2.displayName));
    return {
      devices: sortedAlerts
    };
  });

  let tableHeight = (devices.length + 1) * 38;

  if (tableHeight > maxHeight) {
    tableHeight = maxHeight;
  }

  return (
    <Grid
      rows={devices}
      columns={columns}
      rootComponent={Root}
    >
      <VirtualTable height={tableHeight} rowComponent={Row} />
      <TableHeaderRow showSortingControls={false} />
    </Grid>
  );
};

export default ModalTable;
