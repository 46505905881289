/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { app as App } from 'app/backbone/app'; // eslint-disable-line import/no-cycle
import FilteredCollection from 'app/backbone/lib/entities/filtered_collection';

// TODO: possbily not necessary, remove when all references were deleted
export default class FilteredListColl extends FilteredCollection {
  initialize({ filterInputModel }) {
    this.filterInputModel = filterInputModel;
    this.filterByFilterTags();
    this.filterBySearchKeywords();
    return this.listenTo(this.filterInputModel, 'change', function () { return this.refilter(); });
  }

  filterByFilterTags() {
    return this.filterBy('filter tags', (model) => {
      // OPTIMIZE: filter ids are collected on every model
      const filterIds = this.filterInputModel.getChosenFilterIds();
      if (_(filterIds).isEmpty()) { return true; }
      return _.some(filterIds, (filter) => _.contains(model.get('filter_tags'), filter));
    });
  }

  filterBySearchKeywords() {
    return this.filterBy('search keywords', (model) => {
      const searchKeywords = this.filterInputModel.getKeywords();
      if (_(searchKeywords).isEmpty()) { return true; }
      const modelSearchString = model.get('search_text');
      if (_(modelSearchString).isEmpty()) { return false; }
      return _.some(searchKeywords, (kw) => modelSearchString.match(RegExp.escape(kw)));
    });
  }

  cleanSearchFilter() {
    return App.getChannel().request('clean:search_input');
  }

  getChosen() {
    return this.filter((model) => model.isChosen());
  }

  getSort() {
    const sort = {};
    if (this._sorted) {
      sort.comparator = this._sorted._comparator;
      sort.dirrection = this._sorted._reverse ? 'desc' : 'asc';
    }
    return sort;
  }
}
