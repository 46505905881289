/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import { api as apiRegistry } from 'app/backbone/lib/utilities/registry';
import DialogLayout from 'app/backbone/lib/components/shared_views/dialog_layout';
import OpacityView from 'app/backbone/lib/components/loading/opacity_view';
import SpinnerView from 'app/backbone/lib/components/loading/spinner_view';
import { ServerErrorView } from 'app/backbone/lib/components/shared_views/errors';

export default class AppController extends MnObject {
  initialize(options = {}) {
    super.initialize(options);
    this.region = options.region || App.getChannel().request('get:default:region');
    this._instance_id = _.uniqueId('controller');
    apiRegistry.getChannel().request('register:instance', this, this._instance_id);
  }

  destroy() {
    super.destroy(...arguments); // eslint-disable-line prefer-rest-params
    return apiRegistry.getChannel().request('unregister:instance', this, this._instance_id);
  }

  show(view, options = {}) {
    _.defaults(options, {
      loading: false,
      region: this.region || options.region
    }
    );
    this.setMainView(view);
    this.listenTo(view, 'destroy', this.destroy);
    return this._manageView(view, options);
  }

  showForm(formView, options = {}) {
    ({ formModel: this.formModel } = formView);
    return this.show(formView, options);
  }

  showDialog(contentView, options = {}) {
    this.dialogLayout = new DialogLayout(options);

    this.listenTo(this.dialogLayout, 'attach', () => {
      _.defaults(options,
        { region: this.dialogLayout.getRegion('contentRegion') });

      return this._manageView(contentView, options);
    });

    return App.getView().getRegion('dialogRegion').show(this.dialogLayout, options);
  }

  setMainView(view) {
    if (this._mainView == null) { this._mainView = view; }
    return this.listenTo(view, 'destroy', this.destroy);
  }

  _manageView(view, options) {
    if (options.loading) {
      return this._getLoadingController(view, options);
    }
    options.region.show(view);
  }

  _getLoadingController(view, options) {
    // eslint-disable-next-line no-use-before-define
    return new LoadingController({
      view,
      region: options.region,
      config: options.loading
    });
  }
}

export class LoadingController extends AppController {
  initialize(options) {
    super.initialize(options);
    const { view } = options;
    this.realView = view;

    this.config = options.config;
    this.config = _.isBoolean(this.config) ? {} : this.config;

    _.defaults(this.config, {
      loadingType: 'spinner',
      entities: this.getEntities(),
      debug: false
    }
    );

    const loadingView = this.getLoadingView();
    this.show(loadingView);

    _.chain([this.config.entities]).flatten().each((entity) => (typeof entity.on === 'function' ? entity.on('error', (_entity, response) => {
      if (response.status === 500) {
        return this.show(new ServerErrorView());
      }
    }) : undefined));

    return this.showRealViewInsteadOf(loadingView);
  }

  showRealViewInsteadOf(loadingView) {
    return App.getChannel().request('when:fetched', this.config.entities, () => {
      // # ...after the entities are fetched, execute this callback
      // # ================================================================ ##
      // # If the region we are trying to insert is not the loadingView then
      // # we know the user has navigated to a different page while the loading
      // # view was still open. In that case, we know to manually close the original
      // # view so its controller is also closed.  We also prevent showing the real
      // # view (which would snap the user back to the old view unexpectedly)
      // # ================================================================ ##

      // @_loadedViewEl()?.removeAttr 'style'
      if (this.region.currentView !== loadingView) {
        return this.realView.destroy();
      }

      // # show the real view unless we've set debug in the loading options
      if (!this.config.debug) { return this.show(this.realView); }
    });
  }

  getEntities() {
    // # return the entities manually set during configuration, or just pull
    // # off the model and collection from the view (if they exist)
    return _.chain(this.realView).pick('model', 'collection').toArray().compact()
      .value();
  }

  getLoadingView() {
    const ViewClass = this._loadingViewClass();
    return new ViewClass({
      coveredView: this.region.currentView });
  }

  _loadingViewClass() {
    switch (this.config.loadingType) {
      case 'opacity': return OpacityView;
      case 'spinner': return SpinnerView;
      default: throw new Error('Invalid loadingType');
    }
  }
}
