var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"clearfix flex-center-wrap\">\n  <div class=\"thiamis-img\"></div>\n  <ul class=\"thiamis-info\">\n    <li><b>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.serial",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":47}}}))
    + "</b><span class=\"thiamis-serial\"></span></li>\n    <li><b>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.model",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":46}}}))
    + "</b><span class=\"thiamis-model\"></span></li>\n    <li><b>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.firmware",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":6,"column":11},"end":{"line":6,"column":49}}}))
    + "</b><span class=\"thiamis-firmware\"></span></li>\n    <li><b>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.iccid",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":46}}}))
    + "</b><span class=\"thiamis-iccid\"></span></li>\n    <li><b>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.imei",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":8,"column":11},"end":{"line":8,"column":45}}}))
    + "</b><span class=\"thiamis-imei\"></span></li>\n    <li><b>"
    + alias2(__default(require("../../../../../config/hbs-helpers/i18t.js")).call(alias1,"attributes.thiamis.mac_address",{"name":"i18t","hash":{},"data":data,"loc":{"start":{"line":9,"column":11},"end":{"line":9,"column":52}}}))
    + "</b><span class=\"thiamis-mac-address\"></span></li>\n  </ul>\n</div>\n<div class=\"big-sensor-box clearfix\">\n  <div class=\"params-container\"></div>\n</div>";
},"useData":true});