import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Button, Text, Container } from './styles';
import { resetPasswordRequest } from '~/store/reducers/profile';
import { getProfileIsSyncing } from '~/store/selectors/profile';

const Form = ({ syncing }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSuccess = useCallback((email) => {
    toast.success(
      t('user_profile.pw_change_info', { email }),
      { toastId: 'success_reset_password' }
    );
  }, [t]);

  const onResetButtonClick = useCallback(() => {
    dispatch(resetPasswordRequest({
      onSuccess: handleSuccess
    }));
  }, [dispatch, handleSuccess]);

  return (
    <Container>
      <Text>
        {t('user_profile.password_help_text')}
      </Text>
      <Button
        disabled={syncing}
        onClick={onResetButtonClick}
      >
        {t('user_profile.set_new_password')}
      </Button>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  syncing: getProfileIsSyncing(state)
});

export default connect(mapStateToProps)(Form);
