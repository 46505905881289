import React from 'react';
import isNaN from 'lodash/isNaN';
import humanizeDuration from 'humanize-duration';

export const DurationFormatter = ({ value }) => {
  if (isNaN(value)) { return null; }
  return (
    <span>
      { humanizeDuration(value, { round: true }) }
    </span>
  );
};
