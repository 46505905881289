import React, {
  useCallback, useState, useContext, useEffect
} from 'react';
import classNames from 'classnames';
import includes from 'lodash/includes';
import {
  SortingState,
  RowDetailState,
  SelectionState,
  DataTypeProvider,
  IntegratedSorting,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, TableHeaderRow, TableRowDetail, TableSelection
} from '@devexpress/dx-react-grid-bootstrap3';
import attributeName from 'app/config/hbs-helpers/attributeName';
import DetailRowColumnOrder from '~/components/react_grid/detail_row_column_order';
import ExpandButton from '~/components/react_grid/expand_button';
import GridDetailContainer from './detail_row';
import { CreatedAtFormatter, StatusFormatter, ParamertsFormatter } from './formatters';
import ManageCalibrationsContext from '../manage_context';

const StatusProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={StatusFormatter}
  />
);

const ParametersProvider = (props) => (
  <DataTypeProvider
    {...props}
    formatterComponent={ParamertsFormatter}
  />
);

export const CreatedAtProvider = (props) => (
  <DataTypeProvider
    formatterComponent={CreatedAtFormatter}
    {...props}
  />
);

const statusWeights = {
  ok: 0,
  pending: 1,
  fail: 1
};

const compareStatuses = (a, b) => {
  const statusA = statusWeights[a];
  const statusB = statusWeights[b];
  if (statusA === statusB) {
    return 0;
  }
  return (statusA < statusB) ? -1 : 1;
};

const getRowId = ({ node_id }) => node_id;

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} className="dx-grid-container calibrations-dx-grid-container" />;

const columns = [
  { name: 'serial', title: attributeName('calibration', 'serial') },
  { name: 'parameters', title: attributeName('calibration', 'parameters') },
  { name: 'status', title: attributeName('calibration', 'status') },
  { name: 'timestamp', title: attributeName('calibration', 'timestamp') }
];

const Table = ({ slug, rows }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const {
    isActive: selectionActive,
    selectedCalibrations: selection,
    setSelectedCalibrations: setSelection
  } = useContext(ManageCalibrationsContext);

  useEffect(() => {
    setSelection([]);
  }, [setSelection, slug]);

  const [sorting, setSorting] = useState([{ columnName: 'timestamp', direction: 'desc' }, { columnName: 'status', direction: 'desc' }]);

  const Row = React.memo((props) => {
    const { row: { node_id } } = props;
    const isExpanded = includes(expandedRowIds, node_id);

    const handleExpandRowClick = useCallback(() => {
      if (includes(expandedRowIds, node_id)) {
        setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== node_id));
      } else {
        setExpandedRowIds([...expandedRowIds, ...[node_id]]);
      }
    }, [node_id]);
    return (
      <VirtualTable.Row
        {...props}
        onClick={handleExpandRowClick}
        className={classNames('body-row', { 'expanded-row': isExpanded })}
      />
    );
  });

  const handleSortingChange = useCallback((payload) => setSorting(payload), []);

  return (
    <Grid
      rows={rows}
      columns={columns}
      getRowId={getRowId}
      rootComponent={Root}
    >
      <SortingState
        sorting={sorting}
        onSortingChange={handleSortingChange}
      />
      <IntegratedSorting
        columnExtensions={[
          { columnName: 'status', compare: compareStatuses }
        ]}
      />
      <SelectionState
        selection={selection || []}
        onSelectionChange={setSelection}
      />
      <IntegratedSelection />
      <StatusProvider
        for={['status']}
      />
      <ParametersProvider
        for={['parameters']}
      />
      <CreatedAtProvider
        for={['timestamp']}
      />
      <VirtualTable
        rowComponent={Row}
        columnExtensions={[
          { columnName: 'serial', width: 150 },
          { columnName: 'status', width: 125 },
          { columnName: 'timestamp', width: 125 }
        ]}
      />
      { selectionActive && <TableSelection showSelectAll /> }
      <RowDetailState
        expandedRowIds={expandedRowIds}
        onExpandedRowIdsChange={setExpandedRowIds}
      />
      <TableRowDetail
        contentComponent={GridDetailContainer}
        toggleCellComponent={ExpandButton}
      />
      <DetailRowColumnOrder />
      <TableHeaderRow align="center" showSortingControls />
    </Grid>
  );
};

export default Table;
