/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: { tooltip: '[data-toggle="tooltip"]' },

  onAttach() {
    if (this.ui.tooltip && !this.ui.tooltip.data('bs.popover')) {
      return this.ui.tooltip.tooltip();
    }
  }
});
