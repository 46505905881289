/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import { isMobile } from 'app/utils/resolution_helpers';
import faceTemplate from './templates/face.hbs';

const TwitterWidgetContentView = View.extend({
  template: faceTemplate,

  serializeData() {
    return { twitterName: 'aethair' };
  },

  onDestroy() {
    return $('#twitter-wjs').remove();
  },

  onRender() {
    if (isMobile()) {
      return this.$el.find('.twitter-timeline').attr('data-tweet-limit', 3);
    }
  }
});

export default TwitterWidgetContentView;
