import { fork } from 'redux-saga/effects';
import devices from '~/screens/devices/saga';
import alertScreen from '~/screens/alerts/saga';
import filesScreen from '~/screens/files/saga';
import firmwaresScreen from '~/screens/firmwares/saga';
import calibrationScreen from '~/screens/calibration/saga';
import nodes from './nodes';
import organizations from './organizations';
import partnerships from './partnerships';
import alerts from './alerts';
import users from './users';
import invitations from './invitations';
import reports from './reports';
import stats from './stats';
import apis from './apis';
import webhooks from './webhooks';
import certificates from './certificates';
import templates from './templates';
import profile from './profile';

export default function* root() {
  yield fork(users);
  yield fork(organizations);
  yield fork(nodes);
  yield fork(reports);
  yield fork(stats);
  yield fork(devices);
  yield fork(partnerships);
  yield fork(alerts);
  yield fork(alertScreen);
  yield fork(filesScreen);
  yield fork(firmwaresScreen);
  yield fork(invitations);
  yield fork(apis);
  yield fork(webhooks);
  yield fork(certificates);
  yield fork(templates);
  yield fork(profile);
  yield fork(calibrationScreen);
}
