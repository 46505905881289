/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import DeviceTitle from 'app/backbone/lib/react/components/common/DeviceTitle/DeviceTitle';
import { withSelfUpdatable } from 'app/backbone/lib/react/hocs';
import BackboneReactComponent from 'app/backbone/lib/react/hocs/BackboneReactComponent';
import { __guard__ } from 'app/utils/custom-fns';

class DataPointTitle extends Component {
  getSensorTag() {
    if (!this.props.getModel().isThiamis()) {
      return __guard__(this.props.thiamis != null ? this.props.thiamis.getSensors().get(this.props.getModel().get('device_id')) : undefined, (x) => x.get('tag'));
    }
  }

  render() {
    const displayName = this.props.getModel().get('name');
    const statusType = this.props.getModel().getStatusType(this.props.thiamis);
    const statusText = this.props.getModel().get('status_text');
    const sensorTag = this.getSensorTag();

    return (
      <DeviceTitle displayName={displayName} statusType={statusType} statusText={statusText}>
        { !isEmpty(sensorTag)
          ? <small>{sensorTag}</small> : undefined }
      </DeviceTitle>
    );
  }
}

DataPointTitle.defaultProps = {
  thiamis: null
};

DataPointTitle.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  interval: PropTypes.number.isRequired,
  getModel: PropTypes.func.isRequired,
  thiamis: PropTypes.any
};

export default BackboneReactComponent(withSelfUpdatable(DataPointTitle));
