/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS202: Simplify dynamic range loops
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { View } from 'backbone.marionette';
import Radio from 'backbone.radio';
import I18n from 'app/config/i18n';
import AppCollectionView from 'app/backbone/lib/views/app_collection_view';
import { __guardMethod__ } from 'app/utils/custom-fns';
import chartEmptyParamsTpl from './templates/chart_empty_params.hbs';
import chartParamsTpl from './templates/chart_params.hbs';
import chartParamTpl from './templates/chart_param.hbs';

const monitorChannel = Radio.channel('monitor');

export const ChartEmptyParams = View.extend({
  template: chartEmptyParamsTpl
});

export const ChartParam = View.extend({
  className: 'sensor-box-container col-sm-4',
  template: chartParamTpl,

  triggers:
    { 'click .remove': 'remove:parameter' },

  attributes() {
    return { id: `${this.model.id}-param` };
  },

  initialize({ thiamis }) {
    this.thiamis = thiamis;
  },

  bindings() {
    return {
      '.param-name': {
        observe: 'name',
        updateMethod: 'html',
        onGet() {
          if (_.isEmpty(this.model.get('unit'))) {
            return this.model.get('name');
          } return `${this.model.get('name')} <small>${this.model.get('unit')}</small>`;
        },
        attributes: [{
          name: 'style',
          observe: '_id',
          onGet() {
            const color = this.getParamColor();
            if (color) { return `color: ${color};`; }
          }
        }
        ]
      },
      '.sensor-name small': {
        observe: 'device_id',
        onGet(deviceId) {
          if (!this.model.isThiamis()) { return __guardMethod__(__guardMethod__(this.thiamis.getSensors(), 'get', (x2) => x2.get(deviceId)), 'get', (x) => x.get('tag')); }
        }
      },
      '.sensor-name span': {
        observe: 'device_id',
        onGet(deviceId) {
          if (this.model.isThiamis()) {
            return __guardMethod__(this.thiamis.getDevice(), 'get', (x) => x.get('display_name'));
          } return __guardMethod__(__guardMethod__(this.thiamis.getSensors(), 'get', (x2) => x2.get(deviceId)), 'get', (x1) => x1.get('display_name'));
        }
      },
      '.sensor-box': {
        attributes: [{
          observe: 'measurements',
          name: 'class',
          onGet() {
            if (_.isEmpty(this.model.get('measurements'))) { return 'empty'; }
          }
        }
        ]
      }
    };
  },

  serializeData() {
    return this.getParamValue();
  },

  onRemoveParameter() {
    return monitorChannel.trigger('remove:chart:param', this.model);
  },

  getParamColor() {
    return monitorChannel.request('get:chart:series:color', this.model);
  },

  getParamValue() {
    let max;
    const data = monitorChannel.request('get:chart:series:data', this.model);
    if (!data) { return; }
    // eslint-disable-next-line no-multi-assign
    let min = max = data[0];
    let sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0, end = data.length - 1, asc = end >= 0; asc ? i <= end : i >= end; asc ? i++ : i--) {
      if (data[i] < min) { min = data[i]; }
      if (data[i] > max) { max = data[i]; }
      sum += data[i];
    }
    const precision = this.model.get('round') || _.getAvgDecimalsPoint(_.last(data, 5));
    const avg = _.round(sum / data.length, precision > 4 ? 4 : precision);
    return {
      min: $.isNumeric(min) ? min : I18n.t('base.labels.n_a'),
      max: $.isNumeric(max) ? max : I18n.t('base.labels.n_a'),
      avg: $.isNumeric(avg) ? avg : I18n.t('base.labels.n_a')
    };
  },

  onRender() {
    return this.stickit();
  }
});

const ChartParams = AppCollectionView.extend({
  template: chartParamsTpl,
  childView: ChartParam,
  childViewContainer: '.sensor-list',
  emptyView: ChartEmptyParams,
  className: 'chart-parameters',

  ui:
    { theadTh: 'thead th' },

  buildChildView(model, ItemViewType, childViewOptions = {}) {
    return new ItemViewType(
      _(childViewOptions).extend({
        model,
        thiamis: this.options.thiamis
      }
      )
    );
  },

  onRemoveChild() {
    if (this.collection.size() === 0) { return this.ui.theadTh.hide(); }
  },

  onBeforeAddChild() {
    if (this.isRendered()) {
      this.ui.theadTh.show();
    }
  }
});

export default ChartParams;
