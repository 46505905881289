import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import { AQI_LABELS } from '~/utils/constants';
import { getWidgetDataPointIds } from '~/store/selectors/widgets';
import { getHeatmapWidgetById } from './reducer';
import { getDataPointById, getDataPointsWithProfileByNodeId, getPath } from '~/store/selectors/data_points';
import { getNodeStatus } from '~/store/selectors/nodes';
import { getDataPointStatusById } from '~/store/selectors/profiles';
import { getLastMeasurementTs } from '~/store/selectors/measurements';

const AQI_LEGEND_SORT = {
  [AQI_LABELS.EXCELLENT]: 0,
  [AQI_LABELS.GOOD]: 1,
  [AQI_LABELS.SATISFACTORY]: 2,
  [AQI_LABELS.MODERATE]: 3,
  [AQI_LABELS.POOR]: 4,
  [AQI_LABELS.VERYPOOR]: 5,
  [AQI_LABELS.OFFLINE]: 6
};

export const getHeatmapWidgetLoaded = createSelector(
  getWidgetDataPointIds,
  (state) => state,
  (dataPoints, state) => {
    if (!dataPoints.length) return true;
    const dp = getDataPointById(state, dataPoints[0]);
    if (!dp || !dp._id) return false;
    return true;
  }
);

export const getHeatmapWidgetFilter = createSelector(
  getHeatmapWidgetById,
  (heatmapWidget) => heatmapWidget?.filter
);

export const getDataPointStatusWithOfflineById = createSelector(
  getDataPointById,
  getDataPointStatusById,
  getLastMeasurementTs,
  (state) => state,
  (dataPoint, data = {}, measurements, state) => {
    const nodeStatus = getNodeStatus(state, dataPoint?.node_id, measurements);
    const status = nodeStatus === AQI_LABELS.OFFLINE ? AQI_LABELS.OFFLINE : data?.status;
    return {
      ...data,
      status
    };
  }
);

export const getFilteredDataPointIds = createSelector(
  getWidgetDataPointIds, getHeatmapWidgetById, (state) => state,
  (dataPointIds, heatmapWidget = {}, state) => {
    const { filter } = heatmapWidget;
    if (!filter) {
      return dataPointIds;
    }
    return dataPointIds.filter((dpId) => {
      const { status } = getDataPointStatusWithOfflineById(state, dpId);
      return filter === status;
    });
  }
);

export const getDataPointForHeatmap = createSelector(
  getDataPointById,
  getDataPointStatusWithOfflineById,
  (dataPoint, data) => ({
    ...dataPoint,
    path: getPath(dataPoint),
    status: data?.status || AQI_LABELS.OFFLINE,
    lastValue: data?.value
  })
);

export const getAirthinxDataPointsByNodeId = createSelector(
  getDataPointsWithProfileByNodeId,
  ((state) => state),
  (dataPoints = [], state) => dataPoints
    .map((dataPoint) => {
      const data = getDataPointStatusById(state, dataPoint._id);
      return {
        ...dataPoint,
        lastValue: data?.value
      };
    })
    .filter((dp) => !!dp.lastValue)
    .sort((a, b) => a.name?.localeCompare(b.name))
);

export const getDataPointUniqueStatuses = (state, dataPointIds) => uniq(
  dataPointIds
    .map((dpId) => getDataPointStatusWithOfflineById(state, dpId)?.status)
    .filter((o) => !!o)
    .sort((a, b) => AQI_LEGEND_SORT[a] - AQI_LEGEND_SORT[b])
);
