import {
  call,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';
import requestApi from './requestApi';
import { normalizeResp } from '~/store/normalizr';
import * as Actions from '~/store/reducers/reports';
import * as schema from '~/store/schema';

export function* reportsFetchRequestSaga() {
  try {
    const { data: { reports } } = yield call(
      requestApi,
      'get',
      'reports',
      { dashboard: true }
    );
    const response = normalizeResp(reports, schema.reportsSchema);
    yield put(Actions.fetchReportsSuccess(response));
  } catch (e) {
    yield put(Actions.fetchReportsFailure(e.toString()));
  }
}

function* watchFetchReportsRequest() {
  yield takeLatest(Actions.fetchReportsRequest.toString(), reportsFetchRequestSaga);
}

export default function* app() {
  yield fork(watchFetchReportsRequest);
}
