import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import { getPartnershipsIsFetched } from 'app/react/store/reducers/partnerships';
import { useSelector } from 'react-redux';
import { getNodeById } from '~/store/reducers/nodes';
import ManageDevicesContext from '../../../manage_context';
import { getOrganizationsValueLabelPairsForShareLease } from '../../../selectors';

const ModalOrganizationPlain = ({
  type
}) => {
  const { input: { value: selectedOrganization, onChange } } = useField('selectedOrganization');
  const { selectedDevices } = useContext(ManageDevicesContext);
  const { t } = useTranslation();

  const { devices, isPartnershipFetched } = useSelector((state) => {
    const nodes = selectedDevices.map((deviceId) => getNodeById(state, deviceId));
    const organizations = getOrganizationsValueLabelPairsForShareLease(state);
    const nodeMapped = nodes.map((node) => {
      const organization = organizations.find((item) => item.value === node?.leased_to);
      return {
        ...node, leased: organization
      };
    });
    return {
      devices: nodeMapped,
      isPartnershipFetched: getPartnershipsIsFetched(state)
    };
  });

  useEffect(() => {
    if (!isPartnershipFetched) return;
    // form change on first render won't work, so that's why there's a need for timeout here
    setTimeout(() => {
      const leasedOrganization = devices[0]?.leased;
      onChange(leasedOrganization);
    }, 300);
    // !Important:
    // devices, which is dependant on selectedDevices, will always be a new
    // array while selectedDevices will not, so it is desired behavior to trigger this hook only
    // when selectedDevices list has been changed.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, selectedDevices, isPartnershipFetched]);

  if (type !== 'remove_lease') {
    return <div />;
  }

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="form-group">
          <label className="control-label">
            {t('models.organization')}
          </label>
          <span>
            {selectedOrganization?.label}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ModalOrganizationPlain;
