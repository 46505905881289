import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

export default function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWindowResize = useCallback(debounce(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, 100), []);

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleWindowResize);

    // Call handler right away so state gets updated with initial window size
    handleWindowResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]); // Empty array ensures that effect is only run on mount

  return windowSize;
}
