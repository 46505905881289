/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

require('Backbone.Chooser/build/backbone-chooser');

export const Chooser = (superclass) => class extends superclass {
  initialize(...args) {
    super.initialize(...args);
    return new Backbone.Chooser(this);
  }
};

export const SingleChooser = (superclass) => class extends superclass {
  initialize(...args) {
    super.initialize(...args);
    return new Backbone.SingleChooser(this);
  }
};

export const MultiChooser = (superclass) => class extends superclass {
  initialize(...args) {
    super.initialize(...args);
    return new Backbone.MultiChooser(this);
  }

  unchooseAll() {
    return this.each((model) => {
      if (model.isChosen()) { return model.unchoose(); }
    });
  }
};
