/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';

export const fetchTemplatesRequest = createAction('@@templates/FETCH_REQUEST');
export const fetchTemplatesSuccess = createAction('@@templates/FETCH_SUCCESS');
export const fetchTemplatesError = createAction('@@templates/FETCH_FAILURE');

const initialData = [];
const templates = createReducer(initialData, {
  [fetchTemplatesRequest]() {
    return [];
  },
  [fetchTemplatesSuccess](state, { payload }) {
    return payload;
  }
});

export const getTemplates = (state) => state.templates;

export default templates;
