import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class LinkButton extends Component {
  render() {
    return (
      <a
        ref={(elem) => this.linkButtonElem = elem}
        title={this.props.title}
        className={classNames(this.props.className)}
        role="button"
        tabIndex={0}
        onClick={this.props.onClick ? _.partial(this.props.onClick, this) : undefined}
      >
        {
        this.props.icon
          ? (<i className={`fa ${this.props.icon}`}>{ this.props.badge ? <span className="badge">{this.props.badge}</span> : undefined }</i>)
          : this.props.text
      }
      </a>
    );
  }
}

LinkButton.defaultProps = {
  badge: 0,
  text: '',
  icon: '',
  title: '',
  className: '',
  onClick: () => {}
};

LinkButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  badge: PropTypes.number
};

export default LinkButton;
