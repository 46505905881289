/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'app/config/i18n';
import { attributeName } from 'app/utils/i18n_helpers';
import Timestamp from 'app/backbone/lib/react/components/common/Timestamp/Timestamp';
import ListTable from 'app/backbone/lib/react/components/ListTable/ListTable';
import { __guard__ } from 'app/utils/custom-fns';

class MonitorTable extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSyncing: false,
      columns: [
        {
          name: 'timestamp',
          title: attributeName('message', 'time'),
          sortable: true,
          component: {
            name: Timestamp,
            props: _(this.props).pick('timezone')
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.props.dataPointsColl.on('add remove reset', this.updateColumns, this);
    this.props.collection.on('request', this.onCollectionRequest, this);
    this.props.collection.on('error sync', this.onCollectionSync, this);
    return this.updateColumns();
  }

  componentWillUnmount() {
    this.props.dataPointsColl.off('add remove reset');
    this.props.collection.off('request', this.onCollectionRequest);
    return this.props.collection.off('error sync', this.onCollectionSync);
  }

  onCollectionSync() {
    return this.setState({ isSyncing: false });
  }

  onCollectionRequest() {
    return this.setState({ isSyncing: true });
  }

  getColumn(dataPoint) {
    return {
      name: dataPoint.id,
      className: _.dasherize(dataPoint.get('name')).substring(1).replace('.', ''),
      sortable: true,
      title: {
        component: this.getColumnTitle(dataPoint)
      }
    };
  }

  getColumnTitle(dataPoint) {
    let title = dataPoint.get('name');
    let withTag = false;
    if (!_.isEmpty(dataPoint.get('unit'))) {
      title += ` (${dataPoint.get('unit')})`;
    }
    if (!dataPoint.isThiamis() && _.isDefined(this.props.thiamis)) {
      const tag = __guard__(this.props.thiamis.getSensors().get(dataPoint.get('device_id')), (x) => x.get('tag'));
      if (tag) {
        title += `<small>${tag}</small>`;
        withTag = true;
      }
    }

    // eslint-disable-next-line react/no-danger
    return <span data-withtag={withTag} dangerouslySetInnerHTML={{ __html: title }} />;
  }

  updateColumns() {
    const newColumns = [];
    newColumns.push(this.state.columns[0]);
    this.props.dataPointsColl.each((dp) => newColumns.push(this.getColumn(dp)));
    return this.setState({ columns: newColumns });
  }

  render() {
    if (this.state.isSyncing || this.props.isSyncing) {
      return <h5 className="text-center">{I18n.t('base.labels.loading')}</h5>;
    }
    if (this.props.dataPointsColl.length) {
      return <ListTable columns={this.state.columns} collection={this.props.collection} sortable={this.props.sortable} pagination={this.props.pagination} />;
    }
    return <h5>{this.props.emptyMsg}</h5>;
  }
}

MonitorTable.defaultProps = {
  dataPointsColl: null,
  thiamis: null,
  collection: null,
  sortable: false,
  pagination: {},
  emptyMsg: '',
  isSyncing: false
};

MonitorTable.propTypes = {
  dataPointsColl: PropTypes.any,
  thiamis: PropTypes.any,
  collection: PropTypes.any,
  sortable: PropTypes.bool,
  pagination: PropTypes.any,
  emptyMsg: PropTypes.string,
  isSyncing: PropTypes.bool
};

export default MonitorTable;
