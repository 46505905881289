/* eslint-disable no-param-reassign */
import { Spinner } from 'spin.js';

(function ($) {
  $.fn.spin = function (opts, color) {
    const presets = {
      tiny: { lines: 8, length: 2, width: 2, radius: 3 },
      small: { lines: 8, length: 4, width: 3, radius: 5 },
      large: { lines: 10, length: 8, width: 4, radius: 8 }
    };
    if (Spinner) {
      return this.each(function () {
        const $this = $(this);

        const data = $this.data();

        if (data.spinner) {
          data.spinner.stop();
          delete data.spinner;
        }
        if (opts !== false) {
          if (typeof opts === 'string') {
            if (opts in presets) {
              opts = presets[opts];
            } else {
              opts = {};
            }
            if (color) {
              opts.color = color;
            }
          }
          data.spinner = new Spinner($.extend({ color: $this.css('color') }, opts)).spin(this);
        }
      });
    }
    throw new Error('Spinner class not available.');
  };
}(jQuery));
