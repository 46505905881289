/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Behavior, bindEvents } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import I18n from 'app/config/i18n';
import { api as flashMessagesApi } from 'app/backbone/lib/utilities/flash_messages';

const FormControls = Behavior.extend({
  ui: {
    form: 'form',
    cancel: '.form-cancel',
    saveBtn: 'button[type="submit"]',
    deleteBtn: '.btn-delete'
  },

  triggers: {
    'click @ui.cancel': 'click:cancel',
    'click @ui.saveBtn': 'click:save:form',
    'click @ui.deleteBtn': 'click:delete'
  },

  events: {
    'click .error-message': 'onClickErrorMessage'
  },

  initialize() {
    this.view.formModel = this.getModel().getNewFormModel();

    this.listenTo(this.getModel(), 'request', () => {
      this.isRequesting = true;
      return this.disableSaveBtn();
    });

    this.listenTo(this.getModel(), 'sync error', () => {
      this.isRequesting = false;
      return this.disableSaveBtn(false);
    });

    this.listenTo(this.getModel(), 'validated', () => this.disableSaveBtn(false));

    Backbone.Validation.bind(this.view, { model: this.view.formModel, forceUpdate: true });
    if (this.formModelEvents) {
      bindEvents(this, this.view.formModel, _.result(this, 'formModelEvents'));
    } else if (this.view.formModelEvents) {
      bindEvents(this.view, this.view.formModel, _.result(this.view, 'formModelEvents'));
    }
  },

  disableSaveBtn(flag = true) {
    return (typeof this.ui.saveBtn.attr === 'function' ? this.ui.saveBtn.attr('disabled', flag) : undefined);
  },

  onClickCancel() {
    return this.closeForm();
  },

  onClickSaveForm() {
    return this.saveFormModel();
  },

  onClickErrorMessage(e) {
    const el = $(e.currentTarget);
    if (el.siblings('select').length > 0) {
      el.siblings('select').select2('open');
    } else if (el.siblings('input').length > 0) {
      el.siblings('input').focus();
    } else if (el.siblings('textarea').length > 0) {
      el.siblings('textarea').focus();
    }
    return el.remove();
  },

  getModel() {
    return this.view.options.model;
  },

  validateFormModel() {
    this.view.formModel.validate();
    this.view.formModel.trigger('after:validated');
    const isValid = this.validateAll(this.view.formModel);
    if (!isValid) {
      flashMessagesApi.getChannel().trigger('flash:message', 'error', I18n.t('notifications.errors.validation'));
    }
    return isValid;
  },

  validateAll(model) {
    let isValid = model.isValid(true);
    if (isValid === false) { return false; }
    for (const k in model.attributes) {
      const attr = model.attributes[k];
      // eslint-disable-next-line no-prototype-builtins
      if (_.isObject(attr) && attr.hasOwnProperty('attributes')) {
        isValid = this.validateAll(attr);
      }
    }
    return isValid;
  },

  onSaveSuccess() {
    if (this.view.onSaveSuccess) { this.view.onSaveSuccess(); }
    if (this.view.indexPath) { return flashMessagesApi.getChannel().trigger('flash:message', 'success', I18n.t(`notifications.success.forms.${this.view.indexPath}`)); }
  },

  closeForm() {
    if (this.isDialogView()) {
      return App.getView().getRegion('dialogRegion').hideDialog();
    } if (this.view.indexPath) {
      return App.navigate(this.view.indexPath); // TODO: App.getChannel().request(`app:${this.view.indexPath}`);
    }
  },

  saveFormModel() {
    if (!this.validateFormModel()) { return; }
    return this.saveModel();
  },

  saveModel() {
    return this.getModel().save(this.view.formModel.toJSON({ savedByForm: true }), this.getSaveOptions());
  },

  getSaveOptions() {
    return {
      wait: true,
      validate: false,
      renderRootKey: true,
      success: (model, jsonResponse) => {
        this.view.triggerMethod('save:success', model, jsonResponse);
        return this.closeForm();
      },
      error: (model, xhr) => {
        // unprocessableEntity = 422
        if (xhr.status === 422) { return this._renderErrors(xhr.responseJSON.errors); }
      }
    };
  },

  onRender() {
    this.$el.find('[data-toggle="popover"]').popover({
      container: 'body',
      placement: 'top auto',
      trigger: 'hover'
    });
    return this.view.stickit(this.view.formModel);
  },

  isDialogView() {
    return this.$el.parent().hasClass('dialog-content-region');
  },

  _renderErrors(errors) {
    return (() => {
      const result = [];
      for (const name in errors) {
        let error = errors[name];
        if (_(error).isArray()) { error = error.join(', '); }
        result.push(Backbone.Validation.callbacks.invalid(null, name, error, 'name'));
      }
      return result;
    })();
  }
});

export default FormControls;
