/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import WidgetBase from 'app/backbone/entities/widgets/widget_base';

export default class TwitterWidget extends WidgetBase {
  get defaults() { return { _type: 'TwitterWidget' }; }
}
