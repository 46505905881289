/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { MnObject } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import AppRouter from 'app/backbone/lib/routers/app_router';
import { api as analyticsApi } from 'app/backbone/entities/analytics';
import AnalyticsController from './show/show_controller';

class Router extends AppRouter {
  initialize() {
    super.initialize(...arguments); // eslint-disable-line prefer-rest-params
    return this.appRoute(/^analytics$/, 'showAnalytics');
  }
}

const controller = {
  showAnalytics() {
    App.getChannel().request('enable:org_filter');
    const analytics = new AnalyticsController({
      currentUser: App.getChannel().request('get:current:user'),
      analytics: analyticsApi.getChannel().request('fetch:analytics')
    });
    return analytics.showAnalytics();
  }
};

const API = MnObject.extend({
  channelName: 'router',

  radioRequests: {
    'app:analytics': 'showAnalytics'
  },

  showAnalytics() {
    App.navigate('analytics');
    App.getChannel().trigger('nav:choose', 'analytics');
  }
});

export const api = new API();

const AnalyticsApp = {
  start() {
    return new Router({
      controller
    });
  }
};

export default AnalyticsApp;
