import { Behavior } from 'backbone.marionette';
import { app as App } from 'app/backbone/app';
import { api as thiamisApi } from 'app/backbone/entities/nodes/thiamis';

const NodeWidgetFetchThiamis = Behavior.extend({
  initialize(behavior, { options: { model } }) {
    this.listenTo(model, 'change:node_id', async () => {
      App.getChannel().request('when:fetched', thiamisApi.getChannel().request('fetch:thiamis', model.get('node_id')), ([thiamis]) => {
        this.view.options.thiamis.set(thiamis);
      });
    });
    this.view.options.thiamis = thiamisApi.getChannel().request('fetch:thiamis', model.get('node_id'));
  }
});

export default NodeWidgetFetchThiamis;
